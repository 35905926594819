import React, { Component } from "react";
import { Link } from "react-router-dom";
import afterLoginApiAxios from "../Util/API Calls/afterLogin";
import GlobalFooter from "./GlobalFooter";
import Loader from "./UI Component/Loader/Loader";
import swal from "sweetalert";
import ReactGA from "react-ga";
import CountdownTimer from "./CountdownTimer";
import {
  disableHelpButton,
  enableHelpButton,
} from "./common/DisableHelpButton/DisableHelpButton";
import { DEV, MAINT, QA, UAT } from "./common/config";
import { LOCALSTORAGEKEY } from "../Util/Helper/localStorageUtils";
import { C2CConsole } from "../Util/Helper/C2CConsole";

class ReviewPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.logOut = this.logOut.bind(this);
    this.checkout = this.checkout.bind(this);
    this.paymentDone = this.paymentDone.bind(this);

    let name = localStorage.getItem("patient_name");
    let email = localStorage.getItem("patient_email");

    this.state = {
      name: name,
      email: email,
      doctor: JSON.parse(localStorage.getItem("doctor")),
      additional_fee: 0,
      order_id: "",
      phone: localStorage.getItem("user_name"),
      txnID: "",
      convenience_fee: 0,
      rounded_amount: 0,
      gst_on_convenience_fee: 0,
      showLoader: false,
      modalOpen: false,
    };
    this.userId = localStorage.getItem(LOCALSTORAGEKEY.USER_ID);
  }

  componentDidMount() {
    disableHelpButton();
    localStorage.removeItem("inclinic-online-id");
    localStorage.removeItem("inclinic-online");
  }

  componentWillUnmount() {
    enableHelpButton();
  }
  logOut(e) {
    e.preventDefault();
    afterLoginApiAxios
      .post(
        "auth/user-logout",
        {
          user_token: `${localStorage.getItem("id_token")}`,
        },
        {
          headers: {
            Authorization: `${window.localStorage.getItem("id_token")}`,
          },
        }
      )
      .then((response) => {
        C2CConsole.log(response);
        localStorage.removeItem("id_token");
        localStorage.removeItem("user_type");
        localStorage.removeItem(LOCALSTORAGEKEY.USER_ID);
        localStorage.removeItem("user_name");
        localStorage.removeItem("full_name");
        this.props.history.push("/patient/login");
      })
      .catch((err) => {
        C2CConsole.log("error found");
        C2CConsole.log(err);
        swal("", "An error occured, logging out now!", "info").then(() => {
          localStorage.removeItem("id_token");
          localStorage.removeItem("user_type");
          localStorage.removeItem(LOCALSTORAGEKEY.USER_ID);
          localStorage.removeItem("user_name");
          localStorage.removeItem("full_name");
          this.props.history.push("/patient/login");
        });
      });
  }

  successApi(paymentID, orderID, signature) {
    let self = this;
    if (paymentID && orderID) {
      afterLoginApiAxios
        .post(
          "/public/verify-payu-hash-response",
          {
            transaction_id: this.state.txnID,
            payment_id: paymentID,
            order_id: orderID,
            signature: signature,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("id_token"),
            },
          }
        )
        .then((response) => {
          if (response) {
            let responseData = response.data;
            if (responseData) {
              if (response.data.hasOwnProperty("statusCode")) {
                if (response.data.statusCode == 200) {
                  self.paymentDone();
                  localStorage.removeItem(LOCALSTORAGEKEY.CLINIC_ID);
                  this.props.history.push("/thankyou");
                }
              } else {
                alert("Error ! Please try again.");
              }
            }
          }
        })
        .catch(function (error) {
          C2CConsole.log(error);
        });
    }
  }
  paymentDone() {
    if (localStorage.getItem("meeting_id") !== null) {
      afterLoginApiAxios({
        method: "post",
        url: "/patient/patient_invitation_action",
        headers: {
          authorization: localStorage.getItem("id_token"),
        },
        data: {
          patient_id: this.userId,
          event_id: 4,
          instant_consult_key: localStorage.getItem("meeting_id"),
        },
      })
        .then((res) => {
          C2CConsole.log(res);
        })
        .catch((err) => C2CConsole.log(err));
    }
  }

  checkout(e) {
    e.preventDefault();
    let self = this;
    let razorpayID = "rzp_live_jTzrEHHIbpY2M3";
    if (
      process.env.REACT_APP_ENV === DEV ||
      process.env.REACT_APP_ENV === QA ||
      process.env.REACT_APP_ENV === UAT ||
      process.env.REACT_APP_ENV === MAINT
    ) {
      razorpayID = "rzp_test_rKJ2mGfSEsNNP7";
    }

    let props = this.props;
    this.setState({
      modalOpen: true,
    });
    let options = {
      key: razorpayID, //,
      amount: Number(this.state.additional_fee) * 100, // 2000 paise = INR 20, amount in paisa
      name: "Connect2Clinic",
      description: "Online Consultation",
      image: process.env.PUBLIC_URL + "/images/c2.png",
      order_id: this.state.order_id,
      handler: function (response) {
        localStorage.setItem("paymentID", response.razorpay_payment_id);
        localStorage.setItem("orderID", response.razorpay_order_id);
        localStorage.setItem("signature", response.razorpay_signature);
        if (response.razorpay_payment_id && response.razorpay_order_id) {
          let gaLabel = response.razorpay_payment_id + ": " + this.userId;
          ReactGA.event({
            category: "razorpay",
            action: response.razorpay_order_id,
            label: gaLabel,
          });
          self.paymentDone();
          localStorage.removeItem(LOCALSTORAGEKEY.CLINIC_ID);
          props.history.push("/thankyou");
        } else {
          let gaLabel = this.state.order_id + ": " + this.userId;
          ReactGA.event({
            category: "razorpay",
            action: "failed",
            label: gaLabel,
          });

          localStorage.removeItem(LOCALSTORAGEKEY.CLINIC_ID);
          props.history.push("/failed");
        }
      },
      prefill: {
        name: this.state.name,
        contact: this.state.phone,
        email: this.state.email,
      },
      theme: {
        color: "#17BAE0",
      },
    };
    let rzp = new window.Razorpay(options);
    rzp.open();

    const modalDocument = document.querySelector("#modal.mchild");

    if (modalDocument) {
      modalDocument.style.top = "60px";
    }
  }

  componentWillMount() {
    let apiData = {};
    if (localStorage.getItem("meeting_id") !== null) {
      afterLoginApiAxios({
        method: "post",
        url: "/patient/patient_invitation_action",
        headers: {
          authorization: localStorage.getItem("id_token"),
        },
        data: {
          patient_id: this.userId,
          event_id: 2,
          instant_consult_key: localStorage.getItem("meeting_id"),
        },
      })
        .then((res) => {
          C2CConsole.log(res);
        })
        .catch((err) => C2CConsole.log(err));
    }
    localStorage.removeItem("free");

    if (localStorage.getItem("id_token")) {
    } else {
      this.props.history.push("/patient/login");
    }
    localStorage.setItem("appointment_slot_locked", true);
    this.setState({
      showLoader: true,
    });

    if (localStorage.getItem("order_id") && localStorage.getItem("amount")) {
      this.setState({
        order_id: localStorage.getItem("order_id"),
        additional_fee: localStorage.getItem("amount"),
      });
    }
    if (localStorage.getItem("meeting_id") !== null) {
      apiData = {
        appointment_id: localStorage.getItem("flag_appointment_id"),
        meeting_id: localStorage.getItem("meeting_id"),
        consultation_type: 2,
      };
    } else {
      apiData = {
        appointment_id:
          localStorage.getItem("flag_appointment_id") ||
          localStorage.getItem("flag_appointment_id_instant"), // added flag_appointment_id_instant as flag_appointment_id patient/book-appointment-add-details-rev3 is empty
      };
      C2CConsole.log(apiData);
    }
    const clinicId = localStorage.getItem(LOCALSTORAGEKEY.CLINIC_ID);
    if (clinicId !== "" && clinicId !== undefined && clinicId !== null) {
      apiData["clinic_id"] = clinicId;
    }

    afterLoginApiAxios({
      method: "post",
      url:
        clinicId !== "" && clinicId !== undefined && clinicId !== null
          ? "/clinic/get-calculated-hash_rev1"
          : "/public/get-calculated-hash_rev1",

      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("id_token"),
      },
      data: apiData,
    })
      .then((response) => {
        if (response) {
          let responseData = response.data;
          if (responseData) {
            if (response.data.hasOwnProperty("statusCode")) {
              if (response.data.statusCode == 200) {
                let gaLabel = response.data.statusCode + ": " + this.userId;
                ReactGA.event({
                  category: "get-hash",
                  action: responseData.RequestData.order_id,
                  label: gaLabel,
                });

                this.setState({
                  order_id: responseData.RequestData.order_id,
                  additional_fee: responseData.RequestData.amount,
                  txnID: responseData.RequestData.txnid,
                  convenience_fee: responseData.RequestData.convenience_fee,
                  rounded_amount: responseData.RequestData.rounded_amount,
                  gst_on_convenience_fee:
                    responseData.RequestData.gst_on_convenience_fee,
                  showLoader: false,
                });
                localStorage.setItem("txnID", this.state.txnID);
                localStorage.setItem(
                  "order_id",
                  responseData.RequestData.order_id
                );
                localStorage.setItem("amount", responseData.RequestData.amount);
                localStorage.setItem(
                  "convenience_fee",
                  responseData.RequestData.convenience_fee
                );
                localStorage.setItem(
                  "rounded_amount",
                  responseData.RequestData.rounded_amount
                );
              } else if (response.data.statusCode == 599) {
                let gaLabel = response.data.statusCode + ": " + this.userId;
                ReactGA.event({
                  category: "get-hash",
                  action: "free booking",
                  label: gaLabel,
                });
                localStorage.setItem("free", "free booking");
                localStorage.removeItem(LOCALSTORAGEKEY.CLINIC_ID);
                this.props.history.push("/thankyou");
              } else {
                let gaLabel = response.data.statusCode + ": " + this.userId;
                ReactGA.event({
                  category: "get-hash",
                  action: "failed",
                  label: gaLabel,
                });
                localStorage.removeItem(LOCALSTORAGEKEY.CLINIC_ID);
                this.props.history.push("/failed");
              }
            } else {
            }
          }
        }
      })
      .catch(function (error) {
        C2CConsole.log(error);
      });
  }

  regularBookingUrl = `/doctor/${
    JSON.parse(localStorage.getItem("doctor"))?.short_url_slug
  }`;
  followUpBookingUrl = `/appointment/book-followup/${
    JSON.parse(localStorage.getItem("doctor"))?.short_url_slug
  }`;

  render() {
    return (
      <div>
        {this.state.showLoader ? <Loader /> : null}

        <header>
          <div className="container-fluid">
            <div className="row mt-4">
              <div className="col-6 col-md-3">
                <a href="" className="logo">
                  <img
                    src={process.env.PUBLIC_URL + "/images/HN_logo.png"}
                    alt="logo"
                  />
                </a>
              </div>
              <div className="col-6 col-md-9 text-right">
                <Link
                  to="/"
                  className="mr-5 otp-btn"
                  onClick={(e) => this.logOut(e)}
                >
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </header>
        <div>
          <CountdownTimer />
        </div>
        <div className="row" style={{ marginTop: "2vh", overflowY: "hidden" }}>
          <div className="col-sm-12 col-lg-12 text-center">
            <h2>
              <b>Payment</b>
            </h2>
          </div>
          <div
            className="col-sm-12 col-lg-4 offset-lg-4 text-center loginHeader pb-4"
            style={{ backgroundColor: "#fff" }}
          >
            <span style={{ textTransform: "capitalize" }}>
              Patient Name : <b>{this.state.name.toLowerCase()}</b>
            </span>
            <br />
            <span>
              Patient Email : <b>{this.state.email}</b>
            </span>
            <br />
            <br />
            <br />
            <span>
              Dr. {this.state.doctor.first_name}
              {"'s"} consultation fees
            </span>
            <span>
              <b>&nbsp;&nbsp;Rs. {this.state.additional_fee}</b>
            </span>
            <br />
            <br />
            {/*
            <span>Convenience fees</span>
            <span>
              <b>
                &nbsp;&nbsp;Rs.{" "}
                { Number(this.state.convenience_fee) + Number(this.state.gst_on_convenience_fee) }
              </b>
            </span>
            <br />
            <span>Round off</span>
            <span>
              <b>
                &nbsp;&nbsp;Rs.{" "}
                { Number(this.state.rounded_amount) }
              </b>
            </span>
            <br />
            */}
            <br />
            <span>Total</span>
            <span>
              <b>&nbsp;&nbsp;Rs. {this.state.additional_fee}</b>
            </span>
            <br />
            <br />
            <Link
              to="/"
              className="proceed_to_pay_btn btn text-center"
              onClick={(e) => this.checkout(e)}
              style={{ backgroundColor: "#17BAE0" }}
            >
              Proceed to pay
            </Link>
            <Link
              className=" btn text-center ml-3"
              to={
                localStorage.getItem("is_followup") === "1"
                  ? this.followUpBookingUrl
                  : this.regularBookingUrl
              }
              style={{ backgroundColor: "#D6DAE5" }}
            >
              Back
            </Link>
          </div>
        </div>

        <GlobalFooter />
      </div>
    );
  }
}
export default ReviewPage;
