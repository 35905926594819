import React from "react";
import tickIcon from "../../assets/tick.svg";
import "./TermsSignedInForgotPassword.scss";

const TermsSignedInForgotPassword = (props) => {
  const { staySignIn, handleTermsAndCondition, forgotPassCallBack } = props;
  return (
    <div className="stay-signin-forgot-password">
      <div className="stay-signin-checkbox-text">
        <div
          onClick={handleTermsAndCondition}
          className={
            "stay-sign-in-checkbox " +
            (staySignIn ? "stay-sign-in-checkbox-selected" : "")
          }
        >
          {staySignIn && <img src={tickIcon} alt="" />}
        </div>
        Stay Signed In
      </div>
      <div className="forgot-password" onClick={forgotPassCallBack}>
        Forgot Password?
      </div>
    </div>
  );
};

export default TermsSignedInForgotPassword;
