
export const SELECT_STATE = [
    { label: "Andhra Pradesh", value: 37 },
    { label: "Arunachal Pradesh", value: 12 },
    { label: "Assam", value: 18 },
    { label: "Bihar", value: 10 },
    { label: "Chattisgarh", value: 22 },
    { label: "Delhi", value: 7 },
    { label: "Goa", value: 30 },
    { label: "Gujarat", value: 24 },
    { label: "Haryana", value: 6 },
    { label: "Himachal Pradesh", value: 2 },
    { label: "Jammu and Kashmir", value: 1 },
    { label: "Jharkhand", value: 20 },
    { label: "Karnataka", value: 29 },
    { label: "Kerala", value: 32 },
    { label: "Lakshadweep Islands", value: 31 },
    { label: "Madhya Pradesh", value: 23 },
    { label: "Maharashtra", value: 27 },
    { label: "Manipur", value: 14 },
    { label: "Meghalaya", value: 17 },
    { label: "Mizoram", value: 15 },
    { label: "Nagaland", value: 13 },
    { label: "Odisha", value: 21 },
    { label: "Pondicherry", value: 34 },
    { label: "Punjab", value: 3 },
    { label: "Rajasthan", value: 8 },
    { label: "Sikkim", value: 11 },
    { label: "Tamil Nadu", value: 33 },
    { label: "Telangana", value: 36 },
    { label: "Tripura", value: 16 },
    { label: "Uttar Pradesh", value: 9 },
    { label: "Uttarakhand", value: 5 },
    { label: "West Bengal", value: 19 },
    { label: "Andaman And Nicobar Islands", value: 35 },
    { label: "Chandigarh", value: 4 },
    { label: "Dadra and Nagar Haveli", value: 26 },
    { label: "Daman and Diu", value: 25 },
    { label: "Ladakh", value: 38 },
];