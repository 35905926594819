// dependencies imports ⇒
import React, { useEffect, useState } from "react";
// assets and scss imports ⇒
import "./abhaAadharOTPView.scss";
// project file imports(UI components) ⇒
import AbhaOTPInput from "../common/AbhaOTPInput";
import AbhaCardView from "../common/AbhaCardView";
import AbhaActionButton from "../common/AbhaActionButton";
// const file imports(helper methods, etc) ⇒
import { enrollResendOtp, enrollVerifyOtp } from "../AbhaAPIHandler";
import {
  ENROLL_RESEND_OTP,
  ENROLL_VERIFY_OTP,
} from "../AbhaAPIHandler/APIConstants";
import { OTP_VALIDATION_ERROR } from "../constant";
import PhoneNumberInputWithAction from "../../common/PhoneNumberInputWithAction";

const AbhaAadharOTPView = (props) => {
  const {
    transactionID,
    aadharNumber,
    aadharMobileNumber,
    enteredMobileNumber,
    handleSteps,
    updateTransactionID,
    handlePatientId,
    handleAbhaNumber,
    handleMobileNumberChange,
  } = props;
  // const declarations ⇒
  const [otp, setOTP] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [disableActionButton, setDisableActionButton] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [hideOtpResend, setHideOtpResend] = useState(false);

  // life cycle methods ⇒
  useEffect(() => {
    if (otp?.length < 6 || enteredMobileNumber?.length < 10) {
      setDisableActionButton(true);
    } else {
      setDisableActionButton(false);
    }
  }, [otp, enteredMobileNumber]);

  // API Calls ⇒
  const enrollVerifyOtpAPICall = (reqPayload) => {
    const aadharResponse = enrollVerifyOtp(ENROLL_VERIFY_OTP, reqPayload); //Fetch API Data
    aadharResponse.then(([res, error]) => {
      if (!error) {
        if (res?.data?.statusCode == 200) {
          setDisableActionButton(false);
          setOtpError(false);
          let responseData = res?.data;
          handlePatientId(responseData?.patient_master_id);
          if (responseData?.isNew) {
            if (responseData?.is_aadhar_mobile_no) {
              handleAbhaNumber(responseData?.ABHAProfile?.ABHANumber);
              handleSteps(4);
            } else {
              handleSteps(3);
            }
          } else {
            handleSteps(5);
          }
        } else {
          setErrorMessage(
            res?.data?.code
              ? res?.data?.code?.toLowerCase() == "abdm-1100"
                ? OTP_VALIDATION_ERROR?.THIRD_ERROR
                : OTP_VALIDATION_ERROR?.SECOND_ERROR
              : OTP_VALIDATION_ERROR?.FIRST_ERROR
          );
          setOtpError(true);
          setDisableActionButton(false);
        }
      } else {
        setErrorMessage(OTP_VALIDATION_ERROR.SECOND_ERROR);
        setOtpError(true);
        setDisableActionButton(false);
      }
    });
  };

  const enrollResendOtpAPICall = (reqPayload) => {
    const aadharResendOTPResponse = enrollResendOtp(
      ENROLL_RESEND_OTP,
      reqPayload
    ); //Fetch API Data
    aadharResendOTPResponse.then(([res, error]) => {
      if (!error) {
        if (res?.data?.statusCode == 200) {
          console.log("res", res);
          updateTransactionID(res?.data?.txnId);
          setHideOtpResend(false);
        } else if (res?.data?.statusCode == 503) {
          setErrorMessage(res?.data?.message);
          setOtpError(true);
          setHideOtpResend(true);
        } else {
          setErrorMessage(
            res?.data?.code?.toLowerCase() == "abdm-1100"
              ? OTP_VALIDATION_ERROR?.THIRD_ERROR
              : OTP_VALIDATION_ERROR?.SECOND_ERROR
          );
          setOtpError(true);
        }
      }
    });
  };

  // Actions ⇒
  const handleOTPSubmit = () => {
    setDisableActionButton(true);
    let payload = {
      otp: otp,
      txnid: transactionID,
      mobile_number: enteredMobileNumber,
    };
    enrollVerifyOtpAPICall(payload);
  };

  const handleResendOTP = () => {
    let payload = {
      aadhar_number: aadharNumber,
    };
    enrollResendOtpAPICall(payload);
  };

  // create UI ⇒
  const abhaAadharHeadingAndSubheading = () => {
    return (
      <>
        <div className="aaov-aadhar-otp-verification-heading">
          Create ABHA (Health ID) Card
        </div>
        <div className="aaov-aadhar-otp-subheading">
          We just sent you OTP on your mobile number which is linked to your
          Aadhaar card.
        </div>
        <div className="aaov-aadhar-text">
          Aadhaar Mobile Number {aadharMobileNumber}
        </div>
      </>
    );
  };

  const abhaAadharOTPInput = () => {
    return (
      <div>
        <div className="aaov-otp-input-container">
          <AbhaOTPInput
            autoFocus
            length={6}
            otpError={otpError}
            errorMessage={errorMessage}
            placeholder="*"
            timerUnit={1}
            hideTimerResendBtn={hideOtpResend}
            onChangeOTP={(inputOtp) => setOTP(inputOtp)}
            handleResendOTP={handleResendOTP}
          />
        </div>
      </div>
    );
  };

  const abhaAadharOTPVerificationButton = () => {
    return (
      <div className="aaov-button-container">
        <AbhaActionButton
          title="Submit OTP"
          disabled={disableActionButton}
          handleClick={handleOTPSubmit}
        />
      </div>
    );
  };

  const abhaMobileInputUI = () => {
    return (
      <>
        <div className="aaov-mobile-container">
          <div className="aaov-mobile-input-container">
            <div className="aaov-mobile-input-label">
              Mobile Number<span className="aaov-label-mandatory">*</span>
            </div>
            <PhoneNumberInputWithAction
              placeholder={"Enter Phone Number"}
              inputValue={enteredMobileNumber}
              disabled={false}
              handleInputChange={handleMobileNumberChange}
            />
          </div>
        </div>
        <div className="aaov-mobile-text">
          This mobile number will be used for all the communications related to
          ABHA.
        </div>
      </>
    );
  };

  const AbhaAadharOTPViewUI = () => {
    return (
      <div>
        <AbhaCardView>
          {abhaAadharHeadingAndSubheading()}
          {abhaAadharOTPInput()}
          {abhaMobileInputUI()}
          {abhaAadharOTPVerificationButton()}
        </AbhaCardView>
      </div>
    );
  };

  return <div className="abha-aadhar-otp-view">{AbhaAadharOTPViewUI()}</div>;
};

//export
export default AbhaAadharOTPView;
