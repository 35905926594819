import React, { useEffect, useCallback, useState } from "react";
import swal from "sweetalert";
import Video from "twilio-video";
import { useGenerateReceiverToken } from "../../Hooks/useReceiver";
import Room from "./room";
import { useHistory } from 'react-router-dom'

const VideoChat = ({ appointmentId }) => {
  const generateReceiverToken = useGenerateReceiverToken();
  const [room, setRoom] = useState(null);
  const history = useHistory()

  const generateTwilioToken = useCallback(() => {
    if (appointmentId && localStorage.getItem("authorization_token")) {
      generateReceiverToken.mutate(
        {
          "authorizer-principal-id": { user_type: "receiver" },
          appointment_id: appointmentId,
        },
        {
          onSuccess: async (response) => {
            if(response.statusCode === 515) {
              swal(response.message).then(() => {
                localStorage.clear()
                history.push('/')           
              })
            }else {
              const room = await Video.connect(response.token, {
                name: appointmentId,
              });
              setRoom(room);
            }
            
          },
        }
      );
    }
  }, [appointmentId]);

  useEffect(() => {
    generateTwilioToken();
  }, [appointmentId]);

  return (
    <div>
      {room ? (
        <Room roomName={appointmentId} room={room} handleLogout={() => {}} />
      ) : null}
      <div className="video-section" id="video-section">
        <p className="video-txt">
          Please refresh your browser in case if you received call during
          consultation
        </p>
      </div>
    </div>
  );
};

export default VideoChat;
