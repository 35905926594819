// dependencies imports ⇒
import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import lottie from "lottie-web";
// assets and scss imports ⇒
import "./homePageTopContainer.scss";
import destopView from "../../../assets/pageView.png.png";
import doctorProfile from "../../../assets/forhomepage/doctorProfile.png";
import doctorImage from "../../../assets/doctor.png";
import PatientImage from "../../../assets/patient.png";
import homePageView from "../../../assets/homepageView.png";
import arrowLeft from "../../../assets/forhomepage/arrow_left.png";
import {
  HOME_PAGE_CONTENT_DATA,
  TOP_CONTAINT_DETAILS,
  HOME_PAGE_DATA,
} from "../constant";

const HomePageTopContainer = () => {
  //const ==>
  const animationContainer = useRef(null);
  const { text } = TOP_CONTAINT_DETAILS.CONTAINT;
  const history = useHistory();
  useEffect(() => {
    if (animationContainer.current) {
      // Initialize Lottie animation
      lottie.loadAnimation({
        container: animationContainer.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: JSON.parse(
          JSON.stringify(require("../../../assets/forhomepage/verified.json"))
        ), // Lottie JSON animation data
      });
    }
  }, []);

  //methods ==>

  const renderRatingInfoMobileView = () => {
    const renderedItems = HOME_PAGE_DATA.map((value, index) => (
      <div key={index} className="doctor-info">
        <div className="doctor-info-title">{value}</div>
        <div className="doctor-info-hover-content">{value}</div>
        <div className="doctor-info-content">
          {HOME_PAGE_CONTENT_DATA[index]}
        </div>
      </div>
    ));

    return (
      <div className="doctor-info-container">
        <div className="home_page_top_containt_rating_mobile_view">
          <div className="home_page_top_containt_rating_mobile_rating">
            {renderedItems[0]}
          </div>
          <div className="home_page_top_containt_rating_mobile_text">
            {renderedItems[1]}
          </div>
        </div>
        <div className="home_page_top_containt_rating_mobile_view">
          <div className="home_page_top_containt_rating_mobile_rating">
            {renderedItems[2]}
          </div>
          <div className="home_page_top_containt_rating_mobile_text">
            {renderedItems[3]}
          </div>
        </div>
      </div>
    );
  };

  const renderRatingInfoView = () => {
    return HOME_PAGE_DATA.map((value, index) => (
      <div
        key={index}
        style={{
          marginLeft: index > 0 ? 100 : 0,
          color: "black",
        }}
        className="doctor-info"
      >
        <div className="doctor-info-container">
          <div className="doctor-info-title">{value}</div>
          <div className="doctor-info-hover-content">{value}</div>
          <div className="doctor-info-content">
            {HOME_PAGE_CONTENT_DATA[index]}
          </div>
        </div>
      </div>
    ));
  };

  const handleLogin = (route) => {
    history.push(route);
  };

  const renderLoginLink = () => {
    return (
      <div
        onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSdjwN7PS0mGIsfFE1_EgZU6dD1LoDBG8GiCZ77gtaKjhYXW3Q/viewform','_blank')}
        className="home_top_view_display_Request_link"
        href
      >
        {TOP_CONTAINT_DETAILS.REQUEST_DEMO}
        <img
          className="home_top_view_display_Request_link_img"
          src={arrowLeft}
          alt="arrow"
        />
      </div>
    );
  };
  //<==

  //create UI ==>
  const homeTopContainerView = () => {
    return (
      <div className="home_page_top_main_view">
        <div>
          <div className="home_page_top_container_display_view">
            <div className="home_page_top_AI_text_view">
              <div className="home_page_top_AI_font_color">
                {TOP_CONTAINT_DETAILS.AI_BASED}
              </div>
            </div>
            <div
              className={
                "home_page_top_text_view" +
                " home_page_header_view_pratice_alignment"
              }
            >
              {TOP_CONTAINT_DETAILS.PRATICE}
            </div>
          </div>
          <div className="home_page_top_container_display_view">
            <div className="home_page_top_text_view">
              {TOP_CONTAINT_DETAILS.MANAGEMENT}
              {window.innerWidth < 819 && (
                <>
                  <br />
                  {TOP_CONTAINT_DETAILS.PLATFORM}
                </>
              )}
            </div>

            <div style={{ position: "relative" }}>
              <img
                className="home_page_img_height"
                src={doctorProfile}
                alt=""
              />
              {/* {window.innerWidth < 768 && (
                <img id="movingImg" src={tickMark} alt="" />
              )}
              {window.innerWidth > 768 && ( */}
              <div
                ref={animationContainer}
                className="home_page_top_animation_view"
              ></div>
              {/* )} */}
            </div>
          </div>
          <div className="home_page_top_text_view">
            {window.innerWidth > 819 && TOP_CONTAINT_DETAILS.PLATFORM}
          </div>
          <div className="containt_color">
            {window.innerWidth > 819 ? (
              <div className="textContainer_color">
                {text.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </div>
            ) : (
              <div className="textContainer_color">{text}</div>
            )}
          </div>
          <div className="home_page_top_container_login_view">
            <button
              className="home_page_login_button"
              onClick={() => handleLogin("/doctor/login")}
            >
              <div className="home_page_top_view_doctor_loginView">
                <div className="home_page_top_display_view">
                  <img
                    className="home_page_top_container_doctor_patient_view"
                    src={doctorImage}
                    alt=""
                  />
                  <div className="home_page_login_view">
                    {TOP_CONTAINT_DETAILS.DOCTOR_LOGIN}
                  </div>
                </div>
              </div>
            </button>

            <button
              className="home_page_login_button"
              onClick={() => handleLogin("/patient/login")}
            >
              <div className="home_page_top_view_doctor_loginView">
                <div className="home_page_top_display_view">
                  <img
                    className="home_page_top_container_doctor_patient_view"
                    src={PatientImage}
                    alt=""
                  />
                  <div className="home_page_login_view">
                    {TOP_CONTAINT_DETAILS.PATIENT_LOGIN}
                  </div>
                </div>
              </div>
            </button>
            {window.innerWidth > 819 && renderLoginLink()}
          </div>
          {window.innerWidth < 819 && renderLoginLink()}
        </div>
        <div>
          <img className="home_page_top_destopView" src={destopView} alt="" />
        </div>
      </div>
    );
  };

  const createHomePageRatingView = () => {
    return (
      <div className="home_page_top_container_display">
        <div
          className="containerStyle"
          style={{ width: 178, height: 180, marginTop: 40 }}
        >
          <img src={homePageView} alt="" className="hoverEffect" />
        </div>
        <div className="home_page_top_container_rating_view">
          {renderRatingInfoView()}
        </div>
      </div>
    );
  };

  const createHomePageRatingMobileView = () => {
    return (
      <div className="home_page_Top_container_rating_display_view">
        <div className="home_page_Top_container_rating_display_image_view">
          <img src={homePageView} alt="" className="hoverEffect" />
        </div>
        {renderRatingInfoMobileView()}
      </div>
    );
  };

  //<==

  return (
    <div className="home_page_top_container_view">
      {homeTopContainerView()}
      {window.innerWidth > 819 && createHomePageRatingView()}
      {window.innerWidth < 819 && createHomePageRatingMobileView()}
    </div>
  );
};

export default HomePageTopContainer;
