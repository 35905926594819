// Dependencies import
import React from "react";

// assets and scss
import "./termsAndConditionPopup.scss";

// Project File Imports
import C2CCustomPopup from "../C2CCustomPopup";

// constants import
import { TERMS_AND_CONDITIONS } from "./constant";

const TermsAndConditionPopup = ({
  isPopup = true,
  showPopupFlag,
  handleClosePopup,
  classes,
}) => {
  //display UI
  const popupBody = () => {
    return (
      <div className="terms-and-condition-popup-body">
        {TERMS_AND_CONDITIONS?.map((item) => (
          <div className="terms-and-condition-div">
            <h3>{item?.serial}</h3>
            <p>{item?.description}</p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      {isPopup ? (
        <C2CCustomPopup
          openPopup={showPopupFlag}
          popupHeading="Terms And Conditions"
          handleClosePopup={handleClosePopup}
          dialogClassName={`terms-and-condition-popup ${classes}`}
        >
          {popupBody()}
        </C2CCustomPopup>
      ) : (
        <div className={`terms-and-condition-popup ${classes}`}>
          {popupBody()}
        </div>
      )}
    </>
  );
};

export default TermsAndConditionPopup;
