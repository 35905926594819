// dependecies import ==>
import React from "react";
import { Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
// assets and scss import ==>
import "./c2cCustomPopup.scss";
import close_icon from "../../../../assets/crossIcon.svg";
// project file import ==>
import PrimaryButton from "../../Buttons/PrimaryButton";
import SecondaryButton from "../../Buttons/SecondaryButton";
// constant import ==>

const styles = {
  dialogPaper: {
    maxWidth: window.innerWidth > 768 ? "80%" : "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "10px",
    margin: window.innerWidth > 768 ? "" : "19px",
    position: "relative",
    overflowX: "hidden",
    width: window.innerWidth > 768 ? "auto" : "100%",
  },
};

const C2CCustomPopup = (props) => {
  const {
    classes,
    dialogClassName,
    openPopup,
    popupHeading,
    middleBtn,
    leftBtn,
    rightBtn,
    containerStyle,
    middleBtnDisabled,
    handleClosePopup,
    handleMiddleBtnClick,
    handleLeftBtnClick,
    handleRightBtnClick,
  } = props;

  // Display UI

  const displayC2CCustomPopup = () => {
    return (
      <Dialog
        open={openPopup}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <div className={dialogClassName}>{displayPopupContent()}</div>
      </Dialog>
    );
  };

  const displayPopupContent = () => {
    return (
      <div className="c2c-custom-popup-container">
        {displayPopupHeader()}
        <div className="c2c-custom-popup-cont" style={containerStyle}>
          {typeof props.children === "function"
            ? props.children()
            : props.children}
        </div>
        {(middleBtn || (leftBtn && rightBtn)) && displayPopupFooter()}
      </div>
    );
  };

  const displayPopupHeader = () => {
    return (
      <div className="c2c-custom-popup-header">
        <div className={"c2c-custom-popup-header-title"}>{popupHeading}</div>
        <div className={"c2c-custom-popup-header-close"}>
          <img src={close_icon} alt="" onClick={handleClosePopup} />
        </div>
      </div>
    );
  };
  const displayPopupFooter = () => {
    return (
      <div className="c2c-custom-popup-footer">
        {middleBtn ? (
          <div className="c2c-custom-popup-footer-middle-btn">
            <PrimaryButton
              title={middleBtn}
              handleButtonClick={handleMiddleBtnClick}
              disabled={middleBtnDisabled}
            />
          </div>
        ) : (
          <>
            <div className="c2c-custom-popup-footer-left-btn">
              <PrimaryButton
                title={leftBtn}
                handleButtonClick={handleLeftBtnClick}
              />
            </div>
            <div className="c2c-custom-popup-footer-right-btn">
              <SecondaryButton
                title={rightBtn}
                handleButtonClick={handleRightBtnClick}
              />
            </div>
          </>
        )}
      </div>
    );
  };

  // <===

  return <>{displayC2CCustomPopup()}</>;
};

export default withStyles(styles)(C2CCustomPopup);
