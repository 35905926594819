import React from "react";
import {
  PDF_BLACK,
  PDF_GREY_BG_COLOR,
  PDF_GREY_ROW_COLOR,
  PDF_TABLE_BORDER_COLOR,
  PDF_WHITE_ROW_COLOR,
} from "../../ERXPdfConstants/colorConstant";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

const ErxPdfMainContentTable = ({
  title,
  data,
  tableHeaders,
  pdfFontFamily,
}) => {
  const styles = StyleSheet.create({
    title: {
      textDecoration: "underline",
      fontFamily: pdfFontFamily,
      textTransform: "uppercase",
      marginTop: "5px",
      marginBottom: "5px",
      color: PDF_BLACK,
      fontSize: "9px", // 16 *.565 = 9.04 ~ 9
      fontWeight: "600",
    },
    table: {
      display: "table",
      fontFamily: pdfFontFamily,
      width: "auto",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: PDF_TABLE_BORDER_COLOR,
      fontSize: "7px", // 12 *.565 = 7.91 ~ 8
      fontWeight: "600",
    },
    headerRow: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      backgroundColor: PDF_GREY_BG_COLOR,
    },
    tableRow: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    tableCol: (width) => ({
      width: `${width}%`,
      borderStyle: "solid",
      borderWidth: 0,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      padding: "5px",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      flexWrap: "wrap",
    }),

    evenRow: {
      backgroundColor: PDF_GREY_ROW_COLOR,
    },
    oddRow: {
      backgroundColor: PDF_WHITE_ROW_COLOR,
    },
    tableHeader: {
      fontFamily: pdfFontFamily,
      fontWeight: "600",
      padding: "5px 8px 5px 8px", // 14 *.565 = 7.91 ~ 8
      color: PDF_BLACK,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      fontSize: "8px", // 14 *.565 = 7.91 ~ 8
      minHeight: "21px", // 37 *.565 = 20.09 ~ 21
    },
    tableCell: {
      fontFamily: pdfFontFamily,
      fontWeight: "normal",
      padding: "0px 8px 5px 8px", // 14 *.565 = 7.91 ~ 8
      fontSize: "8px", // 14 *.565 = 7.91 ~ 8
      color: PDF_BLACK,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      minHeight: "21px", // 37 *.565 = 20.09 ~ 21
    },
    subtitle: {
      fontSize: "5px", // 10 *.565 = 5.65 ~ 5
      padding: "0 8px 0 8px", // 14 *.565 = 7.91 ~ 8
      textTransform: "uppercase",
      fontWeight: "normal",
      fontFamily: pdfFontFamily,

      // wrap: true,
    },
  });
  return (
    <View key={title}>
      <Text style={styles.title}>{title}</Text>
      <View style={styles.table}>
        <View style={styles.headerRow}>
          <View style={styles.tableCol(10)}>
            <Text style={styles.tableHeader}>#</Text>
          </View>
          {tableHeaders?.map((header) => (
            <View
              key={`${header.title}${header.id}`}
              style={styles.tableCol(header.width)}
            >
              <Text style={styles.tableHeader}>{header.title}</Text>
            </View>
          ))}
        </View>
        {/* Header */}
        {/* Rows */}
        {data?.length > 0 &&
          data?.map((obj, index) => {
            return (
              <View
                key={index}
                style={[
                  styles.tableRow,
                  index % 2 === 0 ? styles.evenRow : styles.oddRow,
                ]}
              >
                <View style={styles.tableCol(10)}>
                  <Text style={styles.tableCell}>{index + 1}</Text>
                </View>
                {tableHeaders?.length > 0 &&
                  tableHeaders?.map((header, ind) => (
                    <View key={ind} style={styles.tableCol(header.width)}>
                      <Text style={styles.tableCell}>
                        {Array.isArray(header.key)
                          ? header?.key?.map(
                              (k) =>
                                k
                                  .split(".")
                                  .reduce(
                                    (obj1, key) =>
                                      obj1[key] ? obj1[key] : "-",
                                    obj
                                  ) + " "
                            )
                          : obj[header.key]}
                      </Text>
                      {header?.subtitle_key && (
                        <Text style={styles.subtitle}>
                          {obj[header.subtitle_key]}
                        </Text>
                      )}
                    </View>
                  ))}
              </View>
            );
          })}
      </View>
    </View>
  );
};

export default ErxPdfMainContentTable;
