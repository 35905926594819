import React, { useEffect, useState } from "react";
import "./styles/Homepagec2c.scss";
import "./styles/Menu.scss";
import axios from "axios";
import Loader from "../UI Component/Loader/Loader";
import { LOCALENV } from "../../components/common/config/index";

export default function PrescriptionSMS(props) {
  let apiMapUrl = {
    dev: "https://api-dev.c2cassets.com/",
    dev1: "https://api-dev1.c2cassets.com/",
    dev2: "https://api-dev2.c2cassets.com/",
    qa: "https://api-qa.c2cassets.com/",
    uat: "https://api-uat.c2cassets.com/",
    prod: "https://api.connect2clinic.com/",
    maint: "https://api-maint.c2cassets.com/",
    stage: "https://api-stage.c2cassets.com/",
  };
  const api =
    window.location.hostname === "localhost"
      ? LOCALENV
      : process.env.REACT_APP_ENV;
  const siteURL = apiMapUrl[api];
  const [fileData, setFileData] = useState([]);
  const [prescriptions, setPrescriptions] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [flag, setflag] = useState(false);
  const [loading, setloading] = useState(true);
  const params = new URLSearchParams(window.location.search);
  const id = params.get("appointment_id");
  const is_receipt = params.get("is_receipt");

  // Lifecycle methods ==>
  useEffect(() => {
    if (is_receipt || is_receipt === "True") {
      apiCallForReceipt();
    } else {
      apiCallForPrescription();
    }
  }, []);
  // <==

  // API Call ==>
  const apiCallForPrescription = () => {
    axios
      .post(siteURL + "public/get-prescription-by-appointment-id-rev1", {
        appointment_id: id,
      })
      .then((res) => {
        setloading(false);
        if (res.data.statusCode == 200) {
          setflag(true);
          setPrescriptions(res.data?.prescription_url);
          setDocuments(res.data?.documents_list);
        } else {
          setflag(false);
        }
      });
  };

  const apiCallForReceipt = () => {
    axios
      .post(siteURL + "public/get-shared-invoice", {
        appointment_id: id,
      })
      .then((res) => {
        setloading(false);
        if (res.data.statusCode == 200) {
          setflag(true);
          setPrescriptions(res.data?.invoice_url);
          setDocuments(res.data?.documents_list);
        } else {
          setflag(false);
        }
      });
  };
  // <==

  // Helper methods ==>

  const fileName = (file) => {
    const lastDot = file.lastIndexOf("/");
    const name = file.substring(lastDot + 1);

    return name;
  };

  const onDownloadFile = () => {
    var blob = new Blob([fileData], { type: "application/pdf" });
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    var fileName = "Prescription.pdf";
    link.download = fileName;
    link.click();
  };

  // <==

  // create UI ==>
  const createPrescriptionsListUI = () => {
    return (
      <>
        {prescriptions.map((ele, index) => (
          <>{createListUI(`Prescription ${index + 1}`, ele)}</>
        ))}
      </>
    );
  };

  const createDocumentsList = () => {
    return (
      <>
        {documents.map((ele, index) => (
          <>{createListUI(`Document ${index + 1}`, ele)}</>
        ))}
      </>
    );
  };

  const createReceiptListUI = () => {
    return (
      <>
        {prescriptions.map((ele) => (
          <>{createListUI(`Receipt`, ele)}</>
        ))}
      </>
    );
  };

  const createListUI = (title, url) => {
    return (
      <div className="row mb-3 px-4 shadow-sm text-center">
        <div className="col-6 p-1" style={{ color: "#f969a4" }}>
          <h4>{title}</h4>
        </div>
        <div className="col-6 p-1 ">
          <a
            href={url}
            target="_blank"
            style={{
              backgroundColor: "#17bae0",
              width: "6rem",
              height: "2.6rem",
            }}
            className="btn border-0 btn-lg rounded-pill btn-primary"
          >
            View
          </a>
        </div>
      </div>
    );
  };

  const errorMsgUI = () => {
    return (
      <div className="container  text-center " style={{ marginTop: "7rem" }}>
        <h5 style={{ fontSize: "1.2rem" }}>
          Sorry, the link has expired.
          <br /> Please log into your connect2clinic account to access the same.
          <br /> Click{" "}
          <a href="https://www.connect2clinic.com/patient/login ">here</a> to
          login.
        </h5>
      </div>
    );
  };

  const createPrescriptionUI = () => {
    return (
      <>
        <h3 className="text-center mb-3">Documents</h3>
        {createPrescriptionsListUI()}
        {createDocumentsList()}
      </>
    );
  };

  const createReceiptUI = () => {
    return (
      <>
        <h3 className="text-center mb-3">Invoice</h3>
        {createReceiptListUI()}
      </>
    );
  };

  const createUI = () => {
    if (is_receipt || is_receipt === "True") {
      return createReceiptUI();
    } else {
      return createPrescriptionUI();
    }
  };
  //<==

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="container mt-3 ">
          {flag ? <>{createUI()}</> : errorMsgUI()}
        </div>
      )}
    </div>
  );
}
