import moment from "moment";

export const isSlotAfterCurrentTime = (slotTime, counter = 0, selectedDay) => {
  if (
    moment().format("DD-MM-YYYY") ===
    moment(selectedDay.meta).format("DD-MM-YYYY")
  ) {
    const momentSlot = moment(slotTime, "hh:mm A");
    const difference = momentSlot.diff(moment(), "m");
    return difference >= counter;
  }
  return true;
};
