import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Modal,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTimer } from "../../Hooks/useTimer";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFormContext } from "react-hook-form";

// const formValidate = yup.object().shape({
//   OTP: yup.string().required("OTP is required"),
// });

const VerifyOTPModal = ({
  showOTPModal,
  verifyOtp,
  receiver_phone_no,
  receiverId,
  handleVerifyOtp,
  appointmentId,
  onResendOtp,
  handleShowLoginPopUp,
}) => {
  const [resetAllowed, setResendAllowed] = useState(false);
  const { register, handleSubmit, errors, setError } = useFormContext({});

  const { time, start, reset } = useTimer({
    initialTime: 60,
    endTime: 0,
    timerType: "DECREMENTAL",
    autostart: true,
    onTimeOver: () => {
      setResendAllowed(true);
    },
  });

  const resendOtp = (value) => {
    reset();
    start();
    onResendOtp(value);
  };

  return (
    <Modal onEntering={reset} onEntered={start} onExiting={reset} show={showOTPModal} className="verify-number-modal">
      <Modal.Body className="text-center">
        <h2>Verify Number</h2>
        <p>Please enter the OTP</p>
        <Form.Group>
          <div className="label-main">
            <p>+91 {receiver_phone_no}</p>
            <button onClick={handleShowLoginPopUp}>
              Edit
            </button>
          </div>
          <Form.Control
            ref={register}
            type="hidden"
            defaultValue={`+91${receiver_phone_no}`}
            name={"receiver_phone_no"}
          />
          <Form.Control
            ref={register}
            type="number"
            placeholder="Enter OTP"
            name={"OTP"}
          />
          <Form.Control
            ref={register}
            type="hidden"
            defaultValue={receiverId}
            name={"receiver_id"}
          />
          <Form.Control
            ref={register}
            type="hidden"
            defaultValue={appointmentId}
            name={"appointment_id"}
          />
          <div className="label-bottom">
            <p>00:{(time < 10) ? `0${time}` : time} left to expire</p>
            <button
              disabled={time !== 0}
              className={
                resetAllowed ? "textLink" : "text-muted pointer-disabled"
              }
              onClick={(e) => {
                e.preventDefault();
                resendOtp({
                  channel_id: "receiver-login",
                  receiver_phone_no: `${receiver_phone_no}`,
                });
              }}
            >
              Resend OTP
            </button>
          </div>
        </Form.Group>
        {errors.OTP && (
          <span style={{ color: "red" }}>{errors.OTP.message}</span>
        )}
        <div>
          <button
            onClick={handleSubmit(handleVerifyOtp)}
            className="btn primary-cta-button rounded-pill"
          >
            Continue
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VerifyOTPModal;
