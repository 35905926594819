// dependencies imports ⇒
import React, { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useLastLocation } from "react-router-last-location";

// assets and scss imports ⇒
import "./homePageViewContainer.scss";

// project file imports(UI components) ⇒
import HomePageHeaderContainer from "./HomePageHeaderContainer";
import HomePageTopContainer from "./HomePageTopContainer";
import HomePageAboutContainer from "./HomePageAboutContainer";
import HomePageFooterContainer from "./HomePageFooterContainer";
import HomePagePartnersContainer from "./HomePagePartnersContainer";
import HomePageTestimonialContainer from "./HomePageTestimonialContainer";
import HomePageFeatureContainer from "./HomePageFeatureContainer";

// const file imports(helper methods, etc) ⇒
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";

const HomePageViewContainer = () => {
  // const declarations ⇒
  const aboutRef = useRef(null);
  const featureRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const locationState = { ...location?.state };
  const lastLocation = useLastLocation();
  let staffClinicAssociation = localStorage.getItem(
    LOCALSTORAGEKEY.STAFF_CLINIC_ASSOCIATION
  );
  let doctorClinicAssociation = localStorage.getItem(
    LOCALSTORAGEKEY.DOCTOR_CLINIC_ASSOCIATION
  );
  // let or var declarations ⇒

  // life cycle methods ⇒
  useEffect(() => {
    if (locationState?.scrollTo) {
      handleScrollToContent(locationState?.scrollTo);
      history.replace({ path: "/", state: {} });
    }
  }, [locationState]);

  useEffect(() => {
    if (lastLocation === null) {
      let userType = window.localStorage.getItem("user_type");
      if (userType) {
        homepageUrl(userType);
      }
    }
  }, []);

  const homepageUrl = (userType) => {
    if (
      userType === "patient" ||
      userType === "doctor" ||
      userType === "staff"
    ) {
      if (
        staffClinicAssociation === "true" ||
        doctorClinicAssociation === "true"
      ) {
        userType = "clinic";
        window.open(
          `${window.location.origin}/${userType}/account/dashboard`,
          "_self"
        );
      } else {
        if (userType === "staff") {
          userType = "doctor";
          window.open(
            `${window.location.origin}/${userType}/account/dashboard`,
            "_self"
          );
        } else {
          window.open(
            `${window.location.origin}/${userType}/account/dashboard`,
            "_self"
          );
        }
      }
    }
  };

  // API Calls ⇒

  // Helper methods ⇒

  // Actions ⇒

  const handleScrollToContent = (scrollTo) => {
    if (scrollTo === "feature") {
      featureRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // create UI ⇒
  const homePageViewContainerUI = () => {
    return (
      <div className="home-page-view-container">
        <div className="home_page_colour_containt">
          <HomePageHeaderContainer
            handleScrollToContent={handleScrollToContent}
            showDetails={true}
          />
          <HomePageTopContainer />
          <HomePageAboutContainer aboutRef={aboutRef} />
          <HomePageFeatureContainer featureRef={featureRef} />
          <HomePageTestimonialContainer />
          <HomePagePartnersContainer />
          <HomePageFooterContainer
            handleScrollToContent={handleScrollToContent}
          />
        </div>
      </div>
    );
  };

  return <>{homePageViewContainerUI()}</>;
};

//export
export default HomePageViewContainer;
