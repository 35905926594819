// dependencies imports ⇒
import React from "react";
import { useHistory } from "react-router-dom";
// assets and scss imports ⇒
import "./homePageFooterContainer.scss";
import { HOME_PAGE_CONTENT, COMPANY_LINKS } from "../constant";
import PlayStore from "../../../assets/google_play.png";
import AppleStore from "../../../assets/app_store.png";
import Youtube from "../../../assets/youtube.svg";
import LinkedIn from "../../../assets/linkedin.svg";
import Twitter from "../../../assets/twitter.svg";
import Facebook from "../../../assets/facebook.svg";
import FacebookFill from "../../../assets/facebook_fill.svg";
import YoutubeFill from "../../../assets/youtube_fill.svg";
import TwitterFill from "../../../assets/twitter_fill.svg";
import LinkedInFill from "../../../assets/linkedin_fill.svg";
import EmailIcon from "../../../assets/mail_white.svg";
import CallIcon from "../../../assets/call_white.svg";

// project file imports(UI components) ⇒

// const file imports(helper methods, etc) ⇒

const HomePageFooterContainer = (props) => {
  const { handleScrollToContent } = props;
  // const declarations ⇒
  const history = useHistory();

  // let or var declarations ⇒

  // life cycle methods ⇒

  // API Calls ⇒

  // Helper methods ⇒

  // Actions ⇒

  const handleLinks = (link) => {
    if (link?.isLink) {
      window.open(link?.navigateTo, "_blank", "noopener,noreferrer");
    } else {
      if (window.location.pathname === "/") {
        handleScrollToContent(link?.navigateTo);
      } else {
        history.replace({
          pathname: "/",
          state: { scrollTo: link?.navigateTo },
        });
      }
    }
  };

  // create UI ⇒

  const addressUI = () => {
    return (
      <div className="home-page-footer-address-container">
        <div className="home-page-footer-address">
          <div className="home-page-footer-title">Address</div>
          <div className="home-page-footer-address-content">
            {HOME_PAGE_CONTENT?.C2C_ADDRESS}
          </div>
        </div>
      </div>
    );
  };

  const companyUI = () => {
    return (
      <div className="home-page-footer-company">
        <div className="home-page-footer-title">Company</div>
        <div className="home-page-footer-company-content">
          {COMPANY_LINKS.map((links, index) => (
            <div
              className="home-page-footer-company-title"
              onClick={() => handleLinks(links)}
              key={index}
            >
              {links?.title}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const conatactUI = () => {
    return (
      <div className="home-page-footer-contact">
        <div className="home-page-footer-title">Contact</div>
        <div className="home-page-footer-contact-content">
          <div className="home-page-footer-call-content">
            <img
              src={CallIcon}
              alt=""
              className="home-page-footer-contact-icon"
            />
            <a href="tel:1800 267 9899">{HOME_PAGE_CONTENT?.C2C_PHONE_NO}</a>
          </div>
          <div className="home-page-footer-email-content">
            <img
              src={EmailIcon}
              alt=""
              className="home-page-footer-contact-icon"
            />
            <a href={"mailto:hello@connect2clinic.com"}>
              {HOME_PAGE_CONTENT?.C2C_EMAIL}
            </a>
          </div>
        </div>
      </div>
    );
  };
  const followUsOnUI = () => {
    return (
      <div className="home-page-footer-follow-us">
        <div className="home-page-footer-title">FOLLOW US ON</div>

        <div className="home-page-follow-us-icons">
          <a
            href={"https://www.facebook.com/connect2clinic"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="follow-us-icon">
              <img src={Facebook} alt="" className="outlined-icon" />
              <img src={FacebookFill} alt="" className="filled-icon" />
            </div>
          </a>
          <a
            href={"https://twitter.com/Connect2Clinic"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="follow-us-icon">
              <img src={Twitter} alt="" className="outlined-icon" />
              <img src={TwitterFill} alt="" className="filled-icon" />
            </div>
          </a>
          <a
            href={"https://www.linkedin.com/company/connect2clinic"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="follow-us-icon">
              <img src={LinkedIn} alt="" className="outlined-icon" />
              <img src={LinkedInFill} alt="" className="filled-icon" />
            </div>
          </a>
          <a
            href={"https://www.youtube.com/@connect2clinic"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="follow-us-icon">
              <img src={Youtube} alt="" className="outlined-icon" />
              <img src={YoutubeFill} alt="" className="filled-icon" />
            </div>
          </a>
        </div>
      </div>
    );
  };

  const downloadAppUI = () => {
    return (
      <div className="home-page-footer-download-app">
        <div className="home-page-footer-title">
          DOWNLOAD THE CONNECT2CLINIC APP
        </div>
        <div className="home-page-footer-download-app-content">
          <div className="home-page-footer-download-app-icons">
            <a
              href={"https://apps.apple.com/in/app/connect2clinic/id1530572274"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={AppleStore}
                alt="Apple Store"
                className="download-app-icons"
              />
            </a>
          </div>
          <div className="home-page-footer-download-app-icons">
            <a
              href={
                "https://play.google.com/store/apps/details?id=com.connect2clinic.app"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={PlayStore}
                alt="Play Store"
                className="download-app-icons"
              />
            </a>
          </div>
        </div>
      </div>
    );
  };

  const homePageFooterContainerUI = () => {
    return (
      <div className="home-page-footer-container">
        <div className="home-page-footer-content">
          <div className="home-page-footer-top-conetnt">
            {addressUI()}
            {companyUI()}
          </div>
          <div className="home-page-footer-bottom-conetnt">
            {conatactUI()}
            {downloadAppUI()}
            {followUsOnUI()}
          </div>
        </div>
        <div className="home-page-footer-all-rights-content">
          © 2023 — All Rights Reserved
        </div>
      </div>
    );
  };

  return <>{homePageFooterContainerUI()}</>;
};

//export
export default HomePageFooterContainer;
