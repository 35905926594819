// dependencies imports ⇒
import React from "react";
// assets and scss imports ⇒
import "./abhaCardView.scss";

const AbhaCardView = (props) => {
  // create UI ⇒
  const abhaCardViewUI = () => {
    return (
      <div className="abha-card-view-conatiner">
        <div className="abha-card">
          {typeof props.children === "function"
            ? props.children()
            : props.children}
        </div>
      </div>
    );
  };

  return <>{abhaCardViewUI()}</>;
};

//export
export default AbhaCardView;
