import React, { Component } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import afterLoginApiAxios from "../Util/API Calls/afterLogin";
import { connect } from "react-redux";
import { LOCALSTORAGEKEY } from "../Util/Helper/localStorageUtils";
import { C2CConsole } from "../Util/Helper/C2CConsole";

class Failed extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      token: "",
      txnid: localStorage.getItem("txnID"),
      message: "",
      failure_msg: "",
    };

    this.logOut = this.logOut.bind(this);
  }
  componentWillMount() {
    var token = localStorage.getItem("id_token");
    var transaction_id = localStorage.getItem("bolt_txnid");
    var failure_msg = localStorage.getItem("failure_msg");
    this.setState(
      {
        token: token,
        failure_msg: failure_msg,
        //txnid:txnid
      },
      () => {}
    );
  }

  logOut() {
    afterLoginApiAxios
      .post(
        "auth/user-logout",
        {
          user_token: `${localStorage.getItem("id_token")}`,
        },
        {
          headers: {
            Authorization: `${window.localStorage.getItem("id_token")}`,
          },
        }
      )
      .then((response) => {
        localStorage.removeItem("id_token");
        localStorage.removeItem("user_type");
        localStorage.removeItem(LOCALSTORAGEKEY.USER_ID);
        localStorage.removeItem("user_name");
        localStorage.removeItem("full_name");
        localStorage.removeItem("meeting_id");
        this.props.history.push("/patient/login");
      })
      .catch((err) => {
        C2CConsole.log(err);
        swal("", "An error occured, logging out now!", "info").then(() => {
          localStorage.removeItem("id_token");
          localStorage.removeItem("user_type");
          localStorage.removeItem(LOCALSTORAGEKEY.USER_ID);
          localStorage.removeItem("user_name");
          localStorage.removeItem("full_name");
          this.props.history.push("/patient/login");
        });
      });
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <header>
          <div className="container-fluid">
            <div className="row mt-4">
              <div className="col-6 col-md-3">
                <a href="" className="logo">
                  <img
                    src={process.env.PUBLIC_URL + "/images/HN_logo.png"}
                    alt="logo"
                  />
                </a>
              </div>
              <div className="col-6 col-md-9 text-right">
                <Link className="mr-5 otp-btn" onClick={this.logOut.bind(this)}>
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </header>
        <section className="pro-body-content">
          <div className="pro-content2">
            <div className="container p-3 mb-5 ">
              <div className="row justify-content-center">
                <div className="col-md-12">
                  <div
                    className="bg-white shadow-lg p-5 text-center mt-5"
                    style={{ borderRadius: 18 }}
                  >
                    <h2 style={{ fontSize: 22 }} className="mb-4">
                      Failed to book an appointment
                    </h2>
                    {this.state.failure_msg ? (
                      <p style={{ color: "#404760" }}>
                        {this.state.failure_msg} <br />
                        <br />
                        Sorry for the inconvenience caused. For refund related
                        queries please contact support team at{" "}
                        <b style={{ color: "#F969A4" }}>
                          {this.props.mobileNumber}
                        </b>
                      </p>
                    ) : (
                      <p style={{ color: "#404760" }}>
                        Your payment process failed. Please try again.
                      </p>
                    )}
                    <Link
                      to="/patient/account/dashboard"
                      className="otp-btn mt-5"
                      style={{ width: 150 }}
                    >
                      Back to Home
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mobileNumber: state.global.mobileNumber,
  emailId: state.global.emailId,
});

export default connect(mapStateToProps)(Failed);
