import React from "react";
import Modal from "react-bootstrap/Modal";
import WarningIcon from "../../assets/warning.png";

function TimeSlotNotAvailableModal({ slotAvailable, setSlotAvailable }) {
  return (
    <Modal
      show={slotAvailable}
      onHide={() => setSlotAvailable()}
      size="lg"
      backdrop="static"
      keyboard={false}
      centered
      className="time-slot-not-available-modal"
    >
      <Modal.Body className="py-5 px-4">
        <img
          src={WarningIcon}
          alt="warning"
          className="mx-auto d-block"
          style={{ height: "77px", width: "77px" }}
        />
        <div className="text-center mt-2 time-slot-not-available-modal-head">
          Time Slot is not Available
        </div>
        <div className="text-center my-2 time-slot-not-available-modal-body">
          Your appointment time has exceeded. Please connect with your doctor to
          book an appointment.
        </div>
        <div className="text-center mt-3">
          <button
            className="payment-proceed-btn py-2 px-5"
            onClick={() => setSlotAvailable()}
          >
            Okay
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default TimeSlotNotAvailableModal;
