class PatientInfo {
  getPatientData() {
    let patientData = localStorage.getItem("patientData");
    if (patientData) {
      return JSON.parse(patientData);
    } else {
      return {
        height: "",
        weight: "",
        lmp: "",
        chiefComplaints: ["", ""],
        history: "",
        diagnosis: ["", ""],
      };
    }
  }
  getVitalsInfo() {
    let patientVitals = localStorage.getItem("patientVitals");
    if (patientVitals) {
      return JSON.parse(patientVitals);
    } else {
      return {
        appointment_id: "",
        user_type: "",
        user_id: "",
        height: "",
        height_unit: "",
        weight: "",
        weight_unit: "",
        bp_systolic: "",
        bp_diastolic: "",
        temperature: "",
        temperature_unit: "",
        spo2: "",
        random_glucose: "",
        random_glucose_unit: "",
        pulse_rate: "",
        respiration_rate: "",
        lmp: "",
      };
    }
  }
  getDrugsData() {
    let drugData = localStorage.getItem("drugItemData");
    if (drugData) {
      return JSON.parse(drugData);
    } else {
      return [
        {
          drug: "",
          frequency: "",
          duration: "",
          instruction: "",
          freqUnit: "days",
        },
        {
          drug: "",
          frequency: "",
          duration: "",
          instruction: "",
          freqUnit: "days",
        },

        {
          drug: "",
          frequency: "",
          duration: "",
          instruction: "",
          freqUnit: "days",
        },
      ];
    }
  }

  getlabFindingsData() {
    let labFindingsData = localStorage.getItem("examItemData");
    if (labFindingsData) {
      return JSON.parse(labFindingsData);
    } else {
      return [
        {
          test: "",
          instruction: "",
        },
        {
          test: "",
          instruction: "",
        },
      ];
    }
  }

  getNextFollowUpDateData() {
    let nextFollowUpDate = localStorage.getItem("followupData");
    if (nextFollowUpDate) {
      return JSON.parse(nextFollowUpDate);
    } else {
      return "";
    }
  }

  getPrescriptionFormPropertyData() {
    let prescriptionFormProperty = localStorage.getItem(
      "prescriptionFormProperty"
    );
    if (prescriptionFormProperty) {
      return prescriptionFormProperty;
    } else {
      return "";
    }
  }

  getDoctorNotes() {
    let doctorNotes = localStorage.getItem("doctorNotes");
    if (doctorNotes) {
      return doctorNotes;
    } else {
      return "";
    }
  }

  clearPatientData() {
    localStorage.removeItem("patientData");
    localStorage.removeItem("patientVitals");
    localStorage.removeItem("drugItemData");
    localStorage.removeItem("examItemData");
    localStorage.removeItem("followupData");
    localStorage.removeItem("prescriptionFormProperty");
    localStorage.removeItem("doctorNotes");
  }
}

const PatientInfoData = new PatientInfo();

export default PatientInfoData;
