import afterLoginApiAxios from "../API Calls/afterLogin";
import { LOCALSTORAGEKEY } from "../Helper/localStorageUtils";

// Helper Methods ===>

const commonApiHandler = async (method, api, payload, params, token) => {
  try {
    const res = await afterLoginApiAxios({
      method: method,
      url: api,
      params: params ?? "",
      data: payload ?? {},
      headers: {
        authorization: token ?? localStorage.getItem(LOCALSTORAGEKEY.ID_TOKEN),
      },
    });

    if (res) {
      return [res, false];
    }
    return [null, true];
  } catch (error) {
    return [null, error];
  }
};

// <===

// api handler for get method
export const c2cGetApiHandler = (api, params, payload, token) => {
  return commonApiHandler("get", api, payload, params, token);
};

// api handler for post method
export const c2cPostApiHandler = (api, payload, params, token) => {
  return commonApiHandler("post", api, payload, params, token);
};

// api handler for put method
export const c2cPutApiHandler = (api, payload, params, token) => {
  return commonApiHandler("put", api, payload, params, token);
};

// api handler for delete method
export const c2cDeleteApiHandler = (api, params, payload, token) => {
  return commonApiHandler("delete", api, payload, params, token);
};
