import React, { Component } from "react";
import { Link } from "react-router-dom";
import GlobalFooter from "./GlobalFooter";

class Footer extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "#404760", color: "#fff" }}>
        <div className="row pt-3">
          <div className="col-sm-12 text-center">
            <h4 style={{ color: "#fff" }}></h4>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-justify">
              <small>
                <p>
                  Our services are provided through the Site (which may include
                  Mobile Applications), which is a tele-health online solution
                  that allows participating medical professionals and health
                  care staff (“Healthcare Providers” or “Providers”) to
                  communicate with their clients and patients (“Patients”) to
                  provide healthcare services online and perform virtual house
                  calls via a secure Internet connection (the “Services”). We
                  are not a medical service provider, health insurance company,
                  or licensed to sell health insurance.Other than information
                  received directly by you from physicians in the context of a
                  telemedicine service,the content on the website should not be
                  considered medical advice. Other than information received
                  directly by you from physicians in the context of a
                  telemedicine service, you should always talk to an
                  appropriately qualified health care professional for diagnosis
                  and treatment, including information regarding which
                  medications or treatment may be appropriate for you. None of
                  the content on this website represents or warrants that any
                  medication or treatment is safe, appropriate, or effective for
                  you.Without limitation, Connect2Clinic does not recommend or
                  endorse any specific tests, physicians,medications, products
                  or procedures.
                </p>
              </small>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row mt-4 pt-4">
            <div
              className="col-sm-12 col-lg-4 text-left"
              style={{ color: "#2f2f2f" }}
            >
              <span style={{ color: "#F969A4" }}>
                <b>Streamlined visits for providers and patients</b>
              </span>
              <ul style={{ paddingLeft: "20px", color: "#fff" }}>
                <li>HIPAA compliant live video</li>
                <li>PCI/DSS compliant</li>
                <li>Patient self-scheduling</li>
                <li>Patient intake with health questionnaire</li>
                <li>e-Prescription as per MCI guidelines</li>
                <li>Any credit/debit cards across globe</li>
                <li>Patient visit summary</li>
                <li>24/7 provider and patient customer service</li>
              </ul>
            </div>

            <div className="col-sm-12 col-md-8 text-left">
              <span className="pl-5" style={{ color: "#F969A4" }}>
                <b>Our Compliances</b>
              </span>
              <div className="row pl-5 pr-5 pt-2">
                <div className="col-4 col-md-3">
                  <img
                    className="p-2 w-100"
                    src={process.env.PUBLIC_URL + "/images/DI.png"}
                  />
                </div>
                <div className="col-4 col-md-3">
                  <img
                    className="p-2 w-100"
                    src={process.env.PUBLIC_URL + "/images/HIPPA.png"}
                  />
                </div>
                <div className="col-4 col-md-3">
                  <img
                    className="p-2 w-100"
                    src={process.env.PUBLIC_URL + "/images/AWS.png"}
                  />
                </div>
                <div className="col-4 col-md-3">
                  <img
                    className="p-2 w-100"
                    src={process.env.PUBLIC_URL + "/images/PCI.png"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <GlobalFooter />
      </div>
    );
  }
}

export default Footer;
