import React, { useRef, useState, useCallback, useEffect } from "react";
import "../HomepageNew/DatePickerNew.css";

const DatePickerNew = ({ get_selected_Date, error_message }) => {
  const [date, setDate] = useState({
    year: null,
    month: "",
    day: "",
    dayDropDown: false,
    monthDropDown: false,
    yearDropDown: false,
    initial: true,
  });

  const [error, setError] = useState(false);

  const [dateError, setDateError] = useState({
    monthError: false,
    dayError: false,
    yearError: false,
  });
  const [triggerClickOutside, setTriggerClickOutside] = useState(false);

  const wrapperRef = useRef(null);

  const blockInvalidChar = (event) => {
    ["e", "E", "-", "+"].includes(event.key) && event.preventDefault();
  };

  let currentYear = new Date().getFullYear();
  let startingYear = new Date().getFullYear() - 110;

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        triggerClickOutside
      ) {
        get_selected_Date(
          { year: date.year, month: date.month, day: date.day },
          error
        );
        setDate({
          ...date,
          day:
            date.day <= 9 && date.day.length == 1 ? "0" + date.day : date.day,
          month:
            date.month <= 9 && date.month.length == 1
              ? "0" + date.month
              : date.month,
          dayDropDown: false,
          monthDropDown: false,
          yearDropDown: false,
        });
      }
    }
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, date.year, date.month, date.day]);

  const generateList = (start, end) => {
    const lists = [];

    for (let i = start; i <= end; i++) {
      if (i < 10) {
        lists.push(
          <li>
            {0}
            {i}
          </li>
        );
      } else {
        lists.push(<li>{i}</li>);
      }
    }
    return lists;
  };

  const renderDropDown = (type) => {
    if (type === "Year") {
      return generateList(startingYear, new Date().getFullYear()); //1900
    } else if (type === "Month") {
      return generateList(1, 12);
    } else if (type === "Day") {
      const days = new Date(date.year, date.month, 0).getDate();
      return generateList(1, days);
    }
  };

  const changeHandler = useCallback((inputField) => (event) => {
    setTriggerClickOutside(true);
    const value = event.target.value;
    if (!Number.isNaN(+value)) {
      if (inputField === "day" || inputField === "month") {
        if (
          value.length < 2 ||
          (value.length == 2 &&
            inputField === "day" &&
            +value > 0 &&
            +value <= 31) ||
          (value.length == 2 &&
            inputField === "month" &&
            +value > 0 &&
            +value <= 12)
        ) {
          setDate({
            ...date,
            [inputField]: value,
            [inputField + "DropDown"]: false,
          });
        } else {
          setDate({ ...date, [inputField + "DropDown"]: false });
        }
        get_selected_Date(
          {
            year: date.year,
            month: inputField === "month" ? value : date.month,
            day: inputField === "day" ? value : date.day,
          },
          false
        );
      } else if (inputField === "year") {
        if (value?.toString()?.length <= 4) {
          get_selected_Date(
            { year: value, month: date.month, day: date.day },
            false
          );
          setDate({
            ...date,
            day:
              date.day <= 9 && date.day.length == 1 ? "0" + date.day : date.day,
            month:
              date.month <= 9 && date.month.length == 1
                ? "0" + date.month
                : date.month,
            [inputField]: value,
            [inputField + "DropDown"]: false,
            initial: false,
          });
        } else {
          setDate({
            ...date,
            [inputField + "DropDown"]: false,
            initial: false,
          });
        }
      }
    }
  });

  const clickHandler = useCallback((inputField) => (event) => {
    setTriggerClickOutside(true);
    if (
      (inputField === "day" || inputField === "month") &&
      event.target.textContent.length > 2
    ) {
      setDate({
        ...date,
        [inputField]: event.target.textContent.substr(0, 2),
        [inputField + "DropDown"]: false,
      });
    } else {
      get_selected_Date(
        { year: event.target.textContent, month: date.month, day: date.day },
        false
      );
      setDate({
        ...date,
        [inputField]: event.target.textContent,
        [inputField + "DropDown"]: false,
        initial: false,
      });
    }
  });

  const showDropdown = useCallback((inputField) => (event) => {
    setDate({
      ...date,
      day: date.day <= 9 && date.day.length == 1 ? "0" + date.day : date.day,
      month:
        date.month <= 9 && date.month.length == 1
          ? "0" + date.month
          : date.month,
      dayDropDown: false,
      monthDropDown: false,
      yearDropDown: false,
      [inputField + "DropDown"]: !date[inputField + "DropDown"],
    });
  });

  const setValue = (inputField, value) => {
    setDate({
      ...date,
      dayDropDown: false,
      monthDropDown: false,
      yearDropDown: false,
      [inputField]: value,
    });
  };

  const operationHandler = (inputField, value) => (event) => {
    if (inputField === "day") {
      dayRef.current.focus();
      const days = new Date(date.year, date.month, 0).getDate();
      if (date[inputField] >= days && value > 0) {
        setValue("day", days);
      } else if (+date[inputField] <= 1 && value < 0) {
        setValue("day", "01");
      } else {
        if (date[inputField] < 9 && value > 0) {
          setValue("day", "0" + (+date[inputField] + value));
        } else if (date[inputField] <= 10 && value < 0) {
          setValue("day", "0" + (+date[inputField] + value));
        } else {
          setValue("day", +date[inputField] + value);
        }
      }
    } else if (inputField === "month") {
      monthRef.current.focus();
      if (+date[inputField] >= 12 && value > 0) {
        setValue("month", 12);
      } else if (
        (+date[inputField] <= 1 && value < 0) ||
        (+date[inputField] == 0 && value > 0)
      ) {
        setValue("month", "01");
      } else {
        if (+date[inputField] < 9 && +date[inputField] >= 1 && value > 0) {
          setValue("month", "0" + (+date[inputField] + value));
        } else if (
          +date[inputField] <= 10 &&
          +date[inputField] >= 1 &&
          value < 0
        ) {
          setValue("month", "0" + (+date[inputField] + value));
        } else {
          setValue("month", +date[inputField] + value);
        }
      }
    } else if (inputField === "year") {
      yearRef.current.focus();
      if (
        !date[inputField] ||
        (date[inputField] === startingYear && value < 0)
      ) {
        //startingyear instead 1900
        setValue("year", startingYear); //1900
      } else if (date[inputField] == currentYear && value > 0) {
        setValue("year", currentYear);
      } else if (date[inputField] == currentYear && value < 0) {
        setValue("year", +date[inputField] + value);
      } else if (date[inputField] < currentYear) {
        setValue("year", +date[inputField] + value);
      } else {
        setDate({
          ...date,
          dayDropDown: false,
          monthDropDown: false,
          yearDropDown: false,
        });
      }
    }
  };

  if (
    (date.year < startingYear || date.year > currentYear) &&
    date.year?.length === 4 &&
    !dateError.yearError
  ) {
    //startingYear instead of 1900
    setDateError({ ...dateError, ["yearError"]: true });
  } else if (
    date.year >= startingYear &&
    date.year <= currentYear &&
    date.year?.length === 4 &&
    dateError.yearError
  ) {
    //startingYear instead of 1900
    setDateError({ ...dateError, ["yearError"]: false });
  }

  if (
    (+date.month < 1 || +date.month > 12 || (date.month + "").length < 2) &&
    (date.month + "").length > 1 &&
    !dateError.monthError &&
    +date.month === 0
  ) {
    setDateError({ ...dateError, ["monthError"]: true });
  } else if (
    +date.month >= 1 &&
    +date.month <= 12 &&
    (date.month + "").length === 2 &&
    dateError.monthError &&
    +date.month !== 0
  ) {
    setDateError({ ...dateError, ["monthError"]: false });
  }

  if (
    date.month.length > 1 &&
    !dateError.monthError &&
    date.year?.length == 4 &&
    !dateError.yearError
  ) {
    const days = new Date(date.year, date.month, 0).getDate();
    if (
      (date.day < 1 || date.day > days) &&
      !dateError.dayError &&
      (date.day + "").length > 0
    ) {
      setDateError({ ...dateError, ["dayError"]: true });
    } else if (
      date.day >= 1 &&
      date.day <= days &&
      dateError.dayError &&
      (date.day + "").length === 2 &&
      +date.day !== 0
    ) {
      setDateError({ ...dateError, ["dayError"]: false });
    }
  } else if (
    date.day &&
    ("" + date.day).length == 2 &&
    date.day == "00" &&
    !dateError.dayError
  ) {
    setDateError({ ...dateError, ["dayError"]: true });
  } else if (
    date.day &&
    ("" + date.day).length == 2 &&
    date.day !== "00" &&
    dateError.dayError
  ) {
    setDateError({ ...dateError, ["dayError"]: false });
  }

  if (
    (dateError.yearError || dateError.monthError || dateError.dayError) &&
    !error
  ) {
    get_selected_Date(
      { year: date.year, month: date.month, day: date.day },
      true
    );
    setError(true);
  } else if (
    !dateError.yearError &&
    !dateError.monthError &&
    !dateError.dayError &&
    error
  ) {
    get_selected_Date(
      { year: date.year, month: date.month, day: date.day },
      false
    );
    setError(false);
  }

  const handleZeroInput = () => {
    if (date.day && ("" + date.day).length == 1) {
      setDate({ ...date, ["day"]: "0" + date["day"] });
    }
    if (date.month && ("" + date.month).length == 1) {
      setDate({ ...date, ["month"]: "0" + date["month"] });
    }
  };

  let dayRef = useRef(null);
  let monthRef = useRef(null);
  let yearRef = useRef(null);

  if (date.dayDropDown) {
    dayRef.current.focus();
    handleZeroInput();
  } else if (
    (date.day && !date.month && (date.day + "").length == 2) ||
    date.monthDropDown
  ) {
    monthRef.current.focus();
    handleZeroInput();
  } else if (
    (date.month && date.year?.length !== 4 && (date.month + "").length == 2) ||
    date.yearDropDown
  ) {
    if (date.year === null) {
      yearRef.current.focus();
      setDate({ ...date, ["year"]: 1986 }); //1986 startingYear
      handleZeroInput();
    }
  }

  return (
    <div
      className=" date-wrapper d-flex justify-content-between"
      ref={wrapperRef}
    >
      <div className="form-group m-0">
        <div
          className={
            error
              ? `date_error_message up-down-icons-wrapper`
              : "up-down-icons-wrapper"
          }
        >
          <div className="up-down-icons d-flex flex-column">
            <i
              className="fa fa-caret-up"
              onClick={operationHandler("day", 1)}
            ></i>
            <i
              className="fa fa-caret-down"
              onClick={operationHandler("day", -1)}
            ></i>
          </div>

          <input
            className={
              document.activeElement === dayRef.current ? "active-dropdown" : ""
            }
            type="number"
            value={date.day}
            onChange={changeHandler("day")}
            onKeyDown={blockInvalidChar}
            onClick={showDropdown("day")}
            placeholder="DD"
            ref={dayRef}
          />
        </div>
        {date.dayDropDown === true &&
        !date.monthDropDown &&
        !date.yearDropDown ? (
          <ul
            style={{ height: "300px", overflow: "auto" }}
            className="date-dropdown-ul"
            onClick={clickHandler("day")}
          >
            {renderDropDown("Day")}
          </ul>
        ) : (
          ""
        )}
      </div>
      <div className="form-group m-0">
        <div
          className={
            error
              ? `date_error_message up-down-icons-wrapper`
              : "up-down-icons-wrapper"
          }
        >
          <div className="up-down-icons d-flex flex-column">
            <i
              class="fa fa-caret-up"
              onClick={operationHandler("month", 1)}
            ></i>
            <i
              class="fa fa-caret-down"
              onClick={operationHandler("month", -1)}
            ></i>
          </div>
          <input
            className={
              document.activeElement === monthRef.current
                ? "active-dropdown"
                : ""
            }
            placeholder="MM"
            type="number"
            value={date.month}
            onChange={changeHandler("month")}
            onKeyDown={blockInvalidChar}
            onClick={showDropdown("month")}
            ref={monthRef}
          />
        </div>
        {((date.day &&
          !date.month &&
          !date.year?.toString()?.length != 4 &&
          (date.day + "").length == 2) ||
          date.monthDropDown) &&
        !date.yearDropDown &&
        !date.dayDropDown ? (
          <ul className="date-dropdown-ul" onClick={clickHandler("month")}>
            {renderDropDown("Month")}
          </ul>
        ) : (
          ""
        )}
      </div>
      <div className="form-group m-0">
        <div
          className={
            error
              ? `date_error_message up-down-icons-wrapper`
              : "up-down-icons-wrapper"
          }
        >
          <div className="up-down-icons d-flex flex-column">
            <i class="fa fa-caret-up" onClick={operationHandler("year", 1)}></i>
            <i
              class="fa fa-caret-down"
              onClick={operationHandler("year", -1)}
            ></i>
          </div>
          <input
            className={
              document.activeElement === yearRef.current
                ? "active-dropdown"
                : ""
            }
            placeholder="YYYY"
            type="number"
            value={date.year}
            onChange={changeHandler("year")}
            onKeyDown={blockInvalidChar}
            onClick={showDropdown("year")}
            ref={yearRef}
          />
        </div>

        {(date.year?.toString()?.length !== 4 &&
          date.month.length == 2 &&
          date.yearDropDown &&
          !date.initial &&
          !date.monthDropDown &&
          !date.dayDropDown) ||
        date.yearDropDown ||
        (date.year?.toString()?.length !== 4 &&
          date.month.length == 2 &&
          !date.yearDropDown &&
          date.initial &&
          !date.monthDropDown &&
          !date.dayDropDown) ? (
          <ul
            className="date-dropdown-ul"
            onClick={clickHandler("year")}
            style={{ height: "300px", overflow: "auto" }}
          >
            {renderDropDown("Year")}
          </ul>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default DatePickerNew;
