import React from "react";
import error from "../../assets/errorIcon.svg";
import { Modal, Button } from "react-bootstrap";

const StaffLoginErrorModal = ({ show,onClose }) => {
  return show ? (
    <Modal show={show}  centered>
      <Modal.Body className={`py-4 px-2`}>
        <div className="myschedule-modal">
          <div className="text-center align-items-center">
            <img
              src={error}
              alt="error"
              style={{ width: "60px", height: "60px" }}
            />
            <p className="font-weight-bold my-1 modal-heading">
              {"Login Failed!"}
            </p>
            <p className="mt-2 mb-3 modal-paragraph">
              {
                "Your account has not been verified or is disabled. Please connect with your doctor to proceed further."
              }
            </p>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Button
              className="border-0 rounded-pill "
              style={{
                backgroundColor: "#17BAE0",
                color: "#fff",
                width: "140px",
                height: "44px",
              }}
              onClick={onClose}
            >
              Okay
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  ) : null;
};

export default StaffLoginErrorModal;
