import React, { useEffect, useState, useRef } from "react";
import { Col } from "react-bootstrap";
import Stack from "@mui/material/Stack";
import ImageIcon from "@mui/icons-material/Image";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import closeIcon from "../../../assets/close_icon.svg";
import { uploadMediaToS3 } from "../uploadMedia";
import { cellViewType, fileType } from "../../../Util/constants";
import RectangeShapeRoundedImgCell from "../cells/RectangeShapeRoundedImgCell";
import DocumentViewer from "../DocumentViewer";
import afterLoginApiAxiosPEM from "../PEMAfterLogin";

const ShareContentView = (props) => {
  let profile_picture = localStorage.getItem("profile_picture_presigned_url");
  let user_full_name = localStorage.getItem("user_full_name");
  let inputRef = useRef();
  let {
    patientId,
    selectedArticles,
    previousText,
    previousuploadMedia,
    previous_doc_data,
  } = props;
  const [articlesToBeShared, setArticlesToBeShared] =
    useState(selectedArticles);
  const [title, setTitle] = useState(props.previousText);
  const [uploadMedia, setUploadMedia] = useState(previousuploadMedia);
  const [doc_data, setDoc_data] = useState(previous_doc_data);
  const [maxSizeFileError, setmaxSizeFileError] = useState(false);
  const [shareBtnEnable, setshareBtnEnable] = useState(true);

  // API Calls ==>
  const shareContentAPICall = async () => {
    setshareBtnEnable(false);
    let article_ids = articlesToBeShared.map((article) => article.article_id);
    // let patient_ids = ["73ea8635-d3f9-4dfb-9c15-a9638dd779af"];
    let patient_ids = [patientId];
    const data = {
      specialization_id: localStorage.getItem("specialization"),
      article_ids: article_ids,
      patient_ids: patient_ids,
      shared_text: title,
      is_article: article_ids.length > 0,
      is_shared_text: title.length > 0,
      is_media: doc_data ? true : false,
      is_recorded: false,
      media_name: uploadMedia ? uploadMedia.fileName : "",
      media_path: doc_data ? doc_data[0] : "",
      media_size: uploadMedia ? uploadMedia.size : "",
      media_type: uploadMedia ? uploadMedia.filetype : "",
      media_thumbnail_name: uploadMedia ? uploadMedia.fileName : "",
      media_thumbnail_path: doc_data ? doc_data[1] : "",
      media_thumbnail_size: uploadMedia ? uploadMedia.size : "",
      media_thumbnail_type: uploadMedia ? uploadMedia.filetype : "",
      recorded_media_name: "",
      recorded_media_path: "",
      recorded_media_size: "",
      recorded_media_type: "",
    };
    const res = await afterLoginApiAxiosPEM({
      method: "post",
      url: "doctor/share-pem-content",
      data: data,
    });
    if (res) {
      setshareBtnEnable(true);
    }
    if (res && res.data && res.data.statusCode === 200) {
      props.pemCloseBtnCallback();
    }
  };

  //<==

  // actions ==>
  const addContentBtnClicked = () => {
    props.addContentCallback(title, articlesToBeShared, uploadMedia, doc_data);
  };

  const closeImgClick = () => {
    setUploadMedia({});
    const dataTransfer = new DataTransfer();
    inputRef.current.files = dataTransfer.files;
  };

  const shareBtnClick = () => {
    if (shareBtnEnable) {
      shareContentAPICall();
    }
  };
  //<==

  // callbacks ==>
  const onClickCallback = (isSelect, content) => {};

  const closeBtnCallback = (content, viewType) => {
    if (viewType === cellViewType.upload) {
      // setUploadMedia({});
      return;
    }

    const filteredArticles = articlesToBeShared.filter(
      (item) => item.article_id !== content.article_id
    );

    setArticlesToBeShared(filteredArticles);
  };

  // <==

  // helper method ==>
  const handleTitle = (e) => {
    setTitle(e.target.value);
  };

  const uploadMediaHelper = async (data, file) => {
    let [success, response] = await uploadMediaToS3(data, file);
    if (success) {
      setUploadMedia(data);
      setDoc_data([
        response.data.response.main_content.fields.key,
        response.data.response.thumbnail.fields.key,
      ]);
    }
  };

  const handleChange = (e) => {
    const files = e.target.files;
    Object.values(files).forEach((file) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        let data = {
          value: reader.result,
          name: file.name,
          size: file.size,
          fileName: file.name,
          filetype: file.type,
        };
        // uploadFiles.push(data)
        if (data.size < 20000000) {
          setmaxSizeFileError(false);
          uploadMediaHelper(data, file);
        } else {
          setmaxSizeFileError(true);
        }
      };
    });
  };
  // <==

  // Create UI ==>

  const renderInputField = () => {
    return (
      <div className="share-content-input-field-container">
        <Col>
          <div style={{ display: "flex" }}>
            <div className="profile_circular_div_big">
              <span>
                {user_full_name ? user_full_name.charAt(0).toUpperCase() : "C"}
              </span>
            </div>
            <textarea
              type="content"
              className="share-content-input-field"
              placeholder="Write about the content…"
              maxLength="500"
              value={title}
              onChange={handleTitle}
            />{" "}
            {title.length > 0 ? (
              <p
                className={
                  500 - title.length === 0
                    ? "share-text-red"
                    : 500 - title.length > 0 && 500 - title.length < 500
                    ? "share-text-green"
                    : ""
                }
                style={{ lineHeight: "40px" }}
              >
                {500 - title.length}/500
              </p>
            ) : (
              ""
            )}
          </div>
        </Col>
      </div>
    );
  };

  const renderUploadMediaUI = () => {
    // return uploadMedia.map((media, index) => {
    if (uploadMedia && uploadMedia.value) {
      if (uploadMedia.value) {
        let content = {
          keywords: [],
          article_title: uploadMedia.name,
        };
        switch (uploadMedia.filetype) {
          case fileType.png:
          case fileType.jpg:
            return (
              <div className="share-content-upload-media-img-container">
                <img
                  src={closeIcon}
                  alt="cancel"
                  className="share-content-upload-media-cancel"
                  onClick={() => closeImgClick()}
                />
                <img
                  src={uploadMedia.value}
                  alt="img"
                  className="share-content-upload-media-img"
                />
              </div>
            );
          default:
            return (
              <RectangeShapeRoundedImgCell
                viewType={cellViewType.upload}
                content={content}
                onClickCallback={onClickCallback}
                closeBtnCallback={closeImgClick}
                isEnable={false}
                isSelected={false}
              />
            );
        }
      }
    }
    // })
  };

  const renderFooter = () => {
    return (
      <>
        <div className="share-footer-container">
          <hr />
          <div style={{ display: "flex" }}>
            <Stack direction="row" spacing={1}>
              {props.selectedArticles.length < 3 ? (
                <div className="share-content-green-div">
                  <InsertDriveFileIcon style={{ height: "20px" }} />
                  <div
                    className="share-content-footer-text"
                    onClick={() => addContentBtnClicked()}
                  >
                    Add C2C Content
                  </div>
                </div>
              ) : (
                ""
              )}
              <div
                className="share-content-img-div"
                onClick={() => inputRef.current.click()}
              >
                <input
                  type="file"
                  // multiple
                  style={{ display: "none" }}
                  ref={inputRef}
                  onChange={handleChange}
                />
                <ImageIcon style={{ color: "#F9A669" }} />
                <div className="share-content-footer-text">Upload Media</div>
              </div>
            </Stack>

            <div style={{ flex: 1 }}>
              <div
                className="share-content-active-div"
                style={{ float: "right" }}
              >
                <div
                  className="share-content-footer-text"
                  style={{ textAlign: "center", paddingLeft: "0px" }}
                  onClick={() => shareBtnClick()}
                >
                  Share
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  // <==
  return (
    <div className="share-content-container">
      <div className="share-content-top-container">
        {/* {
          <ShareInputField
            previousText={previousText}
            shareInputTextChange={shareInputTextChange}
          />
        } */}
        {renderInputField()}
        {renderUploadMediaUI()}
        {props.selectedArticles.length > 0
          ? props.selectedArticles.map((article, idx) => (
              <DocumentViewer
                removeDocView={() => props.removeDocView(idx)}
                id={article.article_id}
                key={idx}
              />
            ))
          : ""}
      </div>
      {renderFooter()}
    </div>
  );
};

export default ShareContentView;
