import React, { Component } from "react";
import moment, { duration } from "moment";
import { withRouter } from "react-router";
import "../globalStyles/coundownTimerStyle.css";
import { LOCALSTORAGEKEY } from "../Util/Helper/localStorageUtils";

class CountdownTimer extends Component {
  constructor(props) {
    super(props);
    //Initial time of component

    this.state = {
      minutes: 0,
      seconds: 0,
    };
  }

  meetingId = localStorage.getItem(LOCALSTORAGEKEY.MEETING_ID);
  loadTime = moment().add(this.meetingId ? 7 : 20, "minutes");

  setCountdown() {
    // Present time
    const present = moment();

    // Calculate Duration and convert to min and sec
    const timeDuration = duration(this.loadTime.diff(present));

    const mins = timeDuration.minutes();
    const secs = timeDuration.seconds();

    // Stop the interval when both minutes and seconds reach 0
    if (mins <= 0 && secs <= 0) {
      clearInterval(this.interval);
    }

    // Set the state
    this.setState({
      minutes: mins >= 0 ? mins : 0,
      seconds: secs >= 0 ? secs : 0,
    });
  }

  //Run setCountdown every second to give us the time left in min and sec
  componentDidMount() {
    this.setCountdown();
    this.interval = setInterval(() => {
      this.setCountdown();
    }, 1000);
  }

  componentDidUpdate() {
    if (this.state.minutes === 0 && this.state.seconds === 0) {
      localStorage.removeItem(LOCALSTORAGEKEY.CLINIC_ID);
      window.location.replace("/failed");
    }
  }

  //Clear up the interval calculation method when its unmounting
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  addZero(time) {
    if (time < 10) return `0${time}`;
    return time;
  }

  render() {
    return (
      <div className="countdown-container">
        {`Please complete your payment within ${this.addZero(
          this.state.minutes
        )} : ${this.addZero(this.state.seconds)}`}
      </div>
    );
  }
}

export default withRouter(CountdownTimer);
