import React, { useEffect, useState } from "react";
import { View, Text, Image, StyleSheet } from "@react-pdf/renderer";
import { getHeaderFooterImage } from "../ERXPdfHelper/helper";
import { getHeaderData } from "../ERXPdfHelper/headerHelper";
import { getBase64Image } from "../ERXPdfHelper/imageHelper";

const ERXPdfHeaderView = (props) => {
  const { erxPdfData, pdfFontFamily } = props;

  const styles = StyleSheet.create({
    header: {
      top: 0,
      left: 0,
      width: "100%",
      position: "absolute",
      backgroundColor: "#DAE0E2",
      display: "flex",
      flexDirection: "row",
    },
    headerImgCont: {
      width: "100%",
      height: "100%",
    },
    headerImg: {
      maxHeight: "100%",
      width: "100%",
      height: "100%",
      display: "block",
    },
    doctorClinicDetailsContainer: {
      display: "flex",
      flexDirection: "row",
      padding: "11px 13px 11px 13px",
    },
    doctorHospitalLogo: {
      height: "40px",
      width: "40px",
      marginRight: "6px",
      paddingTop: "2px",
    },
    doctorClinicDetailsCont: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      paddingTop: "5px",
    },
    qrCodeUrl: {
      width: "82px",
      height: "82px",
    },
    qrCodeText: {
      paddingTop: "6px",
      fontWeight: "600",
      fontSize: "7px",
      fontFamily: pdfFontFamily,
    },
    headerHospitalName: {
      fontSize: "10px",
      fontWeight: "700",
      marginBottom: "4px",
      textTransform: "capitalize",
      fontFamily: pdfFontFamily,
    },
    headerDoctorName: {
      fontSize: "10px",
      fontWeight: "700",
      marginBottom: "2px",
      textTransform: "capitalize",
      fontFamily: pdfFontFamily,
    },
    headerDoctorDegree: {
      fontSize: "7px",
      fontFamily: pdfFontFamily,
    },
    headerDoctorRegNo: {
      fontSize: "7px",
      marginTop: "4px",
      fontFamily: pdfFontFamily,
    },
    headerDoctorAddress: {
      fontSize: "7px",
      fontFamily: pdfFontFamily,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    headerDoctorConsultationTimings: {
      fontSize: "7px",
      marginTop: "4px",
      fontFamily: pdfFontFamily,
    },
    headerDoctorEmailContainer: {
      display: "flex",
      flexDirection: "row",
      marginBottom: "5px",
    },
    headerEmailIcon: {
      width: "10px",
      height: "10px",
      marginRight: "6px",
    },
    headerDoctorEmail: {
      fontSize: "7px",
      fontFamily: pdfFontFamily,
    },
    headerDoctorNumberContainer: {
      display: "flex",
      flexDirection: "row",
    },
    headerCallIcon: {
      width: "10px",
      height: "10px",
      marginRight: "6px",
    },
    headerDoctorNumber: {
      fontSize: "7px",
      fontFamily: pdfFontFamily,
    },
  });

  const [pdfHeaderData, setPdfHeaderData] = useState({});

  // Lifecycle Methods ===>

  useEffect(() => {
    if (Object.keys(erxPdfData)?.length !== 0) {
      getPdfHeaderData();
    }
  }, [erxPdfData]);

  // <===

  // Helper Methods ===>

  const getPdfHeaderData = async () => {
    let qrCodeUrl = "";
    if (erxPdfData?.doctor_profile_details?.qr_code_url) {
      qrCodeUrl = await getBase64Image(
        erxPdfData?.doctor_profile_details?.qr_code_url
      );
    }

    let [headerImg, showHeaderTemplate] = await getHeaderFooterImage(
      "header",
      erxPdfData?.clinic_id,
      erxPdfData?.doctor_hospital_details[0],
      erxPdfData?.doctor_profile_details
    );

    let headerData = await getHeaderData(
      erxPdfData?.doctor_profile_details,
      qrCodeUrl,
      headerImg,
      showHeaderTemplate
    );

    setPdfHeaderData({ ...headerData });
  };

  const getPdfHeaderHeight = () => {
    let height = "129px";
    let customMargins =
      erxPdfData?.doctor_profile_details?.custom_margin_dimensions;
    if (erxPdfData?.doctor_profile_details?.custom_margins_enabled) {
      height = (+customMargins?.rxTop).toString() + customMargins?.unit;
    }

    return height;
  };

  const getPdfHeaderBorder = () => {
    return !erxPdfData?.doctor_profile_details?.custom_margins_enabled
      ? "0.7px solid #DAE0E2"
      : "none";
  };

  const getPdfHeaderBackground = () => {
    return erxPdfData?.doctor_profile_details?.custom_margins_enabled
      ? "#fff"
      : "#DAE0E2";
  };

  const getPdfHeaderMarginLeft = () => {
    let marginLeft = "0";
    let customMargins =
      erxPdfData?.doctor_profile_details?.custom_margin_dimensions;
    if (erxPdfData?.doctor_profile_details?.custom_margins_enabled) {
      marginLeft = (+customMargins?.rxLeft).toString() + customMargins?.unit;
    }

    return marginLeft;
  };

  const getPdfHeaderMarginRight = () => {
    let marginRight = "0";
    let customMargins =
      erxPdfData?.doctor_profile_details?.custom_margin_dimensions;
    if (erxPdfData?.doctor_profile_details?.custom_margins_enabled) {
      marginRight = (+customMargins?.rxRight).toString() + customMargins?.unit;
    }

    return marginRight;
  };

  // <===

  // Display UI ===>

  const displayHeaderTemplate = () => {
    return (
      <View style={styles.headerImgCont}>
        <Image style={styles.headerImg} src={pdfHeaderData?.headerImg} />
      </View>
    );
  };

  const displayQrCode = () => {
    return (
      <View>
        <View>
          <Image src={pdfHeaderData?.qrCodeUrl} style={styles.qrCodeUrl} />
        </View>
        <View style={styles.qrCodeText}>
          <Text>To book an appointment</Text>
          <Text
            style={{
              paddingLeft: "20px",
              marginTop: "1px",
            }}
          >
            scan the code
          </Text>
        </View>
      </View>
    );
  };

  const displayDoctorClinicDetails = () => {
    return (
      <View style={styles.doctorClinicDetailsContainer}>
        <View
          style={[
            styles.doctorClinicDetailsCont,
            {
              width: pdfHeaderData?.isDrAppointmentBookingQrEnabled
                ? "86%"
                : "100%",
              paddingRight: pdfHeaderData?.isDrAppointmentBookingQrEnabled
                ? "6px"
                : "0",
            },
          ]}
        >
          {pdfHeaderData.is_header_hospital_logo_enabled &&
            pdfHeaderData.hospitalLogo !== "" && (
              <View>
                <Image
                  style={styles.doctorHospitalLogo}
                  src={pdfHeaderData.hospitalLogo}
                />
              </View>
            )}

          <View>
            {pdfHeaderData?.hospitalName !== "" && (
              <Text style={styles.headerHospitalName}>
                {pdfHeaderData?.hospitalName}
              </Text>
            )}
            {pdfHeaderData?.isDoctorNameEnabled && (
              <Text style={styles.headerDoctorName}>
                Dr. {pdfHeaderData?.doctorName}
              </Text>
            )}
            <View style={{ marginBottom: "9px" }}>
              {pdfHeaderData?.isDoctorDegreeEnabled && (
                <Text style={styles.headerDoctorDegree}>
                  {pdfHeaderData?.degree}
                </Text>
              )}
              {pdfHeaderData?.isDoctorRegistrationNumberEnabled && (
                <Text style={styles.headerDoctorRegNo}>
                  Regd No : {pdfHeaderData?.medicalRegNo}
                </Text>
              )}
            </View>
            <View style={{ marginBottom: "9px" }}>
              {pdfHeaderData?.isDoctorAddressEnabled && (
                <Text style={styles.headerDoctorAddress}>
                  <Text style={{ fontWeight: "600" }}>Address : </Text>
                  {pdfHeaderData?.doctorAddress}
                </Text>
              )}
              {pdfHeaderData?.isDoctorConsultationTimingsEnabled && (
                <Text style={styles.headerDoctorConsultationTimings}>
                  <Text style={{ fontWeight: "600" }}>
                    Consultation Timings :{" "}
                  </Text>
                  {pdfHeaderData?.doctorConsultationTimings}
                </Text>
              )}
            </View>
            {pdfHeaderData?.isDoctorRegisteredEmailEnabled && (
              <View style={styles.headerDoctorEmailContainer}>
                <Image
                  src={pdfHeaderData?.emailIcon}
                  style={styles.headerEmailIcon}
                />
                <Text style={styles.headerDoctorEmail}>
                  {pdfHeaderData?.doctorEmail}
                </Text>
              </View>
            )}
            {pdfHeaderData?.contactNos !== "" && (
              <View style={styles.headerDoctorNumberContainer}>
                <Image
                  src={pdfHeaderData?.callIcon}
                  style={styles.headerCallIcon}
                />
                <Text style={styles.headerDoctorNumber}>
                  {pdfHeaderData?.contactNos}
                </Text>
              </View>
            )}
          </View>
        </View>
        {pdfHeaderData?.isDrAppointmentBookingQrEnabled &&
          pdfHeaderData?.qrCodeUrl !== "" &&
          displayQrCode()}
      </View>
    );
  };

  // <===

  return (
    <View
      style={[
        styles.header,
        {
          height: getPdfHeaderHeight(),
          borderBottom: getPdfHeaderBorder(),
          backgroundColor: getPdfHeaderBackground(),
          marginLeft: getPdfHeaderMarginLeft(),
          marginRight: getPdfHeaderMarginRight(),
        },
      ]}
      fixed
    >
      {!erxPdfData?.doctor_profile_details?.custom_margins_enabled &&
        (pdfHeaderData?.isUploadedHeaderTemplateToBeUsed
          ? displayHeaderTemplate()
          : displayDoctorClinicDetails())}
    </View>
  );
};

export default ERXPdfHeaderView;
