import React from "react";
import "./vitalComponent.scss";

const VitalComponent = (props) => {
  const {
    item,
    placeholder,
    value,
    selectedUnit,
    unitList,
    step,
    allowDecimal,
    handleOnChange,
    handleUpArrowClick,
    handleDownArrowClick,
    disabled,
  } = props;

  // Display UI ===>

  const displayVitalComponent = () => {
    return (
      <div className="vital-component">
        <div className="vital-component-input">
          <input
            type="number"
            step={step ?? "0.1"}
            value={value}
            disabled={disabled ? disabled : false}
            placeholder={placeholder}
            onKeyDown={(e) =>
              (e.key?.toLowerCase() === "e" ||
                e.key === "+" ||
                e.key === "-" ||
                (!allowDecimal && e.key === ".")) &&
              e.preventDefault()
            }
            onChange={(e) => handleOnChange(e, item)}
          />
        </div>
        <div
          className="vital-component-unit"
          style={{ justifyContent: unitList?.length > 1 ? "" : "center" }}
        >
          <div className="vital-component-selected-unit">{selectedUnit}</div>
          {unitList?.length > 1 && (
            <div className="vital-component-arrow">
              <i
                className="fa fa-caret-up"
                onClick={() => handleUpArrowClick(item)}
              ></i>
              <i
                className="fa fa-caret-down"
                onClick={() => handleDownArrowClick(item)}
              ></i>
            </div>
          )}
        </div>
      </div>
    );
  };

  // <===

  return <>{displayVitalComponent()}</>;
};

export default VitalComponent;
