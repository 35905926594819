import React, { useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import FormControl from "@material-ui/core/FormControl";
import FilledInput from "@material-ui/core/FilledInput";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { useHistory, useLocation, useParams, Redirect } from "react-router-dom";

import swal from "sweetalert";
import afterLoginApiAxios from "../../Util/API Calls/afterLogin";

function SetPasswordFirstTime(props) {
  let { redirectUrl, phoneNumber = "", baseUrl } = props;

  let history = useHistory();
  let location = useLocation();
  const { slug } = useParams();

  const [showPassword, setShowPassword] = useState(false);
  const [passwordInput, setPasswordInput] = useState("");
  const [passwordError, setPasswordError] = useState(false);

  const handleContinueClick = (e) => {
    e.preventDefault();
    setPasswordError(false);

    if (passwordInput.length <= 5) {
      setPasswordError("Password must be at least 6 characters");
      return;
    }

    afterLoginApiAxios
      .post("patient/set-password", {
        id_token: localStorage.getItem("id_token"),
        new_password: passwordInput,
        headers: {
          Authorization: localStorage.getItem("id_token"),
        },
      })
      .then((response) => {
        if (response) {
          if (response.data && response.data.statusCode === 200) {
            swal({
              title: "Login Successful!",
              type: "success",
            }).then(function () {});

            if (
              location &&
              location.state &&
              location.state.redirectTo == false
            ) {
              return;
            }

            setTimeout(() => {
              if (localStorage.getItem("id_token")) {
                history.replace(location.state.redirectTo);
              }
            }, 100);
          } else {
            setPasswordError("Something Went Wrong!");
          }
        }
      });
  };

  if (phoneNumber == "") {
    // phoneNumber = "1234123412";
    return <Redirect to={`${baseUrl.split("/:slug")[0]}/${slug}/login/`} />;
  }

  return (
    <div
      className="container h-100  d-flex align-items-center justify-content-center flex-column"
      style={{ position: "relative" }}
    >
      <div>
        <ArrowBackIcon
          onClick={() => {
            history.replace(`${baseUrl.split("/:slug")[0]}/${slug}/login/`);
          }}
          style={{ position: "absolute", left: "0%", top: "2%" }}
          className="color-blue cursor-pointer"
        />
        <h3 className="text-center align-top">Set Password</h3>
      </div>
      <h6 className="text-center">
        Set password for easier access (minimum 6 characters required)
      </h6>
      <div className="w-100 d-flex flex-row justify-content-between mt-3">
        <div className="d-inline-block text-muted">+91-{phoneNumber}</div>
        <Edit className="color-blue cursor-pointer" baseUrl={baseUrl} />
      </div>
      <div className="w-100 mt-2">
        <form
          className="d-flex flex-column align-items-center justify-content-center"
          onSubmit={(e) => {
            handleContinueClick(e);
          }}
        >
          <FormControl
            hiddenLabel={true}
            size="small"
            margin="dense"
            fullWidth={true}
          >
            <FilledInput
              id="pwd-input"
              aria-describedby="my-helper-text"
              aria-label="Set-password"
              placeholder="Enter Password"
              disableUnderline={true}
              required
              aria-required
              type={showPassword ? "text" : "password"}
              endAdornment={
                <ShowPassword
                  setShowPassword={setShowPassword}
                  showPassword={showPassword}
                />
              }
              value={passwordInput}
              onChange={(e) => {
                setPasswordInput(e.target.value);
              }}
            />
          </FormControl>
          <div className="input-error text-center mt-1">
            {passwordError ? passwordError : ""}
          </div>
          <button className="btn primary-cta-button rounded-pill mt-2 align-self-center py-2 px-4">
            Submit
          </button>
        </form>
      </div>
      <div
        className="mt-3 textLink font-weight-bold"
        style={{ minWidth: "fit-content" }}
      >
        <div
          onClick={() => {
            history.replace(redirectUrl);
          }}
        >
          Skip for Now
        </div>
      </div>
    </div>
  );
}

export default SetPasswordFirstTime;

const Edit = ({ baseUrl }) => {
  let history = useHistory();
  const { slug } = useParams();
  return (
    <span
      className="color-blue cursor-pointer"
      onClick={() => {
        history.replace(`${baseUrl.split("/:slug")[0]}/${slug}/login/`);
      }}
    >
      Edit
      <EditIcon className="color-blue cursor-pointer" fontSize="inherit" />
    </span>
  );
};

function ShowPassword(props) {
  const { setShowPassword, showPassword = false } = props;

  if (showPassword === false) {
    return (
      <span
        className="color-blue cursor-pointer"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        <VisibilityIcon />
      </span>
    );
  } else {
    return (
      <span
        className="color-blue cursor-pointer"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        <VisibilityOffIcon />
      </span>
    );
  }
}
