import { cookiesDomainUrl, pemUrl } from "../../Util/API Calls/APIList";

const moveTo = (path, history) => {
  history.replace(`/${path}/account/dashboard`);
};

const moveToNextPage = (path, history, cookies) => {
  let lastPathBeforeLogin = cookies["lastPathBeforeLogin"];

  if (lastPathBeforeLogin) {
    if (lastPathBeforeLogin.includes("otp-login")) {
      moveTo(path, history);
    }
    if (lastPathBeforeLogin.includes("set-password")) {
      moveTo(path, history);
    }
    if (lastPathBeforeLogin.includes("patienteducation")) {
      window.location.replace(`${pemUrl}${lastPathBeforeLogin}`);
    } else {
      if (lastPathBeforeLogin.split("/")[1] === path) {
        history.push(lastPathBeforeLogin);
      } else if (lastPathBeforeLogin.split("/")[3] === path) {
        window.location.replace(`${lastPathBeforeLogin}`);
      } else {
        moveTo(path, history);
      }
    }
  } else {
    moveTo(path, history);
  }
};

const settingLastPath = (path, setCookie, removeCookie) => {
  let id_token = localStorage.getItem("id_token");
  if (
    window.location.pathname !== `/${path}` &&
    window.location.pathname !== `/${path}/login` &&
    window.location.pathname !== `/${path}/account/dashboard` &&
    window.location.pathname !== `/${path}/login/password` &&
    window.location.pathname !== `/${path}/login/enter-password` &&
    window.location.pathname !== `/${path}/login/otp-login` &&
    window.location.pathname !== `/${path}/login/enter-otp` &&
    window.location.pathname !== `/${path}/login/set-password` &&
    window.location.pathname !== `/${path}/login/sign-up` &&
    !id_token
  ) {
    setCookie("lastPathBeforeLogin", `${window.location.pathname}`, {
      path: "/",
      domain: cookiesDomainUrl,
    });
  }

  if (id_token && window.location.pathname !== `/${path}/login/set-password`) {
    removeCookie("lastPathBeforeLogin", { path: "/" });
    removeCookie("lastPathBeforeLogin", {
      path: "/",
      domain: cookiesDomainUrl,
    });
  }
};

export { moveToNextPage, settingLastPath };
