import React, { memo, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import afterLoginApiAxios from "../../Util/API Calls/afterLogin";
import { getSignedUrl } from "../../Util/API Calls/SignedAPIUrl";
import PrescriptionSharedModal from "../UI Component/Modal/PrescriptionSharedModal";
import MultipleFileSelector from "../UI Component/MultipleImageSelector/MultipleFileSelector";
import NotificationModal from "../UI Component/Notification/NotificationModal";
import { useInjectReducer } from "./../../Util/injectReducer";
import { useInjectSaga } from "./../../Util/injectSaga";
import PrescriptionPdf from "./../PrescriptionPdf";
import {
  addChiefComplaint,
  addDiagnosisItem,
  addNewDrugItem,
  addNewExamItem,
  clearDrugItemData,
  clearExamItemData,
  deletedComformation,
  deletedComformationCancel,
  deletedComformationSuccess,
  deleteFollowUpDate,
  doctorNotesUpdate,
  fetchDropDownMenu,
  hideModal,
  hidePreviewform,
  initializeAutosave,
  loadLastAutoSavedState,
  prescriptionFormPropertyUpdate,
  previousPrescription,
  getPatientVitals,
  removeDropDownMenu,
  removeDrugItem,
  removeExaminItem,
  resetState,
  saveForm,
  setAppointmentData,
  setNewDate,
  setUserToken,
  setUserType,
  shareButtonClicked,
  showModal,
  test,
  updateChiefComplaint,
  updateDiagnosisItem,
  updateDrugItemData,
  updateExamItemData,
  updatePatientData,
  prescriptionTemplate,
  getSelectedTemplate,
  fetchDrugDropdown,
  checkManualRxShared,
  updatePatientVitals,
} from "./actions";
import CustomDropdown from "./customDropdown";
import DrugItemComponent from "./drugItemComponent";
import ExamItemComponent from "./examItemComponent";
import NextFollowUpItemComponent from "./nextFollowUpItemComponent";
import PatientItemComponent from "./patientIemComponent";
import "./prescriptionComponent.scss";
import reducer from "./reducer";
import saga from "./saga";
import {
  makeSelectDropDownAuto,
  makeSelectDrugs,
  makeSelectLabFindings,
  makeselectPatient,
  makeSelectPrescription,
  getSavedPrescription,
} from "./selectors";
import { DrugsSize } from "../../Util/constants";
import ViewIcon from "../../assets/view-prescription.png";
import DocumentContainer from "../UI Component/DocumentViewer/DocumentCotainer";
import moment from "moment";
import DisabledViewIcon from "../../assets/disabled_eye.png";
import VisibilityIcon from "@material-ui/icons/Visibility";
import GetAppIcon from "@material-ui/icons/GetApp";
import ReplyIcon from "@material-ui/icons/Reply";
import axios from "axios";
import ERxList from "../ManualRX/ERxList";
import ShareManualRxModal from "../ManualRX/ShareManualRxMoadal";
import Info from "../../assets/info.png";
import RxDropdown from "./rxDropdown";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import PEMShareContentPopUp from "../PEM";
import Popup from "../PEM/common/Popup";
import PrescriptionPdfViewer from "../PrescriptionPdfViewer";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";
import { C2CConsole } from "../../Util/Helper/C2CConsole";

const key = "prescriptionComponent";

function PrescriptionComponent(props) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const appt_id = props.appointmentData.app_id.id;
  const is_staff = localStorage.getItem("user_type");

  const patient_id = props.patientId
    ? props.patientId
    : props.appointmentData.patientId
    ? props.appointmentData.patientId
    : null;
  const patientAccountId = props.patientAccountId
    ? props.patientAccountId
    : props.appointmentData.patientAccountId
    ? props.appointmentData.patientAccountId
    : null;

  const patient_dob = props.appointmentData.patient_dob;
  const patient_name = props.appointmentData.patient_name;
  const patient_phone_no = props.appointmentData.patient_phone_no;
  const doctor_id = props.appointmentData.doctor_id;
  //props
  const {
    appointmentData,
    age,
    patient,
    patientAddChiefComplaints,
    patientUpdateChiefComplaints,
    patientAddDiagnosisItem,
    patientUpdateDiagnosisItem,
    patientDetailsChangeHandler,
    patientVitalsChangeHandler,
    drugItemUpdateHandler,
    drugs,
    addNewDrugItemProp,
    clearDrugItemDataProp,
    labFindings,
    examItemUpdateHandler,
    addNewExamItemProp,
    clearExamItemDataProp,
    prescriptionFormPropertyUpdateProps,
    prescriptionDoctorNotesUpdateProps,
    dispatchSetToken,
    dispatchSetUserType,
    dispatchInitializeAutoSave,
    dispatchShareAction,
    dispatchSaveAction,
    dispatchSetAppointmentData,
    dispatchLoadLastAutoSavedState,
    dispatchStateReset,
    dispatchHidePreviewForm,
    dispatchSetNewDate,
    dispatchDeleteFollowUpDate,
    dispatchShowModal,
    dispatchHideModal,
    getSerachPopulatedData,
    dropDown,
    dropDownSelected,
    handleDeletePrec,
    handleDeletePrecCancel,
    handleDeletePrecSuccess,
    removeDrugItem,
    removeExaminItem,
    getPreviousPrescription,
    drawerType,
    prescriptionTemplateProps,
    getTemplateProp,
    getDrugDropDown,
    checkManualRxSharedProp,
    getPatientVitalsInfo,
  } = props;

  const [documentShow, setDocumentShow] = useState(false);

  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");

  const [fullView, setFullView] = useState(false);
  const [manual_rx_list, setManualRXList] = useState([]);
  const [hideAfterUpload, sethideAfterUpload] = useState(0);
  const [erx_counter, setERXCounter] = useState(0);
  const [showRxModal, setshowRxModal] = useState(false);
  const [showTooltip, setToolTip] = useState(false);
  const [templateNameValidation, setTemplateNameValidation] = useState("");
  const [templateFieldError, setTemplateFieldError] = useState(false);
  const [showPEMPopUp, setshowPEMPopUp] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);

  const userId = localStorage.getItem(LOCALSTORAGEKEY.USER_ID);
  const staffId = localStorage.getItem(LOCALSTORAGEKEY.STAFF_ID);

  useEffect(() => {
    try {
      dispatchStateReset();
      dispatchSetToken(localStorage.getItem("id_token"));
      dispatchSetUserType(localStorage.getItem("user_type"));
      dispatchSetAppointmentData(appointmentData);
      // dispatchInitializeAutoSave();
      dispatchLoadLastAutoSavedState(appointmentData.app_id.id);
      getPatientVitalsInfo(appointmentData.app_id.id);
    } catch (error) {
      C2CConsole.error(error);
    }
  }, [appointmentData]);
  useEffect(() => {
    props.prescriptionState.prescription_saved &&
      setERXCounter(erx_counter + 1);
  }, [props.prescriptionState.prescription_saved]);

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 12,
      fontFamily: `"Rubik", sans-serif`,
      margin: "0px 0px 0px 0px",
    },
  }))(Tooltip);

  //   API Call =>
  const fetchPastAppointmentData = async (props) => {
    const request_params = patient_id
      ? {
          doctor_id: appointmentData?.doctor_id
            ? appointmentData?.doctor_id
            : userId,
          patient_id:
            patient_id !== undefined
              ? patient_id
              : appointmentData.app_id.patient_id,
          patient_account_id:
            patientAccountId !== undefined
              ? patientAccountId
              : appointmentData.app_id.patient_account_id,
          patient_dob:
            patient_dob !== undefined
              ? patient_dob
              : appointmentData.app_id.patient_dob,
          patient_name:
            patient_name !== undefined
              ? patient_name
              : appointmentData.app_id.patient_name,
          patient_phone_no:
            patient_phone_no !== undefined
              ? patient_phone_no
              : appointmentData.app_id.phone_no,
          appointment_id: appointmentData?.app_id?.id,
        }
      : {
          doctor_id: appointmentData?.doctor_id
            ? appointmentData?.doctor_id
            : userId,
          rows_per_page: 10,
        };
    const response = await afterLoginApiAxios.post(
      "/doctor/get-prescription-previous-list", //new api end point
      // "/doctor/get-past-appointments",
      request_params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("id_token"),
        },
      }
    );

    if (response) {
      if (response.status === 200) {
        if (
          response?.data?.past_appointments ||
          response?.data?.past_saved_appointments
        ) {
          response?.data?.past_appointments &&
            response.data.past_appointments.forEach((appts) => {
              appts["prescription_type"] = "shared"; // adding shared key
            });
          response?.data?.past_saved_appointments &&
            response.data.past_saved_appointments.forEach((appts) => {
              appts["prescription_type"] = "saved"; // adding saved key
            });
          let all_past_appts = [];
          if (response?.data?.past_appointments) {
            all_past_appts = [
              ...all_past_appts,
              ...response.data.past_appointments,
            ];
          }
          if (response?.data?.past_saved_appointments) {
            all_past_appts = [
              ...all_past_appts,
              ...response.data.past_saved_appointments,
            ];
          }
          all_past_appts.sort((a, b) => {
            return new Date(b.appointment_date) - new Date(a.appointment_date); // sorting added templates by date
          });
          setPastAppointmentData(all_past_appts);
          setPastAppointmentMsg(response.data.message);
        } else {
          setPastAppointmentData([]);
        }
      } else {
        // TODO: Error handling and logging
        setPastAppointmentData([]);
      }
    } else {
      // TODO: Error handling and logging
    }
  };
  const fetchPastRxTemplates = async (props) => {
    const request_params = {
      doctor_id: appointmentData?.doctor_id
        ? appointmentData?.doctor_id
        : userId,
    };
    const response = await afterLoginApiAxios.post(
      "/doctor/get-template-list",
      request_params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("id_token"),
        },
      }
    );

    if (response) {
      if (response.status === 200) {
        if (response?.data?.all_saved_templates) {
          let savedTemplate = response?.data?.all_saved_templates
            ? response?.data?.all_saved_templates
            : [];
          setRxTemplates(savedTemplate);
        } else {
          setRxTemplates([]);
        }
      } else {
        // TODO: Error handling and logging
        setRxTemplates([]);
      }
    } else {
      // TODO: Error handling and logging
    }
  };
  useEffect(() => {
    fetchPastAppointmentData();
    fetchPastRxTemplates();
    getManualRx();
  }, []);

  function viewDocument({ fileURL, fileName, fileType }) {
    if (fileType && fileType.match(/pdf/gim)) {
      downloadURI(fileURL, fileName);
    } else {
      setDocumentShow(true);
      setFileUrl(fileURL);
      setFileName(fileName);
      setFileType(fileType);
    }
  }

  function closeNotification() {
    setDocumentShow(false);
    setFileUrl("");
    setFileName("");
    setFileType("");
    setFullView(false);
  }

  function downloadURI(uri, name) {
    let link = document.createElement("a");
    link.href = uri;
    link.target = "_blank";
    link.click();
  }
  const [patientDetailTabIsOpen, setPatientDetailTabIsOpen] = useState(false);
  const [showMoreVitals, setShowMoreVitals] = useState(false);
  const [pastAppointmentData, setPastAppointmentData] = useState([]);
  const [pastAppointmentMsg, setPastAppointmentMsg] = useState([]);
  const [rxTemplates, setRxTemplates] = useState([]);
  const [drugsTabIsOpen, setDrugsTabIsOpen] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [uploadPrescriptionTabIsOpen, setUploadPrescriptionTabIsOpen] =
    useState(false);
  const [prev_prescription, setprev_prescription] = useState([]);
  const [size_exceeded, setsize_exceeded] = useState(false);
  const [invalid_file, setinvalid_file] = useState(false);
  const [uploadingFile, setuploadingFile] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const [showPrescriotionResetModal, setResetModal] = useState(false);
  const [fillPrescription, setFillPrescription] = useState(false);
  const [selectFillPrescription, setSelectFillPrescription] =
    useState("savedPrescription");
  const [showCheckbox, setShowCheckbox] = useState(true);
  const [isUpdatedDataPresent, setIsUpdatedDataPresent] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [eRxCollapse, seteRxCollapse] = useState(true);

  const toggleUpload = () => {
    setUploadPrescriptionTabIsOpen(!uploadPrescriptionTabIsOpen);
  };

  const [examinationLabFindingsTabIsOpen, setExaminationLabFindingsTabIsOpen] =
    useState(false);
  const [nextfollowupTabIsOpen, setNextFollowupTabIsOpen] = useState(false);

  const fileCheckUploadManualRX = async (files) => {
    let prec = [];
    for (const file of files) {
      try {
        const signedUrl = await getSignedUrl(
          {
            file_size: file.size,
            doc_title: file.name,
            user_type: localStorage.getItem("user_type"),
            use_case: "upload-prescription-rev1",
          },
          file.fileDate
        );

        if (signedUrl) {
          if (signedUrl.statusCode) {
            prec.push({ error: signedUrl.statusCode });
            return prec;
          }
          prec.push({
            file_size: file.size,
            doc_title: file.name,
            doc_data: signedUrl.fields["key"],
            // name: file.name,
          });
        }
      } catch (error) {
        C2CConsole.error(error);
      }
    }
    if (prec.length == files.length) return prec;
  };

  const fileCheckUpload = async (files) => {
    let prec = [];
    for (const file of files) {
      try {
        const signedUrl = await getSignedUrl(
          {
            file_size: file.size,
            doc_title: file.name,
            user_type: localStorage.getItem("user_type"),
            use_case: "upload-prescription-rev1",
          },
          file.fileDate
        );

        if (signedUrl) {
          if (signedUrl.statusCode) {
            prec.push({ error: signedUrl.statusCode });
            return prec;
          }
          prec.push({
            value: signedUrl.fields["key"],
            name: file.name,
          });
        }
      } catch (error) {
        C2CConsole.error(error);
      }
    }
    if (prec.length == files.length) return prec;
  };

  async function submitPrescription() {
    if (prev_prescription) {
      setuploadingFile(true);

      const prec = await fileCheckUpload(prev_prescription);
      const manualRx = await fileCheckUploadManualRX(prev_prescription);

      let params = {};

      if (doctor_id) {
        params["doctor_id"] = doctor_id;
      } else {
        params["doctor_id"] = appointmentData?.doctor_id
          ? appointmentData?.doctor_id
          : userId;
      }
      afterLoginApiAxios
        .post(
          "doctor/upload-manual-prescription-rev1",
          {
            user_type: is_staff === "staff" ? "4" : "1",
            user_id: is_staff === "staff" ? staffId : userId,
            ...params,
            appointment_id: appt_id,
            prescriptions: manualRx,
            prescription_status:
              manual_rx_list.length > 0 || props.prescriptionState.isERxCreated
                ? "edited"
                : "created",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("id_token"),
            },
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            alert("Prescription uploaded successfully");
            sethideAfterUpload(hideAfterUpload + 1);
            setuploadingFile(false);
            getManualRx();
          } else {
            setuploadingFile(false);
          }
        })
        .catch(function (error) {
          alert("Please upload only jpeg,jpg,png,pdf or doc file");
          // setmessage("Upload Failed");
          setuploadingFile(false);
        });

      // afterLoginApiAxios
      //   .post(
      //     "doctor/upload-prescription-rev1",
      //     {
      //       appointment_id: appt_id,
      //       prescriptions: prec,
      //     },
      //     {
      //       headers: {
      //         "Content-Type": "application/json",
      //         Authorization: localStorage.getItem("id_token"),
      //       },
      //     }
      //   )
      //   .then((response) => {
      //     if (response) {
      //       let responseData = response.data;
      //       setuploadingFile(false);

      //       if (responseData) {
      //         if (response.data.hasOwnProperty("statusCode")) {
      //           if (response.data.statusCode == 200) {
      //             alert("Prescription uploaded successfully");
      //             // setmessage("Prescription uploaded successfully");
      //           } else {
      //             // setmessage(response.data.message);
      //             // setdataload(false);
      //             setuploadingFile(false);
      //           }
      //         } else {
      //           // setmessage("Upload Failed");
      //           // setresponse_message("Something Went Wrong!Please Try Again!!");
      //           // setdataload(false);
      //           setuploadingFile(false);
      //         }
      //       }
      //     }
      //   })
      //   .catch(function (error) {
      //     alert("Please upload only jpeg,jpg,png,pdf or doc file");
      //     // setmessage("Upload Failed");
      //     setuploadingFile(false);
      //   });
    } else {
      // setmessage("No file selected");
    }
  }

  const handleRemovePrefilled = () => {
    dispatchStateReset();
    dispatchSetAppointmentData(appointmentData);
    dispatchSetToken(localStorage.getItem("id_token"));
    dispatchSetUserType(localStorage.getItem("user_type"));
    setResetModal(false); //close popup
  };

  const onPrescriptionPrefillSelect = (a) => {
    setTemplateNameValidation("");
    prescriptionTemplateProps({ name: "template_name", value: "" });
    setSelectedOption(a);
    a = { ...a, saved: false }; // saved : false ==> shared presc.
    getPreviousPrescription(a);
    setPatientDetailTabIsOpen(true);
    setDrugsTabIsOpen(true);
  };

  const getManualRx = () => {
    afterLoginApiAxios
      .post(
        "doctor/get-manual-prescription-rev1",
        {
          user_type: is_staff === "staff" ? "4" : "1",
          user_id:
            is_staff === "staff"
              ? staffId
              : appointmentData?.doctor_id
              ? appointmentData?.doctor_id
              : userId,
          appointment_id: appt_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          if (response.data.data && response.data.data.length > 0) {
            setManualRXList(response.data.data);
            checkManualRxSharedProp(true);
          } else {
            setManualRXList([]);
            checkManualRxSharedProp(false);
          }
        }
      })
      .catch((err) => C2CConsole.log(err));
  };

  const downloadManualURI = async (url, name, fileType) => {
    const blob = await axios.get(url, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
      responseType: "blob",
    });
    const a = document.createElement("a");
    const href = window.URL.createObjectURL(blob.data);
    a.href = href;
    a.download = name + "." + fileType.trim();
    a.click();
  };

  const toggleERx = () => {
    seteRxCollapse(!eRxCollapse);
  };

  const ShareManualRx = (data, document_id) => {
    let params = {};

    if (appointmentData?.doctor_id || userId) {
      params["doctor_id"] = appointmentData?.doctor_id
        ? appointmentData?.doctor_id
        : userId;
    }
    afterLoginApiAxios
      .post(
        "doctor/upload-manual-prescription-rev1",
        {
          user_type: is_staff === "staff" ? "4" : "1",
          user_id: is_staff === "staff" ? staffId : userId,
          ...params,
          appointment_id: appt_id, //"1b90c17c-f956-4570-9214-1132fec31893",
          prescriptions: [data],
          document_id: document_id,
          prescription_status:
            manual_rx_list.length > 0 || props.prescriptionState.isERxCreated
              ? "edited"
              : "created",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          setshowRxModal(true);
        }
      })
      .catch((err) => C2CConsole.log(err));
  };

  const onTemplatePrefill = (a) => {
    setTemplateNameValidation("");
    prescriptionTemplateProps({ name: "template_name", value: "" });
    setSelectedOption(a);
    getTemplateProp(a);
    setPatientDetailTabIsOpen(true);
    setDrugsTabIsOpen(true);
  };

  const handleSharePrescription = () => {
    let removeDuplicate = rxTemplates.map((data) => {
      if (data.template_name?.trim()?.length > 0) {
        return data.template_name.toLowerCase()?.trim();
      }
    });
    if (removeDuplicate.includes(templateNameValidation.toLocaleLowerCase())) {
      setTemplateFieldError(true);
    } else {
      setTemplateFieldError(false);
      dispatchSaveAction();
      // setShowShareModal(true);
    }
  };

  const pemCloseBtnCallback = (closeBtnState = false) => {
    setshowPEMPopUp(false);
    if (!closeBtnState) {
      setShowPopUp(true);
    }
    props.fetchPastAppointmentDataList && props.fetchPastAppointmentDataList();
  };

  return (
    <div>
      {dropDown.isDeleteStatus && (
        <NotificationModal
          content={{
            type: "success",
            messagetype: "",
            button: true,
            title: "Delete Saved Input",
            icon: "fa-file-text",
            message: "Are you sure you want to delete this input?",
            button2: true,
            classNameButton1: "button1",
            classNameButton2: "button2",
          }}
          cancelHandler={handleDeletePrecCancel}
          successHandler={handleDeletePrecSuccess}
        />
      )}
      {!props.prescriptionState.disableUpload && (
        <div className="px-3 my-3">
          <i
            className={`fa fa-refresh ${
              props.prescriptionState.presciptionSaving
                ? "animation-rotation"
                : ""
            }`}
            aria-hidden="true"
          ></i>
          {"  "}
          Last saved less than 1 min ago
        </div>
      )}
      <div className="maintable presctiptionClass">
        <div className="container px-3 pb-4  bg-white overflow-hidden">
          {" "}
          {/*p-3*/}
          <div className="patient-data" style={{ fontSize: "12px" }}>
            <Row>
              <h3
                className="py-3 pl-3 pr-4 pb-0 mb-0 doctor-video-collapse-header" // mt-3
                onClick={toggleERx}
              >
                Write E-Prescription
                {eRxCollapse ? (
                  <i className="fa fa-angle-up"></i>
                ) : (
                  <i className="fa fa-angle-down"></i>
                )}
              </h3>
              <Collapse id="basicCollapse" in={eRxCollapse}>
                <div className={is_staff === "staff" ? "w-100" : ""}>
                  <ERxList
                    appointment_id={appt_id}
                    erx_counter={erx_counter}
                    appointmentData={appointmentData}
                    onEditCallBack={(id) => {
                      id["appointment_id"] = appt_id;
                      id["saved"] = true; //  saved:true ===> saved presc.
                      getPreviousPrescription(id); // prefill
                      getPatientVitalsInfo(appt_id); // prefill-vitals
                      setPatientDetailTabIsOpen(true);
                      setDrugsTabIsOpen(true);
                      setExaminationLabFindingsTabIsOpen(true);
                    }}
                    setERXCounter={() => setERXCounter(erx_counter + 1)}
                  />
                  {is_staff == "doctor" && (
                    <div className="rx_subheading py-1 pl-3 pr-4 pb-0 mt-3">
                      {/* Write New E-Prescription */}
                    </div>
                  )}
                  {is_staff == "doctor" && showCheckbox ? (
                    <div className="disclaimer py-1 pl-3 pr-4 pb-0">
                      <div className="row col-12 m-2">
                        {/* <input
									checked={fillPrescription}
									className="form-check-input"
									type="checkbox"
									name="prefill_details"
									id="verbalConsentCheckBox"
									onChange={(e) => {
										if (e.target.checked === true) {
											setFillPrescription(true);
											//  dispatchLoadLastAutoSavedState(appointmentData.app_id.id);
											// if (isUpdatedDataPresent) {
											//   dispatchLoadLastAutoSavedState(
											//     appointmentData.app_id.id
											//   );
											// } else {
											//   getPreviousPrescription(appointmentData.app_id.id);
											// }
										} else {
											setFillPrescription(false);
											setResetModal(true);
										}
									}}
								/> */}
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="selectprescription"
                            onChange={(e) => {
                              if (e.target.checked === true) {
                                setFillPrescription(true);
                                //  dispatchLoadLastAutoSavedState(appointmentData.app_id.id);
                                // if (isUpdatedDataPresent) {
                                //   dispatchLoadLastAutoSavedState(
                                //     appointmentData.app_id.id
                                //   );
                                // } else {
                                //   getPreviousPrescription(appointmentData.app_id.id);
                                // }
                              } else {
                                setFillPrescription(false);
                                setResetModal(true);
                              }
                            }}
                            checked={fillPrescription}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="selectprescription"
                          >
                            <span
                              style={{ fontSize: "14px", fontWeight: "bold" }}
                            >
                              {/* Prefill details from previous consultation */}
                              Prefill Rx details
                            </span>
                          </label>
                        </div>
                        {/* <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
									Prefill details from previous consultation
								</span> */}
                      </div>
                      {fillPrescription && (
                        <>
                          <div className="desktop-i-icon">
                            {/* <div
                            className="my-2"
                            style={{
                              paddingBottom: "3px",
                              paddingTop: "3px",
                              fontSize: "12px",
                            }}
                          >
                            Prescription History
                          </div> */}
                            <div
                              style={{
                                paddingBottom: "3px",
                                paddingTop: "3px",
                                fontSize: "14px",
                              }}
                            >
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="sex"
                                  id="gender_yes"
                                  checked={
                                    selectFillPrescription ==
                                    "savedPrescription"
                                      ? true
                                      : false
                                  }
                                  value={"savedPrescription"}
                                  onChange={(e) => {
                                    setSelectedOption(null);
                                    setTemplateNameValidation("");
                                    prescriptionTemplateProps({
                                      name: "template_name",
                                      value: "",
                                    });
                                    if (e.target.checked === true) {
                                      setSelectFillPrescription(
                                        "savedPrescription"
                                      );
                                    } else {
                                      setSelectFillPrescription(
                                        "prevPrescription"
                                      );
                                    }
                                  }}
                                />
                                <label
                                  className="form-check-label font-weight-bold"
                                  for="is_gender_true"
                                >
                                  Saved Rx Templates
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="sex"
                                  id="gender_no"
                                  checked={
                                    selectFillPrescription == "prevPrescription"
                                      ? true
                                      : false
                                  }
                                  value="prevPrescription"
                                  onChange={(e) => {
                                    setSelectedOption(null);
                                    setTemplateNameValidation("");
                                    prescriptionTemplateProps({
                                      name: "template_name",
                                      value: "",
                                    });
                                    if (e.target.checked === true) {
                                      setSelectFillPrescription(
                                        "prevPrescription"
                                      );
                                    } else {
                                      setSelectFillPrescription(
                                        "savedPrescription"
                                      );
                                    }
                                  }}
                                />
                                <label
                                  className="form-check-label font-weight-bold"
                                  for="is_gender_false"
                                >
                                  Past consultations
                                </label>
                              </div>
                            </div>
                            <div className="desktop-i-icon">
                              {selectFillPrescription == "prevPrescription" && (
                                <div className="save_prescription">
                                  <CustomDropdown
                                    className="w-100"
                                    defaultValue={selectedOption}
                                    options={pastAppointmentData}
                                    pastAppointmentMsg={pastAppointmentMsg}
                                    onChange={onPrescriptionPrefillSelect}
                                  />
                                </div>
                              )}
                              {selectFillPrescription ==
                                "savedPrescription" && (
                                <div className="prev_prescription">
                                  <RxDropdown
                                    className="w-100"
                                    defaultValue={selectedOption}
                                    options={rxTemplates}
                                    onChange={onTemplatePrefill}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="mob-i-icon">
                            <div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="savedPrescription"
                                  id="savedPrescription"
                                  checked={
                                    selectFillPrescription ==
                                    "savedPrescription"
                                      ? true
                                      : false
                                  }
                                  value={"savedPrescription"}
                                  onChange={(e) => {
                                    setTemplateNameValidation("");
                                    prescriptionTemplateProps({
                                      name: "template_name",
                                      value: "",
                                    });
                                    setSelectedOption(null);
                                    if (e.target.checked === true) {
                                      setSelectFillPrescription(
                                        "savedPrescription"
                                      );
                                    } else {
                                      setSelectFillPrescription(
                                        "prevPrescription"
                                      );
                                    }
                                  }}
                                />
                                <label
                                  className="form-check-label font-weight-bold"
                                  for="savedPrescription"
                                >
                                  Saved Rx Templates
                                </label>
                              </div>
                              {selectFillPrescription ==
                                "savedPrescription" && (
                                <div className="prev_prescription pb-2">
                                  <RxDropdown
                                    className="w-100"
                                    defaultValue={selectedOption}
                                    options={rxTemplates}
                                    onChange={onTemplatePrefill}
                                  />
                                </div>
                              )}
                            </div>
                            <div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="prevPrescription"
                                  id="prevPrescription"
                                  checked={
                                    selectFillPrescription == "prevPrescription"
                                      ? true
                                      : false
                                  }
                                  value="prevPrescription"
                                  onChange={(e) => {
                                    setTemplateNameValidation("");
                                    prescriptionTemplateProps({
                                      name: "template_name",
                                      value: "",
                                    });
                                    setSelectedOption(null);
                                    if (e.target.checked === true) {
                                      setSelectFillPrescription(
                                        "prevPrescription"
                                      );
                                    } else {
                                      setSelectFillPrescription(
                                        "savedPrescription"
                                      );
                                    }
                                  }}
                                />
                                <label
                                  className="form-check-label font-weight-bold"
                                  for="prevPrescription"
                                >
                                  Past consultations
                                </label>
                              </div>
                              {selectFillPrescription == "prevPrescription" && (
                                <div className="save_prescription">
                                  <CustomDropdown
                                    className="w-100"
                                    defaultValue={selectedOption}
                                    options={pastAppointmentData}
                                    pastAppointmentMsg={pastAppointmentMsg}
                                    onChange={onPrescriptionPrefillSelect}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ) : null}
                  <form>
                    {is_staff == "doctor" && (
                      <div
                        className="py-3 pl-3 pr-4 pb-0 mb-0 presciption-component-main-header"
                        onClick={() => {
                          setPatientDetailTabIsOpen(!patientDetailTabIsOpen);
                          setShowMoreVitals(false);
                        }}
                      >
                        {"PATIENT DETAILS"}
                        <i
                          style={{ color: "black" }}
                          className={
                            patientDetailTabIsOpen
                              ? "fa fa-minus icon-cog float-right"
                              : "fa fa-plus icon-cog float-right"
                          }
                        ></i>
                      </div>
                    )}
                    {is_staff == "doctor" && (
                      <Collapse in={patientDetailTabIsOpen}>
                        <div>
                          <PatientItemComponent
                            complaints={patient.chiefComplaints}
                            addComplaintItem={patientAddChiefComplaints}
                            chiefComplaintsChange={patientUpdateChiefComplaints}
                            diagnosisList={patient.diagnosis}
                            addDiagnosisItem={patientAddDiagnosisItem}
                            diagnosisChange={patientUpdateDiagnosisItem}
                            handleChange={patientDetailsChangeHandler}
                            handleVitalsChange={patientVitalsChangeHandler}
                            appointmentData={appointmentData}
                            patientGender={
                              appointmentData.app_id.patient_gender
                            }
                            patient={props.prescriptionState.patient}
                            vitals={props.prescriptionState.vitals}
                            getSerachPopulatedData={getSerachPopulatedData}
                            dropDown={dropDown}
                            dropDownSelected={dropDownSelected}
                            handleDeletePrec={handleDeletePrec}
                            moreVitalsBtnState={{
                              showMoreVitals,
                              setShowMoreVitals,
                            }}
                          />
                        </div>
                      </Collapse>
                    )}

                    {is_staff == "doctor" && <hr className="horz-line" />}
                    {is_staff == "doctor" && (
                      <>
                        <div
                          className="py-1 pl-3 pr-4 pb-0 mb-0 presciption-component-main-header"
                          onClick={() => {
                            setDrugsTabIsOpen(!drugsTabIsOpen);
                          }}
                        >
                          {"DRUGS"}
                          <i
                            style={{ color: "black" }}
                            className={
                              drugsTabIsOpen
                                ? "fa fa-minus icon-cog float-right"
                                : "fa fa-plus icon-cog float-right"
                            }
                          ></i>
                        </div>
                        <Collapse id="basicCollapse" in={drugsTabIsOpen}>
                          <div className="row b-bm m-0">
                            <div className="patient-content">
                              <ul
                                id="dynamic-list_medication"
                                name="dynamic-list_medication"
                              >
                                {drugs.length > 0
                                  ? drugs.map((drug, index) => {
                                      return (
                                        <DrugItemComponent
                                          Data={drug}
                                          index={index}
                                          key={index}
                                          handleChange={drugItemUpdateHandler}
                                          clearRecord={clearDrugItemDataProp}
                                          // getSerachPopulatedData={
                                          //   getSerachPopulatedData
                                          // }
                                          getSerachPopulatedData={
                                            getDrugDropDown
                                          }
                                          dropDown={dropDown}
                                          dropDownSelected={dropDownSelected}
                                          handleDeletePrec={handleDeletePrec}
                                          removeDrugItem={removeDrugItem}
                                          dispatchInitializeAutoSave={
                                            dispatchInitializeAutoSave
                                          }
                                        />
                                      );
                                    })
                                  : ""}
                              </ul>
                              {!(drugs?.length >= DrugsSize) && (
                                <Row className="add-button">
                                  <div
                                    onClick={() => addNewDrugItemProp()}
                                    style={{
                                      cursor: "pointer",
                                      color: "#17BAE0",
                                      border: "2px dashed #f969a4",
                                      width: "100%",
                                      boxSizing: "border-box",
                                    }}
                                    className="d-flex align-items-center justify-content-center p-2 "
                                  >
                                    <i className="fa fa-plus-circle mr-1"></i>
                                    Add New Drug
                                  </div>
                                </Row>
                              )}
                            </div>
                          </div>
                        </Collapse>

                        <hr className="horz-line" />
                        <div
                          className="py-1 pl-3 pr-4 pb-0 mb-0 presciption-component-main-header"
                          onClick={() => {
                            setExaminationLabFindingsTabIsOpen(
                              !examinationLabFindingsTabIsOpen
                            );
                          }}
                        >
                          {"EXAMINATION/LAB FINDINGS"}
                          <i
                            style={{ color: "black" }}
                            className={
                              examinationLabFindingsTabIsOpen
                                ? "fa fa-minus icon-cog float-right"
                                : "fa fa-plus icon-cog float-right"
                            }
                          ></i>
                        </div>

                        <Collapse
                          id="basicCollapse"
                          in={examinationLabFindingsTabIsOpen}
                        >
                          <div className="row b-bm m-0">
                            <div className="examination-lab-finding patient-content">
                              <ul
                                id="dynamic-list_lab_order"
                                name="dynamic-list_lab_order"
                              >
                                <div className="">
                                  {labFindings.length > 0
                                    ? labFindings.map((Data, index) => {
                                        return (
                                          <ExamItemComponent
                                            Data={Data}
                                            index={index}
                                            key={index}
                                            handleChange={examItemUpdateHandler}
                                            clearRecord={clearExamItemDataProp}
                                            getSerachPopulatedData={
                                              getSerachPopulatedData
                                            }
                                            dropDown={dropDown}
                                            dropDownSelected={dropDownSelected}
                                            handleDeletePrec={handleDeletePrec}
                                            removeExaminItem={removeExaminItem}
                                            dispatchInitializeAutoSave={
                                              dispatchInitializeAutoSave
                                            }
                                          />
                                        );
                                      })
                                    : ""}
                                </div>
                              </ul>
                              <Row className="add-button">
                                <div
                                  onClick={() => addNewExamItemProp()}
                                  style={{
                                    cursor: "pointer",
                                    color: "#17BAE0",
                                    border: "2px dashed #f969a4",
                                    width: "100%",
                                    boxSizing: "border-box",
                                  }}
                                  className="d-flex align-items-center justify-content-center p-2 "
                                >
                                  <i className="fa fa-plus-circle mr-1"></i>
                                  Add Lab Order
                                </div>
                              </Row>
                            </div>
                          </div>
                        </Collapse>
                        <hr className="horz-line" />
                        <div
                          className="py-1 pl-3 pr-4 pb-0 mb-0 presciption-component-main-header"
                          onClick={() => {
                            setNextFollowupTabIsOpen(!nextfollowupTabIsOpen);
                          }}
                        >
                          {"NEXT FOLLOW UP"}
                          <i
                            style={{ color: "black" }}
                            className={
                              nextfollowupTabIsOpen
                                ? "fa fa-minus icon-cog float-right"
                                : "fa fa-plus icon-cog float-right"
                            }
                          ></i>
                        </div>

                        <Collapse id="basicCollapse" in={nextfollowupTabIsOpen}>
                          <div className="row b-bm m-0">
                            <NextFollowUpItemComponent
                              dateChangeHandler={dispatchSetNewDate}
                              dateValue={
                                props.prescriptionState.nextFollowUpDate
                              }
                              dateDeleteHandler={dispatchDeleteFollowUpDate}
                            />
                          </div>
                        </Collapse>

                        <div className="row px-3">
                          <div className="col-md-12 mt-2">
                            <label style={{ color: "black" }}>
                              SUGGESTED INVESTIGATIONS / INSTRUCTIONS
                            </label>
                          </div>
                          <div className="col-md-12 d-flex">
                            <div className="input_container">
                              <textarea
                                style={{
                                  background: "#F5F6F7",
                                  boxShadow: "none",
                                  resize: "none",
                                }}
                                name="suggested_investigations"
                                className="form-control textarea-doct"
                                rows="2"
                                value={
                                  props.prescriptionState
                                    .suggested_investigations
                                }
                                onChange={(e) => {
                                  setShowLoader(true);
                                  prescriptionFormPropertyUpdateProps(e);
                                  setTimeout(function () {
                                    setShowLoader(false);
                                  }, 500);
                                }}
                              ></textarea>
                              {showLoader === true ? (
                                <span className="span_css">
                                  <Spinner
                                    animation="border"
                                    className="spinner_css"
                                  />
                                </span>
                              ) : null}{" "}
                              {/* style={{position:'relative',top:'10px', right:'26px'}}*/}
                            </div>
                          </div>
                        </div>

                        <div className="row px-3">
                          <div className="col-md-12 mt-3">
                            <label style={{ color: "black" }}>
                              DOCTOR NOTES
                            </label>
                          </div>
                          <div className="col-md-12 d-flex">
                            <textarea
                              style={{
                                background: "#F5F6F7",
                                boxShadow: "none",
                                resize: "none",
                              }}
                              name="doctor_notes"
                              className="form-control textarea-doct"
                              rows="2"
                              value={props.prescriptionState.doctor_notes}
                              onChange={(e) => {
                                prescriptionDoctorNotesUpdateProps(e);
                              }}
                            ></textarea>
                          </div>
                        </div>
                        <div className="mt-2 px-3">
                          <span
                            style={{
                              color: "#A3A9B7",
                              fontSize: "14px",
                              // fontWeight: "bold",
                            }}
                          >
                            These notes will not be visible to patients. You can
                            use this option to add records for your internal
                            reference.{" "}
                          </span>
                        </div>
                        <br />
                      </>
                    )}
                  </form>
                  {is_staff == "doctor" && (
                    <div className="disclaimer px-3">
                      <div className="row col-12 m-2">
                        <div className="custom-control custom-checkbox mb-1">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="saveTemplate"
                            id="saveTemplate"
                            checked={props.prescriptionState.saveTemplate}
                            onChange={(e) =>
                              prescriptionFormPropertyUpdateProps({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              })
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="saveTemplate"
                          >
                            <span
                              style={{ fontSize: "14px", fontWeight: "bold" }}
                            >
                              Save template for future prescriptions for other
                              patients.
                            </span>
                            <span className="desktop-i-icon">
                              <LightTooltip
                                // open={showTooltip}
                                // onClick={() => setToolTip(!showTooltip)}
                                // onMouseOver={() => setToolTip(true)}
                                // onMouseLeave={() => setToolTip(false)}
                                title="If you save this prescription as template, you will be able to reuse this ERx (fields - Chief Complaints, Drugs, Lab Findings and Suggested Investigations) for your future appointments with other patients as well."
                              >
                                <img src={Info} className="ml-2" alt="info" />
                              </LightTooltip>
                            </span>
                            <span className="mob-i-icon">
                              <LightTooltip
                                open={showTooltip}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setToolTip(!showTooltip);
                                }}
                                onMouseOver={() => setToolTip(true)}
                                onMouseLeave={() => setToolTip(false)}
                                title="If you save this prescription as template, you will be able to reuse this ERx (fields - Chief Complaints, Drugs, Lab Findings and Suggested Investigations) for your future appointments with other patients as well."
                              >
                                <img src={Info} className="ml-2" alt="info" />
                              </LightTooltip>
                            </span>
                            {/* <div className="d-flex"> */}
                            {/* <h4 className="mb-0 align-self-center header-title">
                    Mark Check In
                  </h4> */}

                            {/* </div> */}
                          </label>
                        </div>
                        {/* <div>
                          <small
                            data-tooltip="If you save this prescription as template, you will be able to reuse this ERx (fields like Chief Complaints, Drugs, Lab Findings and Suggested Investigations) for your future appointments with other patients as well."
                            data-tooltip-conf="multiline bottom"
                          >
                            <img src={Info} className="ml-2" alt="info" />
                          </small>
                        </div> */}
                      </div>
                      {props.prescriptionState.saveTemplate && (
                        <div className="col-12 text-left">
                          <label style={{ fontSize: "14px" }}>
                            TEMPLATE NAME
                          </label>
                          <input
                            type="text"
                            name="template_name"
                            className="w-100"
                            style={{
                              padding: "12px",
                              background: "#F5F6F7 0% 0% no-repeat padding-box",
                              borderRadius: " 5px",
                              border: "none",
                            }}
                            onChange={(e) => {
                              setTemplateNameValidation(e.target.value);
                              let obj = {
                                name: e.target.name,
                                value: e.target.value,
                              };
                              prescriptionTemplateProps(obj); //prescriptionTemplateProps(e);
                            }}
                            value={props.prescriptionState.template_name}
                          />
                          {templateFieldError && (
                            <p className="pt-1" style={{ color: "#dc3545" }}>
                              Template with same name already exists.
                            </p>
                          )}
                        </div>
                      )}
                      {is_staff == "doctor" && <hr className="horz-line" />}
                    </div>
                  )}
                  {is_staff == "doctor" && (
                    <div className="disclaimer px-3">
                      <div className="row col-12 m-2">
                        {/* <input
									className="form-check-input"
									type="checkbox"
									name="verbalConsentCheckBox"
									id="verbalConsentCheckBox"
									checked={props.prescriptionState.verbalConsentCheckBox}
									onChange={(e) =>
										prescriptionFormPropertyUpdateProps({
											target: {
												name: e.target.name,
												value: e.target.checked
											}
										})
									}
								/>
								<span style={{ fontSize: '14px', fontWeight: 'bold' }}>
									<span className={age < 18 ? '' : 'd-none'}>
										I confirm I have verified the identity proof and relationship of care giver
										during the video consult.
									</span>
									<br className={age < 18 ? '' : 'd-none'} />I have received the verbal consent of the
									patient for doing this tele consult.
								</span> */}
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="verbalConsentCheckBox"
                            id="verbalConsentCheckBox"
                            checked={
                              props.prescriptionState.verbalConsentCheckBox
                            }
                            onChange={(e) =>
                              prescriptionFormPropertyUpdateProps({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              })
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="verbalConsentCheckBox"
                          >
                            <span
                              style={{ fontSize: "14px", fontWeight: "bold" }}
                            >
                              <span className={age < 18 ? "" : "d-none"}>
                                I confirm I have verified the identity proof and
                                relationship of care giver during the video
                                consult.
                              </span>
                              <br className={age < 18 ? "" : "d-none"} />I have
                              received the verbal consent of the patient for
                              doing this tele consult.
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                  {is_staff == "doctor" && (
                    <>
                      {" "}
                      <Row className="px-3">
                        <button
                          className="rounded-pill secondary-button py-2 mb-2"
                          variant="outline-primary"
                          block
                          style={{
                            width: "100%",
                            fontSize: "1rem",
                            margin: "0px 15px",
                          }}
                          onClick={() => {
                            handleSharePrescription();
                            // dispatchSaveAction();
                          }}
                          disabled={props.prescriptionState.presciptionSaving}
                        >
                          {props.prescriptionState.presciptionSaving ? (
                            <Spinner animation="border" />
                          ) : (
                            "Save and Preview Prescription"
                          )}
                        </button>
                      </Row>
                      <Row className="px-3">
                        <button
                          className="rounded-pill primary-cta-button py-2"
                          variant="primary"
                          block
                          style={{
                            width: "100%",
                            fontSize: "1rem",
                            margin: "0px 15px",
                          }}
                          onClick={
                            // handleSharePrescription
                            () => setShowShareModal(true)
                          }
                          disabled={props.prescriptionState.disableUpload}
                          // disabled={props.prescriptionState.disableUpload}
                        >
                          Share Prescription with Patient
                        </button>
                      </Row>
                    </>
                  )}
                </div>
              </Collapse>
            </Row>

            <Row>
              <h3
                className="py-3 pl-3 pr-4 pb-0 mb-0 mt-3 doctor-video-collapse-header"
                onClick={toggleUpload} //toggle
                // this.setState((prevProps) => ({{() => isPanelOpen = !isPanelOpen }
                //   uploadPrescriptionTabIsOpen: !prevProps.uploadPrescriptionTabIsOpen,
                // }))
                // }
              >
                Upload Manual Prescription
                {uploadPrescriptionTabIsOpen ? (
                  <i className="fa fa-angle-up"></i>
                ) : (
                  <i className="fa fa-angle-down"></i>
                )}
              </h3>

              <Collapse id="basicCollapse2" in={uploadPrescriptionTabIsOpen}>
                <div className="w-100 prescription-upload-container py-3  top-border-none rx_border">
                  {" "}
                  {/**border-1 */}
                  <div className="container">
                    <h6>
                      {/* Upload Prescription&nbsp;<small>(Optional)</small> */}
                      UPLOAD HERE
                    </h6>
                    <MultipleFileSelector
                      showWarning
                      maxFileSize={20}
                      single={is_staff == "staff" ? true : false}
                      selectedImages={(imageArray) => {
                        setprev_prescription(imageArray);
                      }}
                      hideAfterUpload={hideAfterUpload}
                    />
                    <br />
                    {prev_prescription ? (
                      <button
                        disabled={
                          size_exceeded || invalid_file || uploadingFile
                        }
                        className="btn btn-lg prescription-save-button mt-3"
                        style={{ backgroundColor: "#17BAE0", color: "#fff" }}
                        onClick={() => submitPrescription()}
                      >
                        {uploadingFile ? (
                          <b>
                            <i
                              className="fa fa-spinner fa-spin"
                              style={{ color: "white" }}
                              aria-hidden="true"
                            ></i>
                          </b>
                        ) : (
                          <span>Upload</span>
                        )}
                      </button>
                    ) : null}
                    <br />
                  </div>
                  {manual_rx_list.length > 0 ? (
                    <div className="mt-3">
                      <div className="m_rx_heading">
                        <p className="px-3 py-1">Shared Files</p>
                      </div>
                      <div className="container">
                        {manual_rx_list.length > 0
                          ? manual_rx_list.map((result, index) => {
                              const lastDot =
                                result.document_title.lastIndexOf(".");
                              const fileName =
                                result.document_title.substring(0, lastDot) ||
                                "Medical Record" + index;
                              const fileType =
                                result.document_title.substring(lastDot + 1) ||
                                "";
                              const staffName = result?.staff_name || "";
                              return (
                                <div
                                  className={`row py-2 ${
                                    manual_rx_list.length === 1 ||
                                    manual_rx_list.length - 1 === index
                                      ? ""
                                      : "m-rx-border-b"
                                  }`}
                                >
                                  <div className="col-7">
                                    <p className="m_rx_title">{fileName}</p>
                                    <p className="m_rx_details">
                                      {moment(result.created_at).format(
                                        "DD MMM, YYYY "
                                      )}
                                      |{" "}
                                      {moment(
                                        result.created_at.split("+")[0]
                                      ).format(" hh:mm A")}
                                    </p>
                                    {/* <p className="m_rx_details">PDF . 2.4MB</p> */}
                                    <div className="medical-record-type">
                                      {fileType && fileType.toUpperCase()}{" "}
                                      <div className="bullet ml-1 mr-1 mt-2" />{" "}
                                      {result.file_size}MB
                                    </div>
                                    {staffName !== "" && (
                                      <div
                                        className="medical-record-type"
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        Shared by Staff-{staffName}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-5 text-center mt-2">
                                    {" "}
                                    {/**style={{display: "flex"}} */}
                                    {fileType.toLowerCase().includes("pdf") ||
                                    fileType.toLowerCase().includes("jpg") ||
                                    fileType.toLowerCase().includes("png") ||
                                    fileType.toLowerCase().includes("jpeg") ? (
                                      <>
                                        {/* <button
                                          className="rx_icons_btn"
                                          onClick={() => {
                                            setFileName(fileName);
                                            setFileType(fileType);
                                            setFileUrl(
                                              result.presigned_url_view
                                            );
                                            setDocumentShow(true);
                                          }}
                                        >
                                          <VisibilityIcon fontSize="small" />
                                        </button>
                                        <button
                                          className="rx_icons_btn"
                                          onClick={() =>
                                            downloadManualURI(
                                              result.presigned_url,
                                              fileName,
                                              fileType
                                            )
                                          }
                                        >
                                          <GetAppIcon fontSize="small" />
                                        </button> */}
                                        <span
                                          data-tooltip="View"
                                          data-tooltip-conf="bottom"
                                        >
                                          <i
                                            className=" medical-record-icon m-1"
                                            onClick={() => {
                                              setFileName(fileName);
                                              setFileType(fileType);
                                              setFileUrl(
                                                result.presigned_url_view
                                              );
                                              setDocumentShow(true);
                                            }}
                                          >
                                            <VisibilityIcon fontSize="small" />
                                          </i>
                                        </span>
                                        <span
                                          data-tooltip="Download"
                                          data-tooltip-conf="bottom"
                                        >
                                          {/* <a target="_blank" href={result.presigned_url_view}> */}
                                          <i
                                            className=" medical-record-icon m-1"
                                            onClick={() =>
                                              downloadManualURI(
                                                result.presigned_url,
                                                fileName,
                                                fileType
                                              )
                                            }
                                          >
                                            <GetAppIcon fontSize="small" />
                                          </i>
                                          {/* </a> */}
                                        </span>
                                        <span
                                          data-tooltip="Share"
                                          data-tooltip-conf="bottom"
                                        >
                                          <i
                                            className=" medical-record-icon m-1"
                                            onClick={() => {
                                              ShareManualRx(
                                                {
                                                  doc_data:
                                                    result.document_path,
                                                  file_size: result.file_size,
                                                  doc_title:
                                                    result.document_title,
                                                },
                                                result.document_id
                                              );
                                            }}
                                          >
                                            <ReplyIcon
                                              fontSize="small"
                                              style={{
                                                transform: "scaleX(-1)",
                                              }}
                                            />
                                          </i>
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        {/* <button
                                          className="rx_icons_btn"
                                          disabled
                                        >
                                          <VisibilityIcon fontSize="small" />
                                        </button> */}
                                        {/* <button
                                          className="rx_icons_btn"
                                          onClick={() =>
                                            downloadManualURI(
                                              result.presigned_url,
                                              fileName,
                                              fileType
                                            )
                                          }
                                        >
                                          <GetAppIcon fontSize="small" />
                                        </button> */}
                                        <span
                                          data-tooltip="View"
                                          data-tooltip-conf="bottom"
                                        >
                                          <i
                                            className="  medical-record-icon-disabled m-1"
                                            //view-disable-icon
                                          >
                                            <VisibilityIcon fontSize="small" />
                                          </i>
                                        </span>
                                        <span
                                          data-tooltip="Download"
                                          data-tooltip-conf="bottom"
                                        >
                                          {/* <a target="_blank" href={result.presigned_url_view}> */}
                                          <i
                                            className=" medical-record-icon m-1"
                                            onClick={() =>
                                              downloadManualURI(
                                                result.presigned_url,
                                                fileName,
                                                fileType
                                              )
                                            }
                                          >
                                            <GetAppIcon fontSize="small" />
                                          </i>
                                          {/* </a> */}
                                        </span>
                                        <span
                                          data-tooltip="Share"
                                          data-tooltip-conf="bottom"
                                        >
                                          <i
                                            className=" medical-record-icon m-1"
                                            onClick={() => {
                                              ShareManualRx(
                                                {
                                                  doc_data:
                                                    result.document_path,
                                                  file_size: result.file_size,
                                                  doc_title:
                                                    result.document_title,
                                                },
                                                result.document_id
                                              );
                                            }}
                                          >
                                            <ReplyIcon
                                              fontSize="small"
                                              style={{
                                                transform: "scaleX(-1)",
                                              }}
                                            />
                                          </i>
                                        </span>
                                      </>
                                    )}
                                  </div>
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Collapse>
            </Row>
            {drawerType === "Edit Prescription" ? (
              <div className="document-collapse-padding"></div>
            ) : null}
          </div>
        </div>
      </div>

      {props.prescriptionState.prescriptionUploaded ? (
        <PrescriptionPdfViewer
          appointment_id={props.prescriptionState.appointmentData.app_id.id}
          prescription_id={props.prescriptionState.patient.prescription_id}
          from_dr_video={false}
          checked={props.prescriptionState.verbalConsentCheckBox}
          dispatchHidePreviewForm={dispatchHidePreviewForm}
          setDrowerPostionInitianlView={props.setDrowerPostionInitianlView}
          isShared={false}
          preview_from="prescription"
        />
      ) : null}
      {showPEMPopUp ? (
        <PEMShareContentPopUp
          patientId={patient_id}
          pemCloseBtnCallback={pemCloseBtnCallback}
          pemSuggestedKeyFromPrescription={
            props?.prescriptionState?.pemSuggestedKey
          }
        />
      ) : (
        ""
      )}

      {showShareModal ? (
        <div>
          <PrescriptionPdfViewer
            appointment_id={appt_id}
            prescription_id={props.prescriptionState.patient.prescription_id}
            from_dr_video={false}
            setShowShareModal={setShowShareModal}
            setDrowerPostionInitianlView={props.setDrowerPostionInitianlView}
            isShared={true}
            preview_from="prescription"
          />
          <Row className="justify-content-center bottom-action-row">
            <Button
              className="rounded-pill c2c-secondary-button"
              onClick={() => {
                setShowShareModal(false);
              }}
            >
              {drawerType ? drawerType : "Edit Prescription"}
            </Button>
            <Button
              className="rounded-pill c2c-primary-button"
              onClick={() => {
                dispatchShareAction();
              }}
              disabled={props.prescriptionState.prescriptionSharing}
            >
              {props.prescriptionState.prescriptionSharing ? (
                <Spinner animation="border" />
              ) : (
                "Approve and Share"
              )}
            </Button>
          </Row>
        </div>
      ) : null}
      <PrescriptionSharedModal
        appointment_id={appt_id}
        prescription_id={props.prescriptionState.patient.prescription_id}
        show={props.prescriptionState.showPrescriptionShareModal}
        onHide={() => {
          dispatchHideModal();
          setERXCounter(erx_counter + 1);
          setShowShareModal(false);
        }}
        okayClickHandler={() => {
          dispatchHideModal();
          setERXCounter(erx_counter + 1);
          setShowShareModal(false);
          fetchPastRxTemplates();
          // props.fetchappointment();
          props.fetchPastAppointmentDataList &&
            props.fetchPastAppointmentDataList();
        }}
        sharePEMClickHandler={async () => {
          dispatchHideModal();
          setERXCounter(erx_counter + 1);
          setShowShareModal(false);
          fetchPastRxTemplates();
          setshowPEMPopUp(true);
        }}
      />
      {showPrescriotionResetModal ? (
        <Modal show={true} style={{ zIndex: "9999999" }}>
          <Modal.Body>
            <div
              className="text-center"
              style={{ padding: "30px", maxWidth: "490px" }}
            >
              <div style={{ textAlign: "center" }}>
                <i
                  class="fa fa-exclamation-circle fa-5x"
                  style={{ color: "rgb(220, 53, 69)" }}
                ></i>
              </div>
              {/* <div className="col-sm-12 col-lg-12 text-center pt-5"> */}
              <h5 style={{ padding: "10px 0px" }}>
                <strong>Prescription changes will be lost</strong>
              </h5>
              <p>
                By unchecking, all the prescription changes will be lost. Are
                you sure you want to continue?
              </p>
              {/* </div> */}
              <div className="container">
                <div
                  style={{
                    marginTop: "30px",
                  }}
                >
                  <Button
                    className="btn btn-lg border-0 mb-md-2 p-2 border rounded-pill coupon-confirm-button"
                    style={{
                      backgroundColor: "#17BAE0",
                      color: "#fff",
                      margin: "0px 10px",
                    }}
                    onClick={handleRemovePrefilled}
                  >
                    Yes
                  </Button>
                  <Button
                    className="btn btn-lg border mb-md-2   rounded-pill coupon-confirm-button"
                    style={{
                      backgroundColor: "#fff",
                      color: "#17BAE0",
                      margin: "0px 10px",
                    }}
                    onClick={() => {
                      setFillPrescription(true);
                      setResetModal(false);
                    }}
                  >
                    No
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}

      {documentShow && (
        <DocumentContainer
          cancelHandler={closeNotification}
          fullViewHandler={() => {
            setFullView(!fullView);
          }}
          url={fileUrl}
          fileName={fileName}
          fileType={fileType}
          // width={width || ""}
        />
      )}
      <ShareManualRxModal
        show={showRxModal}
        handleClose={() => {
          setshowRxModal(false);
        }}
      />
      <Popup
        title={"Patient Education Content Shared!"}
        show={showPopUp}
        handleClose={() => setShowPopUp(false)}
      />
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  prescriptionState: makeSelectPrescription(),
  patient: makeselectPatient(),
  drugs: makeSelectDrugs(),
  labFindings: makeSelectLabFindings(),
  dropDown: makeSelectDropDownAuto(),
});

export function mapDispatchToProps(dispatch) {
  return {
    testActionHandler: () => dispatch(test()),
    patientAddChiefComplaints: () => dispatch(addChiefComplaint()),
    patientUpdateChiefComplaints: (value, index) =>
      dispatch(updateChiefComplaint(value, index)),
    patientAddDiagnosisItem: () => dispatch(addDiagnosisItem()),
    patientUpdateDiagnosisItem: (value, index) =>
      dispatch(updateDiagnosisItem(value, index)),
    patientDetailsChangeHandler: (value, index) =>
      dispatch(updatePatientData(value, index)),
    patientVitalsChangeHandler: (value, name, unit) =>
      dispatch(updatePatientVitals(value, name, unit)),
    drugItemUpdateHandler: (event, handler, drug_id) => {
      dispatch(updateDrugItemData(event, handler, drug_id));
    },
    addNewDrugItemProp: () => dispatch(addNewDrugItem()),
    clearDrugItemDataProp: (index) => dispatch(clearDrugItemData(index)),
    examItemUpdateHandler: (event, handler) =>
      dispatch(updateExamItemData(event, handler)),
    addNewExamItemProp: () => dispatch(addNewExamItem()),
    clearExamItemDataProp: (index) => dispatch(clearExamItemData(index)),
    prescriptionFormPropertyUpdateProps: (event, handler) =>
      dispatch(prescriptionFormPropertyUpdate(event, handler)),
    prescriptionDoctorNotesUpdateProps: (event, handler) =>
      dispatch(doctorNotesUpdate(event, handler)),
    dispatchShareAction: () => dispatch(shareButtonClicked()),
    dispatchSetToken: (token) => dispatch(setUserToken(token)),
    dispatchSetAppointmentData: (app_data) =>
      dispatch(setAppointmentData(app_data)),
    dispatchSetUserType: (type) => dispatch(setUserType(type)),
    dispatchSaveAction: () => dispatch(saveForm()),
    dispatchInitializeAutoSave: () => dispatch(initializeAutosave()),
    dispatchLoadLastAutoSavedState: (id) =>
      dispatch(loadLastAutoSavedState(id)),
    dispatchStateReset: () => dispatch(resetState()),
    dispatchHidePreviewForm: () => dispatch(hidePreviewform()),
    dispatchSetNewDate: (value) => dispatch(setNewDate(value)),
    dispatchDeleteFollowUpDate: () => dispatch(deleteFollowUpDate()),
    dispatchShowModal: () => dispatch(showModal()),
    dispatchHideModal: () => dispatch(hideModal()),
    getSerachPopulatedData: (value, isShowDropDown) =>
      dispatch(fetchDropDownMenu(value, isShowDropDown)),
    dropDownSelected: () => dispatch(removeDropDownMenu()),
    handleDeletePrec: (value) => dispatch(deletedComformation(value)),
    handleDeletePrecCancel: (value) => dispatch(deletedComformationCancel()),
    handleDeletePrecSuccess: (value) => dispatch(deletedComformationSuccess()),
    removeDrugItem: (index) => dispatch(removeDrugItem(index)),
    removeExaminItem: (index) => dispatch(removeExaminItem(index)),
    getPreviousPrescription: (id) => dispatch(previousPrescription(id)),
    getPatientVitalsInfo: (id) => dispatch(getPatientVitals(id)),
    prescriptionTemplateProps: (event, handler) =>
      dispatch(prescriptionTemplate(event, handler)),
    getTemplateProp: (data) => dispatch(getSelectedTemplate(data)),
    getDrugDropDown: (value, isShowDropDown) =>
      dispatch(fetchDrugDropdown(value, isShowDropDown)),
    checkManualRxSharedProp: (data) => dispatch(checkManualRxShared(data)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(PrescriptionComponent);
