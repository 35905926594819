import React, { useEffect, useState } from "react";
import "../globalStyles/prescriptionpdf.css";
import "../globalStyles/login.css";
import afterLoginApiAxios from "../Util/API Calls/afterLogin";
import swal from "sweetalert";
import Loader from "./UI Component/Loader/Loader";
import moment from "moment";
import PdfContainer from "./UI Component/DocumentViewer/PdfContainer";

const PrescriptionPdfViewer = (props) => {
  const {
    disablePreview,
    pdfLangCode,
    handleDisablePreview,
    callSuggestedListAPI,
  } = props;

  const appointment_id = props?.appointment_id;
  const [loader, setLoader] = useState(false);
  const [fileDetails, setFileDetails] = useState({
    fileURL: "",
    fileName: "Prescription",
    fileType: ".pdf",
  });
  const [documentShow, setDocumentShow] = useState(false);

  const getPrescriptionPreview = () => {
    !disablePreview && setLoader(true);
    let params = {
      appointment_id: appointment_id,
      prescription_id: props?.prescription_id,
      "authorizer-principal-id": {
        user_id: localStorage.getItem("id_token"),
        user_type: "2",
        agent: "web",
      },
      is_shared: props?.isShared,
      includeInErx: {
        Vitals: true,
        PatientExamination: true,
        PatientHistory: true,
        DrugMedicine: true,
        VaccineChart: true,
        Diagnosis: true,
        "PatientFollow-up": true,
        ...props?.includeErx,
      },
    };
    if (pdfLangCode) {
      params.langCode = pdfLangCode;
    }
    afterLoginApiAxios
      .post("/api/v1/user/prescription/pdf", params, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("id_token"),
        },
      })
      .then((response) => {
        setLoader(false);
        if (response?.data?.statusCode === 200) {
          !disablePreview && openPdfViewer(response?.data?.url);
          handleDisablePreview && handleDisablePreview();
          callSuggestedListAPI && callSuggestedListAPI();
        } else {
          setLoader(false);
          swal({
            title: "Sorry",
            text: "Prescription could not be downloaded",
            icon: "warning",
          });
        }
      });
  };

  const openPdfViewer = (url) => {
    setLoader(true);
    const presDate = moment().format("DD MMM YYYY");
    fileDetails.fileURL = url;
    fileDetails.fileName = `Prescription_${presDate}`;
    setFileDetails(fileDetails);
    setLoader(false);
    setDocumentShow(true);
  };

  useEffect(() => {
    if (props?.prescription_id) {
      getPrescriptionPreview();
    }
  }, [props?.prescription_id]);

  const renderDocument = (width) => {
    if (documentShow) {
      return (
        <div className="pdf_popup">
          <PdfContainer
            cancelHandler={() => closeNotification()}
            url={fileDetails?.fileURL}
            fileName={fileDetails?.fileName}
            fileType={fileDetails?.fileType}
            width={width || ""}
            preview_from={props?.preview_from}
          />
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const closeNotification = () => {
    setDocumentShow(false);
    props.setDrowerPostionInitianlView(false);
    if (props?.setShowShareModal) {
      props.setShowShareModal(false);
    }
    if (props?.dispatchHidePreviewForm) {
      props.dispatchHidePreviewForm();
    }
    setFileDetails({
      fileName: "",
      fileURL: "",
      fileType: "",
    });
  };
  return (
    <>
      {loader ? <Loader /> : null}
      {renderDocument("98%")}
    </>
  );
};

export default PrescriptionPdfViewer;
