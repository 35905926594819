import axios from "axios";
import swal from "sweetalert";
import { LOCALENV } from "../../components/common/config/index";
import { deleteDB } from "../common/dexieDB";

let apiMapUrl = {
  dev: "https://pem-api-dev.c2cassets.com/",
  qa: "https://pem-api-qa.c2cassets.com/",
  uat: "https://pem-api-uat.c2cassets.com/",
  prod: "https://pem-api.connect2clinic.com/",
  stage: "https://pem-api-stage.connect2clinic.com/",
  maint: "https://pem-api-maint.c2cassets.com/",
};

const api =
  window.location.hostname === "localhost"
    ? LOCALENV
    : process.env.REACT_APP_ENV;
const afterLoginApiAxiosPEM = axios.create({
  baseURL: apiMapUrl[api],
  headers: {
    "X-Application-Source": "web",
  },
});

// Add a request interceptor
afterLoginApiAxiosPEM.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.headers.authorization = localStorage.getItem("id_token");
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

afterLoginApiAxiosPEM.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response.status === 403) {
      swal({
        icon: "error",
        type: "error",
        title: "Your session got expired please login again",
        timer: 10000,
      })
        .then(function () {
          deleteDB();
          window.localStorage.clear();

          if (
            window.location.pathname.split("/")[1] === "doctor" ||
            window.location.pathname.split("/")[1] === "patient"
          ) {
            window.location.replace(
              `/${window.location.pathname.split("/")[1]}/login`
            );
          } else {
            window.location.replace(`/`);
          }
        })
        .catch(function () {
          deleteDB();
          window.localStorage.clear();
          window.location.replace(`/`);
        });
    }
  }
);

export default afterLoginApiAxiosPEM;
