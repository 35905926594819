import React from "react";
import Modal from "react-bootstrap/Modal";
import WarningIcon from "../../assets/warning.png";

function TimeSlotNotAvailableModal({ linkExpired, setLinkExpired }) {
  return (
    <Modal
      show={linkExpired}
      onHide={() => setLinkExpired()}
      size="lg"
      backdrop="static"
      keyboard={false}
      centered
      className="link-expired-modal"
    >
      <Modal.Body className="py-5 px-4">
        <img
          src={WarningIcon}
          alt="warning"
          className="mx-auto d-block"
          style={{ height: "77px", width: "77px" }}
        />
        <div className="text-center mt-2 link-expired-modal-head">
          Link Expired
        </div>
        <div className="text-center my-2 link-expired-modal-body">
          The consultation link has expired. Please connect with your doctor to
          request a new link.
        </div>
        <div className="text-center mt-3">
          <button
            className="payment-proceed-btn py-2 px-5"
            onClick={() => setLinkExpired()}
          >
            Okay
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default TimeSlotNotAvailableModal;
