import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import qs from "querystring";
import Clock from "react-live-clock";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "./recevier.scss";
import { useForm, FormProvider } from "react-hook-form";
import {
  useReceiverLogin,
  useVerifyReceiverOtp,
} from "../../Hooks/useReceiver";
import LoginOTPModal from "./loginOTPModal";
import VerifyOTPModal from "./verifyOTPModal";
import VideoChat from "./videoChat";
import FileSelector from "../UI Component/FileSelector/FileSelector";
import { getSignedUrl } from "../../Util/API Calls/ReceiverSignedAPIUrl";
import querystring from "querystring";
import afterLoginApiAxios from "../../Util/API Calls/afterLogin";
import swal from "sweetalert";
import { da } from "date-fns/locale";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";

const ReceiverVideo = (props) => {
  const formValidate = yup.object().shape({
    OTP: yup
      .string()
      .required("OTP is required")
      .min(6, "OTP must be atleast 6 digit")
      .max(6, "OTP cannot be more than 6 digit"),
  });
  const verifyOTPForm = useForm({
    resolver: yupResolver(formValidate),
  });
  const receiverLogin = useReceiverLogin();
  const receiverVerifyOtp = useVerifyReceiverOtp();
  const location = useLocation();
  const otp = useForm();
  const verifyOtp = useForm();
  const [receiverId, setReceiverId] = useState("");
  const [receiverPhone, setReceiverPhone] = useState("");
  const [appointmentId, setAppointmentId] = useState(
    location.search.split("=")[1]
  );
  const [showLoginModal, setShowLoginModal] = useState(
    localStorage.getItem("authorization_token") ? false : true
  );
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [file, setFile] = useState([]);

  const handleShowLoginPopUp = () => {
    setShowOTPModal(false);
    setShowLoginModal(true);
  };

  const handleOtpSubmit = (payload) => {
    receiverLogin.mutate(payload, {
      onSuccess: (response) => {
        localStorage.setItem("receiver_id", response.receiver_id);
        setReceiverPhone(payload.receiver_phone_no);
        setReceiverId(response.receiver_id);
        setShowLoginModal(false);
        setShowOTPModal(true);
      },
    });
  };

  const handleVerifyOtp = (payload) => {
    receiverVerifyOtp.mutate(payload, {
      onSuccess: (response) => {
        if (response.statusCode === 510) {
          verifyOTPForm.setError("OTP", {
            type: "invalid_otp",
            message: response.message,
          });
        }
        localStorage.setItem(
          "authorization_token",
          response.user.authorization_token
        );
        setShowOTPModal(false);
        window.location.reload();
      },
    });
  };

  const handleResendOtp = (value) => {
    handleOtpSubmit(value);
  };

  setInterval(() => {
    // verifyStatus();
  }, 5000);

  const verifyStatus = async () => {
    let payload = {
      appointment_id: appointmentId,
      user_type: "receiver",
    };
    let { data, status } = await afterLoginApiAxios.post(
      "public/verify-shared-link",
      payload
    );
    if (data.statusCode !== 200) {
      localStorage.clear();
      props.history.push("/");
    }
  };

  const submitPrescription = async (image) => {
    if (image && image.value) {
      let signedUrl = await getSignedUrl(
        {
          file_size: image.size,
          doc_title: image.name,
          user_type: "receiver",
          use_case: "upload-document-receiver",
        },
        image.fileDate
      );
      if (!signedUrl) {
        alert("Please upload only jpeg,jpg,png,pdf or doc file");
      }
      afterLoginApiAxios
        .post(
          "/receiver/receiver-upload-document",
          {
            "authorizer-principal-id": {
              user_type: "receiver",
            },
            doc_title: image.name,
            file_size: image.size,
            doc_data: signedUrl.fields["key"],
            appointment_id: location.search.split("=")[1],
            receiver_id: localStorage.getItem("receiver_id"),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("authorization_token"),
            },
          }
        )
        .then((response) => {
          if (response) {
            var responseData = response.data;
            if (responseData) {
              if (response.data.hasOwnProperty("statusCode")) {
                if (response.data.statusCode == 200) {
                  swal({
                    text: response.data.message,
                    icon: "success",
                    type: "success",
                  });
                  let newFile = {
                    ...image,
                    date: new Date(),
                    document_id:
                      (responseData.response &&
                        responseData.response.document_id) ||
                      "",
                  };
                  setFile([...file, newFile]);
                } else {
                  swal({
                    title: "Upload Failed",
                    text: response.data.message,
                    type: "error",
                  });
                }
              } else {
                swal({
                  title: "Upload Failed",
                  text: "Something Went Wrong!Please Try Again!!",
                  type: "error",
                });
              }
            }
          }
        })
        .catch(function (error) {
          alert("Please upload only jpeg,jpg,png,pdf or doc file");
        });
    } else {
      alert("No file selected");
    }
  };

  const imageSelectHandler = (image) => {
    if (image && image.value) {
      submitPrescription(image);
    }
  };

  const base64ToArrayBuffer = (base64) => {
    var binaryString = atob(base64.split(",")[1]);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };

  const saveByteArray = (reportName, byte, fileType) => {
    var blob = new Blob([byte], { type: fileType });
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  };

  const downloadUploadFile = (file) => {
    var sampleArr = base64ToArrayBuffer(file.value);
    saveByteArray(file.fileName, sampleArr, file.fileType);
  };

  const removePrescription = (document_id) => {
    afterLoginApiAxios
      .post(
        "receiver/delete-document",
        {
          document_id: document_id,
          user_type: "receiver",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("authorization_token"),
          },
        }
      )
      .then((response) => {
        if (response) {
          var responseData = response.data;
          if (responseData) {
            if (response.data.hasOwnProperty("statusCode")) {
              if (response.data.statusCode == 200) {
                setFile(
                  file.filter((item) => item.document_id !== document_id) || []
                );
                swal({
                  title: "Deleted Successfully",
                  text: "File deleted successfully",
                  type: "success",
                });
              } else {
                swal({
                  title: "Deleted Failed",
                  text: response.data.message || "File not Deleted",
                  type: "success",
                });
              }
            } else {
              swal({
                title: "Deletion Failed",
                text: "Something Went Wrong!Please Try Again!!",
                type: "error",
              });
            }
          }
        }
      })
      .catch(function (error) {
        alert("Deleted Failed! Please try again!!");
      });
  };

  return (
    <>
      <header>
        <div className="container-fluid">
          <div className="row">
            <div className="col-6 col-md-3">
              <a href="" className="logo">
                <img
                  src={process.env.PUBLIC_URL + "/images/HN_logo.png"}
                  style={{ width: "70%" }}
                />
              </a>
            </div>
            <div className="col-6 col-md-9">
              <div className="desk-video-duration video-duration ai-center">
                <p className="mr-5">
                  <span>
                    <Clock format={"HH:mm:ss"} ticking={true} />
                  </span>
                </p>
                {/* <p>
                        <span>Doctor:</span> {this.state.appt_id.first_name}{" "}
                        {this.state.appt_id.last_name}
                      </p> */}
              </div>
              {/* <Link className="login-btn" href="" to="/patient/account">
                Dashboard
              </Link> */}
            </div>
          </div>
        </div>
      </header>
      <section className="patient-video-section">
        <Container fluid>
          <Row>
            <Col md="4">
              <div className="video-section" id="video-section">
                {/* <VideoChat appointmentId={appointmentId} /> */}
                <h2>Upload Document</h2>
                <p>
                  Upload any previous medical transcripts, test results,
                  diagnostic reports and more.
                </p>
                <FileSelector
                  className="prescription_selector mb-4"
                  maxFileSize={20}
                  onSelect={(image) => imageSelectHandler(image)}
                />
                <br />
                {(file || []).map((item, index) => (
                  <div
                    className="prescription-history-container mb-2"
                    key={index}
                    style={{ width: "100%" }}
                  >
                    <div
                      className="prescription-file-name"
                      style={{ width: "40%" }}
                    >
                      <div className="medical-record-heading">
                        {item.fileName || (
                          <span>Medical Record {index + 1}</span>
                        )}
                      </div>
                      <div className="medical-record-date">
                        {moment(item.date).format("HH:mm A | DD MMM, YYYY")}
                      </div>
                    </div>
                    <div className="medical-record-container">
                      <div className="medical-record-type ml-3">
                        {item.type && item.type.toUpperCase()} . {item.size}MB
                      </div>
                      <div className="ml-3">
                        <i
                          className="fa fa-trash trash-icon m-1"
                          onClick={() => removePrescription(item.document_id)}
                        ></i>
                        <i
                          className="fa fa-download medical-record-icon m-1"
                          onClick={() => downloadUploadFile(item)}
                        ></i>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
            <Col md="8">
              <div className="video-section" id="video-section">
                <VideoChat appointmentId={appointmentId} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <LoginOTPModal
        showLoginModal={showLoginModal}
        otp={otp}
        handleOtpSubmit={handleOtpSubmit}
      />
      <FormProvider {...verifyOTPForm}>
        <VerifyOTPModal
          showOTPModal={showOTPModal}
          verifyOtp={verifyOtp}
          receiver_phone_no={receiverPhone}
          receiverId={receiverId}
          appointmentId={appointmentId}
          handleVerifyOtp={handleVerifyOtp}
          onResendOtp={handleResendOtp}
          handleShowLoginPopUp={handleShowLoginPopUp}
        />
      </FormProvider>
    </>
  );
};

export default ReceiverVideo;
