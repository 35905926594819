import React, { Component } from "react";
import afterLoginApiAxios from "../Util/API Calls/afterLogin";
import { patientFiles } from "../Util/API Calls/APIList";
import moment from "moment";
import Col from "react-bootstrap/Col";
import GetAppIcon from "@material-ui/icons/GetApp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  disableHelpButton,
  enableHelpButton,
} from "./common/DisableHelpButton/DisableHelpButton";
import axios from "axios";
import { C2CConsole } from "../Util/Helper/C2CConsole";
class DocumentDoctor extends Component {
  constructor(props, context) {
    super(props, context);

    const { app_id } = this.props.appointmentData;
    this.state = {
      appID: app_id.id,
      URL: patientFiles,
      upload_prescriptions: [],
      loaderFlag: true,
      presMsg: "",
    };
  }

  componentWillMount() {
    disableHelpButton();
    if (localStorage.getItem("id_token")) {
    } else {
      this.props.history.push("/doctor/login");
    }

    afterLoginApiAxios
      .post(
        // "patient/get-document",
        "user/get-document-rev1",
        {
          appointment_id: this.state.appID,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then((response) => {
        if (response) {
          var responseData = response.data;
          this.setState({
            loaderFlag: false,
          });
          if (responseData) {
            if (response.data.hasOwnProperty("statusCode")) {
              if (response.data.statusCode == 200) {
                let latestFiles =
                  (responseData.data &&
                    responseData.data.length > 0 &&
                    responseData.data) ||
                  [];
                this.setState({
                  upload_prescriptions: [
                    ...this.state.upload_prescriptions,
                    ...latestFiles,
                  ],
                  presMsg: latestFiles.length == 0 ? "No Document Found" : "",
                });
              } else if (response.data.statusCode == 511) {
                this.setState({
                  presMsg: "No Document Found",
                });
              }
            } else {
              this.setState(
                {
                  presMsg: "No Document Found",
                  dataload: false,
                },
                () => {}
              );
            }
          }
        }
      })
      .catch(function (error) {
        C2CConsole.log(error);
      });

    afterLoginApiAxios
      .post(
        "doctor/get-receiver-document",
        {
          appointment_id: this.state.appID,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then((response) => {
        if (response) {
          var responseData = response.data;
          this.setState({
            loaderFlag: false,
          });
          if (responseData) {
            if (response.data.hasOwnProperty("statusCode")) {
              if (response.data.statusCode == 200) {
                let latestFiles =
                  (responseData.data &&
                    responseData.data.length > 0 &&
                    responseData.data) ||
                  [];
                this.setState({
                  upload_prescriptions: [
                    ...this.state.upload_prescriptions,
                    ...latestFiles,
                  ],
                  presMsg: latestFiles.length == 0 ? "No Document Found" : "",
                });
              } else if (response.data.statusCode == 511) {
                this.setState({
                  presMsg: "No Document Found",
                });
              }
            } else {
              this.setState(
                {
                  presMsg: "No Document Found",
                  dataload: false,
                },
                () => {}
              );
            }
          }
        }
      })
      .catch(function (error) {
        C2CConsole.log(error);
      });
  }
  componentWillUnmount() {
    enableHelpButton();
  }

  async downloadURI(url, name, fileType) {
    const blob = await axios.get(url, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
      responseType: "blob",
    });
    const a = document.createElement("a");
    const href = window.URL.createObjectURL(blob.data);
    a.href = href;
    a.download = name + "." + fileType.trim();

    a.click();
  }

  render() {
    var styles = {
      cardOdd: {
        backgroundColor: "#eeae01",
        color: "#454545",
      },
      cardEven: {
        backgroundColor: "#454545",
        color: "#eeae01",
      },
    };

    const { documentShow, children, actions } = this.props;

    return (
      <div>
        <div className="container">
          <div className="row mb-2">
            {documentShow ? (
              children
            ) : (
              <Col>
                {this.state.upload_prescriptions ? (
                  <div
                    className="col-sm-12 col-lg-12 m-0 p-0"
                    style={{ backgroundColor: "#fff" }}
                  >
                    <p style={{ color: "#a3a9b7" }}>
                      {this.state.upload_prescriptions?.length}{" "}
                      {this.state.upload_prescriptions?.length > 1
                        ? "Documents"
                        : "Document"}
                    </p>
                    {this.state.upload_prescriptions.map((result, index) => {
                      const lastDot = result.document_title.lastIndexOf(".");
                      const fileName =
                        result.document_title.substring(0, lastDot) ||
                        "Medical Record" + index;
                      const fileType =
                        result.document_title.substring(lastDot + 1) || "";
                      return (
                        <div
                          className="prescription-history-container" //px-3
                          key={index}
                        >
                          <div style={{ width: "50%" }}>
                            <div className="prescription-file-name">
                              <div className="medical-record-heading">
                                {fileName}
                              </div>
                              <div className="medical-record-date">
                                {moment(result.created_at).format(
                                  "HH:mm A | DD MMM, YYYY"
                                )}
                              </div>
                            </div>
                            <div className="medical-record-type">
                              {fileType && fileType.toUpperCase()}{" "}
                              <div className="bullet ml-1 mr-1 mt-2" />{" "}
                              {result.file_size}MB
                            </div>
                          </div>
                          <div className="medical-record-container">
                            <div>
                              {fileType.toLowerCase().includes("pdf") ||
                              fileType.toLowerCase().includes("jpg") ||
                              fileType.toLowerCase().includes("png") ||
                              fileType.toLowerCase().includes("jpeg") ? (
                                <i
                                  className=" medical-record-icon m-1"
                                  onClick={() =>
                                    actions.viewDocumentAction({
                                      fileURL: result.presigned_url_view,
                                      fileName: fileName,
                                      fileType: fileType,
                                    })
                                  }
                                >
                                  <VisibilityIcon fontSize="small" />
                                </i>
                              ) : (
                                <i className=" view-disable-icon m-1">
                                  <VisibilityIcon fontSize="small" />
                                </i>
                              )}
                              {/* <a target="_blank" href={result.presigned_url}> */}
                              <i
                                className=" medical-record-icon m-1"
                                onClick={() => {
                                  this.downloadURI(
                                    result.presigned_url,
                                    fileName,
                                    fileType
                                  );
                                }}
                              >
                                <GetAppIcon fontSize="small" />
                              </i>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {this.state.upload_prescriptions.length === 0 && (
                      <span className="ml-3" style={{ color: "red" }}>
                        {this.state.presMsg}
                      </span>
                    )}
                  </div>
                ) : null}
              </Col>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default DocumentDoctor;
