import React, { useState, useEffect } from "react";
import afterLoginApiAxios from "../../Util/API Calls/afterLogin";
import axios from "axios";
import ErxCell from "./ErxCell";
import ShareManualRxModal from "./ShareManualRxMoadal";
import DocumentContainer from "../UI Component/DocumentViewer/DocumentCotainer";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";
import { C2CConsole } from "../../Util/Helper/C2CConsole";

const DoctorViewERX = (props) => {
  const { appointment_id } = props;
  const [e_rx_list, setERxList] = useState([]);
  const [showRxModal, setshowRxModal] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [documentShow, setDocumentShow] = useState(false);
  const [fullView, setFullView] = useState(false);
  const is_staff = localStorage.getItem("user_type");

  const userId = localStorage.getItem(LOCALSTORAGEKEY.USER_ID);
  const staffId = localStorage.getItem(LOCALSTORAGEKEY.STAFF_ID);

  const ImagetoPrint = (source) => {
    return (
      "<html><head><scri" +
      "pt>function step1(){\n" +
      "setTimeout('step2()', 10);}\n" +
      "function step2(){window.print();window.close()}\n" +
      "</scri" +
      "pt></head><body onload='step1()'>\n" +
      "<img src='" +
      source +
      "' /></body></html>"
    );
  };

  const printFile = (url, fileType) => {
    if (fileType.toLowerCase().includes("pdf")) {
      window.open(url, "PRINT", "height=400,width=600");
    }
    if (
      fileType.toLowerCase().includes("jpg") ||
      fileType.toLowerCase().includes("png") ||
      fileType.toLowerCase().includes("jpeg")
    ) {
      var Pagelink = "about:blank";
      var pwa = window.open(Pagelink, "_new");
      pwa.document.open();
      pwa.document.write(ImagetoPrint(url));
      pwa.document.close();
    }
  };

  useEffect(() => {
    afterLoginApiAxios
      .post(
        "doctor/get-shared-e-prescriptions",
        {
          "authorizer-principal-id": {
            user_type: "1",
            user_id: userId,
          },
          user_id: userId,
          appointment_id: appointment_id, //"1b90c17c-f956-4570-9214-1132fec31893",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          if (response.data.data && response.data.data.length > 0) {
            setERxList(response.data.data);
            props.setSharedErxList(response.data.data);
          } else {
            setERxList([]);
          }
        }
      })
      .catch((err) => C2CConsole.log(err));
  }, []);

  const downloadURI = async (url, name, fileType) => {
    const blob = await axios.get(url, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
      responseType: "blob",
    });
    const a = document.createElement("a");
    const href = window.URL.createObjectURL(blob.data);
    a.href = href;
    a.download = name + "." + fileType.trim();
    a.click();
  };

  const ShareERx = (data) => {
    afterLoginApiAxios
      .post(
        "doctor/share-e-prescription",
        {
          user_id: is_staff === "staff" ? staffId : userId,
          appointment_id: data.appointment_id, //"1b90c17c-f956-4570-9214-1132fec31893",
          prescription_id: data.prescription_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          setshowRxModal(true);
        }
      })
      .catch((err) => C2CConsole.log(err));
  };

  function closeNotification() {
    setDocumentShow(false);
    setFileUrl("");
    setFileName("");
    setFileType("");
    setFullView(false);
  }

  return (
    <>
      {e_rx_list.length > 0 ? (
        <div className="w-100  m_rx_border">
          {/**style={{ border: "2px solid red" }} */}
          <div className="m_rx_border_bottom  p-3">
            {/**style={{ border: "2px solid grey" }} */}
            <p className="manual_rx_heading">
              E-Prescription ({e_rx_list.length})
            </p>
          </div>
          {/* <div className="row">
        <div className="col-8"> */}
          {e_rx_list.length > 0 ? (
            <ErxCell
              e_rx_list={e_rx_list}
              setFileName={setFileName}
              setFileType={setFileType}
              setFileUrl={setFileUrl}
              setDocumentShow={setDocumentShow}
              downloadURI={downloadURI}
              ShareERx={ShareERx}
              saved={false}
              icons={{
                edit: false,
                view: true,
                download: true,
                share: true,
              }}
            />
          ) : // ? e_rx_list.map((result, index) => {
          //     return (
          //       <div
          //         className={`d-flex p-3 ${
          //           e_rx_list.length === 1 || e_rx_list.length - 1 === index
          //             ? ""
          //             : "m_rx_border_bottom"
          //         }`}
          //       >
          //         <div style={{ width: "60%" }}>
          //           <p className="m_rx_title">{`E-Prescription ${
          //             // index < 9 ? `0${index + 1}` : index + 1
          //             index < 9
          //               ? `0${e_rx_list.length - index}`
          //               : e_rx_list.length - index
          //           }`}</p>
          //           <p className="m_rx_details">
          //             {moment(result.shared_at.split(" ")[0]).format(
          //               "DD MMM, YYYY "
          //             )}
          //             |{" "}
          //             {moment(result.shared_at.split("+")[0]).format(
          //               " hh:mm A"
          //             )}
          //           </p>
          //           {/* <p className="m_rx_details">
          //             <span className="text-uppercase">{fileType}</span>{" "}
          //             <span className="bullet ml-1 mr-1 mt-2"></span>{" "}
          //             {result.file_size}MB
          //           </p> */}
          //           {/* <div className="medical-record-type">
          //             {fileType && fileType.toUpperCase()}{" "}
          //             <div className="bullet ml-1 mr-1 mt-2" />{" "}
          //             {result.file_size}MB
          //           </div> */}
          //         </div>
          //         {/* </div> */}
          //         {/* <div className="col-3 d-flex"> */}
          //         <div
          //           style={{ width: "40%" }}
          //           className="d-flex justify-content-end"
          //         >
          //           {
          //             // <button className="rx_icons_btn">
          //             //   <img
          //             //     src={ViewIcon}
          //             //     alt=""
          //             //     onClick={() => {
          //             //       setFileName(fileName);
          //             //       setFileType(fileType);
          //             //       setFileUrl(result.presigned_url_view);
          //             //       setDocumentShow(true);
          //             //     }}
          //             //   />
          //             // </button>

          //             <span
          //               style={{ paddingTop: "8px" }}
          //               data-tooltip="View"
          //               data-tooltip-conf="bottom"
          //             >
          //               <i
          //                 className=" medical-record-icon m-1"
          //                 onClick={() => {
          //                   setFileName(
          //                     `E-Prescription ${
          //                       // index < 9 ? `0${index + 1}` : index + 1
          //                       index < 9
          //                         ? `0${e_rx_list.length - index}`
          //                         : e_rx_list.length - index
          //                     }`
          //                   );
          //                   setFileType(".pdf");
          //                   setFileUrl(result.presigned_url_view);
          //                   setDocumentShow(true);
          //                 }}
          //               >
          //                 <VisibilityIcon fontSize="small" />
          //               </i>
          //             </span>
          //           }
          //           {/* <button className="rx_icons_btn">
          //             <img
          //               src={DownloadIcon}
          //               alt=""
          //               onClick={() =>
          //                 downloadURI(
          //                   result.presigned_url,
          //                   fileName,
          //                   fileType
          //                 )
          //               }
          //             />
          //           </button> */}
          //           <span
          //             style={{ paddingTop: "8px" }}
          //             data-tooltip="Download"
          //             data-tooltip-conf="bottom"
          //           >
          //             <i
          //               className=" medical-record-icon m-1"
          //               onClick={() =>
          //                 downloadURI(
          //                   result.presigned_url,
          //                   `E-Prescription ${
          //                     // index < 9 ? `0${index + 1}` : index + 1
          //                     index < 9
          //                       ? `0${e_rx_list.length - index}`
          //                       : e_rx_list.length - index
          //                   }`,
          //                   ".pdf"
          //                 )
          //               }
          //             >
          //               <GetAppIcon fontSize="small" />
          //             </i>
          //           </span>
          //           {/* <button
          //             className="rx_icons_btn"
          //             onClick={() =>
          //               printFile(result.presigned_url_view, fileType)
          //             }
          //           >
          //             <img src={PrintIcon} alt="" />
          //           </button> */}
          //           {/* <button className="rx_icons_btn">
          //             <img
          //               src={ShareIcon}
          //               alt=""
          //               onClick={() => {
          //                 ShareERx({
          //                   doc_data: result.document_path,
          //                   file_size: result.file_size,
          //                   doc_title: result.document_title,
          //                 },result.document_id);
          //               }}
          //             />
          //           </button> */}
          //           <span
          //             style={{ paddingTop: "8px" }}
          //             data-tooltip="Share"
          //             data-tooltip-conf="bottom"
          //           >
          //             <i
          //               className=" medical-record-icon m-1"
          //               onClick={() => {
          //                 ShareERx(result);
          //               }}
          //             >
          //               <ReplyIcon
          //                 fontSize="small"
          //                 style={{ transform: "scaleX(-1)" }}
          //               />
          //             </i>
          //           </span>
          //         </div>
          //       </div>
          //     );
          //   })
          null}

          {/* </div> */}
          {/* </div> */}
        </div>
      ) : null}

      {documentShow && (
        <DocumentContainer
          cancelHandler={closeNotification}
          fullViewHandler={() => {
            setFullView(!fullView);
          }}
          url={fileUrl}
          fileName={fileName}
          fileType={fileType}
          // width={width || ""}
        />
      )}

      <ShareManualRxModal
        show={showRxModal}
        handleClose={() => {
          setshowRxModal(false);
        }}
      />
    </>
  );
};

export default DoctorViewERX;
