import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import Drawer from "@material-ui/core/Drawer";
import "./pemContentSharestyles.scss";
import afterLoginApiAxios from "../../Util/API Calls/afterLogin";

import CloseIcon from "@material-ui/icons/Close";
import AddContent from "./addContent";
import ShareContentView from "./shareContent";
import afterLoginApiAxiosPEM from "./PEMAfterLogin";
import index from "react-highlight-words";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";

const PEMShareContentPopUp = (props) => {
  let { patientId, isERx, appointment_id } = props;
  const [showAddContentView, setshowAddContentView] = useState(true);
  const [selectedArticles, setselectedArticles] = useState([]);
  const [previousText, setpreviousText] = useState("");
  const [previousuploadMedia, setpreviousuploadMedia] = useState({});
  const [previous_doc_data, setprevious_doc_data] = useState(null);
  const [rxKeywords, setrxKeywords] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  const userId = localStorage.getItem(LOCALSTORAGEKEY.USER_ID);
  // life cycle ==>
  useEffect(() => {
    if (isERx !== "False") {
      // fetch the e-prescription
      afterLoginApiAxios
        .post(
          "doctor/get-shared-e-prescriptions",
          {
            "authorizer-principal-id": {
              user_type: "1",
              user_id: userId,
            },
            user_id: userId,
            appointment_id: appointment_id, //"1b90c17c-f956-4570-9214-1132fec31893",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("id_token"),
            },
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            if (response.data.data && response.data.data.length > 0) {
              // make promise for every prescription issue
              const rx_promises = getPromise(response.data.data);
              let rx_keywords = [];
              // setisLoading(true);
              Promise.all(rx_promises)
                .then((res) => {
                  res.forEach((response) => {
                    rx_keywords.push(
                      response.data.prescription_data.textual.display_name
                    );
                  });
                  setrxKeywords(rx_keywords);
                  setisLoading(false);
                })
                .catch((err) => {
                  setrxKeywords([]);
                  setisLoading(false);
                });
              // .finally(() => setisLoading(false));
            }
          }
          setisLoading(false);
        })
        .catch((err) => {
          setrxKeywords([]);
          setisLoading(false);
        });
    } else {
      setisLoading(false);
    }
  }, []);

  // useEffect(() => {
  //   setisLoading(false)
  // }, [rxKeywords])
  // <==
  // Callbacks ==>

  const getPromise = (datList) => {
    return datList.map((dat) => {
      return new Promise((resolve, reject) => {
        afterLoginApiAxios
          .post(
            "doctor/get-prescription-previous-rev1",
            {
              "authorizer-principal-id": {
                user_type: "1",
                user_id: userId,
              },
              user_id: userId,
              prescription_id: dat.prescription_id,
              appointment_id: appointment_id,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("id_token"),
              },
            }
          )
          .then((response) => resolve(response))
          .catch((err) => reject(err));
      });
    });
  };

  const addContentCallback = (
    title,
    articlesToBeShared,
    uploadMedia,
    doc_data
  ) => {
    setpreviousText(title);
    setselectedArticles(articlesToBeShared);
    setpreviousuploadMedia(uploadMedia);
    setprevious_doc_data(doc_data);
    setshowAddContentView(true);
  };

  const previewBtnCallback = (text) => {
    if (text) {
      setpreviousText(text);
    }
    setshowAddContentView(false);
  };

  const uploadBtnCallback = (uploadMedia, docData) => {
    setpreviousuploadMedia(uploadMedia);
    setprevious_doc_data(docData);
  };

  const setSelectedArticlesCallBack = (articles) => {
    setselectedArticles(articles);
  };

  // <==
  // Create UI ==>
  const renderHeader = () => {
    return (
      <div>
        <Col className="share-content-header">
          <div style={{ display: "flex" }}>
            <h1 style={{ flex: 1 }}>Share Patient Education Content</h1>
            {props?.skipBtn ? (
              <div
                onClick={() => props.pemCloseBtnCallback(true)}
                className="skip-btn"
              >
                Skip
              </div>
            ) : (
              <div
                onClick={() => {
                  props.pemCloseBtnCallback(true);
                  props.setErxComponentOpen(false);
                }}
              >
                <CloseIcon style={{ color: "black" }} />
              </div>
            )}
          </div>
        </Col>
      </div>
    );
  };

  const removeArticle = (index) => {
    let new_article_list = [...selectedArticles];
    new_article_list.splice(index, 1);
    setselectedArticles(new_article_list);
    if (!new_article_list.length) {
      setshowAddContentView(true);
    }
  };

  // <==
  return (
    <>
      <Drawer
        anchor="right"
        open={true}
        variant="temporary" //persistent
        PaperProps={{ elevation: 9 }}
      >
        <div className="share-base-content-container">
          {renderHeader()}
          {isLoading ? (
            <p className="m-5">Loading...</p>
          ) : (
            <>
              {showAddContentView ? (
                <AddContent
                  previewBtnCallback={previewBtnCallback}
                  setSelectedArticlesCallBack={setSelectedArticlesCallBack}
                  patientId={patientId}
                  pemCloseBtnCallback={props.pemCloseBtnCallback}
                  uploadBtnCallback={uploadBtnCallback}
                  selectedArticles={selectedArticles}
                  rxKeywords={rxKeywords}
                  pemSuggestedKeyFromPrescription={
                    props?.pemSuggestedKeyFromPrescription
                  }
                />
              ) : (
                <ShareContentView
                  key={selectedArticles.length}
                  addContentCallback={addContentCallback}
                  selectedArticles={selectedArticles}
                  previousText={previousText}
                  patientId={patientId}
                  pemCloseBtnCallback={props.pemCloseBtnCallback}
                  previousuploadMedia={previousuploadMedia}
                  previous_doc_data={previous_doc_data}
                  removeDocView={removeArticle}
                />
              )}
            </>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default PEMShareContentPopUp;
