import React, { Component } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import afterLoginApiAxios from "../Util/API Calls/afterLogin";
import GlobalFooter from "./GlobalFooter";
import { doctorProfileFiles } from "../Util/API Calls/APIList";
import moment from "moment";
import { connect } from "react-redux";
import {
  disableHelpButton,
  enableHelpButton,
} from "./common/DisableHelpButton/DisableHelpButton";
import Loader from "./UI Component/Loader/Loader";
import { LOCALSTORAGEKEY } from "../Util/Helper/localStorageUtils";
import { C2CConsole } from "../Util/Helper/C2CConsole";

class Thankyou extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      token: "",
      txnid: localStorage.getItem("txnID"),
      email: localStorage.getItem("email"),
      message: "",
      doctor: null,
      start: "",
      date: "",
      time: "",
      paymentSuccess: false,
      URL: doctorProfileFiles,
      transactionPollingTimer: null,
      appointment: null,
      todayDate: "",
      startTime: "",
      showLoader: false,
    };

    this.logOut = this.logOut.bind(this);
    localStorage.removeItem("fail_msg");
    this.userId = localStorage.getItem(LOCALSTORAGEKEY.USER_ID);
  }

  confirmPayment = () => {
    afterLoginApiAxios
      .post(
        "/patient/match-payu-hash-response",
        {
          transaction_id: localStorage.getItem("txnID"),
          patient_id: this.userId,
        },
        {
          headers: {
            Authorization: `${window.localStorage.getItem("id_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode == 200) {
          this.setState({ paymentSuccess: true });
          clearInterval(this.transactionPollingTimer);
        } else if (response.data.statusCode == 540) {
          //no trans found, 541= pending, 542= failed
          clearInterval(this.transactionPollingTimer);
          localStorage.setItem("fail_msg", response.data.message);
          this.props.history.push("/requesttimedout");
        } else if (response.data.statusCode == 542) {
          clearInterval(this.transactionPollingTimer);
          localStorage.setItem("fail_msg", response.data.message);
          this.props.history.push("/requesttimedout");
        }
      });
  };

  getDetails = () => {
    this.setState({
      showLoader: true,
    });
    afterLoginApiAxios
      .post(
        "/patient/get_appointment_by_id",
        {
          appointment_id: localStorage.getItem("inclinic-online-id"),
          "authorizer-principal-id": {
            user_type: 2,
            user_id: this.userId,
          },
        },
        {
          headers: {
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then((response) => {
        this.setState({ appointment: response.data.data[0] });
        let date = response.data.data[0].appointment_date;
        let time = response.data.data[0].start_time;
        this.setState({
          todayDate: moment(date).format("DD MMM YYYY"),
          startTime: moment(time, "hh:mm:ssZ").format("hh:mm A"),
          doctor: {
            first_name: response.data.data[0].first_name,
            last_name: response.data.data[0].last_name,
            profile_picture_url: response.data.data[0].profile_picture_url,
          },
          showLoader: false,
        });
      })
      .catch((error) => {
        C2CConsole.log("Error", error);
        this.setState({
          showLoader: false,
        });
      });
  };

  componentDidMount() {
    disableHelpButton();
    window.scrollTo(0, 0);
    if (localStorage.getItem("free")) {
      this.setState({ paymentSuccess: true });
      if (localStorage.getItem("inclinic-online") == "true") {
        this.getDetails();
      }
    } else {
      this.transactionPollingTimer = setInterval(() => {
        this.confirmPayment();
      }, 5000);
      if (localStorage.getItem("inclinic-online") == "true") {
        this.getDetails();
      }
    }
  }

  logOut() {
    afterLoginApiAxios
      .post(
        "auth/user-logout",
        {
          user_token: `${localStorage.getItem("id_token")}`,
        },
        {
          headers: {
            Authorization: `${window.localStorage.getItem("id_token")}`,
          },
        }
      )
      .then((response) => {
        C2CConsole.log(response);
        localStorage.removeItem("id_token");
        localStorage.removeItem("user_type");
        localStorage.removeItem(LOCALSTORAGEKEY.USER_ID);
        localStorage.removeItem("user_name");
        localStorage.removeItem("full_name");
        localStorage.removeItem("meeting_id");
        this.props.history.push("/patient/login");
      })
      .catch((err) => {
        C2CConsole.log("error found");
        C2CConsole.log(err);
        swal("", "An error occured, logging out now!", "info").then(() => {
          localStorage.removeItem("id_token");
          localStorage.removeItem("user_type");
          localStorage.removeItem(LOCALSTORAGEKEY.USER_ID);
          localStorage.removeItem("user_name");
          localStorage.removeItem("full_name");
          this.props.history.push("/patient/login");
        });
      });
  }

  componentWillMount() {
    this.setState({
      showLoader: true,
    });
    if (localStorage.getItem("meeting_id") !== null) {
      afterLoginApiAxios
        .post(
          "/patient/get_appointment_by_id",
          {
            appointment_id: localStorage.getItem("flag_appointment_id_instant"),
          },
          {
            headers: {
              Authorization: `${window.localStorage.getItem("id_token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.statusCode === 200) {
            this.setState({ appointment: res.data.data[0] });
            let date = res.data.data[0].appointment_date;
            let time = res.data.data[0].start_time;
            this.setState({ todayDate: moment(date).format("DD MMM YYYY") });
            this.setState({
              startTime: moment(time, "hh:mm:ssZ").format("hh:mm A"),
            });

            afterLoginApiAxios({
              method: "post",
              url: "/patient/patient_invitation_action",
              headers: {
                authorization: localStorage.getItem("id_token"),
              },
              data: {
                patient_id: this.userId,
                event_id: 4,
                instant_consult_key: localStorage.getItem("meeting_id"),
              },
            })
              .then((res) => {
                C2CConsole.log(res);
                this.setState({
                  showLoader: false,
                });
              })
              .catch((err) => {
                C2CConsole.log(err);
                this.setState({
                  showLoader: false,
                });
              });
          }
        });
    } else {
      this.setState({
        showLoader: false,
      });
    }
    var start = localStorage.getItem("start");
    var date = localStorage.getItem("date");
    var time = localStorage.getItem("start");
    var doctor = JSON.parse(localStorage.getItem("doctor"));
    this.setState({
      start: start,
      date: date,
      time: time,
      doctor: doctor,
    });
  }

  componentWillUnmount() {
    enableHelpButton();
    localStorage.setItem("bolt_txnid", "");
    localStorage.removeItem("inclinic-online-id");
    localStorage.removeItem(LOCALSTORAGEKEY.IS_ASSOCIATED);
    localStorage.removeItem("inclinic-online");
    localStorage.removeItem("free");
  }

  render() {
    var styles = {
      cardOdd: {
        backgroundColor: "#eeae01",
        color: "#454545",
      },
      cardEven: {
        backgroundColor: "#454545",
        color: "#eeae01",
      },
    };

    const backToHomeClicked = () => {
      clearInterval(this.transactionPollingTimer);
      this.props.history.push("/patient/account/dashboard");
    };

    return (
      <div>
        {this.state.showLoader ? <Loader /> : null}
        <header>
          <div className="container-fluid">
            <div className="row mt-4">
              <div className="col-6 col-md-3">
                <a href="" className="logo">
                  <img
                    src={process.env.PUBLIC_URL + "/images/HN_logo.png"}
                    alt="logo"
                  />
                </a>
              </div>
              <div className="col-6 col-md-9 text-right">
                <Link className="mr-5 otp-btn" onClick={this.logOut.bind(this)}>
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </header>
        {this.state.paymentSuccess ? (
          <section className="pro-body-content">
            <div className="pro-content2">
              <div className="container-fluid shadow-lg p-3 mb-5 bg-white rounded">
                <div className="pro-content-col">
                  <div className="thanks-section-main">
                    <h4> Booking Confirmation </h4>
                    <div className="inner">
                      <div>
                        <i
                          className="fa fa-thumbs-up successThumbs"
                          style={{ fontSize: "102px", marginBottom: 12 }}
                        ></i>
                      </div>
                      <div>
                        {this.state?.doctor?.first_name !== undefined && (
                          <p style={{ color: "#404760" }}>
                            Your appointment is successfully scheduled with{" "}
                            <b>
                              {" "}
                              Dr.{" "}
                              {this.state?.doctor?.first_name +
                                " " +
                                this.state?.doctor?.last_name}
                              ,{" "}
                            </b>
                            <br />
                            on{" "}
                            {this.state.appointment ? (
                              <b>
                                {" "}
                                {this.state.todayDate} at {this.state.startTime}
                              </b>
                            ) : (
                              <b>
                                {" "}
                                {moment(this.state.date).format(
                                  "DD MMM YYYY"
                                )}{" "}
                                at {this.state.time}{" "}
                              </b>
                            )}{" "}
                            {this.state.email && (
                              <p>
                                You will shortly receive an email on your
                                registered ID with the details.
                              </p>
                            )}
                            <br />
                            <br />
                            {this.state.txnid ? (
                              <span style={{ color: "#F969A4" }}>
                                Transaction ID {this.state.txnid}
                              </span>
                            ) : null}
                          </p>
                        )}
                      </div>
                      <div>
                        <img
                          className="thankyou-profile-img"
                          alt=""
                          src={
                            this.state.URL +
                            this.state?.doctor?.profile_picture_url
                          }
                        />
                      </div>
                    </div>
                    {localStorage.getItem("meeting_id") !== null ? (
                      <Link
                        to={{
                          pathname: "/patient/video",
                          state: {
                            app_id: this.state.appointment,
                            is_audio: true,
                            is_video: true,
                          },
                        }}
                        href="#"
                        className="otp-btn"
                      >
                        Proceed To Consultation
                      </Link>
                    ) : (
                      <Link
                        to="/patient/account/dashboard"
                        href="#"
                        className="otp-btn"
                      >
                        Proceed To Dashboard
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="pro-body-content">
            <div className="pro-content2">
              <div className="container p-3 mb-5 ">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <div
                      className="bg-white shadow-lg p-5 text-center mt-5"
                      style={{ borderRadius: 18 }}
                    >
                      <h4 style={{ fontWeight: "bold" }} className="mb-4">
                        {" "}
                        Processing your Booking...{" "}
                      </h4>
                      <b>Your booking is under process.</b>
                      <br />
                      <br />
                      <p style={{ color: "#404760" }}>
                        {this.state.failure_msg} <br />
                        <br />
                        Please contact our Customer Happiness Team at{" "}
                        <b style={{ color: "#F969A4" }}>
                          {this.props.mobileNumber}{" "}
                        </b>
                        if you need help.
                      </p>
                      <Link
                        onClick={backToHomeClicked}
                        className="otp-btn mt-5"
                        style={{ width: 150 }}
                      >
                        Back to Home
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        <GlobalFooter />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mobileNumber: state.global.mobileNumber,
  emailId: state.global.emailId,
});

export default connect(mapStateToProps)(Thankyou);
