import React, { useState } from "react";
import DatePicker from "react-date-picker";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import calendericon from "../../assets/calender.png";
import moment from "moment";
import swal from "sweetalert";
import { Calendar } from "react-date-range";
import { ClickAwayListener } from "@material-ui/core";
export default function NextFollowUpItemComponent(props) {
  const { dateChangeHandler, dateValue, dateDeleteHandler } = props;
  const [showCalander, setShowCalander] = useState(false);

  return (
    <Col>
      <Row>
        {/* <div className="customDatePickerWidth px-3">
          <DatePicker
            onCalendarClose={(value) => {
              if (value === "") {
                swal({
                  title: "Error",
                  text: "Date Cannot be less than today's date",
                  icon: "error",
                });
                return;
              }
            }}
            onChange={(value) => {
              if (value === "") {
                swal({
                  title: "Error",
                  text: "Date Cannot be less than today's date",
                  icon: "error",
                });
                return;
              }
              dateChangeHandler(value);
            }}
            onKeyPress={(e)=>{e.preventDefault()}}
            value={dateValue}
            format="dd/MM/yyyy"
            dayPlaceholder="dd"
            monthPlaceholder="mm"
            yearPlaceholder="yyyy"
            minDate={new Date()}
            clearIcon={null}
            calendarIcon={<img src={calendericon} />}
          />
        </div> */}
        <ClickAwayListener
          onClickAway={() => {
            setShowCalander(false);
          }}
        >
          <div className="customDatePickerWidth px-3">
            <div
              className={"cal_input"}
              onClick={() => {
                setShowCalander(!showCalander);
              }}
            >
              <input
                value={dateValue ? moment(dateValue).format("DD/MM/YYYY") : ""}
                placeholder="DD/MM/YYYY"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                // onClick={() => {
                //   setShowCalander(!showCalander);
                // }}
              />
              <div
                className="icon_div"
                // onClick={() => {
                //   setShowCalander(!showCalander);
                // }}
              >
                <img src={calendericon} />
              </div>
            </div>
            {/* <input placeholder="DD/MM/YYYY" onKeyDown={(e) => { e.preventDefault() }} onClick={()=>{setShowCalander(!showCalander)} }/> */}
            {showCalander ? (
              // <ClickAwayListener onClickAway={()=>{if(showCalander===true){setShowCalander(false)}}}>
              <Calendar
                // date={new Date()}
                date={dateValue}
                minDate={new Date()}
                maxDate={
                  new Date(new Date().getTime() + 90 * 24 * 60 * 60 * 1000)
                }
                color="#F969A4"
                onChange={(date) => {
                  dateChangeHandler(date);
                  setShowCalander(false);
                }}
                // showPreview={true}
                // editableDateInputs={false}
              />
            ) : // {/* </ClickAwayListener> */}
            null}
          </div>
        </ClickAwayListener>
      </Row>
      {dateValue !== undefined && dateValue !== "" ? (
        <>
          <Row className="p-2">
            <Col
              lg={10}
              md={10}
              sm={10}
              xs={10}
              className="align-self-center followupdate py-2"
            >
              NEXT FOLLOW UP: {moment(dateValue).format("DD/MM/YYYY")}
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="align-self-center">
              <Button
                variant="outline-danger"
                size={"sm"}
                className="deletefollowup"
                onClick={() => dateDeleteHandler()}
              >
                <DeleteIcon />
              </Button>
            </Col>
          </Row>
        </>
      ) : null}
    </Col>
  );
}
