//dependecy import  ==>
import React, { useEffect, useState } from "react";
//scss or assest import
import "./abhaProfile.scss";
import abhaProfileImage from "../../../assets/dashboard/ahbaProfile.svg";
//project File import ==>
import AbhaPatientProfile from "./AbhaPatientProfile";
import { Divider } from "@material-ui/core";
import Loader from "../../UI Component/Loader/Loader";
// constant file import
import { ABHA_PATIENT_MYPROFILE } from "./APIHandler/apiConstants";
import { getAbhaPatientProfilesDetails } from "./APIHandler";
import { LOCALSTORAGEKEY } from "../../../Util/Helper/localStorageUtils";
import { C2CConsole } from "../../../Util/Helper/C2CConsole";
//<==

const AbhaProfile = () => {
  //const ==>
  const phoneNumber = localStorage.getItem(LOCALSTORAGEKEY.USER_NAME);
  const formattedPhoneNumber = phoneNumber.replace(/(\d{2})(\d+)/, "$1 $2");

  const [patientData, setPatientData] = useState([]);
  const [loader, setLoader] = useState(false);
  //<==

  // Lifecycyle Methods ==>

  useEffect(() => {
    setLoader(true);
    getAbhaPatientProfileDetailsList();
  }, []);

  // <==

  // Api calls ==>

  const getAbhaPatientProfileDetailsList = () => {
    getAbhaPatientProfilesDetails(ABHA_PATIENT_MYPROFILE + phoneNumber)
      .then(([res, err]) => {
        setLoader(false);
        if (!err) {
          if (res?.data?.statusCode === 200) {
            setPatientData(res?.data?.data);
          } else {
            setPatientData([]);
          }
        }
      })
      .catch((err) => {
        C2CConsole.log("err", err);
      });
  };
  // <==

  // Display UI =>
  const abhaHeaderContainer = () => {
    return (
      <div className="apc-header">
        <div className="apc-header-title">MY PROFILE</div>
      </div>
    );
  };

  const abhaProfileTopContainer = () => {
    return (
      <div className="apc-top-container">
        <div>
          <img src={abhaProfileImage} alt="" />
        </div>
        <div className="apc-top--container-text-view">
          <div className="apc-top-container-text">Hello,</div>
          <div className="apc-top-container-patient-phone">
            {formattedPhoneNumber}
          </div>
        </div>
      </div>
    );
  };

  // <==

  return (
    <div className="abha-profile-container">
      {loader && <Loader />}
      {abhaHeaderContainer()}
      <div className="aps-padding-conatiner">
        {abhaProfileTopContainer()}
        <Divider className="abha-horizontal-dotted-line" />
        <AbhaPatientProfile patientData={patientData} />
      </div>
    </div>
  );
};

export default AbhaProfile;
