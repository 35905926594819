import React, { useState } from "react";
import "./DropDownStyleSelector.scss";
import Spinner from "react-bootstrap/Spinner";
import Highlighter from "react-highlight-words";
import { ClickAwayListener } from "@material-ui/core";

export default function DropDownSelector(props) {
  const [showDropdown, setShowDropDown] = useState(true);

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setShowDropDown(false);
        }}
      >
        <div
          id="drop-down-search-selector"
          className={`drop-down-div ${
            props.loading && props.show && props.pindex === props.indx
              ? "input_width"
              : ""
          }`}
        >
          {" "}
          {/*${props.loading && props.show?'input_width':''}*/}
          <input
            type="text"
            className={`${props.isShowDropDown && "menu-item-active"}`}
            onChange={(evt) => {
              setShowDropDown(true);
              props.handleSearch(evt);
            }}
            name={props.name}
            value={props.value}
            id={props.name}
            autoComplete="off"
            placeholder={props.placeholder}
          />
          {props.show && props.pindex === props.indx ? (
            <span>
              <Spinner animation="border" className="spinner_css" />
            </span>
          ) : null}
          {props.isShowDropDown && showDropdown && props.value.length > 0 && (
            <div className="menu-list-container">
              {props.options.map((item, index) => (
                <div
                  className="menu-list-option my-2"
                  key={index}
                  onClick={() => props.handleSelect(item)}
                >
                  {/* {item.label} */}
                  {props.name == "drug" ? (
                    <>
                      <div>
                        {/* <p>{item.label}</p> */}
                        <p>
                          <Highlighter
                            highlightClassName="font-weight-bold highlight_background"
                            searchWords={[props.value]}
                            autoEscape={true}
                            textToHighlight={item.label}
                          />
                        </p>
                        {props.name == "drug" && item.drug_id && (
                          <p>
                            <small style={{ color: "#A3A9B7" }}>
                              {item?.packaging}
                              <span
                                style={{
                                  fontSize: "5px",
                                  position: "relative",
                                  bottom: "2px",
                                }}
                                className="mx-1"
                              >
                                <i class="fa fa-circle" aria-hidden="true"></i>
                              </span>
                              {item?.salt_composition}
                            </small>
                          </p>
                        )}
                      </div>
                      {!item?.drug_id && (
                        <div className="ml-auto delete-icon">
                          <i
                            className="fa fa-trash cursor-pointer"
                            onClick={(evt) => props.handleDelete(evt, item)}
                            aria-hidden="true"
                          ></i>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div>
                        <p>{item.label}</p>
                        {/* <p>
                        <Highlighter
                          highlightClassName="font-weight-bold highlight_background"
                          searchWords={[props.value]}
                          autoEscape={true}
                          textToHighlight={item.label}
                        />
                      </p> */}
                      </div>
                      <div className="ml-auto delete-icon">
                        <i
                          className="fa fa-trash cursor-pointer"
                          onClick={(evt) => props.handleDelete(evt, item)}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </ClickAwayListener>
    </>
  );
}
