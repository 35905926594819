export const ENROLL_AADHAR = "/abha/v1/user/enroll";
export const ENROLL_VERIFY_OTP = "/abha/v1/enroll/verify-otp";
export const ENROLL_RESEND_OTP = "/abha/v1/enroll/resend-otp";
export const LINK_MOBILE_NUMBER = "/abha/v1/patients/link-mobile";
export const LINK_MOBILE_NUMBER_OTP = "/abha/v1/patients/verify-otp";
export const LINK_MOBILE_NUMBER_RESEND_OTP = "/abha/v1/patients/resend-otp";
export const ABHA_ADDRESS_SUGGESTION = (patient_id) => {
  return `/abha/v1/patients/${patient_id}/address-suggestion`;
};
export const ABHA_ADD_ADDRESS = (patient_id) => {
  return `/abha/v1/patients/${patient_id}/add-address`;
};
export const DOWNLOAD_ABHA_CARD = (patient_id) => {
  return `/abha/v1/patients/${patient_id}/docu-download`;
};

export const ABHA_PROFILE = (patient_id) => {
  return `/abha/v1/patients/${patient_id}/user-profile`;
};
