import documentPapers from "../../../../assets/document_papers.svg";
import ThumbIcon from "../../../../assets/thumb.svg";
import BellIcon from "../../../../assets/bellIcon.svg";

export const LEARNMOREPOPUP = [
  {
    title: "Grant permission to link documents.",
    description:
      "Grant permission to the Connect2Clinic App to link your medical documents with the ABHA address.",
    imageSrc: documentPapers,
  },
  {
    title: "Enroll for notifications.",
    description:
      "You will receive notifications on the Connect2Clinic app whenever new medical records are linked to your ABHA address..",
    imageSrc: BellIcon,
  },
  {
    title: "Auto Consent.",
    description:
      "Upon linking new medical records with your ABHA address, they will be automatically available on the Connect2Clinic app. We will provide consent on your behalf to the requesting entity.",
    imageSrc: ThumbIcon,
  },
];

export const POPUPCONTAINT = {
  TITLECONTAINT: " Create ABHA (Health ID) Card",
  SUBTITLECONTAINT: " Setup your Locker",
  TEXTCONTAINT: " This action allows Connect2Clinic to",
};
