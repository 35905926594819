import React, { useEffect, useState } from "react";

import { cellViewType } from "../../../Util/constants";
import afterLoginApiAxiosPEM from "../PEMAfterLogin";
import RectangeShapeRoundedImgCell from "../cells/RectangeShapeRoundedImgCell";

const AddContentList = (props) => {
  const { selectedKeyword } = props;
  const [selectedContents, setSelectedContents] = useState(
    props.selectedArticles
  );
  const [allContents, setallContents] = useState([]);
  const [maxError, setMaxError] = useState(false);

  // LifeCycle ==>
  useEffect(() => {
    setallContents(props.searchedData);
  }, [props.searchedData]);

  useEffect(() => {
    setTimeout(() => setMaxError(false), 2000);
  }, [maxError]);

  useEffect(() => {
    fetchAllArticles(selectedKeyword, 10, 1, true);
  }, [selectedKeyword]);

  // <==

  // API Calls ==>
  const fetchAllArticles = async (
    keyword,
    articlesPerPage,
    pagenumber,
    allArticles
  ) => {
    let keywordValue = keyword ? keyword : "All";
    let allArticlesValue = allArticles ? allArticles : false;
    let pagenumberValue = pagenumber ? pagenumber : 1;
    let articlesPerPageValue = articlesPerPage ? articlesPerPage : 10;
    const res = await afterLoginApiAxiosPEM({
      method: "get",
      url: "doctor/get-all-articles",
      params: {
        keyword: keywordValue,
        specialization_id: localStorage.getItem("specialization"),
        page_number: pagenumberValue,
        articles_per_page: articlesPerPageValue,
        all_articles: allArticlesValue,
      },
    });

    if (res && res.data && res.data.response) {
      setallContents(res.data.response);
    }
  };
  // <==
  // callbacks ==>
  const onClickCallback = (isSelect, content) => {
    if (isSelect) {
      const tempArr = selectedContents;
      tempArr.push(content);
      props.setSelectedArticlesCallBack(tempArr);
      setSelectedContents(tempArr);
    } else {
      const updatedIds = selectedContents.filter(
        (item) => item.article_id !== content.article_id
      );
      props.setSelectedArticlesCallBack(updatedIds);
      setSelectedContents(updatedIds);
    }
  };

  const showErrorCallback = (showError) => {
    if (showError) {
      setMaxError(true);
    } else {
      setMaxError(false);
    }
  };
  // <==

  // Create UI ==>
  const renderErrorUI = () => {
    return (
      <div className="add-content-error-container">
        <div className="add-content-error">
          You cannot select more than 3 contents
        </div>
      </div>
    );
  };

  const renderList = () => {
    if (allContents) {
      const tempAllContent = allContents.filter(
        (content) =>
          !selectedContents.some(
            (data) => data.article_id === content.article_id
          )
      );
      const allContentsTemp = [...selectedContents, ...tempAllContent];

      return allContentsTemp.map((content, idx) => (
        <RectangeShapeRoundedImgCell
          viewType={cellViewType.Content}
          content={content}
          onClickCallback={onClickCallback}
          key={idx}
          isEnable={
            !selectedContents.some(
              (data) => data.article_id === content.article_id
            ) && selectedContents.length >= 3
          }
          isSelected={selectedContents.some(
            (data) => data.article_id === content.article_id
          )}
          showErrorCallback={showErrorCallback}
        />
      ));
    }
    return "";
  };
  // <==

  return (
    <div>
      {maxError ? renderErrorUI() : ""}
      {renderList()}
    </div>
  );
};

export default AddContentList;
