import React, { useState, useEffect } from "react";
import { Media } from "react-bootstrap";
import ConsultationInformation from "../DoctorProfile/ConsultationInformation/ConsultationInformation";
import afterLoginApiAxios from "../../Util/API Calls/afterLogin";
import { doctorProfileFiles } from "../../Util/API Calls/APIList";
import { PROCEDURE_SLOTS } from "../DoctorComponents/MyScheduleFees/Constants";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";

function PatientDetails({ appointmentID, doctorID }) {
  const [doctorDetails, setDoctorDetails] = useState({});

  const userId = localStorage.getItem(LOCALSTORAGEKEY.USER_ID);

  useEffect(() => {
    if (doctorID && doctorID !== "") {
      afterLoginApiAxios({
        method: "post",
        url: "/doctor/get-physical-info",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("id_token"),
        },
        data: {
          appointment_id: appointmentID,
          doctor_id: doctorID,
          "authorizer-principal-id": {
            user_type: 2,
            user_id: userId,
          },
          patient_id: userId,
        },
      }).then((response) => {
        setDoctorDetails(response.data.doctor_details);
      });
    }
  }, [appointmentID, doctorID]);

  let duration = PROCEDURE_SLOTS.find(
    (slot) => slot.slotType === Number(doctorDetails?.slot_type)
  );

  return (
    <>
      <div className="doctor-info-details p-3">
        <Media>
          <div className="rounded-circle shadow doctor-profile-div mr-3">
            <img
              src={
                doctorDetails?.profile_picture_url
                  ? doctorProfileFiles + doctorDetails?.profile_picture_url
                  : "https://via.placeholder.com/150?text=placeHolderImage"
              }
              width={95}
              height={95}
              className="rounded-circle"
            />
          </div>
          <Media.Body
            style={{ width: "calc(100% - 95px)" }}
            className="doctor_info"
          >
            <div className="mb-1 doctor-name left_side_doc">
              {doctorDetails?.doctor_name}
            </div>
            <div className="mb-1 doctor-prof">
              {doctorDetails?.specialization}
            </div>
            <div className="doctor-reg-no">
              Regd No: {doctorDetails?.medical_reg_no}
            </div>
          </Media.Body>
        </Media>
      </div>
      <div className="doctor-consultation-details">
        <ConsultationInformation
          durationTime={duration?.label}
          fees={doctorDetails?.consultation_fees_visible_to_patient}
        />
      </div>
      <div className="more-about-doctor p-3">
        <div className="doc-info">
          <div className="d-flex align-items-center mb-3">
            <div className="image-position">
              <img
                src={process.env.PUBLIC_URL + "/images/education-new.svg"}
                alt=""
              />
            </div>
            <div className="ml-3 education-block">
              <div className="education-text">Education</div>
              <div>{doctorDetails?.degree}</div>
            </div>
          </div>
          <div className="d-flex align-items-center mb-3">
            <div className="image-position">
              <img
                src={process.env.PUBLIC_URL + "/images/hospital-new.svg"}
                alt=""
              />
            </div>
            <div className="ml-3 about-block">
              <div className="about-text">Hospital</div>
              <div>{doctorDetails?.about}</div>
            </div>
          </div>
          <div className="d-flex align-items-center mb-3">
            <div className="image-position">
              <img
                src={process.env.PUBLIC_URL + "/images/about-new.svg"}
                alt=""
                id="image-book"
              />
            </div>
            <div className="ml-3 about-block">
              <div className="about-text">About</div>
              <div style={{ wordBreak: "break-word" }}>
                {doctorDetails?.description}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PatientDetails;
