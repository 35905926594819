import React, { Suspense } from "react";
import Loader from "../components/UI Component/Loader/Loader";

const PharmacyRoutes = React.lazy(() => import("../Routes/PharmacyRoutes"));

const PharmacySection = () => {
  return (
    <>
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        <PharmacyRoutes />
      </Suspense>
    </>
  );
};

export default PharmacySection;
