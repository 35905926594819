export const transformData = (data) => {
  let tempArray = [];

  let allObject = {
    categoryName: "all",
    queries: [],
  };
  tempArray.push(allObject);
  let allRef = tempArray.filter((x) => x.categoryName === "all");
  data.forEach((item) => {
    let foundUniqueCategory = tempArray.filter(
      (x) => x.categoryName === item.category
    );

    allRef[0].queries.push({ question: item.question, answer: item.answer });

    if (foundUniqueCategory.length === 0) {
      let tempObject = {
        categoryName: item.category,
        queries: [{ question: item.question, answer: item.answer }],
      };
      tempArray.push(tempObject);
    } else {
      foundUniqueCategory[0].queries.push({
        question: item.question,
        answer: item.answer,
      });
    }
  });
  return tempArray;
};
