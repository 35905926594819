import React, { Component } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import authAxios from "../Util/API Calls/authAxios";
import afterLoginApiAxios from "../Util/API Calls/afterLogin";
import { LOCALSTORAGEKEY } from "../Util/Helper/localStorageUtils";
import { C2CConsole } from "../Util/Helper/C2CConsole";

const formValid = (formErrors) => {
  let valid = true;
  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });
  return valid;
};

const onlyDigitRegex = RegExp(/^[0-9]+$/);

class BookingLogin extends Component {
  constructor(props, context) {
    super(props, context);
    var type = this.props.match.params.doctorType;

    //const { sid,start,date,doctor,new_user } = this.props.location.state;

    this.state = {
      sid: "",
      start: "",
      date: "",
      doctor: "",

      docType: type,
      phone_number: "",
      otp: "",
      formErrors: {
        phone_number: "",
        otp: "",
      },
      validation_msg: "",
    };
    this.handleChange.bind(this);
    this.onSubmit.bind(this);
    this.onOtpSend.bind(this);
    this.handleOtp.bind(this);
  }

  handleChange = (name) => (event) => {
    const target = event.target;
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;
    if (name == "otp") {
      formErrors.otp = "";
      this.setState(
        {
          otp: event.target.value,
          formErrors: formErrors,
        },
        () => {}
      );
    } else {
      if (value.length > 0) {
        formErrors.phone_number =
          onlyDigitRegex.test(value) && value.length >= 11
            ? ""
            : "Please enter valid Phone Number ,With Country Code";
        this.setState(
          {
            phone_number: event.target.value,
            formErrors: formErrors,
          },
          () => {}
        );
        //this.setState({formErrors});
      } else {
        formErrors.phone_number =
          value.length >= 11 ? "" : " Phone Number is Mandatory";
        this.setState(
          {
            phone_number: event.target.value,
            formErrors: formErrors,
          },
          () => {}
        );
      }
    }
  };

  handleOtp(event) {
    const target = event.target;
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;
    formErrors.otp = value.length > 0 ? "" : " Provide Data For OTP";
    this.setState(
      {
        otp: event.target.value,
        formErrors: formErrors,
      },
      () => {}
    );
  }

  componentWillMount() {
    var id_token = localStorage.getItem("id_token");
    if (id_token) {
      this.props.history.push("/");
    }
    localStorage.removeItem("id_token");
    localStorage.removeItem("user_type");
    localStorage.removeItem(LOCALSTORAGEKEY.USER_ID);
    localStorage.removeItem("user_name");
    localStorage.removeItem("full_name");

    var sid = localStorage.getItem("schedule_sid");
    var start = localStorage.getItem("schedule_start");
    var date = localStorage.getItem("schedule_date");
    var doctor = localStorage.getItem("schedule_doctor");
    var new_user = localStorage.getItem("schedule_new_user");

    this.setState(
      {
        sid: sid,
        start: start,
        date: date,
        doctor: doctor,
      },
      () => {}
    );
  }
  componentWillUnmount() {}

  onSubmit(event) {
    if (
      this.state.phone_number != "" &&
      this.state.otp != "" &&
      formValid(this.state.formErrors)
    ) {
      var phone_no = "+" + this.state.phone_number;

      var data = {
        phone_no: phone_no,
        user_otp: this.state.otp,
        user_type: "patient",
      };

      this.setState(
        {
          validation_msg: "",
        },
        () => {}
      );

      authAxios
        .post(
          "/login",
          {
            phone_no: phone_no,
            user_otp: this.state.otp,
            user_type: "patient",
          },
          {
            headers: {
              "Content-Type": "application/json",
              //"Authorization" : token
            },
          }
        )
        .then((response) => {
          if (response) {
            if (response.data.hasOwnProperty("statusCode")) {
              if (response.data.statusCode == 200) {
                localStorage.setItem("id_token", response.data.user.id_token);
                localStorage.setItem("user_name", response.data.user.user_name);
                localStorage.setItem("user_type", response.data.user.user_type);
                localStorage.setItem(
                  LOCALSTORAGEKEY.USER_ID,
                  response.data.user.user_id
                );
                localStorage.setItem("slot_id", this.state.sid);
                localStorage.setItem("start", this.state.start);
                localStorage.setItem("date", this.state.date);
                localStorage.setItem("doctor", this.state.doctor);

                this.setState({}, () => {});
                var message = response.data.message;
                swal({
                  title: "Login Successful!",
                  text: message,
                  type: "success",
                }).then(function () {});
                this.props.history.push("/appointments/book");
              } else {
                var message = response.data.message;
                swal({
                  title: "Login Failed!",
                  text: message,
                  type: "error",
                  icon: "warning",
                }).then(function () {
                  window.location.reload();
                });
              }
            } else {
              var message = "Something Went Wrong!!Try Again";
              swal({
                title: "Login Failed!",
                text: message,
                type: "error",
                icon: "warning",
              }).then(function () {});
            }
          } else {
            var message = "Something Went Wrong!!Try Again";
            swal({
              title: "Login Failed!",
              text: message,
              type: "error",
              icon: "warning",
            }).then(function () {});
          }
        })
        .catch(function (error) {
          C2CConsole.log("error in submit of booking login", error);
        });
    } else {
      let formErrors = this.state.formErrors;
      formErrors = {
        phone_number: "",
        otp: "",
      };
      if (!this.state.phone_number) {
        formErrors.phone_number = "Phone Number is Mandatory";
        this.setState({ formErrors });
      } else if (
        this.state.phone_number.length > 0 &&
        this.state.phone_number.length < 11
      ) {
        formErrors.phone_number =
          "Provide Valid Phone Number With Country Code";
        this.setState({ formErrors });
      } else {
        formErrors.otp = "Provide Valid Otp";
        this.setState({ formErrors });
      }
    }
  }
  onOtpSend(event) {
    let formErrors = this.state.formErrors;
    if (!this.state.phone_number || this.state.phone_number.length < 11) {
      formErrors.phone_number =
        "Please Provide Valid Phone Number With Country Code";
      this.setState({ formErrors });
    } else {
      var phonenumber = this.state.phone_number;
      var phone_number = "+" + phonenumber; //+918017029639

      afterLoginApiAxios
        .post("public/send-otp", {
          phone_no: phone_number,
          channel_id: "existing-user-register",
        })
        .then((response) => {
          if (response) {
            if (response.data.hasOwnProperty("statusCode")) {
              if (response.data.statusCode == 200) {
                this.setState({}, () => {});
                var message = response.data.message;
                swal({
                  title: "Success!",
                  text: message,
                  type: "success",
                }).then(function () {});
              } else {
                var message = response.data.message;
                swal({
                  title: "Failed!",
                  text: message,
                  type: "error",
                  icon: "warning",
                }).then(function () {
                  //window.location.reload();
                });
              }
            } else {
              var message = "Something Went Wrong!!Try Again";
              swal({
                title: "Failed!",
                text: message,
                type: "error",
                icon: "warning",
              }).then(function () {});
            }
          } else {
            var message = "Something Went Wrong!!Try Again";
            swal({
              title: "Failed!",
              text: message,
              type: "error",
              icon: "warning",
            }).then(function () {});
          }
        })
        .catch(function (error) {
          C2CConsole.log("error in send otp of booking login", error);
        });
    }
  }
  render() {
    var styles = {
      cardOdd: {
        backgroundColor: "#eeae01",
        color: "#454545",
      },
      cardEven: {
        backgroundColor: "#454545",
        color: "#eeae01",
      },
    };
    return (
      <div>
        <div className="row mt-4">
          <div className="col-sm-12 col-md-4 text-center">
            <img
              src={process.env.PUBLIC_URL + "/images/HN_logo.png"}
              style={{ width: "70%" }}
            />
          </div>
          <div className="col-sm-12 col-md-4 text-left pt-4">
            <Link to="/" className="mr-5" style={{ color: "#2f2f2f" }}>
              Home
            </Link>
          </div>
          <div className="col-sm-12 col-md-4 text-center loginButton pt-2">
            <Link
              to="/"
              className="btn btn-lg"
              style={{
                color: "#454545",
                backgroundColor: "#eeae01",
                padding: "8px 0px",
              }}
            >
              <b>Book Appointment</b>
            </Link>
          </div>
        </div>
        <div className="container">
          <div className="row mb-5" style={{ marginTop: "5vh" }}>
            <div
              className="col-sm-12 col-lg-4 offset-lg-4 text-left"
              style={{ paddingTop: "15vh" }}
            >
              <h2>
                <strong>
                  <b>User Login</b>
                </strong>
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-4 offset-lg-4 text-center">
              <div className="row">
                <div className="col-sm-12 text-left">
                  <label>
                    Phone No
                    <small style={{ color: "#cecece" }}>
                      <br />
                      (Please put country code followed by the number)
                    </small>
                  </label>
                  <input
                    type="text"
                    name="phone_number"
                    className="w-100"
                    onChange={this.handleChange("phone_number")}
                  />
                  <br />
                  <button
                    className="btn btn-sm mt-2"
                    style={{
                      color: "#ffffff",
                      backgroundColor: "#454545",
                      padding: "8px 30px",
                    }}
                    onClick={this.onOtpSend.bind(this)}
                  >
                    <b>Send OTP</b>
                  </button>
                  <br />
                  <span style={{ color: "red" }}>
                    {this.state.formErrors["phone_number"]}
                  </span>
                </div>
                <div className="col-sm-12 text-left mt-2">
                  <label>OTP</label>
                  <input
                    type="text"
                    name="otp"
                    className="w-100"
                    onChange={this.handleChange("otp")}
                  />
                  <br />
                  <span style={{ color: "red" }}>
                    {this.state.formErrors["otp"]}
                  </span>
                  <br />
                </div>
                <span style={{ color: "red" }}>
                  {this.state.validation_msg}
                </span>
                <div className="col-sm-4 mt-4">
                  <button
                    className="btn btn-lg mt-2"
                    style={{
                      color: "#454545",
                      backgroundColor: "#eeae01",
                      padding: "8px 70px",
                    }}
                    onClick={this.onSubmit.bind(this)}
                  >
                    <b>Login</b>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-sm-12 text-center">
              <h5 className="text-dark">
                ** In case of emergency, please visit your nearest hospital.
              </h5>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div
            className="row mt-4 pt-3"
            style={{ borderTop: "4px solid #eeae0160" }}
          >
            <div className="col-sm-12 text-center">
              <h5 style={{ color: "#eeae01" }}>
                Are you a physician ?{" "}
                <a href="#" style={{ color: "#454545" }}>
                  Join our doctors team.
                </a>
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default BookingLogin;
