import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import FilledInput from "@material-ui/core/FilledInput";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import beforeLoginAxios from "../../Util/API Calls/beforeLogin";
import swal from "sweetalert";
import ErrorIcon from "@material-ui/icons/Error";

import { useHistory, Redirect } from "react-router-dom";
import {
  setCookieForUser,
  setDynamicCookieForUser,
} from "../../Util/API Calls/cookieHelper";
import { useCookies } from "react-cookie";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";

function PartialUserEnterPassword(props) {
  let { phoneNumber = "" } = props;
  let history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"]);

  function handleForgotPassword() {
    history.replace("/doctor/login/partial-doctor/set-password");
  }

  function handlePassword(val) {
    setPassword(val);
  }

  const handleContinueClick = (e) => {
    e.preventDefault();
    //validations
    // history.replace("/doctor/login/enter-password");
    //api call

    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
      return;
    } else {
      setPasswordError(false);
    }

    beforeLoginAxios
      .post("doctor/webinar-login", {
        phone_no: `+91${phoneNumber}`,
        password: password,
      })
      .then((response) => {
        if (response) {
          if (response.data.hasOwnProperty("statusCode")) {
            if (response.data.statusCode == 200) {
              localStorage.setItem("id_token", response.data.user.id_token);
              localStorage.setItem(
                LOCALSTORAGEKEY.USER_ID,
                response.data.user.user_id
              );
              localStorage.setItem("user_type", response.data.user.user_type);
              setCookieForUser(response.data.user.id_token, setCookie);
              setDynamicCookieForUser(
                LOCALSTORAGEKEY.USER_ID,
                response.data.user.user_id,
                setCookie
              );
              setDynamicCookieForUser(
                "user_type",
                response.data.user.user_type,
                setCookie
              );
              localStorage.setItem("doctorPhone", `+91${phoneNumber}`);
              localStorage.setItem("onBoardedDoctor", true);
              localStorage.setItem(
                "specialization",
                response?.data?.user?.specialization_id
              );
              setTimeout(() => {
                if (localStorage.getItem("id_token")) {
                  history.replace(
                    "/doctor/account/dashboard?utm_source=webapp&utm_medium=onboarding"
                  );
                }
              }, 100);
            } else if (response.data.statusCode == 510) {
              setPasswordError("Incorrect password entered. Please try again");
            }
          }
        }
      });
  };

  const [focusOnOTP, setFocusOnOTP] = useState(true);
  const [showSetPassword, setShowSetPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);

  if (phoneNumber == "") {
    // phoneNumber = "1234123412";
    return <Redirect to="/doctor/login/" />;
  }

  return (
    <div
      className="container h-100  d-flex align-items-center justify-content-center flex-column"
      style={{ position: "relative" }}
    >
      <div className="w-100">
        <ArrowBackIcon
          onClick={() => {
            history.replace("/doctor/login/");
          }}
          style={{ float: "left" }}
          className="color-blue cursor-pointer"
        />
        <h3 className="text-center align-top">Login</h3>
      </div>
      <h6 className="text-center">Enter your password to login</h6>
      <div className="w-100 d-flex flex-row justify-content-between mt-4">
        <div className="d-inline-block text-muted">+91-{phoneNumber}</div>
        <Edit className="color-blue cursor-pointer" />
      </div>
      <div className="w-100 mt-3">
        <form
          className="d-flex flex-column align-items-center justify-content-center"
          onSubmit={(e) => {
            handleContinueClick(e);
          }}
        >
          <FormControl
            hiddenLabel="true"
            size="small"
            margin="dense"
            fullWidth="true"
          >
            <FilledInput
              id="OTP-input"
              aria-describedby="my-helper-text"
              aria-label="Set-password"
              placeholder="Enter Password"
              disableUnderline="true"
              autoFocus={!focusOnOTP}
              type={showPassword ? "text" : "password"}
              endAdornment={
                passwordError ? (
                  <ErrorIcon style={{ color: "#e84444" }} />
                ) : (
                  <ShowPassword
                    setShowPassword={setShowPassword}
                    showPassword={showPassword}
                  />
                )
              }
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              value={password}
            />
          </FormControl>

          <span
            className="align-self-end cursor-pointer textLink border-bottom-0"
            onClick={() => {
              handleForgotPassword();
            }}
          >
            Forgot Password?
          </span>
          <div className="input-error align-self-center text-center mt-3">
            {passwordError ? passwordError : ""}
          </div>
          <button className="btn primary-cta-button rounded-pill mt-3 align-self-center py-2 px-4">
            Submit
          </button>
        </form>
      </div>
      <div
        className="separator w-50 mt-3"
        style={{ margin: "auto", left: "50px" }}
      >
        Or
      </div>
      <div
        className="mt-3 textLink font-weight-bold"
        style={{ minWidth: "fit-content" }}
      >
        <div
          onClick={() => {
            history.replace("/doctor/login/partial-doctor/otp-login");
          }}
        >
          Login with OTP
        </div>
      </div>
    </div>
  );
}

export default PartialUserEnterPassword;

const Edit = () => {
  let history = useHistory();
  return (
    <span
      className="color-blue cursor-pointer"
      onClick={() => {
        history.replace("/doctor/login");
      }}
    >
      Edit
      <EditIcon className="color-blue cursor-pointer" fontSize="inherit" />
    </span>
  );
};

function ShowPassword(props) {
  const { setShowPassword, showPassword = false } = props;

  if (showPassword === false) {
    return (
      <span
        className="color-blue cursor-pointer"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        <VisibilityIcon />
      </span>
    );
  } else {
    return (
      <span
        className="color-blue cursor-pointer"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        <VisibilityOffIcon />
      </span>
    );
  }
}
