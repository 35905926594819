// dependencies imports ⇒
import React from "react";
import { useHistory } from "react-router-dom";
// assets and scss imports ⇒
import "./signInFormLayout.scss";
import C2CLogo from "../../../assets/doctorLoginAssets/Group 4.svg";

// project file imports(UI components) ⇒

// const file imports(helper methods, etc) ⇒

const SignInFormLayout = (props) => {
  const { heading, subheading } = props;
  // const declarations ⇒
  const history = useHistory();

  // let or var declarations ⇒

  // life cycle methods ⇒

  // API Calls ⇒

  // Helper methods ⇒

  // Actions ⇒

  // create UI ⇒

  const signInFormLayoutUI = () => {
    return (
      <div className="sifl-container">
        <div className="sifl-c2c-logo-container">
          <a href="/">
            <img
              src={C2CLogo}
              alt=""
              className="sifl-c2c-logo"
            />
          </a>
        </div>
        <div className="sifl-heading">{heading}</div>
        <div className="sifl-subheading">{subheading}</div>
        <div className="sifl-form-container">
          {typeof props.children === "function"
            ? props.children()
            : props.children}
        </div>
      </div>
    );
  };
  return <div className="sign-in-form-layout">{signInFormLayoutUI()}</div>;
};

// export ⇒

export default SignInFormLayout;
