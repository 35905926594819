/*
 * Prescription Constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

// There are two kinds of actions :
//     1. UI actions
//     2. Side effects

export const RESET_STATE = "c2c/Presctiption/RESET_STATE";

export const TEST = "c2c/Presctiption/TEST";
export const TEST_SUCCESS = "c2c/Presctiption/TEST_SUCCESS";
export const TEST_FAILED = "c2c/Presctiption/TEST_FAILED";

export const ADD_CHIEF_COMPLAINT = "c2c/Presctiption/ADD_CHIEF_COMPLAINT";
export const UPDATE_CHIEF_COMPLAINT = "c2c/Presctiption/UPDATE_CHIEF_COMPLAINT";

export const ADD_DIAGNOSIS_ITEM = "c2c/Presctiption/ADD_DIAGNOSIS_ITEM";
export const UPDATE_DIAGNOSIS_ITEM = "c2c/Presctiption/UPDATE_DIAGNOSIS_ITEM";

export const UPDATE_PATIENT_DATA = "c2c/Presctiption/UPDATE_PATIENT_DATA";
export const UPDATE_PATIENT_VITALS = "c2c/Presctiption/UPDATE_PATIENT_VITALS";
export const UPDATE_DRUG_ITEM_DATA = "c2c/Presctiption/UPDATE_DRUG_ITEM_DATA";
export const CLEAR_DRUG_ITEM_DATA = "c2c/Presctiption/CLEAR_DRUG_ITEM_DATA";
export const ADD_NEW_DRUG_ITEM = "c2c/Presctiption/ADD_NEW_DRUG_ITEM";

export const UPDATE_EXAM_ITEM_DATA = "c2c/Presctiption/UPDATE_EXAM_ITEM_DATA";
export const CLEAR_EXAM_ITEM_DATA = "c2c/Presctiption/CLEAR_EXAM_ITEM_DATA";
export const ADD_NEW_EXAM_ITEM = "c2c/Presctiption/ADD_NEW_EXAM_ITEM";

export const PESCRIPTION_FORM_PROPERTY_UPDATE =
  "c2c/Presctiption/PESCRIPTION_FORM_PROPERTY_UPDATE";

export const DOCTOR_NOTES_UPDATE = "c2c/Presctiption/DOCTOR_NOTES_UPDATE";

export const SHARE_PRESCTIPTION_BUTTON_CLICKED =
  "c2c/Presctiption/SHARE_PRESCTIPTION_BUTTON_CLICKED";

export const VALIDATE_FORM = "c2c/Presctiption/VALIDATE_FORM";

export const SET_USER_TOKEN = "c2c/Presctiption/SET_USER_TOKEN";
export const SET_USER_TYPE = "c2c/Presctiption/SET_USER_TYPE";
export const SET_APPOINTMENT_DATA = "c2c/Presctiption/SET_APPOINTMENT_DATA";

export const SAVE_FORM = "c2c/Presctiption/SAVE_FORM";
export const GET_VITALS = "c2c/Presctiption/GET_VITALS";
export const LOAD_PATIENT_VITALS_FAILED =
  "c2c/Presctiption/LOAD_PATIENT_VITALS_FAILED";
export const SAVE_FORM_SUCCESS = "c2c/Presctiption/SAVE_FORM_SUCCESS";
export const SAVE_FORM_FAILED = "c2c/Presctiption/SAVE_FORM_FAILED";
export const SAVE_FORM_REJECTED = "c2c/Presctiption/SAVE_FORM_REJECTED";

export const PREVIEW_FORM = "c2c/Presctiption/PREVIEW_FORM";
export const PREVIEW_FORM_SUCCESS = "c2c/Presctiption/PREVIEW_FORM_SUCCESS";
export const PREVIEW_FORM_FAILED = "c2c/Presctiption/PREVIEW_FORM_FAILED";

export const SHARE_FORM = "c2c/Presctiption/SHARE_FORM";
export const SHARE_FORM_SUCCESS = "c2c/Presctiption/SHARE_FORM_SUCCESS";
export const SHARE_FORM_FAILED = "c2c/Presctiption/SHARE_FORM_FAILED";

export const INITALIZE_AUTOSAVE = "c2c/Presctiption/INITALIZE_AUTOSAVE";

export const LOAD_LAST_AUTOSAVED = "c2c/Presctiption/LOAD_LAST_AUTOSAVED";
export const LOAD_LAST_AUTOSAVED_SUCCESS =
  "c2c/Presctiption/LOAD_LAST_AUTOSAVED_SUCCESS";
export const LOAD_LAST_AUTOSAVED_FAILED =
  "c2c/Presctiption/LOAD_LAST_AUTOSAVED_FAILED";
export const LOAD_PATIENT_VITALS = "c2c/Presctiption/LOAD_PATIENT_VITALS";
export const HIDE_PREVIEW_FORM = "c2c/Presctiption/HIDE_PREVIEW_FORM";

export const SET_NEW_DATE = "c2c/Presctiption/SET_NEW_DATE";
export const DELETE_FOLLOW_UP_DATE = "c2c/Presctiption/DELETE_FOLLOW_UP_DATE";

export const SHOW_PRESCRIPTION_MODAL =
  "c2c/Presctiption/SHOW_PRESCRIPTION_MODAL";
export const HIDE_PRESCRIPTION_MODAL =
  "c2c/Presctiption/HIDE_PRESCRIPTION_MODAL";

export const FETCH_PRESCRIPTION_DETAILS =
  "c2c/Presctiption/FETCH_PRESCRIPTION_DETAILS";
export const FETCH_DROP_DOWN_DETAIL_SUCCESS =
  "c2c/Presctiption/FETCH_DROP_DOWN_DETAIL_SUCCESS";
export const FETCH_DROP_DOWN_DETAIL_FAILED =
  "c2c/Presctiption/FETCH_DROP_DOWN_DETAIL_FAILED";
export const REMOVE_DROP_DOWN_OPTION =
  "c2c/Presctiption/REMOVE_DROP_DOWN_OPTION";
export const DELETE_COMFORMATION_MODAL_SHOW =
  "c2c/Presctiption/DELETE_COMFORMATION_MODAL_SHOW";
export const DELETE_COMFORMATION_MODAL_SUCCESS =
  "c2c/Presctiption/DELETE_COMFORMATION_MODAL_SUCCESS";
export const DELETE_COMFORMATION_MODAL_CANCEL =
  "c2c/Presctiption/DELETE_COMFORMATION_MODAL_CANCEL";
export const REMOVE_DATA_SUCCESSFULL =
  "c2c/Presctiption/REMOVE_DATA_SUCCESSFULL";
export const REMOVE_DATA_FAILED = "c2c/Presctiption/REMOVE_DATA_FAILED";

export const REMOVE_DRUGITEM = "c2c/Prescription/REMOVE_DRUGITEM";
export const REMOVE_EXAMINITEM = "c2c/Prescription/REMOVE_EXAMINITEM";
export const GET_PREVIOUS_PRESCRIPTION =
  "c2c/Prescription/GET_PREVIOUS_PRESCRIPTION";

export const PRESCRIPTION_TEMPLATE = "c2c/Presctiption/PRESCRIPTION_TEMPLATE";
export const GET_SELECTED_TEMPLATE = "c2c/Presctiption/GET_SELECTED_TEMPLATE";
export const FETCH_DRUG_ON_SEARCH = "c2c/Presctiption/FETCH_DRUG_ON_SEARCH";
export const CHECK_MANUAL_RX_SHARED = "c2c/Presctiption/CHECK_MANUAL_RX_SHARED";

//this is okay!

//we are not adding any new patient data rows, hence we don't need to do this
