// dependecies import ==>
import React from "react";
// assets and scss import ==>
import "./primaryButton.scss";
// project file import ==>
// constant import ==>

const PrimaryButton = (props) => {
  const { title, disabled, handleButtonClick } = props;

  // Display UI ===>

  const displayPrimaryButton = () => {
    return (
      <div
        className={
          "primay-button-container " +
          (disabled ? "primay-button-container-disabled" : "")
        }
        onClick={() => (!disabled ? handleButtonClick() : "")}
      >
        {title ?? "Yes"}
      </div>
    );
  };

  // <===

  return <>{displayPrimaryButton()}</>;
};

export default PrimaryButton;
