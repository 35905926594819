import afterLoginApiAxios from "../../../Util/API Calls/afterLogin";
import { LOCALSTORAGEKEY } from "../../../Util/Helper/localStorageUtils";

export const getFeedbackQuestionFromQR = async (api) => {
  const res = await afterLoginApiAxios({
    method: "get",
    url: api,
    headers: {
      authorization: localStorage.getItem(LOCALSTORAGEKEY.ID_TOKEN),
    },
  });
  if (res) {
    return [res, false];
  }
  return [null, true]; // [response, error]
};

export const submitQRFeedback = async (api, payload) => {
  const res = await afterLoginApiAxios({
    method: "post",
    url: api,
    data: payload,
    headers: {
      authorization: localStorage.getItem(LOCALSTORAGEKEY.ID_TOKEN),
    },
  });
  if (res) {
    return [res, false];
  }
  return [null, true]; // [response, error]
};
