import React, { memo, Component } from "react";
import { Link } from "react-router-dom";
import "../../src/components/styles/GlobalFooter.scss";
import { connect } from "react-redux";
import afterLoginApiAxios from "../Util/API Calls/afterLogin";
import { compose } from "redux";

class GlobalFooter extends Component {
  getGlobalTemplates = (props) => {
    afterLoginApiAxios
      .get("/public/get-customer-support-details")
      .then(function (response) {
        props.dispatch({
          type: "UPDATE_MOBILE_NUMBER",
          payload: {
            mobileNumber: response.data.customer_support_no,
            emailId: response.data.customer_support_emailid,
          },
        });
        localStorage.setItem("mobileNumber", response.data.customer_support_no);
        localStorage.setItem("emailId", response.data.customer_support_emailid);
      })
      .catch(function (error) {
        props.dispatch({
          type: "UPDATE_MOBILE_NUMBER",
          payload: {
            mobileNumber: "(033) 405 85600",
            emailId: "hello@connect2clinic.com",
          },
        });
      })
      .then(function (result) {});
  };

  componentDidMount(props) {
    const { mobileNumber, emailId } = this.props;
    if (!mobileNumber || !emailId || mobileNumber == "" || emailId == "") {
      this.getGlobalTemplates(this.props);
    }
  }

  componentWillMount(props) {
    const { mobileNumber, emailId } = this.props;
    if (!mobileNumber || !emailId) {
      this.getGlobalTemplates(this.props);
    }
  }

  render() {
    return (
      <div className="pt-4 pb-1 footer">
        <div className="container">
          <div className="row m-0">
            <div className="col-lg-5 col-xs-12 about-company text-left">
              <img
                className="p-2"
                src={process.env.PUBLIC_URL + "/images/HN_logo.png"}
                style={{ width: "60%" }}
              />
            </div>
            <div className="pl-5 col-lg-3 col-xs-12 links mt-4">
              <h4 className="mt-lg-0 mt-sm-3" style={{ color: "#F969A4" }}>
                Links
              </h4>
              <ul className="m-0 p-0" style={{ listStyleType: "none" }}>
                <p className="footer-link-text">
                  <a
                    href="../../terms.html"
                    target="_blank"
                    style={{ color: "#fff" }}
                  >
                    Terms of Use
                  </a>
                </p>
                <p className="footer-link-text">
                  <a
                    href="../../privacy.html"
                    target="_blank"
                    style={{ color: "#fff" }}
                  >
                    Privacy Policy
                  </a>
                </p>
                <p className="footer-link-text">
                  <a
                    href="../../hcp.html"
                    target="_blank"
                    style={{ color: "#fff" }}
                  >
                    HCP Policy
                  </a>
                </p>
                <p className="footer-link-text">
                  <a href="/help" style={{ color: "#fff" }}>
                    FAQ's
                  </a>
                </p>
                <p className="footer-link-text">
                  <a href="/investors" style={{ color: "#fff" }}>
                    Investors
                  </a>
                </p>
              </ul>
            </div>
            <div className="pl-5 col-lg-4 col-xs-12 location mt-4">
              <h4 className="mt-lg-0 mt-sm-4" style={{ color: "#F969A4" }}>
                Customer Support
              </h4>
              <p>Get in touch with our customer happiness team 24/7</p>
              <p className="mb-0">
                <i className="fa fa-phone mr-3"></i>{" "}
                <a
                  href={"tel:" + this.props.mobileNumber}
                  className="globalfooter"
                >
                  {this.props.mobileNumber}
                </a>
              </p>
              <p>
                <i className="fa fa-envelope-o mr-3"></i>{" "}
                <a
                  href={"mailto:" + this.props.emailId}
                  className="globalfooter"
                >
                  {this.props.emailId}
                </a>
              </p>
              {/* <p>
                <a href="../../ticket.html" target="_blank">
                  Raise a ticket
                </a>
              </p> */}
            </div>
          </div>
          <div className="row m-0">
            <div className="col copyright footer-copyright-text">
              <p className="">
                <small className="text-white-50">
                  © 2021. All Rights Reserved.
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mobileNumber: state.global.mobileNumber,
  emailId: state.global.emailId,
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(GlobalFooter);
