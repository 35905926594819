import React from "react";
import { useHistory } from "react-router-dom";

function Details({ summary, back, disableProceedBtn, proceed }) {
  let history = useHistory();
  return (
    <div className="payment-content">
      <div className="text-center payment-title">PAYMENT</div>
      <div className="payment-details mx-auto">
        <div>
          <div style={{ marginBottom: "10px" }}>
            <span className="payment-details-title">Patient Name: </span>
            <span className="payment-details-value">
              {summary.patient_name}
            </span>
          </div>
          <div style={{ marginBottom: "12px" }}>
            <span className="payment-details-title">Patient Email: </span>
            <span className="payment-details-value">
              {summary.patient_email}
            </span>
          </div>
        </div>
        <div>
          <div className="payment-details-section">
            <div className="d-flex justify-content-between">
              {+summary?.partial_amount > 0 ? (
                <>
                  <div className="payment-details-title">Part Payment</div>
                  <div className="payment-details-value">
                    ₹{summary.partial_amount}
                  </div>
                </>
              ) : (
                <>
                  <div className="payment-details-title">
                    {summary?.account_type === 1
                      ? "Procedure Fee"
                      : `Dr.${
                          summary?.doctor_name?.split(" ")[1]
                        }'s Consultation Fee`}
                  </div>
                  <div className="payment-details-value">
                    ₹{summary.consultation_fees}
                  </div>
                </>
              )}
            </div>
            {summary?.total_discount > 0 && +summary?.partial_amount <= 0 && (
              <div
                className="d-flex justify-content-between"
                style={{ marginTop: "6px" }}
              >
                <div
                  className="payment-details-title"
                  style={{ color: "#10A74A" }}
                >
                  Total Discount
                </div>
                <div
                  className="payment-details-value"
                  style={{ color: "#10A74A" }}
                >
                  -₹{summary.total_discount}
                </div>
              </div>
            )}
            {+summary?.custom_gst_in_rupees > 0 &&
              +summary?.partial_amount == 0 && (
                <div
                  className="d-flex justify-content-between"
                  style={{ marginTop: "6px" }}
                >
                  <div className="payment-details-title payment-details-gst-title">
                    GST
                  </div>
                  <div className="payment-details-value">
                    ₹{summary.custom_gst_in_rupees}
                  </div>
                </div>
              )}
          </div>
          <div className="payment-details-section">
            <div className="d-flex justify-content-between">
              <div className="payment-details-title">Convenience Fee</div>
              <div className="payment-details-value">
                ₹{summary.convenience_fees}
              </div>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: "6px" }}
            >
              <div className="payment-details-title payment-details-gst-title">
                GST
              </div>
              <div className="payment-details-value">
                ₹{summary.gst_on_convenience_fee}
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-between"
          style={{ paddingTop: "4px" }}
        >
          <div className="payment-details-title">Total Fee</div>{" "}
          <div className="payment-details-value">₹{summary.total_fees}</div>
        </div>
        <hr className="payment-margin" />
        <div className="text-center" style={{ paddingTop: "8px" }}>
          <button
            className="payment-back-btn"
            onClick={() => history.push("/patient/account/dashboard")}
          >
            Back
          </button>
          <button
            className={
              "payment-proceed-btn " +
              (disableProceedBtn ? "payment-proceed-disabled-btn" : "")
            }
            onClick={() => proceed()}
            disabled={disableProceedBtn}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
}

export default Details;
