import React, { useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import moment from "moment";
import "./previousPatientsStyles.scss";
import "./tooltip.scss";
import { Link } from "react-router-dom";

export default function PreviousPatientRow(props) {
  const { appointment, index, actions, viewAppointment } = props;
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");
  let interval = useRef();
  let dots = "...";
  const startTimer = () => {
    const endTime = moment(appointment.appointment_end_date_time);
    interval = setInterval(() => {
      let distance = moment(moment(endTime).add(48, "hours")).diff(moment());
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 48)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval.current);
    };
  }, []);

  function getPrescriptionButton(notificationSent, action) {
    if (notificationSent == null || notificationSent == undefined) {
      return <div>No Data</div>;
    } else {
      if (notificationSent === "False") {
        const endTime = moment(appointment.appointment_end_date_time).add(
          48,
          "hours"
        );
        const editButtonStatus = moment().isAfter(endTime); // true
        let is_allow = localStorage.getItem("edit_prescriptions_allow");
        if (editButtonStatus) {
          return (
            <div class>
              <button
                className={`rounded-pill primary-cta-button py-2 `}
                onClick={() => action.editPrescriptionAction(appointment)}
                disabled={editButtonStatus}
              >
                <i className="fa fa-pencil mx-2" aria-hidden="true"></i>Edit
              </button>
            </div>
          );
        } else {
          startTimer();
          return (
            <div>
              <button
                className={`rounded-pill primary-cta-button py-2 `}
                onClick={() => action.editPrescriptionAction(appointment)}
                disabled={is_allow}
              >
                <i className="fa fa-pencil mx-2" aria-hidden="true"></i>Edit
              </button>
              {
                <div>
                  <small
                    className="cursor-pointer textLink text-center"
                    style={{ color: "#707070" }}
                  >
                    Expires in {timerHours}:
                    {timerMinutes < 10 ? `0${timerMinutes}` : timerMinutes}:
                    {timerSeconds < 10 ? `0${timerSeconds}` : timerSeconds}
                  </small>
                </div>
              }
            </div>
          );
        }
      }

      return (
        <Link to={"/prescription/" + appointment.id}>
          <button className="view-press-button rounded-pill secondary-button py-2">
            <i className="fa fa-eye mx-2" aria-hidden="true"></i>View
          </button>
        </Link>
      );
    }
  }

  function getActionSheet() {
    //return multiple icons / actions from in here\
    return (
      <div>
        <button
          className={
            appointment.is_document ? "action-button" : "action-button-disabled"
          }
          data-tooltip="View Document"
          data-tooltip-conf="bottom"
          onClick={() =>
            appointment.is_document && actions.viewDocumentAction(appointment)
          }
        >
          <i className="fa fa-file-text mx-2" aria-hidden="true"></i>
        </button>
        <button
          className="action-button view-details-button"
          data-tooltip="View detail"
          data-tooltip-conf="bottom"
          onClick={() => {
            viewAppointment(appointment);
          }}
        >
          <i className="fa fa-user mx-2" aria-hidden="true"></i>
        </button>
      </div>
    );
  }
  return (
    <>
      <Row
        lg={6}
        xs={1}
        className="previous-patient  patient-row-individual-row align-items-center shadow-sm my-lg-2 mx-lg-2 border rounded-sm my-2 mx-0 text-md-left text-center"
      >
        <Col className="flex-column align-items-center justify-content-center mb-2 mb-md-0">
          <div className="mobile-responsive-patient_name">
            {appointment.patient_name}
          </div>
          {/* <div className="patient-row-patient-id text-truncate">
          ID:{appointment.patient_id}
        </div> */}
        </Col>
        <Col className="mb-2 mb-md-0">
          {appointment.is_follow_up == "True" ? (
            <button className="customBadge" style={{ width: "100%" }}>
              FOLLOW UP
            </button>
          ) : appointment.is_follow_up == "False" &&
            appointment.procedure_name == "" ? (
            <button className="customButton px-4" style={{ width: "100%" }}>
              INITIAL
            </button>
          ) : appointment.procedure_name.length > 10 ? (
            <button
              className="customButton px-1"
              style={{ width: "100%", textTransform: "uppercase" }}
            >
              {appointment.procedure_name.substring(0, 10) + dots}
            </button>
          ) : (
            <button
              className="customButton px-1"
              style={{ width: "100%", textTransform: "uppercase" }}
            >
              {appointment.procedure_name}
            </button>
          )}
        </Col>
        <Col className="mb-2 mb-md-0">{appointment.phone_no}</Col>
        <Col className="mb-2 mb-md-0">
          {moment(appointment.appointment_start_date_time).format(
            "MMM DD, YYYY"
          )}
          <div className="patient-row-patient-id text-truncate">
            {moment(appointment.appointment_start_date_time).format("hh:mm A")}
          </div>
        </Col>
        <Col className="mb-2 mb-md-0">
          {getActionSheet()}
          {/* <button
          className="secondary-button rounded-pill py-2"
          onClick={() => {
            viewAppointment(appointment);
          }}
        >
          <i className="fa fa-eye mx-2" aria-hidden="true"></i>
          Details
        </button> */}
        </Col>
        <Col className="mb-2 mb-md-0">
          {getPrescriptionButton(
            appointment.prescription_notification_sent,
            actions
          )}
        </Col>
      </Row>

      <div
        key={index}
        className="previous-patient-mobile patient-row-individual-row shadow-sm border rounded-sm my-2 mx-2"
      >
        <div className="m-2">
          <div className="row">
            <div className="col patient-row-header">PATIENT NAME</div>
            <div className="col patient-row-header">PRESCRIPTION</div>
          </div>
          <div className="row mt-1">
            <div className="col">
              {appointment.patient_name}
              {/* <div className="mypatient-row-patient-id text-truncate">
                  {appointment.phone_no}
                </div> */}
            </div>
            <div
              className={`col ${
                !(
                  appointment.prescription_notification_sent == null ||
                  appointment.prescription_notification_sent == undefined
                ) && !(appointment.prescription_notification_sent === "False")
                  ? "view-press-button"
                  : ""
              }`}
            >
              {getPrescriptionButton(
                appointment.prescription_notification_sent,
                actions
              )}
            </div>
          </div>
        </div>

        <div className="m-2">
          <div className="row">
            <div className="col patient-row-header">MOBILE NUMBER</div>
            <div className="col patient-row-header">DATE AND TIME</div>
          </div>

          <div className="row mt-1">
            <div className="col">{appointment.phone_no}</div>
            <div className="col ">
              {moment(appointment.appointment_start_date_time).format(
                "DD MMM, YYYY"
              )}
              <div className="mypatient-row-patient-id text-truncate">
                <small>
                  {moment(appointment.appointment_start_date_time).format(
                    "hh:mm A"
                  )}
                </small>
              </div>
            </div>
          </div>
        </div>

        <div className="m-2">
          <div className="row">
            <div className="col patient-row-header">CONSULTATION TYPE</div>
          </div>
          <div className="row mt-1">
            <div className="col ">
              {appointment.is_follow_up == "True" ? (
                // <button className="customBadge patient-history-followUp-button">
                <button className="customBadge" style={{ width: "40%" }}>
                  FOLLOW UP
                </button>
              ) : appointment.is_follow_up == "False" &&
                appointment.procedure_name == "" ? (
                <button className="customButton px-1" style={{ width: "40%" }}>
                  INITIAL
                </button>
              ) : appointment.procedure_name.length > 10 ? (
                <button
                  className="customButton px-1"
                  style={{ width: "40%", textTransform: "uppercase" }}
                >
                  {appointment.procedure_name.substring(0, 10) + dots}
                </button>
              ) : (
                <button
                  className="customButton px-1"
                  style={{ width: "40%", textTransform: "uppercase" }}
                >
                  {appointment.procedure_name}
                </button>
              )}
            </div>
          </div>
        </div>
        <hr className="mb-0" />
        <div className="text-center justify-content-center align-items-center">
          <div className="row">
            <div className="col" style={{ borderRight: "1px solid #e0e0e0" }}>
              <i
                className="fa fa-file-text mx-2"
                style={{
                  color: appointment.is_document ? "#17BAE0" : "lightgrey",
                }}
                aria-hidden="true"
              ></i>
              <br />
              <button
                style={{ fontSize: "12px", color: "black" }}
                className={
                  appointment.is_document
                    ? "action-button"
                    : "action-button-disabled"
                }
                data-tooltip="View Documents"
                data-tooltip-conf="bottom"
                onClick={() =>
                  appointment.is_document &&
                  actions.viewDocumentAction(appointment)
                }
              >
                VIEW DOCUMENTS
              </button>
            </div>
            <div className="col view-details-button">
              <i
                className="fa fa-user mx-2"
                aria-hidden="true"
                style={{ color: "#17BAE0" }}
              ></i>
              <br />
              <button
                style={{ fontSize: "12px", color: "black" }}
                className="action-button"
                data-tooltip="View detail"
                data-tooltip-conf="bottom"
                onClick={() => {
                  viewAppointment(appointment);
                }}
              >
                VIEW DETAILS
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
