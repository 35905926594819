// dependencies imports ⇒
import React, { useRef } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// assets and scss imports ⇒
import "./phoneNumberInputWithAction.scss";
import Flag from "../../../assets/doctorLoginAssets/Flags.svg";
import { Spinner } from "react-bootstrap";
// project file imports(UI components) ⇒

// const file imports(helper methods, etc) ⇒

const PhoneNumberInputWithAction = (props) => {
  const {
    placeholder,
    inputValue,
    showRegisterButtonLoader,
    handleInputChange,
    handleInputAction,
    handleKeyDown,
    autoFocus,
    hideActionIcon,
  } = props;
  // const declarations ⇒
  const phoneNumberRef = useRef();
  const arrowClassName =
    inputValue?.length === 10 ? "pniwa-arrow-enabled" : "pniwa-arrow-disabled";
  // let or var declarations ⇒

  // life cycle methods ⇒

  // API Calls ⇒

  // Helper methods ⇒

  // Actions ⇒

  const handleOnChange = (e) => {
    let value = e.target.value;
    if (value?.length <= 10) {
      handleInputChange && handleInputChange(value);
    }
  };

  const handleInputKeydown = (e) => {
    (e.key?.toLowerCase() === "e" ||
      e.key === "+" ||
      e.key === "-" ||
      e.key === "." ||
      e.key === "ArrowUp" ||
      e.key === "ArrowDown") &&
      e.preventDefault();
    if (handleKeyDown) handleKeyDown(e);
  };

  // create UI ⇒

  const phoneNumberInputWithActionUI = () => {
    return (
      <div className="pniwa-container">
        <div className="pniwa-prefix-container">
          <img src={Flag} alt="flag" className="pniwa-prefix-image" />
          +91
        </div>
        <div className="pniwa-input-container">
          <input
            ref={phoneNumberRef}
            className="pniwa-input"
            type="number"
            placeholder={placeholder ?? "Enter Phone Number"}
            value={inputValue}
            onChange={handleOnChange}
            onKeyDown={handleInputKeydown}
            autoFocus={autoFocus}
          />
        </div>
        {handleInputAction && (
          <div
            className="pniwa-suffix-container"
            style={{
              marginTop: showRegisterButtonLoader ? "5px" : "",
              marginRight: showRegisterButtonLoader ? "10px" : "",
            }}
          >
            {/* <img className="pniwa-suffix-image" src={""} alt="" /> */}
            {showRegisterButtonLoader ? (
              <div style={{ color: "#17BAE0" }}>
                <Spinner animation="border" />
              </div>
            ) : (
              !hideActionIcon && (
                <ArrowForwardIcon
                  sx={{ color: "#17BAE0" }}
                  className={arrowClassName}
                  onClick={() => {
                    if (inputValue?.length === 10) {
                      handleInputAction();
                    }
                  }}
                />
              )
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="phone-number-input-with-action">
      {phoneNumberInputWithActionUI()}
    </div>
  );
};

// export ⇒
export default PhoneNumberInputWithAction;
