// dependencies imports ⇒
import React from "react";
// assets and scss imports ⇒
import "./actionButton.scss";
import ArrowButton from "../../../../assets/buttonArrow.svg";
import { Spinner } from "react-bootstrap";

const ActionButton = (props) => {
  const { title, disabled, handleClick, showButtonLoader } = props;
  const isDisabled = disabled || showButtonLoader;
  const loaderClass = showButtonLoader ? " abc-button-loader" : "";
  const baseClass = "abc-button";
  const disabledClass = isDisabled ? " abc-button-disabled" : "";
  const buttonClassNames = `${baseClass}${disabledClass}${loaderClass}`;

  // create UI ⇒
  const actionButtonUI = () => {
    return (
      <div
        className={buttonClassNames}
        onClick={() => {
          !disabled && !showButtonLoader && handleClick();
        }}
      >
        <div className="abc-button-title">{title}</div>
        <div className="abc-arrow-spinner">
          {showButtonLoader ? (
            <Spinner
              animation="border"
              className="abc-spinner"          
            />
          ) : (
            <img src={ArrowButton} alt="" className="abc-button-image" />
          )}
        </div>
      </div>
    );
  };
  return <div className="action-button-container">{actionButtonUI()}</div>;
};

//export
export default ActionButton;
