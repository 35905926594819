//dependies import ==>
import React, { useState } from "react";
//scss or assest import ==>
import "./abhaPatientProfile.scss";
import abhaCardProfileImage from "../../../../assets/dashboard/abhaCardProfile.svg";
//project file import ==>
import AbhaPatientProfileDetails from "../AbhaPatientProfileDetails";

const AbhaPatientProfile = (props) => {
  //const ==>
  const { patientData } = props;
  const [showPatientProfile, setShowPatientProfile] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  //<==

  // Actions ==>
  const handleOnViewProfile = (data) => {
    setSelectedPatient(data);
    setShowPatientProfile(true);
  };
  // <==

  // Display UI ==>
  const abhaPatientCard = () => {
    return (
      <div className="appc-patient-card">
        {patientData.map((data, ind) => (
          <div className="apc-patient-card" key={ind}>
            <div className="appc-patient-details">
              <img
                src={abhaCardProfileImage}
                alt=""
                className="appc-patient-profile"
              />
              <div className="appc-patient-name">
                {`${data.firstName} ${data.middleName} ${data.lastName}`}
              </div>
            </div>
            <div className="appc-verify-profile-button">
              <button
                onClick={() => handleOnViewProfile(data)}
                className="appc-button"
              >
                View Profile
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  };

  // <==

  return (
    <div className="abha-patient-profile">
      {patientData.length > 0 && (
        <>
          <div className="apc-profile-title">ABHA Profile: </div>
          {abhaPatientCard()}
          {showPatientProfile && (
            <AbhaPatientProfileDetails
              showPatientProfile={showPatientProfile}
              setShowPatientProfile={setShowPatientProfile}
              selectedPatient={selectedPatient}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AbhaPatientProfile;
