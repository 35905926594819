import React from "react";
import { Modal } from "react-bootstrap";
import "./modalCustom.scss";

const ModalCustomDesign = ({ show, handleClose, children, className, size , centered}) => {
  return (
    <Modal backdrop={'static'} keyboard={false} show={show} scrollable={centered ? false : true} size={size} onHide={handleClose} className={className} contentClassName={` ${centered ? "br-0 centered_aline" : "br-0 modal-bottom" } `} backdropClassName="modal_backdrop_class" centered={centered}>
        <Modal.Body className="p-0">
          {children}
        </Modal.Body>
    </Modal>
  );
};

export default ModalCustomDesign;
