import React, { Component } from "react";
import "./DoctorProfile.scss";
import "../../globalStyles/patientDetails.css";
import Tick from "../../assets/tick.png";

class Stepper extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { active, stepper, stepper_mob } = this.props;
    return (
      <>
        <div className="book-appoint-header book-appoint-header1">
          {(stepper || []).map((item, ind) => (
            <div
              key={ind}
              className={`book-appoint-date-time11 ${
                active == item.index ? "active-index" : ""
              }`}
            >
              {item.tick ? (
                <img src={Tick} />
              ) : (
                <span className="book-appoint-index">{item.index}</span>
              )}
              <span
                className={`book-appoint-text ${
                  item.opacity ? "index-opacity" : ""
                }`}
              >
                {item.name}
              </span>
            </div>
          ))}
        </div>
        <div className="book-appoint-header book-appoint-header1-mob">
          {(stepper_mob || []).map((item, ind) => (
            <div
              className={`book-appoint-date-time11 ${
                active == item.index ? "active-index" : ""
              }`}
              key={ind}
            >
              {item.tick ? (
                <img src={Tick} />
              ) : (
                <span className="book-appoint-index">{item.index}</span>
              )}
              <span
                className={`book-appoint-text ${
                  item.opacity ? "index-opacity" : ""
                }`}
              >
                {item.name}
              </span>
            </div>
          ))}
        </div>
      </>
    );
  }
}
export default Stepper;
