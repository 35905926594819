import ApolloClinic from "../../assets/home-page-partners/apollo_clinic.png";
import ApolloClinicBarrackpore from "../../assets/home-page-partners/apollo_clinic_barrackpore.png";
import ApolloClinicBeliaghata from "../../assets/home-page-partners/apollo_clinic_beliaghata.png";
import ApolloClinicChinarpark from "../../assets/home-page-partners/apollo_clinic_chinarpark.png";
import ApolloClinicGaneshTalkies from "../../assets/home-page-partners/apollo_clinic_ganesh_talkies.png";
import ApolloClinicJessoreRoad from "../../assets/home-page-partners/apollo_clinic_jessore_road.png";
import ApolloClinicPashahRoad from "../../assets/home-page-partners/apollo_clinic_pashah_road.png";
import ApolloDiagnosticsRajarhat from "../../assets/home-page-partners/apollo_diagnostics_rajarhat.png";
import ApolloDiagnosticsKanchrapara from "../../assets/home-page-partners/apollor_diagnostics_kanchrapara.png";
import ApolloDiagnosticsTitagarh from "../../assets/home-page-partners/apollor_diagnostics_titagarh.png";
import ZenHospital from "../../assets/home-page-partners/zen_hospital.png";
import DrVksEntClinic from "../../assets/home-page-partners/dr_vks_ent_clinic.png";
import BlissIvfGynaeClinic from "../../assets/home-page-partners/bliss-ivf-gynae-care.png";
import ButterflyHospital from "../../assets/home-page-partners/butterfly_hospital.png";
import LotusHospital from "../../assets/home-page-partners/lotus_hospital.png";
import SrikaraHospital from "../../assets/home-page-partners/srikara_hospital.png";
import MaruthiNursingHome from "../../assets/home-page-partners/maruti_nursing_home.png";
import MayomHospital from "../../assets/home-page-partners/mayom_hospital.png";
import ProHealthImaging from "../../assets/home-page-partners/prohealth_imaging.png";
import AadityaChowti from "../../assets/home-page-testimonial/Dr. Aaditya Chowti.jpg";
import AbhishekDe from "../../assets/home-page-testimonial/Dr. Abhishek De.jpg";
import SuparnaChoudhary from "../../assets/home-page-testimonial/Dr. Suparna Choudhary.jpg";
import MSReddy from "../../assets/home-page-testimonial/Dr. M S Reddy.jpg";
import MudasirKhan from "../../assets/home-page-testimonial/Dr. Mudasir Khan.jpg";
import RVAnand from "../../assets/home-page-testimonial/Dr. R.V.Anand.jpg";
import RatnabaliChakraborty from "../../assets/home-page-testimonial/Dr. Ratnabali Chakraborty.jpg";
import ShashankaDhanuka from "../../assets/home-page-testimonial/Dr. Shashanka Dhanuka.jpg";
import SudiptaChandra from "../../assets/home-page-testimonial/Dr. Sudipta Chandra.jpg";
import benefitsBooking from "../../assets/forhomepage/benefits_logos_booking.png";
import benefitsBooking_img_one from "../../assets/forhomepage/benefits_logo_2.png";
import benefitsBooking_img_two from "../../assets/forhomepage/benefits_logo_3.png";
import benefitsBooking_img_three from "../../assets/forhomepage/benefits_logo_4.png";
import benefitsBooking_img_four from "../../assets/forhomepage/benefits_logo_5.png";
import AmitojAthwal from "../../assets/home-page-testimonial/Dr. Amitoj Athwal.png";
import SnehaTirpude from "../../assets/home-page-testimonial/Dr. Sneha Tirpude.png";
import SonaliGupta from "../../assets/home-page-testimonial/Dr. Sonali Gupta.png";
import whatsAppIcon from "../../assets/home-page-feature/WhatsApp.svg";
import QueuePlay from "../../assets/home-page-feature/queue_play_next_FILL0_wght300_GRAD0_opsz24.svg";
import ERX from "../../assets/home-page-feature/erx.svg";
import PrePaymentIcon from "../../assets/home-page-feature/Group 7482.svg";
import FeedBackIcon from "../../assets/home-page-feature/feedback.svg";
import TipsAndUpdateIcon from "../../assets/home-page-feature/tips_and_updates_FILL0_wght400_GRAD0_opsz48 (1).svg";
import SecurityIcon from "../../assets/home-page-feature/Security.svg";
import FiveStars from "../../assets/home-page-feature/Group 29060.svg";
import GoogleLogo from "../../assets/home-page-feature/google/google.png";
import AppStoreLogo from "../../assets/home-page-feature/apple_appstore-ar21/apple_appstore-ar21.png";
import PlayStoreLogo from "../../assets/home-page-feature/Google_Play-Logo.wine/Google_Play-Logo.wine.png";
import CapterraLogo from "../../assets/home-page-feature/capterra-inc-vector-logo/capterra-inc-vector-logo.png";


export const HOME_PAGE_CONTENT = {
  C2C_PHONE_NO: "1800 267 9899",
  C2C_EMAIL: "hello@connect2clinic.com",
  C2C_ADDRESS:
    "3rd Floor, Alkem House,Marathon Innova Corporate Building, Senapati Bapat Marg, Lower Parel, Mumbai, Maharashtra-400013.",
};

export const PARTNERS = [
  {
    logo: ApolloClinic,
    title: "Apollo Clinic Salt Lake | Newtown | Vivekanand Road",
  },
  {
    logo: ZenHospital,
    title: "Zen Hospital",
  },
  {
    logo: ApolloClinicBeliaghata,
    title: "Apollo Clinic Betiahata",
  },
  {
    logo: SrikaraHospital,
    title: "Srikara Hospitals",
  },
  {
    logo: ApolloClinicBarrackpore,
    title: "Apollo Clinic Barrackpore",
  },
  {
    logo: LotusHospital,
    title: "Lotus Hospitals",
  },
  {
    logo: ApolloClinicChinarpark,
    title: "Apollo Clinic Chinarpark",
  },
  {
    logo: DrVksEntClinic,
    title: "Dr VKs ENT Clinic",
  },
  {
    logo: ApolloDiagnosticsTitagarh,
    title: "Apollo Diagnostics Titagarh",
  },
  {
    logo: ButterflyHospital,
    title: "Butterfly Hospitals",
  },
  {
    logo: ApolloDiagnosticsKanchrapara,
    title: "Apollo Diagnostics Kanchrapara",
  },
  {
    logo: MayomHospital,
    title: "Mayom Hospital",
  },
  {
    logo: ApolloDiagnosticsRajarhat,
    title: "Apollo Diagnostics Rajarhat",
  },
  {
    logo: MaruthiNursingHome,
    title: "Maruti Nursing Home",
  },
  {
    logo: ApolloClinicJessoreRoad,
    title: "Apollo Clinic Jessore Road",
  },
  {
    logo: ProHealthImaging,
    title: "Prohealth Imaging",
  },
  {
    logo: ApolloClinicPashahRoad,
    title: "Apollo Clinic PA Shah Road",
  },
  {
    logo: BlissIvfGynaeClinic,
    title: "The Bliss IVF & Gynae Care",
  },
  {
    logo: ApolloClinicGaneshTalkies,
    title: "Apollo Clinic Ganesh Talkies",
  },
];

export const TESTIMONIAL = [
  {
    id: 1,
    name: "Dr. Abhishek De",
    profile_img: AbhishekDe,
    specialization: "Dermatologist",
    city: "Kolkatta",
    testimonial_content:
      "I have noticed a couple of unique aspects of this platform that have provided me with a lot of freedom. Firstly, the platform ensures the privacy and security of our data, which is not always the case with many other online apps. Secondly, the ease of use is excellent, and there is no unnecessary interference. Whenever I have requested changes, the C2C team has been very accommodating and responsive in implementing them.",
  },
  {
    id: 2,
    name: "Dr. M S Reddy",
    profile_img: MSReddy,
    specialization: "Psychiatrist",
    city: "Telangana",
    testimonial_content:
      "I have noticed a steady increase in the number of patients using the platform. Over time, there have been significant improvements and developments in the app compared to its initial period. I want to particularly highlight the proactive and supportive nature of the team behind Connect2Clinic. They consider and implement every suggestion provided by the consultants.",
  },
  {
    id: 3,
    name: "Dr. R V Anand",
    profile_img: RVAnand,
    specialization: "Senior Consultant Neuro Physician",
    city: "Chennai",
    testimonial_content:
      "I have been using the C2C platform for online consultations with my patients, and I find it extremely useful. The platform is designed to be user-friendly for both patients and doctors. One key feature that stands out is the ability to directly upload written prescriptions and share through WhatsApp.",
  },
  {
    id: 4,
    name: "Dr. Shashank Dhanuka",
    profile_img: ShashankaDhanuka,
    specialization: "Consultant Urologist",
    city: "West Bengal",
    testimonial_content:
      "I have been connected with Connect2Clinic since its inception, and I must say that I am extremely happy with my experience. The platform is simple and straightforward, which adds to its convenience. The video and audio interface is user-friendly, and the best part is that there are no distractions for both doctors and patients. I would advise keeping the interface as simple as it is now because many other platforms are filled with unnecessary distractions, which defeats the purpose of an online consultation.",
  },
  {
    id: 5,
    name: "Dr. Sneha Tirpude",
    profile_img: SnehaTirpude,
    specialization: "Pulmonologist",
    city: "Pune",
    testimonial_content:
      "What sets Connect to Clinic apart from other video platforms is their promptness in addressing patient and doctor queries, as well as their agility in making necessary software modifications. This level of responsiveness is not commonly found in other softwares.",
  },
  {
    id: 6,
    name: "Dr. Sonali Gupta",
    profile_img: SonaliGupta,
    specialization: "Gynaecologist & IVF Specialist",
    city: "Noida",
    testimonial_content:
      "I am delighted to have been part of this journey since the early days. This platform has become an invaluable and efficient tool in managing the patient-doctor journey. It has greatly assisted me in setting up my entire clinic digitally, particularly during these challenging pandemic times. What sets it apart is the team's openness to suggestions and their willingness to cater to customized needs.",
  },
  {
    id: 7,
    name: "Dr. Sudipta Chandra",
    profile_img: SudiptaChandra,
    specialization: "ENT Specialist",
    city: "Kolkatta",
    testimonial_content:
      "I have been using the C2C app for quite some time now, and I must say that I am extremely satisfied with it. I use it regularly and find it to be an essential app in today's time. One of the best features is the ability to view real-time appointments and patient updates. This app has become a crucial tool for me.",
  },
  {
    id: 8,
    name: "Dr. Mudasir Khan",
    profile_img: MudasirKhan,
    specialization: "Dermatologist",
    city: "Delhi",
    testimonial_content:
      "It’s a good app to help doctors to connect with their patients and for the more we can keep the track of the appointments. Overall the app is very easy to use. I am happy with the services and the app.",
  },
  {
    id: 9,
    name: "Dr. Amitoj Athwal",
    profile_img: AmitojAthwal,
    specialization: "Infertility Specialist",
    city: "",
    testimonial_content:
      "We have been using the clinical management software for appointments and billing for the past few months, and we are delighted with its performance. The software has become an invaluable tool in our daily operations. We appreciate the coordination and assistance provided by the C2C team for appointment bookings. Moreover, it is patient-friendly. Thank you for your support!",
  },
  {
    id: 10,
    name: "Dr. Suparna Choudary",
    profile_img: SuparnaChoudhary,
    specialization: "Physician",
    city: "",
    testimonial_content:
      "I am using the C2C app and it's quite useful and patients are getting organized, works are easy and much more better to do with. I wish them best of luck and continue doing good job",
  },
  {
    id: 11,
    name: "Dr. Ratnabali Chakraborty",
    profile_img: RatnabaliChakraborty,
    specialization: "Physician",
    city: "",
    testimonial_content:
      "As all of you know there were days when we used to keep patients' records manually, on paper, we used to do herculean task, and therefore came software management In the clinics. And we have been using it for quite some time and we are finding a lot of differences. we have found an improved deficiency, and coordination with the patient has improved. The service has become much more speedy and paperless",
  },
  {
    id: 12,
    name: "Dr. Aaditya Chowti",
    profile_img: AadityaChowti,
    specialization: "Consulting Physician",
    city: "Bangalore",
    testimonial_content:
      "I have been associated with your platform since its early stages, and I take pride in being a part of such an incredible platform that is dedicated to making telemedicine a seamless experience. Please continue the outstanding work that you do.",
  },
];

export const BENEFITS = [
  {
    image: benefitsBooking,
    title: "Patient Experience Enhancement",
    description:
      "Improve appointment scheduling and communication for faster check-in and check-out, providing personalized care to patients.",
  },
  {
    image: benefitsBooking_img_one,
    title: "Streamlined Operations",
    description:
      "Electronic medical records management, automate appointment scheduling, billing management to streamline the operations.",
  },
  {
    image: benefitsBooking_img_two,
    title: "Revenue Growth",
    description:
      "By enhancing operational efficiency, reducing costs, and improving patient satisfaction, we can improve the revenue growth.",
  },
  {
    image: benefitsBooking_img_three,
    title: "Lure New Clients",
    description:
      "Attract new patients by enhancing the patient experience, improving online visibility, and streamlining the appointment scheduling process.",
  },
  {
    image: benefitsBooking_img_four,
    title: "Analytical Approach",
    description:
      "Provide healthcare providers with access to evidence-based data and analytics that can help to make informed decisions about patient care.",
  },
];

export const HOME_PAGE_DATA = ["31K+", "900K+", "33+", "4.8+"];

export const HOME_PAGE_CONTENT_DATA = [
  "Doctors Onboarded",
  "Consultations Recorded",
  "Specialities Covered",
  "App Ratings",
];

export const TOP_CONTAINT_DETAILS = {
  AI_BASED: "AI-Based",
  PRATICE: "Practice",
  MANAGEMENT: "Management",
  PLATFORM: "Platform",
  PATIENT_LOGIN: "Patient Login",
  DOCTOR_LOGIN: "Doctor Login",
  REQUEST_DEMO: "Request a Demo ",
  CONTAINT: {
    text: "To save time, faster growth, and provide the ideal\npatient Experience, the finest clinics and hospitals\nuse Connect2Clinic software",
  },
};

export const HOME_HEADER_DETAILS = {
  ABOUT: "About",
  OUR_FEATURE: "Our Features",
  FAQ: "FAQ",
};

export const HOME_ABOUT_DETAILS = {
  ABOUT_CLINIC: {
    heading: "About Connect2Clinic",
    content: [
      "Connect2Clinic, an exceptional digital initiative pioneered by Alkem Labs, is dedicated to transforming the management of healthcare practices. As a subsidiary of Alkem Labs, our unwavering goal is to carry forward the company’s rich tradition of excellence and unwavering dedication to healthcare.",
      "This cutting-edge platform is an AI-driven, comprehensive solution that not only streamlines and elevates the patient experience but also empowers healthcare providers with state-of-the-art tools and technologies.",
    ],
  },

  BENEFITS: "Benefits of Using Connect2Clinic",
  BENEFITS_MOBILE_VIEW: "Benefits of Using C2C",
};

export const COMPANIES_LINK = [
  {
    title: "About Us",
    isLink: false,
    navigateTo: "about",
    hasArrow: false,
    isOpen: false,
  },
  {
    title: "Our Features",
    isLink: false,
    navigateTo: "feature",
    hasArrow: true,
    isOpen: false,
  },
  {
    title: "ABDM",
    isLink: true,
    navigateTo: "/abha",
    hasArrow: true,
    isOpen: false,
  },
  {
    title: "FAQ's",
    isLink: true,
    navigateTo: "/help",
    hasArrow: false,
    isOpen: false,
  },
];

export const EXPLORE_FEATUREDATA = [
  {
    featureIcon: whatsAppIcon,
    featureTitle: "Booking via WhatsApp",
    featureText:
      "Receive real-time appointment bookings, as patients can easily scan unique QR code and schedule appointments with their doctors in real time.",
  },
  {
    featureIcon: QueuePlay,
    featureTitle: "Queue Management System",
    featureText:
      "Manage patient queues, reduce wait times, and enhance overall patient experience with our innovative QMS.",
  },
  {
    featureIcon: ERX,
    featureTitle: "Smart eRx",
    featureText:
      " With multi-lingual smart eRx feature, easily generate electronic prescriptions and share with patients directly, eliminating the need for paper prescriptions.",
  },
];

export const FEATURE_DATA = [
  {
    featureIcon: PrePaymentIcon,
    featureTitle: "Pre Payment",
    featureText:
      "With pre-payment feature, healthcare providers can easily accept pre-payments for appointments, procedures, and services, allowing patients to pay in advance for their care reduce administrative burdens, and improve patient satisfaction.",
  },
  {
    featureIcon: FeedBackIcon,
    featureTitle: "Feedback",
    featureText:
      "Patients can easily provide you with feedback and suggestions to help you improve your services. Patient’s input helps to identify areas of improvement and make the necessary changes to enhance the services.",
  },
  {
    featureIcon: TipsAndUpdateIcon,
    featureTitle: "Medupdates & Patient Education Module",
    featureText:
      "Stay informed on the latest Med Updates and share informative content with patients through patient Education Module",
  },
  {
    featureIcon: SecurityIcon,
    featureTitle: "Your Data is in Safe Hands",
    featureText:
      "Connect2Clinic values your health and privacy. Your data is safeguarded in AWS cloud with advanced 256-bit encryption, ensuring utmost confidentiality and trust.",
  },
];

export const COMPANY_RATING = [
  {
    Companylogo: GoogleLogo,
    fiveStarIcon: FiveStars,
  },
  {
    Companylogo: AppStoreLogo,
    fiveStarIcon: FiveStars,
  },
  {
    Companylogo: PlayStoreLogo,
    fiveStarIcon: FiveStars,
  },
  {
    Companylogo: CapterraLogo,
    fiveStarIcon: FiveStars,
  },
];

export const COMPANY_LINKS = [
  {
    title: "About Us",
    isLink: false,
    navigateTo: "about",
  },
  {
    title: "Our Features",
    isLink: false,
    navigateTo: "feature",
  },
  {
    title: "FAQ'S",
    isLink: true,
    navigateTo: "/help",
  },
  {
    title: "Terms",
    isLink: true,
    navigateTo: "../../terms.html",
  },
  {
    title: "Privacy",
    isLink: true,
    navigateTo: "../../privacy.html",
  },
];
