import React, { useState } from "react";
import BookSchedule from "../../BookSchedule/BookSchedule";
import { Button } from "react-bootstrap";
import classes from "../Patient.module.css";
import rebookAppointmentApi from "../Api/rebookAppointmentApi";
import moment from "moment";
import { useHistory } from "react-router";
import Loader from "../../UI Component/Loader/Loader";
import { isSlotAfterCurrentTime } from "../../../Util/Helper/isSlotAfterCurrentTime";
import swal from "sweetalert";
import { LOCALSTORAGEKEY } from "../../../Util/Helper/localStorageUtils";

const Rebook = ({ doctorId, appointmentId }) => {
  const history = useHistory();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedSlot, setSelectedSlot] = useState();
  const [startTime, setStartTime] = useState();
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(0);

  const rebookAppointment = async () => {
    const isAfter = isSlotAfterCurrentTime(startTime, counter, selectedDate);
    if (!isAfter) {
      return swal({
        icon: "warning",
        title: "Booking time expired",
        text: "You're allowed to book 15 minutes earlier to the appointment start time",
      }).then(() => {
        window.location.reload();
      });
    }
    setLoading(true);
    const appointmentDate = moment(selectedDate.meta).format("YYYY-M-DD");
    await rebookAppointmentApi(
      {
        selectedSlot,
        appointmentId,
        appointmentDate,
      },
      () => {
        localStorage.removeItem("id_token");
        localStorage.removeItem(LOCALSTORAGEKEY.USER_ID);
        history.push("/patient/login");
      }
    );
    setLoading(false);
  };

  return (
    <>
      {loading && <Loader />}
      <BookSchedule
        consultType={1}
        className={"d-flex flex-column align-items-center mt-4"}
        doctorId={doctorId}
        slotsClass={"mt-2"}
        hideAnnotation={true}
        onDateSelect={setSelectedDate}
        onSlotSelect={setSelectedSlot}
        setStartTime={setStartTime}
        setCounter={setCounter}
      />
      <Button
        disabled={!selectedSlot}
        className={"next-btn p-0 mb-3 " + classes.bookButton}
        onClick={rebookAppointment}
      >
        Book
      </Button>
    </>
  );
};

export default Rebook;
