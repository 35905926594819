import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import FilledInput from "@material-ui/core/FilledInput";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useTimer } from "../../Hooks/useTimer";
import swal from "sweetalert";
import beforeLoginAxios from "../../Util/API Calls/beforeLogin";
import ErrorIcon from "@material-ui/icons/Error";
import ReactGA from "react-ga";
import afterLoginApiAxios from "../../Util/API Calls/afterLogin";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";
import { C2CConsole } from "../../Util/Helper/C2CConsole";

function OtpLoginFullDoctor(props) {
  let { phoneNumber = "", baseUrl } = props;

  let history = useHistory();
  let location = useLocation();
  const { slug } = useParams();

  const [otpInput, setOtpInput] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [resendAllowed, setResendAllowed] = useState(false);
  const [sendOTP, setSendOTP] = useState(0);

  useEffect(() => {
    swal({
      title:
        "An OTP has been sent to your registered Phone number and Email Address ",
      type: "warning",
    });
    beforeLoginAxios.post("public/send-otp", {
      user_type: "patient",
      channel_id: "patient-login",
      phone_no: `+91${phoneNumber}`,
    });
  }, [sendOTP]);

  const { time, start, reset } = useTimer({
    initialTime: 60,
    endTime: 0,
    timerType: "DECREMENTAL",
    autostart: true,
    onTimeOver: () => {
      setResendAllowed(true);
    },
  });

  if (phoneNumber == "") {
    return <Redirect to={`${baseUrl.split("/:slug")[0]}/${slug}/login`} />;
  }

  let getBrowserName = () => {
    // Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== "undefined";

    // Safari 3.0+ "[object HTMLElementConstructor]"
    var isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && window["safari"].pushNotification)
      );

    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;

    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1 - 79
    var isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    // Edge (based on chromium) detection
    var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1;

    // Blink engine detection
    var isBlink = isChrome && !!window.CSS;

    if (isFirefox) return "FireFox";
    if (isSafari) return "Safari";
    if (isIE && !isEdge) return "IE";
    if (isEdge) return "Edge";
    if (isChrome) return "Chrome";
    if (isEdgeChromium) return "EdgeChromium";
    if (isBlink) return "Blink-based";
  };

  let registerAnalytics = () => {
    afterLoginApiAxios
      .post(
        "user/engagement-analytics-data",
        {
          usecase: "login",
          user_type: "2",
          device_details: {
            device_type: "desktop",
            device_manufacturer: "",
            os_version: window.navigator.userAgent.substring(
              window.navigator.userAgent.indexOf("(") + 1,
              window.navigator.userAgent.indexOf(")")
            ),
            os_name: window.navigator.userAgent.substring(
              window.navigator.userAgent.indexOf("(") + 1,
              window.navigator.userAgent.indexOf(")")
            ),
            application_type: "web",
            application_version: "1.0",
            browser_name: getBrowserName(),
            browser_version: window.navigator.userAgent.substring(
              window.navigator.userAgent.lastIndexOf(")") + 1
            ),
          },
          patient_login_data: {
            patient_id: localStorage.getItem(LOCALSTORAGEKEY.USER_ID),
          },
        },
        {
          headers: {
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then(() => {})
      .catch((err) => C2CConsole.error(err));
  };

  function handleForgotPassword() {
    if (!resendAllowed) return;
    reset();
    start();
    setResendAllowed(false);
    setSendOTP(sendOTP + 1);
  }

  const handleContinueClick = (e) => {
    e.preventDefault();
    if (otpInput.length !== 6) {
      setOtpError("OTP must be exactly 6 characters");
      return;
    }
    beforeLoginAxios
      .post("auth/login", {
        channel_id: "patient-login",
        phone_no: `+91${phoneNumber}`,
        user_type: "patient",
        user_otp: otpInput,
      })
      .then((response) => {
        if (response) {
          if (response.data.hasOwnProperty("statusCode")) {
            if (response.data.statusCode == 200) {
              localStorage.setItem("id_token", response.data.user.id_token);
              localStorage.setItem("user_name", response.data.user.user_name);
              localStorage.setItem("user_type", response.data.user.user_type);
              localStorage.setItem(
                LOCALSTORAGEKEY.USER_ID,
                response.data.user.user_id
              );
              localStorage.setItem(
                "last_login_date",
                response.data.user.last_login_date
              );
              if (!response.data.user.last_login_date) {
                localStorage.setItem("last_login_date", "");
                localStorage.setItem("Home", true);
                localStorage.setItem("Appointment History", true);
              }
              ReactGA.event({
                category: "login",
                action: "patient_login",
                label: response.data.user.user_id,
              });

              registerAnalytics();

              swal({
                title: "Login Successful!",
                type: "success",
              }).then(function () {});

              if (
                location &&
                location.state &&
                location.state.redirectTo == false
              ) {
                return;
              }
              setTimeout(() => {
                if (localStorage.getItem("id_token")) {
                  history.replace(location.state.redirectTo);
                }
              }, 100);
            } else if (response.data.statusCode == 513) {
              setOtpError("OTP you entered was incorrect");
            } else {
              setOtpError("Something went wrong");
            }
          }
        }
      })
      .catch(function (error) {
        C2CConsole.log(error);
      });
  };

  return (
    <div
      className="container h-100 d-flex align-items-center justify-content-center flex-column"
      style={{
        position: "relative",
        margin: "50px 0 50px 50px",
        paddingRight: "100px",
      }}
    >
      <div>
        <ArrowBackIcon
          onClick={() => {
            history.replace(`${baseUrl.split("/:slug")[0]}/${slug}/login/`);
          }}
          style={{ position: "absolute", left: "0%", top: "2%" }}
          className="color-blue cursor-pointer"
        />
        <h3 className="text-center align-top">Enter OTP</h3>
      </div>
      <h6 className="text-center">Please Enter the OTP to login</h6>
      <div className="w-100 d-flex flex-row justify-content-between mt-3">
        <div className="d-inline-block text-muted">+91-{phoneNumber}</div>
        <Edit className="color-blue cursor-pointer" baseUrl={baseUrl} />
      </div>
      <div className="w-100 mt-2">
        <form
          className="d-flex flex-column align-items-center justify-content-center"
          onSubmit={(e) => {
            handleContinueClick(e);
          }}
        >
          <FormControl
            hiddenLabel={true}
            size="small"
            margin="dense"
            fullWidth={true}
          >
            <FilledInput
              id="OTP-input"
              aria-describedby="my-helper-text"
              aria-label="otp-input"
              autoFocus={true}
              placeholder="Enter OTP"
              disableUnderline={true}
              value={otpInput}
              inputProps={{
                autoComplete: "off",
              }}
              endAdornment={
                otpError ? <ErrorIcon style={{ color: "#e84444" }} /> : null
              }
              onChange={(e) => {
                setOtpInput(e.target.value);
              }}
            />
          </FormControl>
          <div className="row justify-content-between w-100">
            <div className="col-7 p-0">
              {resendAllowed ? (
                <div className="align-self-start text-muted">OTP expired</div>
              ) : (
                <div className="align-self-start text-muted">
                  {time} seconds left to expire
                </div>
              )}
            </div>
            <div className="col-5 p-0 d-flex justify-content-end align-items-start">
              <div
                className={`align-self-start cursor-pointer ${
                  !resendAllowed ? "text-muted pointer-disabled" : "textLink"
                }`}
                style={{ textAlign: "right" }}
                onClick={(e) => {
                  e.preventDefault();
                  handleForgotPassword();
                }}
              >
                Resend OTP
              </div>
            </div>
          </div>
          <div className="input-error text-center mt-1">
            {otpError ? otpError : ""}
          </div>
          <button
            className="btn primary-cta-button rounded-pill mt-3 align-self-center py-2 px-4"
            disabled={resendAllowed}
          >
            Verify OTP
          </button>
        </form>
      </div>
      <div className="separator w-50 mt-3 align-self-center">Or</div>
      <div
        className="mt-3 textLink font-weight-bold align-self-center"
        style={{ minWidth: "fit-content" }}
      >
        <div
          onClick={() => {
            history.replace(
              `${baseUrl.split("/:slug")[0]}/${slug}/login/enter-password`
            );
          }}
        >
          Login with Password
        </div>
      </div>
    </div>
  );
}

export default OtpLoginFullDoctor;

const Edit = ({ baseUrl }) => {
  let history = useHistory();
  const { slug } = useParams();
  return (
    <span
      className="color-blue cursor-pointer"
      onClick={() => {
        history.replace(`${baseUrl.split("/:slug")[0]}/${slug}/login/`);
      }}
    >
      Edit
      <EditIcon className="color-blue cursor-pointer" fontSize="inherit" />
    </span>
  );
};
