import React from "react";
import Row from "react-bootstrap/Row";
import moment from "moment";
import FileSelector from "../UI Component/FileSelector/FileSelector";
import { patientFiles } from "../../Util/API Calls/APIList";
import Check from "../../assets/check-pink.svg";
import Cancel from "../../assets/cross-half.svg";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import GetAppIcon from "@material-ui/icons/GetApp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";

export default function DoucmentUpload(props) {
  const {
    imageSelectHandler,
    file,
    actions,
    loadingFile,
    selectedFile,
    percentage,
    cancelUpload,
    hideDelete,
  } = props;

  function base64ToArrayBuffer(base64) {
    var binaryString = atob(base64.split(",")[1]);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  function saveByteArray(reportName, byte, fileType) {
    var blob = new Blob([byte], { type: fileType });
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  }

  function dounloadUploadFile(file) {
    var sampleArr = base64ToArrayBuffer(file.value);
    saveByteArray(file.fileName, sampleArr, file.fileType);
  }

  function GetViewDocument(item, index) {
    const lastDot = item.document_title.lastIndexOf(".");
    const fileType = item.document_title.substring(lastDot + 1);
    const fileName =
      item.document_title.substring(0, lastDot) || "Medical Record" + index;
    return (
      <div className="prescription-history-container" key={index}>
        <div className="mr-2">
          {item.document_id ? (
            <img src={Check} alt="#" />
          ) : (
            <img src={Cancel} alt="#" />
          )}
        </div>
        <div style={{ width: "50%" }}>
          <div className="prescription-file-name">
            <div className="medical-record-heading">
              {fileName || <span>Medical Record {index + 1}</span>}
            </div>
            <div className="medical-record-date">
              {moment(item.created_at).format("HH:mm A | DD MMM, YYYY")}
            </div>
          </div>
          <div className="medical-record-type-doc">
            {fileType && fileType.toUpperCase()}{" "}
            <div className="bullet ml-1 mr-1" /> {item.file_size}MB
          </div>
        </div>
        <div className="medical-record-container">
          <div className="m-auto">
            {fileType.toLowerCase().includes("pdf") ||
            fileType.toLowerCase().includes("jpg") ||
            fileType.toLowerCase().includes("png") ||
            fileType.toLowerCase().includes("jpeg") ? (
              <i
                className="view-icon m-1"
                onClick={() =>
                  actions.viewDocumentAction({
                    fileURL: item.presigned_url_view || item.value,
                    fileName: fileName,
                    fileType: fileType,
                  })
                }
              >
                <VisibilityIcon fontSize="small" />
              </i>
            ) : (
              <i className="view-disable-icon m-1">
                <VisibilityIcon fontSize="small" />
              </i>
            )}

            {item.presigned_url ? (
              <a target="_blank" href={item.presigned_url}>
                <i className="medical-record-icon m-1">
                  <GetAppIcon fontSize="small" />
                </i>
              </a>
            ) : (
              <i
                className="medical-record-icon m-1"
                onClick={() => dounloadUploadFile(item)}
              >
                <GetAppIcon fontSize="small" />
              </i>
            )}
            {!hideDelete && (
              <i
                className="trash-icon m-1"
                onClick={() => {
                  actions.removePrescription(item.document_id);
                }}
              >
                <DeleteIcon fontSize="small" />
              </i>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div style={{ marginLeft: "12px" }}>
        <Row
          xs={12}
          className="flex-column flex-md-row patient-row-individual-row m-2"
        >
          <p
            className="mt-2"
            style={{
              maxWidth: "800px",
            }}
          >
            Upload any previous medical transcripts, test results, diagnostic
            reports and more.
          </p>
        </Row>
        <div className="mx-2 mb-2">
          <FileSelector
            className="prescription_selector mb-2"
            maxFileSize={20}
            onSelect={(image) => imageSelectHandler(image)}
          />
          <div style={{ color: "#e03535", fontWeight: "600" }}>
            MAX FILE SIZE : 20 MB
          </div>
        </div>
        <p className="upload-descrption mt-2">{file.length} Documents</p>
      </div>
      {selectedFile ? (
        <Row className="m-2">
          <div className="prescription-history-container">
            <div className="mr-2" style={{ width: "30px", height: "30px" }}>
              <CircularProgressbarWithChildren
                value={percentage}
                styles={buildStyles({
                  pathColor: "#F969A4",
                  trailColor: "#edf0ef", //pp === 100 ? "none" : "#edf0ef"
                  color: "#F969A4",
                })}
              >
                <i
                  class="fa fa-close m-1"
                  style={{ fontSize: "18px", color: "#f969a4" }}
                ></i>
              </CircularProgressbarWithChildren>
            </div>
            <div style={{ width: "50%" }}>
              <div className="prescription-file-name">
                <div className="medical-record-heading">
                  {selectedFile.fileName || (
                    <span>Medical Record {file.length}</span>
                  )}
                </div>
                <div className="medical-record-date">
                  {moment(new Date()).format("HH:mm A | DD MMM, YYYY")}
                </div>
              </div>
              <div className="medical-record-type">
                {selectedFile.type && selectedFile.type.toUpperCase()}{" "}
                <div
                  className={`bulletUpload mr-1 ${
                    percentage >= 0 ? "ml-1 mt-2" : "ml-1"
                  }`}
                />
                {selectedFile.size}MB
              </div>
            </div>
            <div className="medical-record-container">
              <div className="m-auto">
                {selectedFile.fileType.toLowerCase().includes("pdf") ||
                selectedFile.fileType.toLowerCase().includes("jpg") ||
                selectedFile.fileType.toLowerCase().includes("png") ||
                selectedFile.fileType.toLowerCase().includes("jpeg") ? (
                  <i
                    className=" view-icon m-1"
                    onClick={() =>
                      actions.viewDocumentAction({
                        fileURL: selectedFile.value,
                        fileName: selectedFile.fileName,
                        fileType: selectedFile.type,
                      })
                    }
                  >
                    <VisibilityIcon fontSize="small" />
                  </i>
                ) : (
                  <i className=" view-disable-icon m-1">
                    <VisibilityIcon fontSize="small" />
                  </i>
                )}

                <i
                  className=" medical-record-icon m-1"
                  onClick={() => dounloadUploadFile(selectedFile)}
                >
                  <GetAppIcon fontSize="small" />
                </i>
                {/* )} */}
                {!hideDelete && (
                  <i
                    className="trash-icon m-1"
                    // onClick={() => actions.removePrescription(item.document_id)}
                    onClick={cancelUpload}
                  >
                    <DeleteIcon fontSize="small" />
                  </i>
                )}
              </div>
            </div>
          </div>
        </Row>
      ) : null}
      <Row className="m-2">
        {(file || []).map((item, index) => GetViewDocument(item, index))}
      </Row>
    </>
  );
}
