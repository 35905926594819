import React from "react";
import errorImg from "../../assets/error-something.png";

export function ErrorFallback({error, resetErrorBoundary}) {
  const gotoLogin = () => {
    if (
      window.location.pathname.split("/")[1] === "doctor" ||
      window.location.pathname.split("/")[1] === "patient"
    ) {
      window.location.replace(
        `/${window.location.pathname.split("/")[1]}/login`
      );
    } else {
      window.location.replace(`/`);
    }
  };
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#fff",
        fontWeight: "bold",
      }}
    >
      <img src={errorImg} style={{width: "40%"}} />
      <div role="alert">
        <h1
          style={{
            fontWeight: "bold",
          }}
        >
          Something went wrong.{" "}
        </h1>
        <p
          style={{
            fontWeight: "bold",
          }}
        >
          Please contact our support team if issue persists.{" "}
        </p>
      </div>

      <div
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button
          className="btn primary-cta-button rounded-pill px-4 first_button m-2"
          onClick={resetErrorBoundary}
        >
          Try again
        </button>
        <button
          className="btn secondary-button rounded-pill secon_button"
          onClick={() => gotoLogin()}
        >
          Go to Login Page
        </button>
      </div>
    </div>
  );
}
