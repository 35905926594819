import React, { useState, useEffect } from "react";
import afterLoginApiAxios from "../../Util/API Calls/afterLogin";
import axios from "axios";
import ShareManualRxModal from "./ShareManualRxMoadal";
import DocumentContainer from "../UI Component/DocumentViewer/DocumentCotainer";
import ErxCell from "./ErxCell";
import { APPROVE_AND_SHARE_ERX } from "../ERXViewContainer/ERXViewApiHandler/apiConstants";
import Loader from "../UI Component/Loader/Loader";
import swal from "sweetalert";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";
import { C2CConsole } from "../../Util/Helper/C2CConsole";
import { c2cPostApiHandler } from "../../Util/c2cApiHandler";
const DoctorSavedErx = (props) => {
  const { appointment_id } = props;
  const [e_rx_list, setERxList] = useState([]);
  const [showRxModal, setshowRxModal] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [documentShow, setDocumentShow] = useState(false);
  const [fullView, setFullView] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const userId = localStorage.getItem(LOCALSTORAGEKEY.USER_ID);

  const ImagetoPrint = (source) => {
    return (
      "<html><head><scri" +
      "pt>function step1(){\n" +
      "setTimeout('step2()', 10);}\n" +
      "function step2(){window.print();window.close()}\n" +
      "</scri" +
      "pt></head><body onload='step1()'>\n" +
      "<img src='" +
      source +
      "' /></body></html>"
    );
  };

  const printFile = (url, fileType) => {
    if (fileType.toLowerCase().includes("pdf")) {
      window.open(url, "PRINT", "height=400,width=600");
    }
    if (
      fileType.toLowerCase().includes("jpg") ||
      fileType.toLowerCase().includes("png") ||
      fileType.toLowerCase().includes("jpeg")
    ) {
      var Pagelink = "about:blank";
      var pwa = window.open(Pagelink, "_new");
      pwa.document.open();
      pwa.document.write(ImagetoPrint(url));
      pwa.document.close();
    }
  };

  useEffect(() => {
    afterLoginApiAxios
      .post(
        "/api/v1/doctor/get-saved-e-prescription",
        {
          "authorizer-principal-id": {
            user_type: "1",
            user_id: userId,
          },
          user_id: userId,
          appointment_id: appointment_id, //"1b90c17c-f956-4570-9214-1132fec31893",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          if (response.data.data && response.data.data.length > 0) {
            setERxList(response.data.data);
            props.setSavedErxList(response.data.data);
          } else {
            setERxList([]);
          }
        }
      })
      .catch((err) => C2CConsole.log(err));
  }, []);

  const downloadURI = async (url, name, fileType) => {
    const blob = await axios.get(url, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
      responseType: "blob",
    });
    const a = document.createElement("a");
    const href = window.URL.createObjectURL(blob.data);
    a.href = href;
    a.download = name + "." + fileType.trim();
    a.click();
  };

  const ShareSavedERx = async (result) => {
    let reqPayload = {
      "authorizer-principal-id": {
        user_id: userId,
        user_type: "1",
      },
      appointment_id: result.appointment_id,
      prescription_id: result.saved_prescription_id,
    };
    setShowLoader(true);
    // hit approve and share ==>
    const shareRXResponse = c2cPostApiHandler(
      APPROVE_AND_SHARE_ERX,
      reqPayload
    );
    await shareRXResponse.then(([res, error]) => {
      setShowLoader(false);
      if (!error) {
        let modal =
          res?.data?.statusCode === 200
            ? setshowRxModal(true)
            : swal({
                title: "Error",
                text: "Unable to Share Prescription",
                icon: "warning",
              });
      }
    });
    // <==
  };

  function closeNotification() {
    setDocumentShow(false);
    setFileUrl("");
    setFileName("");
    setFileType("");
    setFullView(false);
  }

  return (
    <>
      {showLoader && <Loader />}
      {e_rx_list.length > 0 ? (
        <div className="w-100  m_rx_border">
          {/* <div className="m_rx_border_bottom  p-3">
            <p className="manual_rx_heading">
              Saved E-Prescriptions ({e_rx_list.length})
            </p>
          </div> */}
          {e_rx_list.length > 0 ? (
            <ErxCell
              e_rx_list={e_rx_list}
              setFileName={setFileName}
              setFileType={setFileType}
              setFileUrl={setFileUrl}
              setDocumentShow={setDocumentShow}
              downloadURI={downloadURI}
              ShareSavedErx={ShareSavedERx}
              saved={true}
              icons={{
                view: true,
                download: true,
                share: true,
                edit: false,
              }}
            />
          ) : null}
        </div>
      ) : null}

      {documentShow && (
        <DocumentContainer
          cancelHandler={closeNotification}
          fullViewHandler={() => {
            setFullView(!fullView);
          }}
          url={fileUrl}
          fileName={fileName}
          fileType={fileType}
        />
      )}

      <ShareManualRxModal
        show={showRxModal}
        handleClose={() => {
          setshowRxModal(false);
        }}
      />
    </>
  );
};

export default DoctorSavedErx;
