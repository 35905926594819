import { useEffect } from "react";

export function useDisableHelp() {
  useEffect(() => {
    // window.hideWidget();
    // setTimeout(() => {
    //   let helpBtn = window.document.getElementById("launcher-frame");
    //   if (helpBtn && helpBtn.style) {
    //     helpBtn.style.opacity = 0;
    //     helpBtn.style.visibility = "hidden";
    //     helpBtn.style.display = "none";
    //   }
    // }, 500);
    // return () => {
    //   window.showsWidget();
    //   let helpBtn = window.document.getElementById("launcher-frame");
    //   if (helpBtn && helpBtn.style) {
    //     helpBtn.style.opacity = 1;
    //     helpBtn.style.visibility = "visible";
    //     helpBtn.style.display = "block";
    //   }
    // };
  }, []);
}

export default useDisableHelp;
