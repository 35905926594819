import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "./commentTextField.scss";
import Stack from "@mui/material/Stack";
import ImageIcon from "@mui/icons-material/Image";
import closeIcon from "../../../assets/cross.svg";
import afterLoginApiAxiosPEM from "../PEMAfterLogin";
import { uploadMediaToS3 } from "../uploadMedia";

const CommentTextField = (props) => {
  const { placeholderContent, patientId } = props;
  let inputRef = useRef();
  const [inputText, setInputText] = useState("");
  const [enteredFieldView, setenteredFieldView] = useState(false);
  const [maxSizeFileError, setmaxSizeFileError] = useState(false);

  // API Call ==>

  const shareArticle = async () => {
    const data = {
      specialization_id: localStorage.getItem("specialization"),
      article_ids: [],
      patient_ids: [patientId],
      // patient_ids: ["73ea8635-d3f9-4dfb-9c15-a9638dd779af"],
      shared_text: inputText,
      is_article: false,
      is_shared_text: inputText.length > 0,
      is_media: false,
      is_recorded: false,
      media_name: "",
      media_path: "",
      media_size: "",
      media_type: "",
      media_thumbnail_name: "",
      media_thumbnail_path: "",
      media_thumbnail_size: "",
      media_thumbnail_type: "",
      recorded_media_name: "",
      recorded_media_path: "",
      recorded_media_size: "",
      recorded_media_type: "",
    };

    const res = await afterLoginApiAxiosPEM({
      method: "post",
      url: "doctor/share-pem-content",
      data: data,
    });
    if (res && res.data && res.data.statusCode === 200) {
      props.pemCloseBtnCallback();
    }
  };
  // <==
  // actions ==>
  const onSubmit = (event) => {
    setInputText(event["search_string"]);
  };

  const nextBtnClick = () => {
    if (props.showPreviewBtn) {
      props.previewBtnCallback(inputText);
      return;
    }
    shareBtnClick();
  };
  // <==

  // Helper ==>

  const shareBtnClick = () => {
    shareArticle();
  };

  const uploadMediaHelper = async (data, file) => {
    let [success, response] = await uploadMediaToS3(data, file);
    if (success) {
      props.uploadBtnCallback(data, [
        response.data.response.main_content.fields.key,
        response.data.response.thumbnail.fields.key,
      ]);
      props.previewBtnCallback(inputText);
    }
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      let data = {
        value: reader.result,
        name: file.name,
        size: file.size,
        fileName: file.name,
        filetype: file.type,
      };
      //
      if (data.size < 20000000) {
        setmaxSizeFileError(false);

        uploadMediaHelper(data, file);
      } else {
        setmaxSizeFileError(true);
      }
    };
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (inputText.length > 0) {
        setenteredFieldView(true);
        props.hidePreviewBtnCallback();
      }
    }
  };

  const clearData = () => {
    setInputText("");
  };

  // <==

  // Create UI ==>

  const renderEnteredFieldView = () => {
    return (
      <>
        <div className="comment-entered-field-container">
          <div className="comment-entered-field-top-container">
            <div className="profile_circular_div_big">
              <span>C</span>
            </div>

            <p>{inputText}</p>
          </div>
          <hr />
          <div style={{ display: "flex" }}>
            <Stack direction="row" spacing={1}>
              <div
                className="share-content-img-div"
                onClick={() => inputRef.click()}
              >
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={(ref) => (inputRef = ref)}
                  onChange={handleChange}
                />
                <ImageIcon style={{ color: "#F9A669" }} />
                <div className="share-content-footer-text">Upload Media</div>
              </div>
            </Stack>

            <div style={{ flex: 1 }}>
              <div
                className="share-content-active-div"
                style={{ float: "right" }}
              >
                <div
                  className="share-content-footer-text"
                  onClick={() => nextBtnClick()}
                >
                  {props.showPreviewBtn ? "Preview" : "Share"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderTextField = () => {
    return (
      <div className="comment-search-container">
        <div className="profile_circular_div">
          <span>C</span>
        </div>
        <div className="comment-search-wrapper">
          <div className="input-holder">
            <input
              type="text"
              className="content-text-search-input"
              onChange={(e) => onSubmit({ search_string: e.target.value })}
              onKeyDown={handleKeyDown}
              value={inputText}
              placeholder={placeholderContent}
            />
            {inputText !== "" && (
              <div className="close_search">
                <img src={closeIcon} onClick={clearData} alt="" />
              </div>
            )}
            <div
              className="share-content-img-div"
              onClick={() => inputRef.current.click()}
            >
              <input
                type="file"
                // multiple
                style={{ display: "none" }}
                ref={inputRef}
                onChange={handleChange}
              />
              <ImageIcon
                style={{
                  color: "#A3A9B7",
                  height: "20px",
                  width: "20px",
                  marginRight: "5px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // <==

  return <>{enteredFieldView ? renderEnteredFieldView() : renderTextField()}</>;
};

CommentTextField.propTypes = {
  userType: PropTypes.string.isRequired,
};

export default CommentTextField;
