import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

const ERXPdfMainContentList = ({ title, value, pdfFontFamily, alignment }) => {
  const styles = StyleSheet.create({
    title: {
      textDecoration: "underline",
      fontFamily: pdfFontFamily,
      textTransform: "uppercase",
      marginTop: "5px",
      marginBottom: "5px",
      color: "#000",
      fontSize: "9px", // 16 *.565 = 9.04 ~ 9
      fontWeight: "600",
    },
    content: {
      fontFamily: pdfFontFamily,
      // padding: "4px 3px 4px 0", // 7 * 0.565 = 3.955 ~ 4, 5 * 0.565 = 2.825 ~ 3
      fontSize: "8px", // 14 *.565 = 7.91 ~ 8
      color: "#000",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      textTransform: "capitalize",
      marginBottom: "5px",
    },
    bullet: {
      marginTop: "5px",
      marginRight: "4px",
      width: "3px",
      height: "3px",
      borderRadius: "50%",
      backgroundColor: "black",
    },
  });

  return (
    <View>
      <Text style={styles.title}>{title}</Text>
      <View
        style={{
          display: "flex",
          flexDirection: alignment === "right" ? "row" : "column",
          flexWrap: "wrap",
        }}
      >
        {value?.length > 0 &&
          value.map((data, ind) => (
            <View
              key={ind}
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: "5px",
              }}
            >
              <View>
                <Text
                  style={{
                    marginRight: 4,
                    fontSize: "8px",
                    lineHeight: "0px",
                    fontWeight: "700",
                  }}
                >
                  •
                </Text>
              </View>
              <Text style={styles.content}>
                {data?.title?.replace(/[\r\n]/g, " ")}
              </Text>
            </View>
          ))}
      </View>
    </View>
  );
};

export default ERXPdfMainContentList;
