// dependencies imports ⇒
import React, { useRef } from "react";
// assets and scss imports ⇒
import "./homePageFeatureContainer.scss";
import DoctorFemale from "../../../assets/home-page-feature/Mask Group 116.png";
import PhoneImage from "../../../assets/home-page-feature/Group 6991.png";
import ForwordArrow from "../../../assets/home-page-feature/trending_flat_FILL0_wght400_GRAD0_opsz48.svg";
import ForwordArrowIcon from "../../../assets/home-page-feature/arrow_right_alt_FILL0_wght400_GRAD0_opsz48 (2).svg";
import { EXPLORE_FEATUREDATA, FEATURE_DATA, COMPANY_RATING } from "../constant";


// project file imports(UI components) ⇒


// const file imports(helper methods, etc) ⇒


function HomePageFeatureContainer(props) {
    const { featureRef } = props
    const elementRef = useRef(null);

    const handleHorizantalScroll = (element, speed, distance, step) => {
        let scrollAmount = 0;
        const slideTimer = setInterval(() => {
            element.scrollLeft += step;
            scrollAmount += Math.abs(step);
            if (scrollAmount >= distance) {
                clearInterval(slideTimer);
            }

        }, speed);
    };


    const exploreFeatures = () => {
        return <div className="home-page-feature-container">
            <div className="img-explore-our-features-container">
                <div className="image-background-wrapper"></div>
                <img className="female-doctor-image" src={DoctorFemale} alt="Doctor img" />

                <div className="image-background-wrapper-sec">
                    <div className="explore-our-features-container">
                        <div className="explore-our-features-heading">Explore our Features</div>
                        <div className="explore-our-features-btns-wrapper">
                            {
                                EXPLORE_FEATUREDATA.map((item, key) => (
                                    <div key={key} className="explore-our-features-btn">
                                        <div className="explore-feature-btn-icon-wrapper">
                                            <img className="explore-feature-btn-icon" src={item.featureIcon} alt="whatsApp Image" />
                                        </div>
                                        <div className="explore-feature-btn-text-wrapper">
                                            <div className="explore-feature-btn-title">{item.featureTitle}</div>
                                            <div className="explore-feature-btn-text">
                                                {item.featureText}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    const requestDemo = () => {
        return <div className="request-demo-container">
            <div className="request-demo-content-wrapper">
                <img className="request-demo-phone-img" src={PhoneImage} alt="phoneimage" />
                <div className="request-demo-heading-btn-wrapper">
                    <div className="request-demo-heading">Request for Demo</div>
                    <div className="request-demo-btn">
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdjwN7PS0mGIsfFE1_EgZU6dD1LoDBG8GiCZ77gtaKjhYXW3Q/viewform" 
                           target="blank" 
                           className="request-demo-btn-text"
                        >Request Demo
                            <img className="request-demo-icon" src={ForwordArrow} alt="arrow" />
                        </a>
                    </div>
                </div>
            </div>
        </div>;
    }

    const ratingUi = () => {
        return <div className="rated-by-doctors-and-patients-container">
            <div className="rated-by-doctors-and-patients-wrapper">
                <div className="rated-by-doctors-and-patients-heading">Highly Rated by Doctors and Patients</div>
                <div className="rating-logo-star-wrapper">
                    {COMPANY_RATING.map((item, key) => (
                        <div key={key} className="company-logo-star-box">
                            <div className="company-logo-wrapper">
                                <img className="company-logo-img" src={item.Companylogo} alt="googlelogo" />
                            </div>
                            <div className="star-rating-wrapper">
                                <img className="star-rating-img" src={item.fiveStarIcon} alt="Stars" />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>;
    }

    return (
        <div ref={featureRef}>
            {exploreFeatures()}
            <div className="feature-points-container-wrapper">
                <div className="feature-points-container" ref={elementRef}>
                    {
                        FEATURE_DATA.map((item, key) => (
                            <div key={key} className="feature-points-content-wrapper">
                                <div className="feature-icon-title-wrapper">
                                    <div className="feature-icon-wrapper">
                                        <img className="feature-icons-image" src={item.featureIcon} alt="icons" />
                                    </div>
                                    <div className="feature-title">{item.featureTitle}</div>
                                </div>
                                <div className="feature-text-wrapper">
                                    <div className="feature-text">
                                        {item.featureText}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                {
                    window.innerWidth < 819 ? (
                        <div
                            className="scroll-right-btn"
                            onClick={() => handleHorizantalScroll(elementRef.current, 25, 270, 10)}>
                            <img className="scroll-right-arrow-icon" src={ForwordArrowIcon} alt="arrow" />
                        </div>
                    ) : " "
                }
            </div>
            {requestDemo()}
            {ratingUi()}
        </div>
    )


}

export default HomePageFeatureContainer


