import moment from "moment";
import { getBase64Text } from "./imageHelper";
import LocalizedStrings from "react-localization";
import drugHeaders from "../../staticFiles/drug_headers.csv";
import { PDF_DRUG_HEADERS } from "../ERXPdfConstants/constants";

// input : title(header/footer) in string, clinic_id(to pick from hospital details/profile details) in string
//         hospital_details and profile_details in object containing enabled state and image
// output : enabled state and image in base64 format
export const getHeaderFooterImage = async (
  title,
  clinic_id,
  hospital_details,
  profile_details
) => {
  let img = null,
    showTemplate = false;

  if (clinic_id?.trim()) {
    if (
      hospital_details &&
      hospital_details["enable_custom_" + title] &&
      hospital_details["custom_" + title + "_image"]?.presigned_url_view
    ) {
      showTemplate = hospital_details["enable_custom_" + title];
      img = hospital_details["custom_" + title + "_image"]?.presigned_url_view;
    }
  } else {
    if (
      profile_details &&
      profile_details["is_uploaded_" + title + "_template_to_be_used"] &&
      profile_details[title + "_template"]?.presigned_url_view
    ) {
      showTemplate =
        profile_details["is_uploaded_" + title + "_template_to_be_used"];
      img = profile_details[title + "_template"]?.presigned_url_view;
    }
  }

  return [img, showTemplate];
};

export const getVitalsData = (patient_gender, vitals, consultation_type) => {
  if (patient_gender?.toLowerCase() !== "female") {
    let lmpInd = vitals.findIndex((data) => data?.key?.toLowerCase() === "lmp");
    if (lmpInd !== -1) {
      vitals.splice(lmpInd, 1);
    }
  }
  let bp1Index = vitals.findIndex((data) => data?.key === "Bp Systolic");
  let bp2Index = vitals.findIndex((data) => data?.key === "Bp Diastolic");
  if (bp1Index == -1 || bp2Index == -1) {
    if (bp1Index > -1) {
      vitals.splice(bp1Index, 1);
    }
    if (bp2Index > -1) {
      vitals.splice(bp2Index, 1);
    }
  } else {
    let bp3Index = vitals.findIndex((data) => data?.key === "Bp Systolic");
    vitals[bp3Index].key = "BP";
    vitals[bp3Index].value =
      vitals[bp1Index].value + "/" + vitals[bp2Index].value;
    let bp4Index = vitals.findIndex((data) => data?.key === "Bp Diastolic");
    vitals.splice(bp4Index, 1);
  }
  let temperature = vitals.findIndex((data) => data?.key === "Temperature");
  if (temperature > -1) {
    vitals[temperature].unit = "\u{00B0}" + vitals[temperature].unit;
  }
  let heightIndex = vitals.findIndex((data) => data?.key === "Height");
  if (heightIndex > -1) {
    if (vitals[heightIndex].unit.toLowerCase() !== "cms") {
      let heightVal = vitals[heightIndex].value.split(".");
      vitals[heightIndex].value =
        heightVal.length > 1
          ? heightVal[0] + "'" + heightVal[1] + '"'
          : heightVal[0] + "'" + '00"';
      vitals[heightIndex].unit = "";
    } else if (vitals[heightIndex].unit.toLowerCase() === "cms") {
      vitals[heightIndex].unit = "cm";
    }
  }

  if (
    +consultation_type !== 4 &&
    +consultation_type !== 5 &&
    +consultation_type !== 6
  ) {
    let bmiIndex = vitals.findIndex(
      (data) => data?.key?.toLowerCase() === "bmi"
    );
    if (bmiIndex !== -1) {
      vitals.splice(bmiIndex, 1);
    }
  }

  return vitals;
};

export const getObstetricHistory = (obstetric_history, patient_gender) => {
  let list = [];

  if (
    obstetric_history &&
    Object.keys(obstetric_history)?.length !== 0 &&
    patient_gender?.toLowerCase() === "female"
  ) {
    Object.keys(obstetric_history).forEach((ele, ind) => {
      if (obstetric_history[ele]?.toString()?.trim()) {
        list.push({
          id: ind + 1,
          title: ele.replace("_", " "),
          value: obstetric_history[ele],
        });
      }
    });
  }

  return list;
};

export const getObstetricDetails = (
  obstetric_details,
  patient_gender,
  doctor_specialization
) => {
  let list = [];
  let allowedObstetricDetailsSpecializations = [
    "Gynecologist / Obstetrician",
    "Gynecologist & Infertility Specialist",
    "Gynecology & Fertility Specialist",
  ];

  if (
    obstetric_details &&
    Object.keys(obstetric_details)?.length !== 0 &&
    patient_gender?.toLowerCase() === "female" &&
    allowedObstetricDetailsSpecializations.includes(doctor_specialization)
  ) {
    if (obstetric_details?.lmp_date?.toString()?.trim()) {
      list.push({ title: "LMP Date", value: obstetric_details.lmp_date });
    }
    if (obstetric_details?.edd_date?.toString()?.trim()) {
      list.push({ title: "EDD Date", value: obstetric_details.edd_date });
    }
    if (
      obstetric_details?.ega_weeks?.toString()?.trim() ||
      obstetric_details?.ega_days?.toString()?.trim()
    ) {
      let value = "";
      if (
        obstetric_details?.ega_weeks?.toString()?.trim() ||
        +obstetric_details?.ega_weeks === 0
      ) {
        value += obstetric_details?.ega_weeks?.toString()?.trim() + " Weeks";
      }

      if (
        obstetric_details?.ega_days?.toString()?.trim() ||
        +obstetric_details?.ega_days === 0
      ) {
        value +=
          (obstetric_details?.ega_weeks?.toString()?.trim() ? ", " : "") +
          obstetric_details?.ega_days?.toString()?.trim() +
          " Days";
      }
      if (obstetric_details?.lmp_date?.toString()?.trim()) {
        list.push({ title: "EGA", value: value });
      }
    }
    if (obstetric_details?.note?.toString()?.trim()) {
      list.push({ title: "Note", value: obstetric_details.note });
    }
  }

  return list;
};

export const getSortedData = async (
  textual,
  includeInErx,
  pdfAlignmentOrder,
  pdfLanguageSlug
) => {
  let past_medical_history = textual?.past_medical_history;
  let family_history = textual?.family_history;
  let lifestyle_history = textual?.lifestyle_history;
  let menstrual_history = textual?.menstrual_history;
  let patient_gender = textual?.patient_gender;
  let patient_history_and_complaints_settings =
    textual?.patient_history_and_complaints_settings ?? {
      past_medical_history: true,
      family_history: true,
      lifestyle_history: true,
      menstrual_history: true,
    };
  let patientHistory = getPatientHistory(
    past_medical_history,
    family_history,
    lifestyle_history,
    menstrual_history,
    patient_gender,
    patient_history_and_complaints_settings
  );

  let { graphList1, graphList2, graphList3 } = await getGraphList(
    textual,
    textual.doctor_profile_details.custom_margins_enabled,
    textual.doctor_profile_details.custom_margin_dimensions
  );

  let vitalsData = getVitalsData(
    patient_gender,
    textual?.vitals,
    textual?.consultation_type
  );

  const obstetricHistory = getObstetricHistory(
    textual?.obstetric_history,
    patient_gender
  );
  const obstetricDetails = getObstetricDetails(
    textual?.obstetric_details,
    patient_gender,
    textual?.doctor_specialization
  );

  let doctor_diagnosis = textual?.doctor_diagnosis ?? [];
  doctor_diagnosis.forEach((ele) => {
    ele.title = ele.title?.trim();
    ele.description = ele.description?.trim();
  });
  textual.doctor_diagnosis = doctor_diagnosis.filter(
    (ele) => ele.title?.trim() || ele.description?.trim()
  );

  let examinations = textual?.examinations ?? [];
  examinations.forEach((ele) => {
    ele.title = ele.title?.trim();
    ele.description = ele.description?.trim();
  });
  textual.examinations = examinations.filter(
    (ele) => ele.title?.trim() || ele.description?.trim()
  );

  let custom_section = textual?.custom_sections ?? [];
  custom_section.forEach((ele) => {
    ele.custom_title = ele.custom_title?.trim();
    ele.fields.forEach((fields) => {
      fields.field_title = fields.field_title?.trim();
      fields.value.forEach((value) => {
        value.title = value.title?.trim();
      });
    });
    ele.fields = ele.fields?.filter((field) => field?.value?.length > 0);
  });
  textual.custom_sections = custom_section.filter(
    (ele) =>
      ele.fields?.filter(
        (fields) =>
          fields.value?.filter((value) => value.title?.trim())?.length !== 0
      )?.length !== 0
  );

  let vaccineChart = getVaccineChart(textual?.vaccine_chart);

  let drugs_info = textual.drug_info ?? [];
  drugs_info.forEach((drug) => {
    drug.drug_name = drug.drug_name?.trim();
    drug.frequency_extended["title"] = drug.frequency_extended?.title?.trim();
    drug.frequency_extended["details"] =
      drug.frequency_extended?.details?.trim();
    drug.duration = drug.duration?.trim();
    drug.quantity = drug.quantity?.trim();
    drug.unit = drug.unit?.trim();
    drug.instruction = drug.instruction?.trim();
  });
  textual.drug_info = await checkDrugs(drugs_info);
  if (textual?.drug_info?.length > 0) {
    textual.drug_info.forEach((data) => {
      if (data?.instruction_slug) {
        data.instruction = data?.instruction + "   "; //don't remove added to avoid trimming of last word in case of other language
      }
    });
  }

  if (textual.suggested_investigations_description?.length > 0) {
    textual.suggested_investigations_description.forEach((data) => {
      if (data?.slug) {
        data.title = data?.title + "   "; //don't remove added to avoid trimming of last word in case of other language
      }
    });
  }

  let defaultOrder = [
    {
      sortOrder: 1,
      name: "followUp",
      customId: "erx-prescription-follow-up",
      alignment: "left",
      value: textual?.follow_up_date ?? "",
      includeInErx: true,
    },
    {
      sortOrder: 2,
      name: "vitals",
      customId: "erx-prescription-vitals",
      alignment: "left",
      value: vitalsData ?? [],
      includeInErx: includeInErx.hasOwnProperty("Vitals")
        ? includeInErx?.Vitals
        : true,
    },
    {
      sortOrder: 3,
      name: "chiefComplaints",
      customId: "erx-prescription-chief-complaints",
      alignment: "left",
      value: textual?.chief_complaints ?? [],
      includeInErx: true,
    },
    {
      sortOrder: 4,
      name: "medicalHistory",
      customId: "erx-prescription-medical-history",
      alignment: "left",
      value: patientHistory ?? [],
      includeInErx: true,
    },
    {
      sortOrder: 5,
      name: "suggestedInvestigations",
      customId: "erx-prescription-suggested-investigations",
      alignment: "left",
      value: textual?.suggested_investigations_extended ?? [],
      includeInErx: true,
    },
    {
      sortOrder: 6,
      name: "instructionsAndNotes",
      customId: "erx-prescription-instructions-and-notes",
      alignment: "left",
      value: textual?.suggested_investigations_description ?? [],
      includeInErx: true,
    },
    {
      sortOrder: 7,
      name: "drugs",
      customId: "erx-prescription-drugs",
      alignment: "right",
      value: textual?.drug_info ?? [],
      // drugHeaders: await localeHandler.getDrugHeaders(textual.langCode),
      includeInErx: true,
    },
    {
      sortOrder: 8,
      name: "vaccine",
      customId: "erx-prescription-vaccine",
      alignment: "right",
      vaccineNotes: textual.vaccine_notes?.trim() ?? "",
      value: vaccineChart ?? [],
      includeInErx: true,
    },
    {
      sortOrder: 9,
      name: "diagnosis",
      customId: "erx-prescription-diagnosis",
      alignment: "right",
      value: textual?.doctor_diagnosis ?? [],
      includeInErx: includeInErx.hasOwnProperty("Diagnosis")
        ? includeInErx?.Diagnosis
        : true,
    },
    {
      sortOrder: 10,
      name: "reportsExamination",
      customId: "erx-prescription-patient-reports-examination",
      alignment: "right",
      value: textual?.examinations ?? [],
      includeInErx: includeInErx.hasOwnProperty("PatientExamination")
        ? includeInErx?.PatientExamination
        : true,
    },
    {
      sortOrder: 11,
      name: "growthChart",
      customId: "erx-prescription-growth-chart",
      alignment: "right",
      graph1: graphList1 ?? [],
      graph2: graphList2 ?? [],
      graph3: graphList3 ?? [],
      includeInErx: true,
    },
    {
      sortOrder: 12,
      name: "obstetricHistory",
      customId: "erx-prescription-obstetric-history",
      alignment: "left",
      value: obstetricHistory ?? [],
      includeInErx:
        textual?.patient_history_and_complaints_settings?.hasOwnProperty(
          "obstetric_history"
        )
          ? textual?.patient_history_and_complaints_settings?.obstetric_history
          : true,
    },
    {
      sortOrder: 13,
      name: "customSection1",
      customId: "erx-prescription-custom-section1",
      alignment: "right",
      value:
        textual?.custom_sections?.filter(
          (ele) => ele.custom_name === "customSection1"
        ) ?? [],
      includeInErx: true,
    },
    {
      sortOrder: 14,
      name: "customSection2",
      customId: "erx-prescription-custom-section2",
      alignment: "right",
      value:
        textual?.custom_sections?.filter(
          (ele) => ele.custom_name === "customSection2"
        ) ?? [],
      includeInErx: true,
    },
    {
      sortOrder: 15,
      name: "customSection3",
      customId: "erx-prescription-custom-section3",
      alignment: "right",
      value:
        textual?.custom_sections?.filter(
          (ele) => ele.custom_name === "customSection3"
        ) ?? [],
      includeInErx: true,
    },
    {
      sortOrder: 16,
      name: "obstetricCalculator",
      customId: "erx-prescription-obstetric-calculator",
      alignment: "left",
      value: obstetricDetails ?? [],
      includeInErx: includeInErx.hasOwnProperty("ObstetricCalculator")
        ? includeInErx?.ObstetricCalculator
        : true,
    },
  ];

  let alignmentOrder = {};
  if (pdfAlignmentOrder && Object.keys(pdfAlignmentOrder)?.length !== 0) {
    alignmentOrder = pdfAlignmentOrder;
  } else if (textual?.alignment_options) {
    alignmentOrder = textual?.alignment_options;
  }

  if (pdfLanguageSlug && Object.keys(pdfLanguageSlug)?.length !== 0) {
    let drugIndex = defaultOrder.findIndex((data) => data.name === "drugs");
    let instructionIndex = defaultOrder.findIndex(
      (data) => data.name === "instructionsAndNotes"
    );

    Object.entries(pdfLanguageSlug).forEach(([key, value]) => {
      if (drugIndex > -1 && defaultOrder[drugIndex]?.value?.length > 0) {
        defaultOrder[drugIndex].value.forEach((data) => {
          if (data.duration_slug && data.duration_slug === key) {
            data["duration"] = value;
          } else if (data.instruction_slug && data.instruction_slug === key) {
            data["instruction"] = value + "   "; //don't remove add to avoid trimming of last word in case of other language
          } else if (data.frequency && data.frequency === key) {
            data["frequency_extended"].details = value;
          }
        });
      }

      if (
        instructionIndex > -1 &&
        defaultOrder[instructionIndex]?.value?.length > 0
      ) {
        defaultOrder[instructionIndex].value.forEach((data) => {
          if (data.slug && data.slug === key) {
            data["title"] = value + "   "; //don't remove add to avoid trimming of last word in case of other language
          }
        });
      }
    });
  }

  let leftMargin =
    textual?.doctor_profile_details?.custom_margin_dimensions?.rxLeft ?? 0;
  let rightMargin =
    textual?.doctor_profile_details?.custom_margin_dimensions?.rxRight ?? 0;
  let sumOfLeftRight = Number(leftMargin) + Number(rightMargin);
  defaultOrder.forEach((ele) => {
    ele.sortOrder = alignmentOrder[ele.name]?.sort ?? ele.sortOrder;
    ele.alignment =
      textual?.doctor_profile_details?.custom_margins_enabled &&
      sumOfLeftRight > 4
        ? "right"
        : alignmentOrder[ele.name]?.alignment ?? ele.alignment;
  });
  return defaultOrder.sort((a, b) => a.sortOrder - b.sortOrder);
};

const filterEmptyEntries = (list) => {
  return list.filter((ele) => ele?.title?.trim());
};

const addHistory = (patient_history, key, value) => {
  patient_history.push({ key, value });
  return patient_history;
};

const getPatientHistory = (
  past_medical_history,
  family_history,
  lifestyle_history,
  menstrual_history,
  patient_gender,
  patient_history_and_complaints_settings
) => {
  let patient_history = [];

  const patient_history_keys = Object.keys(
    patient_history_and_complaints_settings
  ).filter((key) => patient_history_and_complaints_settings[key]);

  if (
    past_medical_history?.length !== 0 &&
    patient_history_keys.includes("past_medical_history")
  ) {
    patient_history = addHistory(
      patient_history,
      "Medical History",
      filterEmptyEntries(past_medical_history)
    );
  }
  if (
    family_history?.length !== 0 &&
    patient_history_keys.includes("family_history")
  ) {
    patient_history = addHistory(
      patient_history,
      "Family History",
      filterEmptyEntries(family_history)
    );
  }
  if (
    menstrual_history?.length !== 0 &&
    patient_gender?.toLowerCase() === "female" &&
    patient_history_keys.includes("menstrual_history")
  ) {
    patient_history = addHistory(
      patient_history,
      "Menstrual History",
      filterEmptyEntries(menstrual_history)
    );
  }
  if (
    lifestyle_history?.length !== 0 &&
    patient_history_keys.includes("lifestyle_history")
  ) {
    patient_history = addHistory(
      patient_history,
      "Lifestyle History",
      filterEmptyEntries(lifestyle_history)
    );
  }
  return patient_history;
};

const getGraphList = async (
  textual,
  custom_margins_enabled,
  custom_margin_dimensions
) => {
  let graphPath = {
    ...textual.growth_charts_s3_path,
  };
  let age = textual.patient_age.split(" ")[0];

  let graphToBeShown = {
    "weight-for-length": false,
    "weight-for-age": false,
    "bmi-for-age": false,
    "hc-for-age": false,
    "muac-for-age": false,
    "length-for-age": false,
    ...textual.growth_charts_settings,
  };

  let graphList = [];
  let graphToBeShownKeys = Object.keys(graphToBeShown);
  if (Number(age) > 2) {
    graphToBeShownKeys = graphToBeShownKeys.filter(
      (d) => d !== "muac-for-age" && d !== "hc-for-age"
    );
  }

  for (let key of graphToBeShownKeys) {
    if (graphToBeShown[key] === true) {
      if (graphPath.hasOwnProperty(key)) {
        const base64Path = await getBase64Text(graphPath[key]);
        let obj = {
          key: base64Path,
          imgClassName: "",
        };
        graphList.push(obj);
      }
    }
  }

  let graphListData = {
    graphList1: [],
    graphList2: [],
    graphList3: [],
  };

  if (graphList.length > 0) {
    let rxLeft = custom_margin_dimensions?.rxLeft
      ? Number(custom_margin_dimensions?.rxLeft)
      : 0;
    let rxRight = custom_margin_dimensions?.rxRight
      ? Number(custom_margin_dimensions?.rxRight)
      : 0;
    if (custom_margins_enabled && (rxLeft > 0 || rxRight > 0)) {
      let arrays = [];
      while (graphList.length > 0) {
        arrays.push(graphList.splice(0, 2));
      }
      for (let ind = 0; ind < arrays.length; ind++) {
        graphListData["graphList" + (ind + 1)] = arrays[ind];
      }
    } else {
      let arrays = [];
      while (graphList.length > 0) {
        arrays.push(graphList.splice(0, 3));
      }
      for (let ind = 0; ind < arrays.length; ind++) {
        graphListData["graphList" + (ind + 1)] = arrays[ind];
      }
    }
  }

  return graphListData;
};

// input : clinic_id(to decide it is clinic or individual doctor) in string
//         enable_custom_header enabled state of header template and custom_margins_enabled enabled state of margins
// output : boolean to show doctor details or not
export const getDoctorDetails = (
  clinic_id,
  enable_custom_header,
  custom_margins_enabled
) => {
  return clinic_id && enable_custom_header && !custom_margins_enabled;
};

export const getDoctorFullName = (details) => {
  return (
    (details?.prefix_name ? details?.prefix_name + ". " : "") +
    (details?.first_name ? details?.first_name + " " : "") +
    (details?.middle_name ? details?.middle_name + " " : "") +
    (details?.last_name ?? "")
  );
};

// input : patient age in the format "22 years 03 months 28 days"
// output : string - converted to the format "22 Y 3M 28D"
export const getPatientAge = (patient_age) => {
  let value = patient_age,
    display_age = "";
  let year = value.split("years");
  let month = year[1]?.split("months");
  let day = month[1]?.split("days");
  let yy = year && Number(year[0]?.trim());
  let mm = month && Number(month[0]?.trim());
  let dd = day && Number(day[0]?.trim());
  if (yy == 0 && mm == 0 && dd == 0) {
    display_age = "1 D";
  } else {
    display_age = `${yy > 0 ? yy + "Y" : ""}${mm > 0 ? " " + mm + "M" : ""}${
      dd > 0 ? " " + dd + "D" : ""
    }`;
  }
  return display_age;
};

// input : date
// output : string - converted to the format "30 Mar 2023"
export const getFormatedDate = (date) => {
  return moment(date).format("DD MMM YYYY");
};

export const handleDisplayLeftContent = async (data) => {
  let display = "block";
  let otherData = JSON.parse(JSON.stringify(data));
  let allRight = otherData
    .filter(
      (ele) =>
        (ele.hasOwnProperty("value") &&
          ele.value !== "" &&
          ele.value?.length !== 0) ||
        (ele.hasOwnProperty("oldValue") &&
          ele.oldValue !== "" &&
          ele.oldValue?.length !== 0) ||
        (ele.hasOwnProperty("graph1") && ele.graph1?.length !== 0)
    )
    ?.every((ele) => ele.alignment === "right");

  if (allRight) {
    display = "none";
  }

  return display;
};

export const convertToBooleanHelper = (obj) => {
  return convertStringToBoolean(obj);
};

export const convertStringToBoolean = (obj) => {
  if (obj) {
    Object.keys(obj).forEach((key) => {
      obj[key] =
        typeof obj[key] === "object"
          ? convertStringToBoolean(obj[key])
          : obj[key] === "true" || obj[key] === "True"
          ? true
          : obj[key] === "False" || obj[key] === "false"
          ? false
          : obj[key];
    });
    return obj;
  }
};

const getVaccineChart = (vaccine_chart) => {
  if (vaccine_chart?.length !== 0) {
    vaccine_chart.forEach((data) => {
      data["vaccinated_date"] = data?.vaccinated_date
        ? moment(data.vaccinated_date).format("DD MMM YYYY")
        : "-";
      data["vaccine_brand_name"] = data?.vaccine_brand_name
        ? data?.vaccine_brand_name
        : "-";
      data["height_weight_hc"] =
        !data?.height && !data?.weight && !data?.head_circumference
          ? "-"
          : (data?.height ? data?.height : "-") +
            "/" +
            (data?.weight ? data?.weight : "-") +
            "/" +
            (data?.head_circumference ? data?.head_circumference : "-");
    });
  }
  return vaccine_chart;
};

const checkDrugs = (arr) => {
  return arr.filter(
    (drug) =>
      drug.drug_name?.trim() ||
      drug.frequency_extended?.title?.trim() ||
      drug.frequency_extended?.details?.trim() ||
      drug.duration?.trim() ||
      drug.quantity?.trim() ||
      drug.unit?.trim() ||
      drug.instruction?.trim()
  );
};

export const getDrugHeaders = async (lang) => {
  let result = await fetch(drugHeaders, { mode: "no-cors" }).then(
    async (response) => {
      let res = await response.text();
      let array = res?.split("\r\n");
      let headers = array[0].split(",");
      let obj = {};

      array.forEach((ele, index) => {
        if (ele.trim() && index !== 0) {
          let str = ele.split(",");
          if (str.length !== 0) {
            headers.forEach((head, ind) => {
              obj[head] = { ...obj[head], [str[0]]: str[ind] };
            });
          }
        }
      });
      return obj;
    }
  );

  let strings = new LocalizedStrings(result);
  if (lang?.trim()) {
    strings.setLanguage(lang);
  }
  let drugHeader = [...PDF_DRUG_HEADERS];
  drugHeader.forEach((data) => {
    data["title"] = strings.formatString(strings[data.langTitle]);
  });
  return drugHeader;
};
export const capitalizeFirstLetter = (word) => {
  return word?.length > 0
    ? word?.charAt(0) && word?.charAt(0).toUpperCase() + word?.slice(1)
    : "";
};
export const breakLongWords = (value, maxLength) => {
  if (value.length > 0) {
    const words = value.split(" ");
    return words
      .map((word) => {
        if (word.length > maxLength) {
          return word.replace(new RegExp(`.{${maxLength}}`, "g"), "$& ");
        }
        return word;
      })
      .join(" ");
  }
  return value;
};

export const capitalizeEachWord = (sentence) => {
  if (sentence) {
    let tempSentence = sentence
      .split(" ")
      .map((word) => word[0] && word[0]?.toUpperCase() + word?.slice(1))
      .join(" ");
    tempSentence = tempSentence
      .split(".")
      .map((word) => word[0] && word[0]?.toUpperCase() + word?.slice(1))
      .join(".");

    return tempSentence;
  }
  return "";
};
