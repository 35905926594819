//dependencies import ==>
import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  getAppointmentsQuestions,
  isAppointmentsReview,
  postReviewApi,
} from "./Api";
import { ThankYouPage } from "./ThankYouPage";
import { ForSatisfactionRating } from "./ForSatisfactionRating";
import { SCALE_RATING, STAR_RATING } from "./constant";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import swal from "sweetalert";
import TextField from "@mui/material/TextField";
import ConfirmationModal from "../UI Component/ConfirmationModal/ConfirmationModal";
//<==
//assest and scss import ==>
import PF_star_rating_active from "../../assets/PF_star_rating_active.png";
import PF_star_rating from "../../assets/PF_star_rating.png";
import emptyImg from "../../assets/emptyImg.svg";
import { getFeedbackQuestionFromQR, submitQRFeedback } from "./APIHandler";
import { PATIENT_FEEDBACK } from "./APIHandler/apiConstants";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";
const customStyle = {
  "& .MuiOutlinedInput-notchedOutline": { borderColor: "#56CDE8  !important" },
  "& .MuiFormLabel-root": {
    color: "#56CDE8 !important",
  },
  "& .MuiInputBase-root": {
    padding: "9px 14px",
  },
};
//<==
const PatientDefaultQus = () => {
  //const declarations ==>
  const [responseData, setResponseData] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [noqusPassed, setNoqusPassed] = useState(1);
  const showSkipButton = true;
  const [disabledFlag, setDisabledFlag] = useState(false);
  const [disabledFlagForInputFild, setDisabledFlagForInputFild] = useState([]);
  const [showReviewPage, setShowReviewPage] = useState(false);
  const [showThankYouPage, setShowThankYouPage] = useState(false);
  const [showThankYouPageAfterSubmit, setShowThankYouPageAfterSubmit] =
    useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [shareGoogleReview, setShareGoogleReview] = useState(false);
  const [placeIdURL, setPlaceIdURL] = useState(null);
  const [responseEmpty, setResponseEmpty] = useState(false);
  const { appointment_id, clinic_id } = useParams();
  const user_id = localStorage.getItem(LOCALSTORAGEKEY.USER_ID);
  const location = useLocation();

  //<==

  //life cycles methods  ==>
  useEffect(() => {
    if (location?.pathname?.includes("clinic_feedback_questions")) {
      getQRFeedbackQuestionAPI();
    } else {
      fetchApi();
    }
  }, []);

  useEffect(() => {
    if (responseData.length - 1 === categoryIndex) {
      if (disabledFlag || disabledFlagForInputFild.length > 0)
        setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [categoryIndex, disabledFlag, disabledFlagForInputFild]);

  //<==

  //Api calls ==>
  const fetchApi = async () => {
    const response = await isAppointmentsReview(appointment_id);
    if (response.data?.status) {
      if (!response.data?.captured) {
        const res = await getAppointmentsQuestions(appointment_id);
        if (res?.data?.statusCode === 200) {
          if (res?.data?.response?.length > 0) {
            setShowReviewPage(true);
            const data = res?.data?.response.map((e) => {
              return { ...e, open: 1 };
            });
            setResponseData(data);
            setResponseEmpty(false);
          } else {
            setResponseEmpty(true);
          }
        }
      } else {
        setShowThankYouPage(true);
      }
    } else {
      setShowThankYouPage(true);
    }
  };

  const getQRFeedbackQuestionAPI = () => {
    if (localStorage.getItem(LOCALSTORAGEKEY.ID_TOKEN)) {
      let api = PATIENT_FEEDBACK + "clinic_feedback_questions/" + clinic_id;
      const feedbackResponse = getFeedbackQuestionFromQR(api);
      feedbackResponse.then(([res, err]) => {
        if (!err) {
          if (res?.data?.statusCode === 200) {
            if (res?.data?.response?.length > 0) {
              setShowReviewPage(true);
              const data = res?.data?.response.map((e) => {
                return { ...e, open: 1 };
              });
              setResponseData(data);
              setResponseEmpty(false);
            } else {
              setResponseEmpty(true);
            }
          } else if (res?.data?.statusCode === 500) {
            setShowThankYouPage(true);
          }
        }
      });
    } else {
      window.location.replace(
        `/${window.location.pathname.split("/")[1]}/login`
      );
    }
  };

  const determineButtonLabel = () => {
    if (!isSubmiting) {
      if (responseData.length - 1 === categoryIndex) {
        return "Submit";
      } else if (
        responseData[categoryIndex]?.open >=
        responseData[categoryIndex]?.questions?.length
      ) {
        return "Next Category";
      } else {
        return "Skip Category";
      }
    } else {
      return "Submitting...";
    }
  };

  const submitQRFeedbackAPI = () => {
    let api = PATIENT_FEEDBACK + clinic_id + "/add_clinic_feedback_questions";
    let obj = {};
    responseData.forEach((e) => {
      if (e.questions && e.questions?.length !== 0) {
        e.questions.forEach((el) => {
          obj[el.id] = el.Ans ?? "-";
        });
      }
    });
    const payload = {
      clinic_id: clinic_id,
      patient_id: user_id,
      data: [
        {
          responses: obj,
        },
      ],
    };

    setIsSubmiting(true);
    setDisabled(true);
    const qrFeedbackResponse = submitQRFeedback(api, payload);
    setShareGoogleReview(false);
    qrFeedbackResponse.then(([res, err]) => {
      if (res.data.statusCode === 200) {
        setShowReviewPage(false);
        setShowThankYouPageAfterSubmit(true);
        if (res.data.google_link && res.data.google_link !== "") {
          setShareGoogleReview(true);
          setPlaceIdURL(res?.data?.google_link);
        }
      } else {
        if (res.data.status === 500) {
          swal({
            text: "You have already submitted the feedback",
            icon: "error",
          });
        }
        setIsSubmiting(false);
        setDisabled(false);
      }
    });
  };

  const submitAppointmentFeedback = async () => {
    let obj = {};
    responseData.forEach((e) => {
      if (e.questions && e.questions?.length !== 0) {
        e.questions.forEach((el) => {
          obj[el.id] = el.Ans ?? "-";
        });
      }
    });
    const data = [
      {
        appointment_id: appointment_id,
        responses: obj,
      },
    ];
    setIsSubmiting(true);
    setDisabled(true);
    const res = await postReviewApi(appointment_id, data);
    setShareGoogleReview(false);
    if (res.data.statusCode === 200) {
      setShowReviewPage(false);
      setShowThankYouPageAfterSubmit(true);
      if (res.data.google_link && res.data.google_link !== "") {
        setShareGoogleReview(true);
        setPlaceIdURL(res?.data?.google_link);
      }
    } else {
      if (res.data.status === 500) {
        swal({
          text: "You have already submitted the feedback",
          icon: "error",
        });
      }
      setIsSubmiting(false);
      setDisabled(false);
    }
  };
  //<==

  //Actions ==>
  const handleGoogleReviewFailure = () => {
    setShareGoogleReview(false);
  };

  const handleGoogleReviewSuccess = () => {
    window.open(placeIdURL, "_blank");
    setShareGoogleReview(false);
  };

  const handleSubmit = () => {
    if (location?.pathname?.includes("clinic_feedback_questions")) {
      submitQRFeedbackAPI();
    } else {
      submitAppointmentFeedback();
    }
  };

  const handleSkipButton = () => {
    responseData[categoryIndex].open = responseData[categoryIndex]?.open + 1;
    setResponseData([...responseData]);
  };
  //<==

  //create UI ==>
  const ForStarRating = ({ ques, index }) => {
    return (
      <div className="PF-defaultQus-bdy-div">
        <div>
          <p className="fnt-16">{ques.question}</p>
          <div className="PF-star-detail-star">
            {Object.keys(STAR_RATING).map((key) => {
              return (
                <div
                  className="PF-star-img-div"
                  key={key}
                  onClick={(e) => {
                    ques.Ans = key;
                    setDisabledFlag(true);
                    if (responseData[categoryIndex].open - 1 <= index) {
                      responseData[categoryIndex].open =
                        responseData[categoryIndex]?.open + 1;
                    }
                    setResponseData([...responseData]);
                  }}
                >
                  <img
                    src={
                      ques.Ans >= key ? PF_star_rating_active : PF_star_rating
                    }
                    alt=""
                  />
                </div>
              );
            })}
          </div>
        </div>
        {responseData[categoryIndex].open === index + 1 &&
          index !== responseData[categoryIndex]?.questions.length - 1 &&
          showSkipButton && (
            <div>
              <button onClick={handleSkipButton} className="skip-btn">
                Skip
              </button>
            </div>
          )}
      </div>
    );
  };

  const ForScaleRating = ({ ques, index }) => {
    const createLabelUI = (question, key) => {
      return (
        <div
          style={{
            background: question.Ans >= key.label && key.color,
            borderColor: question.Ans >= key.label && key.label,
            color: question.Ans >= key.label && "white",
          }}
          className="scale-rating"
        >
          {key.label}
        </div>
      );
    };

    return (
      <div className="PF-defaultQus-bdy-div">
        <div>
          <p className="fnt-16">{ques.question}</p>
          <div className="d-flex flex-column">
            <div className="PF-star-detail-star">
              {SCALE_RATING.map((key) => {
                return (
                  <div
                    className="PF-star-img-div"
                    key={key.label}
                    onClick={(e) => {
                      ques.Ans = key.label;
                      setDisabledFlag(true);
                      if (responseData[categoryIndex]?.open - 1 <= index) {
                        responseData[categoryIndex].open =
                          responseData[categoryIndex]?.open + 1;
                      }
                      setResponseData([...responseData]);
                    }}
                  >
                    {createLabelUI(ques, key)}
                    {/* Pass ques and key as arguments */}
                  </div>
                );
              })}
            </div>
            <div className="d-flex justify-content-between scale-rating-bottom-text">
              <div>No at all</div>
              <div>Very likely</div>
            </div>
          </div>
        </div>
        {responseData[categoryIndex].open === index + 1 &&
          index !== responseData[categoryIndex]?.questions.length - 1 &&
          showSkipButton && (
            <div>
              <button onClick={handleSkipButton} className="skip-btn">
                Skip
              </button>
            </div>
          )}
      </div>
    );
  };

  const ForYesAndNo = ({ ques, index }) => {
    return (
      <div className="PF-defaultQus-bdy-div">
        <div>
          <p className="fnt-16">{ques.question}</p>
          <div className="d-flex flex-column">
            <div className="PF-star-detail-star">
              <div className="PF-star-img-div">
                <label
                  onClick={(e) => {
                    ques.Ans = "Yes";
                    setDisabledFlag(true);
                    if (responseData[categoryIndex]?.open - 1 <= index) {
                      responseData[categoryIndex].open =
                        responseData[categoryIndex]?.open + 1;
                    }
                    responseData.length - 1 !== categoryIndex &&
                      (responseData[categoryIndex]?.questions.length - 1 ===
                      index
                        ? setCategoryIndex(categoryIndex + 1)
                        : ques.skippable &&
                          setCategoryIndex(categoryIndex + 1));
                    setResponseData([...responseData]);
                  }}
                  class="for-yes-and-no-container"
                >
                  Yes
                  <input checked={ques.Ans} type="radio" name={ques.id} />
                  <span class="checkmark"></span>
                </label>
                <label
                  onClick={(e) => {
                    ques.Ans = "No";
                    setDisabledFlag(true);
                    if (responseData[categoryIndex]?.open - 1 <= index) {
                      responseData[categoryIndex].open =
                        responseData[categoryIndex]?.open + 1;
                    }
                    responseData.length - 1 !== categoryIndex &&
                      (responseData[categoryIndex]?.questions.length - 1 ===
                      index
                        ? setCategoryIndex(categoryIndex + 1)
                        : !ques.skippable &&
                          setCategoryIndex(categoryIndex + 1));
                    setResponseData([...responseData]);
                  }}
                  class="for-yes-and-no-container ml-4"
                >
                  No
                  <input
                    type="radio"
                    checked={ques.Ans == "No"}
                    name={ques.id}
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        {responseData[categoryIndex].open === index + 1 &&
          index !== responseData[categoryIndex]?.questions.length - 1 &&
          showSkipButton && (
            <div>
              <button onClick={handleSkipButton} className="skip-btn">
                Skip
              </button>
            </div>
          )}
      </div>
    );
  };

  const HeadingTitle = () => {
    return (
      <>
        {categoryIndex !== 0 && (
          <div className="mb-1"> {responseData[categoryIndex]?.category}</div>
        )}
      </>
    );
  };
  // emptyUI UI renders when the response is null or empty array
  const createEmptyUI = () => {
    return (
      <div className="main-empty-screen">
        <img className="main-empty-image" src={emptyImg} alt="" />
        <div className="main-empty-text1">
          <span className="main-empty-text-thankyou">Thank you! </span>
          {window.innerWidth > 768 && <br />}
          Please reach out to us directly for your feedback.
        </div>
      </div>
    );
  };

  //ReviewPage UI renders when response is not null
  const createReviewPageUI = () => {
    return (
      <div className="main-div-feedback-box">
        <div className="d-flex justify-content-between ">
          <h5 className="fnt-18 mar-0 ml-2 mt-3 mb-1 h-color cursor-pointer">
            {categoryIndex !== 0 && (
              <ArrowBackIcon
                onClick={() => {
                  setCategoryIndex(categoryIndex - 1);
                }}
              />
            )}{" "}
            Rate your experience
            {categoryIndex === 0 && (
              <div className="appointment-subtitle">
                1 being Bad and 5 being Amazing
              </div>
            )}
          </h5>
          <div className="fnt-18 mar-0 ml-2 mt-3 h-color">
            {categoryIndex + 1}/{responseData.length}
          </div>
        </div>

        <div className="PF-defaultQus-bdy mt-3">
          <HeadingTitle />
          {responseData[categoryIndex]?.questions
            ?.slice(0, responseData[categoryIndex]?.open)
            .map((ques, index) => (
              <React.Fragment key={index}>
                {ques.answer_type === 0 && (
                  <ForStarRating ques={ques} index={index} />
                )}

                {ques.answer_type === 1 && (
                  <ForSatisfactionRating
                    setDisabledFlag={setDisabledFlag}
                    setResponseData={setResponseData}
                    noqusPassed={noqusPassed}
                    handleSkipButton={handleSkipButton}
                    index={index}
                    ques={ques}
                    setNoqusPassed={setNoqusPassed}
                    showSkipButton={showSkipButton}
                    responseData={responseData}
                    categoryIndex={categoryIndex}
                  />
                )}

                {ques.answer_type === 4 && (
                  <div className="pb-3">
                    <div className="d-flex justify-content-between">
                      <p className="fnt-16 pb-2">{ques.question}</p>
                      {responseData[categoryIndex].open === index + 1 &&
                        index !==
                          responseData[categoryIndex]?.questions.length - 1 &&
                        showSkipButton && (
                          <div>
                            <button
                              onClick={handleSkipButton}
                              className="skip-btn"
                            >
                              Skip
                            </button>
                          </div>
                        )}
                    </div>
                    <TextField
                      onChange={(e) => {
                        if (e.target.value.length <= 1000) {
                          ques.Ans = e.target.value;
                          setResponseData([...responseData]);
                        }
                        if (e.target.value.length > 0) {
                          if (!disabledFlagForInputFild.includes(ques.id)) {
                            setDisabledFlagForInputFild([
                              ...disabledFlagForInputFild,
                              ques.id,
                            ]);
                          }
                        } else {
                          const filterValue = disabledFlagForInputFild.filter(
                            (element) => element !== ques.id
                          );
                          setDisabledFlagForInputFild(filterValue);
                        }
                      }}
                      sx={customStyle}
                      fullWidth
                      focused
                      value={ques.Ans}
                      id="outlined-multiline-static"
                      label="Feedback (optional)"
                      multiline
                      rows={2}
                    />
                  </div>
                )}
                {ques.answer_type === 2 && (
                  <ForYesAndNo ques={ques} index={index} />
                )}
                {ques.answer_type === 3 && (
                  <ForScaleRating ques={ques} index={index} />
                )}
              </React.Fragment>
            ))}
        </div>

        <div className="footsticky">
          <button
            onClick={() => {
              responseData.length - 1 > categoryIndex
                ? setCategoryIndex(categoryIndex + 1)
                : handleSubmit();
            }}
            disabled={responseData.length - 1 === categoryIndex && disabled}
            className={`footbtn ${
              responseData.length - 1 === categoryIndex
                ? !disabled && "footbtn-active"
                : "footbtn-active"
            }`}
          >
            {determineButtonLabel()}
          </button>
        </div>
      </div>
    );
  };

  const createConfirmModelUI = () => {
    return (
      <ConfirmationModal
        showSuccessButton
        headingContent="Do you want to post this on google ?"
        mainContent="Spread the good word by sharing your feedback on google."
        showCloseIcon
        onSuccess={handleGoogleReviewSuccess}
        open={shareGoogleReview}
        handleClose={handleGoogleReviewFailure}
        placeIdURL={placeIdURL}
      />
    );
  };
  //thank you UI render after feed back is submmitted
  const createThankYouUI = () => {
    return (
      <ThankYouPage
        flag
        text="Thanks for your time, your opinion matters to us. Wish you great health"
      />
    );
  };
  //thank you page UI render when patient again visit the page which is already submmitted

  //<==

  return (
    <div className="patient-default-qus">
      {responseEmpty && createEmptyUI()}
      {showReviewPage && createReviewPageUI()}
      {showThankYouPage && (
        <ThankYouPage text="You have already submitted the feedback" />
      )}
      {showThankYouPageAfterSubmit && createThankYouUI()}
      {shareGoogleReview && placeIdURL && createConfirmModelUI()}
    </div>
  );
};

export default PatientDefaultQus;
