export const STAR_RATING = {
  1: "Very Poor",
  2: "Poor",
  3: "Average",
  4: "Good",
  5: "Excellent",
};
export const SCALE_RATING = [
  { label: 1, color: "#FA2413" },
  { label: 2, color: "#EB0405" },
  { label: 3, color: "#FFAE00" },
  { label: 4, color: "#74C130" },
  { label: 5, color: "rgb(73 146 8)" },
];
