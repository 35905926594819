export const QualificationList = [
  { label: "MBBS", value: "MBBS" },
  { label: "MBBS MD", value: "MBBS MD" },
  { label: "MBBS MS", value: "MBBS MS" },
  { label: "MBBS DNB", value: "MBBS DNB" },
  { label: "MBBS MD DM", value: "MBBS MD DM" },
  { label: "MBBS MS M.Ch", value: "MBBS MS M.Ch" },
  { label: "BDS", value: "BDS" },
  { label: "BDS MDS", value: "BDS MDS" },
  { label: "BUMS", value: "BUMS" },
  { label: "BHMS", value: "BHMS" },
  { label: "BAMS", value: "BAMS" },
  { label: "BYNS", value: "BYNS" },
  { label: "BSMS", value: "BSMS" },
  { label: "BPT", value: "BPT" },
  { label: "BPT MPT", value: "BPT MPT" },
  { label: "BSC / BA Nutrition", value: "BSC / BA Nutrition" },
  { label: "BSC Food Technology", value: "BSC Food Technology" },
  { label: "MSC Food and Nutrition", value: "MSC Food and Nutrition" },
  { label: "Others", value: "Others" },
];
