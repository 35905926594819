import afterLoginApiAxios from "../../../Util/API Calls/afterLogin";
import swal from "sweetalert";
import sessionExpiredError from "../../Errors/sessionExpiredError";

export default async (
  { appointmentId, selectedSlot, appointmentDate },
  callback
) => {
  try {
    const response = await afterLoginApiAxios.post(
      "/patient/rebook-appointment",
      {
        appointment_id: appointmentId,
        appointment_slot_id: selectedSlot,
        appointment_date: appointmentDate,
      },
      {
        headers: {
          authorization: localStorage.getItem("id_token"),
        },
      }
    );
    if (response.data.statusCode === 200) {
      // Request Successful
      swal({
        title: "Appointment Rebooked",
        icon: "success",
        type: "success",
      }).then(() => {
        window.location.reload();
      });
      return;
    }
    // Request UnSuccessful
    swal({
      title: "Appointment cannot be Rebooked",
      icon: "warning",
      type: "warning",
      text: response?.data?.message,
    });
  } catch (e) {
    if (e?.response?.status === 403) {
      sessionExpiredError(callback);
    }
  }
};
