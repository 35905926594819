import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DropDownSelector from "../UI Component/DropDownSelector/DropDownSelector";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";

export default function DiagnosisItem(props) {
  const {
    diagnosisList,
    addDiagnosisItem,
    diagnosisChange,
    dropDownSelected,
    getSerachPopulatedData,
    dropDown,
    handleDeletePrec,
  } = props;
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [show, setShow] = useState(false);
  const [pindex, setPIndex] = useState();

  const userId = localStorage.getItem(LOCALSTORAGEKEY.USER_ID);

  const handleSerachData = (value, index) => {
    setPIndex(index);
    diagnosisChange(value, index);
    if (value.length > 2) {
      setShow(true);
      const search_field = "diagnosis";
      setTypingTimeout(
        setTimeout(function () {
          getSerachPopulatedData(
            {
              doctor_id: userId,
              search_text: value,
              search_field,
              user_type: "1",
            },
            search_field + "_" + index
          );
          setShow(false);
        }, 500)
      );
    } else {
      setShow(false);
      dropDownSelected();
    }
  };

  const handleSelect = (item, index) => {
    diagnosisChange(item.label, index);
    dropDownSelected(item.label, index);
  };

  const handleDelete = (event, item, name) => {
    event && event.stopPropagation();
    const search_field = name;
    handleDeletePrec({
      doctor_id: userId,
      item_id: item.id,
      search_field,
      user_type: "1",
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <Col>
        <Row className="mb-2">
          <Col
            className="prescription-section-title"
            style={{ color: "black", fontSize: "14px" }}
          >
            DIAGNOSIS
            {/* <span style={{ fontSize: "12px", color: "red" }}>*</span> */}
          </Col>
          <Col
            className="float-right prescription-col-float-right"
            onClick={addDiagnosisItem}
            style={{ color: "#17bae0", fontSize: "12px" }}
          >
            <AddCircleIcon /> Add Diagnosis
          </Col>
        </Row>
        {diagnosisList &&
          diagnosisList.map((complaint, index) => (
            <Row className="mb-2" key={index}>
              <Col>
                <DropDownSelector
                  value={complaint}
                  handleSearch={(evt) => {
                    handleSerachData(evt.target.value, index);
                  }}
                  name={`Diagnosis${index}`}
                  id={`Diagnosis${index}`}
                  options={dropDown.selectState}
                  isShowDropDown={
                    "diagnosis_" + index === dropDown.isShowDropDown
                      ? true
                      : false
                  }
                  handleSelect={(item) => handleSelect(item, index)}
                  handleDelete={(event, item) =>
                    handleDelete(event, item, "diagnosis")
                  }
                  show={show}
                  indx={index}
                  pindex={pindex}
                  loading={true}
                  placeholder={`Diagnosis or provisional diagnosis ${
                    index + 1
                  }`}
                />
              </Col>
            </Row>
          ))}
      </Col>
    </div>
  );
}
