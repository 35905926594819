import React, { Suspense } from "react";
import Loader from "../components/UI Component/Loader/Loader";
const AppointmentRoutes = React.lazy(() =>
  import("../Routes/AppointmentRoutes")
);

export default function () {
  return (
    <>
      <Suspense fallback={<div><Loader/></div>}>
        <AppointmentRoutes />
      </Suspense>
    </>
  );
}
