import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MainDrawerCross from "../../assets/mobile-menu-drawer.svg";
import DrawerOpen from "../../assets/drawer-open.svg";
import "../styles/Drawer.scss";
import Call from "../../assets/call.svg";
import Mail from "../../assets/mail.svg";
import Icon from "@material-ui/core/Icon";
import { Link } from "react-router-dom";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { connect } from "react-redux";

const drawerWidth = 340;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "space-between",
    boxShadow: " 0 1px 3px white, 0 2px 6px lightgrey",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  iconStyle: {
    width: "10%",
  },
}));

function PersistentDrawerRight(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    if (!window.localStorage.getItem("user_type")) {
      setUserType(null);
    } else {
      setUserType(window.localStorage.getItem("user_type"));
    }
  }, [window.localStorage]);

  if (userType !== "patient" && userType !== "doctor") {
    return (
      <div>
        <Link className="thecolor" to={`/`}>
          Home Page
        </Link>
      </div>
    );
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerReload = () => {
    setOpen(false);
    window.location.reload();
  };

  const logoutHandler = () => {
    window.localStorage.clear();
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        handleDrawerClose();
      }}
    >
      <div className={classes.root}>
        <CssBaseline />

        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerOpen}
          className={clsx(open && classes.hide)}
        >
          <img src={DrawerOpen} alt="#" />
        </IconButton>

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <div className="menu">Menu</div>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <img src={DrawerOpen} alt="#" />
              ) : (
                <img src={MainDrawerCross} alt="#" />
              )}
            </IconButton>
          </div>

          <List>
            <ListItem button key={"Dashboard"}>
              <Link className="thecolor" to={`/${userType}/account/dashboard`}>
                <ListItemText>
                  <div className="menu-list-style">Dashboard </div>
                </ListItemText>
              </Link>
            </ListItem>
            <Divider />
            <ListItem button key={"Faq"} onClick={handleDrawerReload}>
              <Link className="thecolor" to={`/help`}>
                <ListItemText>
                  <div className="menu-list-style">FAQ</div>{" "}
                </ListItemText>
              </Link>
            </ListItem>
            <Divider />
            <ListItem button key={"Previous Checkups"}>
              <Link
                className="thecolor"
                to={{
                  pathname: `/${userType}/account/dashboard`,
                  state: { previouscheckup: 2 },
                }}
              >
                <ListItemText>
                  <div className="menu-list-style">{`Appointment History`}</div>{" "}
                </ListItemText>
              </Link>
            </ListItem>
            <Divider />
            <ListItem
              button
              key={"LOGOUT"}
              onClick={() => {
                logoutHandler();
              }}
            >
              <Link className="thecolor" to={`/${userType}/login`}>
                <ListItemText className="menu-list-style">
                  <div className="menu-list-style">Logout</div>{" "}
                </ListItemText>
              </Link>
            </ListItem>
            <div></div>
          </List>

          <List className="bottom-drawer-style">
            <ListItem className="drawer-customer-care">
              Customer Support
            </ListItem>

            <ListItem className="drawer-customer-support-align">
              <ListItemText>
                <Icon>
                  <img src={Call} alt="#" />
                </Icon>
                <a href={"tel:" + props.mobileNumber} className="drawer-a">
                  {props.mobileNumber}
                </a>
              </ListItemText>
            </ListItem>

            <ListItem className="drawer-customer-support-align">
              <ListItemText>
                <Icon>
                  <img src={Mail} alt="#" />
                </Icon>
                <a href={"mailto:" + props.emailId} className="drawer-a">
                  {props.emailId}
                </a>
              </ListItemText>
            </ListItem>
          </List>
        </Drawer>
      </div>
    </ClickAwayListener>
  );
}
const mapStateToProps = (state) => ({
  mobileNumber: state.global.mobileNumber,
  emailId: state.global.emailId,
});

export default connect(mapStateToProps)(PersistentDrawerRight);
