import afterLoginApiAxios from "../../../Util/API Calls/afterLogin";
import { LOCALSTORAGEKEY } from "../../../Util/Helper/localStorageUtils";
import {
  ADD_OR_SHARE_ERX,
  SHARE_ERX,
  UPLOAD_MANUAL_RX,
  VIEW_PRESCRIPTION_REV2,
} from "./APIconstants";

export const getPrescriptionDates = async (payload, api) => {
  const res = await afterLoginApiAxios({
    method: "post",
    url: api,
    data: payload,
    headers: {
      authorization: localStorage.getItem("id_token"),
    },
  });

  if (res?.data?.statusCode === 200) {
    if (res?.data?.calendar_availabilities?.length > 0) {
      let arr = [];
      res.data.calendar_availabilities.forEach((data) => {
        Object.entries(data).forEach(([key, value]) => {
          if (value === false) {
            // arr.push(new Date(key));
            arr.push(key);
          }
        });
      });
      return [arr, false];
    } else {
      return [[], false];
    }
  }
  return [null, true]; // [response, error]
};

export const getPrescriptionList = async (payload, api) => {
  const res = await afterLoginApiAxios({
    method: "post",
    url: api,
    data: payload,
    headers: {
      authorization: localStorage.getItem("id_token"),
    },
  });

  if (res?.data?.statusCode === 200) {
    if (res?.data?.data?.length > 0) {
      const modifiedResponse = await getModifiedResponse(res.data.data);
      return [modifiedResponse, false];
    }
    return [[], false];
  }
  return [null, true]; // [response, error]
};

const getModifiedResponse = (dataList) => {
  const modifiedData = dataList.reduce(
    (data, item) => ({
      ...data,
      [item.created_at_date]: [...(data[item.created_at_date] || []), item],
    }),
    {}
  );
  Object.entries(modifiedData).forEach(([key, value]) => {
    if (value?.length > 0) {
      let manualRxCount = 0;
      let eRxCount = 0;
      value.forEach((data) => {
        if (data?.doc_type === "rx") {
          data["count"] =
            manualRxCount + 1 < 10
              ? " 0" + (manualRxCount + 1)
              : " " + (manualRxCount + 1);
          manualRxCount = manualRxCount + 1;
        } else {
          data["count"] =
            eRxCount + 1 < 10 ? " 0" + (eRxCount + 1) : " " + (eRxCount + 1);
          eRxCount = eRxCount + 1;
        }
        data["time"] = data.created_at_date + " " + data.created_at_time;
      });
      manualRxCount = 0;
      eRxCount = 0;
    }
  });
  return modifiedData;
};

export const getSharedPrescriptionResponse = async (payload, includeErx) => {
  let api = VIEW_PRESCRIPTION_REV2;
  const res = await afterLoginApiAxios({
    method: "post",
    url: api,
    data: payload,
    headers: {
      authorization: localStorage.getItem("id_token"),
    },
  });

  if (res?.data?.statusCode === 200) {
    if (res?.data?.prescription_data) {
      let arr = [];
      Object.entries(
        res?.data?.prescription_data?.textual?.vaccine_chart
      ).forEach(([key, d]) => {
        if (d.length > 0) {
          d.forEach((s) => {
            if (s.scheduled_date) {
              arr.push(s);
            }
          });
        }
      });
      let includeInErx = {
        ...includeErx,
        DrugMedicine: true,
        "PatientFollow-up": true,
        PatientHistory: true,
        VaccineChart: true,
      };
      const shareErxResponse = await shareErx({
        ...res?.data?.prescription_data?.textual,
        ...((res?.data?.prescription_data?.textual?.consultation_type == "5" ||
          res?.data?.prescription_data?.textual?.consultation_type == "6") && {
          includeInErx: includeInErx,
        }),
        vaccinations: arr,
        share_prescription: true,
      });
      return [shareErxResponse, false];
    }
    return [[], false];
  }
  return [[], true];
};

const shareErx = async (payload) => {
  payload["vitals"] = payload?.vitals?.reduce(
    (obj, cur) => ({ ...obj, [cur.key]: cur }),
    {}
  );
  let newPayload = {
    ...payload,
    appointment_id: payload?.appointment_id,
    complaints: payload?.complaints,
    diagnosis: payload?.diagnosis,
    doctor_recommendation: payload?.doctor_recommendation,
    lab_results: payload?.lab_results,
    user_type: localStorage.getItem("user_type"),
    user_id: localStorage.getItem(LOCALSTORAGEKEY.USER_ID),
    height: payload.vitals["Height"]?.value ?? "",
    weight: payload.vitals["Weight"]?.value ?? "",
    weight_unit: payload.vitals["Weight"]?.unit ?? "",
    height_unit: payload.vitals["Height"]?.unit ?? "",
    bp_systolic: payload.vitals["Bp Systolic"]?.value ?? "",
    bp_diastolic: payload.vitals["Bp Diastolic"]?.value ?? "",
    temperature: payload.vitals["Temperature"]?.value ?? "",
    temperature_unit: payload.vitals["Temperature"]?.unit ?? "",
    spo2: payload.vitals["SPO2"]?.value ?? "",
    random_glucose: payload.vitals["Random Glucose"]?.value ?? "",
    random_glucose_unit: payload.vitals["Random Glucose"]?.unit ?? "",
    pulse_rate: payload.vitals["Pulse Rate"]?.value ?? "",
    respiration_rate: payload.vitals["Respiratory Rate"]?.value ?? "",
    lmp: payload.lmp,
    relevance_history: payload?.relevance_history,
    suggested_investigations: payload?.suggested_investigations,
    medication: payload?.medications,
    finalize: false,
    follow_up_date: payload?.follow_up_date,
    auto_save: false,
    share_prescription: true,
    doctor_notes: payload?.doctor_notes,
    prescription_status: "shared",
  };

  let api = ADD_OR_SHARE_ERX;
  const res = await afterLoginApiAxios({
    method: "post",
    url: api,
    data: newPayload,
    headers: {
      authorization: localStorage.getItem("id_token"),
    },
  });

  if (res) {
    if (res?.data?.statusCode === 200) {
      return [res.data, false];
    }
  }
};

export const getSharedManualRxResponse = async (payload) => {
  let api = UPLOAD_MANUAL_RX;
  const res = await afterLoginApiAxios({
    method: "post",
    url: api,
    data: payload,
    headers: {
      authorization: localStorage.getItem("id_token"),
    },
  });

  if (res?.data?.statusCode === 200) {
    return [res.data, false];
  }
  return [[], true];
};

export const shareSharedErx = async (payload) => {
  let api = SHARE_ERX;
  const res = await afterLoginApiAxios({
    method: "post",
    url: api,
    data: payload,
    headers: {
      authorization: localStorage.getItem("id_token"),
    },
  });

  if (res?.data?.statusCode === 200) {
    return [res.data, false];
  }
  return [[], true];
};

export const approveAndShareRxData = async (payload, api) => {
  const res = await afterLoginApiAxios({
    method: "post",
    url: api,
    data: payload,
    headers: {
      authorization: localStorage.getItem("id_token"),
    },
  });
  if (res) {
    if (res?.data?.statusCode === 200) {
      return [res, false];
    }
    return [null, true];
  }
  return [null, true]; // [response, error]
};
