import React, { useState } from "react";
import "./rectangeShapeRoundedImgCell.scss";

import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import closeIcon from "../../../assets/close_icon.svg";
import c2cLogo from "../../../assets/c2c_logo.png";

import { cellViewType } from "../../../Util/constants";

const RectangeShapeRoundedImgCell = (props) => {
  const {
    viewType,
    onClickCallback,
    closeBtnCallback,
    showErrorCallback,
    content,
    isSelected,
    isEnable,
  } = props;

  // actions ==>
  const toggleBtnClicked = (content) => {
    switch (viewType) {
      case cellViewType.Content:
        if (!isEnable) {
          if (isSelected) {
            onClickCallback(false, content);
          } else {
            onClickCallback(true, content);
          }
          showErrorCallback(false);
        } else {
          showErrorCallback(true);
        }
        break;
      case cellViewType.Shared:
        onClickCallback(false, content);
        break;
      case cellViewType.upload:
        onClickCallback(false, content);
        break;
      default:
        break;
    }
  };

  const closeBtnClick = (content, viewType) => {
    closeBtnCallback(content, viewType);
  };
  // <==

  // create UI ==>
  const renderRightTopBtn = (content) => {
    switch (viewType) {
      case cellViewType.Content:
        if (!isSelected) {
          return (
            <div className="rectangle-shape-rounded-uncheck">
              <CircleIcon />
            </div>
          );
        } else {
          return (
            <div className="rectangle-shape-rounded-check">
              <CheckCircleIcon />
            </div>
          );
        }
      case cellViewType.Shared:
        return (
          <div
            className="rectangle-shape-rounded-check"
            onClick={() => closeBtnClick(content, cellViewType.Shared)}
          >
            <img src={closeIcon} alt="" />
          </div>
        );
      case cellViewType.upload:
        return (
          <div
            className="rectangle-shape-rounded-check"
            onClick={() => closeBtnClick(content, cellViewType.upload)}
          >
            <img src={closeIcon} alt="" />
          </div>
        );
      default:
        break;
    }
  };
  // <==

  return (
    <>
      {viewType === cellViewType.Content ? <hr /> : ""}
      <div>
        <div
          className={`${
            viewType === cellViewType.Content
              ? "rectangle-shape-rounded-image-cell"
              : "ractangle-shape-rounded-image-share-cell"
          }`}
          onClick={() => toggleBtnClicked(content)}
        >
          <img
            className={
              viewType === cellViewType.upload
                ? "content-img-rounded-pdf"
                : "content-img-rounded"
            }
            src={
              viewType === cellViewType.upload
                ? c2cLogo
                : content.thumbnail_presigned_view
                ? content.thumbnail_presigned_view
                : content.article_media_presigned_url
            }
            alt=""
          />
          <div className="rectangle-shape-rounded-image-content">
            <div className="rectangle-shape-rounded-image-content-right">
              <div className="rectangle-shape-rounded-image-content-title">
                {content.keywords.toString().replaceAll(",", " \u00B7 ")}
              </div>
              <div>
                <p>{content.article_title}</p>
              </div>
            </div>
          </div>
          {renderRightTopBtn(content)}
        </div>
      </div>
    </>
  );
};

export default RectangeShapeRoundedImgCell;
