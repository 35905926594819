import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Modal } from "@mui/material";
import check from "../../../assets/check.png";
import afterLoginApiAxios from "../../../Util/API Calls/afterLogin";
import swal from "sweetalert";
import "./commonModals.css";
import moment from "moment";
import { C2CConsole } from "../../../Util/Helper/C2CConsole";

export default function PrescriptionSharedModal({
  show,
  okayClickHandler,
  sharePEMClickHandler,
  appointment_id,
  fetchPastAppointmentDataList,
  prescription_id,
}) {
  const [followUpDate, setFollowUpDate] = useState("");
  const [patient_phone, set_patient_phone] = useState("");

  useEffect(() => {
    if (prescription_id) {
      afterLoginApiAxios
        .post(
          "/user/view-prescription-rev2", //"/user/view-prescription-rev1"
          {
            appointment_id: appointment_id,
            prescription_id: prescription_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("id_token"),
            },
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            const { patient_phone_no, follow_up_date } =
              response.data.prescription_data.textual;
            set_patient_phone(patient_phone_no);
            setFollowUpDate(follow_up_date);
          }
        })
        .catch((error) => {
          C2CConsole.log("error", error);
          // swal({
          //   title: "Error",
          //   text: error.message,
          //   icon: "error",
          // });
        });
    }
  }, []);

  return (
    <>
      {show ? (
        <Modal open={show} onClose={okayClickHandler}>
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="prescription-shared-modal-wrapper p-4">
              <div className="text-center align-items-center">
                <img
                  src={check}
                  alt="Success"
                  style={{ width: "77px", height: "77px" }}
                />
                <h2 className="header-text mt-2 pem-header-content">
                  Your Prescription Has Been Shared With The Patient
                </h2>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <Button
                  className="custom-modal-button yes-button"
                  style={{
                    borderRadius: "50px",
                    backgroundColor: "#17BAE0",
                    borderColor: "white",
                  }}
                  onClick={sharePEMClickHandler}
                >
                  Share Patient Education Content
                </Button>
              </div>
              <div
                style={{
                  color: "#17BAE0",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={okayClickHandler}
              >
                skip
              </div>
              {followUpDate !== undefined && followUpDate !== "" ? (
                <div className="text-center align-items-center mt-4">
                  <span>
                    Follow up Reminder set to patient given mobile number (
                    {patient_phone}){" "}
                    <span style={{ color: "#EA78A5" }}>
                      {moment(followUpDate).format("DD/MMM/YYYY")}
                    </span>
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
}
