import React, { Suspense } from "react";
import Loader from "../components/UI Component/Loader/Loader";

const DoctorRoutesNew = React.lazy(() => import("../Routes/DoctorRoutesNew"));

export default function () {
  return (
    <>
      <Suspense fallback={<div><Loader/></div>}>
        <DoctorRoutesNew />
      </Suspense>
    </>
  );
}
