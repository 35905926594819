import React, { useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import FormControl from "@material-ui/core/FormControl";
import FilledInput from "@material-ui/core/FilledInput";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import beforeLoginAxios from "../../Util/API Calls/beforeLogin";
import ErrorIcon from "@material-ui/icons/Error";
import ReactGA from "react-ga";
import afterLoginApiAxios from "../../Util/API Calls/afterLogin";

import { useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";
import { C2CConsole } from "../../Util/Helper/C2CConsole";

function LoginEnterPassword(props) {
  let { redirectUrl, phoneNumber = "", baseUrl = "" } = props;
  let history = useHistory();
  let location = useLocation();
  const { slug } = useParams();

  function handleForgotPassword() {
    beforeLoginAxios
      .post("public/send-otp", {
        user_type: "patient",
        channel_id: "patient-login",
        phone_no: `+91${phoneNumber}`,
      })
      .then((response) => {
        if (response) {
          if (response.data && response.data.statusCode === 200) {
            history.push("forgot-password", {
              redirectTo: redirectUrl,
            });
          }
        }
      });
  }

  function handlePassword(val) {
    setPassword(val);
  }

  let getBrowserName = () => {
    // Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== "undefined";

    // Safari 3.0+ "[object HTMLElementConstructor]"
    var isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
        (typeof safari !== "undefined" && window["safari"].pushNotification)
      );

    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;

    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1 - 79
    var isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    // Edge (based on chromium) detection
    var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1;

    // Blink engine detection
    var isBlink = isChrome && !!window.CSS;

    if (isFirefox) return "FireFox";
    if (isSafari) return "Safari";
    if (isIE && !isEdge) return "IE";
    if (isEdge) return "Edge";
    if (isChrome) return "Chrome";
    if (isEdgeChromium) return "EdgeChromium";
    if (isBlink) return "Blink-based";
  };

  let registerAnalytics = () => {
    afterLoginApiAxios
      .post(
        "user/engagement-analytics-data",
        {
          usecase: "login",
          user_type: "2",
          device_details: {
            device_type: "desktop",
            device_manufacturer: "",
            os_version: window.navigator.userAgent.substring(
              window.navigator.userAgent.indexOf("(") + 1,
              window.navigator.userAgent.indexOf(")")
            ),
            os_name: window.navigator.userAgent.substring(
              window.navigator.userAgent.indexOf("(") + 1,
              window.navigator.userAgent.indexOf(")")
            ),
            application_type: "web",
            application_version: "1.0",
            browser_name: getBrowserName(),
            browser_version: window.navigator.userAgent.substring(
              window.navigator.userAgent.lastIndexOf(")") + 1
            ),
          },
          patient_login_data: {
            patient_id: localStorage.getItem(LOCALSTORAGEKEY.USER_ID),
          },
        },
        {
          headers: {
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then(() => { })
      .catch((err) => C2CConsole.error(err));
  };

  const handleContinueClick = (e) => {
    e.preventDefault();
    //validations

    //api call
    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
      return;
    } else {
      setPasswordError(false);
    }

    beforeLoginAxios
      .post("auth/login", {
        channel_id: "patient-login",
        phone_no: `+91${phoneNumber}`,
        user_type: "patient",
        password: password,
      })
      .then((response) => {
        if (response) {
          if (response.data.hasOwnProperty("statusCode")) {
            if (response.data.statusCode == 200) {
              localStorage.setItem("id_token", response.data.user.id_token);
              localStorage.setItem("user_name", response.data.user.user_name);
              localStorage.setItem("user_type", response.data.user.user_type);
              localStorage.setItem(
                LOCALSTORAGEKEY.USER_ID,
                response.data.user.user_id
              );
              localStorage.setItem(
                "last_login_date",
                response.data.user.last_login_date
              );
              if (!response.data.user.last_login_date) {
                localStorage.setItem("last_login_date", "");
                localStorage.setItem("Home", true);
                localStorage.setItem("Appointment History", true);
              }
              ReactGA.event({
                category: "login",
                action: "patient_login",
                label: response.data.user.user_id,
              });

              registerAnalytics();

              if (
                location &&
                location.state &&
                location.state.redirectTo == false
              ) {
                return;
              }
              setTimeout(() => {
                if (localStorage.getItem("id_token")) {
                  history.replace(location.state.redirectTo, {
                    redirectTo: redirectUrl,
                  });
                }
              }, 100);
            } else if (response.data.statusCode == 510) {
              setPasswordError("Incorrect password entered. Please try again");
            }
          }
        }
      });
  };

  const [focusOnOTP, setFocusOnOTP] = useState(true);
  const [showSetPassword, setShowSetPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);

  if (phoneNumber == "") {
    // phoneNumber = "1234123412";
    return <Redirect to={`${baseUrl.split("/:slug")[0]}/${slug}/login/`} />;
  }

  return (
    <div
      className="container h-100 d-flex align-items-center justify-content-center flex-column"
      style={{ position: "relative", padding: "50px" }}
    >
      <div className="w-100">
        <ArrowBackIcon
          onClick={() => {
            history.replace(`${baseUrl.split("/:slug")[0]}/${slug}/login/`);
          }}
          style={{ position: "absolute", left: "9.5%", top: "11%" }}
          className="color-blue cursor-pointer"
        />
        <h3 className="text-center align-top">Login</h3>
      </div>
      <h6 className="text-center">Enter your password to login</h6>
      <div className="w-100 d-flex flex-row justify-content-between mt-4">
        <div className="d-inline-block text-muted">+91-{phoneNumber}</div>
        <Edit className="color-blue cursor-pointer" baseUrl={baseUrl} />
      </div>
      <div className="w-100 mt-3">
        <form
          className="d-flex flex-column align-items-center justify-content-center"
          onSubmit={(e) => {
            handleContinueClick(e);
          }}
        >
          <FormControl
            hiddenLabel="true"
            size="small"
            margin="dense"
            fullWidth="true"
          >
            <FilledInput
              id="password-input"
              placeholder="Enter Password"
              aria-describedby="my-helper-text"
              aria-label="Set-password"
              disableUnderline="true"
              autoFocus={!focusOnOTP}
              type={showPassword ? "text" : "password"}
              endAdornment={
                passwordError ? (
                  <ErrorIcon style={{ color: "#e84444" }} />
                ) : (
                  <ShowPassword
                    setShowPassword={setShowPassword}
                    showPassword={showPassword}
                  />
                )
              }
              onChange={(e) => {
                handlePassword(e.target.value);
              }}
              value={password}
            />
          </FormControl>

          <span
            className="align-self-end cursor-pointer textLink border-bottom-0"
            onClick={() => {
              handleForgotPassword();
            }}
            style={{ fontWeight: "600" }}
          >
            Forgot Password?
          </span>
          <div className="input-error align-self-center text-center mt-3">
            {passwordError ? passwordError : ""}
          </div>
          <button className="btn primary-cta-button rounded-pill mt-3 align-self-center py-2 px-4">
            Login
          </button>
        </form>
      </div>
      <div className="separator w-50 mt-3">Or</div>
      <div
        className="mt-3 textLink font-weight-bold"
        style={{ minWidth: "fit-content" }}
      >
        <div
          onClick={() => {
            history.replace(
              `${baseUrl.split("/:slug")[0]}/${slug}/login/otp-login`,
              {
                redirectTo: redirectUrl,
              }
            );
          }}
        >
          Login with OTP
        </div>
      </div>
    </div>
  );
}

export default LoginEnterPassword;

const Edit = ({ baseUrl }) => {
  let history = useHistory();
  const { slug } = useParams();
  return (
    <span
      className="color-blue cursor-pointer"
      onClick={() => {
        history.replace(`${baseUrl.split("/:slug")[0]}/${slug}/login/`);
      }}
    >
      Edit
      <EditIcon className="color-blue cursor-pointer" fontSize="inherit" />
    </span>
  );
};

export function ShowPassword(props) {
  const { setShowPassword, showPassword = false } = props;

  if (showPassword === false) {
    return (
      <span
        className="color-blue cursor-pointer"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        <VisibilityIcon />
      </span>
    );
  } else {
    return (
      <span
        className="color-blue cursor-pointer"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        <VisibilityOffIcon />
      </span>
    );
  }
}
