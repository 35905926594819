import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import EnterDoctorDetails from "./EnterDoctorDetails";
import LoginEnterPassword from "./LoginEnterPassword";
import OtploginPartialDoctor from "./otploginPartialDoctor";
import PartialDoctorForgotPassword from "./PartialDoctorForgotPassword";
import PartialUserEnterPassword from "./PartialUserEnterPassword";
import SignUpUnregistered from "./SignUpUnregistered";
import StaffLoginEnterPassword from "./StaffLoginEnterPassword";
import StaffOtpLogin from "./StaffOtpLogin";
import StaffVerifyNumber from "./staffVerifyNumber";
import "./styles.scss";
import { disableHelpButton } from "../common/DisableHelpButton/DisableHelpButton";
import DoctorSignInPasswordContainer from "../DoctorSignInContainer/DoctorSignInPasswordContainer";
import DoctorSignInOTPContainer from "../DoctorSignInContainer/DoctorSignInOTPContainer";
import DoctorResetPasswordContainer from "../DoctorSignInContainer/DoctorResetPasswordContainer";
import DoctorCreateAccountContainer from "../DoctorSignInContainer/DoctorCreateAccountContainer";
function DoctorLogin(props) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if (props.showPatientLoginModal) {
      disableHelpButton();
      return () => {
        disableHelpButton();
      };
    }
  }, []);
  return (
    <>
      <div className="max-width-restrict">
        <Switch>
          <Route path="/doctor/login/staff/set-password">
            <StaffVerifyNumber phoneNumber={phoneNumber} />
          </Route>
          <Route path="/doctor/login/staff/enter-password">
            <StaffLoginEnterPassword phoneNumber={phoneNumber} />
          </Route>
          <Route path="/doctor/login/sign-up" exact>
            <DoctorCreateAccountContainer
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              loader={loader}
              setLoader={setLoader}
            />
          </Route>
          <Route path="/doctor/login/sign-up/set-password">
            <SignUpUnregistered phoneNumber={phoneNumber} />
          </Route>
          <Route path="/doctor/login/sign-up/enter-details">
            <EnterDoctorDetails phoneNumber={phoneNumber} />
          </Route>
          <Route path="/doctor/login/set-password">
            <DoctorResetPasswordContainer
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              loader={loader}
              setLoader={setLoader}
            />
          </Route>
          <Route path="/doctor/login/partial-doctor/set-password">
            <PartialDoctorForgotPassword
              phoneNumber={phoneNumber}
              loader={loader}
              setLoader={setLoader}
            />
          </Route>
          <Route path="/doctor/login/partial-doctor/otp-login">
            <OtploginPartialDoctor
              phoneNumber={phoneNumber}
              loader={loader}
              setLoader={setLoader}
            />
          </Route>
          <Route path="/doctor/login/partial-doctor" exact>
            <PartialUserEnterPassword
              phoneNumber={phoneNumber}
              loader={loader}
              setLoader={setLoader}
            />
          </Route>
          <Route path="/doctor/login/otp-login">
            <DoctorSignInOTPContainer
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              loader={loader}
              setLoader={setLoader}
            />
          </Route>
          <Route path="/doctor/login/staff/otp-login">
            <StaffOtpLogin
              phoneNumber={phoneNumber}
              loader={loader}
              setLoader={setLoader}
            />
          </Route>
          <Route path="/doctor/login/enter-password">
            <LoginEnterPassword
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              loader={loader}
              setLoader={setLoader}
            />
          </Route>
          <Route path="/doctor/login/" exact>
            <DoctorSignInPasswordContainer
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              loader={loader}
              setLoader={setLoader}
            />
          </Route>
        </Switch>
      </div>
    </>
  );
}

export default DoctorLogin;
