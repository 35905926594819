import React, { useState, useEffect } from "react";
import afterLoginApiAxios from "../../Util/API Calls/afterLogin";
import moment from "moment";
import CreateIcon from "@mui/icons-material/Create";
import axios from "axios";
import ShareManualRxModal from "./ShareManualRxMoadal";
import DocumentContainer from "../UI Component/DocumentViewer/DocumentCotainer";
import VisibilityIcon from "@material-ui/icons/Visibility";
import GetAppIcon from "@material-ui/icons/GetApp";
import ReplyIcon from "@material-ui/icons/Reply";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";
import { C2CConsole } from "../../Util/Helper/C2CConsole";

const DoctorViewManualRX = (props) => {
  const { appointment_id } = props;
  const [manual_rx_list, setManualRXList] = useState([]);
  const [showRxModal, setshowRxModal] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [documentShow, setDocumentShow] = useState(false);
  const [fullView, setFullView] = useState(false);
  const [showHoriz, setShowHoriz] = useState(false);
  let is_staff = localStorage.getItem("user_type");
  let userId = localStorage.getItem(LOCALSTORAGEKEY.USER_ID);
  let staffId = localStorage.getItem(LOCALSTORAGEKEY.STAFF_ID);

  const [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {
    handleWindowResize();
    function handleWindowResize() {
      window.innerWidth < 900 ? setShowHoriz(true) : setShowHoriz(false);
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  const icons = {
    view: true,
    share: true,
    download: true,
  };
  const ImagetoPrint = (source) => {
    return (
      "<html><head><scri" +
      "pt>function step1(){\n" +
      "setTimeout('step2()', 10);}\n" +
      "function step2(){window.print();window.close()}\n" +
      "</scri" +
      "pt></head><body onload='step1()'>\n" +
      "<img src='" +
      source +
      "' /></body></html>"
    );
  };

  const handleAction = (key, index, e_rx_list, result) => {
    switch (key) {
      case "download":
        downloadCall(result, fileName, fileType);
        break;
      case "view":
        viewPrescription(fileName, fileType, result);
        break;
      case "share":
        ShareERx(result);
        break;
      default:
        break;
    }
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const printFile = (url, fileType) => {
    if (fileType.toLowerCase().includes("pdf")) {
      window.open(url, "PRINT", "height=400,width=600");
    }
    if (
      fileType.toLowerCase().includes("jpg") ||
      fileType.toLowerCase().includes("png") ||
      fileType.toLowerCase().includes("jpeg")
    ) {
      var Pagelink = "about:blank";
      var pwa = window.open(Pagelink, "_new");
      pwa.document.open();
      pwa.document.write(ImagetoPrint(url));
      pwa.document.close();
    }
  };

  useEffect(() => {
    afterLoginApiAxios
      .post(
        "doctor/get-manual-prescription-rev1",
        {
          user_type: is_staff === "staff" ? "4" : "1",
          user_id: is_staff === "staff" ? staffId : userId,
          appointment_id: appointment_id, //"1b90c17c-f956-4570-9214-1132fec31893",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          if (response.data.data && response.data.data.length > 0) {
            setManualRXList(response.data.data);
            props.setManualRxList(response?.data?.data);
          } else {
            setManualRXList([]);
          }
        }
      })
      .catch((err) => C2CConsole.log(err));
  }, []);

  const downloadURI = async (url, name, fileType) => {
    const blob = await axios.get(url, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
      responseType: "blob",
    });
    const a = document.createElement("a");
    const href = window.URL.createObjectURL(blob.data);
    a.href = href;
    a.download = name + "." + fileType.trim();
    a.click();
  };

  const ShareManualRx = (data, document_id) => {
    let params = {};

    if (userId) {
      params["doctor_id"] = userId;
    }
    afterLoginApiAxios
      .post(
        "doctor/upload-manual-prescription-rev1",
        {
          user_type: is_staff === "staff" ? "4" : "1",
          user_id: is_staff === "staff" ? staffId : userId,
          ...params,
          appointment_id: appointment_id, //"1b90c17c-f956-4570-9214-1132fec31893",
          prescriptions: [data],
          document_id: document_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          setshowRxModal(true);
        }
      })
      .catch((err) => C2CConsole.log(err));
  };

  function closeNotification() {
    setDocumentShow(false);
    setFileUrl("");
    setFileName("");
    setFileType("");
    setFullView(false);
  }
  const downloadCall = (result, fileName, fileType) => {
    downloadURI(result.presigned_url, fileName, fileType);
  };
  const viewPrescription = (fileName, fileType, result) => {
    setFileName(fileName);
    setFileType(fileType);
    setFileUrl(result.presigned_url_view);
    setDocumentShow(true);
  };
  const getActionIcon = (key) => {
    let icon =
      key === "view" ? (
        <span className="mr-2">
          <VisibilityIcon fontSize="small" />
        </span>
      ) : key === "edit" ? (
        <span className="mr-2">
          <CreateIcon fontSize="small" />
        </span>
      ) : key === "download" ? (
        <span className="mr-2">
          <GetAppIcon fontSize="small" />
        </span>
      ) : (
        key === "share" && (
          <span className="mr-2">
            <ReplyIcon fontSize="small" style={{ transform: "scaleX(-1)" }} />
          </span>
        )
      );
    return icon;
  };
  const ShareERx = (result) => {
    ShareManualRx(
      {
        doc_data: result.document_path,
        file_size: result.file_size,
        doc_title: result.document_title,
      },
      result.document_id
    );
  };
  return (
    <>
      {manual_rx_list.length > 0 ? (
        <div className="w-100  m_rx_border">
          <div className="m_rx_border_bottom  p-3">
            <p className="manual_rx_heading">
              Manual Prescription ({manual_rx_list.length})
            </p>
          </div>
          {manual_rx_list.length > 0
            ? manual_rx_list.map((result, index) => {
                const lastDot = result.document_title.lastIndexOf(".");
                const fileName =
                  result.document_title.substring(0, lastDot) ||
                  "Medical Record" + index;
                const fileType =
                  result.document_title.substring(lastDot + 1) || "";
                const staffName = result?.staff_name || "";
                return (
                  <div
                    className={`d-flex p-3 ${
                      manual_rx_list.length === 1 ||
                      manual_rx_list.length - 1 === index
                        ? ""
                        : "m_rx_border_bottom"
                    }`}
                    key={index}
                  >
                    <div style={{ width: "60%" }}>
                      <p
                        className="m_rx_title"
                        style={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {fileName}
                      </p>
                      <p className="m_rx_details">
                        {moment(result.created_at).format("DD MMM, YYYY ")}|{" "}
                        {moment(result.created_at.split("+")[0]).format(
                          " hh:mm A"
                        )}
                      </p>
                      <div className="medical-record-type">
                        {fileType && fileType.toUpperCase()}{" "}
                        <div className="bullet ml-1 mr-1 mt-2" />{" "}
                        {result.file_size}MB
                      </div>
                      {staffName !== "" && (
                        <div
                          className="medical-record-type"
                          style={{ textTransform: "capitalize" }}
                        >
                          Shared by Staff-{staffName}
                        </div>
                      )}
                    </div>
                    {/* </div> */}
                    {/* <div className="col-3 d-flex"> */}
                    {showHoriz ? (
                      <div
                        style={{ width: "40%" }}
                        className="d-flex justify-content-end"
                      >
                        <div class="dropdown">
                          <span
                            style={{ paddingTop: "8px" }}
                            data-tooltip="Edit"
                            data-tooltip-conf="bottom"
                            onClick={(e) => {
                              handleClick(e);
                            }}
                            data-toggle="dropdown"
                          >
                            <i className="medical-record-icon m-1">
                              <MoreHoriz fontSize="small" />
                            </i>
                          </span>
                          <div className="dropdown-menu dropleft menu-pos">
                            {Object.keys(icons).map((key, index) => {
                              return (
                                icons[key] === true && (
                                  <button
                                    style={{
                                      color: "#26bee2",
                                      background: "transparent",
                                    }}
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      handleAction(
                                        key,
                                        index,
                                        manual_rx_list,
                                        result
                                      );
                                    }}
                                  >
                                    {getActionIcon(key)}
                                    {key?.charAt(0)?.toUpperCase() +
                                      key?.slice(1)}
                                  </button>
                                )
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{ width: "40%" }}
                        className="d-flex justify-content-end"
                      >
                        {fileType.toLowerCase().includes("pdf") ||
                        fileType.toLowerCase().includes("jpg") ||
                        fileType.toLowerCase().includes("png") ||
                        fileType.toLowerCase().includes("jpeg") ? (
                          <span style={{ paddingTop: "8px" }}>
                            <i
                              className=" medical-record-icon m-1"
                              data-tooltip="View"
                              data-tooltip-conf="bottom"
                              style={{ fontStyle: "normal" }}
                              onClick={() =>
                                viewPrescription(fileName, fileType, result)
                              }
                            >
                              <VisibilityIcon fontSize="small" />
                            </i>
                          </span>
                        ) : (
                          <span style={{ paddingTop: "8px" }}>
                            <i
                              className=" view-disable-icon m-1"
                              data-tooltip="View"
                              data-tooltip-conf="bottom"
                              style={{ fontStyle: "normal" }}
                            >
                              <VisibilityIcon fontSize="small" />
                            </i>
                          </span>
                        )}
                        <span style={{ paddingTop: "8px" }}>
                          <i
                            className=" medical-record-icon m-1"
                            data-tooltip="Download"
                            data-tooltip-conf="bottom"
                            style={{ fontStyle: "normal" }}
                            onClick={() =>
                              downloadCall(result, fileName, fileType)
                            }
                          >
                            <GetAppIcon fontSize="small" />
                          </i>
                        </span>
                        <span style={{ paddingTop: "8px" }}>
                          <i
                            className=" medical-record-icon m-1"
                            data-tooltip="Share"
                            data-tooltip-conf="bottom"
                            style={{ fontStyle: "normal" }}
                            onClick={() => {
                              ShareERx(result);
                            }}
                          >
                            <ReplyIcon
                              fontSize="small"
                              style={{ transform: "scaleX(-1)" }}
                            />
                          </i>
                        </span>
                      </div>
                    )}
                  </div>
                );
              })
            : null}

          {/* </div> */}
          {/* </div> */}
        </div>
      ) : null}
      {documentShow && (
        <DocumentContainer
          cancelHandler={closeNotification}
          fullViewHandler={() => {
            setFullView(!fullView);
          }}
          url={fileUrl}
          fileName={fileName}
          fileType={fileType}
          // width={width || ""}
        />
      )}
      <ShareManualRxModal
        show={showRxModal}
        handleClose={() => {
          setshowRxModal(false);
        }}
      />
    </>
  );
};

export default DoctorViewManualRX;
