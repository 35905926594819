import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import ChiefComplaintItem from "./chiefComplaintItem";
import DiagnosisItem from "./diagnosisItem";
import Spinner from "react-bootstrap/Spinner";
import PatientVitals from "./PatientVitals";
import moment from "moment";
export default function PatientItemComponent(props) {
  const {
    complaints,
    addComplaintItem,
    handleChange,
    chiefComplaintsChange,
    diagnosisList,
    diagnosisChange,
    addDiagnosisItem,
    patientGender,
    patient,
    getSerachPopulatedData,
    dropDown,
    dropDownSelected,
    handleDeletePrec,
    handleVitalsChange,
    vitals,
    moreVitalsBtnState,
  } = props;
  const [showLoader, setShowLoader] = useState(false);

  const handleDateChange = (date, item) => {
    let dateOne = moment().format("YYYY-MM-DD");
    let dateTwo = moment(date).format("YYYY-MM-DD");
    let value = Math.abs(moment(dateOne).diff(dateTwo, "days")) + "";
    handleVitalsChange(date ? value + "" : "", item.name, item.unit);
  };

  return (
    <Card bg={"light"} className="patient-content mt-2 border-0 p-3">
      <Col xs={12}>
        <Row className="text-uppercase mb-2">
          <Col
            className="prescription-section-title"
            style={{ color: "black", fontSize: "14px" }}
          >
            VITALS
          </Col>
        </Row>
        <PatientVitals
          patientGender={patientGender}
          patient={patient}
          vitals={vitals}
          moreVitalsBtnState={moreVitalsBtnState}
          handleUnitChange={(value, item) =>
            handleVitalsChange(value, item.name, item.unit)
          }
          handleVitalChange={(e, item) => {
            handleVitalsChange(e.target.value, item.name, item.unit);
          }}
          handleDateChange={handleDateChange}
        />
        {/* <Row className="mb-2">
          <Col className="history_input">
            <input
              type="text"
              pattern="[0-9]{3}"
              placeholder="Weight"
              type="number"
              style={{
                fontSize: "14px",
                height: "48px",
              }}
              className="form-control shadow-sm"
              id="patient-weight"
              name="weight"
              value={patient.weight}
              onChange={(e) => {
                if (e.target.value.length > 3) {
                  e.target.value = e.target.value.substr(0, 3);
                }
                handleChange(e);
              }}
            />
          </Col>
          <Col className="history_input">
            <input
              type="text"
              placeholder="Height"
              pattern="[0-9]{3}"
              type="number"
              style={{
                fontSize: "14px",
                height: "48px",
              }}
              className="form-control shadow-sm"
              id="patient-height"
              name="height"
              value={patient.height}
              onChange={(e) => {
                if (e.target.value.length > 3) {
                  e.target.value = e.target.value.substr(0, 3);
                }
                handleChange(e);
              }}
            />
          </Col>
        </Row> */}
        {/* {patientGender === "Female" ? (
          <Row className="mb-2">
            <Col>
              <input
                type="text"
                placeholder="LMP"
                pattern="[0-9]{3}"
                type="number"
                style={{
                  fontSize: "14px",
                  height: "48px",
                }}
                className="form-control shadow-sm"
                id="lmp"
                name="lmp"
                value={patient.lmp}
                onChange={(e) => {
                  if (e.target.value.length > 3) {
                    e.target.value = e.target.value.substr(0, 3);
                  }
                  handleChange(e);
                }}
              />
            </Col>
          </Row>
        ) : null} */}
        <Row>
          <ChiefComplaintItem
            complaints={complaints}
            addComplaintItem={addComplaintItem}
            chiefComplaintsChange={chiefComplaintsChange}
            getSerachPopulatedData={getSerachPopulatedData}
            dropDown={dropDown}
            dropDownSelected={dropDownSelected}
            handleDeletePrec={handleDeletePrec}
          />
        </Row>
        <Row className="text-uppercase mb-2">
          <Col
            className="prescription-section-title"
            style={{ color: "black", fontSize: "14px" }}
          >
            HISTORY
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <div className="history_container">
              <input
                type="text"
                placeholder="Relevant points from history"
                style={{
                  fontSize: "14px",
                  height: "48px",
                }}
                className="form-control shadow-sm"
                id="patientHistory"
                name="history"
                value={patient.history}
                onChange={(e) => {
                  setShowLoader(true);
                  handleChange(e);
                  setTimeout(function () {
                    setShowLoader(false);
                  }, 500);
                }}
              />
              {showLoader === true ? (
                // <span className="span_css">
                <Spinner animation="border" className="spinner_css" />
              ) : // {/* </span> */}
              null}
            </div>
          </Col>
        </Row>
        <Row>
          <DiagnosisItem
            diagnosisList={diagnosisList}
            addDiagnosisItem={addDiagnosisItem}
            diagnosisChange={diagnosisChange}
            getSerachPopulatedData={getSerachPopulatedData}
            dropDown={dropDown}
            dropDownSelected={dropDownSelected}
            handleDeletePrec={handleDeletePrec}
          />
        </Row>
      </Col>
    </Card>
  );
}
