export const LOCALSTORAGEKEY = {
  appointmentDoctorName: "appointmentDoctorName",
  transactionDoctorNameFilter: "transactionDoctorNameFilter",
  dashboardStartDate: "dashboardStartDate",
  dashboardEndDate: "dashboardEndDate",
  dashboardConsultation: "dashboardConsultation",
  dashboardDoctorName: "dashboardDoctorName",
  CLINIC_ID: "clinicId",
  USER_TYPE: "user_type",
  DOCTOR_ROLE_NAME: "doctor_role_name",
  USER_ID: "user_id",
  ID_TOKEN: "id_token",
  SELECTED_SPECIALIZATIONS: "selectedSpecializations",
  DOCTOR_CLINIC_ASSOCIATION: "doctor_clinic_association",
  STAFF_CLINIC_ASSOCIATION: "staff_clinic_association",
  STAFF_ID: "staff_id",
  DOCTOR_ROLE: "doctorRole",
  MEETING_ID: "meeting_id",
  CAREGIVER_NAME: "caregiver_name",
  CAREGIVER_EMAIL: "caregiver_email",
  CAREGIVER_GOVT_ID: "caregiver_govt_id",
  ECAREGIVER_NAME: "ecaregiverName",
  ECAREGIVER_EMAIL: "ecaregiverEmail",
  ECAREGIVER_GOVT_ID: "ecaregivergovtID",
  EPATIENT_PHONE: "epatientPhone",
  WHATSAPPENABLE: "whatsAppEnable",
  WHATSAPP_CONSENT: "whatsapp_consent",
  WHATSAPP_NUMBER: "whatsapp_number",
  USER_NAME: "user_name",
  REGISTRATION_TYPE: "registration_type",
  FORMATTED_UHID: "formatted_uhid",
  SLOT_ID: "slot_id",
  DATE: "date",
  DOCTOR: "doctor",
  PATIENT_NAME: "patient_name",
  PATIENT_GENDER: "patient_gender",
  PATIENT_EMAIL: "patient_email",
  SELECTEDPATIENT: "selectedPatient",
  FLAG_APPOINTMENT_ID: "flag_appointment_id",
  SCHEDULE_SID: "schedule_sid",
  SCHEDULE_START: "schedule_start",
  SCHEDULE_DATE: "schedule_date",
  SCHEDULE_NEW_USER: "schedule_new_user",
  EXTSTING_USER_BOOK_NEW_ACCOUNT: "existing_user_book_new_account",
  EXISTING_USER_BOOK_PATIENT: "existing_user_book_patient",
  PATIENT_DOB: "patient_dob",
  EXISTING_USER_BOOK_IS_FOLLOW_UP: "existing_user_book_is_follow_up",
  PAYMENT_ID: "paymentID",
  SIGNATURE: "signature",
  AMOUNT: "amount",
  CONVENIENCE_FEE: "convenience_fee",
  GST_ON_CONVENIENCE_FEE: "gst_on_convenience_fee",
  PATIENT_REG_TYPE: "patient_reg_type",
  PATIENT_ADDRESS: "patient_address",
  PATIENT_PINCODE: "patient_pincode",
  STATE_CODE: "state_code",
  PATIENT_IS_DRUG_ALLERGY_PRESENT: "patient_is_drug_allergy_present",
  PATEINT_IS_HABIT_PRESENT: "patient_is_habit_present",
  IS_TAKING_MEDICINE: "is_taking_medicine",
  MEDICATION: "medication",
  PATIENT_DRUG_ALLERGY: "patient_drug_allergy",
  PATIENT_HABITS: "patient_habits",
  FULL_NAME: "full_name",
  IS_FOLLOWUP: "is_followup",
  FLAG_APPOINTMENT_ID_INSTANT: "flag_appointment_id_instant",
  REGISTERED_FOR_SELF: "registered_for_self",
  IS_NEW: "isNew",
  PATIENT_PHONE: "patient_phone",
  FOLLOWUP_CONSULTATION_TYPE: "followup_consultation_type",
  CONSULTATION_TYPE: "consultation-type",
  APPOINTMENT_SLOT_LOCKED: "appointment_slot_locked",
  STATE_NAME: "state_name",
  ORDER_ID: "order_id",
  TRANSACTION_ID: "txnID",
  INCLINE_ONLINE_PAYMENT: "inclinic-online",
  INCLINE_ONLINE_PAYMENT_ID: "inclinic-online-id",
  FREE_BOOKING: "free",
  IS_CLINIC_AND_LAB: "is_clinic_and_lab",
  IS_LAB: "is_lab",
  IS_ASSOCIATED: "is_associated_to_clinic",
  PREVIOUS_SELECTED_DOCTORS: "previousSelectedDoctors",
  SELECTED_CLINIC_DETAILS: "selectedClinicDetails",
  SESSION_ID: "session_id",
  ABHA_TOKEN_COUNT: "abhaTokenCount",
  ENABLE_LEADS_DASHBOARD: "enable_leads_dashboard",
  ENABLE_CALL_APPOINTMENT_PATIENTS: "enable_call_appointment_patients",
  ENABLE_CALL_LEADS_PATINETS: "enable_call_leads_patients",
};

export const removeFromLocal = (type) => {
  switch (type) {
    case LOCALSTORAGEKEY.appointmentDoctorName:
      localStorage.removeItem(LOCALSTORAGEKEY.appointmentDoctorName);
      break;
    case LOCALSTORAGEKEY.transactionDoctorNameFilter:
      localStorage.removeItem(LOCALSTORAGEKEY.transactionDoctorNameFilter);
      break;
    case LOCALSTORAGEKEY.dashboardStartDate:
      localStorage.removeItem(LOCALSTORAGEKEY.dashboardStartDate);
      break;
    case LOCALSTORAGEKEY.dashboardEndDate:
      localStorage.removeItem(LOCALSTORAGEKEY.dashboardEndDate);
      break;
    case LOCALSTORAGEKEY.dashboardConsultation:
      localStorage.removeItem(LOCALSTORAGEKEY.dashboardConsultation);
      break;
    case LOCALSTORAGEKEY.dashboardDoctorName:
      localStorage.removeItem(LOCALSTORAGEKEY.dashboardDoctorName);
      break;
    default:
      break;
  }
};
