import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import CachedIcon from "@material-ui/icons/Cached";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useTimer } from "../../Hooks/useTimer";
import swal from "sweetalert";
import beforeLoginAxios from "../../Util/API Calls/beforeLogin";
import ErrorIcon from "@material-ui/icons/Error";
import ReactGA from "react-ga";
import afterLoginApiAxios from "../../Util/API Calls/afterLogin";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";
import { C2CConsole } from "../../Util/Helper/C2CConsole";

function ResetPassword(props) {
  let { phoneNumber = "", baseUrl } = props;

  let history = useHistory();
  let location = useLocation();
  const { slug } = useParams();

  const [showPassword, setShowPassword] = useState(false);
  const [otpInput, setOtpInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [resendAllowed, setResendAllowed] = useState(false);
  const [otpApproved, setOtpApproved] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);

  useEffect(() => {
    swal({
      title:
        "An OTP has been sent to your registered Phone number and Email Address ",
      type: "warning",
    });
  }, []);

  const handleOTPInput = (input) => {
    setOtpApproved(false);
    setOtpInput(input);
  };

  const { time, start, reset } = useTimer({
    initialTime: 60,
    endTime: 0,
    timerType: "DECREMENTAL",
    autostart: true,
    onTimeOver: () => {
      setResendAllowed(true);
    },
  });

  function handleForgotPassword() {
    if (!resendAllowed) return;
    reset();
    start();
    setResendAllowed(false);
    beforeLoginAxios
      .post("public/send-otp", {
        user_type: "patient",
        channel_id: "patient-login",
        phone_no: `+91${phoneNumber}`,
      })
      .then((response) => {
        if (response) {
          if (response.data && response.data.statusCode === 200) {
            swal({
              title:
                "An OTP has been sent to your registered Phone number and Email Address ",
              type: "warning",
            });
          }
        }
      });
  }

  let getBrowserName = () => {
    // Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== "undefined";

    // Safari 3.0+ "[object HTMLElementConstructor]"
    var isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && window["safari"].pushNotification)
      );

    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;

    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1 - 79
    var isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    // Edge (based on chromium) detection
    var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1;

    // Blink engine detection
    var isBlink = isChrome && !!window.CSS;

    if (isFirefox) return "FireFox";
    if (isSafari) return "Safari";
    if (isIE && !isEdge) return "IE";
    if (isEdge) return "Edge";
    if (isChrome) return "Chrome";
    if (isEdgeChromium) return "EdgeChromium";
    if (isBlink) return "Blink-based";
  };

  let registerAnalytics = () => {
    afterLoginApiAxios
      .post(
        "user/engagement-analytics-data",
        {
          usecase: "login",
          user_type: "2",
          device_details: {
            device_type: "desktop",
            device_manufacturer: "",
            os_version: window.navigator.userAgent.substring(
              window.navigator.userAgent.indexOf("(") + 1,
              window.navigator.userAgent.indexOf(")")
            ),
            os_name: window.navigator.userAgent.substring(
              window.navigator.userAgent.indexOf("(") + 1,
              window.navigator.userAgent.indexOf(")")
            ),
            application_type: "web",
            application_version: "1.0",
            browser_name: getBrowserName(),
            browser_version: window.navigator.userAgent.substring(
              window.navigator.userAgent.lastIndexOf(")") + 1
            ),
          },
          patient_login_data: {
            patient_id: localStorage.getItem(LOCALSTORAGEKEY.USER_ID),
          },
        },
        {
          headers: {
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then(() => {})
      .catch((err) => C2CConsole.error(err));
  };

  const handleContinueClick = (e) => {
    e.preventDefault();
    setPasswordError(false);
    if (otpInput.length == 0) return;
    if (otpInput.length !== 6) {
      setOtpError("OTP must be exactly 6 characters");
      return;
    }
    setOtpError(false);
    if (passwordInput.length <= 5) {
      setPasswordError("Password must be at least 6 characters");
      return;
    }
    setPasswordError(false);
    setOtpLoading(true);
    beforeLoginAxios
      .post("auth/login", {
        channel_id: "patient-login",
        phone_no: `+91${phoneNumber}`,
        user_type: "patient",
        user_otp: otpInput,
      })
      .then((response) => {
        if (response) {
          setOtpLoading(false);
          if (response.data.hasOwnProperty("statusCode")) {
            if (response.data.statusCode == 200) {
              localStorage.setItem("id_token", response.data.user.id_token);
              localStorage.setItem("user_name", response.data.user.user_name);
              localStorage.setItem("user_type", response.data.user.user_type);
              localStorage.setItem(
                LOCALSTORAGEKEY.USER_ID,
                response.data.user.user_id
              );
              localStorage.setItem(
                "last_login_date",
                response.data.user.last_login_date
              );
              if (!response.data.user.last_login_date) {
                localStorage.setItem("last_login_date", "");
                localStorage.setItem("Home", true);
                localStorage.setItem("Appointment History", true);
              }
              ReactGA.event({
                category: "login",
                action: "patient_login",
                label: response.data.user.user_id,
              });
              registerAnalytics();
              setOtpApproved(true);
              afterLoginApiAxios
                .post("patient/set-password", {
                  id_token: response.data.user.id_token,
                  new_password: passwordInput,
                  headers: {
                    Authorization: localStorage.getItem("id_token"),
                  },
                })
                .then((response) => {
                  if (response) {
                    if (response.data && response.data.statusCode === 200) {
                      swal({
                        title: "Login Successful!",
                        type: "success",
                      }).then(function () {});

                      if (
                        location &&
                        location.state &&
                        location.state.redirectTo == false
                      ) {
                        return;
                      }

                      setTimeout(() => {
                        if (localStorage.getItem("id_token")) {
                          history.replace(location.state.redirectTo);
                        }
                      }, 100);
                    } else {
                      setPasswordError("Something Went Wrong!");
                    }
                  }
                });
            } else if (response.data.statusCode == 513) {
              setOtpError("OTP you entered was incorrect");
            } else {
              setOtpError(response.data.message);
            }
          }
        }
      })
      .catch(function (error) {
        C2CConsole.log(error);
      });
  };

  const getOTPAdornment = () => {
    if (otpLoading)
      return <CachedIcon className="rotating" style={{ color: "#4AC9E7" }} />;
    if (otpApproved) return <CheckCircleIcon style={{ color: "#10A74A" }} />;
    if (otpError) return <ErrorIcon style={{ color: "#e84444" }} />;
    return null;
  };

  if (phoneNumber == "") {
    // phoneNumber = "1234123412";
    return <Redirect to={`${baseUrl.split("/:slug")[0]}/${slug}/login/`} />;
  }

  return (
    <div
      className="container h-100  d-flex align-items-center justify-content-center flex-column"
      style={{ position: "relative", padding: "50px" }}
    >
      <div>
        <ArrowBackIcon
          onClick={() => {
            history.replace(`${baseUrl.split("/:slug")[0]}/${slug}/login/`);
          }}
          style={{ position: "absolute", left: "9.5%", top: "11%" }}
          className="color-blue cursor-pointer"
        />
        <h3 className="text-center align-top">
          {location && location.state && location.state.title
            ? location.state.title
            : "Verify Number"}
        </h3>
      </div>
      <h6 className="text-center">Please Enter the OTP</h6>
      <div className="w-100 d-flex flex-row justify-content-between mt-3">
        <div className="d-inline-block text-muted">+91-{phoneNumber}</div>
        <Edit className="color-blue cursor-pointer" baseUrl={baseUrl} />
      </div>
      <div className="w-100 mt-2">
        <form
          className="d-flex flex-column align-items-center justify-content-center"
          onSubmit={(e) => {
            handleContinueClick(e);
          }}
        >
          <FormControl
            hiddenLabel="true"
            size="small"
            margin="dense"
            fullWidth="true"
          >
            <FilledInput
              id="OTP-input"
              aria-describedby="my-helper-text"
              required
              aria-required
              placeholder="Enter OTP"
              aria-label="otp-input"
              autoFocus={true}
              inputProps={{
                autoComplete: "off",
                inputMode: "numeric",
              }}
              disableUnderline="true"
              value={otpInput}
              endAdornment={getOTPAdornment()}
              onChange={(e) => {
                handleOTPInput(e.target.value);
              }}
            />
          </FormControl>
          <div className="row justify-content-between w-100">
            <div className="col-6 p-0">
              {resendAllowed ? (
                <div className="align-self-start text-muted">OTP expired</div>
              ) : (
                <div className="align-self-start text-muted">
                  {time} seconds left to expire
                </div>
              )}
            </div>
            <div className="col-6 p-0 d-flex justify-content-end align-items-start">
              <div
                className={`cursor-pointer ${
                  !resendAllowed ? "text-muted pointer-disabled" : "textLink"
                }`}
                style={{ textAlign: "right" }}
                onClick={(e) => {
                  e.preventDefault();
                  handleForgotPassword();
                }}
              >
                Resend OTP
              </div>
            </div>
          </div>
          <div className="input-error text-center mt-1">
            {otpError ? otpError : ""}
          </div>
          <div className="mt-3 text-center">
            {location && location.state && location.state.resetTitle
              ? location.state.resetTitle
              : "Set password for easier access (minimum 6 characters required)"}
          </div>
          <FormControl
            hiddenLabel={true}
            size="small"
            margin="dense"
            fullWidth={true}
          >
            <FilledInput
              id="pwd-input"
              aria-describedby="my-helper-text"
              aria-label="Set-password"
              placeholder="Enter Password"
              disableUnderline={true}
              required
              aria-required
              type={showPassword ? "text" : "password"}
              endAdornment={
                <ShowPassword
                  setShowPassword={setShowPassword}
                  showPassword={showPassword}
                />
              }
              value={passwordInput}
              onChange={(e) => {
                setPasswordInput(e.target.value);
              }}
            />
          </FormControl>
          <div className="input-error text-center mt-1">
            {passwordError ? passwordError : ""}
          </div>
          <button
            className="btn primary-cta-button rounded-pill mt-2 align-self-center py-2 px-4"
            disabled={resendAllowed}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;

const Edit = ({ baseUrl }) => {
  let history = useHistory();
  const { slug } = useParams();
  return (
    <span
      className="color-blue cursor-pointer"
      onClick={() => {
        history.replace(`${baseUrl.split("/:slug")[0]}/${slug}/login/`);
      }}
    >
      Edit
      <EditIcon className="color-blue cursor-pointer" fontSize="inherit" />
    </span>
  );
};

function ShowPassword(props) {
  const { setShowPassword, showPassword = false } = props;

  if (showPassword === false) {
    return (
      <span
        className="color-blue cursor-pointer"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        <VisibilityIcon />
      </span>
    );
  } else {
    return (
      <span
        className="color-blue cursor-pointer"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        <VisibilityOffIcon />
      </span>
    );
  }
}
