import React, { Component } from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";
import "../styles/Categories.scss";
import "../styles/MostAskedQuestion.scss";
import TabWithHorizontalScroll from "./TabsParent";
import { transformData } from "./TransformData";

const theme = createMuiTheme({
  spacing: 4,
});

const styles = {
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
};

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theCategory: "",
      selectedCategory: "",
      unique: "",
      queries: "",
      mainUnique: [],
      checkCategory: "",
      renderUniqueCategory: [],
    };
  }

  render() {
    return (
      <div className="categories-layout">
        <div className="categories">Categories</div>
        <div className="header-align">
          {this.props.temporaryData !== undefined ? (
            <TabWithHorizontalScroll
              tabData={transformData(this.props.temporaryData)}
            />
          ) : (
            "Currently Empty, Please wait while your data is being fetched"
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Categories);
