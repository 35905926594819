import afterLoginApiAxiosPEM from "./PEMAfterLogin";
import axios from "axios";

const uploadMediaToS3 = async (data, file) => {
  const dataToUpload = {
    doc_title: data.fileName,
    file_size: data.size,
    use_case: "upload-pem-content",
    thumbnail_title: data.fileName,
    thumbnail_file_size: data.size,
  };
  // 1st call ==>
  const res = await afterLoginApiAxiosPEM({
    method: "post",
    url: "pem/upload-pem-media",
    data: dataToUpload,
  });

  // 2nd call ==>
  if (res && res.data && res.data.response && res.data.response.main_content) {
    let uploadedSuccess = await sendFileToS3(
      res.data.response.main_content,
      file
    );
    if (uploadedSuccess) {
      return [uploadedSuccess, res];
    }
  } else {
    return [false, res];
  }
};

const sendFileToS3 = async (AWS3, file) => {
  let formData = new FormData();
  Object.keys(AWS3.fields).forEach((keyName) => {
    formData.append(keyName, AWS3.fields[keyName]);
  });
  formData.append("file", file);
  const res = await axios({
    method: "post",
    url: AWS3.url,
    data: formData,
    headers: {
      "Content-Type":
        "multipart/form-data;boundary=--WebKitFormBoundaryE19zNvXGzXaLvS5C",
    },
  });

  if (res && res.status === 204) {
    return true;
  }
  return false;
};

export { uploadMediaToS3 };
