import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { useHistory, Redirect, useLocation } from "react-router-dom";
import { useTimer } from "../../Hooks/useTimer";
import swal from "sweetalert";
import beforeLoginAxios from "../../Util/API Calls/beforeLogin";
import ErrorIcon from "@material-ui/icons/Error";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";

function StaffVerifyNumber(props) {
  let { phoneNumber = "" } = props;

  let history = useHistory();
  let location = useLocation();

  const [showPassword, setShowPassword] = useState(false);
  const [otpInput, setOtpInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [resendAllowed, setResendAllowed] = useState(false);
  useEffect(() => {
    swal({
      title:
        "An OTP has been sent to your registered Phone number and Email Address ",
      type: "warning",
    });
  }, []);

  const { time, start, pause, reset, status } = useTimer({
    initialTime: 60,
    endTime: 0,
    timerType: "DECREMENTAL",
    autostart: true,
    onTimeOver: () => {
      setResendAllowed(true);
    },
  });

  // const handlePasswordInput = (input) => {
  //   var phoneno = /(^[09876]{1}[0-9]*)+/g;

  //   if (!input.match(phoneno)) {
  //     phoneno.lastIndex = 0;
  //     return;
  //   }
  //   phoneno.lastIndex = 0;
  //   setPasswordInput(input);
  // };

  function handleForgotPassword() {
    if (!resendAllowed) return;
    reset();
    start();
    setResendAllowed(false);
    beforeLoginAxios
      .post("public/staff-send-otp", {
        // user_type: "doctor",
        channel_id: "staff-login",
        phone_no: `+91${phoneNumber}`,
      })
      .then((response) => {
        if (response) {
          if (response.data && response.data.statusCode === 200) {
            history.replace("/doctor/login/staff/set-password");
          }
        }
      });
  }

  const handleContinueClick = (e) => {
    e.preventDefault();
    setPasswordError(false);
    setOtpError(false);
    if (otpInput.length !== 6) {
      setOtpError("OTP must be exactly 6 characters");
      return;
    }
    if (passwordInput.length <= 5) {
      setPasswordError("Password must be at least 6 characters");
      return;
    }
    beforeLoginAxios
      .post("auth/staff-login-rev1", {
        // channel_id: "doctor-login",
        phone_no: `+91${phoneNumber}`,
        user_type: "staff",
        user_otp: otpInput,
        new_password: passwordInput,
      })
      .then((response) => {
        if (response) {
          if (response.data && response.data.statusCode === 200) {
            localStorage.setItem("id_token", response.data.user.id_token);
            localStorage.setItem(
              LOCALSTORAGEKEY.USER_ID,
              response.data.user.doctor_id
            );
            localStorage.setItem("user_type", response.data.user.user_type);
            localStorage.setItem(
              "user_name",
              response.data.user.staff_full_name
            );
            localStorage.setItem(
              LOCALSTORAGEKEY.STAFF_ID,
              response.data.user.staff_id
            );
            localStorage.setItem(
              "full_name",
              response.data.user.staff_full_name
            );
            localStorage.setItem("doctorPhone", `+91${phoneNumber}`);
            localStorage.setItem(
              "share_instant_consultation_link_allow",
              response.data.user.permission
                .share_instant_consultation_link_allow
            );
            localStorage.setItem(
              "add_edit_new_staff_name_and_number_allow",
              response.data.user.permission
                .add_edit_new_staff_name_and_number_allow
            );
            localStorage.setItem(
              "start_instant_consultation_allow",
              response.data.user.permission.start_instant_consultation_allow
            );
            localStorage.setItem(
              "upload_manual_prescriptions_allow",
              response.data.user.permission.upload_manual_prescriptions_allow
            );
            localStorage.setItem(
              "access_knowledge_center_allow",
              response.data.user.permission.access_knowledge_center_allow
            );
            localStorage.setItem(
              "download_offline_patients_allow",
              response.data.user.permission.download_offline_patients_allow
            );
            localStorage.setItem(
              "edit_prescriptions_allow",
              response.data.user.permission.edit_prescriptions_allow
            );
            localStorage.setItem(
              "join_webinar_allow",
              response.data.user.permission.join_webinar_allow
            );
            localStorage.setItem(
              "update_account_details_allow",
              response.data.user.permission.update_account_details_allow
            );
            localStorage.setItem(
              "update_profile_details_allow",
              response.data.user.permission.update_profile_details_allow
            );
            localStorage.setItem(
              "last_login_date",
              response.data.user.last_login_date
            );

            localStorage.setItem(
              "start_upcoming_consultation_allow",
              response.data.user.permission.start_upcoming_consultation_allow
            );
            localStorage.setItem(
              "register_for_upcoming_webinar_allow",
              response?.data?.user?.permission
                ?.register_for_upcoming_webinar_allow
            );
            // swal({
            //   title: "Login Successful!",
            //   type: "success",
            // }).then(function () {});

            localStorage.setItem(
              LOCALSTORAGEKEY.STAFF_CLINIC_ASSOCIATION,
              response?.data?.staff_clinic_association
            );

            localStorage.setItem(
              "staff_doctor_association",
              response?.data?.staff_doctor_association
            );
            setTimeout(() => {
              if (localStorage.getItem("id_token")) {
                if (response?.data?.staff_clinic_association === true) {
                  history.replace("/clinic/account/dashboard");
                } else {
                  history.replace("/doctor/account/dashboard");
                }
              }
            }, 100);
          } else {
            setOtpError("OTP you entered was incorrect");
          }
        }
      });
  };

  if (phoneNumber == "") {
    // phoneNumber = "1234123412";
    return <Redirect to="/doctor/login/" />;
  }
  return (
    <div
      className="container h-100  d-flex align-items-center justify-content-center flex-column"
      style={{ position: "relative" }}
    >
      <div>
        <ArrowBackIcon
          onClick={() => {
            history.replace("/doctor/login/");
          }}
          style={{ position: "absolute", left: "0%", top: "2%" }}
          className="color-blue cursor-pointer"
        />
        <h3 className="text-center align-top">
          {location && location.state && location.state.title
            ? location.state.title
            : "Verify Number"}
        </h3>
      </div>
      <h6 className="text-center">Please Enter the OTP</h6>
      <div className="w-100 d-flex flex-row justify-content-between mt-3">
        <div className="d-inline-block text-muted">+91-{phoneNumber}</div>
        <Edit className="color-blue cursor-pointer" />
      </div>
      <div className="w-100 mt-2">
        <form
          className="d-flex flex-column align-items-center justify-content-center"
          onSubmit={(e) => {
            handleContinueClick(e);
          }}
        >
          <FormControl
            hiddenLabel="true"
            size="small"
            margin="dense"
            fullWidth="true"
          >
            <FilledInput
              id="OTP-input"
              aria-describedby="my-helper-text"
              required
              aria-required
              placeholder="Enter OTP"
              aria-label="otp-input"
              autoFocus={true}
              inputProps={{
                autoComplete: "off",
                inputMode: "numeric",
              }}
              disableUnderline="true"
              value={otpInput}
              endAdornment={
                otpError ? <ErrorIcon style={{ color: "#e84444" }} /> : null
              }
              onChange={(e) => {
                setOtpInput(e.target.value);
              }}
            />
          </FormControl>
          <div className="row justify-content-between w-100">
            <div className="col-6 p-0">
              {resendAllowed ? (
                <div className="align-self-start text-muted">OTP expired</div>
              ) : (
                <div className="align-self-start text-muted">
                  {time} seconds left to expire
                </div>
              )}
            </div>
            <div className="col-6 p-0 d-flex justify-content-end align-items-start">
              <div
                className={`cursor-pointer ${
                  !resendAllowed ? "text-muted pointer-disabled" : "textLink"
                }`}
                style={{ textAlign: "right" }}
                onClick={(e) => {
                  e.preventDefault();
                  handleForgotPassword();
                }}
              >
                Resend OTP
              </div>
            </div>
          </div>
          <div className="input-error text-center mt-1">
            {otpError ? otpError : ""}
          </div>
          <div className="mt-3 text-center">
            {location && location.state && location.state.resetTitle
              ? location.state.resetTitle
              : "Set password for easier access (minimum 6 characters required)"}
          </div>
          <FormControl
            hiddenLabel={true}
            size="small"
            margin="dense"
            fullWidth={true}
          >
            <FilledInput
              id="pwd-input"
              aria-describedby="my-helper-text"
              aria-label="Set-password"
              placeholder="Enter Password"
              disableUnderline={true}
              required
              aria-required
              type={showPassword ? "text" : "password"}
              endAdornment={
                <ShowPassword
                  setShowPassword={setShowPassword}
                  showPassword={showPassword}
                />
              }
              value={passwordInput}
              onChange={(e) => {
                setPasswordInput(e.target.value);
              }}
            />
          </FormControl>
          <div className="input-error text-center mt-1">
            {passwordError ? passwordError : ""}
          </div>
          <button
            className="btn primary-cta-button rounded-pill mt-2 align-self-center py-2 px-4"
            disabled={resendAllowed}
          >
            Submit
          </button>
        </form>
        {/* <SetPassword
          focusOnOTP={focusOnOTP}
          className={showSetPassword ? "visible" : "hidden"}
          setShowPassword={setShowPassword}
          showPassword={showPassword}
        /> */}
      </div>
    </div>
  );
}

export default StaffVerifyNumber;

const Edit = () => {
  let history = useHistory();
  return (
    <span
      className="color-blue cursor-pointer"
      onClick={() => {
        history.replace("/doctor/login");
      }}
    >
      Edit
      <EditIcon className="color-blue cursor-pointer" fontSize="inherit" />
    </span>
  );
};

function ShowPassword(props) {
  const { setShowPassword, showPassword = false } = props;

  if (showPassword === false) {
    return (
      <span
        className="color-blue cursor-pointer"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        <VisibilityIcon />
      </span>
    );
  } else {
    return (
      <span
        className="color-blue cursor-pointer"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        <VisibilityOffIcon />
      </span>
    );
  }
}
