// Dependies import
import React, { useState, useEffect } from "react";
//assest and scss import
import "./abhaPatientProfileOTPContainer.scss";
//project files import
import AbhaOTPInput from "../../../AbhaViewContainer/common/AbhaOTPInput";
// constant files import
import {
  ABHA_DOWNLOAD_CARD,
  ABHA_VERIFY_OTP,
} from "../APIHandler/apiConstants";
import { getAbhaDownloadCard, getVerifyAbhaOTP } from "../APIHandler";
import Loader from "../../../UI Component/Loader/Loader";

function AbhaPatientProfileOTPContainer(props) {
  const {
    selectedPatient,
    txnId,
    sendAbhaPatientOTP,
    onCloseModal,
    hideOtpResend,
    otpErrorMsg,
  } = props;
  // const import

  const [otp, setOtp] = useState("");
  const [otpError, setOtpErorr] = useState("");
  const [disableVerifyOtpButton, setDisableVerifyOtpButton] = useState(true);
  const [loader, setLoader] = useState(false);

  // Lifecycle methods ==>

  useEffect(() => {
    if (otp?.length < 6) {
      setDisableVerifyOtpButton(true);
    } else {
      setDisableVerifyOtpButton(false);
    }
  }, [otp]);

  useEffect(() => {
    setOtpErorr(otpErrorMsg);
  }, [otpErrorMsg]);

  // <==

  // Actions ==>

  const handleOTPChange = (otpInput) => {
    setOtp(otpInput);
  };

  const handleResendOTP = () => {
    sendAbhaPatientOTP();
  };

  // <==

  // Api Calls ===>

  const verifyAbhaPatientOTP = () => {
    let payload = {
      otp: otp,
      txnid: txnId,
    };
    setLoader(true);
    getVerifyAbhaOTP(ABHA_VERIFY_OTP, payload)
      .then(([resVerify, errVerify]) => {
        if (!errVerify) {
          if (resVerify?.data?.statusCode === 200) {
            setOtpErorr("");
            setLoader(true);
            getAbhaDownloadCard(
              ABHA_DOWNLOAD_CARD({ txnid: resVerify?.data?.txnId })
            ).then(([resDownload, errDownload]) => {
              if (!errDownload) {
                if (resDownload?.data?.statusCode == 200) {
                  setLoader(false);
                  let content = resDownload?.data?.content;
                  const linkSource = `data:image/png;base64,${content}`;
                  const downloadLink = document.createElement("a");
                  const fileName = "abha_card.png";

                  downloadLink.href = linkSource;
                  downloadLink.download = fileName;
                  downloadLink.click();
                  onCloseModal();
                } else {
                  setLoader(false);
                  onCloseModal();
                }
              }
            });
          } else {
            setOtpErorr("Enter valid OTP");
            setLoader(false);
          }
        }
      })
      .catch((err) => {
        console.log(err); // Change 'C2CConsole.log' to 'console.log'
      });
  };

  // <===

  // Display UI ==>

  const abhaPatientProfileOTPUi = () => {
    return (
      <div className="abha-patient-profile-otp-container">
        <div className="download-abha-otp-wrapper">
          <div className="otp-linked-mobile-number-pera">
            We just sent you OTP on your mobile number which is linked to your
            ABHA card.
          </div>
          <div className="aadhaar-number-heading">
            ABHA Mobile Number ******
            {selectedPatient?.phone_no?.substr(
              selectedPatient?.phone_no?.length - 4,
              selectedPatient?.phone_no?.length
            )}
          </div>
          <div className="abha-otp-input-wrapper">
            <AbhaOTPInput
              autoFocus
              length={6}
              otpError={otpError}
              errorMessage={otpError}
              placeholder="*"
              timerUnit={1}
              hideTimerResendBtn={hideOtpResend}
              onChangeOTP={handleOTPChange}
              handleResendOTP={handleResendOTP}
            />
          </div>
        </div>
        <div className="verify-otp-btn-wrapper">
          <div
            className={
              "verify-otp-btn " +
              (disableVerifyOtpButton ? "verify-otp-btn-disabled" : "")
            }
            onClick={() =>
              disableVerifyOtpButton ? "" : verifyAbhaPatientOTP()
            }
          >
            Verify OTP
          </div>
        </div>
      </div>
    );
  };

  // <==

  return (
    <>
      {loader && <Loader />}
      {abhaPatientProfileOTPUi()}
    </>
  );
}

export default AbhaPatientProfileOTPContainer;
