import { getBase64Image } from "./imageHelper";
import c2cLogo from "../../../../assets/c2c_logo.png";

export const getFooterData = async (
  erxPdfData,
  doctor_hospital_details,
  doctor_profile_details,
  showFooterTemplate,
  footer_img
) => {
  let [hospitalDetails, hospitalLogo, is_hospital_logo_enabled] =
    await getHospitalDetails(doctor_hospital_details);

  return {
    dummy_doctor_flag: erxPdfData?.dummy_doctor_flag,
    c2cLogo: c2cLogo,
    signature_url: erxPdfData?.signature_url,
    first_name: doctor_profile_details?.first_name,
    middle_name: doctor_profile_details?.middle_name,
    last_name: doctor_profile_details?.last_name,
    doctor_hospital_details: hospitalDetails,
    hospitalLogo: hospitalLogo,
    is_hospital_logo_enabled: is_hospital_logo_enabled,
    is_uploaded_footer_template_to_be_used: showFooterTemplate,
    footer_img: footer_img,
    consultation_type:
      erxPdfData?.consultation_type === "0" ||
      erxPdfData?.consultation_type === "1"
        ? "tele consultation"
        : "in-clinic consultation",
  };
};

export const getSignatureImage = async (doctor_profile_details) => {
  let signature_image = "";
  if (doctor_profile_details?.signature_url) {
    signature_image = await getBase64Image(
      doctor_profile_details?.signature_url
    );
  }

  return signature_image;
};

export const getHospitalDetails = async (doctor_hospital_details) => {
  let hospitalDetails = doctor_hospital_details;
  let hospitalLogo = null;
  let is_hospital_logo_enabled = false;

  if (hospitalDetails?.length > 0) {
    let detail = hospitalDetails?.filter(
      (d) => d?.is_hospital_logo_enabled && d?.hospital_logo?.presigned_url_view
    );

    detail = detail?.length ? detail[0] : null;
    is_hospital_logo_enabled =
      detail && detail.is_hospital_logo_enabled ? true : false;

    if (
      detail &&
      detail.hospital_logo &&
      detail.hospital_logo.presigned_url_view
    ) {
      hospitalLogo = detail.hospital_logo.presigned_url_view;
    }

    hospitalDetails = hospitalDetails.filter(
      (details) =>
        (details?.is_hospital_name_enabled && details?.hospital_name !== "") ||
        (details?.is_hospital_address_enabled &&
          details?.hospital_address !== "") ||
        (details?.is_hospital_contact_no_enabled &&
          details?.hospital_contact_number !== "") ||
        (details?.is_hospital_timings_enabled &&
          details?.hospital_timings !== "")
    );

    hospitalDetails.map((details, index) => {
      details.index = index + 1;
      if (
        details &&
        details.hospital_contact_number &&
        !details.hospital_contact_number.startsWith("+91") &&
        details.hospital_contact_number.length === 10
      ) {
        details.hospital_contact_number =
          "+91" + details.hospital_contact_number;
      }
    });
  }

  return [hospitalDetails, hospitalLogo, is_hospital_logo_enabled];
};
