import React, { useEffect, useState, useCallback } from "react";
import Participant from "./participant";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import afterLoginApiAxios from "../../Util/API Calls/afterLogin";

const Room = ({ roomName, room, handleLogout }) => {
  const history = useHistory();
  const [participants, setParticipants] = useState([]);
  const [isMute, setMute] = useState(true);
  const [isVideo, setVideo] = useState(true);

  const checkCallExtended = async (appointmenID, token) => {
    let response = await afterLoginApiAxios.post(
      "patient/check-extend-slot",
      {
        appointment_id: appointmenID,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    return response.data;
  };

  const participantConnected = (participant) => {
    setParticipants((prevParticipants) => [...prevParticipants, participant]);
  };

  const participantDisconnected = async (participant) => {
    setParticipants((prevParticipants) =>
      prevParticipants.filter((p) => p !== participant)
    );
    if (localStorage.getItem("authorization_token")) {
      let extensionStatus = await checkCallExtended(
        roomName,
        localStorage.getItem("authorization_token")
      );
      let newEndTime = "";
      if (
        extensionStatus.date !== undefined &&
        extensionStatus.extend_time !== undefined
      ) {
        newEndTime = extensionStatus.date + " " + extensionStatus.extend_time;
      }

      if (localStorage.getItem("extension_end_time") === newEndTime) {
        if (!room.participants.size) {
          localStorage.clear();
          swal("The meeting has ended").then(() => history.push("/"));
        }
      } else {
        localStorage.setItem("extension_end_time", newEndTime);
      }
    }
  };

  const roomDisconnected = (room, error) => {
    if (error.message == "Room completed") {
      localStorage.clear();
      swal("The meeting has ended").then(() => history.push("/"));
    }
  };

  useEffect(() => {
    room.participants.forEach(participantConnected);
    room.on("participantConnected", participantConnected);
    room.on("participantDisconnected", participantDisconnected);
    room.on("disconnected", roomDisconnected);
    room.once("disconnected", (error) =>
      room.participants.forEach(participantDisconnected)
    );
    return () => {
      room.off("participantConnected", participantConnected);
      room.off("participantDisconnected", participantDisconnected);
    };
  }, [room]);

  const remoteParticipants = participants.map((participant) => (
    <Participant key={participant.sid} participant={participant} />
  ));

  const muteToggle = () => {
    setMute(!isMute);
    room.localParticipant.audioTracks.forEach((publication) => {
      if (isMute) publication.track.disable();
      else publication.track.enable();
    });
  };

  const disconnectParticipant = () => {
    room.disconnect();
  };

  const videoToggle = () => {
    setVideo(!isVideo);
    room.localParticipant.videoTracks.forEach((publication) => {
      if (isVideo) publication.track.disable();
      else publication.track.enable();
    });
  };

  return (
    <div className="room">
      {/* <h2>Room: {roomName}</h2>
      <button onClick={handleLogout}>Log out</button> */}
      <div className="local-participant">
        {room ? (
          <Participant
            key={room.localParticipant.sid}
            participant={room.localParticipant}
            mute={isMute}
            video={isVideo}
          />
        ) : (
          ""
        )}
      </div>
      {/* <h3>Remote Participants</h3> */}
      <div className="remote-participants">{remoteParticipants}</div>
      <div className="call-action-btn">
        <button className="callBtn" onClick={muteToggle}>
          <span className="fa-stack fa-lg videoBtn">
            {!isMute ? (
              <i
                className="fa fa-microphone-slash"
                aria-hidden="true"
                style={{ color: "red" }}
              />
            ) : (
              <i className="fa fa-microphone" aria-hidden="true" />
            )}
          </span>
        </button>
        {/* <button className="callBtn endCall" onClick={disconnectParticipant}>
            <span className="fa-stack fa-lg videoBtn">
              <i className="fa fa-phone fa-rotate-45"></i>
            </span>
          </button> */}
        <button className="callBtn" onClick={videoToggle}>
          <span className="fa-stack fa-lg videoBtn">
            {!isVideo ? (
              <i className="fa fa-ban fa-stack-1x text-danger"></i>
            ) : (
              <i className="fa fa-video-camera fa-stack-1x"></i>
            )}
          </span>
        </button>
      </div>
    </div>
  );
};

export default Room;
