import beforeLoginAxios from "../API Calls/beforeLogin";

// Helper Methods ===>

const commonApiHandler = async (method, api, payload, params) => {
  try {
    const res = await beforeLoginAxios({
      method: method,
      url: api,
      params: params ?? "",
      data: payload ?? {},
    });

    if (res) {
      return [res, false];
    }
    return [null, true];
  } catch (error) {
    return [null, error];
  }
};

// <===

// api handler for get method
export const c2cBeforeLoginGetApiHandler = (api, params, payload) => {
  return commonApiHandler("get", api, payload, params);
};

// api handler for post method
export const c2cBeforeLoginPostApiHandler = (api, payload, params) => {
  return commonApiHandler("post", api, payload, params);
};
