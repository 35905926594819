import React from 'react'
import C2CLogo from "../../assets/PaitentFeedback-C2C-Logo.png"

const PatientFeedbackHeader = () => {
  return (
    <div className='headsticky'>
        <img src={C2CLogo} alt="C2C" className='headlogo' width="190px" />
    </div>
  )
}

export default PatientFeedbackHeader