import React from "react";
import "./Notification.css";
import { Button } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";

class NotificationModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { show, content, cancelHandler, successHandler } = this.props;
    const {
      message,
      classNameButton1,
      classNameButton2,
      title
    } = content;
    return (
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes"
        cancelBtnText="No"
        title={title}
        customButtons={
          <React.Fragment>
            <div className="col-12 col-md-12 button-spacing">
              <Button
                className={`time-extension-button ${classNameButton1}`}
                onClick={() => successHandler()}
              >
                Yes
                </Button>
              <Button
                className={`time-extension-button mt-2 ${classNameButton2}`}
                onClick={() => cancelHandler()}
              >
                No
              </Button>
            </div>
          </React.Fragment>
        }
      >
        {message}
      </SweetAlert>
    );
  }
}
export default NotificationModal;