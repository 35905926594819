import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";

//redux
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import history from "./Util/history";
import configureStore from "./configureStore";
import { QueryClientProvider, QueryClient } from "react-query";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "./components/common/ErrorFallback";
const initialState = {};
const store = configureStore(initialState, history);

const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ConnectedRouter history={history}>
        <React.StrictMode>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <App />
          </ErrorBoundary>
        </React.StrictMode>
      </ConnectedRouter>
    </QueryClientProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
