export const ERX_PDF_PAYLOAD = {
  statusCode: 200,
  message: "pdf generated successfully",
  data: {
    is_default_locale: true,
    prescription_id: "", //needed -> in creating preview erx payload
    doctor_diagnosis: [
      {
        id: 1,
        title: "Cough",
        value: {
          id: 1,
          label: "fever",
          value: "fever",
        },
        description:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout",
      },
      {
        id: 2,
        title: "Fever",
        value: {
          id: 2,
          label: "fever2",
          value: "fever2",
        },
        description:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout",
      },
    ], //needed -> to show doctor daignosis
    examinations: [
      {
        id: 1,
        title: "X-Ray Report(Right Leg)",
        value: {
          id: 2,
          title: "report",
          value: "report",
        },
        description:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout",
      },
      {
        id: 2,
        title: "X-Ray Report(Right Leg)",
        value: {
          id: 3,
          title: "report2",
          value: "report2",
        },
        description:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout",
      },
    ], //needed -> to shown the examination
    chief_complaints: [
      {
        id: 1,
        label: "Abdominal Pain",
        title: "Abdominal Pain",
        value: "Abdominal Pain",
        master_id: "bdec4f88-84d1-4375-859e-e9dadc393d5d",
        button_status: "",
        capture_painscale: false,
      },
      {
        id: 2,
        label: "Bragard test finding",
        title: "Bragard test finding",
        value: "Bragard test finding",
        master_id: "53a994c8-5a56-432e-ac0c-9774ecbb02ee",
        button_status: "",
        capture_painscale: false,
      },
    ], //needed -> to shown the chief complaints
    past_medical_history: [
      {
        id: 1,
        label: "Abdominal fullness",
        title: "Abdominal fullness",
        value: "Abdominal fullness",
        master_id: "cc4f5f50-9a97-4f6d-9d42-440cb66adfe4",
        button_status: "",
        capture_painscale: false,
      },
      {
        id: 2,
        label: "Able to recongnize faces",
        title: "Able to recongnize faces",
        value: "Able to recongnize faces",
        master_id: "bf749276-902d-413c-bc92-e08eb746d9b0",
        button_status: "",
        capture_painscale: false,
      },
    ], //needed -> to shown the past medical history
    family_history: [
      {
        id: 1,
        label: "Diabetes",
        title: "Diabetes",
        value: "Diabetes",
        master_id: "79b163f6-4f82-42b8-a1b8-5e7e794ac6e6",
        button_status: "",
        capture_painscale: false,
      },
    ], //needed -> to shown the family history
    menstrual_history: [], //needed -> to shown the menstrual history
    lifestyle_history: [
      {
        id: 1,
        label: "Drinking - Occasional",
        title: "Drinking - Occasional",
        value: "Drinking - Occasional",
        master_id: "071cbb40-c704-4082-88ff-2b9c57b02d54",
        button_status: "",
        capture_painscale: false,
      },
    ], //needed -> to shown the lifestyle history
    obstetric_history: {
      gravidity: "0",
      parity: "0",
      living: "0",
      abortion: "0",
      ectopic: "0",
      death: "0",
      obstetric_note: "notes",
    }, // needed -> to show obstetric history
    obstetric_details: {
      note: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium. !@#$%^&*()_+}{|":<>?123456789/*-+ab.ddgcashbhjvjh',
      edd_date: "30 Sep, 2024",
      ega_days: 4,
      lmp_date: "25 Dec, 2023",
      ega_weeks: 8,
      cycle_length: "28",
    }, // needed -> to show obstetric details
    suggested_investigations_extended: [
      {
        id: 1,
        title: "suggested",
        value: "suggested",
      },
    ], //needed -> to show suggested investigation
    suggested_investigations_description: [
      {
        id: 1,
        title: "notes and instruction",
        value: "notes and instruction",
        button_status: "save",
      },
    ], //needed -> to instruction and notes
    custom_sections: [
      {
        fields: [
          {
            value: [
              {
                id: 1,
                title: "abc",
                value: "abc",
              },
            ],
            field_id: 1,
            field_title: "Edit Field 01",
          },
        ],
        custom_id: 1,
        custom_name: "customSection1",
        custom_title: "Custom Section",
      },
      {
        fields: [
          {
            value: [
              {
                id: 1,
                title: "def",
                value: "def",
              },
            ],
            field_id: 1,
            field_title: "Edit Field 01",
          },
        ],
        custom_id: 1,
        custom_name: "customSection2",
        custom_title: "Custom Section",
      },
    ], //needed -> custom section data
    appointment_id: "", //needed -> while creating the erx preview payload
    appointment_date: "2024-02-26T00:00:00.000Z", //needed -> to show appointment date
    follow_up_date: "2024-03-01T00:00:00.000Z", //needed -> to show followup date
    patient_name: "Neerrrrrrr", //needed -> to show patient name
    patient_dob: "1996-01-01T00:00:00.000Z", //needed -> in graph calculation to get the age from dob
    consultation_type: 5, //needed -> used in calculation in getVitalsData inside helper
    clinic_id: "", //needed -> used to check docotr belonging to clinic or individual and used in few conditions
    patient_gender: "Female", //needed -> to show gender
    patient_age: "28years 1months 25days", //needed -> to show age
    parent_guardian_name: "", //needed -> to show the gaurdian name
    patient_phone_no: "+919916152849", //needed to show patient phone number
    formatted_uhid: "", //needed -> to show uhid
    vaccine_notes: "", //needed -> to show vaccine notes
    doctor_specialization: "Gynecology & Fertility Specialist", //needed -> to show obstetric details based on specialization
    relation: "", //needed -> to show relation
    vaccine_chart: [
      {
        prescription_vaccination_id: "6183dedd-3a14-4818-b81c-baef24e1d6b7",
        vaccine_id: "53d91ae0-0c6f-4a42-86b7-e1b56ba439d3",
        name: "Hepatitis B (Hep B)",
        vaccine_schedule_id: "ad7e33d6-d0dc-4579-9c78-87996d512ab2",
        vaccine_category_id: "3e0b7a70-dbc7-451f-ad24-03946c1ae421",
        schedule_id: "ad7e33d6-d0dc-4579-9c78-87996d512ab2",
        title: "General Vaccines",
        created_at: "2024-02-22 13:56:27.547004+00",
        due_in_numeric: null,
        due_in_units: null,
        prescription_id: "76586f63-7f99-49ac-b8cb-8d2657f6612f",
        scheduled_date: "30 Jan 2024",
        vaccinated_date: "31 Jan 2024",
        height: 7,
        weight: 65,
        head_circumference: 10,
        height_unit: "cm",
        weight_unit: "Kg",
        head_circumference_unit: "cm",
        custom_vaccine: false,
        vaccine_category_name: "Hep B",
        patient_account_id: "7ef45ca3-dda9-4e95-ab07-a4fc4de14246",
        vaccine_brand_name: "ComBE Five",
        master_vaccine_brand_id: "6d9d155a-ca6a-47f5-a445-d645e08b9a9d",
        isEditable: true,
      },
      {
        prescription_vaccination_id: "6c62d782-c1aa-4c30-b80c-4ab3d76585ec",
        vaccine_id: "53d91ae0-0c6f-4a42-86b7-e1b56ba439d5",
        name: "Yellow Fever",
        vaccine_schedule_id: "ad7e33d6-d0dc-4579-9c78-87996d512ab2",
        vaccine_category_id: "8db4e07a-23ea-4160-afde-83e3afd3be9c",
        schedule_id: "ad7e33d6-d0dc-4579-9c78-87996d512ab2",
        title: "General Vaccines",
        created_at: "2024-02-22 13:56:40.665749+00",
        due_in_numeric: null,
        due_in_units: null,
        prescription_id: "76586f63-7f99-49ac-b8cb-8d2657f6612f",
        scheduled_date: "31 Jan 2024",
        vaccinated_date: "30 Jan 2024",
        height: 7,
        weight: 65,
        head_circumference: 10,
        height_unit: "cm",
        weight_unit: "Kg",
        head_circumference_unit: "cm",
        custom_vaccine: false,
        vaccine_category_name: "Yellow Fever",
        patient_account_id: "7ef45ca3-dda9-4e95-ab07-a4fc4de14246",
        vaccine_brand_name: "Stamaril",
        master_vaccine_brand_id: "e2bed57f-e1d7-40dd-aa71-bf03dc89e99a",
        isEditable: true,
      },
    ], //needed -> to show vaccine chart
    drug_info: [
      {
        drug_id: "a21f341a-5404-46b6-ae89-ede37d814fa5",
        doctor_drug_id: "255ca31b-c6cd-4326-a654-2cff98d549c8",
        appointment_prescription_id: "81d87ae4-6044-47c5-bc68-eaae37085352",
        drug_name: "paracetanal 80mg suppository",
        frequency: "freq_1",
        frequency_extended: {
          slug: "freq_1",
          title: "1-1-1",
          details: "Before Food",
        },
        duration: "1 Day",
        quantity: "1\u20443",
        unit: "Capsule",
        packaging: "packet of 5 suppositories",
        salt_composition: "Paracetamol/Acetaminophen 80mg",
        duration_slug: "dur_1",
        instruction_slug: "",
        instruction: "A",
      },
    ], // needed -> to drugs data
    signature_url:
      "https://connect2clinic-doctor-files-qa-aac.s3.amazonaws.com/doctor_65766fc2-0cf5-4487-8ae5-0f7a0feaa415/signature/signature%20pic.JPG?response-content-type=image%2Fjpeg&response-content-disposition=attachment&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA3JUEYN6PS6TFCXOK%2F20240223%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240223T124735Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEI3%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEA9qDN8OSUZf5rF3KafXGiLzYejMvdp5LDREccnJZ9aawCIGWzKQj6F4HsV%2BA7lvV%2FnlUuAoszZLdEBdsBg51PHf%2FzKqYDCHYQAhoMNzc2NTkzNzY0MjU1IgzDyLj0dXuB50kQ5rgqgwOLDkeWaTMV7zz3AZBrAKgknZMf5DSjYiHwriVicPon0vNf%2Fr0tBD5HqYV5iqFVlGfcFy6svk%2BcfDPwEplex6N6B%2F%2B5q64b%2F%2Ba5uHcwNGMMU5ZzTxh%2F00x24r%2BDk4Rcga7ZhMAPF6pu2c4hQ9X05kAr5kboXzmWS%2FilVpmIZfp7NgR3rMXOBAKp1LVsrD8vckwj7Ipd9i27UYbbO3%2Be7b%2BXXn67vPLhCXSS28mNxwP9F1v0bT26gPAWcE83hAST0P5cTcml9qwFeL7xyYvm6vS2ivpqbmskAWatdfQIdmKLBE3AQDRVO32t0ews%2Fd5KQihnFsKIjEDBQMnrRs%2F5aEyQ6gZ912YFLTKHyYSK1qWIOUtUG2Dz9VrqNP1zlRL5xZ1yxZknc9koS55ewx2FJMsApwXT3R%2BHP471hAnJuVDtHfskzoRRaecGUU2CtHnLStaCT99xxvUDhBsycbe3S4a4rRTk0yY6pK%2FJ04b0%2FxjeOl1nj%2BXn2wEZPeallDk7w9UJVlEw5afirgY6nQFjLUvHl1%2FuOd0cmsxlkB%2FNQw8i2qy33tznWJnH9vW93lN2eic1WtUmp9uLya5yh9TPkTZLSgrxrGDBFg7iuTXZytV%2BZ1f5T889lQdjc2GuMtfMHGH8sAsjDBrprpbIxz3LEFB4uH7QWEuh%2FmbWvgE8Y4jGRd88466V68RF8ZiNrgVQZz0sr6GTadZ2KgMAr3OPPWFiwtAiDwYKAnAu&X-Amz-Signature=ed275d66598ad0be9850f9d53bd577fb310c67b88f1cfd200b7313d4dc9c7238",
    growth_charts_settings: {
      // needed  -> to show growth chart based on the settings
      expand: true,
      selection: true,
      "hc-for-age": true,
      "bmi-for-age": true,
      "muac-for-age": true,
      "length-for-age": true,
      "weight-for-age": true,
      "weight-for-length": true,
    },

    growth_charts_s3_path: {}, // needed -> used in pdf
    alignment_options: {
      //needed -> to show the alignment and sort position
      drugs: {
        sort: 8,
        alignment: "right",
      },
      vitals: {
        sort: 2,
        alignment: "right",
      },
      vaccine: {
        sort: 4,
        alignment: "right",
      },
      followUp: {
        sort: 1,
        alignment: "left",
      },
      diagnosis: {
        sort: 9,
        alignment: "right",
      },
      growthChart: {
        sort: 11,
        alignment: "right",
      },
      customSection: {
        sort: 13,
        alignment: "right",
      },
      medicalHistory: {
        sort: 5,
        alignment: "right",
      },
      chiefComplaints: {
        sort: 3,
        alignment: "right",
      },
      reportsExamination: {
        sort: 10,
        alignment: "right",
      },
      instructionsAndNotes: {
        sort: 7,
        alignment: "right",
      },
      suggestedInvestigations: {
        sort: 6,
        alignment: "left",
      },
      customSection1: {
        alignment: "right",
        sort: 15,
      },
    },
    vitals: [
      {
        id: "c52ea112-f67d-4c7c-a084-04a40f9d84af",
        key: "Weight",
        value: "10",
        unit: "kg",
      },
      {
        id: "c52ea112-f67d-4c7c-a084-04a40f9d84af",
        key: "Height",
        value: "10",
        unit: "cms",
      },
      {
        id: "c52ea112-f67d-4c7c-a084-04a40f9d84af",
        key: "BMI",
        value: "1000.00",
        unit: "kg/m\u00b2",
      },
      {
        id: "c52ea112-f67d-4c7c-a084-04a40f9d84af",
        key: "Bp Systolic",
        value: "10",
        unit: "mmHg",
      },
      {
        id: "c52ea112-f67d-4c7c-a084-04a40f9d84af",
        key: "Bp Diastolic",
        value: "10",
        unit: "mmHg",
      },
      {
        id: "c52ea112-f67d-4c7c-a084-04a40f9d84af",
        key: "Temperature",
        value: "32",
        unit: "F",
      },
      {
        id: "c52ea112-f67d-4c7c-a084-04a40f9d84af",
        key: "SPO2",
        value: "100",
        unit: "%",
      },
      {
        id: "c52ea112-f67d-4c7c-a084-04a40f9d84af",
        key: "Random Glucose",
        value: "100",
        unit: "mg/dL",
      },
      {
        id: "c52ea112-f67d-4c7c-a084-04a40f9d84af",
        key: "Pulse Rate",
        value: "100",
        unit: "BPM",
      },
      {
        id: "c52ea112-f67d-4c7c-a084-04a40f9d84af",
        key: "Respiratory Rate",
        value: "100",
        unit: "BPM",
      },
      {
        id: "c52ea112-f67d-4c7c-a084-04a40f9d84af",
        key: "LMP",
        value: "8",
        unit: "days",
      },
    ], //needed -> to vitals
    patient_history_and_complaints_settings: {
      //needed -> to show patient histoey sub sections
      expand: true,
      selection: false,
      family_history: true,
      lifestyle_history: true,
      menstrual_history: true,
      obstetric_history: true,
      past_medical_history: true,
    },
    doctor_hospital_details: [
      //needed -> to show hospital details in footer
      {
        hospital_name: "Apple Multispecialty Clinic (Dare to Smile)",
        hospital_logo_path:
          "doctor_65766fc2-0cf5-4487-8ae5-0f7a0feaa415/1685706966SampleJPGImage_10mbmb (1).jpg",
        is_logo_deleted: false,
        hospital_address:
          "Address 01, Camp. SGS Mall, Moledina Road Pune - 411004",
        hospital_contact_number: "9797979797",
        hospital_timings:
          "08:00 - 12:00  PM (Mon, Tue, Wed) | 04:00 - 08:00 (Thu, Fri, Sat) | 12:00 - 03:00 (Sun)",
        is_hospital_name_enabled: true,
        is_hospital_logo_enabled: true,
        is_hospital_address_enabled: true,
        is_hospital_contact_no_enabled: true,
        is_hospital_timings_enabled: true,
        hospital_id: "448bd7dd-fce5-413e-9bf1-a835ec010b1c",
        updated_at: "2024-02-21T12:47:06.776Z",
        hospital_logo: {
          presigned_url: "",
          presigned_url_view: "test",
          file_path:
            "doctor_65766fc2-0cf5-4487-8ae5-0f7a0feaa415/1685706966SampleJPGImage_10mbmb (1).jpg",
          bucket_name: "connect2clinic-doctor-profile-files-qa-aac",
          file_size_in_mb: "0.16",
          name: "SampleJPGImage_10mbmb (1).jpg",
          file_type: "jpg",
          uploaded_at: "2024-02-21T12:47:06.776Z",
        },
      },
      {
        hospital_name: "Varsha clinic",
        hospital_logo_path: "",
        is_logo_deleted: false,
        hospital_address: "Kandivali east",
        hospital_contact_number: "7023510480",
        hospital_timings: "4 p.m. to 5 p.m.",
        is_hospital_name_enabled: true,
        is_hospital_logo_enabled: true,
        is_hospital_address_enabled: true,
        is_hospital_contact_no_enabled: true,
        is_hospital_timings_enabled: true,
        hospital_id: "938e6d2f-7a04-45fa-b002-703ed2549f13",
        updated_at: "2024-02-21T12:47:06.785Z",
        hospital_logo: {},
      },
    ],
    doctor_profile_details: {
      //needed -> to show doctor details and header and the erx content
      prefix_name: "Dr",
      first_name: "Demo",
      middle_name: "",
      last_name: "Doctor",
      professional_statement: "Manipal Hospital",
      description:
        "The introduction has been updated! Kindly re-update with the required one!",
      medical_reg_no: "34567",
      doctor_registered_email: "kevinjose2009@gmail.com",
      joining_date: "2021-01-07T00:00:00.000Z",
      profile_picture_url: "",
      short_url_slug: "demo-doctor1",
      doctor_registered_mobile_number: "+919999999999",
      degree: "MD",
      doctor_address: "#1234 mig 1 group 1 2nd cross Maharas",
      bank_account_name: "Punjab national bank",
      bank_account_number: "778965432567",
      bank_ifsc_code: "PUNB98977",
      govt_issued_photo_url:
        "doctor_65766fc2-0cf5-4487-8ae5-0f7a0feaa415/govt_issued_photo/human.jpeg",
      medical_registration_certificate_url:
        "doctor_65766fc2-0cf5-4487-8ae5-0f7a0feaa415/registration_certificate/med reg (1).png",
      is_primary: true,
      show_in_prescription: true,
      display_name: "Gynecology & Fertility Specialist",
      details_text: null,
      slug: null,
      doctor_consultation_timings: "10:00 AM - 10.00 P",
      header_template_path:
        "doctor_65766fc2-0cf5-4487-8ae5-0f7a0feaa415/1702630871hospital-typographic-header-healthcare-modern-medicine-treatment-expertize-diagnostic-medical-specialist-uniform-204192913.jpg",
      footer_template_path:
        "doctor_65766fc2-0cf5-4487-8ae5-0f7a0feaa415/1702630882invoice2.jpg",
      is_header_template_deleted: false,
      is_footer_template_deleted: false,
      is_doctor_name_enabled: true,
      is_doctor_degree_enabled: true,
      is_doctor_registration_number_enabled: true,
      is_doctor_address_enabled: true,
      is_doctor_registered_mob_no_enabled: true,
      is_doctor_alternate_no_enabled: true,
      is_doctor_registered_email_enabled: true,
      is_doctor_consultation_timings_enabled: true,
      is_dr_appointment_booking_link_enabled: true,
      is_dr_appointment_booking_qr_enabled: true,
      is_uploaded_header_template_to_be_used: false,
      is_uploaded_footer_template_to_be_used: true,
      is_default_c2c_footer_to_be_shown_in_receipt: true,
      custom_margins_enabled: false,
      updated_at: "2024-02-21T12:47:06.322Z",
      doctor_alternate_number: "999999999999",
      custom_margin_dimensions: {
        unit: "cm",
        rxTop: "4",
        rxLeft: "",
        rcptTop: "",
        rxRight: "",
        rcptLeft: "",
        rxBottom: "4",
        rcptRight: "",
        rcptBottom: "",
      },
      header_template: {
        presigned_url: "",
        presigned_url_view: "test",
        file_path:
          "doctor_65766fc2-0cf5-4487-8ae5-0f7a0feaa415/1702630871hospital-typographic-header-healthcare-modern-medicine-treatment-expertize-diagnostic-medical-specialist-uniform-204192913.jpg",
        bucket_name: "connect2clinic-doctor-profile-files-qa-aac",
        file_size_in_mb: "0.08",
        uploaded_at: "2024-02-21T12:47:06.322Z",
        file_type: "jpg",
        name: "hospital-typographic-header-healthcare-modern-medicine-treatment-expertize-diagnostic-medical-specialist-uniform-204192913.jpg",
      },
      footer_template: {
        presigned_url: "",
        presigned_url_view: "test",
        file_path:
          "doctor_65766fc2-0cf5-4487-8ae5-0f7a0feaa415/1702630882invoice2.jpg",
        bucket_name: "connect2clinic-doctor-profile-files-qa-aac",
        file_size_in_mb: "0.12",
        uploaded_at: "2024-02-21T12:47:06.322Z",
        file_type: "jpg",
        name: "invoice2.jpg",
      },
      doctor_appointment_booking_link:
        "https://qa.c2cassets.com/doctor/demo-doctor1",
      qr_code_url:
        "https://demo-dev2-bucket.s3.ap-south-1.amazonaws.com/qr_code/doctor_170852281065766fc2-0cf5-4487-8ae5-0f7a0feaa415.png",
    },
    includeInErx: {
      //needed -> key is needed to show whether to include in erx or not
      Vitals: true,
      PatientHistory: true,
      Diagnosis: true,
      PatientExamination: true,
      GrowthChart: true,
      ObstetricCalculator: true,
    },
    langCode: "ta", // needed -> to show data in particular language
    pdf_path: {
      s3_bucket_name: "", //needed -> s3 bucket name
      rx_path: "", // needed -> rx path
    },
    dummy_doctor_flag: true, //needed -> used to show the sample text
  },
};
export const PDF_DRUG_HEADERS = [
  {
    id: 1,
    title: "Drug Name",
    langTitle: "Drug Name",
    key: "drug_name",
    subtitle_key: "salt_composition",
    width: 25,
  },
  {
    id: 2,
    title: "Frequency",
    langTitle: "Frequency",
    key: [
      "quantity",
      "unit",
      "frequency_extended.title",
      "frequency_extended.details",
    ],
    width: 30,
  },
  { id: 3, title: "Period", langTitle: "Period", key: "duration", width: 15 },
  {
    id: 4,
    title: "Instructions",
    langTitle: "Instructions",
    key: "instruction",
    width: 25,
  },
];
export const PDF_VACCINE_HEADERS = [
  {
    id: 1,
    title: "Vaccine Name",
    key: "name",
    width: 20,
  },
  {
    id: 2,
    title: "Vaccine Brand",
    key: "vaccine_brand_name",
    width: 25,
  },
  { id: 3, title: "Vaccine Date", key: "vaccinated_date", width: 25 },
  { id: 4, title: "Height/Weight/HC", key: "height_weight_hc", width: 25 },
];
export const PDF_DIAGNOSIS = "DIAGNOSIS";
export const PDF_REPORT_AND_EXAM = "PATIENT/REPORTS EXAMINATION";
