import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import ErrorIcon from "@material-ui/icons/Error";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Redirect, useHistory } from "react-router-dom";
import afterLoginApiAxios from "../../Util/API Calls/afterLogin";
import beforeLoginAxios from "../../Util/API Calls/beforeLogin";
import { moveToNextPage } from "../common/DeepLink";
import {
  setCookieForUser,
  COOKIE_NAME,
  setDynamicCookieForUser,
} from "../../Util/API Calls/cookieHelper";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";
import { C2CConsole } from "../../Util/Helper/C2CConsole";

function LoginEnterPassword(props) {
  let { phoneNumber = "" } = props;
  let history = useHistory();
  const [stayLogin, setStayLogin] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"]);

  useEffect(() => {
    localStorage.setItem("is_partial_doctor", "False");
  }, []);

  function handleForgotPassword() {
    beforeLoginAxios
      .post("public/send-otp", {
        user_type: "doctor",
        channel_id: "doctor-login",
        phone_no: `+91${phoneNumber}`,
      })
      .then((response) => {
        if (response) {
          if (response.data && response.data.statusCode === 200) {
            history.replace("/doctor/login/set-password", {
              title: "Reset Password",
              resetTitle:
                "Set password for easier access (minimum 6 characters required)",
            });
          }
        }
      })
      .catch(function (error) {
        //C2CConsole.log(error);
      });
  }

  function handlePassword(val) {
    setPassword(val);
  }

  const handleContinueClick = (e) => {
    e.preventDefault();
    //validations
    // history.replace("/doctor/login/enter-password");
    //api call
    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
      return;
    } else {
      setPasswordError(false);
    }
    beforeLoginAxios
      .post("auth/login-rev2", {
        channel_id: "doctor-login",
        phone_no: `+91${phoneNumber}`,
        user_type: "doctor",
        password: password,
        stay_login: stayLogin,
        source: "web",
      })
      .then((response) => {
        if (response) {
          if (response.data.hasOwnProperty("statusCode")) {
            if (response.data.statusCode == 200) {
              setCookieForUser(response.data.user.id_token, setCookie);
              setDynamicCookieForUser(
                LOCALSTORAGEKEY.USER_ID,
                response.data.user.user_id,
                setCookie
              );
              setDynamicCookieForUser(
                "user_type",
                response.data.user.user_type,
                setCookie
              );
              localStorage.setItem("id_token", response.data.user.id_token);
              localStorage.setItem(
                LOCALSTORAGEKEY.USER_ID,
                response.data.user.user_id
              );
              localStorage.setItem("user_type", response.data.user.user_type);
              localStorage.setItem("user_name", response.data.user.user_name);
              localStorage.setItem("full_name", response.data.user.full_name);
              localStorage.setItem(
                "doctor_role_name",
                response.data.user.doctor_role_name
              );
              localStorage.setItem("doctorPhone", `+91${phoneNumber}`);
              localStorage.setItem("stay-login", stayLogin);
              localStorage.setItem(
                LOCALSTORAGEKEY.DOCTOR_CLINIC_ASSOCIATION,
                response?.data?.doctor_clinic_association
              );
              localStorage.setItem(
                "last_login_date",
                response.data.user.last_login_date
              );
              localStorage.setItem(
                "specialization",
                response?.data?.specialization_id
              );
              registerAnalytics();

              setTimeout(() => {
                if (localStorage.getItem("id_token")) {
                  if (localStorage.getItem("cid")) {
                    history.push(
                      `/doctor/instant_consultation?consultation_id=${localStorage.getItem(
                        "cid"
                      )}`
                    );
                  } else {
                    if (response?.data?.doctor_clinic_association === true) {
                      if (
                        cookies?.lastPathBeforeLogin &&
                        cookies?.lastPathBeforeLogin ==
                        "/doctor/account/webinars"
                      ) {
                        history.push("/clinic/account/webinars");
                      } else {
                        moveToNextPage("clinic", history, cookies);
                      }
                    } else {
                      moveToNextPage("doctor", history, cookies);
                    }
                  }
                }
              }, 100);
            } else if (response.data.statusCode == 510) {
              setPasswordError("Incorrect password entered. Please try again");
            }
          }
        }
      })
      .catch(function (error) {
        //C2CConsole.log(error);
      });
    // }
  };

  const [focusOnOTP, setFocusOnOTP] = useState(true);
  const [showSetPassword, setShowSetPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);

  if (phoneNumber == "") {
    // phoneNumber = "1234123412";
    return <Redirect to="/doctor/login/" />;
  }

  const getBrowserName = () => {
    // Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== "undefined";

    // Safari 3.0+ "[object HTMLElementConstructor]"
    var isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
        (typeof safari !== "undefined" && window["safari"].pushNotification)
      );

    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;

    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1 - 79
    var isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    // Edge (based on chromium) detection
    var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1;

    // Blink engine detection
    var isBlink = isChrome && !!window.CSS;

    if (isFirefox) return "FireFox";
    if (isSafari) return "Safari";
    if (isIE && !isEdge) return "IE";
    if (isEdge) return "Edge";
    if (isChrome) return "Chrome";
    if (isEdgeChromium) return "EdgeChromium";
    if (isBlink) return "Blink-based";
  };

  const registerAnalytics = () => {
    afterLoginApiAxios
      .post(
        "user/engagement-analytics-data",
        {
          usecase: "login",
          user_type: "1",
          device_details: {
            device_type: "desktop",
            device_manufacturer: "",
            os_version: window.navigator.userAgent.substring(
              window.navigator.userAgent.indexOf("(") + 1,
              window.navigator.userAgent.indexOf(")")
            ),
            os_name: window.navigator.userAgent.substring(
              window.navigator.userAgent.indexOf("(") + 1,
              window.navigator.userAgent.indexOf(")")
            ),
            application_type: "web",
            application_version: "1.0",
            browser_name: getBrowserName(),
            browser_version: window.navigator.userAgent.substring(
              window.navigator.userAgent.lastIndexOf(")") + 1
            ),
          },
          doctor_login_data: {
            doctor_id: localStorage.getItem(LOCALSTORAGEKEY.USER_ID),
            doctor_status: "registered",
          },
        },
        {
          headers: {
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then(() => { })
      .catch((err) => C2CConsole.error(err));
  };
  return (
    <div
      className="container h-100 d-flex align-items-center justify-content-center flex-column px-sm-2 px-md-0"
      style={{ position: "relative" }}
    >
      <div className="w-100">
        <ArrowBackIcon
          onClick={() => {
            history.replace("/doctor/login/");
          }}
          style={{ position: "absolute", left: "0%", top: "2%" }}
          className="color-blue cursor-pointer"
        />
        <h3 className="text-center align-top">Login</h3>
      </div>
      <h6 className="text-center">Enter your password to login</h6>
      <div className="w-100 d-flex flex-row justify-content-between mt-4">
        <div className="d-inline-block text-muted">+91-{phoneNumber}</div>
        <Edit className="color-blue cursor-pointer" />
      </div>
      <div className="w-100 mt-3">
        <form
          className="d-flex flex-column align-items-center justify-content-center"
          onSubmit={(e) => {
            handleContinueClick(e);
          }}
        >
          <FormControl
            hiddenLabel="true"
            size="small"
            margin="dense"
            fullWidth="true"
          >
            <FilledInput
              id="password-input"
              placeholder="Enter Password"
              aria-describedby="my-helper-text"
              aria-label="Set-password"
              disableUnderline="true"
              autoFocus={!focusOnOTP}
              type={showPassword ? "text" : "password"}
              endAdornment={
                passwordError ? (
                  <ErrorIcon style={{ color: "#e84444" }} />
                ) : (
                  <ShowPassword
                    setShowPassword={setShowPassword}
                    showPassword={showPassword}
                  />
                )
              }
              onChange={(e) => {
                handlePassword(e.target.value);
              }}
              value={password}
            />
          </FormControl>
          <div className="d-flex stay-login pt-2">
            <label className="container custom-checkbox mb-0 d-flex">
              <input
                type="checkbox"
                name="EnableOnlinePayment"
                onClick={() => setStayLogin(true)}
              />
              <span className="checkmark"></span>
              <span className="staysignin">Stay Signed In</span>
            </label>

            <span
              className="forgotpassword align-self-end cursor-pointer textLink border-bottom-0"
              onClick={() => {
                handleForgotPassword();
              }}
            >
              Forgot Password?
            </span>
          </div>
          <div className="input-error align-self-center text-center mt-3">
            {passwordError ? passwordError : ""}
          </div>
          <button className="btn primary-cta-button rounded-pill mt-3 align-self-center py-2 px-4">
            Login
          </button>
        </form>
      </div>
      <div
        className="separator w-50 mt-3"
        style={{ margin: "auto", left: "50px" }}
      >
        Or
      </div>
      <div
        className="mt-3 textLink font-weight-bold"
        style={{ minWidth: "fit-content" }}
      >
        <div
          onClick={() => {
            history.replace("/doctor/login/otp-login");
          }}
        >
          Login with OTP
        </div>
      </div>
    </div>
  );
}

export default LoginEnterPassword;

const Edit = () => {
  let history = useHistory();
  return (
    <span
      className="color-blue cursor-pointer"
      onClick={() => {
        history.replace("/doctor/login");
      }}
    >
      Edit
      <EditIcon className="color-blue cursor-pointer" fontSize="inherit" />
    </span>
  );
};

export function ShowPassword(props) {
  const { setShowPassword, showPassword = false } = props;

  if (showPassword === false) {
    return (
      <span
        className="color-blue cursor-pointer"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        <VisibilityIcon />
      </span>
    );
  } else {
    return (
      <span
        className="color-blue cursor-pointer"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        <VisibilityOffIcon />
      </span>
    );
  }
}
