import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

import afterLoginApiAxios from "../../Util/API Calls/afterLogin";
import { cookiesDomainUrl } from "../../Util/API Calls/APIList";
import { API_STATUS_CONSTANT } from "../../Util/API Calls/apiStatusConstants";
import { USER_TYPE } from "../../Util/constants";
import { deleteDB } from "./dexieDB";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";
import { C2CConsole } from "../../Util/Helper/C2CConsole";

const Logout = () => {
  const history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"]);

  useEffect(() => {
    const params = {
      user_token: `${localStorage.getItem("id_token")}`,
    };
    apiCall("auth/user-logout", params);
  }, []);

  // API Call ==>
  const apiCall = async (apiURL, params) => {
    let userType = localStorage.getItem("user_type");

    try {
      const res = await afterLoginApiAxios({
        method: "post",
        url: apiURL,
        data: params,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("id_token"),
        },
      });

      if (res?.data?.statusCode === API_STATUS_CONSTANT.SUCCESS) {
        clearStorageByLogout();
        switch (userType) {
          case USER_TYPE.PATIENT:
            history.push(`/${userType}/login`);
            break;
          default:
            deleteDB();
            history.push(`/${USER_TYPE.DOCTOR}/login`);
            break;
        }
        return;
      }
      history.goBack();
    } catch (err) {
      history.goBack();
      C2CConsole.log("error found");
      C2CConsole.log(err);
    }
  };
  // <==

  // Helper methods ==>

  const clearStorageByLogout = () => {
    removeCookie("id_token", {
      path: "/",
      domain: cookiesDomainUrl,
    });
    removeCookie(LOCALSTORAGEKEY.USER_ID, {
      path: "/",
      domain: cookiesDomainUrl,
    });
    removeCookie("user_type", { path: "/", domain: cookiesDomainUrl });
    localStorage.clear();
    sessionStorage.removeItem("hidden_features");
  };
  // <==

  return <></>;
};

export default Logout;
