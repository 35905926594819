import React from "react";
import classes from "./MultipleImageSelector.module.css";

import { Card } from "react-bootstrap";

const PreviewNames = ({ name, removeClickHandler, id }) => {
  return (
    <Card body className={classes.previewedNameRoot}  style={{width:'100%',backgroundColor:'#F5F5F5', color:'#17BAE0'}}>
      {name}
      <i
        className={"fa fa-times " + classes.crossedIconName}
        onClick={() => removeClickHandler(id)}
      />
    </Card>
  );
};

export default PreviewNames;
