// dependencies imports ⇒
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
// assets and scss imports ⇒
import "./doctorSignInOTPContainer.scss";
import PhoneNumberInputWithAction from "../../common/PhoneNumberInputWithAction";
import OTPInput from "../../common/OTPInput";
import ActionButton from "../../common/Buttons/ActionButton";
import { OTP_ERROR_MESSAGE } from "../constant";
import PasswordInputWithHeader from "../../common/PasswordInputWithHeader";
import SignInFormLayout from "../../common/SignInFormLayout";
import ErrorContainer from "../../common/ErrorContainer";
import Loader from "../../UI Component/Loader/Loader";
import {
  DOCTOR_LOGIN_WITH_PASSWORD_OR_OTP,
  SEND_ONBORAD_DOCTOR_OTP,
  SEND_OTP,
} from "../DoctorSignInAPIHandler/apiConstant";
import swal from "sweetalert";
import { LOCALSTORAGEKEY } from "../../../Util/Helper/localStorageUtils";
import { C2CConsole } from "../../../Util/Helper/C2CConsole";
import afterLoginApiAxios from "../../../Util/API Calls/afterLogin";
import { moveToNextPage } from "../../common/DeepLink";
import {
  setCookieForUser,
  setDynamicCookieForUser,
} from "../../../Util/API Calls/cookieHelper";
import beforeLoginAxios from "../../../Util/API Calls/beforeLogin";
import StaffLoginErrorModal from "../../Staff/StaffLoginErrorModal";
import { deleteDB } from "../../common/dexieDB";
import LoginWithPasswordOtp from "../../LoginWithPasswordOtp";
import { c2cBeforeLoginPostApiHandler } from "../../../Util/c2cBeforeLoginApiHandler";
// project file imports(UI components) ⇒

// const file imports(helper methods, etc) ⇒

const DoctorSignInOTPContainer = (props) => {
  const { phoneNumber, setPhoneNumber, loader } = props;
  // const declarations ⇒
  const [disableContinueButton, setDisableContinueButton] = useState(true);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [otpInput, setOtpInput] = useState("");
  const [password, setPassword] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [isPartialDoctor, setIsPartialDoctor] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorCard, setShowErrorCard] = useState(false);
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const [showRegisterButtonLoader, setShowRegisterButtonLoader] =
    useState(false);

  const [cookies, setCookie] = useCookies(["cookie-name"]);
  const history = useHistory();

  // let or var declarations ⇒

  // life cycle methods ⇒

  useEffect(() => {
    if (mobileNumber?.length === 10) {
      handleInputAction();
    }
  }, [mobileNumber]);

  useEffect(() => {
    if (mobileNumber?.length < 10 || otpInput?.length < 6) {
      setDisableContinueButton(true);
    } else {
      setDisableContinueButton(false);
    }
  }, [mobileNumber, otpInput]);

  useEffect(() => {
    if (phoneNumber?.length == 10) {
      setMobileNumber(phoneNumber);
    }
  }, [phoneNumber]);

  const getBrowserName = () => {
    // Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== "undefined";

    // Safari 3.0+ "[object HTMLElementConstructor]"
    var isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && window["safari"].pushNotification)
      );

    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;

    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1 - 79
    var isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    // Edge (based on chromium) detection
    var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1;

    // Blink engine detection
    var isBlink = isChrome && !!window.CSS;

    if (isFirefox) return "FireFox";
    if (isSafari) return "Safari";
    if (isIE && !isEdge) return "IE";
    if (isEdge) return "Edge";
    if (isChrome) return "Chrome";
    if (isEdgeChromium) return "EdgeChromium";
    if (isBlink) return "Blink-based";
  };

  const registerAnalytics = () => {
    afterLoginApiAxios
      .post(
        "user/engagement-analytics-data",
        {
          usecase: "login",
          user_type: "1",
          device_details: {
            device_type: "desktop",
            device_manufacturer: "",
            os_version: window.navigator.userAgent.substring(
              window.navigator.userAgent.indexOf("(") + 1,
              window.navigator.userAgent.indexOf(")")
            ),
            os_name: window.navigator.userAgent.substring(
              window.navigator.userAgent.indexOf("(") + 1,
              window.navigator.userAgent.indexOf(")")
            ),
            application_type: "web",
            application_version: "1.0",
            browser_name: getBrowserName(),
            browser_version: window.navigator.userAgent.substring(
              window.navigator.userAgent.lastIndexOf(")") + 1
            ),
          },
          doctor_login_data: {
            doctor_id: localStorage.getItem(LOCALSTORAGEKEY.USER_ID),
            doctor_status: "registered",
          },
        },
        {
          headers: {
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .catch((err) => C2CConsole.error(err));
  };

  // API Calls ⇒
  const handleSubmitAPICall = () => {
    let payload = {
      channel_id: "doctor-login",
      login_type: "password",
      phone_no: "+91" + mobileNumber,
      user_type: "doctor",
    };
    const sendOTPResponse = c2cBeforeLoginPostApiHandler(SEND_OTP, payload);
    sendOTPResponse.then(([response, error]) => {
      if (!error) {
        if (response.data && response.data.statusCode === 200) {
          setShowErrorCard(false);
          localStorage.setItem("is_partial_doctor", "False");
          if (response.data.password_set === 1) {
            setIsPartialDoctor(false);
            getOTPAPICall();
          } else {
            history.replace("/doctor/login/set-password");
          }
        } else if (response.data && response.data.statusCode === 500) {
          setIsPartialDoctor(true);
          partialDoctorLogin();
        } else if (response.data && response.data.statusCode === 501) {
          setShowRegisterButtonLoader(false);
          swal({
            title: "Invalid Phone Number Entered",
            type: "info",
          });
        } else {
          setShowRegisterButtonLoader(false);
          swal({
            title: "Something Went Wrong!",
            type: "info",
          });
        }
      } else {
        setShowRegisterButtonLoader(false);
      }
    });
  };

  const getOTPAPICall = () => {
    let payload = {
      channel_id: "doctor-login",
      phone_no: "+91" + mobileNumber,
      user_type: "doctor",
    };

    const doctorOTPResponse = c2cBeforeLoginPostApiHandler(SEND_OTP, payload);
    doctorOTPResponse.then(([response, error]) => {
      if (!error) {
        if (response.data.statusCode == 200) {
          setShowRegisterButtonLoader(false);
          setShowOtpInput(true);
          if (response?.data?.password_set === 1) {
            setShowPasswordInput(false);
          } else {
            setShowPasswordInput(true);
          }
        } else {
          setShowRegisterButtonLoader(false);
        }
      } else {
        setShowRegisterButtonLoader(false);
      }
    });
  };

  const handleLoginAPICall = () => {
    let payload = {
      channel_id: "doctor-login",
      phone_no: "+91" + mobileNumber,
      user_type: "doctor",
      user_otp: otpInput,
      stay_login: false,
      source: "web",
    };
    setShowButtonLoader(true);
    const doctorLoginResponse = c2cBeforeLoginPostApiHandler(
      DOCTOR_LOGIN_WITH_PASSWORD_OR_OTP,
      payload
    );
    doctorLoginResponse.then(([response, error]) => {
      if (!error) {
        if (response?.data?.statusCode == 200) {
          setOtpError(false);
          setCookieForUser(response.data.user.id_token, setCookie);
          setDynamicCookieForUser(
            LOCALSTORAGEKEY.USER_ID,
            response.data.user.user_id,
            setCookie
          );
          setDynamicCookieForUser(
            "user_type",
            response.data.user.user_type,
            setCookie
          );
          localStorage.setItem("id_token", response.data.user.id_token);
          localStorage.setItem(
            LOCALSTORAGEKEY.USER_ID,
            response.data.user.user_id
          );
          localStorage.setItem("user_type", response.data.user.user_type);
          localStorage.setItem("user_name", response.data.user.user_name);
          localStorage.setItem("full_name", response.data.user.full_name);
          localStorage.setItem("doctorPhone", `+91${mobileNumber}`);
          localStorage.setItem(
            LOCALSTORAGEKEY.DOCTOR_CLINIC_ASSOCIATION,
            response?.data?.doctor_clinic_association
          );
          localStorage.setItem(
            "last_login_date",
            response.data.user.last_login_date
          );
          localStorage.setItem(
            "specialization",
            response?.data?.specialization_id
          );
          localStorage.setItem(
            "doctor_role_name",
            response?.data?.user?.doctor_role_name
          );
          registerAnalytics();
          swal({
            title: "Login Successful!",
            type: "success",
          });
          setTimeout(() => {
            if (localStorage.getItem("id_token")) {
              if (localStorage.getItem("cid")) {
                history.push(
                  `/doctor/instant_consultation?consultation_id=${localStorage.getItem(
                    "cid"
                  )}`
                );
              } else {
                if (response?.data?.doctor_clinic_association === true) {
                  if (
                    cookies?.lastPathBeforeLogin &&
                    cookies?.lastPathBeforeLogin == "/doctor/account/webinars"
                  ) {
                    history.push("/clinic/account/webinars");
                  } else {
                    moveToNextPage("clinic", history, cookies);
                  }
                } else {
                  moveToNextPage("doctor", history, cookies);
                }
              }
            }
          }, 100);
        } else if (response?.data?.statusCode == 513) {
          setOtpError(true);
          setShowButtonLoader(false);
        } else {
          setOtpError(true);
          setShowButtonLoader(false);
        }
      } else {
        setShowButtonLoader(false);
      }
    });
  };

  const partialDoctorLogin = () => {
    localStorage.setItem("is_partial_doctor", "True");
    beforeLoginAxios
      .post("public/send-onboard-doctor-OTP", {
        phone_no: `+91${mobileNumber}`,
        channel_id: "doctor-login",
        login_type: "password",
      })
      .then((response) => {
        if (response) {
          if (response.data && response.data.statusCode === 200) {
            if (response.data.onboard_application_id === "NULL") {
              //swal doctor ot found
              setShowErrorCard(true);
              setShowRegisterButtonLoader(false);
            } else if (response.data.password_set === 0) {
              history.replace("/doctor/login/partial-doctor/set-password", {
                title: "Verify Phone Number",
                skipOTP: true,
              });
            } else if (response.data.password_set === 1) {
              getPartialDoctorOTPAPICall();
            } else {
              setShowRegisterButtonLoader(false);
              swal({
                title: "Something Went Wrong",
                text: "You are not registered on Connect2Clinic. But Something went wrong while attempting to register you. Please reach out to the customer support team, to register yourself",
                type: "info",
              });
            }
          } else {
            setShowRegisterButtonLoader(false);
          }
        } else {
          setShowRegisterButtonLoader(false);
        }
      })
      .catch(function (error) {
        C2CConsole.log(error);
      });
  };

  const handleLoginPartialDoctor = () => {
    setShowButtonLoader(true);
    beforeLoginAxios
      .post("doctor/webinar-login", {
        phone_no: `+91${phoneNumber}`,
        user_otp: otpInput,
      })
      .then((response) => {
        if (response) {
          if (response?.data?.statusCode == 200) {
            localStorage.setItem("id_token", response.data.user.id_token);
            localStorage.setItem(
              LOCALSTORAGEKEY.USER_ID,
              response.data.user.user_id
            );
            localStorage.setItem("user_type", response.data.user.user_type);
            setCookieForUser(response.data.user.id_token, setCookie);
            setDynamicCookieForUser(
              LOCALSTORAGEKEY.USER_ID,
              response.data.user.user_id,
              setCookie
            );
            setDynamicCookieForUser(
              "user_type",
              response.data.user.user_type,
              setCookie
            );
            localStorage.setItem("doctorPhone", `+91${phoneNumber}`);
            localStorage.setItem("onBoardedDoctor", true);
            localStorage.setItem(
              "specialization",
              response?.data?.user?.specialization_id
            );
            setTimeout(() => {
              if (localStorage.getItem("id_token")) {
                history.replace(
                  "/doctor/account/dashboard?utm_source=webapp&utm_medium=onboarding"
                );
              } else {
                setShowButtonLoader(false);
              }
            }, 100);
          } else if (response?.data?.statusCode == 510) {
            setPasswordError("Incorrect password entered");
            setShowButtonLoader(false);
          } else {
            setShowButtonLoader(false);
          }
        } else {
          setShowButtonLoader(false);
        }
      });
  };

  const getPartialDoctorOTPAPICall = () => {
    let payload = {
      channel_id: "doctor-login",
      phone_no: "+91" + mobileNumber,
    };

    const doctorOTPResponse = c2cBeforeLoginPostApiHandler(
      SEND_ONBORAD_DOCTOR_OTP,
      payload
    );
    doctorOTPResponse.then(([response, error]) => {
      if (!error) {
        if (response.data.statusCode == 200) {
          setShowOtpInput(true);
          setShowRegisterButtonLoader(false);
          if (response?.data?.password_set === 1) {
            setShowPasswordInput(false);
          } else {
            setShowPasswordInput(true);
          }
        } else {
          setShowRegisterButtonLoader(false);
        }
      } else {
        setShowRegisterButtonLoader(false);
      }
    });
  };

  const checkIsStaffAPICall = () => {
    setShowRegisterButtonLoader(true);
    beforeLoginAxios
      .post("public/staff-send-otp", {
        channel_id: "staff-login",
        phone_no: `+91${phoneNumber}`,
        login_type: "password",
      })
      .then((response) => {
        if (response) {
          if (response.data && response.data.statusCode === 200) {
            if (response.data.password_set === 1) {
              setIsStaff(true);
              getStaffOTP();
            } else {
              history.replace("/doctor/login/staff/set-password");
            }
          } else if (response.data && response.data.statusCode == 505) {
            setShowErrorPopup(true);
            setShowRegisterButtonLoader(false);
          } else if (response.data && response.data.statusCode === 202) {
            setIsStaff(false);
            handleSubmitAPICall();
          } else if (response.data && response.data.statusCode === 510) {
            setShowRegisterButtonLoader(false);
            swal({
              title: "Invalid Phone Number Entered",
              type: "info",
            });
          } else {
            setShowRegisterButtonLoader(false);
            swal({
              title: "Something Went Wrong!",
              type: "info",
            });
          }
        } else {
          setShowRegisterButtonLoader(false);
        }
      })
      .catch(function (error) {
        C2CConsole.log(error);
      });
  };

  const getStaffOTP = () => {
    beforeLoginAxios
      .post("public/staff-send-otp", {
        channel_id: "staff-login",
        phone_no: `+91${phoneNumber}`,
      })
      .then((response) => {
        if (response) {
          if (response.data && response.data.statusCode === 200) {
            setShowOtpInput(true);
            setShowRegisterButtonLoader(false);
          } else {
            setShowRegisterButtonLoader(false);
            swal({
              title: "Something Went Wrong!",
              type: "info",
            });
          }
        } else {
          setShowRegisterButtonLoader(false);
        }
      })
      .catch(function (error) {
        C2CConsole.log("err", error);
      });
  };

  const handleStaffLoginAPICall = () => {
    if (otpInput.length !== 6) {
      setOtpError("OTP must be exactly 6 characters");
      return;
    }

    setShowButtonLoader(true);
    beforeLoginAxios
      .post("auth/staff-login-rev1", {
        phone_no: `+91${mobileNumber}`,
        user_type: "staff",
        user_otp: otpInput,
      })
      .then((response) => {
        if (response) {
          if (response?.data?.statusCode == 200) {
            setOtpError(false);
            setCookieForUser(response.data.user.id_token, setCookie);
            setDynamicCookieForUser(
              LOCALSTORAGEKEY.USER_ID,
              response.data.user.staff_id,
              setCookie
            );
            setDynamicCookieForUser(
              "user_type",
              response.data.user.user_type,
              setCookie
            );
            setDynamicCookieForUser(
              "full_name",
              response.data.user.full_name,
              setCookie
            );
            setDynamicCookieForUser(
              "specialization",
              response?.data?.specialization_id,
              setCookie
            );
            setDynamicCookieForUser(
              "last_login_date",
              response.data.user.last_login_date,
              setCookie
            );
            setDynamicCookieForUser(
              "user_name",
              response.data.user.user_name,
              setCookie
            );
            setDynamicCookieForUser(
              "doctor_role_name",
              response.data.user.doctor_role_name,
              setCookie
            );
            setDynamicCookieForUser(
              "doctor_clinic_association",
              response.data.doctor_clinic_association,
              setCookie
            );
            setDynamicCookieForUser(
              "doctorPhone",
              `+91${phoneNumber}`,
              setCookie
            );
            localStorage.setItem("id_token", response.data.user.id_token);
            localStorage.setItem(
              LOCALSTORAGEKEY.USER_ID,
              response.data.user.doctor_id
            );
            localStorage.setItem("user_type", response.data.user.user_type);
            localStorage.setItem(
              "user_name",
              response.data.user.staff_full_name
            );
            localStorage.setItem(
              LOCALSTORAGEKEY.STAFF_ID,
              response.data.user.staff_id
            );
            localStorage.setItem(
              "full_name",
              response.data.user.staff_full_name
            );
            localStorage.setItem(
              "share_instant_consultation_link_allow",
              response.data.user.permission
                .share_instant_consultation_link_allow
            );
            localStorage.setItem(
              "add_edit_new_staff_name_and_number_allow",
              response.data.user.permission
                .add_edit_new_staff_name_and_number_allow
            );
            localStorage.setItem(
              "start_instant_consultation_allow",
              response.data.user.permission.start_instant_consultation_allow
            );
            localStorage.setItem(
              "upload_manual_prescriptions_allow",
              response.data.user.permission.upload_manual_prescriptions_allow
            );
            localStorage.setItem(
              "access_knowledge_center_allow",
              response.data.user.permission.access_knowledge_center_allow
            );
            localStorage.setItem(
              "download_offline_patients_allow",
              response.data.user.permission.download_offline_patients_allow
            );
            localStorage.setItem(
              "edit_prescriptions_allow",
              response.data.user.permission.edit_prescriptions_allow
            );
            localStorage.setItem(
              "join_webinar_allow",
              response.data.user.permission.join_webinar_allow
            );
            localStorage.setItem(
              "update_account_details_allow",
              response.data.user.permission.update_account_details_allow
            );
            localStorage.setItem(
              "update_profile_details_allow",
              response.data.user.permission.update_profile_details_allow
            );

            localStorage.setItem("doctorPhone", `+91${phoneNumber}`);
            localStorage.setItem(
              "last_login_date",
              response.data.user.last_login_date
            );

            localStorage.setItem(
              "start_upcoming_consultation_allow",
              response.data.user.permission.start_upcoming_consultation_allow
            );
            localStorage.setItem(
              "register_for_upcoming_webinar_allow",
              response?.data?.user?.permission
                ?.register_for_upcoming_webinar_allow
            );
            localStorage.setItem(
              LOCALSTORAGEKEY.STAFF_CLINIC_ASSOCIATION,
              response?.data?.staff_clinic_association
            );

            localStorage.setItem(
              "staff_doctor_association",
              response?.data?.staff_doctor_association
            );

            setTimeout(() => {
              if (localStorage.getItem("id_token")) {
                if (response?.data?.staff_clinic_association === true) {
                  history.replace("/clinic/account/dashboard");
                } else {
                  history.replace("/doctor/account/dashboard");
                }
              } else {
                setShowButtonLoader(false);
              }
            }, 100);
          } else if (response?.data?.statusCode == 513) {
            setOtpError(true);
            setShowButtonLoader(false);
          } else {
            setOtpError(true);
            setShowButtonLoader(false);
          }
        } else {
          setShowButtonLoader(false);
        }
      })
      .catch(function (error) {
        C2CConsole.log(error);
      });
  };
  // Helper methods ⇒

  // Actions ⇒

  const handleContinue = () => {
    deleteDB();
    if (isStaff) {
      handleStaffLoginAPICall();
    } else {
      if (isPartialDoctor) {
        handleLoginPartialDoctor();
      } else {
        handleLoginAPICall();
      }
    }
  };

  const handleResendOTP = () => {
    if (!isStaff) {
      if (isPartialDoctor) {
        getPartialDoctorOTPAPICall();
      } else {
        getOTPAPICall();
      }
    } else {
      getStaffOTP();
    }
  };

  const handleMobileInputChange = (value) => {
    setMobileNumber(value);
    setPhoneNumber(value);
  };

  const handleInputAction = () => {
    checkIsStaffAPICall();
  };

  const handlePhoneKeyDown = (e) => {
    if (e.key === "Enter" && mobileNumber?.length === 10) {
      handleInputAction();
    }
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
  };
  const onEnterKey = () => {
    !disableContinueButton && handleContinue();
  };

  // create UI ⇒

  const doctorLoginWithOTPMobileInputUI = () => {
    return (
      <div className="dlwoc-mobile-input">
        <PhoneNumberInputWithAction
          placeholder={"Enter Phone Number"}
          inputValue={mobileNumber}
          disabled={false}
          showRegisterButtonLoader={showRegisterButtonLoader}
          handleInputChange={handleMobileInputChange}
          handleInputAction={handleInputAction}
          handleKeyDown={handlePhoneKeyDown}
          autoFocus
        />
      </div>
    );
  };

  const doctorLoginWithOTPInputUI = () => {
    return (
      <div>
        <OTPInput
          autoFocus
          hasError={otpError}
          errorMessage={OTP_ERROR_MESSAGE}
          onChangeOTP={(otp) => setOtpInput(otp)}
          handleResendOTP={handleResendOTP}
          onEnter={onEnterKey}
        />
      </div>
    );
  };

  const doctorSetPasswordUI = () => {
    return (
      <div>
        <PasswordInputWithHeader
          title="Set password for easier access"
          isMandatory={false}
          passwordInput={password}
          errorMessage={passwordError}
          handlePasswordInputChange={handlePasswordChange}
        />
      </div>
    );
  };

  const doctorOTPActionButton = () => {
    return (
      <div className="dlwoc-action-button">
        <ActionButton
          title="Continue"
          disabled={disableContinueButton}
          showButtonLoader={showButtonLoader}
          handleClick={handleContinue}
        />
      </div>
    );
  };

  const doctorCreateActionButton = () => {
    return (
      <div className="dlwoc-action-button">
        <ActionButton
          title="Register New Account"
          disabled={false}
          handleClick={() => history.replace("/doctor/login/sign-up")}
        />
      </div>
    );
  };

  const errorContrainerUi = () => {
    return (
      <div className="error-msg-container">
        <ErrorContainer
          title={"No Doctor Registered!"}
          errorMessage={
            "You are not registered on Connect2Clinic. Kindly create a new Doctor’s account"
          }
        />
      </div>
    );
  };
  const loginpasswordCallBack = () => {
    history.push("/doctor/login");
  };
  const loginOtpCallBack = () => {
    history.push("/doctor/login/otp-login");
  };

  const doctorLoginWithPasswordAndOTPHeader = () => {
    return (
      <LoginWithPasswordOtp
        loginpasswordCallBack={loginpasswordCallBack}
        loginOtpCallBack={loginOtpCallBack}
        otpPath={"/doctor/login/otp-login"}
        passwordPath={"/doctor/login"}
      />
    );
  };

  const doctorLoginWithOtpUI = () => {
    return (
      <>
        <SignInFormLayout heading={"Hello, Doctor"} subheading={"Login here"}>
          {doctorLoginWithPasswordAndOTPHeader()}
          {doctorLoginWithOTPMobileInputUI()}

          {showErrorCard && errorContrainerUi()}

          {showOtpInput && !showErrorCard && doctorLoginWithOTPInputUI()}
          {showPasswordInput && doctorSetPasswordUI()}
          {showOtpInput && doctorOTPActionButton()}
          {showErrorCard && doctorCreateActionButton()}
        </SignInFormLayout>
      </>
    );
  };
  return (
    <div>
      {loader ? (
        <div className="loader-container">
          <Loader />
        </div>
      ) : (
        <div className="doctor-login-with-otp-container">
          {doctorLoginWithOtpUI()}
          <StaffLoginErrorModal
            show={showErrorPopup}
            onClose={() => {
              setShowErrorPopup(false);
            }}
          />
        </div>
      )}
    </div>
  );
};
// export ⇒
export default DoctorSignInOTPContainer;
