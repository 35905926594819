export function disableHelpButton() {
  // window.hideWidget();
  // let helpBtn = window.document.getElementById("launcher-frame");
  // if (helpBtn && helpBtn.style) {
  //   helpBtn.style.visibility = "hidden";
  //   helpBtn.style.display = "none";
  //   helpBtn.style.opacity = 0;
  // }
}

export function enableHelpButton() {
  // window.showsWidget();
  // let helpBtn = window.document.getElementById("launcher-frame");
  // if (helpBtn && helpBtn.style) {
  //   helpBtn.style.opacity = 1;
  //   helpBtn.style.visibility = "visible";
  //   helpBtn.style.display = "block";
  // }
}
