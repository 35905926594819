import React, { Component } from "react";
import "./DoctorProfile.scss";
import "../../globalStyles/patientDetails.css";
import Profile from "./Profile/Profile";

class DoctorDetails extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      setDoctorDetails,
      doctorSlug = false,
      duration,
      fees,
      updateProcedures,
      selectedProcedure,
      setalternateNumber,
      handleInstruction,
      selectedConsultationType,
      consultationList,
      instruction,
      selectedClinic,
    } = this.props;
    return (
      <div className={"col-md-3 col-xs-12"}>
        <div className="doctor-profile-divider">
          <Profile
            duration={duration}
            fees={fees}
            setDoctorDetails={setDoctorDetails}
            doctorSlug={doctorSlug}
            updateProcedures={updateProcedures}
            setalternateNumber={setalternateNumber}
            handleInstruction={handleInstruction}
            selectedConsultationType={selectedConsultationType}
            consultationList={consultationList}
            instruction={instruction}
            selectedClinic={selectedClinic}
          />
        </div>
      </div>
    );
  }
}
export default DoctorDetails;
