import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class NoAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <div className="bg-info col-lg-12 col-md-12 col-xs-12 col-sm-12 pb-5 ">
        <div className="container-fluid">
          <div className="row mt-5 hv-100">
            <div className="col-md-6 offset-md-3">
              <img src="{process.env.PUBLIC_URL+'/images/noAccess.png'}" />
              <label className="text-white mt-3">
                <Link className="text-white" to="/">
                  Click here to login
                </Link>
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
