import React, { useState, useEffect } from "react";
import Header from "../Header";
import DoctorProfile from "./DoctorProfile";
import PatientLoginModal from "./PatientLoginModal";
import Details from "./Details";
import swal from "sweetalert";
import moment from "moment";
import { Redirect, useParams, useHistory } from "react-router";
import afterLoginApiAxios from "../../Util/API Calls/afterLogin";
import { getSignedUrl } from "../../Util/API Calls/SignedAPIUrl";
import "./patientdetails.scss";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";
import unAuthorizedError from "../Errors/unAuthorizedError";
import { C2CConsole } from "../../Util/Helper/C2CConsole";

function PatientDetails() {
  const [patientState, setPatientState] = useState({
    name: "",
    email: "",
    dob: null,
    sex: "Male",
    address: "",
    pincode: "",
    purpose: "",
    selectedState: 0,
    prev_prescription: [],
  });
  const [followUp, setFollowUp] = useState(false);
  const [isNamePresent, setIsNamePresent] = useState(false);
  const [isDobPresent, setDobPresent] = useState(false);
  const [terms, setTerms] = useState(false);
  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    dob: "",
    sex: "",
    address: "",
    pincode: "",
    selectedState: "",
    purpose: "",
  });
  const [appointmentDate, setAppointmentDate] = useState("");
  const [appointmentSlotId, setAppointmentSlotId] = useState("");
  const [doctorId, setDoctorId] = useState("");
  const [clinicId, setClinicId] = useState("");
  const [loggedInStatus, setLoggedInStatus] = useState(() => {
    try {
      if (
        localStorage.getItem("id_token") &&
        localStorage.getItem("user_type") == "patient"
      ) {
        return true;
      }
    } catch (error) {
      return false;
    }
    return false;
  });
  const [videoUrl, setVideo] = useState(
    "https://www.youtube.com/embed/yYFOPYu_nKI"
  );

  const userId = localStorage.getItem(LOCALSTORAGEKEY.USER_ID);

  let { slug } = useParams();
  let history = useHistory();

  useEffect(() => {
    if (loggedInStatus) {
      afterLoginApiAxios({
        method: "post",
        url: "/patient/get_physical_appointment_by_id",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("id_token"),
        },
        data: {
          appointment_id: slug,
          "authorizer-principal-id": {
            user_type: 2,
            user_id: userId,
          },
        },
      }).then((response) => {
        if (!response) {
          return unAuthorizedError(() => {
            history.push(window.location.pathname + "/login");
          });
        }
        if (response?.data?.data[0]?.patient_name.length) {
          setIsNamePresent(true);
        }
        if (response?.data?.data[0]?.patient_dob.length) {
          setDobPresent(true);
        }
        let tempData = {
          name: response.data.data[0].patient_name,
          email: response.data.data[0].email,
          dob: moment(response.data.data[0].patient_dob).format("D MMM YYYY"),
          sex: response.data.data[0].patient_gender,
          address: response.data.data[0].patient_address,
          pincode: response.data.data[0].patient_pincode,
          purpose: response.data.data[0].purpose_of_appointment,
          selectedState: parseInt(response.data.data[0].state_code),
          prev_prescription: [],
        };
        setClinicId(response.data.data[0].clinic_id);
        localStorage.setItem("clinicId", response.data.data[0].clinic_id);
        setPatientState(tempData);
        setDoctorId(response.data.data[0].doctor_id);
        setFollowUp(
          response.data.data[0].is_follow_up == "False" ? false : true
        );
        setAppointmentDate(response.data.data[0].appointment_date);
        setAppointmentSlotId(response.data.data[0].doctor_appointment_id);
      });
    }
  }, [loggedInStatus]);

  const fileCheckUpload = async (files) => {
    let prec = [];
    for (const file of files) {
      let signedUrl = await getSignedUrl(
        {
          file_size: file.size,
          doc_title: file.name,
          user_type: localStorage.getItem("user_type"),
          use_case: "book-appointment-add-details_rev1",
        },
        file.fileDate
      );
      if (signedUrl) {
        prec.push({
          value: signedUrl.fields["key"],
          name: file.name,
        });
      }
    }
    if (prec.length == files.length) return prec;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailRegex = RegExp(/[\w-]+@([\w-]+\.)+[\w-]+/);
    const pincodeRegex = RegExp(/^[1-9][0-9]{5}$/);

    if (patientState.name === "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        name: "Patient Name is Mandatory",
      }));
    }

    if (patientState.email !== "" && !emailRegex.test(patientState.email)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email",
      }));
    }

    if (patientState.dob === null) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        dob: "Date of birth is Mandatory",
      }));
    }

    if (patientState.address === "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        address: "Address is Mandatory",
      }));
    }

    if (patientState.pincode === "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        pincode: "Pincode is Mandatory",
      }));
    }

    if (
      patientState.pincode !== "" &&
      !pincodeRegex.test(patientState.pincode)
    ) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        pincode: "Please Enter Valid Pincode",
      }));
    }

    if (patientState.selectedState === 0) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        selectedState: "State is Mandatory",
      }));
    }

    if (patientState.purpose === "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        purpose: "Purpose is Mandatory",
      }));
    }

    if (
      patientState.name === "" ||
      (patientState.email !== "" && !emailRegex.test(patientState.email)) ||
      patientState.dob === null ||
      patientState.address === "" ||
      patientState.pincode === "" ||
      patientState.selectedState === 0 ||
      patientState.purpose === "" ||
      (patientState.pincode !== "" && !pincodeRegex.test(patientState.pincode))
    ) {
      return;
    }

    let prec = await fileCheckUpload(patientState.prev_prescription);
    if (prec == undefined) {
      swal({
        title: "Invalid File type!",
        text: "Please upload file having extensions .jpeg/.jpg/.png/.gif/.pdf/.doc only.",
        type: "error",
        icon: "warning",
      }).then(function () {});
      return;
    }

    let formData = {
      registration_type: "0",
      name: patientState.name,
      dob: moment(patientState.dob).format("YYYY-MM-D"),
      gender: patientState.sex,
      prev_prescription: prec || [],
      purpose_of_appointment: patientState.purpose,
      patient_email: patientState.email,
      appointment_date: moment(appointmentDate).format("YYYY-MM-D"),
      appointment_slot_id: appointmentSlotId,
      is_follow_up: followUp,
      is_accept_terms: terms,
      patient_phone_number: localStorage.getItem("user_name"),
      patient_address: patientState.address,
      patient_pincode: patientState.pincode,
      state_code: patientState.selectedState,
      appointment_id: slug,
      clinic_id: clinicId,
      patient_id: userId,
      "authorizer-principal-id": {
        user_type: 2,
        user_id: userId,
      },
    };

    afterLoginApiAxios({
      method: "post",
      url: "/patient/update-consult-details",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("id_token"),
      },
      data: formData,
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          history.push(`/in-clinic-payment/${slug}`);
        } else {
          swal({
            title: "Error",
            text: response.data.message,
            type: "error",
            icon: "error",
          });
        }
      })
      .catch((error) => C2CConsole.log(error));
  };

  if (loggedInStatus) {
    return (
      <div style={{ backgroundColor: "#F5F6F7" }}>
        <div className="patient-details-header">
          <Header />
        </div>
        <div className="online-patient-container">
          <div className="welcome-head mb-4 p-4">
            Welcome to C2C - an initiative to simplify healthcare for you. You
            can <b>book an online appointment</b> with the doctor by selecting
            the date and time of your preference below. In case you are looking
            for a{" "}
            <b>
              follow-up consultation, please book using the ‘Follow-up’ option
              on the right.
            </b>
          </div>
          <div className="row">
            <div className="col-md-3 col-xs-12">
              <div className="doctor-profile-info">
                <DoctorProfile appointmentID={slug} doctorID={doctorId} />
              </div>
            </div>
            <div className="col-md-9 col-xs-12 for-mob-view">
              <Details
                patientState={patientState}
                setPatientState={setPatientState}
                terms={terms}
                setTerms={setTerms}
                isNamePresent={isNamePresent}
                isDobPresent={isDobPresent}
                formErrors={formErrors}
              />
            </div>
          </div>
        </div>
        <div className="online-patient-continue py-3">
          <button
            disabled={!terms}
            className={`${
              terms ? "continue-btn" : "continue-disabled-btn"
            } px-5 py-2`}
            onClick={(e) => handleSubmit(e)}
          >
            Continue
          </button>
        </div>
        <button className="book-appoint-video-btn">
          <a
            href=""
            data-toggle="modal"
            data-target="#myModal"
            className="book-appoint-video"
            onClick={() =>
              setVideo("https://www.youtube.com/embed/yYFOPYu_nKI")
            }
          >
            <img
              src={
                process.env.PUBLIC_URL + "/images/how-to-book-video-play.svg"
              }
              alt=""
            />
            <span>How to book appointment</span>
          </a>
        </button>
      </div>
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: `/online-payment/${slug}/login`,
        }}
      />
    );
  }
}

export const OnlineLoginPage = () => {
  const [showPatientLoginModal, setShowPatientLoginModal] = useState(true);
  return (
    <PatientLoginModal
      baseUrl="/online-payment/:slug"
      showPatientLoginModal={showPatientLoginModal}
      setShowPatientLoginModal={(value) => {
        setShowPatientLoginModal(value);
      }}
    />
  );
};

export default PatientDetails;
