// Dependies import
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import moment from "moment";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
//assest and scss import
import "./abhaPatientProfileDetails.scss";
//project files import
import AbhaPatientProfileOTPContainer from "../AbhaPatientProfileOTPContainer";
// constant file import
import { ABHA_SEND_OTP } from "../APIHandler/apiConstants";
import { sendAbhaPatienOTP } from "../APIHandler";
import { C2CConsole } from "../../../../Util/Helper/C2CConsole";

function AbhaPatientProfileDetails(props) {
  const { showPatientProfile, setShowPatientProfile, selectedPatient } = props;

  // const import
  const [showOTPUi, setShowOTPUi] = useState(false);
  const [txnId, setTxnId] = useState();
  const [hideOtpResend, setHideOtpResend] = useState(false);
  const [otpErrorMsg, setOtpErrorMsg] = useState("");

  // Helper Methods ==>

  const getGenderLabel = (gender) => {
    if (gender === "M") {
      return "Male";
    } else if (gender === "F") {
      return "Female";
    } else {
      return "Others";
    }
  };

  // <===

  // Actions ===>

  const onDownloadButtonCLick = () => {
    sendAbhaPatientOTP();
    setShowOTPUi(true);
  };

  const onCloseModal = () => {
    setShowPatientProfile(false);
  };

  // <==

  // Api Calls ===>

  const sendAbhaPatientOTP = () => {
    let payload = {
      abha_number: selectedPatient?.abha_number,
    };

    sendAbhaPatienOTP(ABHA_SEND_OTP, payload)
      .then(([res, err]) => {
        C2CConsole.log(res);
        if (!err) {
          if (res?.data?.statusCode === 200) {
            setTxnId(res?.data?.txnId);
            setOtpErrorMsg("");
            setHideOtpResend(false);
          } else if (res?.data?.statusCode === 503) {
            setHideOtpResend(true);
            setOtpErrorMsg(res?.data?.message);
          }
        }
      })
      .catch((err) => {
        C2CConsole.log(err);
      });
  };

  // <===

  // Display UI ==>

  const displayPatientDetails = (title, value) => {
    return (
      <div className="patient-personal-detail">
        <div className="patient-detail-title-wrapper">
          <div className="patient-detail-title">{title}</div>:
        </div>
        <div className="patient-detail-value">{value}</div>
      </div>
    );
  };

  const patientAbhaDetails = () => {
    return (
      <div className="abha-patient-details-btn-wrapper">
        <div className="patient-personal-details-wrapper">
          {displayPatientDetails(
            "Patient Name",
            `${" "}
              ${selectedPatient?.firstName} ${selectedPatient?.middleName}${" "}
              ${selectedPatient?.lastName}`
          )}
          {displayPatientDetails(
            "DOB",
            `${moment(selectedPatient?.dob).format("DD MMM YYYY")}`
          )}
          {displayPatientDetails(
            "Gender",
            getGenderLabel(selectedPatient?.gender)
          )}
          {displayPatientDetails("ABHA Number", selectedPatient?.abha_number)}
          {displayPatientDetails("ABHA Address", selectedPatient?.abha_address)}
        </div>
        <div className="download-your-abha-btn-wrapper">
          <div
            onClick={onDownloadButtonCLick}
            className="download-your-abha-btn"
          >
            Download Your ABHA Card
          </div>
        </div>
      </div>
    );
  };

  const patientAbhaProfile = () => {
    return (
      <div className="abha-patient-profile-details-container">
        <div className="patient-profile-heading-btn-wrapper">
          <div className="abha-patient-profile-headinng">
            {showOTPUi ? "Download ABHA Card" : "Patient Profile"}
          </div>
          <div className="abha-patient-profile-details-close-icon">
            <HighlightOffIcon
              onClick={onCloseModal}
              sx={{ color: "#909090", cursor: "pointer" }}
            />
          </div>
        </div>
        {showOTPUi ? (
          <AbhaPatientProfileOTPContainer
            selectedPatient={selectedPatient}
            txnId={txnId}
            sendAbhaPatientOTP={sendAbhaPatientOTP}
            hideOtpResend={hideOtpResend}
            otpErrorMsg={otpErrorMsg}
            onCloseModal={onCloseModal}
          />
        ) : (
          patientAbhaDetails()
        )}
      </div>
    );
  };

  const abhaPatientProfileDetailUi = () => {
    return (
      <>
        {showPatientProfile && (
          <Dialog
            open={true}
            PaperProps={{
              sx: {
                borderRadius: "20px",
                margin: window.innerWidth > 768 ? "" : "19px",
                width: window.innerWidth > 768 ? "auto" : "100%",
              },
            }}
          >
            {patientAbhaProfile()}
          </Dialog>
        )}
      </>
    );
  };

  // <==

  return <>{abhaPatientProfileDetailUi()}</>;
}

export default AbhaPatientProfileDetails;
