// dependencies imports ⇒
import React, { useEffect, useState } from "react";
// assets and scss imports ⇒
import "./abhaExistingCardView.scss";
import BlueTick from "../../../assets/blueTick.png";
import TickGif from "../../../assets/tickGif.gif";
import C2cimage from "../../../assets/forhomepage/c2c_logo_new.png";
import Govimage from "../../../assets/gov.png";
// project file imports(UI components) ⇒
import HomePageHeaderContainer from "../../HomePageViewContainer/HomePageHeaderContainer";
import AbhaActionButton from "../common/AbhaActionButton";
// const file imports(helper methods, etc) ⇒
import { downloadAbhaCard, getAbhaProfile } from "../AbhaAPIHandler";
import {
  ABHA_PROFILE,
  DOWNLOAD_ABHA_CARD,
} from "../AbhaAPIHandler/APIConstants";
import { detectMimeType } from "../helper";
import { GENDERS } from "../constant";

const AbhaExistingCardView = (props) => {
  const { patientId, transactionID } = props;
  // const declarations ⇒
  const [profileDetails, setProfileDetails] = useState({});
  const [qrCode, setQRCode] = useState("");

  // life cycle methods ⇒
  useEffect(() => {
    if (patientId && transactionID) {
      let payload = {
        txnid: transactionID,
      };
      getAbhaProfileAPICall(payload);
      let qrPayload = {
        txnid: transactionID,
        type: "qr_code",
      };
      getQRCodeAPICall(qrPayload);
    }
  }, [patientId, transactionID]);

  // API Calls ⇒
  const getAbhaProfileAPICall = (reqPayload) => {
    const adhaProfileResponse = getAbhaProfile(
      ABHA_PROFILE(patientId),
      reqPayload
    ); //Fetch API Data
    adhaProfileResponse.then(([res, error]) => {
      if (!error) {
        if (res?.data?.statusCode == 200) {
          setProfileDetails({ ...res?.data });
        }
      }
    });
  };

  const getQRCodeAPICall = (reqPayload) => {
    const qrResponse = downloadAbhaCard(
      DOWNLOAD_ABHA_CARD(patientId),
      reqPayload
    ); //Fetch API Data
    qrResponse.then(([res, error]) => {
      if (!error) {
        if (res?.data?.statusCode == 200) {
          setQRCode("data:image/jpg;base64," + res?.data?.content);
        }
      }
    });
  };

  // Actions ⇒
  const handleDownloadAbhaCard = () => {
    let reqPayload = {
      txnid: transactionID,
      type: "card",
    };
    const qrResponse = downloadAbhaCard(
      DOWNLOAD_ABHA_CARD(patientId),
      reqPayload
    ); //Fetch API Data
    qrResponse.then(([res, error]) => {
      if (!error) {
        if (res?.data?.statusCode == 200) {
          let base64String = res?.data?.content;
          let signature = detectMimeType(base64String);
          const linkSource = `data:${signature};base64,${base64String}`;
          const downloadLink = document.createElement("a");
          const fileName = `abha_card.${signature.split("/")[1]}`;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    });
  };

  // Helper methods ⇒
  const getDob = (data) => {
    return `${data?.dayOfBirth ?? "--"}/${data?.monthOfBirth ?? "--"}/${
      data?.yearOfBirth ?? "--"
    }`;
  };

  // create UI ⇒
  const AbhaExistingCardViewUI = () => {
    return (
      <div>
        <div className="axcv-card-container">
          <div className="axcv-card-background"></div>
          <div className="axcv-card-tick-gif">
            <img src={TickGif} alt="" />
          </div>
          <div className="axcv-heading">
            <div>We found an ABHA address linked with your Aadhaar.</div>
          </div>
          <div className="axcv-abha-card-container">
            <div className="axcv-abha-card">
              <div className="axcv-abha-blue-tick">
                <img src={BlueTick} alt="" />
              </div>
              <div>
                <div></div>
                <div></div>
              </div>
              <div className="axcv-button-container-img">
                <img src={C2cimage} alt="" />
                <img
                  className="axcv-button-container-img2"
                  src={Govimage}
                  alt=""
                />
              </div>
              <div className="axcv-abha-card-content">
                <div className="axcv-abha-card-left-content">
                  <div>Health ID: {profileDetails?.healthIdNumber} </div>
                  <div>ABHA Address: {profileDetails?.healthId}</div>
                  <div>Date Of Birth: {getDob(profileDetails)}</div>
                  <div>
                    Gender:{" "}
                    {GENDERS[profileDetails?.gender]
                      ? GENDERS[profileDetails?.gender]
                      : "Others"}
                  </div>
                  <div>Mobile: {profileDetails?.mobile}</div>
                </div>
                <div className="axcv-abha-card-right-content">
                  <img src={qrCode} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="axcv-button-container">
            <AbhaActionButton
              title="Download Your ABHA Card"
              disabled={false}
              handleClick={handleDownloadAbhaCard}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <HomePageHeaderContainer showDetails={false} />
      <div className="abha-existing-card-view">{AbhaExistingCardViewUI()}</div>
    </div>
  );
};

//export
export default AbhaExistingCardView;
