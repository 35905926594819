import React, { useEffect } from "react";
import { useTimer } from "../../Hooks/useTimer";
import "./payment.scss";

function CountdownTimer({ doSomething }) {
  useEffect(() => {
    start();
  }, []);

  const addZero = (time) => {
    if (time < 10) return `0${time}`;
    return time;
  };

  const { time, start, reset } = useTimer({
    initialTime: 1200,
    endTime: 0,
    timerType: "DECREMENTAL",
    autostart: false,
    onTimeOver: () => {
      doSomething();
    },
  });

  return (
    <div className="payment-countdown-container">
      {`Please complete your payment within ${addZero(
        Math.floor(time / 60)
      )}:${addZero(time - Math.floor(time / 60) * 60)}`}
    </div>
  );
}

export default CountdownTimer;
