import afterLoginApiAxios from "./afterLogin";
import axios from "axios";

export function getSignedUrl(signedUrlData, file) {
  return new Promise((resolve, reject) => {
    try {
      afterLoginApiAxios
        .post("receiver/get-signed-url", signedUrlData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("authorization_token"),
          },
        })
        .then(async (response) => {
          if (response) {
            var responseData = response.data;
            if (responseData.response && responseData.response.fields) {
              let fileData = await sendFileToS3(responseData.response, file);
              resolve(responseData.response);
            }
            resolve(null);
          }
        })
        .catch((error) => reject(error));
    } catch (error) {
      reject(error);
    }
  });
}

export function sendFileToS3(AWS3, file) {
  let formData = new FormData();
  Object.keys(AWS3.fields).forEach((keyName) => {
    formData.append(keyName, AWS3.fields[keyName]);
  });
  formData.append("file", file);
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(AWS3.url, formData, {
          headers: {
            "Content-Type":
              "multipart/form-data;boundary=--WebKitFormBoundaryE19zNvXGzXaLvS5C",
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
}
