import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./ConfirmationModal.scss";

function BootstrapDialogTitle({children, onClose, showCloseIcon, ...other}) {
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {showCloseIcon && onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            fontSize:"0.9rem",
            color:"black"
          }}
        >
            <span className="px-1">Close</span>
          <CloseIcon fontSize="small"/>
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const ConfirmationModal = ({
  open,
  handleClose,
  showCloseIcon,
  headingContent,
  children,
  mainContent,
  showSuccessButton,
  showFailureButton,
  onSuccess,
  onFailure
}) => {
  return (
    <>
      <Dialog
        onClose={handleClose}
        fullWidth
        maxWidth={"sm"}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          showCloseIcon={showCloseIcon}
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent id="dialog-content-container">
          {headingContent && (
            <DialogContentText id="dialog-content-heading">
              {headingContent}
            </DialogContentText>
          )}
          {mainContent && (
            <DialogContentText id="dialog-content-text">
              {mainContent}
            </DialogContentText>
          )}
          {children}
        </DialogContent>
        <DialogActions id="dialog-content-action-div">
          {showSuccessButton && onSuccess && (
            <button
              className="dialog-content-success"
              onClick={onSuccess}
              autoFocus
            >
              Yes
            </button>
          )}
          {showFailureButton && onFailure && (
            <button
              className="dialog-content-failure"
              onClick={onFailure}
              autoFocus
            >
              No
            </button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationModal;
