import React from "react";
import "../styles/MostAskedQuestion.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import QuestionList from "./QuestionList";
import afterLoginApiAxios from "../../Util/API Calls/afterLogin";
import swal from "sweetalert";

var detectedCategory = [];
class MostAskedQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      temporaryData: null,
      MostAskedQuestion: "",
    };
  }

  render() {
    return (
      <div className="page-layout">
        <div className="text-maq">Most Asked Questions</div>
        <div className="toggle-qa">
          {this.props.maq !== undefined
            ? this.props.maq.map((query, index) => (
                <QuestionList
                  queryquestion={query.question}
                  queryanswer={query.answer}
                  key={index}
                  activateKey={index}
                />
              ))
            : "Currently Empty, Please wait while your data is being fetched"}
        </div>
      </div>
    );
  }
}

export default MostAskedQuestion;
