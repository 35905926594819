// dependencies imports ⇒
import React, { useState } from "react";
// assets and scss imports ⇒
import "./passwordInputWithHeader.scss";
import eyeOn from "../../../assets/doctorLoginAssets/eyee.svg";
import eyeOff from "../../../assets/doctorLoginAssets/eyee (1).svg";
// project file imports(UI components) ⇒

// const file imports(helper methods, etc) ⇒

const PasswordInputWithHeader = (props) => {
  // const declarations ⇒
  const {
    title,
    isMandatory,
    placeholder,
    passwordInput,
    disabled,
    hasError,
    errorMessage,
    autoComplete,
    handlePasswordInputChange,
    inputRef = null,
    onKeyDown,
  } = props;

  const [showPassowrd, setShowPassword] = useState(false);
  // let or var declarations ⇒

  // life cycle methods ⇒

  // API Calls ⇒

  // Helper methods ⇒

  // Actions ⇒

  const handleOnChange = (e) => {
    let value = e.target.value;
    handlePasswordInputChange && handlePasswordInputChange(value);
  };

  const handleTogglePassowrd = () => {
    setShowPassword(!showPassowrd);
  };

  // create UI ⇒

  const passwordInputHeaderUI = () => {
    return (
      <div className="piwh-header-title">
        {title}
        {isMandatory ? (
          <span className="piwh-header-title-mandatory">*</span>
        ) : (
          ""
        )}
      </div>
    );
  };

  const passwordInputUI = () => {
    return (
      <div
        className={
          "piwh-input-with-icon-container " +
          (hasError ? "piwh-input-with-icon-container-error " : "")
        }
      >
        <div className={"piwh-input-container"}>
          <input
            className="piwh-input"
            type={showPassowrd ? "text" : "password"}
            placeholder={placeholder ?? ""}
            value={passwordInput}
            disabled={disabled}
            onChange={handleOnChange}
            ref={inputRef ? inputRef : null}
            autoComplete={autoComplete ?? "none"}
            onKeyDown={
              onKeyDown && typeof onKeyDown === "function"
                ? onKeyDown
                : undefined
            }
          />
        </div>
        <div className="piwh-image-container">
          <img
            src={showPassowrd ? eyeOn : eyeOff}
            alt="eye"
            className="piwh-image"
            onClick={handleTogglePassowrd}
          />
        </div>
      </div>
    );
  };

  const passwordInputErrorUI = () => {
    return <div className="piwh-input-error">{errorMessage}</div>;
  };

  const passwordInputWithHeaderUI = () => {
    return (
      <div className="piwh-container">
        {title && passwordInputHeaderUI()}
        {passwordInputUI()}
        {hasError && passwordInputErrorUI()}
      </div>
    );
  };

  return (
    <div className="password-input-with-header">
      {passwordInputWithHeaderUI()}
    </div>
  );
};

// export ⇒

export default PasswordInputWithHeader;
