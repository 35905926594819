import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import afterLoginApiAxios from "../../../Util/API Calls/afterLogin";
import swal from "sweetalert";
import { doctorProfileFiles } from "../../../Util/API Calls/APIList";
import Loader from "../../UI Component/Loader/Loader";
import ConsultationInformation from "../ConsultationInformation/ConsultationInformation";
import { Media } from "react-bootstrap";
import "../../../globalStyles/doctorProfileStyle.css";
import Verification_Tick from "../../../assets/verified.png";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Pending from "../../../assets/pending.png";
import { LOCALSTORAGEKEY } from "../../../Util/Helper/localStorageUtils";

const doctorIntitalState = {
  about: "",
  country_code: "",
  degree: "",
  description: "",
  display_name: "",
  doctor_id: "",
  first_name: "",
  id: "",
  last_name: "",
  medical_reg_no: "",
  middle_name: "",
  phone_no: "",
  prefix_name: "",
  profile_picture_url: "",
  short_url_slug: "",
  total_fees: Number,
};

const Profile = ({
  setDoctorDetails,
  doctorSlug = false,
  duration,
  fees,
  updateProcedures,
  setalternateNumber,
  // handleInstruction,
  selectedConsultationType,
  consultationList,
  selectedClinic,
}) => {
  let clinicId = localStorage.getItem(LOCALSTORAGEKEY.CLINIC_ID);
  const routeParams = useParams();
  const routeHistory = useHistory();
  const [doctorProfile, setDoctorProfile] = useState(doctorIntitalState);
  const [loading, setLoading] = useState(false);
  const [procedureDetails, setprocedureDetails] = useState({});
  const [consultationTypeList, setConsultationList] = useState([]);

  useEffect(() => {
    let meeting_id = localStorage.getItem("meeting_id"); // to check if its an instant consultation

    setLoading(true);
    afterLoginApiAxios
      .post("/public/get-doctor-details-by-slug-rev1", {
        doctor_url_short_code: doctorSlug ? doctorSlug : routeParams.slug,
        ...(!meeting_id && clinicId && { clinic_id: clinicId }),
      })
      .then((response) => {
        setLoading(false);
        if (response.data.statusCode !== 200) return response;
        localStorage.setItem(
          "whatsAppEnable",
          response.data.enable_whatsapp_notifications
        );
        // Request Successful
        localStorage.setItem("doctor", JSON.stringify(response.data.doctor));
        localStorage.setItem(
          LOCALSTORAGEKEY.IS_ASSOCIATED,
          response.data.is_associated_to_clinic
        );
        {
          setalternateNumber &&
            setalternateNumber(response.data.alternate_numbers);
        }
        setDoctorProfile(response.data.doctor);
        setprocedureDetails(response.data.procedure_list);
        setConsultationList(response.data.regular_followup_list);
        if (response.data.is_associated_to_clinic === false) {
          updateProcedures &&
            updateProcedures(
              response.data.procedure_list,
              response.data.regular_followup_list
            );
        } else {
          if (selectedClinic) {
            updateProcedures &&
              updateProcedures(
                response.data.procedure_list,
                response.data.regular_followup_list
              );
          }
        }
    
      })
      .then((response) => {
        setLoading(false);
        //  Request Failed!
        if (response && response.data.statusCode !== 200) {
          swal({
            title: "Could not Fetch Doctor Details!",
            text: response.data.message,
            icon: "error",
          }).then(() => {
            routeHistory.push("/");
          });
        }
      });
  }, [routeHistory, routeParams.slug, clinicId, selectedClinic]);

  useEffect(() => {
    setDoctorDetails(doctorProfile);
  }, [doctorProfile.doctor_id, setDoctorDetails, doctorProfile]);

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 12,
      fontFamily: `"Rubik", sans-serif`,
      margin: "0px 0px 0px 0px",
    },
  }))(Tooltip);

  // const handleData=(data)=>{
  //   handleInstruction(data)
  // }
  return (
    <>
      {loading ? <Loader /> : null}
      <div className="patient-doctor-profile mb-3">
        <Media className="mb-4">
          <div className="rounded-circle shadow doctor-profile-div mr-3">
            {doctorProfile.profile_picture_url ? (
              <img
                src={
                  doctorProfile.profile_picture_url
                    ? doctorProfileFiles + doctorProfile.profile_picture_url
                    : "https://via.placeholder.com/150?text=placeHolderImage"
                }
                className="rounded-circle"
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <span className="doctor-profile-initial">
                {doctorProfile.first_name.charAt(0) +
                  doctorProfile.last_name.charAt(0)}
              </span>
            )}
          </div>
          <Media.Body
            style={{ width: "calc(100% - 95px)" }}
            className="doctor_info"
          >
            <div className="mb-1 doctor-name left_side_doc">
              {`${doctorProfile.prefix_name}.${doctorProfile.first_name} `}
              {doctorProfile.middle_name ? doctorProfile.middle_name : ""}{" "}
              {doctorProfile.last_name}
            </div>
            <div className="mb-1 doctor-prof">{doctorProfile.display_name}</div>
            <div className="doctor-reg-no for_profile">
              <p
                className="doctor_reg_no"
                style={{ width: "calc(100% - 31px)" }}
              >
                Regd No:{" "}
              </p>
              <p>
                {doctorProfile.medical_reg_no}
                {doctorProfile.approval_status === "C2C Partially Verified" ? (
                  <LightTooltip title="C2C Partially Verified">
                    <Button>
                      <img src={Pending} />
                    </Button>
                  </LightTooltip>
                ) : doctorProfile.approval_status ===
                  "C2C Verification Pending" ? (
                  <LightTooltip title="C2C Verification Pending">
                    <Button>
                      <img src={Pending} />
                    </Button>
                  </LightTooltip>
                ) : (
                  ""
                )}
              </p>
            </div>
            {doctorProfile.approval_status === "C2C Registration Verified" && (
              <div className="verification-status">
                <img src={Verification_Tick} /> C2C Registration Verified
              </div>
            )}
          </Media.Body>
        </Media>
      </div>
      <ConsultationInformation
        duration={duration}
        fees={fees}
        procedureDetails={procedureDetails}
        selectedConsultationType={selectedConsultationType}
        consultationList={consultationList}
        consultationTypeList={consultationTypeList}
        doctorSlug={doctorSlug ? doctorSlug : routeParams.slug}
      />
      <div className="doctor-qualification-details">
        <div className="doc-info">
          <div className="d-flex ai-center mb-3">
            <img
              src={process.env.PUBLIC_URL + "/images/education-new.svg"}
              alt=""
            />
            <div className="ml-3 education-block">
              <div className="education-text">Education</div>
              <div>{doctorProfile.degree}</div>
            </div>
          </div>
          <div className="d-flex ai-center mb-3">
            <img
              src={process.env.PUBLIC_URL + "/images/hospital-new.svg"}
              alt=""
            />
            <div className="ml-3 about-block">
              <div className="about-text">Hospital</div>
              <div>{doctorProfile.about}</div>
            </div>
          </div>
          <div className="d-flex ai-center mb-3">
            <img
              src={process.env.PUBLIC_URL + "/images/about-new.svg"}
              alt=""
            />
            <div className="ml-3 about-block">
              <div className="about-text">About</div>
              <div style={{ wordBreak: "break-word" }}>
                {doctorProfile.description}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
