import React, { useState } from "react";
import "./styles.scss";
import Edit from "../../../src/assets/edit-icon1.svg";
import Done from "../../../src/assets/done.svg";
import Delete from "../../../src/assets/delete.svg";
import WhatsApp from "../../../src/assets/whatsapp_icon.svg";

export const WhatsAppContainer = (props) => {
  const {
    outerError,
    clearErrors,
    whatsAppNumber,
    setWhatsAppNumber,
    whatsappMessageEnable,
    setWhatsappMessageEnable,
    height,
    setShowTopMargin,
  } = props;

  const [showEditWhatsApp, setShowEditWhatsApp] = useState(false);
  const [error, setError] = useState(false);
  const [number, setNumber] = useState(whatsAppNumber);

  const handleWhatsAppDisplay = () => {
    setWhatsappMessageEnable(!whatsappMessageEnable);
  };

  const handleChangeNumber = (e) => {
    setNumber(e.target.value);
    const phoneNumberRegex = RegExp(/^[6-9]\d{9}$/gi);
    if (!phoneNumberRegex.test(e)) {
      clearErrors && clearErrors("whatsAppNumber");
      setError(false);
    }
  };

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  const showEditWhatsAppNumber = () => {
    return (
      <span className="room-name pl-3 ">
        * notifications will be sent to{" "}
        <span className="whatsapp_number_span"> +91{whatsAppNumber}</span>
        <img
          src={Edit}
          alt="Edit Receipt"
          className="ml-2 cursor-pointer"
          onClick={() => {
            setShowEditWhatsApp(true);
            setShowTopMargin && setShowTopMargin(true);
          }}
        />
      </span>
    );
  };

  const showEditWhatsAppNumberInput = () => {
    return (
      <div class="input-group-prepend whatsapp_width">
        <span
          class="input-group-text phstyle"
          style={{ height: height ? `${height}px` : "45px" }}
        >
          +91
        </span>
        <input
          type="number"
          className="whatsapp_edit_input pl-3"
          onInput={maxLengthCheck}
          onKeyDown={(e) =>
            (e.keyCode === 69 ||
              e.keyCode === 187 ||
              e.keyCode === 61 ||
              e.keyCode === 190 ||
              e.keyCode === 189) &&
            e.preventDefault()
          }
          value={number}
          maxlength="10"
          onChange={handleChangeNumber}
        />
        <div className="img_div">
          <img
            src={Delete}
            alt="delete"
            className="action-button-delete pr-2 cursor-pointer"
            onClick={() => {
              setWhatsAppNumber(whatsAppNumber);
              setShowEditWhatsApp(false);
              setShowTopMargin && setShowTopMargin(false);
              setError(false);
            }}
          />
          <span></span>
          <img
            src={Done}
            alt="done"
            className="action-button-done pr-2 pl-2 cursor-pointer"
            onClick={() => {
              const regexExp = /^[6-9]\d{9}$/gi;
              const valid = regexExp.test(number);
              if (valid) {
                setWhatsAppNumber(number);
                setShowEditWhatsApp(false);
                setShowTopMargin && setShowTopMargin(false);
                setError(false);
              } else {
                setError(true);
              }
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="row mt-3">
        <div className="col-12 whatsapp_div">
          <div
            className="d-flex align-items-center hover-pointer cursor-pointer"
            onClick={handleWhatsAppDisplay}
          >
            <label
              className="whatsapp_checkbox hover-pointer cursor-pointer"
              style={
                whatsappMessageEnable ? { backgroundColor: "#17bae0" } : null
              }
            >
              <span>
                {whatsappMessageEnable ? (
                  <i
                    className="fa fa-check facheck cursor-pointer"
                    style={{ fontSize: 13 }}
                  ></i>
                ) : null}
              </span>
            </label>
            <span className="fnt-14 whatsapp_ntfcn">
              Get notifications on WhatsApp
            </span>
            <img src={WhatsApp} alt="whatsapp" className="pr-2" />
          </div>
        </div>
      </div>
      <div className="row whatsapp_edit_div">
        {!showEditWhatsApp
          ? showEditWhatsAppNumber()
          : showEditWhatsAppNumberInput()}
      </div>
      {!outerError && error && (
        <div
          style={{ color: " #F969A4", font: "normal normal 500 12px Rubik" }}
        >
          Please enter valid mobile number.
        </div>
      )}
    </>
  );
};
