import React, { Component } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Footer from "./Footer";
import afterLoginApiAxios from "../Util/API Calls/afterLogin";
import { LOCALSTORAGEKEY } from "../Util/Helper/localStorageUtils";
import { C2CConsole } from "../Util/Helper/C2CConsole";

var Razorpay;

class PaymentSuccess extends Component {
  constructor(props, context) {
    super(props, context);
    this.pay = this.pay.bind(this);
    var type = this.props.match.params.doctorType;
    this.state = {
      docType: type,
      txnid: "",
      key: "",
      amount: "",
      firstname: "",
      email: "",
      phone: "",
      productinfo: "",
      surl: "",
      furl: "",
      udf5: "",
      hash: "",
      start: "",
      date: "",
      doctor: "",
      category: "",
      transaction_id: "",
    };
  }
  componentWillMount() {
    var txnid = localStorage.getItem("txnid");
    var key = localStorage.getItem("key");
    var amount = localStorage.getItem("amount");
    var firstname = localStorage.getItem("firstname");
    var email = localStorage.getItem("email");
    var phone = localStorage.getItem("phone");
    var productinfo = localStorage.getItem("productinfo");
    var surl = localStorage.getItem("surl");
    var furl = localStorage.getItem("furl");
    var udf5 = localStorage.getItem("udf5");
    var hash = localStorage.getItem("hash");

    var start = localStorage.getItem("start");
    var date = localStorage.getItem("date");
    var doctor = localStorage.getItem("doctor");
    var category = localStorage.getItem("category");

    this.setState(
      {
        txnid: txnid,
        key: key,
        amount: amount,
        firstname: firstname,
        email: email,
        phone: phone,
        productinfo: productinfo,
        surl: surl,
        furl: furl,
        udf5: udf5,
        hash: hash,
        start: start,
        date: date,
        doctor: doctor,
        category: category,
      },
      () => {}
    );
  }
  componentWillUnmount() {
    localStorage.setItem("txnid", "");
    localStorage.setItem("key", "");
    localStorage.setItem("amount", "");
    localStorage.setItem("firstname", "");
    localStorage.setItem("email", "");
    localStorage.setItem("phone", "");
    localStorage.setItem("productinfo", "");
    localStorage.setItem("surl", "");
    localStorage.setItem("furl", "");
    localStorage.setItem("udf5", "");
    localStorage.setItem("hash", "");

    localStorage.setItem("start", "");
    localStorage.setItem("date", "");
    localStorage.setItem("doctor", "");
    localStorage.setItem("category", "");

    ////props to local storage//////
    localStorage.setItem("doctorData", "");
    localStorage.setItem("schedule_sid", "");
    localStorage.setItem("schedule_start", "");
    localStorage.setItem("schedule_date", "");
    localStorage.setItem("schedule_doctor", "");
    localStorage.setItem("schedule_new_user", "");
    localStorage.setItem("existing_user_book_new_account", "");
    localStorage.setItem("existing_user_book_patient", "");
    localStorage.setItem("existing_user_book_patient_dob", "");
    localStorage.setItem("existing_user_book_is_follow_up", "");
    ///end props to local storage///////
  }
  pay() {
    var pd = {
      key: this.state.key,
      txnid: this.state.txnid,
      hash: this.state.hash,
      amount: this.state.amount,
      firstname: this.state.firstname,
      email: this.state.email,
      phone: this.state.phone,
      productinfo: this.state.productinfo,
      udf5: this.state.udf5,
      surl: this.state.surl,
      furl: this.state.furl,
      //	mode:'dropout'
    };
    let self = this;
    self.redirectToPayU(pd);
  }
  redirectToPayU(pd) {
    var self = this;
    var Handler = {
      responseHandler: function (BOLT) {
        // your payment response Code goes here, BOLT is the response object
        var data = {};
        var transaction_id = BOLT.response.txnid;
        data = BOLT.response;
        if (transaction_id) {
          afterLoginApiAxios
            .post("public/verify-payu-hash-response", {
              transaction_id: transaction_id,
            })
            .then((response) => {
              if (response) {
                if (response.data.hasOwnProperty("statusCode")) {
                  if (response.data.statusCode == 200) {
                    localStorage.setItem("bolt_txnid", BOLT.response.txnid);
                    if (
                      response.data.payment_status == "SUCCESS" ||
                      response.data.payment_status == "PENDING"
                    ) {
                      localStorage.removeItem(LOCALSTORAGEKEY.CLINIC_ID);
                      self.props.history.push("/thankyou");
                    } else {
                      localStorage.removeItem(LOCALSTORAGEKEY.CLINIC_ID);
                      self.props.history.push("/failed");
                    }
                  } else {
                    let message = response.data.message;
                    swal({
                      title: "Failed!",
                      text: message,
                      type: "error",
                      icon: "warning",
                    });
                  }
                } else {
                  let message = "Something Went Wrong!!Try Again";
                  swal({
                    title: "Failed!",
                    text: message,
                    type: "error",
                    icon: "warning",
                  });
                }
              } else {
                let message = "Something Went Wrong!!Try Again";
                swal({
                  title: "Failed!",
                  text: message,
                  type: "error",
                  icon: "warning",
                });
              }
            })
            .catch(function (error) {
              C2CConsole.log(error);
            });
        }
      },
      catchException: function (BOLT) {
        // the code you use to handle the integration errors goes here
        let message = "Something Went Wrong!!Try Again";
        swal({
          title: "Failed!",
          text: message,
          type: "error",
          icon: "warning",
        });
      },
    };
    //use window.bolt.launch if you face an error in bolt.launch
    window.bolt.launch(pd, Handler);
  }
  render() {
    var styles = {
      cardOdd: {
        backgroundColor: "#eeae01",
        color: "#454545",
      },
      cardEven: {
        backgroundColor: "#454545",
        color: "#eeae01",
      },
    };
    return (
      <div>
        <div className="row mt-4">
          <div className="col-sm-12 col-md-4 text-center">
            <img
              src={process.env.PUBLIC_URL + "/images/HN_logo.png"}
              style={{ width: "70%" }}
            />
          </div>
          <div className="col-sm-12 col-md-4 menuItems text-left pt-4">
            <Link to="/" className="mr-5" style={{ color: "#2f2f2f" }}>
              Home
            </Link>
          </div>
          <div className="col-sm-12 col-md-4 text-center loginButton pt-2"></div>
        </div>
        <div className="container footerSection mt-4">
          <div className="row mb-5" style={{ marginTop: "5vh" }}>
            <div
              className="col-sm-12 col-lg-6 offset-lg-3 text-center"
              style={{ paddingTop: "8vh" }}
            >
              <h2>
                <strong>
                  <b>
                    Review & <span style={{ color: "#eeae01" }}>Pay</span>
                  </b>
                </strong>
              </h2>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-12 col-lg-3 offset-lg-3 text-left">
              Appointment with :
            </div>
            <div className="col-sm-12 col-lg-4  text-left">
              {this.state.doctor}
            </div>
            <div className="col-sm-12 col-lg-3 offset-lg-3 text-left">
              Physician Type :
            </div>
            <div className="col-sm-12 col-lg-4  text-left">
              {this.state.category}
            </div>
            <div className="col-sm-12 col-lg-3 offset-lg-3 text-left">
              Appointment Date :
            </div>
            <div className="col-sm-12 col-lg-4  text-left">
              {this.state.date}
            </div>
            <div className="col-sm-12 col-lg-3 offset-lg-3 text-left">
              Appointment Time :
            </div>
            <div className="col-sm-12 col-lg-4  text-left">
              {this.state.start}
            </div>
            <div className="col-sm-12 col-lg-3 offset-lg-3 text-left">
              Appointment Mode :
            </div>
            <div className="col-sm-12 col-lg-4  text-left">
              Video Conference only
            </div>
            {/* <div className="col-sm-12 col-lg-3 offset-lg-3 text-left">
							Payment ID :
						</div>
						<div className="col-sm-12 col-lg-4  text-left">
							vduw3678hdubjs
						</div> */}
            <div className="col-sm-12 col-lg-3 offset-lg-3 text-left">
              Payment Amount :
            </div>
            <div className="col-sm-12 col-lg-4  text-left">
              Rs. {this.state.amount}
            </div>
            <div className="col-sm-12 col-lg-6 offset-lg-3 text-left mt-3">
              ** Advised to login 5mins earlier to connect with your doctor.
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm-12 col-lg-4 offset-lg-4 text-center">
              {/*
						 <Link className="btn btn-lg mt-2" style={{color:'#454545',backgroundColor:'#eeae01',padding:'8px 70px'}} to="/patientlogin"><b>Login</b></Link>
						 */}
              <button
                id="rzp-button1"
                className="btn btn-cn mb-4"
                style={{ borderRadius: "40px" }}
                onClick={this.pay}
              >
                Pay to confirm
              </button>
            </div>
          </div>
        </div>
        <Footer />
        <div
          className="modal fade"
          id="registerModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Patient Details
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-12 text-left">
                    <label>Patient Name</label>
                    <input type="text" name="phone" className="w-100" />
                  </div>
                  <div className="col-sm-12 text-left">
                    <label>Date of Birth</label>
                    <input type="date" name="phone" className="w-100" />
                  </div>
                  <div className="col-sm-12 text-left">
                    <label>Gender</label>&nbsp;
                    <input type="radio" name="male" className="mr-1" />
                    Male
                    <input type="radio" name="female" className="mr-1" />
                    Female
                  </div>
                  <div className="col-sm-12 text-left">
                    <label>City</label>
                    <input type="text" name="city" className="w-100" />
                  </div>
                  <div className="col-sm-12 text-left">
                    <label>Phone No.</label>
                    <input type="text" name="phone" className="w-100" />
                  </div>
                  <div className="col-sm-12 text-left">
                    <Link
                      className="btn btn-sm mt-2"
                      style={{ color: "#ffffff", backgroundColor: "#454545" }}
                      data-toggle="modal"
                      data-target="#registerModal"
                    >
                      <b>Send OTP</b>
                    </Link>
                  </div>
                  <div className="col-sm-12">
                    <label>OTP</label>
                    <input type="text" name="otp" className="w-100" />
                  </div>
                  <div className="col-sm-4">
                    <Link
                      className="btn btn-sm mt-2"
                      style={{ color: "#454545", backgroundColor: "#eeae01" }}
                      data-toggle="modal"
                      data-target="#registerModal"
                    >
                      <b>Confirm & Pay</b>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PaymentSuccess;
