import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { Col } from "react-bootstrap";

import afterLoginApiAxiosPEM from "../PEMAfterLogin";
import searchIcon from "../../../assets/search_icon.svg";
import AddContentSearchBar from "../common/AddContentSearchBar";
import { Link } from "@material-ui/core";

const SuggestedKeywords = (props) => {
  const [showMoreActive, setshowMoreActive] = useState(false);
  const [searchEnable, setsearchEnable] = useState(false);
  const [allKeywords, setallKeywords] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [filterKeywords, setfilterKeywords] = useState([]);
  const { selectedKeyword, pemSuggestedKeyFromPrescription } = props;

  // LifeCycle ==>
  useEffect(() => {
    fetchAllKeywords();
  }, []);

  // <==

  // API Calls ==>
  const fetchAllKeywords = async () => {
    const res = await afterLoginApiAxiosPEM({
      method: "get",
      url: "doctor/get-all-keywords",
      params: {
        specialization_id: localStorage.getItem("specialization"),
      },
    });

    if (res && res.data && res.data.response) {
      // setallKeywords(res.data.response);
      const filterKeywords = [];
      res.data.response.forEach((keyword, index, keys) => {
        if (props.rxKeywords.includes(keyword.title)) {
          filterKeywords.push(keyword);
          keys.splice(index, 1);
        }
      });
      const allKeywords = res.data.response[0];
      res.data.response.splice(0, 1);
      const orderedKeywords = [
        allKeywords,
        ...filterKeywords,
        ...res.data.response,
      ];
      if (filterKeywords.length > 0) {
        setfilterKeywords(props.rxKeywords);
      } else {
        setfilterKeywords([]);
      }
      if (res.data.response[0]) {
        setallKeywords(orderedKeywords);
      }
    }
  };

  // <==

  // callbacks ==>
  const backBtnCallback = (data) => {
    setsearchEnable(false);
    props.searchDataCallback(data);
  };

  const searchDataCallback = (data) => {
    props.searchDataCallback(data);
  };
  // <==

  // actions ==>
  const showMoreBtnClick = () => {
    setshowMoreActive(false);
  };
  // <==

  // create UI ==>
  const renderKeyword = () => {
    if (allKeywords) {
      return allKeywords.map((content, idx) => {
        if (idx > 8 && !showMore) {
          return;
        }
        if (idx > 7 && !showMore) {
          return (
            <Link
              style={{
                color: "#17bae0",
                cursor: "pointer",
                whiteSpace: "nowrap",
              }}
              onClick={() => setShowMore(true)}
            >
              See More
            </Link>
          );
        }
        return (
          <div
            className={`${
              selectedKeyword?.toLowerCase() === content?.title?.toLowerCase()
                ? "selected-keyword ml-3"
                : pemSuggestedKeyFromPrescription?.length > 0
                ? pemSuggestedKeyFromPrescription?.includes(
                    content.title?.toLowerCase()
                  )
                  ? `active-keyword ${idx === 0 ? "ml-3" : ""}`
                  : `inactive-keyword ${idx === 0 ? "ml-3" : ""}`
                : `active-keyword ${idx === 0 ? "ml-3" : ""}`
            } active-keyword-extra`}
            key={idx}
            onClick={() => {
              props.clickOnKeyword(content.title);
            }}
          >{`${content.title}(${content.count})`}</div>
        );
      });
    }
  };

  const renderSuggestedKeywords = () => {
    return (
      <div
        className={`suggested-keywords-list ${
          showMoreActive
            ? "suggested-keywords-list-active"
            : "suggested-keywords-list-inactive"
        }`}
      >
        <Stack direction="row" spacing={2} style={{ display: "-webkit-box" }}>
          {renderKeyword()}
        </Stack>
      </div>
    );
  };

  const renderSearchUI = () => {
    return (
      <Col>
        <AddContentSearchBar
          placeholderContent="Search for patient content"
          backBtnCallback={backBtnCallback}
          searchDataCallback={searchDataCallback}
        />
      </Col>
    );
  };
  // <==

  return (
    <div>
      <div className="suggested-keywords-header">
        {!searchEnable && (
          <>
            <h1 style={{ flex: "1" }}>Suggested Keywords</h1>
            <img
              src={searchIcon}
              alt=""
              onClick={() => setsearchEnable(true)}
            />
          </>
        )}
      </div>
      {searchEnable ? renderSearchUI() : renderSuggestedKeywords()}
    </div>
  );
};

export default SuggestedKeywords;
