import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import FilledInput from "@material-ui/core/FilledInput";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { useHistory, Redirect, useLocation } from "react-router-dom";
import { useTimer } from "../../Hooks/useTimer";
import swal from "sweetalert";
import beforeLoginAxios from "../../Util/API Calls/beforeLogin";
import { useForm } from "react-hook-form";
import Spinner from "react-bootstrap/Spinner";
import { QualificationList } from "../common/QualificationsList";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";

function EnterDoctorDetails() {
  let history = useHistory();
  let location = useLocation();

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    //C2CConsole.log(data);
  };

  const [specializationList, setSpecializationList] = useState([]);
  const [qualification, setQualification] = useState("");
  const [qualificationFreeText, setQualificationFreeText] = useState("");
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    beforeLoginAxios
      .get("public/get-doctor-categories")
      .then((response) => {
        if (response.data.statusCode === 200) {
          setSpecializationList(response.data.categories);
        }
      })
      .catch((e) => {
        if (e.status === 403) {
          swal({
            title: "Server Error",
            type: "warning",
          }).then(function () {
            history.replace("/doctor/login/sign-up");
          });
        }
      });
  }, []);

  const handleQualification = (e) => {
    setQualification(e.target.value);
  };

  const handleQualificationFreeText = (e) => {
    setQualificationFreeText(e.target.value);
  };

  const switchToDropdown = () => {
    setQualification("");
    setQualificationFreeText("");
  };

  const displayErrorText = (fieldName, errorText) => {
    if (errors[fieldName]["type"] === "pattern") {
      return `Numbers, special characters not allowed in ${fieldName.toLowerCase()}`;
    } else if (errors[fieldName]["type"] === "maxLength") {
      return `${errorText} can contain max length of up to 255 characters`;
    } else {
      return `${errorText} is required`;
    }
  };

  const handleContinueClick = (data) => {
    setLoading(true);

    beforeLoginAxios
      .post("public/save-new-doctor-details", {
        "authorizer-principal-id": {
          user_type: "1",
        },
        zipcode: data["Pin Code"],
        first_name: data["First Name"],
        last_name: data["Last name"],
        degree: data["Educational Qualification"]
          ? data["Educational Qualification"]
          : qualificationFreeText,
        email: data["Email"],
        specialization: data["Specialization"],
        phone_no: `+91${location.state.phoneNumber}`,
        password: location.state.passwordInput,
        otp: location.state.otpInput,
      })
      .then((response) => {
        if (response) {
          if (response.data && response.data.statusCode === 200) {
            setLoading(false);
            localStorage.setItem("id_token", response.data.user.id_token);
            localStorage.setItem(
              LOCALSTORAGEKEY.USER_ID,
              response.data.user.user_id
            );
            localStorage.setItem("user_type", response.data.user.user_type);
            localStorage.setItem(
              "doctorPhone",
              `+91${location.state.phoneNumber}`
            );
            localStorage.setItem("onBoardedDoctor", true);
            history.replace(
              "/doctor/account/dashboard?utm_source=webapp&utm_medium=onboarding"
            );
            swal({
              title: "Login Successful!",
              type: "success",
            }).then(function () {});
          } else {
            setLoading(false);
            swal({
              title: "Registration Failed",
              text: "Please Contact the C2C support team.",
              type: "Warning",
            }).then(function () {
              history.replace("/doctor/login/");
            });
          }
        }
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  try {
    const { phoneNumber = "", passwordInput = "" } = location.state;
    if (phoneNumber == "") {
      swal({
        title: "Something went wrong",
        type: "warning",
      });
      return <Redirect to="/doctor/login/sign-up" />;
    }
    if (passwordInput == "") {
      swal({
        title: "Something went wrong",
        type: "warning",
      });
      return <Redirect to="/doctor/login/sign-up" />;
    }
  } catch (error) {
    return <Redirect to="/doctor/login/sign-up" />;
  }

  return (
    <div
      className="container pt-1 h-100 d-flex align-items-center justify-content-center flex-column"
      style={{ position: "relative" }}
    >
      <div>
        <h3 className="text-center align-top">Get Started</h3>
      </div>
      <h6 className="text-center">Register Your Virtual Clinic</h6>
      <div className="w-100 mt-2">
        <form
          onSubmit={handleSubmit(handleContinueClick)}
          className="d-flex flex-column align-items-center justify-content-center"
        >
          <input
            className={
              errors["First Name"]
                ? "input-style-error mt-2"
                : "input-style mt-2"
            }
            type="text"
            placeholder="First Name*"
            name="First Name"
            ref={register({
              required: true,
              maxLength: 255,
              pattern: /^([a-zA-Z]+\s)*[a-zA-Z]+$/,
            })}
          />
          {errors["First Name"] && (
            <span className="input-error">
              <small>{displayErrorText("First Name", "First name")}</small>
            </span>
          )}

          <input
            className={
              errors["Last name"]
                ? "input-style-error mt-2"
                : "input-style mt-2"
            }
            type="text"
            placeholder="Last name*"
            name="Last name"
            ref={register({
              required: true,
              maxLength: 255,
              pattern: /^([a-zA-Z]+\s)*[a-zA-Z]+$/,
            })}
          />
          {errors["Last name"] && (
            <span className="input-error text-smaller">
              <small>{displayErrorText("Last name", "Last name")}</small>
            </span>
          )}

          {qualification !== "Others" ? (
            <select
              className="mt-2"
              style={{ height: "40px" }}
              name="Educational Qualification"
              placeholder="Educational Qualification*"
              ref={register({ required: true })}
              value={qualification}
              onChange={(e) => handleQualification(e)}
            >
              <option value="" disabled hidden className="text-muted">
                Educational Qualification
              </option>
              {QualificationList.map((val, ind) => (
                <option value={val.value} key={ind}>
                  {val.value}
                </option>
              ))}
            </select>
          ) : (
            <div
              className="mt-2 d-flex align-items-center education-qualification"
              style={{
                border: errors["Educational Qualification"]
                  ? "1px solid #e84444"
                  : "",
              }}
            >
              <input
                className="input-style1"
                type="text"
                placeholder="Educational Qualification*"
                name="Educational Qualification"
                ref={register({ required: true })}
                value={qualificationFreeText}
                autoFocus
                onChange={(e) => handleQualificationFreeText(e)}
                style={{
                  height: errors["Educational Qualification"] ? "38px" : "",
                }}
              />
              <div className="close_serch" style={{ opacity: "1" }}>
                <img
                  src={require("../../assets/forhomepage/close_icon.svg")}
                  onClick={switchToDropdown}
                />
              </div>
            </div>
          )}
          {errors["Educational Qualification"] && (
            <span className="input-error text-smaller ">
              <small>Educational Qualification is Mandatory</small>
            </span>
          )}
          <select
            className="mt-2"
            style={{ height: "40px" }}
            name="Specialization"
            ref={register({ required: true })}
          >
            <option value="" disabled selected>
              Specialization
            </option>
            {specializationList.map((x) => (
              <option key={x.id} value={x.display_name}>
                {x.display_name}
              </option>
            ))}
          </select>
          {errors.Specialization && (
            <span className="input-error text-smaller ">
              <small>Specialization is required</small>
            </span>
          )}
          <input
            className={
              errors["Educational Qualification"]
                ? "input-style-error mt-2"
                : "input-style mt-2"
            }
            type="text"
            placeholder="Email*"
            name="Email"
            ref={register({ required: true, pattern: /^\S+@\S+$/i })}
          />
          {errors.Email && (
            <span className="input-error text-smaller ">
              <small>Please Enter a valid Email Address</small>
            </span>
          )}
          <input
            className={
              errors["Educational Qualification"]
                ? "input-style-error mt-2"
                : "input-style mt-2"
            }
            type="text"
            placeholder="Pin Code*"
            name="Pin Code"
            ref={register({
              required: true,
              maxLength: 6,
              pattern: /[0-9]{6}/i,
            })}
          />
          {errors["Pin Code"] && (
            <span className="input-error text-smaller ">
              <small>Pin Code is required</small>
            </span>
          )}
          <button
            type="submit"
            className="btn primary-cta-button rounded-pill mt-2 align-self-center mt-4 py-2 px-4"
          >
            {loading ? <Spinner animation="border" /> : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default EnterDoctorDetails;

const Edit = () => {
  let history = useHistory();
  return (
    <span
      className="color-blue cursor-pointer"
      onClick={() => {
        history.replace("/doctor/login/sign-up");
      }}
    >
      Edit
      <EditIcon className="color-blue cursor-pointer" fontSize="inherit" />
    </span>
  );
};

function ShowPassword(props) {
  const { setShowPassword, showPassword = false } = props;

  if (showPassword === false) {
    return (
      <span
        className="color-blue cursor-pointer"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        <VisibilityIcon />
      </span>
    );
  } else {
    return (
      <span
        className="color-blue cursor-pointer"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        <VisibilityOffIcon />
      </span>
    );
  }
}
