import React from "react";
import GlobalFooter from "../GlobalFooter";
import Header from "../Header";
import InvestorsWeb from "../../assets/investors-web.png";
import Next from "../../assets/Next.png";
import InvestorsMob from "../../assets/investors-mob.png";
import "../Investors/style.css";
import Pdf from "../../assets/Annual-Return-2020-21.pdf";
import useDisableHelp from "../../Hooks/useDisableHelp";
import { QA, UAT, PROD, LOCALENV } from "../common/config";
import { siteURL } from "../../Util/API Calls/APIList";

const Investors = () => {
  useDisableHelp();

  const envConstant =
    window.location.hostname === "localhost"
      ? LOCALENV
      : process.env.REACT_APP_ENV;

  const getLink1 = () => {
    switch (envConstant) {
      case QA:
      case UAT:
      case PROD:
        return "/static/media/Form-MGT7-Annual-Return-2020-21.pdf";
      default:
        return "https://qa.c2cassets.com/static/media/Form-MGT7-Annual-Return-2020-21.pdf";
    }
  };

  const getLink2 = () => {
    switch (envConstant) {
      case QA:
      case UAT:
      case PROD:
        return "/static/media/Form-MGT7-Annual-Return-2021-22.pdf";
      default:
        return "https://qa.c2cassets.com/static/media/Form-MGT7-Annual-Return-2021-22.pdf";
    }
  };

  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row d-flex flex-column align-items-center justify-content-center">
          <div className="investors w-100 px-0 py-2 mx-0 col-sm-12 col-lg-4 col-md-6">
            <div className="investors-wrapper" style={{ textAlign: "center" }}>
              <b>Investors</b>
              <p>Download the annual returns.</p>
              <div className="d-flex flex-column">
                <a href={getLink1()} target="_blank">
                  Form MGT 7 - Annual Return 2020-21
                  <img src={Next} alt="InvestorsWeb" />
                </a>
                <a href={getLink2()} target="_blank">
                  Form MGT 7 - Annual Return 2021-22
                  <img src={Next} alt="InvestorsWeb" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-8 mx-0 px-0">
            <div className="investors-doctor-web custom-item">
              <picture>
                <source srcSet={InvestorsWeb} />
                <img src={InvestorsWeb} alt="InvestorsWeb" />
              </picture>
            </div>
            <div className="investors-doctor-mob custom-item">
              <picture>
                <source srcSet={InvestorsMob} />
                <img src={InvestorsMob} alt="InvestorsMob" />
              </picture>
            </div>
          </div>
        </div>
      </div>
      {/* <GlobalFooter /> */}
    </div>
  );
};
export default Investors;
