import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import QuestionList from "./QuestionList";
import "../styles/TabsParent.scss";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Consultation from "../../assets/selected/Consultation.svg";
import Compliance from "../../assets/selected/Compliance.svg";
import GettingStarted from "../../assets/selected/Getting Started.svg";
import Appointments from "../../assets/selected/Appointments.svg";
import Prescription from "../../assets/selected/Prescriptions.svg";
import Payments from "../../assets/selected/Payments.svg";
import Troubleshooting from "../../assets/selected/Troubleshooting.svg";
import Security from "../../assets/selected/Security.svg";
import Onboarding from "../../assets/selected/Onboarding.svg";
import Icon from "@material-ui/core/Icon";
import Consultationfaded from "../../assets/unselected/consultationfaded.svg";
import Compliancefaded from "../../assets/unselected/Compliancefaded.svg";
import GettingStartedfaded from "../../assets/unselected/GettingStartedfaded.svg";
import Appointmentsfaded from "../../assets/unselected/Appointmentsfaded.svg";
import Prescriptionfaded from "../../assets/unselected/Prescriptions.svg";
import Paymentsfaded from "../../assets/unselected/Payments.svg";
import Troubleshootingfaded from "../../assets/unselected/Troubleshooting.svg";
import Securityfaded from "../../assets/unselected/Security.svg";
import Onboardingfaded from "../../assets/unselected/Onboarding.svg";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiTab: {
      // Name of the rule
      wrapper: {
        // Some CSS
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "flex-start",
        fontSize: "1.2rem",
        //fontWeight:"600"
      },
      textColorPrimary: {
        "&$selected": { color: "black" },
      },
    },
    MuiAppBar: {
      colorTransparent: {
        padding: "0px",
      },
    },
    MuiTabs: {
      indicator: {
        color: "green",
      },
    },
    PrivateTabIndicator: {
      colorPrimary: {
        backgroundColor: "pink",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    flexDirection: "row",
    padding: "0px",
    justifyContent: "center",
  },
  paper: {
    boxShadow: "none",
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
  },
  indicator: {
    backgroundColor: "red",
  },
}));

export default function TabWithHorizontalScroll(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const run = (cn, index) => {
    if (cn.toLowerCase() == "consultation")
      return (
        <div className="marginIcon-tabsparent">
          <Icon>
            <img src={index === value ? Consultation : Consultationfaded} />
          </Icon>
        </div>
      );

    if (cn.toLowerCase() == "compliance")
      return (
        <div className="marginIcon-tabsparent">
          <Icon>
            <img src={index === value ? Compliance : Compliancefaded} />
          </Icon>
        </div>
      );

    if (cn.substring(0, 3).toLowerCase() == "get")
      return (
        <div className="marginIcon-tabsparent">
          <Icon>
            <img src={index === value ? GettingStarted : GettingStartedfaded} />
          </Icon>
        </div>
      );

    if (cn.toLowerCase() == "appointments")
      return (
        <div className="marginIcon-tabsparent">
          <Icon>
            <img src={index === value ? Appointments : Appointmentsfaded} />
          </Icon>
        </div>
      );

    if (cn.toLowerCase() == "prescription")
      return (
        <div className="marginIcon-tabsparent">
          <Icon>
            <img src={index === value ? Prescription : Prescriptionfaded} />
          </Icon>
        </div>
      );

    if (cn.toLowerCase() == "payments")
      return (
        <div className="marginIcon-tabsparent">
          <Icon>
            <img src={index === value ? Payments : Paymentsfaded} />
          </Icon>
        </div>
      );

    if (cn.toLowerCase() == "troubleshooting")
      return (
        <div className="marginIcon-tabsparent">
          <Icon>
            <img
              src={index === value ? Troubleshooting : Troubleshootingfaded}
            />
          </Icon>
        </div>
      );

    if (cn.toLowerCase() == "security")
      return (
        <div className="marginIcon-tabsparent">
          <Icon>
            <img src={index === value ? Security : Securityfaded} />
          </Icon>
        </div>
      );
    if (cn.toLowerCase() == "onboarding")
      return (
        <div className="marginIcon-tabsparent">
          <Icon>
            <img src={index === value ? Onboarding : Onboardingfaded} />
          </Icon>
        </div>
      );
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <AppBar position="static" color="transparent" elevation={0}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs"
          >
            {props.tabData.map((x, index) => (
              <Tab
                icon={
                  x.categoryName !== undefined ? run(x.categoryName, index) : ""
                }
                label={x.categoryName}
                {...a11yProps(index)}
                key={index}
              />
            ))}
          </Tabs>
        </AppBar>
        {props.tabData.map((x, index) => (
          <TabPanel value={value} index={index} key={index}>
            <div className="header-categories">Related Questions</div>
            {x.queries.map((query, localIndex) => (
              <QuestionList
                key={localIndex}
                queryquestion={query.question}
                queryanswer={query.answer}
                activateKey={localIndex}
              />
            ))}
          </TabPanel>
        ))}
      </div>
    </ThemeProvider>
  );
}
