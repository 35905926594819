//dependies import
import React from "react";
import Dialog from "@mui/material/Dialog";
import { withStyles } from "@material-ui/styles";
//assset/scss import
import "./learnMorePopup.scss";
import crossIcon from "../../../../assets/crossIcon.svg";
//project files import
import { LEARNMOREPOPUP, POPUPCONTAINT } from "./constant";

const styles = {
  dialogPaper: {
    width: window.innerWidth < 768 ? "414px" : "580px",
    padding: "20px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "10px",
  },
};

const LearnMorePopup = ({
  showPopupFlag,
  isPopup = true,
  handleClosePopup,
  classes,
}) => {
  //create UI ==>

  const popupHeader = () => {
    return (
      <div className="learn-more-popup-containt-details">
        <div className="learn-more-popup-containt">
          {POPUPCONTAINT.TITLECONTAINT}
        </div>
        <div onClick={handleClosePopup}>
          <img src={crossIcon} alt="" className="learn-more-popup-crossicon" />
        </div>
      </div>
    );
  };

  const popupBody = () => {
    return (
      <div className="c2c-learnmore-popup">
        {isPopup && <> {popupHeader()}</>}
        <div className="learn-more-popup-subcontaint">
          {POPUPCONTAINT.SUBTITLECONTAINT}
        </div>
        <div className="learn-more-popup-subcontaint-text">
          {POPUPCONTAINT.TEXTCONTAINT}
        </div>
        {LEARNMOREPOPUP.map((item, index) => {
          const marginLeftStyle =
            index === 2
              ? { marginLeft: "20px" }
              : index === 0
              ? { marginLeft: "-10px" }
              : null;
          return (
            <div key={index} className="main-popup-view-main">
              <div className="main-img-padding">
                <img
                  src={item.imageSrc}
                  alt=""
                  className="learn-more-popup-img"
                />
              </div>
              <div className="main-containt-padding">
                <div
                  className="main-containt-padding-sidedisplay"
                  style={marginLeftStyle}
                >
                  {item.title}
                </div>
                <div
                  className="main-containt-padding-sidedisplay-text"
                  style={marginLeftStyle}
                >
                  {item.description}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {isPopup ? (
        <>
          <Dialog
            open={showPopupFlag}
            classes={{
              paper: classes.dialogPaper,
            }}
          >
            {popupBody()}
          </Dialog>
        </>
      ) : (
        <>{popupBody()}</>
      )}
    </>
  );
};

export default withStyles(styles)(LearnMorePopup);
