import React from "react";
import { Row, Col } from "react-bootstrap";
import "./UI Component/StarRating/starRating.scss";
import ModalCustom from "./UI Component/Modal/ModalCustomDesign";
import RedCross from "../assets/red_close.png";

export default function CantTakeFollowupConfirmationModal({
  show,
  cancelHandler,
  successHandler,
  text,
}) {
  return (
    <ModalCustom
      show={show}
      size={"lg"}
      className="modal-90w custom-modal-message-design"
    >
      <div
        id="star-rating-container-div"
        className="container container-eligible p60 p-4"
      >
        <Row className="mb-4 mt-5 m-0 message-body-div">
          <span className="w-100-imp text-center">
            <img
              src={RedCross}
              className="times-circle-icon mb-3"
              alt="Message"
            />
            <div className="message-title">
              You are not eligible for a follow up appointment
            </div>
            <div className="">Do you want to proceed with Regular Booking?</div>
          </span>
        </Row>

        <div className="d-flex p-0 mb-5">
          <div className="m-auto text-center">
            <button
              className="star-rating-button mr-2"
              onClick={() => {
                const clinic = sessionStorage.getItem("selectedClinic");
                sessionStorage.setItem("followUpSelectdClinic", clinic);
                sessionStorage.setItem(
                  "followUpConsultationType",
                  JSON.stringify({
                    id: 0,
                    label: "Tele-Consult Regular",
                    value: "regular",
                  })
                );
                successHandler();
              }}
            >
              Yes
            </button>

            <button className="star-cancel-button ml-2" onClick={cancelHandler}>
              No
            </button>
          </div>
        </div>
      </div>
    </ModalCustom>
  );
}
