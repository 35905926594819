import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button, Col, Modal } from "react-bootstrap";
import afterLogin from "../Util/API Calls/afterLogin";
import Loader from "./UI Component/Loader/Loader";
import Rebook from "./Patient/Rebook";
import { patientFiles } from "../Util/API Calls/APIList";
import moment from "moment";
import "./Admin/styles.css";
import DatePicker from "react-date-picker";
import afterLoginApiAxios from "../Util/API Calls/afterLogin";
import _ from "lodash";
import axios, { CancelToken, isCancel } from "axios";
import Row from "react-bootstrap/Row";
import swal from "sweetalert";
import unAuthorizedError from "./Errors/unAuthorizedError";
import PreviousAppoinmentRow from "./PreviousPatients/PreviousAppoinmentRow";
import Drawer from "@material-ui/core/Drawer";
import DocumentUpload from "./UploadDocument/DoucmentUpload";
import DocumentCotainer from "./UI Component/DocumentViewer/DocumentCotainer";
import { getSignedUrl } from "../Util/API Calls/SignedAPIUrl";
import CantTakeFollowupConfirmationModal from "./CantTakeFollowupConfirmationModal";
import {
  disableHelpButton,
  enableHelpButton,
} from "./common/DisableHelpButton/DisableHelpButton";
import { LOCALSTORAGEKEY } from "../Util/Helper/localStorageUtils";
import { C2CConsole } from "../Util/Helper/C2CConsole";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const monthShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

var FontAwesome = require("react-fontawesome");
class PreviousDoctors extends Component {
  constructor(props, context) {
    super(props, context);
    this.viewAppointment = this.viewAppointment.bind(this);
    this.closeAppointment = this.closeAppointment.bind(this);
    this.state = {
      URL: patientFiles,
      docType: "",
      appointments: null,
      openView: false,
      openReschedule: false,
      rescheduleAppt: null,
      patient: null,
      months: [
        "-",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      searchTerm: "",
      activeSearchTerm: "",
      filterDate: null,
      filteringByDate: false,
      dateSorted: false,
      showLoader: false,
      all_appointments: [],
      isCalendarOpen: false,
      number_of_pages: 0,
      activePage: 1,
      isCancelEventHappening: false,
      calendarChanged: false,
      activeStartDate: new Date(),
      loading: false,
      drawerType: "",
      drawerOpen: false,
      appointmentForDrawer: null,
      file: [],
      documentShow: false,
      selectedPatientIndex: -1,
      selectedFile: "",
      percentage: 0,
      docId: "",
      uploaded: false,
      clicked_delete: false,
      patient_prescription_url: [],
    };

    this.searchBarRef = React.createRef();
    this.calendarRef = React.createRef();
    this.logOut.bind(this);
    this.rescheduleModal = this.rescheduleModal.bind(this);
    this.closeReschedule = this.closeReschedule.bind(this);
    this.getDebouncedResults = _.debounce(this.handleSearch, 2000);
    this.cancelFileUpload = React.createRef();
    this.userId = localStorage.getItem(LOCALSTORAGEKEY.USER_ID);
  }

  rescheduleModal(appointment) {
    this.setState({
      rescheduleAppt: appointment,
      openReschedule: true,
    });
  }

  closeReschedule() {
    this.setState({
      rescheduleAppt: null,
      openReschedule: false,
    });
  }

  componentWillMount() {
    var token = localStorage.getItem("id_token");
    var user_type = localStorage.getItem("user_type");
    var user_name = localStorage.getItem("user_name");
    var full_name = localStorage.getItem("full_name");

    if (localStorage.getItem("id_token")) {
    } else {
      this.props.history.push("/patient/login");
    }
    this.setState({
      token: token,
      user_type: user_type,
      user_name: full_name,
      showLoader: true,
      showCantTakeFollowupModal: false,
    });

    this.fetchAppointments();
  }

  fetchAppointments() {
    afterLogin
      .post(
        "/patient/get-past-appointments",
        {
          upcoming: "false",
          rows_per_page: 10,
          page_no: 1,
          patient_id: this.userId,
        },
        {
          headers: {
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then((response) => {
        if (response) {
          var responseData = response.data;
          if (response.status == 403) {
            this.props.history.push("/patient/login");
          }
          if (responseData) {
            if (response.data.hasOwnProperty("statusCode")) {
              if (response.data.statusCode == 200) {
                this.setState({
                  appointments: response?.data?.past_appointments,
                  all_appointments: response?.data?.past_appointments,
                  number_of_pages: response?.data?.total_pages_count,
                  activePage: 1,

                  showLoader: false,
                });
              } else if (response.data.statusCode == 503) {
                this.setState({
                  appointments: null,
                  number_of_pages: 0,
                  showLoader: false,
                });
              } else if (response.data.statusCode == 403) {
                this.props.history.push("/patient/login");
              }
            } else {
              this.setState(
                {
                  response_message: "Something Went Wrong!Please Try Again!!",
                  dataload: false,
                },
                () => {}
              );
            }
          }
        }
      })
      .catch(function (error) {
        C2CConsole.log(error);
      });
  }

  logOut() {
    afterLoginApiAxios
      .post(
        "auth/user-logout",
        {
          user_token: `${localStorage.getItem("id_token")}`,
        },
        {
          headers: {
            Authorization: `${window.localStorage.getItem("id_token")}`,
          },
        }
      )
      .then((response) => {
        C2CConsole.log(response);
        localStorage.removeItem("id_token");
        localStorage.removeItem("user_type");
        localStorage.removeItem(LOCALSTORAGEKEY.USER_ID);
        localStorage.removeItem("user_name");
        localStorage.removeItem("full_name");
        this.props.history.push("/patient/login");
      })
      .catch((err) => {
        C2CConsole.log("error found");
        C2CConsole.log(err);
        swal("", "An error occured, logging out now!", "info").then(() => {
          localStorage.removeItem("id_token");
          localStorage.removeItem("user_type");
          localStorage.removeItem(LOCALSTORAGEKEY.USER_ID);
          localStorage.removeItem("user_name");
          localStorage.removeItem("full_name");
          this.props.history.push("/patient/login");
        });
      });
  }
  viewAppointment(appoint) {
    this.setState({
      openView: true,
      patient: appoint,
    });

    afterLoginApiAxios
      .post(
        "/patient/get-appointment-details-by-id",
        {
          appointment_id: appoint?.appointment_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then((response) => {
        this.setState({
          patient_prescription_url:
            response.data.appointment_details.patient_prescription_url,
        });
      })
      .catch(function (error) {
        C2CConsole.log(error);
      });
  }

  closeAppointment() {
    this.setState({
      openView: false,
    });
  }

  toggleDrawer() {
    this.setState({
      drawerOpen: !this.state.drawerOpen,
      drawerType: "",
      file: [],
      selectedPatientIndex: -1,
    });
  }

  handleDateOrder = () => {
    const appointments = [...this.state.appointments].reverse();
    const dateSorted = !this.state.dateSorted;
    this.setState({
      appointments,
      dateSorted,
    });
  };

  handleSearch(e, forceRefresh, bringToFocus) {
    if (e) {
      e.persist();
    }

    if (
      !this.state.filterDate &&
      this.state.searchTerm.trim().length === 0 &&
      this.state.searchTerm.trim() === this.state.activeSearchTerm &&
      forceRefresh === false
    ) {
      return;
    }

    this.setState({
      activeSearchTerm: this.state.searchTerm,
      showLoader: true,
    });

    let params = {};
    if (this.state.filterDate) {
      const date = new Date(this.state.filterDate);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = this.state.filterDate.getFullYear().toString();
      const appointment_date_query = [year, month, day].join("-");
      params["start_date"] = appointment_date_query;
    }
    if (this.state.activeSearchTerm.length != 0) {
      let activeSearchTerm = this.state.activeSearchTerm.toLowerCase();
      if (
        activeSearchTerm == "d" ||
        activeSearchTerm == "r" ||
        activeSearchTerm == "." ||
        activeSearchTerm == "dr" ||
        activeSearchTerm == "r."
      ) {
        activeSearchTerm = "";
      } else if (activeSearchTerm.indexOf("dr.") >= 0) {
        activeSearchTerm = activeSearchTerm.replace("dr.", "");
      }
      params["search_string"] = activeSearchTerm.trim();
    }
    afterLogin
      .post(
        "/patient/get-past-appointments",
        {
          ...params,
          upcoming: "false",
          rows_per_page: 10,
          page_no: 1,
          patient_id: this.userId,
        },
        {
          headers: {
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then((response) => {
        if (response) {
          var responseData = response.data;
          if (response.status == 403) {
            this.props.history.push("/patient/login");
          }
          if (responseData) {
            if (response.data.hasOwnProperty("statusCode")) {
              if (response.data.statusCode == 200) {
                this.setState({
                  appointments: response?.data?.past_appointments,
                  all_appointments: response?.data?.past_appointments,
                  number_of_pages: response?.data?.total_pages_count,
                  activePage: 1,
                  appointment_date_query: this.state.filterDate,
                  showLoader: false,
                });
              } else if (response.data.statusCode == 503) {
                this.setState({
                  appointments: null,
                  number_of_pages: 0,
                  showLoader: false,
                });
              } else if (response.data.statusCode == 403) {
                this.props.history.push("/patient/login");
              }
            } else {
              this.setState(
                {
                  response_message: "Something Went Wrong!Please Try Again!!",
                  dataload: false,
                },
                () => {}
              );
            }
            if (bringToFocus) {
              this.searchBarRef.current.focus();
            }
          }
        }
      })
      .catch(function (error) {
        C2CConsole.log(error);
      });
  }

  fetchPage() {
    let params = {};
    if (this.state.filterDate) {
      const date = new Date(this.state.filterDate);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = this.state.filterDate.getFullYear().toString();
      const appointment_date_query = [year, month, day].join("-");
      params["start_date"] = appointment_date_query;
      // params["appointment_date_query"] = this.state.filterDate;
    }

    if (this.state.activeSearchTerm.length != 0) {
      let activeSearchTerm = this.state.searchTerm.toLowerCase();
      if (
        activeSearchTerm == "d" ||
        activeSearchTerm == "r" ||
        activeSearchTerm == "." ||
        activeSearchTerm == "dr" ||
        activeSearchTerm == "r."
      ) {
        activeSearchTerm = "";
      } else if (activeSearchTerm.indexOf("dr.") >= 0) {
        activeSearchTerm = activeSearchTerm.replace("dr.", "");
      }
      params["search_string"] = activeSearchTerm.trim();
    }

    //WHEN NOT IN SEARCH MODE
    afterLogin
      .post(
        "/patient/get-past-appointments",
        {
          ...params,
          upcoming: "false",
          rows_per_page: 10,
          page_no: this.state.activePage,
          patient_id: this.userId,
        },
        {
          headers: {
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then((response) => {
        if (response) {
          var responseData = response.data;
          if (response.status == 403) {
            this.props.history.push("/patient/login");
          }
          if (responseData) {
            if (response.data.hasOwnProperty("statusCode")) {
              if (response.data.statusCode == 200) {
                // let sortedAppointments = !this.state.dateSorted
                //   ? response.data?.past_appointments
                //   : response.data?.past_appointments.reverse();
                this.setState({
                  appointments: response.data?.past_appointments,
                  all_appointments: response?.data?.past_appointments,
                  number_of_pages: response?.data?.total_pages_count,
                  showLoader: false,
                });
              } else if (response?.data?.statusCode == 503) {
                this.setState({
                  appointments: null,
                  number_of_pages: 0,
                  showLoader: false,
                });
              } else if (response?.data?.statusCode == 403) {
                this.props.history.push("/patient/login");
              }
            } else {
              this.setState(
                {
                  response_message: "Something Went Wrong!Please Try Again!!",
                  dataload: false,
                },
                () => {}
              );
            }
          }
        }
      })
      .catch(function (error) {
        C2CConsole.log(error);
      });
  }

  viewDocumentAction(appointment) {
    this.getAppoinmentDocument(appointment);
    this.setState({ appointmentForDrawer: { app_id: appointment } }, () => {
      this.setState({
        drawerOpen: true,
        drawerType: "Documents",
        file: [],
      });
    });
  }

  getAppoinmentDocument = (appointment) => {
    afterLoginApiAxios
      .post(
        // 'patient/get-document',
        "user/get-document-rev1",
        {
          appointment_id: appointment?.appointment_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then((response) => {
        if (response) {
          var responseData = response.data;
          this.setState({
            loaderFlag: false,
          });
          if (responseData) {
            if (response.data.hasOwnProperty("statusCode")) {
              if (response.data.statusCode == 200) {
                let latestFiles =
                  (responseData.data &&
                    responseData.data.length > 0 &&
                    responseData.data) ||
                  [];
                latestFiles = latestFiles.sort(
                  (a, b) => new Date(b.created_at) - new Date(a.created_at)
                );
                this.setState({
                  file: latestFiles,
                  presMsg: latestFiles.length == 0 ? "No Document Found" : "",
                });
              } else if (response.data.statusCode == 511) {
                this.setState({
                  presMsg: "No Document Found",
                });
              }
            } else {
              this.setState(
                {
                  presMsg: "No Document Found",
                  dataload: false,
                },
                () => {}
              );
            }
          }
        }
      })
      .catch(function (error) {
        C2CConsole.log(error);
      });
  };

  imageSelectHandler = (image) => {
    if (image && image.value) {
      this.setState({ selectedFile: image });
      this.submitPrescription(image);
    }
  };

  async submitPrescription(image) {
    let that = this;
    if (image && image.value) {
      this.setState({
        uploadingFile: true,
        consentFlag: false,
      });
      const option = {
        onUploadProgress: (ProgressEvent) => {
          const { loaded, total } = ProgressEvent;
          let percent = Math.floor((loaded * 100) / total);
          if (percent === 100) {
            this.setState({ percentage: 90 });
          } else {
            this.setState({ percentage: percent });
          }
        },
        cancelToken: new CancelToken(
          (cancel) => (this.cancelFileUpload.current = cancel)
        ),
      };
      Object.entries(image).forEach(([key, value]) => {
        if (key === "fileDate") {
          value.option = option;
        }
      });
      let signedUrl = await getSignedUrl(
        {
          file_size: image.size,
          doc_title: image.name,
          user_type: localStorage.getItem("user_type"),
          use_case: "upload-document-rev1",
        },
        image.fileDate
        // option
      );
      if (!signedUrl) {
        alert("Please upload only jpeg,jpg,png,pdf or doc file");
        that.setState({
          message: "Upload Failed",
          uploadingFile: false,
        });
      } else {
        this.setState({ uploaded: true });
        afterLoginApiAxios
          .post(
            "patient/upload-document-rev1",
            {
              appointment_id:
                this.state?.appointmentForDrawer?.app_id?.appointment_id,
              doc_data: signedUrl.fields["key"],
              file_size: image.size,
              doc_title: image.name,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("id_token"),
              },
            }
          )
          .then((response) => {
            if (response) {
              var responseData = response.data;
              this.setState({
                uploadingFile: false,
              });
              if (responseData) {
                if (response.data.hasOwnProperty("statusCode")) {
                  if (response.data.statusCode == 200) {
                    this.setState({ docId: responseData.response.document_id });
                    this.setState({ selectedFile: "" });
                    this.setState({ percentage: 0 });
                    if (
                      this.state.uploaded === true &&
                      this.state.clicked_delete === true
                    ) {
                      this.removePrescription(
                        responseData.response.document_id
                      );
                    } else {
                      let file = {
                        ...image,
                        created_at: new Date(),
                        document_id:
                          (responseData.response &&
                            responseData.response.document_id) ||
                          "",
                        doc_data: signedUrl.fields["key"],
                        document_title: image.name,
                        file_size: image.size,
                      };
                      this.setState({
                        file: [file, ...this.state.file],
                      });
                      this.getAppoinmentDocument(
                        this.state.appointmentForDrawer.app_id
                      );
                    }
                  } else {
                    swal({
                      title: "Upload Failed",
                      text: response.data.message,
                      type: "error",
                    });
                  }
                } else {
                  swal({
                    title: "Upload Failed",
                    text: "Something Went Wrong!Please Try Again!!",
                    type: "error",
                  });
                }
              }
            }
          })
          .catch(function (error) {
            alert("Please upload only jpeg,jpg,png,pdf or doc file");
            that.setState({
              message: "Upload Failed",
              uploadingFile: false,
            });
          });
      }
    } else {
      this.setState({
        message: "No file selected",
      });
    }
  }
  cancelUpload() {
    if (this.cancelFileUpload.current) {
      this.setState({ clicked_delete: true });
      if (this.state.uploaded === false) {
        this.cancelFileUpload.current("upload has been cancelled");
        this.setState({ percentage: 0 });
        this.setState({ selectedFile: "" });
        swal({
          title: "Deleted Successfully",
          text: "File deleted successfully",
          type: "success",
        });
        this.setState({ clicked_delete: false });
      }
    }
  }
  renderPagination() {
    if (this.state.number_of_pages != 0) {
      return (
        <div className="d-flex justify-content-center page-number-row my-3">
          {/* {this.renderPageNumbers()} */}
          <span
            onClick={(e) =>
              this.setState(
                (prevState) => ({ showLoader: true, activePage: 1 }),
                () => this.fetchPage()
              )
            }
            className={this.state.activePage == 1 ? "d-none" : "inactivePage"}
          >
            <i className="fa fa-angle-double-left" aria-hidden="true"></i>
          </span>
          <span
            onClick={(e) =>
              this.setState(
                (prevState) => ({
                  showLoader: true,
                  activePage: prevState.activePage - 1,
                }),
                () => this.fetchPage()
              )
            }
            className={this.state.activePage == 1 ? "d-none" : "inactivePage"}
          >
            <i className="fa fa-angle-left" aria-hidden="true"></i>
          </span>
          <span
            onClick={(e) =>
              this.setState(
                (prevState) => ({
                  showLoader: true,
                  activePage: prevState.activePage - 1,
                }),
                () => this.fetchPage()
              )
            }
            className={this.state.activePage == 1 ? "d-none" : "inactivePage"}
          >
            {this.state.activePage - 1}
          </span>
          <span className="activePage">{this.state.activePage}</span>
          <span
            onClick={(e) =>
              this.setState(
                (prevState) => ({
                  showLoader: true,
                  activePage: prevState.activePage + 1,
                }),
                () => this.fetchPage()
              )
            }
            className={
              this.state.activePage == this.state.number_of_pages
                ? "d-none"
                : "inactivePage"
            }
          >
            {this.state.activePage + 1}
          </span>
          <span
            onClick={(e) =>
              this.setState(
                (prevState) => ({
                  showLoader: true,
                  activePage: prevState.activePage + 1,
                }),
                () => this.fetchPage()
              )
            }
            className={
              this.state.activePage == this.state.number_of_pages
                ? "d-none"
                : "inactivePage"
            }
          >
            <i className="fa fa-angle-right" aria-hidden="true"></i>
          </span>
          <span
            onClick={(e) =>
              this.setState(
                (prevState) => ({
                  showLoader: true,
                  activePage: this.state.number_of_pages,
                }),
                () => this.fetchPage()
              )
            }
            className={
              this.state.activePage == this.state.number_of_pages
                ? "d-none"
                : "inactivePage"
            }
          >
            <i className="fa fa-angle-double-right" aria-hidden="true"></i>
          </span>
        </div>
      );
    }
  }

  componentWillReceiveProps(props) {
    if (props.refreshState == true) {
      this.setState(
        {
          searchTerm: "",
          activeSearchTerm: "",
          filterDate: null,
          filteringByDate: false,
          dateSorted: false,
          showLoader: false,
          all_appointments: [],
          isCalendarOpen: false,
          number_of_pages: 0,
          activePage: 1,
          calendarChanged: false,
          // showLoader: true,
        }
        // () => this.fetchAppointments()
      );
    }
  }

  renderDate() {
    if (this.state.filterDate) {
      const date = new Date(this.state.filterDate);
      const day = date.getDate().toString().padStart(2, "0");
      const month = date.getMonth();
      const monthName = monthNames[month];
      const year = this.state.filterDate.getFullYear().toString();
      // const appointment_date_query = [year, month,day].join('-').toString()
      return `Showing results for ${day} ${monthName}, ${year}`;
    }
    return "";
  }

  selectedPatient(index) {
    this.setState({
      selectedPatientIndex: index,
    });
  }

  bookFollowUp(appointment) {
    localStorage.setItem("patientId", appointment.patient_id);
    localStorage.setItem("patient_name", appointment.patient_name);
    localStorage.setItem("patient_email", appointment.email);
    localStorage.setItem("patient_gender", appointment.patient_gender);
    localStorage.setItem("patient_dob", appointment.patient_dob);
    localStorage.setItem("patient_reg_type", appointment.registration_type);
    localStorage.setItem(
      "epatientPhone",
      appointment.patient_phone ? appointment.patient_phone : ""
    );

    this.setState({ loading: true });
    afterLoginApiAxios
      .post(
        "/patient/follow-up-validity-checking-rev1",
        {
          patient_id: appointment.patient_id,
          patient_name: appointment.patient_name,
          patient_dob: appointment.patient_dob,
          doctor_id: appointment.doctor_id,
          patient_account_id: appointment.patient_account_id,
        },
        {
          headers: {
            authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then((res) => {
        this.setState({
          showLoader: false,
          loading: false,
        });
        if (res.data.statusCode === 403) {
          // Request UnSuccessful
          return unAuthorizedError(() => {
            this.props.history.push("/patient/login");
          });
        }
        // Request Successful
        if (res.data.follow_up_possible) {
          if (+res.data.is_caregiver) {
            localStorage.setItem("ecaregiverName", appointment.caregiver_name);
            localStorage.setItem(
              "ecaregiverEmail",
              appointment.caregiver_email
            );
            localStorage.setItem(
              "ecaregiverageProof",
              appointment.caregiver_age_proof
            );
            localStorage.setItem(
              "ecaregivergovtID",
              appointment.caregiver_govt_id
            );
          }
          this.props.history.push(
            "/appointment/book-followup/" + appointment.slug
          );
          localStorage.setItem("lastAppointment", res.data.last_appointment);
        } else {
          // Patient is not eligible to book followup
          // swal({
          //   icon: "warning",
          //   title: res.data.message,
          // });
          this.setState({ showCantTakeFollowupModal: true });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        if (err.response?.status === 403) {
          unAuthorizedError(() => {
            this.props.history.push("/patient/login");
          });
        } else {
          swal({
            icon: "error",
            title: "Something went wrong",
          });
        }
      });
  }

  viewDocument = ({ fileURL, fileName, fileType }) => {
    this.setState({
      documentShow: true,
      fileURL: fileURL,
      fileName: fileName,
      fileType: fileType,
    });
  };

  renderDocument(width) {
    if (this.state.documentShow) {
      return (
        <DocumentCotainer
          cancelHandler={() => this.closeNotification()}
          url={this.state.fileURL}
          fileName={this.state.fileName}
          fileType={this.state.fileType}
          width={width || ""}
        />
      );
    }
  }

  closeNotification = () => {
    this.setState({
      documentShow: false,
      fileName: "",
      fileURL: "",
      fileType: "",
    });
  };

  // 6003 changes

  deleteDocumentWithOutDocumentId(document_id) {
    if (this.state.isCancelEventHappening === false) {
      this.setState({ isCancelEventHappening: true });
      let that = this;
      // getting appointment id
      let appointment_id = "";
      for (let idx in this.state.file) {
        let item = this.state.file[idx];
        if (item.document_id === document_id) {
          appointment_id = item.appointment_id;
        }
      }

      // filtering array which exclude "document_id" element
      const filter_files = this.state.file.filter(function (item) {
        return item.document_id !== document_id;
      });

      // mapping array for finding document_paths only
      const updated_files = filter_files.map(function (item) {
        return item.document_path;
      });

      afterLoginApiAxios
        .post(
          "user/delete-booking-document",
          {
            appointment_id: appointment_id,
            updated_files: updated_files,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("id_token"),
            },
          }
        )
        .then((response) => {
          if (response) {
            var responseData = response.data;
            this.setState({
              uploadingFile: false,
            });

            if (responseData.statusCode === 200) {
              this.setState({
                file:
                  this.state.file.filter(
                    (item, index) => item.document_id !== document_id
                  ) || [],
              });
              this.setState({ uploaded: false });
              this.setState({ clicked_delete: false });
              swal({
                title: "Deleted Successfully",
                text: "File deleted successfully",
                type: "success",
              });
            } else {
              swal({
                title: "Deleted Failed",
                text: responseData.message || "File not Deleted",
                type: "success",
              });
            }
          }
          this.setState({ isCancelEventHappening: false });
        })
        .catch(function (error) {
          this.setState({ isCancelEventHappening: false });
          alert("Deleted Failed! Please try again!!");
          that.setState({
            message: "Deleted Failed",
            uploadingFile: false,
          });
        });
    }
  }

  deleteDocumentWithDocumentId(document_id) {
    if (this.state.isCancelEventHappening === false) {
      this.setState({ isCancelEventHappening: true });
      let that = this;
      afterLoginApiAxios
        .post(
          "patient/delete-document",
          {
            document_id: document_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("id_token"),
            },
          }
        )
        .then((response) => {
          if (response) {
            var responseData = response.data;
            this.setState({
              uploadingFile: false,
            });
            if (responseData) {
              if (response.data.hasOwnProperty("statusCode")) {
                if (response.data.statusCode == 200) {
                  this.setState({
                    file:
                      this.state.file.filter(
                        (item, index) => item.document_id !== document_id
                      ) || [],
                  });
                  this.setState({ uploaded: false });
                  this.setState({ clicked_delete: false });
                  swal({
                    title: "Deleted Successfully",
                    text: "File deleted successfully",
                    type: "success",
                  });
                } else {
                  swal({
                    title: "Deleted Failed",
                    text: response.data.message || "File not Deleted",
                    type: "success",
                  });
                }
              } else {
                this.setState(
                  {
                    message: "Deleted Failed",
                    response_message: "Something Went Wrong!Please Try Again!!",
                    dataload: false,
                    uploadingFile: false,
                  },
                  () => {}
                );
              }
            }
          }
          this.setState({ isCancelEventHappening: false });
        })
        .catch(function (error) {
          this.setState({ isCancelEventHappening: false });
          alert("Deleted Failed! Please try again!!");
          that.setState({
            message: "Deleted Failed",
            uploadingFile: false,
          });
        });
    }
  }

  removePrescription = (document_id) => {
    if (document_id.includes("BOOKING_DOC")) {
      this.deleteDocumentWithOutDocumentId(document_id);
    } else {
      this.deleteDocumentWithDocumentId(document_id);
    }
  };

  //
  onSuccessRegularBooking = () => {
    this.setState({ showCantTakeFollowupModal: false });
    window.location.href = "/doctor/" + this.state.doctor.short_url_slug;
  };

  render() {
    var styles = {
      cardOdd: {
        backgroundColor: "#eeae01",
        color: "#454545",
      },
      cardEven: {
        backgroundColor: "#454545",
        color: "#eeae01",
      },
    };
    return (
      <React.Fragment>
        <CantTakeFollowupConfirmationModal
          cancelHandler={() => {
            this.setState({ showCantTakeFollowupModal: false });
          }}
          successHandler={() => {
            this.onSuccessRegularBooking();
          }}
          show={this.state.showCantTakeFollowupModal}
        />
        {this.state.loading ? <Loader /> : null}
        {/* Search Bar */}
        <div style={{ marginTop: "30px" }}>
          <div className="pt-4 px-0" style={{ backgroundColor: "white" }}>
            <h6 className="mx-3" style={{ fontWeight: "bold" }}>
              {" "}
              APPOINTMENT HISTORY
            </h6>
            <div className="row p-2 align-items-center justify-content-between mx-2 searchPatientContainer">
              <div className="position-relative searchBarPatient">
                <input
                  type="text"
                  ref={this.searchBarRef}
                  placeholder="Search by doctor name..."
                  value={this.state.searchTerm}
                  disabled={this.state.showLoader}
                  onChange={(e) =>
                    this.setState({ searchTerm: e.target.value }, () =>
                      this.getDebouncedResults(e, false, true)
                    )
                  }
                  className="form-control pr-4"
                />
                <i
                  className={`fa fa-times position-absolute cursor-pointer clearSearch ${
                    this.state.searchTerm.length == 0 ? "d-none" : ""
                  }`}
                  onClick={() =>
                    this.setState(
                      {
                        searchTerm: "",
                        activeSearchTerm: "",
                      },
                      () => this.handleSearch(null, true, false)
                    )
                  }
                ></i>
              </div>

              <div className="filters mt-3 mt-md-0">
                <span className="mx-2">
                  <i
                    style={{ cursor: "pointer" }}
                    className="fa fa-refresh"
                    title="Remove Date Filter"
                    onClick={() =>
                      this.setState(
                        {
                          filteringByDate: false,
                          filterDate: null,
                          search_string: "",
                          dateToView: new Date(),
                          // activeStartDate:new Date()
                        },
                        () => this.handleSearch(null, true, false)
                      )
                    }
                  ></i>
                </span>
                <span classname="border-1 mr-1 mx-2">
                  |
                  <span
                    className={this.state.filterDate ? "text-red mx-2" : "mx-2"}
                  >
                    <i
                      style={{ cursor: "pointer" }}
                      className="fa fa-calendar"
                      title="Find Appointments by Date"
                      onClick={() => this.setState({ isCalendarOpen: true })}
                    ></i>
                  </span>
                  {this.state.filterDate ? <sup>&nbsp;!</sup> : ""}
                </span>
                <span>
                  |
                  <span className="mx-2">
                    {/* <i className="fa fa-filter"></i>&nbsp; */}
                    <i
                      // style={{ marginLeft: "10px" }}
                      style={{ cursor: "pointer" }}
                      title={
                        this.state.dateSorted
                          ? "Sort Descending date"
                          : "Sort Ascending date"
                      }
                      onClick={() => this.handleDateOrder()}
                      // className={
                      //   !this.state.dateSorted
                      //     ? "fa fa-arrow-down"
                      //     : "fa fa-arrow-up"
                      // }
                      className="fa fa-filter"
                      aria-hidden="true"
                    ></i>
                  </span>
                </span>

                {/* <span className="border-1">
                  <button
                    onClick={() => this.setState({ isCalendarOpen: true })}
                    className="btn btn-sm bg-white mr-1 searchBarBtn"
                    title={"Find appointments by date"}
                  ></button>
                  <button
                    className="btn btn-sm mr-1 searchBarBtn"
                    // onClick={() =>
                    // }
                    title="Remove date filter"
                  ></button>
                </span> */}
                {/* <span className=" border-1">
                  <button
                    onClick={() => this.handleDateOrder()}
                    className="btn btn-sm bg-white searchBarBtn"
                    title={
                      this.state.dateSorted
                        ? "Sort Descending date"
                        : "Sort Ascending date"
                    }
                  >
                  </button>
                </span> */}
              </div>
            </div>
            <div className="row justify-content-center justify-content-sm-end">
              <div className="mr-2 searchBar">
                {!this.state.showLoader ? (
                  <DatePicker
                    clearIcon={null}
                    ref={this.calendarRef}
                    value={this.state.dateToView}
                    onCalendarOpen={() =>
                      this.setState({ calendarChanged: false })
                    }
                    onChange={(e) =>
                      this.setState({
                        filterDate: e,
                        calendarChanged: true,
                        dateToView: e,
                      })
                    }
                    onCalendarClose={
                      this.state.calendarChanged
                        ? () =>
                            this.setState({ isCalendarOpen: false }, () =>
                              this.handleSearch(null, false, false)
                            )
                        : () => this.setState({ isCalendarOpen: false })
                    }
                    isOpen={this.state.isCalendarOpen}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <p className="pl-0 pl-sm-5">
            {" "}
            <span>
              <i>{this.renderDate()}</i>
            </span>
          </p>
          {/* {this.renderPagination()} */}
          <div className="px-2">
            <div className="prevPatientsContainer">
              {this.state.showLoader ? <Loader /> : null}
              {this.state.appointments ? (
                // <span>
                <React.Fragment>
                  <div>
                    <Row
                      style={{
                        color: "rgb(249, 105, 164)",
                        fontWeight: "bold",
                        alignItems: "center",
                        fontSize: "11px",
                        marginLeft: "14px",
                      }}
                    >
                      <Col>
                        <div>DOCTOR NAME</div>
                      </Col>
                      <Col>
                        <div>PATIENT NAME & ID</div>
                      </Col>
                      <Col>
                        <div>CONSULTATION</div>
                      </Col>
                      <Col>
                        <div>DATE AND TIME</div>
                      </Col>
                      <Col>
                        <div>FOLLOW UP</div>
                      </Col>
                      <Col xs={2}>
                        <div>PRESCRIPTION</div>
                      </Col>
                      <Col xs={2}>
                        <div>ACTIONS</div>
                      </Col>
                    </Row>
                    {/* <div
                      className="row xs-auto"
                      style={{
                        color: "rgb(249, 105, 164)",
                        fontWeight: "bold",
                        // alignItems: "center",
                        fontSize: "10px",
                      }}
                    >
                      <div className="col-md-2">DOCTOR NAME</div>
                      <div className="col-md-2">PATIENT NAME & ID</div>
                      <div className="col-md-2">CONSULTATION</div>
                      <div className="col-md-2">DATE AND TIME</div>
                      <div className="col-md-1">FOLLOW UP</div>
                      <div className="col-md-2">PRESCRIPTION</div>
                      <div className="col-md-2">ACTIONS"</div>
                    </div> */}
                    {this.state.appointments.map((appointment, index) => {
                      return (
                        <PreviousAppoinmentRow
                          appointment={appointment}
                          key={index}
                          index={index}
                          selectedPatientIndex={this.state.selectedPatientIndex}
                          viewAppointment={this.viewAppointment.bind(this)}
                          actions={{
                            selectedPatientAction:
                              this.selectedPatient.bind(this),
                            viewAppointmentAction:
                              this.viewAppointment.bind(this),
                            viewDocumentAction:
                              this.viewDocumentAction.bind(this),
                            bookFollowUpAction: this.bookFollowUp.bind(this),
                          }}
                        />
                      );
                    })}
                  </div>
                </React.Fragment>
              ) : (
                //   {this.state.appointments.map((appointment, index) => {
                //     return (
                //       <div
                //         className="row upcomingList p-2 pt-3 text-center"
                //         style={{
                //           backgroundColor: index % 2 == 0 ? "#fff" : "#f5f6f7",
                //         }}
                //       >
                //         <div className="col-sm-12 col-lg-2 pt-3 p-0">
                //           <h6>
                //             Dr. {appointment.first_name}{" "}
                //             {appointment.middle_name
                //               ? appointment.middle_name
                //               : ""}{" "}
                //             {appointment.last_name}
                //           </h6>
                //           {appointment.rebooking_available == true ? (
                //             <button
                //               className="customButton patient-history-button"
                //               onClick={() => this.bookFollowUp(appointment)}
                //             >
                //               <small style={{ fontSize: "65%" }}>
                //                 Book Followup
                //               </small>
                //             </button>
                //           ) : null}
                //         </div>
                //         <div className="col-sm-12 col-lg-2 patientName pt-1 p-0">
                //           <small style={{ color: "#F969A4" }}>Patient</small>
                //           <h6>{appointment.patient_name}</h6>
                //           <small
                //             style={{
                //               backgroundColor: "#404760",
                //               color: "#fff",
                //               paddingLeft: "2px",
                //               paddingRight: "2px",
                //             }}
                //           >
                //             ID:{" "}
                //             {appointment.appointment_number
                //               ? appointment.appointment_number
                //               : "NA"}
                //           </small>
                //         </div>
                //         <div className="col-sm-12 col-lg-2 pt-2">
                //           {appointment.is_follow_up == "True" ? (
                //             <button className="customBadge patient-history-followUp-button">
                //               <small style={{ fontSize: "65%" }}>FollowUp</small>
                //             </button>
                //           ) : (
                //             <button
                //               className="customBadge"
                //               style={{ marginRight: "30px" }}
                //             >
                //               <small style={{ fontSize: "65%" }}>
                //                 Initial Consultation
                //               </small>
                //             </button>
                //           )}
                //         </div>
                //         <div className="col-sm-12 col-lg-2 text-center pt-2 p-0">
                //           <small>
                //             <FontAwesome
                //               className="mr-1 iconSmall"
                //               name="calendar"
                //             />{" "}
                //             {appointment.appointment_date.substring(8, 10)}{" "}
                //             {
                //               this.state.months[
                //                 Number(
                //                   appointment.appointment_date.substring(5, 7)
                //                 )
                //               ]
                //             }{" "}
                //             {moment(
                //               appointment.appointment_date,
                //               "YYYY-MM-DD"
                //             ).format("YYYY")}
                //             ,{" "}
                //             {moment(appointment.start_time, "hh:mmZ").format(
                //               "hh:mm A"
                //             )}{" "}
                //           </small>
                //         </div>
                //         <div className="col-sm-12 col-lg-2 text-center p-0 pt-2">
                //         {appointment.status == "2" ?
                //           <button style={{backgroundColor:'#f969a4',color:'#fff',border:'none'}}>Cancelled</button>
                //           :
                //             <span>
                //               <Link
                //                 to={"/prescriptionforpatients/" + appointment.id}
                //               >
                //                 <button className="customButton pl-4 pr-4">
                //                   <small style={{ fontSize: "80%" }}>
                //                     <FontAwesome className="mr-1" name="eye" />
                //                     Prescription
                //                   </small>
                //                 </button>
                //               </Link>
                //               <Link to={"/documentforpatients/" + appointment.id}>
                //                 <button className="customButton mt-1 ml-1 ">
                //                   <small>
                //                     <i className="fa fa-paperclip"></i>
                //                   </small>
                //                 </button>
                //               </Link>
                //             </span>
                //           }
                //         </div>
                //         <div className="col-sm-12 col-lg-2 text-center p-0 pt-2">
                //         <button
                //             className="action-button"
                //             data-tooltip="Uploaded Document"
                //             data-tooltip-conf="up"
                //           >
                //             <i className="fa fa-file-text mx-2" aria-hidden="true"></i>
                //           </button>
                //           {appointment.status == "2" ? null : (
                //             <button
                //               className="customButton pl-3 pr-3"
                //               onClick={this.viewAppointment.bind(
                //                 this,
                //                 appointment
                //               )}
                //             >
                //               <small style={{ fontSize: "80%" }}>
                //                 <FontAwesome className="mr-1" name="eye" />
                //                 Details
                //               </small>
                //             </button>
                //           )}
                //         </div>
                //       </div>
                //     );
                //   })}
                // </span>
                <span>
                  {this.state.showLoader == false ? (
                    <div className="row upcomingList p-2 mt-2 text-center">
                      <div
                        className="col-sm-12 col-lg-12 pt-1"
                        style={{ fontSize: "12px" }}
                      >
                        No Records Found
                      </div>
                    </div>
                  ) : null}
                </span>
              )}
              {this.state.openView ? (
                <Modal
                  show={this.state.openView}
                  onHide={this.closeAppointment}
                >
                  {this.state.openView
                    ? disableHelpButton()
                    : enableHelpButton()}
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <h4>Appointment Details</h4>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <small>
                      {this.state.patient ? (
                        <div className="row">
                          <div className="col-sm-12 col-lg-12 text-center">
                            <h3>
                              <FontAwesome
                                className="mr-1 iconSmall"
                                name="calendar"
                              />
                              <br />
                              <b>
                                {" "}
                                {this.state.patient.appointment_date.substring(
                                  8,
                                  10
                                )}{" "}
                                {
                                  this.state.months[
                                    Number(
                                      this.state.patient.appointment_date.substring(
                                        5,
                                        7
                                      )
                                    )
                                  ]
                                }{" "}
                                {moment(
                                  this.state.patient.appointment_date,
                                  "YYYY-MM-DD"
                                ).format("YYYY")}
                              </b>
                            </h3>
                            <h5>
                              {Number(
                                this.state?.patient?.appointment_start_time?.substring(
                                  0,
                                  2
                                )
                              ) <= 12
                                ? Number(
                                    this.state?.patient?.appointment_start_time?.substring(
                                      0,
                                      2
                                    )
                                  ) +
                                  "" +
                                  this.state?.patient?.appointment_start_time?.substring(
                                    2,
                                    5
                                  )
                                : Number(
                                    this.state?.patient?.appointment_start_time?.substring(
                                      0,
                                      2
                                    )
                                  ) -
                                  12 +
                                  "" +
                                  this.state?.patient?.appointment_start_time?.substring(
                                    2,
                                    5
                                  )}
                              {Number(
                                this.state?.patient?.appointment_start_time?.substring(
                                  0,
                                  2
                                )
                              ) < 12
                                ? " AM"
                                : " PM"}
                            </h5>
                          </div>
                          <div className="col-sm-12 col-lg-10 offset-lg-1 mt-2">
                            <div className="row" style={{ fontSize: "15px" }}>
                              <div className="col-6 col-sm-6 col-lg-6 labelPatient pt-2">
                                Patient Name:
                              </div>
                              <div className="col-6 col-sm-6 col-lg-6 pt-2">
                                {this.state.patient.patient_name}
                              </div>
                              <div className="col-6 col-sm-6 col-lg-6 labelPatient pt-2">
                                DOB:
                              </div>
                              <div className="col-6 col-sm-6 col-lg-6 pt-2">
                                {moment(this.state.patient.patient_dob).format(
                                  "DD MMM YYYY"
                                )}
                              </div>
                              <div className="col-6 col-sm-6 col-lg-6 labelPatient pt-2">
                                Gender:
                              </div>
                              <div className="col-6 col-sm-6 col-lg-6 pt-2">
                                {this.state.patient.patient_gender}
                              </div>
                              <div className="col-6 col-sm-6 col-lg-6 labelPatient pt-2">
                                Mobile Number:
                              </div>
                              <div className="col-6 col-sm-6 col-lg-6 pt-2">
                                +(
                                {this.state?.patient?.patient_phone_no?.substring(
                                  1,
                                  3
                                )}
                                ){" "}
                                {this.state?.patient?.patient_phone_no?.substring(
                                  3,
                                  6
                                )}
                                -
                                {this.state?.patient?.patient_phone_no?.substring(
                                  6,
                                  9
                                )}
                                -
                                {this.state?.patient?.patient_phone_no?.substring(
                                  9,
                                  13
                                )}
                              </div>

                              <br />

                              <div className="col-6 col-sm-6 col-lg-6 labelPatient pt-3">
                                Purpose of consultation:
                              </div>
                              <div className="col-6 col-sm-6 col-lg-6 pt-3">
                                {this.state?.patient?.purpose_of_appointment}
                              </div>

                              <div className="col-6 col-sm-6 col-lg-6 labelPatient pt-3">
                                Attachments:
                              </div>
                              {this.state?.patient_prescription_url &&
                              this.state?.patient_prescription_url?.length >
                                0 ? (
                                <div className="col-6 col-sm-6 col-lg-6 pt-1 pb-3">
                                  <span>
                                    {this.state?.patient_prescription_url.map(
                                      (each_pres, index) => {
                                        return (
                                          <span>
                                            {each_pres ? (
                                              <div className="mb-2">
                                                Medical Record {index + 1}{" "}
                                                <a
                                                  target="_blank"
                                                  href={each_pres}
                                                  className="mr-2 btn btn-info btn-sm downloadButton"
                                                >
                                                  Download
                                                </a>
                                              </div>
                                            ) : (
                                              <span>--</span>
                                            )}
                                          </span>
                                        );
                                      }
                                    )}
                                  </span>
                                </div>
                              ) : (
                                <div className="col-6 col-sm-6 col-lg-6 pt-3 pb-3">
                                  NA
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <p className="m-5 m p-5">Loading..</p>
                      )}
                    </small>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="dark" onClick={this.closeAppointment}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              ) : null}

              <Modal
                size={"xl"}
                dialogClassName={"w-100 mw-75"}
                show={this.state.openReschedule}
                onHide={this.closeReschedule}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Rebook Appointment</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Rebook
                    doctorId={this.state.rescheduleAppt?.doctor_id}
                    appointmentId={this.state.rescheduleAppt?.id}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="dark" onClick={this.closeReschedule}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            {this.renderPagination()}

            <Drawer
              anchor={"right"}
              open={this.state.drawerOpen}
              onClose={this.toggleDrawer.bind(this)}
            >
              {this.state.drawerOpen && disableHelpButton()}
              <div className="py-5 previous-patients-prescription-edit-drawer px-0 px-md-2">
                <div
                  className="d-flex flex-row justify-content-between"
                  style={{ paddingLeft: "20px", paddingRight: "20px" }}
                >
                  <h2
                    className="mb-0 font-weight-bold"
                    style={{ fontSize: "25px" }}
                  >
                    {this.state.drawerType}
                  </h2>
                  <span
                    className="float-right cursor-pointer mb-3"
                    onClick={this.toggleDrawer.bind(this)}
                    style={{ fontSize: "1rem" }}
                  >
                    <img
                      className="close-img"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEUAAAATCAYAAADGWsDDAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAARaADAAQAAAABAAAAEwAAAADuhDkdAAAErElEQVRYCe2Wa2hURxTH9xkiSZFurKJJNa6Sh5tsiKmP0lIrtVY/qIUqIkpdsdpv9kOxQmgrlQREKkULlkJpfGIrVrRQItpHFCGKBsljjUpsa5sa7YcK2ujm3d//uhOu693ErBSC5MC5Z+bMf86d+c85c6/LNSIjDKTMQF5e3hgmu50ChEKhQDAYHO009rT4fGYjRUVFYY/Hs43+DLfbHQiHwzFsLf336+vrLxqcz+c7npmZ+Sf9t4xvuFr2sLuvr6+msbFx92BrLC4ujrD/Oex1jUdgTn+i1+utVhMiNhJoHroNnYrvDBOCwknwaY7X6gzjB4f8KntZzUartOGBlhonpApMRPMsUiBkE46xBHkNpr5uaGj4CXY3018ICaMI/NFAQYfjWFNTU01vb+8arW0gYmyEuITXPFM+s5h7BkKu2jdIP1pSUqISmmn3J7ZJ0xUQqAXMgMRm9CikqhT7RRg676EhVOX3JeTvwPYLC1xGnHdxTMdewO5lDfv7AUNsqGyIaZESJ8ZlL6VEQsyYVQosIMxGWpzeiX8+DL7sNCYfpC3HHEBHg92MvU28rZBQQdsSMHNpHMAfBbMKexjdDmZDHOJigUtY+EH81/CtQ38DuxfMYoNJxWqjThmTjBC9w5ebm+vH+llMzOmlnGY7fmkyUWld4USVbZKdkPAt9oOcnJzK1tbW+7QXKD4YZUE3eozN1mDvoZYwvgUSjvE+YSTfEedZ7Hb0ezlSlcSMIe4cYkUUT4SZDFFf4unu7nb89D4YHvhZUFCQxUamgTqSgFTfn5WVNTvub8KOgogv0Onysfmf0bNq6zOPKUZ/RFXSlhL7BDolPz//GXxPJPaMIVBEwZwIkd/HSXYFAoFeteUYivj9/gmcsJvgl+zz2EgUt1zZepAh+0jXSZSHyuUdTup37L729vbKlpaWDtpTFAe7i7Fd2IckPT19Ko7+34KHBv+Hju4UpbM2NdkpPid7ED3tNEaW3ZGfzY5LGLf6kGONa4yTquAfJ7unp2c+3RNoeUZGxlcag49/ZMF/SMyQXcFPg9R6jT+J2O8Q3rNHseKXbyQxrvVJxlnLwl7hG/28HVBYWDgJ/1J8f9n9pt3c3HyddhsvWWJ8ssx5M9435TGxtLT0ubq6ui4+eSd1tzDnELpQOHy/0r7LvGA0Gr1klKF/Wbj+rpXJKYudEJUMZRuRVUAnYqySYTGfamMAzpMV++mfpP8CczSxi/YnCuAkBN/DvI3MqwT3De3XsSvBVvPyvzWH/6DPwL3I4t7u7Ow8l5aWVoRbGWNKoo937mBeOZizZNRR8Lp7qvC1YcvQlCSREHOpyjLm+Lm2MoWTu0qavsFbG1mcPofH0Y9p/4F9iQCXsUb0xZBawlg5Df1vrIWQBqx++nRhLkMtgYj1NE4zXs39cAd7iv5FcBELwIP7pQKzE9/nECIyf4CQ2o6OjkUGM1SbjBATR8Twrkcyxm0ANuuhjLJJ6Rv4emz+QZuU23hK6hZAx3QvKyvzx2Kx8RDTplJKEtDL10gX9E3KqDMJZlC3ftfJ0F8E1MZFQLJJdvJIjrnJcE+Fn5JWiUQeZzPCCf842BHMCAMPGPgPBj5Bnjq/A3IAAAAASUVORK5CYII="
                      alt="X"
                    />
                  </span>
                </div>
                {/* <h2 className="px-2">
                {this.state.drawerType}
                <span
                  className="float-right cursor-pointer mb-3"
                  onClick={this.toggleDrawer.bind(this)}
                  style={{ fontSize: "1rem" }}
                >
                  <i className="fa fa-times" aria-hidden="true"></i>
                </span>
              </h2> */}
                <DocumentUpload
                  imageSelectHandler={this.imageSelectHandler.bind(this)}
                  file={this.state.file}
                  actions={{
                    viewDocumentAction: this.viewDocument.bind(this),
                    removePrescription: this.removePrescription.bind(this),
                  }}
                  selectedFile={this.state.selectedFile}
                  percentage={this.state.percentage}
                  cancelUpload={this.cancelUpload.bind(this)}
                />
                {this.renderDocument("98%")}
              </div>
            </Drawer>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(PreviousDoctors);
