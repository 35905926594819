import React, { useEffect, useState } from "react";
import { Document, Page, StyleSheet, Font, Text } from "@react-pdf/renderer";
import ERXPdfMainContentView from "../ERXPdfMainContentView";
import ERXPdfHeaderView from "../ERXPdfHeaderView";
import ERXPdfFooterView from "../ERXPdfFooterView";

Font.register({
  family: "notosans",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/notosans/v35/o-0mIpQlx3QUlC5A4PNB6Ryti20_6n1iPHjcz6L1SoM-jCpoiyD9A99d.ttf",
      fontWeight: "normal",
    }, // font-style: normal, font-weight: normal
    {
      src: "https://fonts.gstatic.com/s/notosans/v36/o-0mIpQlx3QUlC5A4PNB6Ryti20_6n1iPHjcz6L1SoM-jCpoiyDPA99d.ttf",
      fontWeight: "500",
    },
    {
      src: "https://fonts.gstatic.com/s/notosans/v36/o-0mIpQlx3QUlC5A4PNB6Ryti20_6n1iPHjcz6L1SoM-jCpoiyAjBN9d.ttf",
      fontWeight: "600",
    },
    {
      src: "https://fonts.gstatic.com/s/notosans/v36/o-0mIpQlx3QUlC5A4PNB6Ryti20_6n1iPHjcz6L1SoM-jCpoiyAaBN9d.ttf",
      fontWeight: "700",
    },
  ],
});

Font.register({
  family: "notosanskannada",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/notosanskannada/v27/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrDvNzSI.ttf",
    }, // font-style: normal, font-weight: normal
    {
      src: "https://fonts.gstatic.com/s/notosanskannada/v27/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrAnNzSI.ttf",
      fontWeight: "500",
    },
    {
      src: "https://fonts.gstatic.com/s/notosanskannada/v27/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrOXKzSI.ttf",
      fontWeight: "600",
    },
    {
      src: "https://fonts.gstatic.com/s/notosanskannada/v27/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrNzKzSI.ttf",
      fontWeight: "700",
    },
  ],
});

Font.register({
  family: "notosanstamil",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/notosanstamil/v27/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7vGo70R.ttf",
    }, // font-style: normal, font-weight: normal
    {
      src: "https://fonts.gstatic.com/s/notosanstamil/v27/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7v0o70R.ttf",
      fontWeight: "500",
    },
    {
      src: "https://fonts.gstatic.com/s/notosanstamil/v27/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7sYpL0R.ttf",
      fontWeight: "600",
    },
    {
      src: "https://fonts.gstatic.com/s/notosanstamil/v27/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7shpL0R.ttf",
      fontWeight: "700",
    },
  ],
});

Font.register({
  family: "notosansgujarati",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/notosansgujarati/v25/wlpWgx_HC1ti5ViekvcxnhMlCVo3f5pv17ivlzsUB14gg1TMR2Gw4VceEl7MA_x3EAPM.ttf",
    }, // font-style: normal, font-weight: normal
    {
      src: "https://fonts.gstatic.com/s/notosansgujarati/v25/wlpWgx_HC1ti5ViekvcxnhMlCVo3f5pv17ivlzsUB14gg1TMR2Gw4VceEl7MA_ybFwPM.ttf",
      fontWeight: "500",
    },
    {
      src: "https://fonts.gstatic.com/s/notosansgujarati/v25/wlpWgx_HC1ti5ViekvcxnhMlCVo3f5pv17ivlzsUB14gg1TMR2Gw4VceEl7MA_x3EAPM.ttf",
      fontWeight: "600",
    },
    {
      src: "https://fonts.gstatic.com/s/notosansgujarati/v25/wlpWgx_HC1ti5ViekvcxnhMlCVo3f5pv17ivlzsUB14gg1TMR2Gw4VceEl7MA_xOEAPM.ttf",
      fontWeight: "700",
    },
  ],
});

Font.register({
  family: "notonaskharabic",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/notonaskharabic/v33/RrQ5bpV-9Dd1b1OAGA6M9PkyDuVBePeKNaxcsss0Y7bwvc5krA.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/notonaskharabic/v33/RrQ5bpV-9Dd1b1OAGA6M9PkyDuVBePeKNaxcsss0Y7bwj85krA.ttf",
      fontWeight: "500",
    },
    {
      src: "https://fonts.gstatic.com/s/notonaskharabic/v33/RrQ5bpV-9Dd1b1OAGA6M9PkyDuVBePeKNaxcsss0Y7bwY8lkrA.ttf)",
      fontWeight: "600",
    },
    {
      src: "https://fonts.gstatic.com/s/notonaskharabic/v33/RrQ5bpV-9Dd1b1OAGA6M9PkyDuVBePeKNaxcsss0Y7bwWslkrA.ttf",
      fontWeight: "700",
    },
  ],
});

Font.register({
  family: "notoserifdevanagari",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/notoserifdevanagari/v28/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTA-ow-H.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/notoserifdevanagari/v28/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTAMow-H.ttf",
      fontWeight: "500",
    },
    {
      src: "https://fonts.gstatic.com/s/notoserifdevanagari/v28/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTDgpA-H.ttf",
      fontWeight: "600",
    },
    {
      src: "https://fonts.gstatic.com/s/notoserifdevanagari/v28/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTDZpA-H.ttf",
      fontWeight: "700",
    },
  ],
});

Font.register({
  family: "notoserifbengali",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/notoserifbengali/v19/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JfcAHnqn.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/notoserifbengali/v19/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JfcyHnqn.ttf",
      fontWeight: "500",
    },
    {
      src: "https://fonts.gstatic.com/s/notoserifbengali/v19/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JffeGXqn.ttf",
      fontWeight: "600",
    },
    {
      src: "https://fonts.gstatic.com/s/notoserifbengali/v19/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JffnGXqn.ttf",
      fontWeight: "700",
    },
  ],
});

Font.register({
  family: "anektelugu",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/anektelugu/v8/LhWLMVrUNvsddMtYGCx4FcVWOjlwE1WgXdoJ-5XHMl2DkooGK7i13y--oE0.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/anektelugu/v8/LhWLMVrUNvsddMtYGCx4FcVWOjlwE1WgXdoJ-5XHMl2DkooGK7i13x2-oE0.ttf",
      fontWeight: "500",
    },
    {
      src: "https://fonts.gstatic.com/s/anektelugu/v8/LhWLMVrUNvsddMtYGCx4FcVWOjlwE1WgXdoJ-5XHMl2DkooGK7i13_G5oE0.ttf",
      fontWeight: "600",
    },
    {
      src: "https://fonts.gstatic.com/s/anektelugu/v8/LhWLMVrUNvsddMtYGCx4FcVWOjlwE1WgXdoJ-5XHMl2DkooGK7i138i5oE0.ttf",
      fontWeight: "700",
    },
  ],
});

const PDFViewForC2C = (props) => {
  const { erxPdfData, lang, pdfMainContent } = props;
  const [pdfFontFamily, setPdfFontFamily] = useState("notosans");

  const styles = StyleSheet.create({
    document: { backgroundColor: "white" },
    page: {
      border: "1px solid #DAE0E2",
    },

    container: {
      height: 1000, //more than 841 will move to other page(A4)
      width: "100%",
    },
    topHeaderHr: {
      borderTop: "1px solid #dae0e2",
      borderBottom: "none",
      marginBottom: 0,
      marginTop: 0,
    },
    waterMark: {
      position: "absolute",
      top: "60%",
      left: "30%",
      fontSize: 60,
      transform: "rotate(-45deg)",
      color: "#dae0e2",
      textTransform: "uppercase",
    },
  });

  // Lifecycle Methods ===>

  useEffect(() => {
    setPdfFontFamily(currentLang());
  }, [lang]);

  // <===

  // Helper Methods ===>

  const currentLang = () => {
    switch (lang) {
      case "en":
        return "notosans";
      case "hi":
        return "notoserifdevanagari";
      case "mr":
        return "notoserifdevanagari";
      case "bn":
        return "notoserifbengali";
      case "kn":
        return "notosanskannada";
      case "te":
        return "anektelugu";
      case "ta":
        return "notosanstamil";
      case "gu":
        return "notosansgujarati";
      case "ur":
        return "notonaskharabic";

      default:
        return "notosans";
    }
  };

  const getCustomMargins = (side, unit) => {
    return (+side).toString() + unit;
  };

  const getPdfPagePadding = () => {
    let paddingTop = "135px",
      paddingRight = "0",
      paddingBottom = "142px",
      paddingLeft = "0";
    let customMargins =
      erxPdfData?.doctor_profile_details?.custom_margin_dimensions;
    if (erxPdfData?.doctor_profile_details?.custom_margins_enabled) {
      paddingTop = getCustomMargins(customMargins?.rxTop, customMargins?.unit);
      let rxBottom = customMargins?.rxBottom;
      const isCmUnit = customMargins?.unit === "cm";
      const isInUnit = customMargins?.unit === "in";
      const isRxBottomEqualTo0 =
        customMargins?.rxBottom === 0 || !customMargins?.rxBottom;
      if (isCmUnit) {
        if (isRxBottomEqualTo0) {
          rxBottom = "2.5";
        } else {
          rxBottom = 2 + +customMargins?.rxBottom;
        }
      } else if (isInUnit) {
        rxBottom = 1 + +customMargins?.rxBottom;
      }

      paddingBottom = getCustomMargins(rxBottom, customMargins?.unit);
    }

    return `${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft}`;
  };

  // <===
  return (
    <Document style={styles.document}>
      <Page
        size="A4"
        style={[
          styles.page,
          { padding: getPdfPagePadding(), fontFamily: pdfFontFamily },
        ]}
      >
        <ERXPdfHeaderView
          erxPdfData={erxPdfData}
          pdfFontFamily={pdfFontFamily}
        />

        <ERXPdfMainContentView
          erxPdfData={erxPdfData}
          pdfFontFamily={pdfFontFamily}
          pdfMainContent={pdfMainContent}
        />
        <ERXPdfFooterView
          erxPdfData={erxPdfData}
          pdfFontFamily={pdfFontFamily}
        />
        {erxPdfData?.dummy_doctor_flag && (
          <Text style={styles.waterMark} fixed>
            Sample
          </Text>
        )}
      </Page>
    </Document>
  );
};

export default PDFViewForC2C;
