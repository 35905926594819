import React, { useState, useEffect } from "react";
import SuggestedKeywords from "./SuggestedKeywords";
import AddContentList from "./AddContentList";
import CommentTextField from "../common/CommentTextField";

const AddContent = (props) => {
  const [selectedKeyword, setSelectedKeyword] = useState("All");
  const [selectedArticleLength, setSelectedArticleLength] = useState(
    props.selectedArticles.length
  );
  const [hidePreviewBtn, sethidePreviewBtn] = useState(false);
  const [goBackHidden, setgoBackHidden] = useState(true);
  const [searchedData, setsearchedData] = useState([]);

  const previewBtnClick = (text) => {
    props.previewBtnCallback(text);
  };

  const clickOnKeyword = (title) => {
    setSelectedKeyword(title);
  };

  // callbacks ==>
  const hidePreviewBtnCallback = () => {
    sethidePreviewBtn(true);
  };
  const setSelectedArticlesCallBack = (selectedArticles) => {
    props.setSelectedArticlesCallBack(selectedArticles);
    setSelectedArticleLength(selectedArticles.length);
  };

  const uploadBtnCallback = (uploadMedia, docData) => {
    props.uploadBtnCallback(uploadMedia, docData);
  };

  const searchDataCallback = (data) => {
    setsearchedData(data);
  };
  //<==
  const renderFooter = () => {
    if (hidePreviewBtn) {
      return "";
    }
    return (
      <>
        {selectedArticleLength > 0 && selectedArticleLength <= 3 ? (
          <div className="add-content-Footer">
            <div className="active-div-blue" onClick={() => previewBtnClick()}>
              Preview
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    // make this dynamic ==>

    <>
      <div className="add-content-container">
        {!goBackHidden ? (
          <div style={{ color: "#17BAE0", margin: "20px 0px 10px 20px" }}>
            {"< Go back"}
          </div>
        ) : (
          ""
        )}

        <SuggestedKeywords
          selectedKeyword={selectedKeyword}
          clickOnKeyword={clickOnKeyword}
          searchDataCallback={searchDataCallback}
          rxKeywords={props.rxKeywords}
          pemSuggestedKeyFromPrescription={
            props?.pemSuggestedKeyFromPrescription
          }
        />
        <AddContentList
          selectedKeyword={selectedKeyword}
          setSelectedArticlesCallBack={setSelectedArticlesCallBack}
          selectedArticles={props.selectedArticles}
          searchedData={searchedData}
        />
      </div>
      <div className="add-content-footer-container">
        {renderFooter()}
        <CommentTextField
          placeholderContent={"Write Patient Education Content"}
          hidePreviewBtnCallback={hidePreviewBtnCallback}
          previewBtnCallback={previewBtnClick}
          showPreviewBtn={selectedArticleLength > 0}
          patientId={props.patientId}
          pemCloseBtnCallback={props.pemCloseBtnCallback}
          uploadBtnCallback={uploadBtnCallback}
        />
      </div>
    </>
  );
};

export default AddContent;
