import React, { useState } from "react";
import Badge from "react-bootstrap/Badge";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";
import DropDownSelector from "../UI Component/DropDownSelector/DropDownSelector";

export default function ExamItemComponent(props) {
  const {
    Data,
    index,
    handleChange,
    clearRecord,
    getSerachPopulatedData,
    dropDown,
    dropDownSelected,
    handleDeletePrec,
    removeExaminItem,
    dispatchInitializeAutoSave,
  } = props;
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [show, setShow] = useState(false);
  const [pindex, setPIndex] = useState();
  const [exindex, setExamIndex] = useState();

  const userId = localStorage.getItem(LOCALSTORAGEKEY.USER_ID);

  const handleSerachData = (e) => {
    if (e.target.name === "test") {
      setPIndex(index);
      setExamIndex("");
    } else {
      setPIndex("");
      setExamIndex(index);
    }
    clearTimeout(typingTimeout);
    handleChange(e, index);
    if (e.target.value.length > 2) {
      setShow(true);
      const search_field =
        e.target.name === "test" ? "lab_findings" : "examination_instructions";
      const value = e.target.value;
      setTypingTimeout(
        setTimeout(function () {
          getSerachPopulatedData(
            {
              doctor_id: userId,
              search_field: search_field,
              search_text: value,
              user_type: "1",
            },
            search_field + "_" + index
          );
          setShow(false);
        }, 500)
      );
    } else {
      setShow(false);
      dropDownSelected();
    }
  };

  const handleSelect = (item, name) => {
    handleChange({ target: { name, value: item.label } }, index);
    dropDownSelected(item.label, index);
  };

  const handleDelete = (event, item, name) => {
    event && event.stopPropagation();
    const search_field = name;
    handleDeletePrec({
      doctor_id: userId,
      item_id: item.id,
      search_field,
      user_type: "1",
    });
  };

  return (
    <div
      className="drug-card mb-4 shadow-sm rounded prescription_card"
      key={index}
    >
      <div
        className="d-flex justify-content-between"
        style={{ padding: "0px 5px" }}
      >
        <div style={{ position: "relative", bottom: "24px" }}>
          <Badge
            className="rounded-circle p-2 m-2 shadow bg-white rounded"
            variant="secondary"
            style={{
              cursor: "pointer",
              color: "black",
            }}
          >
            {index + 1 >= 10 ? index + 1 : "0" + Number(index + 1)}
          </Badge>
        </div>
        <div style={{ display: "flex" }}>
          <Badge
            onClick={() => clearRecord(index)}
            className="m-2 float-right" //"rounded-circle m-2 float-right"
            variant="light"
            style={{
              cursor: "pointer",
              color: "#17BAE0",
              fontSize: "12px",
              background: "none",
            }}
          >
            Clear
          </Badge>
          <Badge
            onClick={() => {
              removeExaminItem(index);
              dispatchInitializeAutoSave();
            }}
            className="m-2 float-right" //rounded-circle
            variant="light"
            style={{
              cursor: "pointer",
              color: "#17BAE0",
              fontSize: "14px",
              height: "fit-content",
              backgroundColor: "white",
            }}
          >
            <i
              class="fa fa-trash"
              aria-hidden="true"
              style={{ color: "#dc3545" }}
            ></i>
          </Badge>
        </div>
      </div>
      <li
        id={"li_medication_" + index}
        name={"li_medication_" + index + "[]"}
        style={{
          padding: "10px",
          marginBottom: "10px",
          width: "100%",
        }}
      >
        <div className="mb-2">
          <div className="mb-2" style={{ color: "#6f747a" }}>
            <DropDownSelector
              value={Data.test}
              handleSearch={(value) => {
                handleSerachData(value);
              }} //handleSerachData(value, index)
              name={"test"}
              id={`lab_order_test_${index}`}
              options={dropDown.selectState}
              isShowDropDown={
                "lab_findings_" + index === dropDown.isShowDropDown
                  ? true
                  : false
              }
              handleSelect={(item) => handleSelect(item, "test")}
              handleDelete={(event, item) =>
                handleDelete(event, item, "lab_findings")
              }
              indx={index}
              show={show}
              pindex={pindex}
              loading={true}
            />
          </div>
          <div className="mb-2 " style={{ color: "#6f747a" }}>
            <DropDownSelector
              value={Data.instruction}
              handleSearch={(value) => {
                handleSerachData(value);
              }} //handleSerachData(value, index)
              name={"instruction"}
              id={`lab_order_inst_${index}`}
              options={dropDown.selectState}
              isShowDropDown={
                "examination_instructions_" + index === dropDown.isShowDropDown
                  ? true
                  : false
              }
              handleSelect={(item) => handleSelect(item, "instruction")}
              handleDelete={(event, item) =>
                handleDelete(event, item, "examination_instructions")
              }
              indx={index}
              show={show}
              pindex={exindex}
              loading={true}
            />
          </div>
        </div>
      </li>
    </div>
  );
}
