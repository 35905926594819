// dependencies imports ⇒
import React, { useEffect, useRef, useState } from "react";
import Validator from "aadhaar-validator";
// assets and scss imports ⇒
import "./abhaAadharValidationView.scss";
import tickIcon from "../../../assets/tick.svg";
// project file imports(UI components) ⇒
import AbhaActionButton from "../common/AbhaActionButton";
import AbhaCardView from "../common/AbhaCardView";
import TermsAndConditonPopup from "../../common/Popups/TermsAndConditionPopup";
// const file imports(helper methods, etc) ⇒
import { AADHAR_VALIDATION_ERROR, TERMS_AND_CONDITIONS } from "../constant";
import { ENROLL_AADHAR } from "../AbhaAPIHandler/APIConstants";
import { enrollAadhar } from "../AbhaAPIHandler";
import LearnMorePopup from "../../common/Popups/LearnMorePopup";

// project file imports(UI components) ⇒

// const file imports(helper methods, etc) ⇒

const AbhaAadharValidationView = (props) => {
  const { handleAadharData } = props;
  // const declarations ⇒
  const [aadharInput, setAadharInput] = useState({
    first: "",
    middle: "",
    last: "",
  });
  const [inputFocused, setInputFocused] = useState("");
  const [aadharError, setAadharError] = useState(false);
  const [checked, setChecked] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showTermsAndConditionsPopup, setShowTermsAndConditionsPopup] =
    useState(false);
  const [disableActionButton, setDisableActionButton] = useState(true);
  const [errorMessage, setErrorMessage] = useState();
  const firstRef = useRef();
  const middleRef = useRef();
  const lastRef = useRef();

  // life cycle methods ⇒
  useEffect(() => {
    let aadharNumber =
      aadharInput.first + aadharInput.middle + aadharInput.last;
    if (aadharNumber?.length === 12 && checked) {
      setDisableActionButton(false);
    } else {
      setDisableActionButton(true);
    }
  }, [aadharInput, checked]);

  // API Calls ⇒
  const enrollAadharAPICall = (reqPayload) => {
    const aadharResponse = enrollAadhar(ENROLL_AADHAR, reqPayload); //Fetch API Data
    aadharResponse.then(([res, error]) => {
      if (!error) {
        if (res?.data?.statusCode == 200) {
          setDisableActionButton(false);
          let responseData = res?.data;
          let mobileNumber = responseData.message.split(" ");
          handleAadharData(
            reqPayload?.aadhar_number,
            responseData?.txnId,
            mobileNumber[mobileNumber.length - 1]
          );
        } else if (res?.data?.statusCode == 503) {
          setDisableActionButton(false);
          setErrorMessage(res?.data?.message);
          setAadharError(true);
        } else if (res?.data?.statusCode == 400) {
          setDisableActionButton(false);
          setErrorMessage(AADHAR_VALIDATION_ERROR?.THIRD_ERROR);
          setAadharError(true);
        } else {
          setDisableActionButton(false);
          setErrorMessage(
            res?.data?.code
              ? AADHAR_VALIDATION_ERROR.SECOND_ERROR
              : AADHAR_VALIDATION_ERROR.FIRST_ERROR
          );
          setAadharError(true);
        }
      } else {
        setErrorMessage(AADHAR_VALIDATION_ERROR.SECOND_ERROR);
        setAadharError(true);
        setDisableActionButton(false);
      }
    });
  };

  // Actions ⇒
  const handleAadharInput = (e, position) => {
    let value = e.target.value;
    if (value?.length <= 4) {
      setAadharInput({
        ...aadharInput,
        [position]: value,
      });
    }
    if (value.length === 4 && position === "first") {
      middleRef.current.focus();
      setInputFocused("middle");
    } else if (value.length === 4 && position === "middle") {
      lastRef.current.focus();
      setInputFocused("last");
    }
  };

  const handleOnFocus = (position) => {
    setInputFocused(position);
  };

  const handleOnKeyDown = (e) => {
    const pressedKey = e.key;
    switch (pressedKey) {
      case "Backspace":
      case "Delete": {
        if (aadharInput[inputFocused]?.length === 0) {
          if (inputFocused === "last") {
            middleRef.current.focus();
          } else if (inputFocused === "middle") {
            firstRef.current.focus();
          }
        }
        break;
      }
      default: {
        if (
          e.key?.toLowerCase() === "e" ||
          e.key === "+" ||
          e.key === "-" ||
          e.key === "." ||
          e.key === "ArrowUp" ||
          e.key === "ArrowDown"
        ) {
          e.preventDefault();
        }
        break;
      }
    }
  };

  const handleTermsAndCondition = () => {
    setChecked(!checked);
  };

  const handleVerifyAadhar = () => {
    let aadharNumber =
      aadharInput.first + aadharInput.middle + aadharInput.last;
    if (Validator.isValidNumber(aadharNumber)) {
      setAadharError(false);
      setDisableActionButton(true);
      if (checked) {
        let payload = {
          aadhar_number: aadharNumber,
        };
        enrollAadharAPICall(payload);
      }
    } else {
      setErrorMessage(AADHAR_VALIDATION_ERROR?.FIRST_ERROR);
      setAadharError(true);
      setDisableActionButton(false);
    }
  };

  const togglePopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleOnPaste = (e) => {
    e.preventDefault();
    let copiedVaue = e.clipboardData.getData("text/plain");
    let value = copiedVaue.replaceAll(" ", "");
    if (value?.trim()?.length == 12) {
      if (!isNaN(value)) {
        const result = copiedVaue?.trim().match(/.{1,4}/g) || [];
        setAadharInput({
          first: result[0],
          middle: result[1],
          last: result[2],
        });
        lastRef.current.focus();
      }
    }
  };

  // create UI ⇒
  const abhaAadharCreateInstruction = () => {
    return (
      <div className="aavv-ci">
        You can create your ABHA number using Aadhaar instantaneously. Please
        ensure that Aadhaar is linked to a Mobile Number as an OTP
        authentication will follow. If you do not have a Mobile Number linked,
        visit the nearest <br />
        <span>
          <a
            href={"https://facility.ndhm.gov.in/"}
            target="_blank"
            className="aavv-terms-link-selected"
          >
            ABDM participating facility
          </a>{" "}
          and seek assistance.
        </span>
      </div>
    );
  };

  const abhaAadharCreateButton = () => {
    return (
      <div className="aavv-button-container">
        <AbhaActionButton
          title={"Create ABHA"}
          disabled={disableActionButton}
          handleClick={handleVerifyAadhar}
        />
      </div>
    );
  };

  const abhaAadharInputUI = () => {
    return (
      <div>
        <div className="aavv-input-label">Enter Your Aadhaar Number</div>
        <div className="aavv-input-container-view">
          <div
            className={
              "aavv-input-container " +
              (aadharError ? "aavv-input-container-error" : "")
            }
          >
            <input
              className="aavv-input"
              placeholder="****"
              maxLength={4}
              type="number"
              value={aadharInput.first}
              onChange={(e) => handleAadharInput(e, "first")}
              ref={firstRef}
              onKeyDown={handleOnKeyDown}
              onFocus={() => handleOnFocus("first")}
              onPaste={handleOnPaste}
            />
          </div>
          <div
            className={
              "aavv-input-container " +
              (aadharError ? "aavv-input-container-error" : "")
            }
          >
            <input
              className="aavv-input"
              placeholder="****"
              maxLength={4}
              type="number"
              value={aadharInput.middle}
              onChange={(e) => handleAadharInput(e, "middle")}
              ref={middleRef}
              onKeyDown={handleOnKeyDown}
              onFocus={() => handleOnFocus("middle")}
              onPaste={handleOnPaste}
            />
          </div>
          <div
            className={
              "aavv-input-container " +
              (aadharError ? "aavv-input-container-error" : "")
            }
          >
            <input
              className="aavv-input"
              placeholder="****"
              maxLength={4}
              type="number"
              value={aadharInput.last}
              onChange={(e) => handleAadharInput(e, "last")}
              ref={lastRef}
              onKeyDown={handleOnKeyDown}
              onFocus={() => handleOnFocus("last")}
              onPaste={handleOnPaste}
            />
          </div>
        </div>
        {aadharError && <div className="aavv-input-error">{errorMessage}</div>}
      </div>
    );
  };

  const abhaAadharHeadingAndSubheadingUI = () => {
    return (
      <>
        <div className="aavv-subheading">
          <span className="aavv-subheading-bold">ABHA</span> (Health ID) Card
        </div>
        <div className="aavv-about">
          In coordination with NHA, Govt. of India, Connect2Clinic securely
          provides facility to create ABHA No.
        </div>
      </>
    );
  };

  const aabhaTermsAndConditionUI = () => {
    return (
      <div className="aavv-terms-container">
        <div
          onClick={handleTermsAndCondition}
          className={
            "aavv-terms-checkbox " +
            (checked ? "aavv-terms-checkbox-selected" : "")
          }
        >
          {checked && <img src={tickIcon} alt="" />}
        </div>
        <div className="add-time-range-day-selector-copy-text">
          <p className="add-time-range-terms-text">
            I agree to give the necessary permission to Connect2Clinic to set up
            my ABHA ID
            <span className="aavv-terms-Learn-more" onClick={togglePopup}>
              Learn More
            </span>
            <span
              className="aavv-terms-tac"
              onClick={() => {
                setShowTermsAndConditionsPopup(true);
              }}
            >
              Terms and Conditions
            </span>
          </p>
        </div>
      </div>
    );
  };

  const abhaAadharMainHeading = () => {
    return (
      <div className="aavv-main-heading">
        Create Ayushman Bharat Health Account
      </div>
    );
  };

  const abhaAadharValidationUI = () => {
    return (
      <div>
        <AbhaCardView>
          {abhaAadharMainHeading()}
          {abhaAadharHeadingAndSubheadingUI()}
          {abhaAadharInputUI()}
          {abhaAadharCreateInstruction()}
          {aabhaTermsAndConditionUI()}
          {abhaAadharCreateButton()}
        </AbhaCardView>
      </div>
    );
  };
  return (
    <div className="abha-aadhar-validation-view">
      {abhaAadharValidationUI()}
      <LearnMorePopup
        handleClosePopup={handleClosePopup}
        showPopupFlag={showPopup}
      />
      <TermsAndConditonPopup
        showPopupFlag={showTermsAndConditionsPopup}
        handleClosePopup={() => {
          setShowTermsAndConditionsPopup(false);
        }}
      />
    </div>
  );
};
//export
export default AbhaAadharValidationView;
