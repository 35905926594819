import React, { Suspense, Fragment } from 'react';
const DSMRoutes = React.lazy(() => import('../components/DSMPortal/DSMRoutes'));

export default function () {
  return (
    <Fragment>
      {/*! DO NOT CHANGE DSM ROUTES.. ALL ROUTES OF DSM ARE MANAGED BY BELOW COMPONENT!*/}
      <Suspense fallback={<div>Loading...</div>}>
        <DSMRoutes />
      </Suspense>
    </Fragment>
  );
}
