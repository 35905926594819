import React, { useEffect, useRef } from "react";
import "./Loader.scss";
import LoaderLottie from "../../../assets/loader_lottie.json";
import lottie from "lottie-web";

export default function () {
  const lottieContainer = useRef(null);
  useEffect(() => {
    if (lottieContainer.current) {
      const instance = lottie.loadAnimation({
        animationData: LoaderLottie,
        container: lottieContainer.current,
        loop: true,
        autoplay: true,
      });
      return () => instance.destroy();
    }
  }, []);

  return (
    <div className={"loader__root"}>
      <div className="loader-container">
        <div id="loader-logo" ref={lottieContainer}></div>
      </div>
    </div>
  );
}
