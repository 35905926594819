// dependencies imports ⇒
import React, { useRef, useState } from "react";
// assest imports ⇒
import building from "../../../assets/c2cBuilding.png";
import arrowLeft from "../../../assets/forhomepage/arrow_left.png";
import arrowRight from "../../../assets/forhomepage/arrow_right.png";
import "./homePageAboutContainer.scss";
// project file imports(UI components) ⇒
import { BENEFITS, HOME_ABOUT_DETAILS } from "../constant";

const HomePageAboutContainer = (props) => {
  const { aboutRef } = props;
  //const ==>
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [disableArrow, setDisableArrow] = useState(true);
  const containerRef = useRef(null);
  const { heading, content } = HOME_ABOUT_DETAILS.ABOUT_CLINIC;
  //<==

  //methods ==>
  const handleMouseDown = (event) => {
    setIsDragging(true);
    setStartX(event.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
    containerRef.current.style.userSelect = "none";
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    containerRef.current.style.userSelect = "auto";
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const x = event.pageX - containerRef.current.offsetLeft;
    const deltaX = x - startX;
    event.preventDefault();
    containerRef.current.scrollLeft = scrollLeft - deltaX;
  };

  const scrollLeftsArrow = () => {
    if (containerRef.current) {
      setDisableArrow(false);
      containerRef.current.scrollLeft = containerRef.current.scrollLeft + 230; // Adjust the scroll amount as needed
    }
  };

  const scrollRightArrow = () => {
    if (containerRef.current) {
      setDisableArrow(true);
      containerRef.current.scrollLeft = containerRef.current.scrollLeft - 230; // Adjust the scroll amount as needed
    }
  };

  //<==

  //create UI ==>

  const createAboutContaintUI = () => {
    return (
      <div className="about-content">
        <div className="heading">{heading}</div>
        {content.map((paragraph, index) => (
          <p className="paragraph" key={index}>
            {paragraph}
          </p>
        ))}
      </div>
    );
  };

  const createAboutLogoView = () => {
    return (
      <div>
        <img className="mobile-img-web" src={building} alt="" />
      </div>
    );
  };

  const createAboutContantMobileUI = () => {
    return (
      <div>
        <div className="about-mobile-content">
          <div className="mobile-heading">{heading}</div>
        </div>
        {content.map((paragraph, index) => (
          <p className="mobile-paragraph" key={index}>
            {paragraph}
          </p>
        ))}
        <img className="mobile-img" src={building} alt="" />
      </div>
    );
  };

  const benefitsHeaderUI = () => {
    return (
      <div className="home_page_header_benefits_title_main">
        <div className="home_page_header_benefits_title">
          {HOME_ABOUT_DETAILS.BENEFITS}
        </div>
        <div className="left-right-arrow">
          <div className="home_page_header_benefits_arrow_view_container">
            <img
              className={
                "home_page_header_benefits_arrow_view " +
                (disableArrow ? "home_page_about_containt_arrow_disable" : "")
              }
              src={arrowRight}
              alt="Right Arrow"
              onClick={scrollRightArrow}
            />
          </div>
          <div className="home_page_header_benefits_arrow_view_container">
            <img
              className={
                "home_page_header_benefits_left_arrow_view " +
                (!disableArrow ? "home_page_about_containt_arrow_disable" : "")
              }
              src={arrowLeft}
              alt="Left Arrow"
              onClick={scrollLeftsArrow}
            />
          </div>
        </div>
      </div>
    );
  };

  const benefitsHeaderMobileUI = () => {
    return (
      <div className="benefits-header-container">
        <div className="header-text">
          {HOME_ABOUT_DETAILS.BENEFITS_MOBILE_VIEW}
        </div>
        <div className="arrow-images">
          <img src={arrowRight} alt="Right Arrow" onClick={scrollRightArrow} />
          <img src={arrowLeft} alt="Left Arrow" onClick={scrollLeftsArrow} />
        </div>
      </div>
    );
  };

  const benefitsContentUI = () => {
    return (
      <div
        ref={containerRef}
        className="home_page_header_benefits_containt_view"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        <div className="home_page_about_containt_benefits_view">
          {BENEFITS.map((benefit, index) => (
            <div key={index} className="home_page_header_benefits_containt">
              <img src={benefit.image} alt="" />
              <div className="benefits-title-heading">{benefit.title}</div>
              <div className="benefits-pera-text">{benefit.description}</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  //<==

  return (
    <div className="home-page-about-main-container">
      <div>
        <div className="home_page_about_containt_view" ref={aboutRef}>
          {window.innerWidth > 819 && createAboutContaintUI()}
          {window.innerWidth > 819 && createAboutLogoView()}
          {window.innerWidth < 819 && createAboutContantMobileUI()}
        </div>
      </div>
      {window.innerWidth > 819 && benefitsHeaderUI()}
      {window.innerWidth < 819 && benefitsHeaderMobileUI()}
      {benefitsContentUI()}
    </div>
  );
};

export default HomePageAboutContainer;
