import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import moment from "moment";
import "./previousPatientsStyles.scss";
import "./tooltip.scss";
import { Link } from "react-router-dom";

export default function PreviousAppoinmentRow(props) {
  const { appointment, index, actions, selectedPatientIndex } = props;
  const [notValid, setnotValid] = useState(false);
  let short_procedure_name = "";
  if (appointment.procedure_name.length > 15) {
    var entered_procedure_name = appointment.procedure_name;
    var res = entered_procedure_name.substring(0, 16);
    var dots = "...";
    short_procedure_name = res + dots;
  }
  const checkForValid = () => {
    const endTime = new Date(appointment.appointment_end_date_time);
    let dayDiff = moment(
      moment(endTime, "DD-MM-YYYY").add(15, "days")
    ).isAfter();
    let timeDiff = moment(moment(endTime, "HH:mm")).isAfter();
    if (!dayDiff && !timeDiff) {
      setnotValid(true);
    }
  };
  const getDateTime = (date) => {
    let dateTime = date?.split(".");
    return date != ""
      ? moment(dateTime[0]).format("MMM DD, YYYY hh:mm A")
      : null;
  };
  useEffect(() => {
    checkForValid();
  }, [appointment]);

  function getPrescriptionButton(notificationSent, manual_notifiction, action) {
    return (
      <span>
        {/* <Link to={"/prescriptionforpatients/" + appointment.id}> */}
        <Link
          to={{
            pathname: "/prescriptionforpatients/" + appointment?.appointment_id,
            state: {
              prescription_id: appointment.prescription_id,
            },
          }}
        >
          <button
            className="rounded-pill primary-cta-button py-2"
            disabled={
              notificationSent.toLowerCase() === "true" ||
              manual_notifiction.toLowerCase() === "true"
                ? false
                : true
            }
          >
            {" "}
            {/**rounded-pill secondary-button py-2 */}
            <i className="fa fa-eye mx-2" aria-hidden="true"></i>Prescription
          </button>
        </Link>
      </span>
    );
  }

  function getActionSheet(action) {
    //return multiple icons / actions from in here\
    return (
      <div>
        <button
          className={notValid ? "action-button-disabled" : "action-button"}
          data-tooltip="Upload Document"
          data-tooltip-conf="bottom"
          onClick={() => {
            action.viewDocumentAction(appointment);
            action.selectedPatientAction(index);
          }}
          disabled={notValid}
        >
          <i className="fa fa-file-text mx-2" aria-hidden="true"></i>
        </button>
        {appointment.status == "2" ? null : (
          <button
            data-tooltip="View detail"
            data-tooltip-conf="bottom"
            className="action-button"
            onClick={() => {
              action.viewAppointmentAction(appointment);
            }}
          >
            <i className="fa fa-user mx-2" aria-hidden="true"></i>
          </button>
        )}
      </div>
    );
  }

  let patientSelectedStyle = {
    defaultStyle: { opacity: "1" },
    selectedStyle: { opacity: index === selectedPatientIndex ? "1" : "0.5" },
  };

  return (
    <Row
      // lg={6}
      xs="auto"
      // className=" mx-2 mt-3 mb-2 d-none d-sm-none d-md-flex"
      className="flex-column flex-md-row patient-row-individual-row align-items-center shadow-sm my-lg-2 mx-lg-2 border rounded-sm my-2 mx-0 text-md-left text-center"
      style={
        selectedPatientIndex === -1
          ? patientSelectedStyle.defaultStyle
          : patientSelectedStyle.selectedStyle
      }
    >
      <Col className="flex-column align-items-center justify-content-center mb-2 mb-md-0">
        <div style={{ fontSize: "14px" }}>
          Dr. {appointment?.doctor_first_name}{" "}
          {appointment?.doctor_middle_name ? appointment?.middle_name : ""}{" "}
          {appointment?.doctor_last_name}
          {/* {appointment?.rebooking_available == true ? (
            <button
              className="customButton patient-history-button book_followup_btn"
              onClick={() => actions.bookFollowUpAction(appointment)}
            >
              <small style={{ fontSize: "65%" }}>Book Followup</small>
            </button>
          ) : null} */}
        </div>
      </Col>
      <Col className="mb-2 mb-md-0" style={{ fontSize: "14px" }}>
        {/* <small style={{ color: "#F969A4" }}>Patient</small> */}
        <h6 style={{ wordWrap: "break-word", fontSize: "14px" }}>
          {appointment.patient_name}
        </h6>
        <small
          style={{
            backgroundColor: "#404760",
            color: "#fff",
            paddingLeft: "2px",
            paddingRight: "2px",
          }}
        >
          ID:{" "}
          {appointment.appointment_number
            ? appointment.appointment_number
            : "NA"}
        </small>
      </Col>
      <Col className="mb-2 mb-md-0" style={{ fontSize: "14px" }}>
        {/* {appointment.is_follow_up == "True" ? ( */}
        {appointment.consultationType == "followup" ? (
          <button className="customBadge procedure-name-patient-side patient-history-followUp-button">
            <small style={{ fontSize: "65%" }}>FollowUp</small>
          </button>
        ) : // appointment.is_follow_up == "False" &&
        appointment?.consultationType != "followup" &&
          appointment?.consultation_type !== "4" ? (
          <button className="customBadge procedure-name-patient-side">
            <small style={{ fontSize: "65%" }}>Initial consultation </small>
          </button>
        ) : appointment?.procedure_name?.length > 15 ? (
          <button className="customBadge procedure-name-patient-side">
            <small style={{ fontSize: "65%" }}>{short_procedure_name}</small>
          </button>
        ) : (
          <button className="customBadge procedure-name-patient-side">
            <small style={{ fontSize: "65%" }}>
              {" "}
              {appointment.procedure_name}{" "}
            </small>
          </button>
        )}
      </Col>
      <Col className="mb-2 mb-md-0" style={{ fontSize: "14px" }}>
        {/* <FontAwesome className="mr-1 iconSmall" name="calendar" />{" "} */}
        {getDateTime(appointment?.appointment_start_date_time)}
      </Col>
      <Col className="mb-2 mb-md-0">
        {appointment?.rebooking_available == true ? (
          <button
            className="customButton patient-history-button book_followup_btn"
            onClick={() => actions.bookFollowUpAction(appointment)}
          >
            <small style={{ fontSize: "65%", color: "blue" }}>
              Book Followup
            </small>
          </button>
        ) : (
          <button
            disabled={true}
            style={{
              fontSize: "14px",
              background: "transparent",
              color: "gray",
            }}
          >
            Book Now
          </button>
        )}
      </Col>
      <Col className="mb-2 mb-md-0 " style={{ fontSize: "14px" }}>
        {getPrescriptionButton(
          appointment.prescription_notification_sent,
          appointment.manual_rx_notification_sent,
          actions
        )}
      </Col>
      <Col xs={2} className="mb-2 mb-md-0">
        {getActionSheet(actions)}
      </Col>
    </Row>
  );
}
