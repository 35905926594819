export const TERMS_AND_CONDITIONS = [
  {
    serial: "01.",
    description:
      "I am voluntarily sharing my Aadhar Number/Virtual ID issued by the UIDAI, and my demographic information for the purpose of creating an Ayushman Bharat Health Account number (“ABHA number”) and Ayushman Bharat Health Account address (“ABHA Address”). I authorize NHA to use my Aadhar number/ Virtual ID for performing Aadhar based authentication with UIDAI as per the provisions of the Aadhar (Targeted Delivery of Financial and Other Subsidies, Benefits and Services) Act, 2016 for the aforesaid purpose. I understand that UIDAI will share my e-KYC details, or response of “Yes” with NHA upon successful authentication.",
  },
  {
    serial: "02.",
    description:
      "I intend to create Ayushman Bharat Health Account Number (“ABHA number”) and Ayushman Bharat Health Account address (“ABHA Address”) using document other than Aadhar.",
  },
  {
    serial: "03.",
    description:
      "I consent to usage of my ABHA address and ABHA number for linking of my legacy (past) health records and those which will be generated during this encounter.",
  },
  {
    serial: "04.",
    description:
      "I authorize the sharing of all my health records with healthcare provider(s) for the purpose of providing healthcare services to me during this encounter.",
  },
  {
    serial: "05.",
    description:
      "I consent to the anonymization and subsequent use of my health records for public health purposes.",
  },
  {
    serial: "06.",
    description:
      "Doctor confirm that I have duly informed and explained the beneficiary of the contents of consent for future purposes",
  },
  {
    serial: "07.",
    description:
      "Patient, have been explained about the consent as stated above and hereby provide my consent for the aforementioned purposes",
  },
];
