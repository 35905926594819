// dependencies imports ⇒
import React from "react";
// assets and scss imports ⇒
import "./abhaActionButton.scss";
import ArrowButton from "../../../../assets/buttonArrow.svg";

const AbhaActionButton = (props) => {
  const { title, disabled, handleClick } = props;

  // create UI ⇒
  const abhaActionButtonUI = () => {
    return (
      <div
        className={"aab-button " + (disabled ? "aab-button-disabled" : "")}
        onClick={() => {
          !disabled && handleClick();
        }}
      >
        <div className="aab-button-title">{title}</div>
        <div>
          <img src={ArrowButton} alt="" className="aab-button-image" />
        </div>
      </div>
    );
  };
  return <div className="aab-container">{abhaActionButtonUI()}</div>;
};

//export
export default AbhaActionButton;
