// dependecies import ==>
import React from "react";
// assets and scss import ==>
import "./secondaryButton.scss";
// project file import ==>
// constant import ==>

const SecondaryButton = (props) => {
  const { title, disabled, handleButtonClick } = props;

  // Display UI ===>

  const displaySecondaryButton = () => {
    return (
      <div
        className={
          "secondary-button-container " +
          (disabled ? "secondary-button-container-disabled" : "")
        }
        onClick={() => (!disabled ? handleButtonClick() : "")}
      >
        {title ?? "No"}
      </div>
    );
  };

  // <===

  return <>{displaySecondaryButton()}</>;
};

export default SecondaryButton;
