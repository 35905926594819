import React, { Component } from "react";
import "../styles/SearchedQuery.scss";
import Row from "react-bootstrap/Row";
import "../styles/SearchedQuery.scss";
import "../styles/MostAskedQuestion.scss";

class Query extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  render() {
    return (
      <Row>
        <div
          onClick={() => {
            this.props.makeCollapse(
              this.props.query.question,
              this.props.query.answer
            );
          }}
          className="search-card-text mx-4 "
        >
          {this.props.query.question.substring(0, 2) === `No` ? (
            <div>
              No match found. Please try to rephrase your query or reach out to
              our customer care.
            </div>
          ) : (
            this.props.query.question
          )}
        </div>
        <hr className="questions-list-hr " />
      </Row>
    );
  }
}

export default Query;
