import React, { Component, useRef } from "react";
import { Link } from "react-router-dom";
import afterLoginApiAxios from "../Util/API Calls/afterLogin";

import Loader from "./UI Component/Loader/Loader";
import { patientFiles, doctorFiles } from "../Util/API Calls/APIList";
import moment from "moment";
import swal from "sweetalert";
import afterLogin from "../Util/API Calls/afterLogin";
import PrescriptionPdf from "./PrescriptionPdf";
import { getSignedUrl } from "../Util/API Calls/SignedAPIUrl";
import { withRouter } from "react-router";
import DownloadIcon from "../assets/download.svg";
import ShareIcon from "../assets/Share.svg";
import PrintIcon from "../assets/PrintIcon.svg";
import ShareManualRxModal from "./ManualRX/ShareManualRxMoadal";
import DoctorViewManualRX from "./ManualRX/DoctorManualRx";
import DoctorViewERX from "./ManualRX/DoctorERx";
import DoctorSavedErx from "./ManualRX/DoctorSavedErx";
import { C2CConsole } from "../Util/Helper/C2CConsole";

const ref = React.createRef();

const options = {
  orientation: "portrait",
  unit: "px",
  format: [900, 1000],
};

class PrescriptionsDoctor extends Component {
  constructor(props, context) {
    super(props, context);

    var id = this.props.match.params.id;
    var FullUrlPath = props.location.pathname;
    var FullUrlPathArr = FullUrlPath.split("/");
    var FullUrlPathArrPop = FullUrlPathArr.pop();
    var FullUrlPathArrPopIndex = FullUrlPathArr.splice(
      FullUrlPathArr.indexOf(FullUrlPathArrPop),
      1
    );
    var urlPath = FullUrlPathArr.join("/");

    var appointmentId = props.location.state?.appointmentId;

    this.state = {
      // appID: "cbb12d71-a177-47df-8610-5411fd25fd27",
      appID: id,
      prescription: null,
      prescription_info: null,
      URL: patientFiles,
      DoctorURL: doctorFiles,
      showButton: true,
      upload_prescriptions: null,
      prev_prescription: [],
      file: [],
      message: "",
      loaderFlag: false, //true,
      presMsg: "",
      size_exceeded: false,
      invalid_file: false,
      editButtonStatus: true,
      endTimeStatus: "",
      urlPath: urlPath,
      appointmentId: appointmentId,
      sharedErxList: [],
      manualRxList: [],
      savedErxList: [],
    };
    this.downloadpdf = this.downloadpdf.bind(this);
    this.print = this.print.bind(this);
    this.getFiles = this.getFiles.bind(this);
    this.fileCheck = this.fileCheck.bind(this);
    this.submitPrescription = this.submitPrescription.bind(this);
    this.deletePrescription = this.deletePrescription.bind(this);

    this.temp_file_arr = [];
  }

  downloadpdf(event) {
    this.setState({
      loaderFlag: true,
    });
    afterLogin
      .post(
        "/patient/prescription-pdf",
        {
          appointment_id: this.state.appID,
        },
        {
          headers: {
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then((response) => {
        this.setState({
          loaderFlag: false,
        });
        const presDate = moment(
          this.state.prescription.appointment_date
        ).format("DD MMM YYYY");
        const linkSource = `data:application/pdf;base64,${response.data.pdf_blob}`;
        const downloadLink = document.createElement("a");
        const fileName = `Prescription_${presDate}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      })
      .catch(function (error) {
        this.setState({
          loaderFlag: false,
        });
        swal({
          title: "OOPS",
          text: "Prescription could not be downloaded",
          icon: "warning",
        });
      });
  }

  print() {
    window.print();
  }

  getFiles(files) {
    this.temp_file_arr = [];
    this.setState({ file: files });

    for (var i = 0; i < this.state.file.length; i++) {
      this.fileCheck(this.state.file[i].name, i);
    }
  }

  deletePrescription(index) {
    if (this.state.upload_prescriptions) {
      this.setState({
        loaderFlag: true,
      });

      afterLoginApiAxios
        .post(
          "doctor/delete-uploaded-prescription",
          {
            appointment_id: this.state.appID,
            prescriptions_url: [this.state.upload_prescriptions[index]],
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("id_token"),
            },
          }
        )
        .then((response) => {
          if (response) {
            var responseData = response.data;
            if (response.status == 403) {
              this.props.history.push("/doctor/login");
            }
            if (responseData) {
              if (response.data.hasOwnProperty("statusCode")) {
                if (response.data.statusCode == 200) {
                  this.setState({
                    loaderFlag: false,
                  });

                  swal({
                    title: "Prescription Deleted!",
                    type: "success",
                  }).then(function () {
                    window.location.reload();
                  });
                } else {
                  this.setState({
                    loaderFlag: false,
                  });

                  this.setState(
                    {
                      message: response.data.message,
                    },
                    () => {}
                  );
                }
              } else {
                this.setState(
                  {
                    response_message: "Something Went Wrong!Please Try Again!!",
                    dataload: false,
                  },
                  () => {}
                );
              }
            }
          }
        })
        .catch(function (error) {
          C2CConsole.log(error);
        });
    } else {
      this.setState({
        message: "No file selected",
      });
    }
  }

  fileCheck(obj, index) {
    var filePath = obj;
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.svg|\.pdf|\.doc)$/i;
    if (!allowedExtensions.exec(filePath)) {
      alert(
        "Please upload file having extensions .jpeg/.jpg/.png/.gif/.svg/.pdf/.doc only."
      );
      this.setState(
        {
          message: "Invalid File Type.",
          file: "",
          invalid_file: true,
        },
        () => {}
      );
      // if(this.state.file != null){
      //  imageName = this.state.file.name;
      //  image64 = this.state.file.base64;
      // }
    } else {
      //var file;
      //file.file_name = this.state.file[index].name;
      //file.file_base64 = this.state.file[index].base64;

      var test_arr = {};
      test_arr.name = this.state.file[index].name;
      test_arr.value = this.state.file[index].base64;
      this.temp_file_arr.push(test_arr);
      var current_file_size = this.state.file[index].file.size / 1024 / 1024;
      var size_exceeded = current_file_size > 20 ? true : false;
      if (size_exceeded) {
        alert("Please upload a file less than 20 MB");
        this.setState(
          {
            message: "File size exceeds 20 MB. Please choose another file.",
            size_exceeded: size_exceeded,
            invalid_file: false,
          },
          () => {}
        );
      } else {
        this.setState(
          {
            message: "",
            presc: this.temp_file_arr,
            size_exceeded: size_exceeded,
            invalid_file: false,
          },
          () => {}
        );
      }
    }
  }

  fileCheckUpload = async (files) => {
    let prec = [];
    for (const file of files) {
      let signedUrl = await getSignedUrl(
        {
          file_size: file.size,
          doc_title: file.name,
          user_type: localStorage.getItem("user_type"),
          use_case: "upload-prescription",
        },
        file.file
      );
      if (signedUrl) {
        prec.push({
          value: signedUrl.fields["key"],
          name: file.name,
        });
      }
    }
    if (prec.length == files.length) return prec;
  };

  async submitPrescription() {
    var that = this;
    if (this.state.presc) {
      this.setState({
        loaderFlag: true,
      });

      // let prec = await this.fileCheckUpload(this.state.file)

      afterLoginApiAxios
        .post(
          "doctor/upload-prescription",
          {
            appointment_id: this.state.appID,
            prescriptions: this.state.presc,
            // prescriptions: prec
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("id_token"),
            },
          }
        )
        .then((response) => {
          if (response) {
            var responseData = response.data;
            this.setState({
              loaderFlag: false,
            });
            if (responseData) {
              if (response.data.hasOwnProperty("statusCode")) {
                if (response.data.statusCode == 200) {
                  swal({
                    title: "Prescription Uploaded!",
                    type: "success",
                  }).then(function () {
                    window.location.reload();
                  });
                  this.setState({
                    message: "Upload Successful",
                  });
                } else {
                  alert(response.data.message);
                  this.setState(
                    {
                      message: response.data.message,
                    },
                    () => {}
                  );
                }
              } else {
                alert("Upload failed");
                this.setState(
                  {
                    message: "Upload Failed",
                    response_message: "Something Went Wrong!Please Try Again!!",
                    dataload: false,
                  },
                  () => {}
                );
              }
            }
          }
        })
        .catch(function (error) {
          alert("Upload failed");
          that.setState({
            message: "Upload Failed",
          });
        });
    } else {
      this.setState({
        message: "No file selected",
      });
    }
  }

  render() {
    var styles = {
      cardOdd: {
        backgroundColor: "#eeae01",
        color: "#454545",
      },
      cardEven: {
        backgroundColor: "#454545",
        color: "#eeae01",
      },
    };

    let is_allow = localStorage.getItem("upload_manual_prescriptions_allow");
    return (
      <div className="rx_background">
        {this.state.loaderFlag ? <Loader /> : null}
        {/* <div className="row pt-4" style={{ backgroundColor: "#fff" }}>
          <div className="col-sm-12 col-md-4 text-center">
            <img
              src={process.env.PUBLIC_URL + "/images/HN_logo.png"}
              style={{ width: "70%" }}
            />
          </div>

          <div className="col-sm-12 col-md-8 text-center pt-4">
            
              <Link
                className="mr-5 otp-btn"
                style={{ color: "#fff" }}
                to="/doctoraccount"
              >
                Back
              </Link>
           
          </div>
        </div> */}

        {/* <div className="container"> */}
        <div className="row m-rx-border-b m-0 mb-3 bg-white">
          {" "}
          {/*view-prescriprion-section*/}
          <div className="w-100 mx-4">
            <h4 className="my-3">
              <b>VIEW PRESCRIPTION</b>
            </h4>
          </div>
          <div className="mx-4">
            <h4>
              {/* <strong> */}
              {/* <b> */}
              {/* passing page number back when we click on goback */}
              <button
                style={{ background: "transparent" }}
                onClick={() => this?.props?.history.goBack()}
              >
                {/* <i className="fa fa-chevron-circle-left mr-3" style={{ color: "#17BAE0" }} ></i> */}
                <span style={{ color: "#17bae0", marginRight: "20px" }}>
                  {"<"} Go Back
                </span>
              </button>
              {"   "}

              {/* </strong> */}
            </h4>
          </div>
        </div>

        <div className="row  mb-5 view-prescriprion-section">
          {this.state.savedErxList.length > 0 && (
            <div className="rx_subheading my-3">
              Saved E-Prescriptions ({this.state.savedErxList.length})
            </div>
          )}
          <div className="w-100 mb-3 bg-white">
            <DoctorSavedErx
              appointment_id={this.state.appID}
              setSavedErxList={(list) =>
                this.setState({
                  savedErxList: list,
                })
              }
            />
          </div>
          {this.state.manualRxList.length > 0 ||
          this.state.sharedErxList.length > 0 ? (
            <div className="rx_subheading my-3">
              Shared Prescriptions (
              {this.state.manualRxList.length + this.state.sharedErxList.length}
              )
            </div>
          ) : null}
          <div className="w-100 mb-3 bg-white">
            <DoctorViewManualRX
              appointment_id={this.state.appID}
              setManualRxList={(list) =>
                this.setState({
                  manualRxList: list,
                })
              }
            />
          </div>
          <div className="w-100 mt-3 bg-white">
            <DoctorViewERX
              appointment_id={this.state.appID}
              setSharedErxList={(list) =>
                this.setState({
                  sharedErxList: list,
                })
              }
            />
          </div>
        </div>

        {/** commenting previous e-rx code
         *
         * starting
         */}

        {/* {this.state.prescription ? (
          <PrescriptionPdf
            downloadpdf={this.downloadpdf}
            appointment_id={this.state.appID}
          />
        ) : (
          <p className="p-5 m-5 text-center">{this.state.presMsg}</p>
        )} */}

        {/** ending */}

        {/* {this.state.prescription ? (
            <div className="row mt-3" ref={ref}>
              <div
                className="maintable w-100 p-4"
                style={{ backgroundColor: "#fff" }}
                id={"capture"}
              >
                <div className="container">
                  <div className="top-ttl b-bm">
                    <div>
                      <div className="col-md-2 date"></div>
                      <div className="col-md-4 offset-md-2"></div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 date pt-3">
                        <b>
                          <strong>
                            Dr. {this.state.prescription.first_name}{" "}
                            {this.state.prescription.last_name}
                          </strong>
                        </b>
                        <br />
                        <span style={{ color: "#F969A4" }}>
                          {this.state.prescription.degree} <br />
                          <span style={{ color: "#878782" }}>
                            Regd No: {this.state.prescription.medical_reg_no}
                            <br />
                            Phone No: {
                              this.state.prescription.doctor_phone_no
                            }{" "}
                          </span>
                        </span>{" "}
                        <br />
                      </div>
                      <div className="col-md-5">
                        Name: <b> {this.state.prescription.patient_name} </b>{" "}
                        <br />
                        Phone No:{" "}
                        <b> {this.state.prescription.patient_phone_no} </b>
                        <br />
                        Appointment Date:{" "}
                        <b>
                          {moment(
                            this.state.prescription.appointment_date
                          ).format("DD-MM-YYYY")}
                        </b>{" "}
                        <br />
                        Appointment Time:{" "}
                        <b>
                          {this.state.prescription.start_time} -{" "}
                          {this.state.prescription.end_time}
                        </b>
                      </div>

                      <div className="col-md-3">
                        <div className="add-rec pt-5">
                          <Pdf targetRef={ref} filename="prescription.pdf" options={options}>
									        {({ toPdf }) => <button className="btn btn-cn" onClick={toPdf}>Generate Pdf</button>}
											</Pdf>
                          {this.state.showButton ? (
                            <button
                              id="dwnBtn"
                              type="button"
                              className="btn otp-btn downloadBtn"
                              title="Click here to download pdf"
                              onClick={this.downloadpdf}
                              style={{ width: "auto", lineHeight: "0" }}
                            >
                              Download PDF
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="patient-data">
                    <form>
                      <div className="row b-bm mt-3">
                        <div
                          className="patient-content p-4"
                          style={{ backgroundColor: "#e7f6f9" }}
                        >
                          <ul>
                            <li>
                              <div className="row">
                                <div className="col-12">
                                  <h6 style={{ color: "#2f2f2f" }}>
                                    Clinical Notes
                                  </h6>
                                </div>
                                <div
                                  className="col-md-4"
                                  style={{ color: "#616161" }}
                                >
                                  Complaints
                                </div>
                                <div
                                  className="col-md-8"
                                  style={{
                                    color: "#757575",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {this.state.prescription.complaints}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="row">
                                <div
                                  className="col-md-4"
                                  style={{ color: "#616161" }}
                                >
                                  Observations
                                </div>
                                <div
                                  className="col-md-8"
                                  style={{
                                    color: "#757575",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {this.state.prescription.observations}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="row">
                                <div
                                  className="col-md-4"
                                  style={{ color: "#616161" }}
                                >
                                  Diagnosis
                                </div>
                                <div
                                  className="col-md-8"
                                  style={{
                                    color: "#757575",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {this.state.prescription.diagnosis}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="row b-bm mt-3">
                        <div
                          className="patient-content p-4"
                          style={{ backgroundColor: "#efeff0" }}
                        >
                          <ul>
                            <li>
                              <div className="row ">
                                <div className="col-12">
                                  <h6 style={{ color: "#2f2f2f" }}>
                                    Lab Orders
                                  </h6>
                                </div>
                                <div
                                  className="col-md-6"
                                  style={{ color: "#616161" }}
                                >
                                  TEST
                                </div>
                                <div
                                  className="col-md-6"
                                  style={{ color: "#616161" }}
                                >
                                  INSTRUCTION
                                </div>
                              </div>
                            </li>

                            {this.state.prescription.lab_results.map(
                              (test, index) => {
                                return (
                                  <li>
                                    <div className="row">
                                      <div
                                        className="col-md-6"
                                        style={{
                                          color: "#757575",
                                          wordBreak: "break-word",
                                        }}
                                      >
                                        {test.test}
                                      </div>
                                      <div
                                        className="col-md-6"
                                        style={{
                                          color: "#757575",
                                          wordBreak: "break-word",
                                        }}
                                      >
                                        {test.instruction}
                                      </div>
                                    </div>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="row b-bm mt-3">
                        <div
                          className="patient-content p-4"
                          style={{ backgroundColor: "#e7f6f9" }}
                        >
                          <ul>
                            <li>
                              <div className="row ">
                                <div className="col-12">
                                  <h6 style={{ color: "#2f2f2f" }}>
                                    Medications
                                  </h6>
                                </div>
                                <div
                                  className="col-md-3"
                                  style={{ color: "#616161" }}
                                >
                                  DRUG{" "}
                                </div>
                                <div
                                  className="col-md-3"
                                  style={{ color: "#616161" }}
                                >
                                  FREQUENCY{" "}
                                </div>
                                <div
                                  className="col-md-3"
                                  style={{ color: "#616161" }}
                                >
                                  DURATION{" "}
                                </div>
                                <div
                                  className="col-md-3"
                                  style={{ color: "#616161" }}
                                >
                                  INSTRUCTIONS
                                </div>
                              </div>
                            </li>

                            {this.state.prescription.medications.map(
                              (medicine, index) => {
                                return (
                                  <li>
                                    <div className="row ">
                                      <div
                                        className="col-md-3"
                                        style={{
                                          color: "#757575",
                                          wordBreak: "break-word",
                                        }}
                                      >
                                        {medicine.drug}
                                      </div>

                                      <div
                                        className="col-md-3"
                                        style={{
                                          color: "#757575",
                                          wordBreak: "break-word",
                                        }}
                                      >
                                        {medicine.frequency}
                                      </div>
                                      <div
                                        className="col-md-3"
                                        style={{
                                          color: "#757575",
                                          wordBreak: "break-word",
                                        }}
                                      >
                                        {medicine.duration}
                                      </div>
                                      <div
                                        className="col-md-3"
                                        style={{
                                          color: "#757575",
                                          wordBreak: "break-word",
                                        }}
                                      >
                                        {medicine.instruction}
                                      </div>
                                    </div>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>

                      <div className="row b-bm mt-3">
                        <div
                          className="patient-content p-4"
                          style={{ backgroundColor: "#efeff0" }}
                        >
                          <ul>
                            <li>
                              <div className="row ">
                                <div className="col-12">
                                  <h6 style={{ color: "#2f2f2f" }}>
                                    Recommendation
                                  </h6>
                                </div>
                                <div
                                  className="col-md-12"
                                  style={{ color: "#616161" }}
                                >
                                  {this.state.prescription
                                    .doctor_recommendation == 0
                                    ? "NA"
                                    : null}
                                  {this.state.prescription
                                    .doctor_recommendation == 1
                                    ? "In-person Visit"
                                    : null}
                                  {this.state.prescription
                                    .doctor_recommendation == 2
                                    ? "Virtual Visit"
                                    : null}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </form>

                    <div className="row col-md-12 mt-2">
                      This is an electronically generated prescription . It does
                      not need any signature
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p className="p-5 m-5 text-center">{this.state.presMsg}</p>
          )} */}
        {/* </div> */}

        <div
          className="modal fade"
          id="registerModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Register
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-12 text-left">
                    <label>Phone No.</label>
                    <input type="text" name="phone" className="w-100" />
                  </div>

                  <div className="col-sm-12">
                    <label>OTP</label>
                    <input type="text" name="otp" className="w-100" />
                  </div>

                  <div className="col-sm-4">
                    <Link
                      className="btn btn-sm mt-2"
                      style={{ color: "#454545", backgroundColor: "#eeae01" }}
                      data-toggle="modal"
                      data-target="#registerModal"
                    >
                      <b>Register</b>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ShareManualRxModal show={false} />
      </div>
    );
  }
}

export default withRouter(PrescriptionsDoctor);
