import moment from "moment";

export const APPLICABLE_ROLE = {
  DOCTOR: "doctor",
  STAFF: "staff",
};

export const APPLICABLE_SUB_ROLE = {
  DOCTORADMIN: "Administrator",
  DOCTOR_CONSULTANT: "consultant",
  RESOURCE_CONSULTANT: "resource_consultant",
  RESOURCE_ADMIN: "resource_administrator",
};

export const APPLICABLE_ACCOUNT_TYPE = {
  DOCTOR: 0,
  RESOURCE: 1,
};

export const HEADERLIST = {
  PATIENTDETAILS: "PATIENT DETAILS",
  DOCTORNAME: "ACCOUNT",
  CONSULTATION: "PROCEDURE",
  STATUS: "STATUS",
  DATETIME: "DATE & TIME",
  MOBILEDATE: "DATE",
  PRESCRIPTION: "PRESCRIPTION",
  ACTION: "ACTION",
};

export const APIFILTERLIST = {
  CONSULTATION: "consultation_filter",
  PROCEDURE: "procedure_type_filter",
  STARTDATE: "start_date",
  ENDDATE: "end_date",
  DOCTORNAME: "doctor_ids",
  DOCTOR_NAME: "doctor_name",
  SEARCHSTRING: "search_string",
  SORTORDER: "sort_order",
  STATUS: "status_filter",
  PAGENO: "page_no",
  CONSULTATION_TYPE: "consultation_type",
  IS_PROCEDURE: "is_procedure",
  PRESCRIPTION_FILTER: "prescription_filter",
};

export const SELECTEDFILTERTITLE = {
  CONSULTATION: "Consultation",
  DATE: "Selected Period",
  DOCTORNAME: "Doctor Name",
  CLEARALL: "Clear All Filter",
  STATUS: "Appointment Status",
  PRESCRIPTION_FILTER: "Prescription",
};

export const CONSULTATIONTYPE = {
  INCLINIC: "in-clinic",
  TELECONSULT: "Tele-Consult",
  REGULAR: "Regular",
  FOLLOWUP: "followup",
  FOLLOW_UP: "Follow-Up",
  PROCEDURE: "procedure",
  INSTANT: "Instant",
  ONLINE: "online",
};

export const STATUS = [
  {
    name: "confirmed",
    color: "#FFB400",
    backgroundColor: "#FFB40033",
  },
  {
    name: "checked in",
    color: "#F07223",
    backgroundColor: "#F0722333",
  },
  {
    name: "in progress",
    color: "#10A74A",
    backgroundColor: "#10A74A33",
  },
  {
    name: "completed",
    color: "#E0228B",
    backgroundColor: "#E0228B33",
  },
  {
    name: "cancelled",
    color: "#DA1346",
    backgroundColor: "#DA134633",
  },
  {
    name: "no show",
    color: "#A3A9B7",
    backgroundColor: "#A3A9B733",
  },
];

export const HEADER_FILTER_TYPE = {
  NORMAL: "normal",
  DATE: "date",
  CONSULTATION: "consultation",
  DOCTORNAME: "doctorname",
};

export const STATUS_FILTER = [
  {
    name: "All",
    value: "all",
    selected: true,
  },
  {
    name: "Confirmed",
    value: "confirmed",
    selected: false,
  },
  {
    name: "Checked In",
    value: "checked in",
    selected: false,
  },
  {
    name: "In Progress",
    value: "in progress",
    selected: false,
  },
  {
    name: "Completed",
    value: "completed",
    selected: false,
  },
  {
    name: "Cancelled",
    value: "cancelled",
    selected: false,
  },
  {
    name: "No Show",
    value: "no show",
    selected: false,
  },
];

export const CONSULTATION_FILTER_MENU = [
  {
    name: "In-Clinic - Regular",
    value: "inclinic",
  },
  {
    name: "In-Clinic - Follow-Up",
    value: "inclinic",
  },
  {
    name: "Tele-Consult - Regular",
    value: "online",
  },
  {
    name: "Tele-Consult - Follow-Up",
    value: "online",
  },
  {
    name: "Instant Consultation",
    value: "instant",
  },
];

export const SORT_MENU_ITEMS = [
  {
    name: "Least Recent First",
    value: "least_recent_first",
  },
  {
    name: "Most Recent First",
    value: "most_recent_first",
  },
  {
    name: "Patient Name (A-Z)",
    value: "patient_name_az",
  },
  {
    name: "Patient Name (Z-A)",
    value: "patient_name_za",
  },
];

export const PRESCRIPTION_FILTER_ITEMS = [
  {
    name: "All",
    value: "all",
    hide: false,
  },
  {
    name: "Laboratory Procedures",
    value: "laboratory_procedures",
    hide: true,
  },
  {
    name: "Clinic Appointments",
    value: "clinic_appointments",
    hide: true,
  },
  {
    name: "Prescription Shared",
    value: "shared",
    hide: false,
  },
  {
    name: "Prescription Created and Not Shared",
    value: "created",
    hide: false,
  },
  {
    name: "Prescription Not Created",
    value: "",
    hide: false,
  },
];

export const PRESCRIPTION_KEYS = {
  CREATED: "created",
  EDITED: "edited",
  ALL: "all",
  FILTER: "Filter",
  LAB_PROCEDURES: "laboratory_procedures",
  CLINIC_APPOINTMENTS: "clinic_appointments",
};

export const DEFAULT_DASHBOARD_PAYLOAD = {
  sort_order: "least_recent_first",
  consultation_filter: "all",
  page_no: 1,
  search_string: "",
  rows_per_page: 10,
  dr_name: "",
  start_date: moment().format("YYYY-MM-DD"),
  end_date: "",
  status_filter: "all",
  appointment_status_filter: "all",
};

export const dateFilter = [
  {
    name: "Today",
    value: "Today",
  },
  {
    name: "Last 7 Days",
    value: "Last 7 Days",
  },
  {
    name: "Last 30 Days",
    value: "Last 30 Days",
  },
  {
    name: "Date Range",
    value: "Date Range",
  },
];

export function getDateByRanges(value) {
  let dateRange = {
    startDate: new Date(),
    endDate: new Date(),
  };
  const Today = new Date();
  const today = moment(new Date()).format("YYYY-MM-DD");

  const lastWeekMonth = moment(
    new Date(Today.getFullYear(), Today.getMonth() - 1, Today.getDate())
  ).format("YYYY-MM-DD");

  const lastWeek = moment(
    new Date(Today.getFullYear(), Today.getMonth(), Today.getDate() - 7)
  ).format("YYYY-MM-DD");

  if (!value || value == "Today") {
    dateRange = {
      startDate: today,
      endDate: today,
    };
  } else if (value == "Last 7 Days") {
    dateRange = {
      startDate: lastWeek,
      endDate: today,
    };
  } else if (value == "Last 30 Days") {
    dateRange = {
      startDate: lastWeekMonth,
      endDate: today,
    };
  }
  return dateRange;
}

export const TELECONSULT_FILTER_MENU = [
  {
    name: "Tele-Consult - Regular",
    value: "Tele-Consult Regular",
    type: "default",
  },
  {
    name: "Tele-Consult - Follow-Up",
    value: "Tele-Consult Follow-Up",
    type: "default",
  },
];

export const INCLINIC_FILTER_MENU = [
  {
    name: "In-Clinic - Regular",
    value: "In-Clinic Regular",
    type: "default",
  },
  {
    name: "In-Clinic - Follow-Up",
    value: "In-Clinic Follow-Up",
    type: "default",
  },
];

export const STATUS_COUNT = [
  {
    name: "Total Appts",
    value: "total",
    selected: true,
    applicableConsultationType: [
      CONSULTATIONTYPE.INCLINIC,
      CONSULTATIONTYPE.ONLINE,
      CONSULTATIONTYPE.INSTANT.toLowerCase(),
    ],
  },
  {
    name: "Confirmed",
    value: "confirmed",
    selected: false,
    applicableConsultationType: [
      CONSULTATIONTYPE.INCLINIC,
      CONSULTATIONTYPE.ONLINE,
      CONSULTATIONTYPE.INSTANT.toLowerCase(),
    ],
  },
  {
    name: "Checked In",
    value: "checked in",
    selected: false,
    applicableConsultationType: [CONSULTATIONTYPE.INCLINIC],
  },
  {
    name: "In Progress",
    value: "in progress",
    selected: false,
    applicableConsultationType: [
      CONSULTATIONTYPE.INCLINIC,
      CONSULTATIONTYPE.ONLINE,
      CONSULTATIONTYPE.INSTANT.toLowerCase(),
    ],
  },
  {
    name: "Completed",
    value: "completed",
    selected: false,
    applicableConsultationType: [
      CONSULTATIONTYPE.INCLINIC,
      CONSULTATIONTYPE.ONLINE,
      CONSULTATIONTYPE.INSTANT.toLowerCase(),
    ],
  },
  {
    name: "Cancelled",
    value: "cancelled",
    selected: false,
    applicableConsultationType: [
      CONSULTATIONTYPE.INCLINIC,
      CONSULTATIONTYPE.ONLINE,
    ],
  },
  {
    name: "No Show",
    value: "no show",
    selected: false,
    applicableConsultationType: [
      CONSULTATIONTYPE.INCLINIC,
      CONSULTATIONTYPE.ONLINE,
      CONSULTATIONTYPE.INSTANT.toLowerCase(),
    ],
  },
];

export const PAYMENT_METHOD_HEADER = [
  {
    name: "ALL",
    value: "all",
    selected: true,
  },
  {
    name: "Paid Online",
    value: "online",
    selected: false,
  },
  {
    name: "Pay at Clinic",
    value: "cash",
    selected: false,
  },
  {
    name: "Partial Payment",
    value: "partial",
    selected: false,
  },
];

export const STATUS_COUNT_QUEUE = [
  {
    name: "Total Appts",
    value: "total",
    selected: true,
    applicableConsultationType: [
      CONSULTATIONTYPE.INCLINIC,
      CONSULTATIONTYPE.ONLINE,
      CONSULTATIONTYPE.INSTANT.toLowerCase(),
    ],
    disabled: false,
  },
  {
    name: "Confirmed",
    value: "confirmed",
    selected: false,
    applicableConsultationType: [
      CONSULTATIONTYPE.INCLINIC,
      CONSULTATIONTYPE.ONLINE,
      CONSULTATIONTYPE.INSTANT.toLowerCase(),
    ],
    disabled: false,
  },
  {
    name: "Checked In",
    value: "checked in",
    selected: false,
    applicableConsultationType: [CONSULTATIONTYPE.INCLINIC],
    disabled: false,
  },
  {
    name: "In Progress",
    value: "in progress",
    selected: false,
    applicableConsultationType: [
      CONSULTATIONTYPE.INCLINIC,
      CONSULTATIONTYPE.ONLINE,
      CONSULTATIONTYPE.INSTANT.toLowerCase(),
    ],
    disabled: false,
  },
  {
    name: "Completed",
    value: "completed",
    selected: false,
    applicableConsultationType: [
      CONSULTATIONTYPE.INCLINIC,
      CONSULTATIONTYPE.ONLINE,
      CONSULTATIONTYPE.INSTANT.toLowerCase(),
    ],
    disabled: true,
  },
  {
    name: "Cancelled",
    value: "cancelled",
    selected: false,
    applicableConsultationType: [
      CONSULTATIONTYPE.INCLINIC,
      CONSULTATIONTYPE.ONLINE,
    ],
    disabled: true,
  },
  {
    name: "No Show",
    value: "no show",
    selected: false,
    applicableConsultationType: [
      CONSULTATIONTYPE.INCLINIC,
      CONSULTATIONTYPE.ONLINE,
      CONSULTATIONTYPE.INSTANT.toLowerCase(),
    ],
    disabled: true,
  },
];

export const DASHBOARD_VIEW = {
  LIST: "list",
  QUEUE: "queue",
};

export const QUEUE_STATUS = {
  COMPLETED: "completed",
  CANCELLED: "cancelled",
  NO_SHOW: "no show",
};

export const VITALSKEY_MAP = {
  weight: "Weight",
  height: "Height",
  bp_systolic: "BP Systolic",
  bp_diastolic: "BP Diastolic",
  temperature: "Temperature",
  spo2: "SPO2",
  random_glucose: "Random Glucose",
  pulse_rate: "Pulse Rate",
  respiration_rate: "Respiration Rate",
  lmp: "LMP",
  weight_unit: "Weight_unit",
  height_unit: "Height_unit",
  random_glucose_unit: "Random Glucose_unit",
  bp_systolic_unit: "BP Systolic_unit",
  bp_diastolic_unit: "BP Diastolic_unit",
  lmp_unit: "lmp_unit",
  respiration_rate_unit: "Respiration Rate_unit",
  spo2_unit: "SPO2_unit",
  pulse_rate_unit: "Pulse Rate_unit",
  temperature_unit: "Temperature_unit",
};

export const VITALS_LIST = [
  {
    id: 1,
    name: "Weight",
    value: "",
    unit: "",
    unitList: ["Kg", "Lbs"],
  },
  {
    id: 2,
    name: "Height",
    value: "",
    unit: "",
    unitList: ["cm", "ft’in”"],
  },
  {
    id: 3,
    name: "BP Systolic",
    value: "",
    unit: "mmHg",
    unitList: ["mmHg"],
  },
  {
    id: 4,
    name: "BP Diastolic",
    value: "",
    unit: "mmHg",
    unitList: ["mmHg"],
  },
  {
    id: 5,
    name: "Temperature",
    value: "",
    unit: "",
    unitList: ["°F", "°C"],
  },
  {
    id: 6,
    name: "SPO2",
    value: "",
    unit: "%",
    unitList: ["%"],
  },
  {
    id: 7,
    name: "Random Glucose",
    value: "",
    unit: "",
    unitList: ["mg/dL", "mmol/L"],
  },
  {
    id: 8,
    name: "Pulse Rate",
    value: "",
    unit: "BPM",
    unitList: ["BPM"],
  },
  {
    id: 9,
    name: "Respiration Rate",
    value: "",
    unit: "BPM",
    unitList: ["BPM"],
  },
  {
    id: 10,
    name: "LMP",
    value: "",
    unit: "Days",
    unitList: ["Days"],
  },
];

export const REASONS_LIST = [
  {
    id: 1,
    label: "Patient asked to Cancel",
    selected: false,
  },
  {
    id: 2,
    label: "Patient requested to Reschedule",
    selected: false,
  },
  {
    id: 3,
    label: "Doctor in OT",
    selected: false,
  },
  {
    id: 4,
    label: "Doctor on Leave",
    selected: false,
  },
  {
    id: 5,
    label: "Doctor Delayed",
    selected: false,
  },
  {
    id: 6,
    label: "Duplicate appointment",
    selected: false,
  },
  {
    id: 7,
    label: "Public Holiday",
    selected: false,
  },
  {
    id: 8,
    label: "Other",
    selected: false,
  },
];
