import React, { useState } from "react";
import "./Document.css";
import PdfViewer from "./PdfViewer";
import Print_icon from "../../../assets/print_icon.png";
import Cancel_black_24dp from "../../../assets/cancel_black_24dp.png";

import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import axios from "axios";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontFamily: `"Rubik", sans-serif`,
    margin: "0px 0px 0px 0px",
  },
}))(Tooltip);

const PdfContainer = (props) => {
  const [fullView, setFullView] = useState(true);

  const viewHandler = () => {
    const { fullViewHandler } = props;
    setFullView(!fullView);
    fullViewHandler && fullViewHandler();
  };

  const downloadURI = async () => {
    const blob = await axios.get(url, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
      responseType: "blob",
    });
    const a = document.createElement("a");
    const href = window.URL.createObjectURL(blob.data);
    a.href = href;
    a.download = fileName + "." + fileType.trim();
    a.click();
  };

  const ImagetoPrint = (source) => {
    return (
      "<html><head><scri" +
      "pt>function step1(){\n" +
      "setTimeout('step2()', 10);}\n" +
      "function step2(){window.print();window.close()}\n" +
      "</scri" +
      "pt></head><body onload='step1()'>\n" +
      "<img src='" +
      source +
      "' /></body></html>"
    );
  };

  const printFile = async () => {
    if (fileType.toLowerCase().includes("pdf")) {
      const blob = await axios.get(url, {
        headers: {
          "Content-Type": "application/octet-stream",
        },
        responseType: "blob",
      });
      const href = window.URL.createObjectURL(blob.data);
      window.open(href, "PRINT", "height=400,width=600");
    }
    if (
      fileType.toLowerCase().includes("jpg") ||
      fileType.toLowerCase().includes("png") ||
      fileType.toLowerCase().includes("jpeg")
    ) {
      var Pagelink = "about:blank";
      var pwa = window.open(Pagelink, "_new");
      pwa.document.open();
      pwa.document.write(ImagetoPrint(url));
      pwa.document.close();
    }
  };

  const { url, fileName, cancelHandler, fileType, width,} = props;

  return (
    <div
      className={`document-container ${fullView && "document-container-full"}`}
      style={{ width: width }}
    >
      <div className="document-header">
        <div className="document-header-left">{fileName || ""}</div>{" "}
        <div className="document-header-right">
          <LightTooltip title="Print">
            <span
              className="px-1 close-icon-notification"
              onClick={() => printFile()}
              style={{ fontSize: "1rem" }}
            >
              <img className="print-icon" src={Print_icon} alt="X" />
            </span>
          </LightTooltip>
          <LightTooltip title="Download">
            <i
              className="pr-1 close-icon-notification"
              aria-hidden="true"
              onClick={() => downloadURI()}
            >
              <GetAppIcon fontSize="small" />
            </i>
          </LightTooltip>
          <LightTooltip title="Close Document">
            <span
              className="pr-1 close-icon-notification"
              onClick={() => cancelHandler()}
              style={{ fontSize: "1rem" }}
            >
              <img className="print-icon" src={Cancel_black_24dp} alt="X" />
            </span>
          </LightTooltip>
        </div>
        <div className="full-view" onClick={viewHandler}>
          {fullView ? "Close " : ""}Full View
        </div>
      </div>
      <div className="document-content mb-5">
        <PdfViewer
          url={url}
          fileType={fileType}
          preview_from={props?.preview_from}
        />
      </div>
    </div>
  );
};

export default PdfContainer;
