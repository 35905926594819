import { PROD } from "../../components/common/config";

export const C2CConsole = {
  log: (...arg) => {
    if (![PROD].includes(process.env.REACT_APP_ENV)) {
      console.log(...arg);
    }
  },
  error: (...arg) => {
    if (![PROD].includes(process.env.REACT_APP_ENV)) {
      console.error(...arg);
    }
  },
  warn: (...arg) => {
    if (![PROD].includes(process.env.REACT_APP_ENV)) {
      console.warn(...arg);
    }
  },
  debug: (...arg) => {
    if (![PROD].includes(process.env.REACT_APP_ENV)) {
      console.debug(...arg);
    }
  },
};
