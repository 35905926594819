import React, { useEffect, useState } from "react";
import Menu from "material-ui-popup-state/HoverMenu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  usePopupState,
  bindHover,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { Link } from "react-router-dom";
import "../styles/MyAccountButton.scss";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";

const MenuPopupState = () => {
  const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });

  const [userType, setUserType] = useState(null);

  useEffect(() => {
    if (!window.localStorage.getItem("user_type")) {
      setUserType(null);
    } else {
      setUserType(window.localStorage.getItem("user_type"));
    }
  }, [window.localStorage]);

  if (userType !== "patient" && userType !== "doctor") {
    return (
      <div>
        <Link className="thecolor" to={`/`}>
          Home Page
        </Link>
      </div>
    );
  }

  const logoutHandler = () => {
    window.localStorage.clear();
  };

  const getUrl = () => {
    let doctorClinicAssociation = localStorage.getItem(
      LOCALSTORAGEKEY.DOCTOR_CLINIC_ASSOCIATION
    );
    switch (userType) {
      case "doctor":
        if (doctorClinicAssociation === "true") {
          return `/clinic/account/dashboard`;
        }
        return `/${userType}/account/dashboard`;
      default:
        return `/${userType}/account/dashboard`;
    }
  };

  return (
    <React.Fragment>
      <div className="menu-button" {...bindHover(popupState)}>
        My Account
      </div>

      <Menu
        {...bindMenu(popupState)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem onClick={popupState.close}>
          <Link className="thecolor" to={getUrl()}>
            {/*  */}
            Dashboard
          </Link>
        </MenuItem>
        {/* <MenuItem onClick={popupState.close}>
          <Link
            className="thecolor"
            onClick={() => localStorage.setItem("nav_item_doctor", 2)}
            to={{
              pathname: `/${userType}/account/appointmenthistory`,
              state: { previousCheckup: 2 },
            }}
          >
            {`Appointment History`}
          </Link>
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            popupState.close();
            logoutHandler();
          }}
        >
          <Link className="thecolor" to={`/${userType}/login`}>
            Logout
          </Link>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default MenuPopupState;
