import React from "react";
import "./errorContainer.scss";

function ErrorContainer(props) {
  const { title, errorMessage } = props;
  return (
    <div
      className={
        "error-container " +
        (errorMessage ? "error-with-message" : "error-without-message")
      }
    >
      {title && <div className="error-heading">{title}</div>}
      {errorMessage && <div className="error-content">{errorMessage}</div>}
    </div>
  );
}

export default ErrorContainer;
