import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { DEV, QA } from "./components/common/config";
import "./App.css";
import "./globalStyles/dashboardStyle.css";
import "./globalStyles/dashboardResponsive.css";
import "./globalStyles/doctorProfileStyle.css";
import "./globalStyles/patientDetails.css";
import "./globalStyles/login.css";
import "./globalStyles/video.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PaymentSuccess from "./components/PaymentSuccess";
import NoAccess from "./components/NoAccess";
// import PrescriptionsDoctor from "./components/PrescriptionsDoctor";
import PrescriptionsPatient from "./components/PrescriptionsPatient";
import PreviousPatients from "./components/PreviousPatients";
import PreviousDoctors from "./components/PreviousDoctors";
import ReactGA from "react-ga";
import SmartBanner from "react-smartbanner";
import "react-smartbanner/dist/main.css";
import "react-widgets/dist/css/react-widgets.css";
import "rsuite/dist/styles/rsuite-default.css";
import ReviewPage from "./components/ReviewPage";
import Thankyou from "./components/Thankyou";
import Failed from "./components/Failed";
import BookingLogin from "./components/BookingLogin";
import ReceiverVideo from "./components/Receiver/index";
import RequestTimedOut from "./components/RequestTimedOut";
import connect2clinic from "./components/connect2clinic";
import HelpAndFaq from "./components/Faq/HelpAndFaq";
import PrescriptionSMS from "./components/HomepageNew/PrescriptionSMS";
import Finance from "./components/Finance/Finance";
//import AdminSection from "./LazyLoading/AdminSection";
import PatientDetails, {
  OnlineLoginPage,
} from "./components/OnlinePatientSide/PatientDetails";
import PatientDetailsUser from "./components/PatientDetailsUser";
import Payment, { LoginPage } from "./components/Payment/Payment";
import AppointmentsSection from "./LazyLoading/AppointmentsSection";
import DoctorSection from "./LazyLoading/DoctorSection";
import DoctorAdminSection from "./LazyLoading/DoctorAdminSection";
import DSMSection from "./LazyLoading/DSMSection";
// import PrescriptionsDoctor from "./components/PrescriptionsDoctor";
import PatientSection from "./LazyLoading/PatientSection";
import Investors from "./components/Investors/index";
import { LastLocationProvider } from "react-router-last-location";
import Logout from "./components/common/Logout";
import PrescriptionsDoctor from "./components/PrescriptionsDoctor";
import { newAdminPortalURL, newDsmPortalURL } from "./Util/API Calls/APIList";
import PatientFeedback from "./components/PatientFeedback/PatientFeedback";
import AbhaViewContainer from "./components/AbhaViewContainer";
import HomePageViewContainer from "./components/HomePageViewContainer";
import AbhaProfile from "./components/PatientComponents/AbhaProfiles";
import { cookiesLogicForAuth } from "./Util/API Calls/cookieHelper";
import ERXPdfViewConatiner from "./components/ERXViewContainer/ERXPdfViewContainer";
import PharmacySection from "./LazyLoading/PharmacySection";

const reload = () => window.location.reload();

// triggering pipeline for faq issue
function App() {
  const [cookies] = useCookies(["cookie-name"]);
  const urlParams = new URLSearchParams(window.location.search);
  const fromApp = urlParams.get("fromApp");
  cookiesLogicForAuth(cookies);
  if (["prod"].includes(process.env.REACT_APP_ENV)) {
    // for google analytics - for production
    ReactGA.initialize("UA-173460690-1");
  } else {
    //for google analytics - development
    ReactGA.initialize("UA-173460690-2");
  }
  ReactGA.pageview(window.location.href);
  return (
    <>
      {fromApp == "true" ? null : (
        <SmartBanner
          title={"Connect2Clinic"}
          daysHidden={0}
          daysReminder={1}
          position={"Top"}
          price={{
            ios: "FREE",
            android: "FREE",
          }}
          appMeta={{
            ios: "apple-itunes-app",
            android: "google-play-app",
          }}
          button={"View"}
          ignoreIosVersion={false}
          style={{ minHeight: "10vh", backgroundColor: "blue" }}
        />
      )}
      <Router>
        <LastLocationProvider>
          <Switch>
            <Route path="/" exact component={HomePageViewContainer} />
            <Route
              path="/appointments/:appointment_id/patient_feedback"
              exact
              component={PatientFeedback}
            />
            <Route path="/sms" exact component={PrescriptionSMS} />
            {(process.env.REACT_APP_ENV === DEV ||
              process.env.REACT_APP_ENV === QA ||
              window.location.hostname === "localhost") && (
              <Route path="/abha" exact component={AbhaViewContainer} />
            )}
            <Route path="/erx-pdf" exact component={ERXPdfViewConatiner} />
            <Route path="/connect" exact component={connect2clinic} />
            <Route path={"/appointment"} component={AppointmentsSection} />
            <Route path={"/appointments"} component={AppointmentsSection} />
            <Route path="/doctor" component={DoctorSection} />
            <Route path="/clinic" component={DoctorAdminSection} />
            <Route path="/investors" component={Investors} />
            <Route path="/patient" component={PatientSection} />
            <Route path="/pharmacist" component={PharmacySection} />
            <Route path="/checkout" exact component={PaymentSuccess} />
            <Route path="/noaccess" exact component={NoAccess} />
            <Route
              path="/prescription/:id"
              exact
              component={PrescriptionsDoctor}
            />
            <Route
              path="/previouspatients"
              exact
              component={PreviousPatients}
            />
            <Route path="/myprofile" exact component={AbhaProfile} />
            <Route path="/previousdoctors" exact component={PreviousDoctors} />
            <Route path="/finance" exact component={Finance} />

            <Route
              path="/prescriptionforpatients/:id"
              exact
              component={PrescriptionsPatient}
            />
            <Route path="/logintobook" exact component={BookingLogin} />
            <Route
              path="/patientdetailsforuser"
              exact
              component={PatientDetailsUser}
            />
            <Route
              path="/online-payment/:slug/login"
              component={OnlineLoginPage}
            />
            <Route
              path="/online-payment/:slug"
              exact
              component={PatientDetails}
            />
            <Route path="/privacy" exact component={PatientDetailsUser} />
            <Route path="/thankyou" exact component={Thankyou} />
            <Route path="/reviewpage" exact component={ReviewPage} />
            <Route path="/thankyou" exact component={Thankyou} />
            <Route path="/failed" exact component={Failed} />

            <Route path="/help" component={HelpAndFaq} />
            <Route
              path="/in-clinic-payment/:slug/login"
              component={LoginPage}
            />
            <Route path="/in-clinic-payment/:slug" exact component={Payment} />

            {/*! DO NOT CHANGE ADMIN ROUTES.. ALL ROUTES OF ADMINS ARE MANAGED BY BELOW COMPONENT!*/}

            {/* <Route path="/admin/" component={AdminSection} /> */}
            <Route
              path="/admin/"
              component={() => {
                window.location.href = newAdminPortalURL;
                return <div></div>;
              }}
            />
            {/* <Route path="/dsm/" component={DSMSection} /> */}
            {/* this is previous code for dsm Route */}
            <Route
              path="/dsm/"
              component={() => {
                window.location.href = newDsmPortalURL;
                return <div></div>;
              }}
            />

            <Route path="/requesttimedout" component={RequestTimedOut} />
            <Route
              path="/.well-known/apple-app-site-association"
              onEnter={reload}
            />
            <Route path="/apple-app-site-association" onEnter={reload} />
            <Route path="/receiver/video" component={ReceiverVideo} />
            <Route path="/logout" component={Logout} />
          </Switch>
        </LastLocationProvider>
      </Router>
    </>
  );
}
export default App;
