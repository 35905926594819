import beforeLoginAxios from "../../../Util/API Calls/beforeLogin";

const performApiRequest = async (api, payload) => {
  const res = await beforeLoginAxios({
    method: "post",
    url: api,
    data: payload,
  });

  if (res) {
    return [res, false];
  }
  return [null, true]; // [response, error]
};

export const enrollAadhar = async (api, payload) => {
  return performApiRequest(api, payload);
};

export const enrollVerifyOtp = async (api, payload) => {
  return performApiRequest(api, payload);
};

export const enrollResendOtp = async (api, payload) => {
  return performApiRequest(api, payload); // [response, error]
};

export const linkMobileNumber = async (api, payload) => {
  return performApiRequest(api, payload); // [response, error]
};

export const sendOtpToLinkMobileNumber = async (api, payload) => {
  return performApiRequest(api, payload); // [response, error]
};

export const resendOtpToLinkMobileNumber = async (api, payload) => {
  return performApiRequest(api, payload); // [response, error]
};

export const getAbhaAddressSuggestion = async (api, payload) => {
  return performApiRequest(api, payload); // [response, error]
};

export const addAbhaAddress = async (api, payload) => {
  return performApiRequest(api, payload); // [response, error]
};

export const downloadAbhaCard = async (api, payload) => {
  return performApiRequest(api, payload); // [response, error]
};

export const getAbhaProfile = async (api, payload) => {
  return performApiRequest(api, payload); // [response, error]
};
