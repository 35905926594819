import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import FilledInput from "@material-ui/core/FilledInput";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { useHistory, Redirect } from "react-router-dom";
import { useTimer } from "../../Hooks/useTimer";
import swal from "sweetalert";
import beforeLoginAxios from "../../Util/API Calls/beforeLogin";
import ErrorIcon from "@material-ui/icons/Error";

function SignUpUnregistered(props) {
  let { phoneNumber = "" } = props;

  let history = useHistory();

  const [showPassword, setShowPassword] = useState(false);
  const [otpInput, setOtpInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [resendAllowed, setResendAllowed] = useState(false);

  useEffect(() => {
    swal({
      title: "An OTP has been sent to the Phone Number you entered ",
      type: "warning",
    });
  }, []);

  const { time, start, pause, reset, status } = useTimer({
    initialTime: 60,
    endTime: 0,
    timerType: "DECREMENTAL",
    autostart: true,
    onTimeOver: () => {
      setResendAllowed(true);
    },
  });

  if (phoneNumber == "") {
    return <Redirect to="/doctor/login/sign-up" />;
  }

  function handleForgotPassword() {
    if (!resendAllowed) return;
    reset();
    start();
    setResendAllowed(false);
    beforeLoginAxios
      .post("public/send-onboard-doctor-OTP", {
        channel_id: "doctor-login",
        login_type: "password",
        phone_no: `+91${phoneNumber}`,
      })
      .then((response) => {
        if (response) {
          if (response.data && response.data.statusCode !== 200) {
            swal({
              title: "Something Went Wrong",
              type: "warning",
            });
          }
        }
      });
  }

  const handleContinueClick = (e) => {
    //validations
    // history.replace("/doctor/login/enter-password");
    //api call
    e.preventDefault();
    setPasswordError(false);
    setOtpError(false);

    if (otpInput.length !== 6) {
      setOtpError("OTP must be exactly 6 characters");
      return;
    }
    if (passwordInput.length <= 5) {
      setPasswordError("Password must be at least 6 characters");
      return;
    }

    beforeLoginAxios
      .post("doctor/webinar-login", {
        phone_no: `+91${phoneNumber}`,
        user_otp: otpInput,
      })
      .then((response) => {
        if (response) {
          if (response.data && response.data.statusCode === 200) {
            localStorage.setItem("doctorPhone", `+91${phoneNumber}`);
            localStorage.setItem(
              "specialization",
              response?.data?.user?.specialization_id
            );
            history.replace("/doctor/login/sign-up/enter-details", {
              otpInput,
              phoneNumber,
              passwordInput,
            });
          } else if (response.data && response.data.statusCode === 510) {
            //alert()
            setOtpError("OTP you entered was Incorrect");
          }
        }
      });
  };

  return (
    <div
      className="container h-100  d-flex align-items-center justify-content-center flex-column"
      style={{ position: "relative" }}
    >
      <div>
        <ArrowBackIcon
          onClick={() => {
            history.replace("/doctor/login/sign-up");
          }}
          style={{ position: "absolute", left: "0%", top: "2%" }}
          className="color-blue cursor-pointer"
        />
        <h3 className="text-center align-top">Verify Number</h3>
      </div>
      <h6 className="text-center">Please Enter the OTP</h6>
      <div className="w-100 d-flex flex-row justify-content-between mt-3">
        <div className="d-inline-block text-muted">+91-{phoneNumber}</div>
        <Edit className="color-blue cursor-pointer" />
      </div>
      <div className="w-100 mt-2">
        <form
          className="d-flex flex-column align-items-center justify-content-center"
          onSubmit={(e) => {
            handleContinueClick(e);
          }}
        >
          <FormControl
            hiddenLabel={true}
            size="small"
            margin="dense"
            fullWidth={true}
          >
            <FilledInput
              id="OTP-input"
              aria-describedby="my-helper-text"
              placeholder="Enter OTP"
              aria-label="otp-input"
              autoFocus={true}
              disableUnderline={true}
              value={otpInput}
              inputProps={{
                autoComplete: "off",
                inputMode: "numeric",
              }}
              endAdornment={
                otpError ? <ErrorIcon style={{ color: "#e84444" }} /> : null
              }
              onChange={(e) => {
                setOtpInput(e.target.value);
              }}
            />
          </FormControl>
          <div className="row justify-content-between w-100">
            <div className="col-7 p-0">
              {resendAllowed ? (
                <div className="align-self-start text-muted">OTP expired</div>
              ) : (
                <div className="align-self-start text-muted">
                  {time} seconds left to expire
                </div>
              )}
            </div>
            <div className="col-5 p-0 d-flex justify-content-end">
              <div
                className={`align-self-start cursor-pointer ${
                  !resendAllowed ? "text-muted pointer-disabled" : "textLink"
                }`}
                style={{ textAlign: "right" }}
                onClick={(e) => {
                  e.preventDefault();
                  handleForgotPassword();
                }}
              >
                Resend OTP
              </div>
            </div>
          </div>
          <div className="input-error text-center mt-1">
            {otpError ? otpError : ""}
          </div>
          <div className="mt-3 text-center">
            Set password for easier access (minimum 6 characters required)
          </div>
          <FormControl
            hiddenLabel="true"
            size="small"
            margin="dense"
            fullWidth="true"
          >
            <FilledInput
              id="password-input"
              aria-describedby="my-helper-text"
              aria-label="Set-password"
              placeholder="Enter Password"
              disableUnderline="true"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <ShowPassword
                  setShowPassword={setShowPassword}
                  showPassword={showPassword}
                />
              }
              value={passwordInput}
              onChange={(e) => {
                setPasswordInput(e.target.value);
              }}
            />
          </FormControl>
          <div className="input-error text-center mt-1">
            {passwordError ? passwordError : ""}
          </div>

          <button
            className="btn primary-cta-button rounded-pill mt-2 align-self-center py-2 px-4"
            disabled={resendAllowed}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default SignUpUnregistered;

const Edit = () => {
  let history = useHistory();
  return (
    <span
      className="color-blue cursor-pointer"
      onClick={() => {
        history.replace("/doctor/login/sign-up");
      }}
    >
      Edit
      <EditIcon className="color-blue cursor-pointer" fontSize="inherit" />
    </span>
  );
};

function ShowPassword(props) {
  const { setShowPassword, showPassword = false } = props;

  if (showPassword === false) {
    return (
      <span
        className="color-blue cursor-pointer"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        <VisibilityIcon />
      </span>
    );
  } else {
    return (
      <span
        className="color-blue cursor-pointer"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        <VisibilityOffIcon />
      </span>
    );
  }
}
