import React, { useRef, useLayoutEffect, useEffect } from "react";

const SingleOTPInput = (props) => {
  const { focus, autoFocus, ...rest } = props;
  const inputRef = useRef();
  const prevFocus = usePrevious(!!focus);

  function usePrevious(value) {
    const ref = useRef();

    // Store current value in ref
    useEffect(() => {
      ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
  }

  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);

  return <input ref={inputRef} {...rest} />;
};

export default SingleOTPInput;
