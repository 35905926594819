import React, { PureComponent } from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import afterLoginApiAxios from "../../../Util/API Calls/afterLogin";

import "./Document.css";

class PdfViewer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      url: props.url || "",
      fileType: props.fileType,
      document: "",
    };
  }

  componentDidUpdate() {}
  componentDidMount() {
    if (this.props?.preview_from === "prescription") {
      this.fetchDoc();
    } else {
      this.setState({ document: this.props.url });
    }
  }

  fetchDoc = () => {
    afterLoginApiAxios({
      method: "get",
      url: this.state.url,
      responseType: "blob",
    }).then((response) => {
      var reader = new FileReader();
      reader.readAsDataURL(response.data);
      reader.onloadend = () => {
        var base64data = reader.result;

        this.setState({ document: base64data, url: response.config.url });
      };
    });
  };

  render() {
    const docs = [
      {
        uri: `${this.state.document}`,
      },
    ];

    return (
      this.state.document && (
        //  <FileViewer
        //   fileType={this.state.fileType}
        //   filePath={this.state.document}
        // />

        <DocViewer
          documents={docs}
          pluginRenderers={DocViewerRenderers}
          style={{
            width: "100%",
            //  height: 800,
          }}
          config={{
            header: {
              disableHeader: false,
              disableFileName: false,
              retainURLParams: false,
            },
          }}
        />
      )
    );
  }
}

export default PdfViewer;
