import afterLoginApiAxios from "../../Util/API Calls/afterLogin";

export const getAppointmentsQuestions = async (
  id = "25c0119d-e2aa-44c5-abcc-289476a02a98"
) => {
  return afterLoginApiAxios.get(
    `api/v1/public/appointments/${id}/survey_questions?category=0`
  );
};

//api/v1/public/appointments/:id/reviews

export const isAppointmentsReview = async (
  id = "9c347133-159d-48ed-bce3-ce0f9c1e114a"
) => {
  return afterLoginApiAxios.get(
    `api/v1/public/appointments/${id}/reviews`
  );
};

export const postReviewApi = async (id, data) => {
  return afterLoginApiAxios.post(
    `api/v1/public/appointments/${id}/reviews`,
    {
      data,
    }
  );
};
