/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import history from "./Util/history";
import produce from "immer";

// import globalReducer from "containers/App/reducer";
// import languageProviderReducer from "containers/LanguageProvider/reducer";

export const initialState = {
  loading: false,
  error: false,
  currentUser: false,
  mobileNumber: localStorage.getItem("mobileNumber") || "",
  emailId: localStorage.getItem("emailId") || "",
};
/* eslint-disable default-case, no-param-reassign */
const globalReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case "LOGIN":
        draft.currentUser = true;
        draft.error = false;
        break;
      case "UPDATE_MOBILE_NUMBER":
        draft.mobileNumber = action.payload.mobileNumber;
        draft.emailId = action.payload.emailId;
        break;
    }
  });

// export default appReducer;

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    global: globalReducer,
    // language: languageProviderReducer, In future, we may need multilingual spport
    router: connectRouter(history),
    ...injectedReducers,
  });

  return rootReducer;
}
