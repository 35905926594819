// dependencies imports ⇒
import React, { useEffect, useState } from "react";
// assets and scss imports ⇒
import "./homePageTestimonialContainer.scss";
import LeftArrow from "../../../assets/home_page_arrow_left.svg";
import RightArrow from "../../../assets/home_page_arrow_right.svg";

// project file imports(UI components) ⇒

// const file imports(helper methods, etc) ⇒
import { TESTIMONIAL } from "../constant";

const HomePageTestimonialContainer = () => {
  // const declarations ⇒
  const [testimonialList, setTestimonialList] = useState([]);
  const [temptestimonialList, setTempTestimonialList] = useState([]);
  const [testimonialId, setTestimonialId] = useState(1);

  // let or var declarations ⇒

  // life cycle methods ⇒

  useEffect(() => {
    let listArr = [];
    listArr.push(TESTIMONIAL[TESTIMONIAL?.length - 1], TESTIMONIAL[0], TESTIMONIAL[1]);
    setTempTestimonialList(TESTIMONIAL);
    setTestimonialList(listArr);
  }, [TESTIMONIAL]);

  // API Calls ⇒

  // Helper methods ⇒

  // Actions ⇒

  const handleRight = () => {
    let list = arrayRotate([...temptestimonialList]);
    setTempTestimonialList(list);
    setTestimonialId(list[0].id);
    let listArr = [];
    listArr.push(list[list?.length - 1], list[0], list[1]);
    setTestimonialList([...listArr]);
  };

  const handleLeft = () => {
    let list = arrayRotate([...temptestimonialList], true);
    setTempTestimonialList(list);
    setTestimonialId(list[0].id);
    let listArr = [];
    listArr.push(list[list?.length - 1], list[0], list[1]);
    setTestimonialList([...listArr]);
  };

  const arrayRotate = (arr, reverse) => {
    if (reverse) arr.unshift(arr.pop());
    else arr.push(arr.shift());
    return arr;
  }

  // create UI ⇒
  const homePageTestimonialContainerUI = () => {
    return (
      <div className="home-page-testimonial-container">
        <div className="home-page-testimonial-container-content">
          <div className="home-page-testimonial-title">
            Here’s what our Doctors think
          </div>
          {window.innerWidth > 819 ? (
            <div className="home-page-testimonial-list-container">
              <div>
                <img
                  src={LeftArrow}
                  alt=""
                  className="home-page-testimonial-left-right-arrow"
                  onClick={handleLeft}
                />
              </div>
              <div className="home-page-testimonial-list">
                {testimonialList?.map((data, index) => (
                  <div
                    className={
                      "home-page-testimonial-card " +
                      (testimonialId === data?.id
                        ? "home-page-testimonial-card-active"
                        : "home-page-testimonial-card-opacity")
                    }
                    key={index}
                  >
                    <div className="home-page-testimonial-profile">
                      <img className="home-page-testimonial-profile-image" src={data?.profile_img} alt="" />
                    </div>
                    <div className="home-page-testimonial-doctor-name">
                      {data?.name}
                    </div>
                    <div className="home-page-testimonial-specialization">
                      {data?.specialization}
                    </div>
                    <div className="home-page-testimonial-details">
                      {data?.testimonial_content}
                    </div>
                  </div>
                ))}
              </div>
              <div>
                <img
                  src={RightArrow}
                  alt=""
                  className="home-page-testimonial-left-right-arrow"
                  onClick={handleRight}
                />
              </div>
            </div>
          ) : (
            <div className="mobile-view-testimonial-view-container">
              <div className="testimonial-side-card-1"></div>
              <div className="home-page-testimonial-list-mobile-view">
                <div className="home-page-testimonial-list">
                  <div
                    className={
                      "home-page-testimonial-card " +
                      (testimonialId === testimonialList[1]?.id
                        ? "home-page-testimonial-card-active"
                        : "home-page-testimonial-card-opacity")
                    }
                  >
                    <div className="home-page-testimonial-profile">
                      <img className="home-page-testimonial-profile-image" src={testimonialList[1]?.profile_img} alt="" />
                    </div>
                    <div className="home-page-testimonial-doctor-name">
                      {testimonialList[1]?.name}
                    </div>
                    <div className="home-page-testimonial-specialization">
                      {testimonialList[1]?.specialization}
                    </div>
                    <div className="home-page-testimonial-details">
                      {testimonialList[1]?.testimonial_content}
                    </div>
                  </div>
                </div>
                <div className="home-page-testimonial-left-right-arrow-container">
                  <div>
                    <img
                      src={LeftArrow}
                      alt=""
                      className="home-page-testimonial-left-right-arrow"
                      onClick={handleLeft}
                    />
                  </div>
                  <div>
                    <img
                      src={RightArrow}
                      alt=""
                      className="home-page-testimonial-left-right-arrow"
                      onClick={handleRight}
                    />
                  </div>
                </div>
              </div>
              <div className="testimonial-side-card-2"></div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return <>{homePageTestimonialContainerUI()}</>;
};

//export
export default HomePageTestimonialContainer;
