import axios from "axios";
import swal from "sweetalert";
import { LOCALENV, PROD } from "../../components/common/config/index";
import { USER_TYPE } from "../constants";
import { deleteDB } from "../../components/common/dexieDB";
import { LOCALSTORAGEKEY } from "../Helper/localStorageUtils";

let apiMapUrl = {
  dev: "https://api-dev.c2cassets.com/",
  dev1: "https://api-dev1.c2cassets.com/",
  dev2: "https://api-dev2.c2cassets.com/",
  qa: "https://api-qa.c2cassets.com/",
  uat: "https://api-uat.c2cassets.com/",
  prod: "https://api.connect2clinic.com/",
  maint: "https://api-maint.c2cassets.com/",
  stage: "https://api-stage.c2cassets.com/",
};

const api =
  window.location.hostname === "localhost"
    ? LOCALENV
    : process.env.REACT_APP_ENV;
const afterLoginApiAxios = axios.create({
  baseURL: apiMapUrl[api],
  headers: {
    "X-Application-Source": "web",
  },
});

afterLoginApiAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error?.response?.status === 403) {
      swal({
        icon: "error",
        type: "error",
        title: "Your session got expired please login again",
        timer: 10000,
      })
        .then(function () {
          deleteDB();
          const userType = localStorage.getItem(LOCALSTORAGEKEY.USER_TYPE);
          window.localStorage.clear();
          document.cookie =
            "id_token=; expires=Thu, 01 Jan 2023 00:00:00 UTC; path=/;";
          if (process.env.REACT_APP_ENV === PROD) {
            document.cookie =
              "id_token=; expires=Thu, 01 Jan 2023 00:00:00 UTC; path=/; domain=connect2clinic.com";
          } else {
            document.cookie =
              "id_token=; expires=Thu, 01 Jan 2023 00:00:00 UTC; path=/; domain=.c2cassets.com";
          }

          switch (userType) {
            case USER_TYPE.PATIENT:
              window.location.replace(`/${USER_TYPE.PATIENT}/login`);
              break;
            case USER_TYPE.DOCTOR:
              window.location.replace(`/${USER_TYPE.DOCTOR}/login`);
              break;
            default:
              window.location.replace(`/${USER_TYPE.DOCTOR}/login`);
              break;
          }
        })
        .catch(function () {
          deleteDB();
          window.localStorage.clear();
          window.location.replace(`/`);
          document.cookie =
            "id_token=; expires=Thu, 01 Jan 2023 00:00:00 UTC; path=/;";
        });
    }
  }
);

export default afterLoginApiAxios;
