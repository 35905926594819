import React, { useState } from "react";
import "../styles/MostAskedQuestion.scss";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import subicon from "../../assets/sub-icon.svg";
import addicon from "../../assets/add_icon.svg";

function QuestionList(props) {
  const [showAnswer, setShowAnswer] = useState(false);

  return (
    <Row
      className="questions-list-row"
      onClick={() => {
        setShowAnswer(!showAnswer);
      }}
      style={{ marginLeft: "1%" }}
    >
      <Col
        xs={10}
        className="questions-list-question"
        style={{ padding: "0px" }}
      >
        <div className="maq-question-style">{props.queryquestion}</div>
      </Col>
      <Col xs={2} className="questions-list-question-adornment">
        <img src={showAnswer ? subicon : addicon} alt="#" />
      </Col>
      <Row
        className="questions-list-answer px-3"
        style={{
          visibility: showAnswer ? "visible" : "hidden",
          opacity: showAnswer ? "0.82" : "0",
          height: showAnswer ? "auto" : "0",
          padding: "0px",
        }}
      >
        <Col style={{ padding: "0px" }}>{props.queryanswer} </Col>
      </Row>
      <hr className="questions-list-hr" />
    </Row>
  );
}

export default QuestionList;
