import React, { Component } from "react";
import "./Document.css";
import DocumentViewer from "./DocumentViewer";
import Print_icon from "../../../assets/print_icon.png";
import Cancel_black_24dp from "../../../assets/cancel_black_24dp.png";

import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import axios from "axios";
//TODO: Return Image as props after clicking on done

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontFamily: `"Rubik", sans-serif`,
    margin: "0px 0px 0px 0px",
  },
}))(Tooltip);

class DocumentContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullView: true,
    };
  }

  componentDidMount() {}

  viewHandler = () => {
    const { fullViewHandler } = this.props;
    this.setState({ fullView: !this.state.fullView });
    {
      fullViewHandler && fullViewHandler();
    }
  };

  async downloadURI(url, name, fileType) {
    const blob = await axios.get(url, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
      responseType: "blob",
    });
    const a = document.createElement("a");
    const href = window.URL.createObjectURL(blob.data);
    a.href = href;
    a.download = name + "." + fileType.trim();

    a.click();
  }

  ImagetoPrint = (source) => {
    return (
      "<html><head><scri" +
      "pt>function step1(){\n" +
      "setTimeout('step2()', 10);}\n" +
      "function step2(){window.print();window.close()}\n" +
      "</scri" +
      "pt></head><body onload='step1()'>\n" +
      "<img style='display: block; height: 100%; width: 100%; height: 297mm; width: 210mm; object-fit: contain; background-size:   cover; ' src='" +
      source +
      "' /></body></html>"
    );
    // style added i.e, width:210mm and height:297mm for image to print
  };

  printFile = (url, fileType) => {
    if (fileType.toLowerCase().includes("pdf")) {
      window.open(url, "PRINT", "height=400,width=600");
    }
    if (
      fileType.toLowerCase().includes("jpg") ||
      fileType.toLowerCase().includes("png") ||
      fileType.toLowerCase().includes("jpeg")
    ) {
      var Pagelink = "about:blank";
      var pwa = window.open(Pagelink, "_new");
      pwa.document.open();
      pwa.document.write(this.ImagetoPrint(url));
      pwa.document.close();
    }
  };

  render() {
    const { url, fileName, cancelHandler, fileType, width } = this.props;
    return (
      <div
        className={`document-container ${
          this.state.fullView && "document-container-full"
        }`}
        style={{ width: width }}
      >
        <div className="document-header">
          <div className="document-header-left">{fileName || ""}</div>{" "}
          <div className="document-header-right">
            <LightTooltip title="Print">
              <span
                className="px-1 close-icon-notification" //pr-3
                onClick={() => this.printFile(url, fileType)}
                style={{ fontSize: "1rem" }}
              >
                <img className="print-icon" src={Print_icon} alt="X" />
              </span>
            </LightTooltip>
            <LightTooltip title="Download">
              <i
                className="pr-1 close-icon-notification" //pr-3
                aria-hidden="true"
                onClick={() => this.downloadURI(url, fileName, fileType)}
              >
                <GetAppIcon fontSize="small" />
              </i>
            </LightTooltip>
            <LightTooltip title="Close Document">
              <span
                className="pr-1 close-icon-notification" //pr-3
                onClick={() => cancelHandler()}
                style={{ fontSize: "1rem" }}
              >
                <img className="print-icon" src={Cancel_black_24dp} alt="X" />
              </span>
            </LightTooltip>
          </div>
          <div className="full-view" onClick={() => this.viewHandler()}>
            {this.state.fullView ? "Close " : ""}Full View
          </div>
        </div>
        <div className="document-content">
          <DocumentViewer url={url} fileType={fileType} />
        </div>
      </div>
    );
  }
}

export default DocumentContainer;
