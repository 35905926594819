// Dependies import
import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";

//assest and scss import
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./doctorCreateAccountContainer.scss";
//project files import
import PhoneNumberInputWithAction from "../../common/PhoneNumberInputWithAction";
import ActionButton from "../../common/Buttons/ActionButton";
import PasswordInputWithHeader from "../../common/PasswordInputWithHeader";
import OTPInput from "../../common/OTPInput";
import SignInFormLayout from "../../common/SignInFormLayout";

//const import
import { OTP_ERROR_MESSAGE } from "../constant";
import swal from "sweetalert";
import { SEND_OTP } from "../DoctorSignInAPIHandler/apiConstant";
import beforeLoginAxios from "../../../Util/API Calls/beforeLogin";
import { C2CConsole } from "../../../Util/Helper/C2CConsole";
import { c2cBeforeLoginPostApiHandler } from "../../../Util/c2cBeforeLoginApiHandler";

function DoctorCreateAccountContainer(props) {
  const { phoneNumber, setPhoneNumber } = props;
  // const declarations ⇒
  const [disableContinueButton, setDisableContinueButton] = useState(true);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [otpInput, setOtpInput] = useState("");
  const [password, setPassword] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(false);
  const [showRegisterButtonLoader, setShowRegisterButtonLoader] =
    useState(false);

  const history = useHistory();
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const inputRef = useRef();
  //methods ==>

  useEffect(() => {
    if (mobileNumber?.length === 10) {
      handleInputAction();
    }
  }, [mobileNumber]);

  useEffect(() => {
    if (
      mobileNumber?.length === 10 &&
      otpInput.length === 6 &&
      inputRef?.current
    ) {
      inputRef.current.focus();
    }
  }, [otpInput]);

  useEffect(() => {
    if (phoneNumber) {
      setMobileNumber(phoneNumber);
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (mobileNumber?.length < 10 || otpInput?.length < 6) {
      setDisableContinueButton(true);
    } else {
      setDisableContinueButton(false);
    }
  }, [mobileNumber, otpInput]);

  const handleSubmitAPICall = () => {
    let payload = {
      channel_id: "doctor-login",
      login_type: "password",
      phone_no: "+91" + mobileNumber,
      user_type: "doctor",
    };
    setShowRegisterButtonLoader(true);
    const sendOTPResponse = c2cBeforeLoginPostApiHandler(SEND_OTP, payload);
    sendOTPResponse.then(([response, error]) => {
      if (!error) {
        if (response.data && response.data.statusCode === 200) {
          if (response.data.password_set === 1) {
            history.replace("/doctor/login");
          } else {
            setShowRegisterButtonLoader(false);
          }
        } else if (response.data && response.data.statusCode === 500) {
          partialDoctorLogin();
        } else if (response.data && response.data.statusCode === 501) {
          setShowRegisterButtonLoader(false);
          swal({
            title: "Invalid Phone Number Entered",
            type: "info",
          });
        } else {
          setShowRegisterButtonLoader(false);
          swal({
            title: "Something Went Wrong!",
            type: "info",
          });
        }
      } else {
        setShowRegisterButtonLoader(false);
      }
    });
  };

  const partialDoctorLogin = () => {
    localStorage.setItem("is_partial_doctor", "True");
    beforeLoginAxios
      .post("public/send-onboard-doctor-OTP", {
        phone_no: `+91${phoneNumber}`,
        channel_id: "doctor-login",
        create_account: true,
        login_type: "password",
      })
      .then((response) => {
        if (response) {
          if (response.data && response.data.statusCode === 200) {
            if (response.data.onboard_application_id === "NULL") {
              //swal doctor ot found
              setShowOtpInput(true);
              setShowRegisterButtonLoader(false);
            } else if (response.data.password_set === 1) {
              history.replace("/doctor/login");
            } else {
              setShowRegisterButtonLoader(false);
            }
          } else {
            setShowRegisterButtonLoader(false);
          }
        } else {
          setShowRegisterButtonLoader(false);
        }
      })
      .catch(function (error) {
        C2CConsole.log(error);
      });
  };

  const handleCreateDoctorAPICall = () => {
    setPasswordError(false);
    setOtpError(false);

    if (otpInput.length !== 6) {
      setOtpError("OTP must be exactly 6 characters");
      return;
    }
    if (password.length <= 5) {
      setPasswordErrorMessage("Password must be at least 6 characters");
      setPasswordError(true);
      return;
    }

    setShowButtonLoader(true);
    beforeLoginAxios
      .post("doctor/webinar-login", {
        phone_no: `+91${mobileNumber}`,
        user_otp: otpInput,
      })
      .then((response) => {
        if (response) {
          if (response.data && response.data.statusCode === 200) {
            setOtpError(false);
            localStorage.setItem("doctorPhone", `+91${mobileNumber}`);
            localStorage.setItem(
              "specialization",
              response?.data?.user?.specialization_id
            );
            history.replace("/doctor/login/sign-up/enter-details", {
              otpInput,
              phoneNumber: mobileNumber,
              passwordInput: password,
            });
          } else if (response.data && response.data.statusCode === 510) {
            //alert()
            setOtpError(true);
            setShowButtonLoader(false);
          } else {
            setShowButtonLoader(false);
          }
        } else {
          setShowButtonLoader(false);
        }
      });
  };

  const handleMobileInputChange = (value) => {
    setMobileNumber(value);
    setPhoneNumber(value);
  };

  const handleInputAction = () => {
    handleSubmitAPICall();
  };

  const handleResendOTP = () => {
    partialDoctorLogin();
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
  };

  const handleContinue = () => {
    handleCreateDoctorAPICall();
  };
  const handleKeyDown = (e) => {
    if (e.key !== "Backspace" && e.key !== "Delete") {
      if (e.key === "Enter" && mobileNumber?.length === 10) {
        handleInputAction();
      }
    }
  };
  const onKeyDownPassword = (e) => {
    if (e.key !== "Backspace" && e.key !== "Delete") {
      if (
        e.key === "Enter" &&
        mobileNumber?.length === 10 &&
        otpInput?.length === 6 &&
        password
      ) {
        handleContinue();
      }
    }
  };

  //<==

  //create UI ==>

  function doctorLoginAccountCard() {
    return (
      <div className="login-account-card">
        <div className="create-account-input-terms-wrapper">
          <div className="create-account-input-wrapper">
            <PhoneNumberInputWithAction
              placeholder={"Enter Phone Number"}
              inputValue={mobileNumber}
              disabled={false}
              showRegisterButtonLoader={showRegisterButtonLoader}
              handleInputChange={handleMobileInputChange}
              handleInputAction={handleInputAction}
              handleKeyDown={handleKeyDown}
              autoFocus={true}
            />
          </div>
          {showOtpInput && (
            <div className="enter-otp-input-feild">
              <OTPInput
                autoFocus
                hasError={otpError}
                errorMessage={OTP_ERROR_MESSAGE}
                onChangeOTP={(otp) => setOtpInput(otp)}
                handleResendOTP={handleResendOTP}
              />
            </div>
          )}

          {showOtpInput && (
            <div className="create-account-password-input-header">
              <PasswordInputWithHeader
                title="Set password for easier access"
                isMandatory={true}
                passwordInput={password}
                hasError={passwordError}
                errorMessage={passwordErrorMessage}
                handlePasswordInputChange={handlePasswordChange}
                onKeyDown={onKeyDownPassword}
                inputRef={inputRef}
              />
            </div>
          )}

          {showOtpInput && (
            <div className="create-account-action-button-wrapper">
              <ActionButton
                title="Continue"
                disabled={disableContinueButton}
                showButtonLoader={showButtonLoader}
                handleClick={handleContinue}
              />
            </div>
          )}

          {!showOtpInput && (
            <div className="terms-and-policy-wrapper">
              <div>By clicking on continue, you agree to our</div>
              <div>
                <a
                  className="privacy-text-btn"
                  href="../../privacy.html"
                  target="_blank"
                >
                  Privacy Policy
                </a>
                &
                <a
                  className="term-text-btn"
                  href="../../terms.html"
                  target="_blank"
                >
                  Terms of Use
                </a>
              </div>
            </div>
          )}
        </div>
        {!showOtpInput && (
          <div className="login-to-vitrual-btn-wrapper">
            <div
              className="login-vitrual-btn"
              onClick={() => {
                history.replace("/doctor/login");
              }}
            >
              Login to Virtual Clinic{" "}
              <ArrowForwardIcon sx={{ color: "#43CEFF" }} />{" "}
            </div>
          </div>
        )}
      </div>
    );
  }

  const createAccount = () => {
    return (
      <div>
        <div>
          <SignInFormLayout
            heading={showOtpInput ? "Verify Number" : "Create An Account"}
            subheading={
              showOtpInput ? "Please Enter the OTP" : "Register Virtual Clinic"
            }
          >
            <div className="create-account-container">
              <div className="doctor-create-account-box">
                {" "}
                {doctorLoginAccountCard()}
              </div>
            </div>
          </SignInFormLayout>
        </div>
      </div>
    );
  };

  return <div style={{ display: "flex" }}>{createAccount()} </div>;
}

export default DoctorCreateAccountContainer;
