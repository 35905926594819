import React, { useState } from "react";
import { Calendar } from "react-calendar";
import { ClickAwayListener } from "@material-ui/core";

import "./vitalDatePickerInput.scss";
import calendarIcon from "../../../assets/bulk_reschedule_calendar.svg";

const VitalDatePickerInput = (props) => {
  const {
    differentInputFormat,
    selected_date,
    placeholder,
    minDate,
    maxDate,
    allowBackspace,
    handleDateChange,
  } = props;
  const [showCalendar, setShowCalendar] = useState(false);

  const handleShowCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const handleKeyDown = (e) => {
    if (allowBackspace && e.key === "Backspace") {
      setShowCalendar(false);
      handleDateChange("");
    } else {
      e.preventDefault();
    }
  };

  const handleSelectedDate = (date) => {
    handleDateChange(date);
    setShowCalendar(false);
  };
  const datePickerInput = () => {
    return (
      <div className="vdpi-container">
        <div className="vdpi-input-container" onClick={handleShowCalendar}>
          <div className="vdpi-input">
            <input
              value={differentInputFormat ?? selected_date}
              placeholder={placeholder}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="vdpi-calendar-icon">
            <img src={calendarIcon} alt="" />
          </div>
        </div>
        {showCalendar && (
          <ClickAwayListener
            onClickAway={() => {
              setShowCalendar(false);
            }}
          >
            <div className="vdpi-calendar">
              <Calendar
                value={selected_date ?? new Date(selected_date)}
                minDate={minDate}
                maxDate={maxDate}
                onChange={handleSelectedDate}
              />
            </div>
          </ClickAwayListener>
        )}
      </div>
    );
  };

  return <div className="vital-date-picker-input">{datePickerInput()}</div>;
};

export default VitalDatePickerInput;
