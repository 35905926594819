import { withStyles } from "@material-ui/styles";
import InputBase from "@material-ui/core/InputBase";

export const StyledInputBase = withStyles((theme) => ({
  root: {
    border: "none",
    width: "100%",
    "&:focus": {
      border: "none",
    },
  },
  input: {
    width: "100%",
    position: "relative",
    padding: "10px 12px",
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      border: "none",
    },
  },
}))(InputBase);
