import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import FilledInput from "@material-ui/core/FilledInput";
import { useHistory, useParams } from "react-router-dom";
import beforeLoginAxios from "../../Util/API Calls/beforeLogin";
import swal from "sweetalert";
import Spinner from "react-bootstrap/Spinner";
import useDisableHelp from "../../Hooks/useDisableHelp";

function SignInEnterPhoneNumber(props) {
  let history = useHistory();
  const { setPhoneNumber, redirectUrl, phoneNumber = "", baseUrl } = props;
  const [loading, setLoading] = useState(false);
  const [continueAllowed, setContinueAllowed] = useState(
    phoneNumber.length == 10
  );

  const { slug } = useParams();
  // useDisableHelp();

  const handleContinueClick = (e) => {
    e.preventDefault();
    setLoading(true);
    //validations

    //api call
    beforeLoginAxios
      .post("public/send-otp", {
        user_type: "patient",
        channel_id: "patient-login",
        phone_no: `+91${phoneNumber}`,
        login_type: "password",
      })
      .then((response) => {
        if (response) {
          setLoading(false);
          if (response.data && response.data.statusCode === 200) {
            if (response.data.password_set === 1) {
              history.replace(
                `${baseUrl.split("/:slug")[0]}/${slug}/login/enter-password`,
                {
                  redirectTo: redirectUrl,
                }
              );
            } else {
              history.replace(
                `${baseUrl.split("/:slug")[0]}/${slug}/login/enter-otp`,
                {
                  setPassword: true,
                  redirectTo: `${baseUrl.split("/:slug")[0]
                    }/${slug}/login/set-password`,
                }
              );
            }
          } else if (response.data && response.data.statusCode === 500) {
          } else if (response.data && response.data.statusCode === 501) {
            swal({
              title: "Invalid Phone Number Entered",
              type: "info",
            });
          } else {
            swal({
              title: "Something Went Wrong!",
              type: "info",
            });
          }
        }
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  function handlePhoneNumber(num) {
    if (num == "") setPhoneNumber(num);
    if (num.length < 10) setContinueAllowed(false);
    if (num.length == 10) setContinueAllowed(true);
    if (num.length > 10) return;
    const onlyDigitRegex = RegExp(/^\d+$/);
    if (onlyDigitRegex.test(num)) {
      setPhoneNumber(num);
    }
    onlyDigitRegex.lastIndex = 0;
  }

  return (
    <>
      <div className="container " style={{ padding: '50px' }}>
        <div className="d-flex align-items-center justify-content-center flex-column h-100 ">
          <h3 className="text-center">Welcome Back</h3>
          <h6 className="text-center font-weight-normal">
            Login to your account
          </h6>
          <div className="w-100 d-flex align-items-center justify-content-center flex-column mt-3">
            <form
              className="d-flex flex-column align-items-center justify-content-center"
              onSubmit={(e) => {
                handleContinueClick(e);
              }}
            >
              <FormControl
                hiddenLabel="true"
                size="small"
                margin="dense"
                fullWidth="true"
              >
                <FilledInput
                  style={{
                    backgroundColor: "#F5F6F7",
                  }}
                  className="shadow-sm"
                  placeholder="Enter Phone Number"
                  id="OTP-input"
                  aria-describedby="my-helper-text"
                  aria-label="otp-input"
                  autoFocus={true}
                  startAdornment={<Plus91 />}
                  disableUnderline="true"
                  inputProps={{
                    inputMode: "numeric",
                  }}
                  onChange={(e) => {
                    handlePhoneNumber(e.target.value);
                  }}
                  value={phoneNumber}
                />
              </FormControl>
              <p className="mt-3">
                By clicking on continue, you agree to our{" "}
                <a
                  className="textlink"
                  href="/privacy.html"
                  target="_blank"
                  style={{ color: "#17BAE0", fontWeight: "500" }}
                >
                  Privacy Policy
                </a>{" "}
                &{" "}
                <a
                  className="textlink"
                  href="/terms.html"
                  target="_blank"
                  style={{ color: "#17BAE0", fontWeight: "500" }}
                >
                  Terms of Use
                </a>
              </p>
              <button
                className="btn primary-cta-button rounded-pill mt-2 align-self-center py-2 px-4 mt-5"
                disabled={!continueAllowed}
                style={{ opacity: "1" }}
              >
                {loading ? <Spinner animation="border" /> : "Continue"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

const Plus91 = () => <div className="adornment-beautify">+91</div>;

export default SignInEnterPhoneNumber;
