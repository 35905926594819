// dependencies imports ⇒
import React, { useState } from "react";
// assets and scss imports ⇒
import "./sidebar.scss";
import crossIcon from "../../../assets/crossIcon.svg";
import appleIcon from "../../../assets/forhomepage/apple_logo_new.png";
import playstoreIcon from "../../../assets/forhomepage/playstore_logo_new.png";
import Call from "../../../assets/forhomepage/phone_logo_new.png";
import Mail from "../../../assets/forhomepage/email_logo_new.png";
import C2cimage from "../../../assets/forhomepage/c2c_logo_new.png";
//project file imports(UI components) ⇒
import DoctorInputSearch from "../../common/DoctorInputSearch/DoctorInputSearch";
import { HOME_PAGE_CONTENT, COMPANIES_LINK } from "../constant";
import { DEV, QA } from "../../common/config";

const Sidebar = ({ visible, onClose, handleScrollToContent }) => {
  const [serchContainer, setSerchContainer] = useState(false);

  //const ==>
  const sidebarClass = visible
    ? "side_bar_main_view sidebar-visible"
    : "side_bar_main_view";
  //<==

  const handleLinks = (link) => {
    if (link?.isLink) {
      onClose();
      window.open(link.navigateTo, "_self", "noopener,noreferrer");
    } else {
      onClose();
      handleScrollToContent(link?.navigateTo);
    }
  };

  const removeData = () => {
    setSerchContainer(false);
  };

  //create UI
  const createAppleLogoUI = () => {
    return (
      <div className="home_page_header_logoSpace">
        {" "}
        <a
          href={
            "https://play.google.com/store/apps/details?id=com.connect2clinic.app"
          }
          target="_blank"
        >
          <img className="appstore-logo" src={appleIcon} alt="#" />
        </a>
      </div>
    );
  };

  const createPlayStoreLogoUI = () => {
    return (
      <div className="home_page_header_logoSpace">
        {" "}
        <a
          href={
            "https://play.google.com/store/apps/details?id=com.connect2clinic.app"
          }
          target="_blank"
        >
          <img className="playstore-logo" src={playstoreIcon} alt="#" />
        </a>
      </div>
    );
  };

  const createCallLogoUI = () => {
    return (
      <div>
        {" "}
        <a
          href={
            "https://play.google.com/store/apps/details?id=com.connect2clinic.app"
          }
          target="_blank"
        >
          <img className="call-logo" src={Call} alt="#" />
        </a>
      </div>
    );
  };

  const createMailLogoUI = () => {
    return (
      <div className="home_page_header_logoSpace">
        {" "}
        <a
          href={
            "https://play.google.com/store/apps/details?id=com.connect2clinic.app"
          }
          target="_blank"
        >
          <img src={Mail} alt="#" />
        </a>
      </div>
    );
  };

  const createDownlodUI = () => {
    return <div className="side_bar_company_download_view">Download</div>;
  };

  const createPhoneUI = () => {
    return (
      <div>
        <a href="tel:1800 267 9899" className="side_bar_company_color">
          {HOME_PAGE_CONTENT.C2C_PHONE_NO}
        </a>
      </div>
    );
  };

  const createMailUI = () => {
    return (
      <div>
        <a
          href="mailto:hello@connect2clinic.com"
          className="side_bar_company_color"
        >
          {HOME_PAGE_CONTENT.C2C_EMAIL}
        </a>
      </div>
    );
  };

  const renderLink = (link) => {
    if (link.isLink) {
      return (
        <a href={link.navigateTo} className="sidebar_containt_view">
          {link.title}
        </a>
      );
    } else {
      return <div>{link.title}</div>;
    }
  };

  //<==
  return (
    <div className={sidebarClass}>
      <div className="sidebar-content custom-sidebar-content">
        <div className="close-icon" onClick={onClose}>
          <img src={crossIcon} alt="Close" />
        </div>
        <img className="side_bar_color" src={C2cimage} />
        <div style={{ marginTop: 60 }}>
          {COMPANIES_LINK.map((link, index) =>
            link.title === "ABDM" ? (
              (process.env.REACT_APP_ENV === DEV ||
                process.env.REACT_APP_ENV === QA ||
                window.location.hostname === "localhost") && (
                <div
                  key={index}
                  className="sidebar_containt_view"
                  onClick={() => handleLinks(link)}
                >
                  {renderLink(link)}
                </div>
              )
            ) : (
              <div
                key={index}
                className="sidebar_containt_view"
                onClick={() => handleLinks(link)}
              >
                {renderLink(link)}
              </div>
            )
          )}
        </div>
      </div>
      <div
        className={`side_bar_doctor_search ${serchContainer ? "openSerchBox" : ""
          }`}
      >
        <DoctorInputSearch closeData={removeData} />
      </div>
      <div
        className="bottom-bar"
        style={{ position: "absolute", boxShadow: "-1px -10px 30px #B1B1B119" }}
      >
        <div className="bar-icons">
          {createAppleLogoUI()}
          {createPlayStoreLogoUI()}
          {createDownlodUI()}
          {createCallLogoUI()}
          {createPhoneUI()}
        </div>
        <div className="mail-section">
          {createMailLogoUI()} {createMailUI()}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
