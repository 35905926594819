import moment from "moment";
import React from "react";
import Select from "react-select";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

function CustomDropdown(props) {
  const customStyles = {
    container: (provided) => ({
      ...provided,
      background: "#f5f5f5",
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
    }),
    control: (provided) => ({
      ...provided,
      background: "#f5f5f5",
      borderColor: "#56CDE8 !important",
      boxShadow: "none",
      cursor: "pointer",
      "&:hover": {
        borderColor: "#56CDE8 !important",
        boxShadow: "none",
      },
    }),
    clearIndicator: (provided) => ({
      ...provided,
      background: "#f5f5f5",
    }),
  };

  const renderDate = (date) => {
    let dateToRender = moment(date, "YYYY-MM-DD hh:mm:ss+z").format(
      "D MMMM, YYYY"
    );
    if (moment(dateToRender).isValid()) {
      return moment(date, "YYYY-MM-DD hh:mm:ss+z").format("D MMMM, YYYY");
    } else {
      let dateToRender = moment(date.split(" ")[0], "YYYY-MM-DD").format(
        "D MMMM, YYYY"
      );

      if (moment(dateToRender).isValid()) {
        moment(date.split(" ")[0], "YYYY-MM-DD").format("D MMMM, YYYY");
      }
    }
    return "";
  };
  const getAppointmentType = (consType) => {
    switch (consType) {
      case "online":
        return "Online Consultation";
      default:
        return consType;
    }
  };

  const Option = (props) => {
    const { data, innerRef, innerProps } = props;

    return (
      <div
        className="px-3 py-2"
        ref={innerRef}
        {...innerProps}
        style={{ cursor: "pointer" }}
      >
        <div>
          <span style={{ fontSize: "1rem" }}>
            {renderDate(data.appointment_start_date_time).length > 0
              ? renderDate(data.appointment_start_date_time)
              : moment(data.appointment_date).format("D MMMM, YYYY")}
          </span>
          <span className="ml-1"> - </span>
          <span style={{ fontSize: "0.8rem" }}> {data.patient_name}</span>
        </div>
        <div style={{ color: "#A6ACB9" }}>
          <span className="my-1" style={{ fontSize: "12px" }}>
            {getAppointmentType(data.appointment_type)} -{" "}
            <span style={{ textTransform: "capitalize" }}>
              {data.consultation_type}
            </span>
            {data.prescription_type === "shared" && (
              <span
                className="my-1"
                style={{ fontSize: "12px", color: "#E0228B" }}
              >
                <span style={{ color: "black" }}> &bull;</span>{" "}
                {data?.prescription_type?.charAt(0).toUpperCase() +
                  data?.prescription_type?.slice(1)}
              </span>
            )}
            {data.prescription_type === "saved" && (
              <span
                className="my-1"
                style={{ fontSize: "12px", color: "#56CDE8" }}
              >
                <span style={{ color: "black" }}> &bull;</span>{" "}
                {data?.prescription_type?.charAt(0).toUpperCase() +
                  data?.prescription_type?.slice(1)}
              </span>
            )}
          </span>
        </div>
      </div>
    );
  };
  const formatOptionLabel = (data, { context }) => {
    if (context === "value") {
      return (
        <div className="d-flex align-items-center justify-content-start">
          <span style={{ fontSize: "1rem" }}>
            {/* {renderDate(data.appointment_start_date_time)} */}
            {renderDate(data.appointment_start_date_time).length > 0
              ? renderDate(data.appointment_start_date_time)
              : moment(data.appointment_date).format("D MMMM, YYYY")}
          </span>
          <span className="ml-1"> - </span>
          <span style={{ fontSize: "0.8rem" }} className="ml-1">
            {data.patient_name}
          </span>
        </div>
      );
    }
  };

  return (
    <>
      <Select
        className="w-100 mb-3 select__menu-list"
        classNamePrefix="select"
        name="prescriptionSelector"
        formatOptionLabel={formatOptionLabel}
        placeholder="Select Prescription"
        components={{ Option }}
        styles={customStyles}
        {...props}
      />
    </>
  );
}

export default CustomDropdown;
