import React, { useState, useEffect } from "react";
import classes from "../DoctorProfile.module.css";
import "../../../globalStyles/doctorProfileStyle.css";
import { PROCEDURE_SLOTS } from "../../DoctorComponents/MyScheduleFees/Constants";
import { useLocation } from "react-router-dom";

const ConsultationInformation = ({
  fees,
  durationTime,
  procedureDetails,
  selectedConsultationType,
  consultationList,
  consultationTypeList,
  doctorSlug,
}) => {
  //fees -->removed fees,//duration,
  const [selectedOption, setselectedOption] = useState("");
  const [payableFee, setpayableFee] = useState(0);
  let id = localStorage.getItem("procedure_id");
  let consultation_type = localStorage.getItem("procedure_consultation_type") || localStorage.getItem("consultation-type");
  const [timeduration, setTimeDuration] = useState();
  const [isInstantMeetingId, setIsInstantMeetingId] = useState(null);

  let userType = localStorage.getItem("user_type");
  const location = useLocation();

  useEffect(() => {
    // Clear or set initial values for localStorage when the component mounts
    if (doctorSlug && location.pathname.includes(doctorSlug)) {
      localStorage.removeItem("procedure_consultation_type");
    }
  }, []);

  useEffect(() => {
    setIsInstantMeetingId(localStorage.getItem("meeting_id"));
    if (procedureDetails?.length > 0 && id) {
      let selected = procedureDetails.find((ele) => ele.id == id);
      if (selected) {
        PROCEDURE_SLOTS.forEach((data) => {
          if (data.value == selected.consultation_duration) {
            setTimeDuration(data.label);
          }
        });
        feesOnChangeHandler(selected.fees);
        setselectedOption(selected);
      }
    } else {
      if (consultation_type) {
        if (consultationTypeList) {
          let selected = consultationTypeList.find(
            (ele) => ele.consultation_type == consultation_type
          );
          if (selected) {
            PROCEDURE_SLOTS.forEach((data) => {
              if (data.slotType == selected.slot_type) {
                setTimeDuration(data.label);
              }
            });
            feesOnChangeHandler(selected.fees);
            setselectedOption(selected);
          }
        }
      } else if (consultationList) {
        let selected = consultationList.find(
          (ele) => ele.consultation_type == selectedConsultationType
        );
        if (selected) {
          PROCEDURE_SLOTS.forEach((data) => {
            if (data.slotType == selected.slot_type) {
              setTimeDuration(data.label);
            }
          });
          feesOnChangeHandler(selected.fees);
          setselectedOption(selected);
        }
      } else if (userType === "patient") {
        setpayableFee(fees);
      }
    }
  }, [
    id,
    procedureDetails,
    selectedConsultationType,
    consultationList,
    consultationTypeList,
    consultation_type,
    fees,
  ]);

  useEffect(() => {
    setTimeDuration(durationTime);
  }, [durationTime]);

  const feesOnChangeHandler = (event) => {
    const value = event;
    if (isNaN(value)) {
      setpayableFee(0);
    }
    let costBeforeRoundup = (Number(value) / 94.4287) * 100;
    let lowerLimit =
      Number(Math.floor(costBeforeRoundup).toFixed(2)) + Number(0.01);
    const finalCost =
      costBeforeRoundup > lowerLimit
        ? parseFloat(costBeforeRoundup.toFixed(2))
        : Math.floor(lowerLimit);
    setpayableFee(finalCost);
  };

  return (
    <div
      className={`${classes.consultationInformation} consultationInformation_wrapper `}
    >
      <div className={classes.consultationDuration}>
        <img src={process.env.PUBLIC_URL + "/images/duration-new.svg"} alt="" />
        <div className="duration-block">
          <p className="duration-text">Duration</p>
          <p style={{ color: "#0F0F0F" }}>
            {isInstantMeetingId == null ? timeduration : "15 Mins"}
          </p>{" "}
          {/*{duration}*/}
        </div>
      </div>
      <div style={{ borderRight: "1px solid #d6dae5", height: "60px" }}></div>
      <div className={`${classes.consultationFees} 'ml-auto' `}>
        <img src={process.env.PUBLIC_URL + "/images/fees-new.svg"} alt="Fees" />
        <div className="fees-block">
          <p className="fees-text" style={{ fontWeight: "500" }}>
            Fee
          </p>
          {isInstantMeetingId == null ? (
            <>
              <span style={{ color: "#0F0F0F" }}>
                {selectedOption?.consultation_type == 5 ||
                selectedOption?.consultation_type == 6
                  ? selectedOption?.fees_visible_to_patient
                  : `Rs ${
                      selectedOption?.discount_percentage != "" &&
                      selectedOption &&
                      selectedOption?.discounted_amount
                        ? selectedOption?.discounted_amount
                        : payableFee
                    }`}
              </span>
              <>
                {!selectedOption ||
                selectedOption?.consultation_type == 5 ||
                selectedOption?.consultation_type == 6 ||
                selectedOption?.discount_percentage === "0" ||
                selectedOption?.discount_percentage === "" ? null : (
                  <>
                    <span
                      style={{
                        color: "#A3A9B7",
                        fontSize: "11px",
                        fontWeight: "500",
                        marginLeft: "10px",
                      }}
                    >
                      <s>{`Rs ${payableFee}`}</s>
                    </span>
                    <p
                      style={{
                        color: "#F07223",
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                    >
                      {selectedOption?.discount_percentage &&
                        selectedOption?.discount_percentage !== "" &&
                        selectedOption?.discount_percentage !== "0" &&
                        `(${selectedOption?.discount_percentage} % off on fee)`}
                    </p>
                  </>
                )}
              </>
            </>
          ) : (
            <span style={{ color: "#0F0F0F" }}>
              Rs {localStorage.getItem("instantFees")}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConsultationInformation;
