import React from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Modal,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
const formValidate = yup.object().shape({
  receiver_phone_no: yup.string().required('Receiver phone number is required').min(10, "Must be 10 digits").max(10, "Must be 10 digits")
})

const LoginOTPModal = ({ showLoginModal, otp, handleOtpSubmit }) => {
  const { status } = otp;
  const {handleSubmit, errors, register} = useForm({
    resolver: yupResolver(formValidate)
  })
  return (
    <Modal show={showLoginModal} className="verify-otp-modal">
      <Modal.Body className="text-center">
        <Form>  
        <h2>Verify Number</h2>
        <p>
          To access the consultation, please authenticate you mobile number via
          OTP
        </p>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">+91</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            name={"receiver_phone_no"}
            type={"number"}
            ref={register}
            placeholder="Enter Receiver Number"
          />
          <FormControl
            type={"hidden"}
            name={"channel_id"}
            ref={register}
            value={"receiver-login"}
          />
        </InputGroup>
        {errors.receiver_phone_no && <span style={{color: 'red'}}>Please enter receiver number</span>}
        <div>
        <button
          onClick={handleSubmit(handleOtpSubmit)}
          className="btn primary-cta-button rounded-pill"
        >
          {otp.isLoading ? "loading...." : "Get OTP"}
        </button>
        </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default LoginOTPModal;
