import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import check from "../../../assets/pem/check.svg";

const Popup = (props) => {
  const { title, show, handleClose } = props;
  const [showPopUp, setShowPopUp] = useState(show);

  //   lifecycle ==>
  useEffect(() => {
    if (show) {
      setShowPopUp(true);
      setTimeout(function () {
        setShowPopUp(false);
        handleClose();
      }, 3000);
    }
  }, [show]);
  //   <==

  return (
    <>
      {showPopUp ? (
        <Modal show={showPopUp} onHide={handleClose} centered={true}>
          <Modal.Body className="p-3 p-lg-3">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img
                src={check}
                width="45px"
                className="delete-icon"
                alt="success"
              />
              <h4
                className="mt-3"
                style={{
                  textAlign: "center",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                {title}
              </h4>
              {/* <div className="d-flex flex-row justify-content-center my-3">
                <button
                  className="btn primary-cta-button rounded-pill px-5 mr-2"
                  onClick={handleClose}
                >
                  Okay
                </button>
              </div> */}
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
};

export default Popup;
