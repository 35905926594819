// dependencies imports ⇒
import React from "react";
// assets and scss imports ⇒
import "./homePagePartnersContainer.scss";
import { PARTNERS } from "../constant";

// project file imports(UI components) ⇒

// const file imports(helper methods, etc) ⇒

const HomePagePartnersContainer = () => {
  // const declarations ⇒

  // let or var declarations ⇒

  // life cycle methods ⇒

  // API Calls ⇒

  // Helper methods ⇒

  const getPartnersList = () => {
    return (
      <>
        {PARTNERS.map((partner, index) => (
          <div className="home-page-partners-slide" key={index}>
            <div className="home-page-partners-logo">
              <img src={partner?.logo} alt="" />
            </div>
            <div className="home-page-partners-name">{partner?.title}</div>
          </div>
        ))}
      </>
    );
  };

  // Actions ⇒

  // create UI ⇒
  const homePagePartnersContainerUI = () => {
    return (
      <div className="home-page-partners-container">
        <div className="home-page-partners-title">Our Partners</div>
        <div class="home-page-partners-slider">
          <div class="home-page-partners-slide-track">
            {getPartnersList()}
            {getPartnersList()}
          </div>
        </div>
      </div>
    );
  };

  return <>{homePagePartnersContainerUI()}</>;
};

//export
export default HomePagePartnersContainer;
