import React, { useEffect, useState } from "react";
import { View, Text, Image, StyleSheet } from "@react-pdf/renderer";
import { getHeaderFooterImage } from "../ERXPdfHelper/helper";
import { getFooterData } from "../ERXPdfHelper/footerHelper";

const ERXPdfFooterView = (props) => {
  const { erxPdfData, pdfFontFamily } = props;
  // 595/1052 = 0.565 for 72 PPI so 16 = 16*0.565 = 9.04 = 9
  const styles = StyleSheet.create({
    footer: {
      bottom: 0,
      left: 0,
      width: "100%",
      position: "absolute",
    },
    clinic_footer_container: {
      display: "flex",
      flexDirection: "row",
    },
    hospital_title: {
      fontSize: "7px", // 13*0.565 ~ 7
      color: "#000000",
      fontFamily: pdfFontFamily,
      marginBottom: "6px", // 10*.565 ~ 6
      fontWeight: "600",
    },
    hospital_subtitle: {
      fontSize: "7px", // 13*0.565 ~ 7
      color: "#000000",
      fontFamily: pdfFontFamily,
    },
    signature_logo_view: {
      bottom: 0,
      right: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    signature_img: {
      height: "34px", // 60*0.565 ~ 34
      marginBottom: "3px", // 6*0.565
      width: "100%",
      objectFit: "fill",
    },

    signature_text: {
      fontSize: "6px", // 10*0.565 ~ 6
      color: "#000000",
      fontFamily: pdfFontFamily,
    },
    logo_img: {
      right: 0,
      height: "34px", // 60*0.565 ~ 34
      width: "34px",
      flexDirection: "row",
      margin: "3px 0px", // 6*0.565
    },
    clinic_powered_by_container: {
      display: "flex",
      flexDirection: "row",
    },
    clinic_powered_by_text: {
      fontSize: "6px", // 10*0.565 ~ 6
      color: "#000000",
      fontFamily: pdfFontFamily,
      height: "15px",
      textAlign: "center",
      paddingTop: "3px",
    },
    clinic_powered_by_img: {
      height: "15px", // 26*0.565 101*0.565 ~ 15 57
      width: "57px",
    },
    footer_image: {
      maxHeight: "82px", // 140*0.565 ~ 82
      width: "100%",
    },
    dummy_text: {
      fontSize: "7px", // 12*0.565 ~ 7
      color: "#F82323",
      textAlign: "left",
      marginBottom: "6px", // 10*0.565 ~ 6
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    signature_view: {
      paddingRight: "11px", // 20*0.565 ~ 11
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      width: "20%",
    },
    powered_by_view: {
      paddingLeft: "11px", // 20*0.565 ~ 11
      display: "flex",
      flexDirection: "column",
      width: "80%",
    },
    footerHospitalLogo: {
      width: "33px",
      height: "33px",
      margin: "3px 0",
    },
    footerDummyTextContainer: {
      top: 15,
      bottom: 0,
      left: 0,
      width: "80%",
      paddingRight: "8px",
    },
  });
  const [pdfFooterData, setPdfFooterData] = useState({});

  // Lifecycle Methods ===>

  useEffect(() => {
    if (Object.keys(erxPdfData)?.length !== 0) {
      getPDFFooterData();
    }
  }, [erxPdfData]);

  // <===

  // Helper Methods ===>

  const getPDFFooterData = async () => {
    let [footer_img, showFooterTemplate] = await getHeaderFooterImage(
      "footer",
      erxPdfData?.clinic_id,
      erxPdfData?.doctor_hospital_details[0],
      erxPdfData?.doctor_profile_details
    );

    let footerData = await getFooterData(
      erxPdfData,
      erxPdfData?.doctor_hospital_details,
      erxPdfData?.doctor_profile_details,
      showFooterTemplate,
      footer_img
    );

    setPdfFooterData({ ...footerData });
  };

  const getPdfFooterHeight = () => {
    let height = "136px";
    let customMargins =
      erxPdfData?.doctor_profile_details?.custom_margin_dimensions;
    if (erxPdfData?.doctor_profile_details?.custom_margins_enabled) {
      let rxBottom = customMargins?.rxBottom;
      const isCmUnit = customMargins?.unit === "cm";
      const isInUnit = customMargins?.unit === "in";
      const isRxBottomEqualTo0 =
        customMargins?.rxBottom === 0 || !customMargins?.rxBottom;
      if (isCmUnit) {
        if (isRxBottomEqualTo0) {
          rxBottom = "2.5";
        } else {
          rxBottom = 2 + +customMargins?.rxBottom;
        }
      } else if (isInUnit) {
        rxBottom = 1 + +customMargins?.rxBottom;
      }

      height = (+rxBottom).toString() + customMargins?.unit;
    }

    return height;
  };

  const getPdfFooterBorder = () => {
    return !erxPdfData?.doctor_profile_details?.custom_margins_enabled
      ? "0.7px solid #DAE0E2"
      : "none";
  };

  const getPdfFooterPaddingLeft = () => {
    let paddingLeft = "0";
    let customMargins =
      erxPdfData?.doctor_profile_details?.custom_margin_dimensions;
    if (erxPdfData?.doctor_profile_details?.custom_margins_enabled) {
      paddingLeft = (+customMargins?.rxLeft).toString() + customMargins?.unit;
    }

    return paddingLeft;
  };

  const getPdfFooterPaddingRight = () => {
    let paddingRight = "0";
    let customMargins =
      erxPdfData?.doctor_profile_details?.custom_margin_dimensions;
    if (erxPdfData?.doctor_profile_details?.custom_margins_enabled) {
      paddingRight = (+customMargins?.rxRight).toString() + customMargins?.unit;
    }

    return paddingRight;
  };

  const getClinicFooterPadding = () => {
    let paddingRight = "0",
      paddingLeft = "0";
    let customMargins =
      erxPdfData?.doctor_profile_details?.custom_margin_dimensions;
    if (erxPdfData?.doctor_profile_details?.custom_margins_enabled) {
      if (+customMargins?.rxRight <= 0) {
        paddingRight = "11px";
      }
      if (+customMargins?.rxLeft <= 0) {
        paddingLeft = "11px";
      }
    }
    return erxPdfData?.doctor_profile_details?.custom_margins_enabled
      ? `7px ${paddingRight} 0 ${paddingLeft}`
      : "7px 11px";
  };

  // <===

  const createClinicDetails = () => {
    return (
      <View style={{ marginTop: "5px" }}>
        {pdfFooterData?.doctor_hospital_details?.map((item, ind) => (
          <View
            style={{
              marginBottom:
                ind !== pdfFooterData?.doctor_hospital_details?.length - 1
                  ? "9px"
                  : "0",
            }}
            key={ind}
          >
            <Text style={styles.hospital_title}>
              Clinic/Hospital Details {ind + 1}:
            </Text>
            <View style={{ marginBottom: "2px" }}>
              <Text style={styles.hospital_subtitle}>
                {item.is_hospital_name_enabled && item.hospital_name + " |"}
                {item.is_hospital_address_enabled && item.hospital_address}
              </Text>
              <Text style={styles.hospital_subtitle}>
                {item.is_hospital_contact_no_enabled &&
                  " | Contact No:" + item.hospital_contact_number}
              </Text>
            </View>
            <Text style={styles.hospital_subtitle}>
              {item.is_hospital_timings_enabled && item.hospital_timings}
            </Text>
          </View>
        ))}
      </View>
    );
  };

  const createLogoSignatureView = () => {
    return (
      <View
        style={[
          styles.signature_logo_view,
          { width: "20%", textAlign: "right" },
        ]}
      >
        {pdfFooterData?.signature_url &&
          pdfFooterData?.signature_url !== "" && (
            <Image
              style={styles.signature_img}
              src={pdfFooterData?.signature_url}
            />
          )}
        <Text style={styles.signature_text}>Signature</Text>
        {pdfFooterData?.hospitalLogo &&
          pdfFooterData?.hospitalLogo !== "" &&
          !erxPdfData?.doctor_profile_details?.custom_margins_enabled && (
            <View>
              <Image
                src={pdfFooterData?.hospitalLogo}
                style={styles.footerHospitalLogo}
              />
            </View>
          )}
        <View style={styles.clinic_powered_by_container}>
          <Text style={styles.clinic_powered_by_text}>Powered by</Text>
          <Image
            style={styles.clinic_powered_by_img}
            src={pdfFooterData?.c2cLogo}
          />
        </View>
      </View>
    );
  };

  const createFooterImageView = () => {
    return (
      <>
        <View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              bottom: 0,
              left: 0,
              height: "54px", // 116 - 62
              paddingTop: "5px",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <View style={styles.powered_by_view}>
                <View style={styles.clinic_powered_by_container}>
                  <Text style={styles.clinic_powered_by_text}>Powered by</Text>
                  <Image
                    style={styles.clinic_powered_by_img}
                    src={pdfFooterData?.c2cLogo}
                  />
                </View>
                {pdfFooterData?.dummy_doctor_flag && (
                  <View>
                    <Text style={styles.dummy_text}>
                      *This is a dummy prescription & is not valid for the
                      purchase of any medicine. Please do not issue any
                      medication
                    </Text>
                  </View>
                )}
              </View>
            </View>
            <View style={styles.signature_view}>
              {pdfFooterData?.signature_url &&
                pdfFooterData?.signature_url !== "" && (
                  <Image
                    style={styles.signature_img}
                    src={pdfFooterData?.signature_url}
                  />
                )}
              <Text style={styles.signature_text}>Signature</Text>
            </View>
          </View>

          {pdfFooterData?.footer_img && pdfFooterData?.footer_img !== "" && (
            <Image
              style={styles.footer_image}
              src={pdfFooterData?.footer_img}
            />
          )}
        </View>
      </>
    );
  };

  return (
    <View
      style={[
        styles.footer,
        {
          height: getPdfFooterHeight(),
          borderTop: getPdfFooterBorder(),
          paddingLeft: getPdfFooterPaddingLeft(),
          paddingRight: getPdfFooterPaddingRight(),
        },
      ]}
      fixed
    >
      {pdfFooterData?.is_uploaded_footer_template_to_be_used &&
      !erxPdfData?.doctor_profile_details?.custom_margins_enabled ? (
        createFooterImageView()
      ) : (
        <View
          style={[
            styles.clinic_footer_container,
            { padding: getClinicFooterPadding() },
          ]}
        >
          {!erxPdfData?.doctor_profile_details?.custom_margins_enabled ? (
            <View style={{ width: "80%", marginRight: "8px" }}>
              {createClinicDetails()}
            </View>
          ) : (
            <View style={{ width: "80%", marginRight: "8px" }}>
              {pdfFooterData?.dummy_doctor_flag && (
                <View style={styles.footerDummyTextContainer}>
                  <Text style={styles.dummy_text}>
                    *This is a dummy prescription & is not valid for the
                    purchase of any medicine. Please do not issue any medication
                  </Text>
                </View>
              )}
            </View>
          )}

          {createLogoSignatureView()}
        </View>
      )}
    </View>
  );
};

export default ERXPdfFooterView;
