import React, { Suspense } from "react";
import Loader from "../components/UI Component/Loader/Loader";
// const PatientRoutes = React.lazy(() => import("../Routes/PatientRoutes"));
const PatientRoutes = React.lazy(() => import("../Routes/PatientRoutesNew"));

export default function () {
  return (
    <>
      <Suspense fallback={<div><Loader/></div>}>
        <PatientRoutes />
      </Suspense>
    </>
  );
}
