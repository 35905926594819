import React from "react";
import "./DownloadAppAndCallContact.scss";
import playStoreLogo from "../../assets/doctorLoginAssets/Group 6977.svg";
import appleLogo from "../../assets/doctorLoginAssets/Group.svg";
import phoneLogo from "../../assets/doctorLoginAssets/Phone Rounded.svg";
import emailLogo from "../../assets/doctorLoginAssets/Letter.svg";

const DownloadAppAndCallContactUI = ({ pharmacy }) => {
  return (
    <div className="dacc-downloadapp-and-call-buttons-wrapper">
      {!pharmacy && (
        <div className="dacc-download-app-text">Download our App</div>
      )}
      {!pharmacy && (
        <div className="dacc-download-btn-container">
          <a
            className="dacc-download-app-btn"
            href={"https://apps.apple.com/in/app/connect2clinic/id1530572274"}
            target="_blank"
          >
            <img src={appleLogo} className="dacc-apple-play-logo" alt="#" />
            Apple Store
          </a>
          <a
            className="dacc-download-app-btn"
            href={
              "https://play.google.com/store/apps/details?id=com.connect2clinic.app"
            }
            target="_blank"
          >
            <img src={playStoreLogo} className="dacc-apple-play-logo" alt="#" />
            Google Play
          </a>
        </div>
      )}
      <div className="dacc-contact-email-coontainer">
        <div className="dacc-c2c-contact">
          <img className="dacc-c2c-contact-logo" src={phoneLogo} alt="#" />
          <a className="dacc-contact-btn-text" href="tel:18002679899">
            1800 267 9899
          </a>
        </div>
        <div className="dacc-c2c-contact" style={{ marginRight: 10 }}>
          <img className="dacc-c2c-contact-logo" src={emailLogo} alt="#" />
          <a
            className="dacc-contact-btn-text"
            href="mailto: hello@connect2clinic.com"
          >
            hello@connect2clinic.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppAndCallContactUI;
