// dependencies imports ⇒
import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";

// assets and scss imports ⇒
import "./doctorResetPasswordContainer.scss";
import PhoneNumberInputWithAction from "../../common/PhoneNumberInputWithAction";
import OTPInput from "../../common/OTPInput";
import ActionButton from "../../common/Buttons/ActionButton";
import { OTP_ERROR_MESSAGE } from "../constant";
import PasswordInputWithHeader from "../../common/PasswordInputWithHeader";
import SignInFormLayout from "../../common/SignInFormLayout";
import Loader from "../../UI Component/Loader/Loader";
import swal from "sweetalert";
import {
  DOCTOR_LOGIN_WITH_PASSWORD_OR_OTP,
  SEND_ONBORAD_DOCTOR_OTP,
  SEND_OTP,
} from "../DoctorSignInAPIHandler/apiConstant";
import { LOCALSTORAGEKEY } from "../../../Util/Helper/localStorageUtils";
import { useCookies } from "react-cookie";
import { moveToNextPage } from "../../common/DeepLink";
import beforeLoginAxios from "../../../Util/API Calls/beforeLogin";
import StaffLoginErrorModal from "../../Staff/StaffLoginErrorModal";
import { C2CConsole } from "../../../Util/Helper/C2CConsole";
import { c2cBeforeLoginPostApiHandler } from "../../../Util/c2cBeforeLoginApiHandler";
// project file imports(UI components) ⇒

// const file imports(helper methods, etc) ⇒

const DoctorResetPasswordContainer = (props) => {
  const { phoneNumber, setPhoneNumber, loader } = props;
  // const declarations ⇒
  const [disableContinueButton, setDisableContinueButton] = useState(true);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [otpInput, setOtpInput] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [showPassowrdError, setShowPasswordError] = useState(false);
  const [isPartialDoctor, setIsPartialDoctor] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showConfirmPassowrdError, setShowConfirmPasswordError] =
    useState(false);
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const [showRegisterButtonLoader, setShowRegisterButtonLoader] =
    useState(false);

  const [cookies] = useCookies(["cookie-name"]);
  const confirmRef = useRef();
  const history = useHistory();
  // let or var declarations ⇒

  // life cycle methods ⇒
  useEffect(() => {
    if (mobileNumber?.length === 10) {
      handleInputAction();
    }
  }, [mobileNumber]);

  useEffect(() => {
    if (
      mobileNumber?.length === 10 &&
      otpInput?.length === 6 &&
      confirmRef?.current
    ) {
      confirmRef.current.focus();
    }
  }, [otpInput]);

  useEffect(() => {
    if (mobileNumber?.length < 10 || !password) {
      setDisableContinueButton(true);
    } else {
      setDisableContinueButton(false);
    }
  }, [password, mobileNumber]);

  useEffect(() => {
    if (phoneNumber?.length == 10) {
      setMobileNumber(phoneNumber);
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (
      mobileNumber?.length < 10 ||
      otpInput?.length < 6 ||
      !password ||
      !confirmPassword
    ) {
      setDisableContinueButton(true);
    } else {
      setDisableContinueButton(false);
    }
  }, [otpInput, mobileNumber, confirmPassword, password]);

  // API Calls ⇒

  const handleSubmitAPICall = () => {
    let payload = {
      channel_id: "doctor-login",
      login_type: "password",
      phone_no: "+91" + mobileNumber,
      user_type: "doctor",
    };
    const sendOTPResponse = c2cBeforeLoginPostApiHandler(SEND_OTP, payload);
    sendOTPResponse.then(([response, error]) => {
      if (!error) {
        if (response.data && response.data.statusCode === 200) {
          localStorage.setItem("is_partial_doctor", "False");
          setIsPartialDoctor(false);
          setShowRegisterButtonLoader(false);
          if (response.data.password_set === 1) {
            getOTPAPICall();
          } else {
            setShowRegisterButtonLoader(false);
            setShowOtpInput(true);
          }
        } else if (response.data && response.data.statusCode === 500) {
          setIsPartialDoctor(true);
          getPartialDoctorOTP();
        } else if (response.data && response.data.statusCode === 501) {
          setShowRegisterButtonLoader(false);
          swal({
            title: "Invalid Phone Number Entered",
            type: "info",
          });
        } else {
          setShowRegisterButtonLoader(false);
          swal({
            title: "Something Went Wrong!",
            type: "info",
          });
        }
      } else {
        setShowRegisterButtonLoader(false);
      }
    });
  };

  const getOTPAPICall = () => {
    let payload = {
      channel_id: "doctor-login",
      phone_no: "+91" + mobileNumber,
      user_type: "doctor",
    };

    const doctorOTPResponse = c2cBeforeLoginPostApiHandler(SEND_OTP, payload);
    doctorOTPResponse.then(([response, error]) => {
      if (!error) {
        if (response.data.statusCode == 200) {
          setShowRegisterButtonLoader(false);
          setShowOtpInput(true);
        } else {
          setShowRegisterButtonLoader(false);
        }
      } else {
        setShowRegisterButtonLoader(false);
      }
    });
  };

  const handleLoginAPICall = () => {
    let payload = {
      channel_id: "doctor-login",
      phone_no: "+91" + mobileNumber,
      user_type: "doctor",
      new_password: password,
      user_otp: otpInput,
      stay_login: false,
      source: "web",
    };
    setShowButtonLoader(true);
    const doctorLoginResponse = c2cBeforeLoginPostApiHandler(
      DOCTOR_LOGIN_WITH_PASSWORD_OR_OTP,
      payload
    );
    doctorLoginResponse.then(([response, error]) => {
      if (!error) {
        if (response.data && response.data.statusCode === 200) {
          localStorage.setItem("id_token", response.data.user.id_token);
          localStorage.setItem(
            LOCALSTORAGEKEY.USER_ID,
            response.data.user.user_id
          );
          localStorage.setItem("user_type", response.data.user.user_type);
          localStorage.setItem("user_name", response.data.user.user_name);
          localStorage.setItem("full_name", response.data.user.full_name);
          localStorage.setItem(
            "doctor_role_name",
            response?.data?.user?.doctor_role_name
          );
          localStorage.setItem("doctorPhone", `+91${phoneNumber}`);
          localStorage.setItem(
            "last_login_date",
            response.data.user.last_login_date
          );
          localStorage.setItem(
            LOCALSTORAGEKEY.DOCTOR_CLINIC_ASSOCIATION,
            !!response?.data?.doctor_clinic_association
          );
          swal({
            title: "Login Successful!",
            type: "success",
          });
          setTimeout(() => {
            if (localStorage.getItem("id_token")) {
              if (localStorage.getItem("cid")) {
                history.push(
                  `/doctor/instant_consultation?consultation_id=${localStorage.getItem(
                    "cid"
                  )}`
                );
              } else {
                if (response?.data?.doctor_clinic_association === true) {
                  if (
                    cookies?.lastPathBeforeLogin &&
                    cookies?.lastPathBeforeLogin == "/doctor/account/webinars"
                  ) {
                    history.push("/clinic/account/webinars");
                  } else {
                    moveToNextPage("clinic", history, cookies);
                  }
                } else {
                  moveToNextPage("doctor", history, cookies);
                }
              }
            }
          }, 100);
        } else {
          setOtpError(true);
          setShowButtonLoader(false);
        }
      } else {
        setShowButtonLoader(false);
      }
    });
  };

  const getPartialDoctorOTP = () => {
    let payload = {
      channel_id: "doctor-login",
      phone_no: "+91" + mobileNumber,
      user_type: "doctor",
    };

    const doctorOTPResponse = c2cBeforeLoginPostApiHandler(
      SEND_ONBORAD_DOCTOR_OTP,
      payload
    );
    doctorOTPResponse.then(([response, error]) => {
      if (!error) {
        if (response.data.statusCode == 200) {
          setShowRegisterButtonLoader(false);
          setShowOtpInput(true);
        } else {
          setShowRegisterButtonLoader(false);
        }
      } else {
        setShowRegisterButtonLoader(false);
      }
    });
  };

  const handlePartialDoctorLoginAPICall = () => {
    setShowPasswordError(false);
    setOtpError(false);
    if (otpInput.length !== 6) {
      setOtpError(true);
      return;
    }
    if (password.length <= 5) {
      setPasswordError("Password must be at least 6 characters");
      setShowPasswordError(true);
      return;
    }

    setShowButtonLoader(true);
    beforeLoginAxios
      .post("doctor/webinar-login", {
        phone_no: `+91${mobileNumber}`,
        user_otp: otpInput,
        new_password: password,
      })
      .then((response) => {
        if (response) {
          if (response?.data?.statusCode == 200) {
            localStorage.setItem("id_token", response.data.user.id_token);
            localStorage.setItem(
              LOCALSTORAGEKEY.USER_ID,
              response.data.user.user_id
            );
            localStorage.setItem("user_type", response.data.user.user_type);
            localStorage.setItem("doctorPhone", `+91${phoneNumber}`);
            localStorage.setItem("onBoardedDoctor", true);
            localStorage.setItem(
              "specialization",
              response?.data?.user?.specialization_id
            );

            setTimeout(() => {
              if (localStorage.getItem("id_token")) {
                history.replace(
                  "/doctor/account/dashboard?utm_source=webapp&utm_medium=onboarding"
                );
              }
            }, 100);

            swal({
              title: "Login Successful!",
              type: "success",
            });
          } else {
            setOtpError(true);
            setShowButtonLoader(false);
          }
        } else {
          setShowButtonLoader(false);
        }
      });
  };
  // Helper methods ⇒

  // Actions ⇒

  const handleContinue = () => {
    setOtpError(false);
    setShowConfirmPasswordError(false);
    setShowPasswordError(false);
    if (password.length <= 5) {
      setPasswordError("Password must be at least 6 characters");
      setShowPasswordError(true);
      return;
    } else {
      setShowPasswordError(false);
    }
    if (confirmPassword.length <= 5) {
      setConfirmPasswordError("Password must be at least 6 characters");
      setShowConfirmPasswordError(true);
      return;
    } else {
      setShowConfirmPasswordError(false);
    }
    if (password === confirmPassword) {
      setShowConfirmPasswordError(false);
      setShowPasswordError(false);
      if (isStaff) {
        handleStaffLoginAPICall();
      } else {
        if (isPartialDoctor) {
          handlePartialDoctorLoginAPICall();
        } else {
          handleLoginAPICall();
        }
      }
    } else {
      setPasswordError("New Password and Confirm Password should be same");
      setConfirmPasswordError(
        "New Password and Confirm Password should be same"
      );
      setShowConfirmPasswordError(true);
      setShowPasswordError(true);
    }
  };

  const handleResendOTP = () => {
    if (!isStaff) {
      if (isPartialDoctor) {
        getPartialDoctorOTP();
      } else {
        getOTPAPICall();
      }
    } else {
      getStaffOTP();
    }
  };

  const checkIsStaffAPICall = () => {
    setShowRegisterButtonLoader(true);
    beforeLoginAxios
      .post("public/staff-send-otp", {
        // user_type: "doctor",
        channel_id: "staff-login",
        phone_no: `+91${phoneNumber}`,
        login_type: "password",
      })
      .then((response) => {
        if (response) {
          if (response.data && response.data.statusCode === 200) {
            if (response.data.password_set === 1) {
              setIsStaff(true);
              getStaffOTP();
            } else {
              history.replace("/doctor/login/staff/set-password");
            }
          } else if (response.data && response.data.statusCode == 505) {
            setShowRegisterButtonLoader(false);
            setShowErrorPopup(true);
          } else if (response.data && response.data.statusCode === 202) {
            setIsStaff(false);
            handleSubmitAPICall();
          } else if (response.data && response.data.statusCode === 510) {
            setShowRegisterButtonLoader(false);
            swal({
              title: "Invalid Phone Number Entered",
              type: "info",
            });
          } else {
            setShowRegisterButtonLoader(false);
            swal({
              title: "Something Went Wrong!",
              type: "info",
            });
          }
        } else {
          setShowRegisterButtonLoader(false);
        }
      })
      .catch(function (error) {
        C2CConsole.log("err", error);
      });
  };

  const getStaffOTP = () => {
    beforeLoginAxios
      .post("public/staff-send-otp", {
        // user_type: "doctor",
        channel_id: "staff-login",
        phone_no: `+91${phoneNumber}`,
      })
      .then((response) => {
        if (response) {
          if (response.data && response.data.statusCode === 200) {
            setShowOtpInput(true);
            setShowRegisterButtonLoader(false);
          } else {
            setShowRegisterButtonLoader(false);
            swal({
              title: "Something Went Wrong!",
              type: "info",
            });
          }
        } else {
          setShowRegisterButtonLoader(false);
        }
      })
      .catch(function (error) {
        C2CConsole.log("err", error);
      });
  };

  const handleStaffLoginAPICall = () => {
    setShowPasswordError(false);
    setOtpError(false);
    if (otpInput.length !== 6) {
      setOtpError(true);
      return;
    }
    if (password.length <= 5) {
      setPasswordError("Password must be at least 6 characters");
      setShowPasswordError(true);
      return;
    }

    setShowButtonLoader(true);
    beforeLoginAxios
      .post("auth/staff-login-rev1", {
        phone_no: `+91${phoneNumber}`,
        user_type: "staff",
        user_otp: otpInput,
        new_password: password,
      })
      .then((response) => {
        if (response) {
          if (response.data && response.data.statusCode === 200) {
            localStorage.setItem("id_token", response.data.user.id_token);
            localStorage.setItem(
              LOCALSTORAGEKEY.USER_ID,
              response.data.user.doctor_id
            );
            localStorage.setItem("user_type", response.data.user.user_type);
            localStorage.setItem(
              "user_name",
              response.data.user.staff_full_name
            );
            localStorage.setItem(
              LOCALSTORAGEKEY.STAFF_ID,
              response.data.user.staff_id
            );
            localStorage.setItem(
              "full_name",
              response.data.user.staff_full_name
            );
            localStorage.setItem("doctorPhone", `+91${phoneNumber}`);
            localStorage.setItem(
              "share_instant_consultation_link_allow",
              response.data.user.permission
                .share_instant_consultation_link_allow
            );
            localStorage.setItem(
              "add_edit_new_staff_name_and_number_allow",
              response.data.user.permission
                .add_edit_new_staff_name_and_number_allow
            );
            localStorage.setItem(
              "start_instant_consultation_allow",
              response.data.user.permission.start_instant_consultation_allow
            );
            localStorage.setItem(
              "upload_manual_prescriptions_allow",
              response.data.user.permission.upload_manual_prescriptions_allow
            );
            localStorage.setItem(
              "access_knowledge_center_allow",
              response.data.user.permission.access_knowledge_center_allow
            );
            localStorage.setItem(
              "download_offline_patients_allow",
              response.data.user.permission.download_offline_patients_allow
            );
            localStorage.setItem(
              "edit_prescriptions_allow",
              response.data.user.permission.edit_prescriptions_allow
            );
            localStorage.setItem(
              "join_webinar_allow",
              response.data.user.permission.join_webinar_allow
            );
            localStorage.setItem(
              "update_account_details_allow",
              response.data.user.permission.update_account_details_allow
            );
            localStorage.setItem(
              "update_profile_details_allow",
              response.data.user.permission.update_profile_details_allow
            );
            localStorage.setItem(
              "last_login_date",
              response.data.user.last_login_date
            );

            localStorage.setItem(
              "start_upcoming_consultation_allow",
              response.data.user.permission.start_upcoming_consultation_allow
            );
            localStorage.setItem(
              "register_for_upcoming_webinar_allow",
              response?.data?.user?.permission
                ?.register_for_upcoming_webinar_allow
            );
            localStorage.setItem(
              LOCALSTORAGEKEY.STAFF_CLINIC_ASSOCIATION,
              response?.data?.staff_clinic_association
            );

            localStorage.setItem(
              "staff_doctor_association",
              response?.data?.staff_doctor_association
            );
            setTimeout(() => {
              if (localStorage.getItem("id_token")) {
                if (response?.data?.staff_clinic_association === true) {
                  history.replace("/clinic/account/dashboard");
                } else {
                  history.replace("/doctor/account/dashboard");
                }
              }
            }, 100);
          } else {
            setShowButtonLoader(false);
            setOtpError(true);
          }
        } else {
          setShowButtonLoader(false);
        }
      });
  };

  const handleMobileInputChange = (value) => {
    setMobileNumber(value);
    setPhoneNumber(value);
  };

  const handleInputAction = () => {
    checkIsStaffAPICall();
  };

  const handlePasswordChange = (value, name) => {
    if (name === "confirm_password") {
      setConfirmPassword(value);
    } else {
      setPassword(value);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key !== "Backspace" && e.key !== "Delete") {
      if (e.key === "Enter" && mobileNumber?.length === 10) {
        handleInputAction();
      }
    }
  };
  const onKeyDownReConfirmPassword = (e) => {
    if (e.key !== "Backspace" && e.key !== "Delete") {
      if (
        e.key === "Enter" &&
        mobileNumber?.length === 10 &&
        otpInput?.length === 6 &&
        password &&
        confirmPassword
      ) {
        handleContinue();
      }
    }
  };

  // create UI ⇒

  const doctorResetPasswordMobileInputUI = () => {
    return (
      <div className="drpc-mobile-input-container">
        <PhoneNumberInputWithAction
          placeholder={"Enter Phone Number"}
          inputValue={mobileNumber}
          disabled={false}
          showRegisterButtonLoader={showRegisterButtonLoader}
          handleInputChange={handleMobileInputChange}
          handleInputAction={handleInputAction}
          handleKeyDown={handleKeyDown}
          autoFocus={true}
        />
      </div>
    );
  };

  const doctorResetPasswordOTPInputUI = () => {
    return (
      <div className="drpc-otp-container">
        <OTPInput
          autoFocus
          hasError={otpError}
          errorMessage={OTP_ERROR_MESSAGE}
          onChangeOTP={(otp) => setOtpInput(otp)}
          handleResendOTP={handleResendOTP}
        />
      </div>
    );
  };

  const doctorSetAndConfirmPasswordUI = () => {
    return (
      <div className="drpc-password-container">
        <PasswordInputWithHeader
          title="Set a new password"
          isMandatory={true}
          passwordInput={password}
          hasError={showPassowrdError}
          errorMessage={passwordError}
          handlePasswordInputChange={(value) =>
            handlePasswordChange(value, "new_password")
          }
          inputRef={confirmRef}
        />
        <PasswordInputWithHeader
          title="Confirm Password"
          isMandatory={true}
          passwordInput={confirmPassword}
          hasError={showConfirmPassowrdError}
          errorMessage={confirmPasswordError}
          handlePasswordInputChange={(value) =>
            handlePasswordChange(value, "confirm_password")
          }
          onKeyDown={onKeyDownReConfirmPassword}
        />
      </div>
    );
  };

  const doctorResetActionButton = () => {
    return (
      <div className="drpc-action-button">
        <ActionButton
          title="Continue"
          disabled={disableContinueButton}
          showButtonLoader={showButtonLoader}
          handleClick={handleContinue}
        />
      </div>
    );
  };

  const doctorResetPasswordUI = () => {
    return (
      <div>
        <SignInFormLayout
          heading={"Reset Password"}
          subheading={
            showOtpInput ? "Please Enter the OTP" : "Please Enter Number"
          }
        >
          {doctorResetPasswordMobileInputUI()}
          {showOtpInput && doctorResetPasswordOTPInputUI()}
          {showOtpInput && doctorSetAndConfirmPasswordUI()}
          {showOtpInput && doctorResetActionButton()}
        </SignInFormLayout>
      </div>
    );
  };
  return (
    <>
      {loader ? (
        <div className="loader-container">
          <Loader />
        </div>
      ) : (
        <div className="doctor-reset-password-container">
          {doctorResetPasswordUI()}
          <StaffLoginErrorModal
            show={showErrorPopup}
            onClose={() => {
              setShowErrorPopup(false);
            }}
          />
        </div>
      )}
    </>
  );
};
// export ⇒
export default DoctorResetPasswordContainer;
