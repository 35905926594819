import React, { useState, useEffect } from "react";
import VitalComponent from "../common/VitalComponent";
import { VITALS_LIST } from "../DashboardAppointmentsView/Constant";
import VitalDatePickerInput from "../common/VitalDatePickerInput";
const PatientVitals = ({
  patientGender,
  patient,
  handleVitalChange,
  vitals,
  handleUnitChange,
  moreVitalsBtnState,
  handleDateChange,
}) => {
  const [vitalList, setVitalsList] = useState(VITALS_LIST);
  useEffect(() => {
    let vitalsListDuplicate = [...vitalList];
    vitalsListDuplicate.forEach((item) => {
      if (item.unit === "") {
        item.unit = item.unitList[0];
      }
    });
    setVitalsList(vitalsListDuplicate);
  }, []);
  const renderPrimaryVitals = () => {
    return vitalList.slice(0, 4).map((vital, index) => {
      return (
        <div
          className="col-md-6 col-12 my-2 pr-1 pl-1 primary-vitals"
          key={index}
        >
          {renderVital(vital)}
        </div>
      );
    });
  };
  const onArrowClick = (item) => {
    let index = vitalList.findIndex((vi) => vi.id === item.id);
    let ind = vitalList[index].unitList.findIndex(
      (un) => un === vitalList[index].unit
    );
    if (vitalList[index].unitList[ind - 1]) {
      vitalList[index].unit = vitalList[index].unitList[ind - 1];
    } else {
      vitalList[index].unit =
        vitalList[index].unitList[vitalList[index].unitList.length - 1];
    }
    handleUnitChange(vitals[item.name] ? Number(vitals[item.name]) : "", item);
    setVitalsList([...vitalList]);
  };

  const renderMoreVitals = () => {
    return vitalList.slice(4, VITALS_LIST.length).map((vital, index) => {
      return (
        moreVitalsBtnState.showMoreVitals && (
          <div
            className="col-md-6 col-12 my-2 pr-1 pl-1 primary-vitals"
            key={index}
          >
            {vital.name == "LMP"
              ? patientGender == "Female" && renderVital(vital)
              : renderVital(vital)}
          </div>
        )
      );
    });
  };

  const renderVital = (vital) => {
    return vital?.name === "LMP" ? (
      <div className="vital-lmp">
        <VitalDatePickerInput
          placeholder="LMP"
          differentInputFormat={
            vitals[vital.name]
              ? vitals[vital.name] + " Days"
              : vital?.value
              ? vital.value + " Days"
              : ""
          }
          selected_date={""}
          maxDate={new Date()}
          allowBackspace={true}
          handleDateChange={(date) => handleDateChange(date, vital)}
        />
      </div>
    ) : (
      <VitalComponent
        item={vital}
        placeholder={vital.name}
        value={vitals[vital.name] ? vitals[vital.name] : vital.value}
        selectedUnit={
          vitals[`${vital.name}_unit`]
            ? vitals[`${vital.name}_unit`]
            : vital.unit
        }
        unitList={vital.unitList}
        allowDecimal={true}
        moreVitalsBtnState={moreVitalsBtnState}
        handleUpArrowClick={(item) => onArrowClick(item)}
        handleDownArrowClick={(item) => onArrowClick(item)}
        handleOnChange={(e, item) => handleVitalChange(e, item)}
      />
    );
  };
  const renderPatientVitals = () => {
    return (
      <div className="container-fluid">
        <div className="row">{renderPrimaryVitals()}</div>
        <div className="row ">{renderMoreVitals()}</div>
        <div className="row add-more-vitals my-3 pr-1 pl-1">
          {!moreVitalsBtnState.showMoreVitals && (
            <button
              className="add-more-vitals-btn"
              onClick={(e) => {
                e.preventDefault();
                moreVitalsBtnState.setShowMoreVitals(
                  !moreVitalsBtnState.showMoreVitals
                );
              }}
            >
              Add More Vitals
            </button>
          )}
        </div>
      </div>
    );
  };
  return <>{renderPatientVitals()}</>;
};

export default PatientVitals;
