export const GET_PAST_VITALS = "/api/v1/doctor/list-past-vitals";
export const GET_PATIENT_VITALS = "/api/v1/doctor/get-vitals";
export const ADD_EDIT_VITALS = "api/v1/doctor/add-edit-vitals";
export const GET_SAVED_TEMPLATES = "doctor/get-template-list";
export const GET_PAST_CONSULTATIONS = "doctor/get-prescription-previous-list";
export const GET_MANUAL_RX = "doctor/get-manual-prescription-rev1";
export const GET_PREVIOUS_PRESCRIPTION =
  "/doctor/get-prescription-previous-rev1";
export const GET_SHARED_E_PRESCRIPTIONS = "doctor/get-shared-e-prescriptions";
export const GET_SAVED_E_PRESCRIPTIONS =
  "/api/v1/doctor/get-saved-e-prescription";
export const GET_PATIENT_DETAILS = "api/v1/get-erx-patient-details";
export const UPDATE_PATIENT_DETAILS = "api/v1/update-erx-patient-details";
export const GET_MEDICAL_TERM_LIST = "/api/v1/search/medical_terms";
export const GET_MENSTRUAL_LIST = "/api/v1/search/menstrual_terms";
export const GET_LIFESTYLE_LIST = "/api/v1/search/lifestyle_terms";
export const GET_DIAGNOSIS_LIST = "/api/v1/search/diagnosis_terms";
export const UPLOAD_DOCUMENT = "api/v1/doctor/upload-document";
export const GET_PATIENT_DOCTOR_DOCUMENTS = "user/get-document-rev1";
export const DELETE_FILE = "/api/v1/delete-upload-file";
export const GET_PRESCRIPTION_DATA = "/api/v1/doctor/edit-prescription";
export const ADD_UPDATE_HISTORY_AND_COMPLAINTS = "/api/v1/update-prescription";
export const GET_ERX_TEMPLATE_DATA = "doctor/get-erx-template-data";
export const GET_PRESCRIPTION_ID = "api/v1/prescription";
export const GET_REPORT_LIST = "/api/v1/search/examination_terms";
export const GET_INVESTIGATION_SEARCH_TERMS =
  "/api/v1/search/master_investigation_terms";
export const GET_INSTRUCTION_SEARCH_TERMS =
  "/api/v1/search/master_instruction_terms";
export const GET_USER_SETTINGS = "/api/v1/clinic/get-user-settings";
export const UPDATE_USER_SETTINGS = "/api/v1/clinic/user_settings";
export const GET_DRUG_FOR_AUTOFILL = "doctor/search-drug-for-autofill";
export const ADD_FOLLOWUP_DATE = "/api/v1/appointments/followup_reminder";
export const ADD_EDIT_PRESCRIPTION = "/doctor/add-edit-e-prescription";
export const DELETE_DOCTOR_ADDED_DRUG = "/doctor/delete-search-item";
export const GET_VACCINE_CHART = "/api/v1/prescription";
export const ADD_UPDATE_VACCINE_CHART = "/api/v1/prescription/vaccinations";
export const APPROVE_AND_SHARE_ERX = "/api/v2/doctor/share-e-prescription";
export const PRINT_GROW_CHART = "/api/v1/user/prescription/growth_charts";
export const SAVE_DRUG_IN_DB = "/api/v1/doctor/knowledge_base/medications";
export const GET_PATIENT_HISTORY_SUGGESTIONS =
  "/api/v1/suggestions/medical_terms";
export const GET_INSTRUCTION_SUGGESTIONS =
  "/api/v1/suggestions/instruction_terms";
export const GET_DRUGS_SUGGESTIONS = "/api/v1/suggestions/drugs";
export const GET_DRUG_INSTRUCTION_LIST = "/api/v1/search/drug_instructions";
export const GET_PRESCRIPTION_FOR_PREVIEW = "/api/v1/preview/erx";
export const GET_PAST_MEDICAL_HISTORY_LIST =
  "/api/v1/search/past_medical_history";
export const GET_FAMILY_HISTORY_LIST = "/api/v1/search/family_history";
export const SAVE_CUSTOM_CHIEF_COMPLAINT = "/api/v1/create/chief_complaints";
export const SAVE_CUSTOM_PAST_MEDICAL_HISTORY =
  "/api/v1/create/past_medical_history";
export const SAVE_CUSTOM_FAMILY_HISTORY = "/api/v1/create/family_history";
export const SAVE_CUSTOM_MENSTRUAL_HISTORY = "/api/v1/create/menstrual_history";
export const SAVE_CUSTOM_LIFESTYLE_HISTORY = "/api/v1/create/lifestyle_history";
export const SAVE_CUSTOM_INSTRUCTION_AND_NOTES =
  "/api/v1/create/instructions_and_notes";
export const SAVE_DRUG_INSTRUCTION_IN_DB = "/api/v1/create/drug_instructions";
export const SAVE_TEMPLATE = "/doctor/api/v1/save_template";
export const GET_PRESCRIPTION_PREVIEW = "/doctor/v1/preview_settings";
export const GET_PRESCRIPTION_PDF = "/doctor/v2/prescription/pdf";
export const GET_PREVIEW_PDF = "/api/v1/save/preview-erx";
export const DELETE_SAVED_TEMPLATE = "/api/v1/doctor/delete_template";
export const GET_SECTION_TEMPLATE = "/api/v1/get-sectional-template";
export const CREATE_SECTION_TEMPLATE = "/api/v1/create_sectional_template";
export const DELETE_SECTION_TEMPLATE = "/api/v1/delete-sectional-template";
