import React from "react";
import UNCHECK_EMOJI_1 from "../../assets/FeedbackImages/1_un.svg";
import UNCHECK_EMOJI_2 from "../../assets/FeedbackImages/2_un.svg";
import UNCHECK_EMOJI_3 from "../../assets/FeedbackImages/3_un.svg";
import UNCHECK_EMOJI_4 from "../../assets/FeedbackImages/4_un.svg";
import UNCHECK_EMOJI_5 from "../../assets/FeedbackImages/5_un.svg";
import CHECK_EMOJI_1 from "../../assets/FeedbackImages/1_ch.svg";
import CHECK_EMOJI_2 from "../../assets/FeedbackImages/2_ch.svg";
import CHECK_EMOJI_3 from "../../assets/FeedbackImages/3_ch.svg";
import CHECK_EMOJI_4 from "../../assets/FeedbackImages/4_ch.svg";
import CHECK_EMOJI_5 from "../../assets/FeedbackImages/5_ch.svg";

const emojiRating = {
  1: "Bad",
  2: "Average",
  3: "Good",
  4: "Satisfied",
  5: "Wonderful",
};
export const ForSatisfactionRating = ({
  responseData,
  showSkipButton,
  handleSkipButton,
  noqusPassed,
  ques,
  index,
  setResponseData,
  setDisabledFlag,
  categoryIndex,
}) => {
  return (
    <div className="PF-defaultQus-bdy-div">
      <div>
        <p className="fnt-16">{ques.question}</p>
        <div className="PF-star-detail">
          {Object.keys(emojiRating).map((key) => {
            return (
              <div
                className="PF-star-img-div"
                key={key}
                onClick={(e) => {
                  ques.Ans = key;
                  setResponseData([...responseData]);
                  setDisabledFlag(true);
                  if (responseData[categoryIndex].open - 1 <= index) {
                    responseData[categoryIndex].open = responseData[categoryIndex]?.open + 1;
                  }
                }}>
                {key == 1 && (
                  <div>
                    <img src={ques.Ans == key ? CHECK_EMOJI_1 : UNCHECK_EMOJI_1} />
                    <div>{emojiRating[1]}</div>
                  </div>
                )}
                {key == 2 && (
                  <div>
                    {" "}
                    <img src={ques.Ans == key ? CHECK_EMOJI_2 : UNCHECK_EMOJI_2} /> <div>{emojiRating[2]}</div>{" "}
                  </div>
                )}
                {key == 3 && (
                  <div>
                    {" "}
                    <img src={ques.Ans == key ? CHECK_EMOJI_3 : UNCHECK_EMOJI_3} /> <div>{emojiRating[3]}</div>{" "}
                  </div>
                )}
                {key == 4 && (
                  <div>
                    {" "}
                    <img src={ques.Ans == key ? CHECK_EMOJI_4 : UNCHECK_EMOJI_4} /> <div>{emojiRating[4]}</div>{" "}
                  </div>
                )}
                {key == 5 && (
                  <div>
                    {" "}
                    <img src={ques.Ans == key ? CHECK_EMOJI_5 : UNCHECK_EMOJI_5} /> <div>{emojiRating[5]}</div>{" "}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      {responseData[categoryIndex].open  === index + 1 && index !== responseData[categoryIndex]?.questions.length-1  && showSkipButton && (
        <div>
          <button onClick={handleSkipButton} className="skip-btn">
            Skip
          </button>
        </div>
      )}
    </div>
  );
};
