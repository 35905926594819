import React, { Component } from "react";
import { Link } from "react-router-dom";
import afterLoginApiAxios from "../Util/API Calls/afterLogin";
import { patientFiles, doctorFiles, siteURL } from "../Util/API Calls/APIList";
import Loader from "./UI Component/Loader/Loader";
import swal from "sweetalert";
import DocumentContainer from "./UI Component/DocumentViewer/DocumentCotainer";
import ErxCell from "./ManualRX/ErxCell";
import axios from "axios";

const ref = React.createRef();

const options = {
  orientation: "portrait",
  unit: "px",
  format: [900, 1000],
};

class PrescriptionsPatient extends Component {
  constructor(props, context) {
    super(props, context);

    var id = this.props.match.params.id;
    var prescriptionID = this.props.location?.state?.prescription_id;
    this.state = {
      appID: id,
      prescription: null,
      upload_prescriptions: null,
      URL: patientFiles,
      DoctorURL: doctorFiles,
      loaderFlag: true,
      presMsg: "",
      prescriptionData: null,
      prescription_id: prescriptionID,
      manual_rx_names: [],
      sharedPrescriptions: [],
      fileName: "",
      fileType: "",
      documentShow: "",
      fileUrl: "",
      fullView: false,
    };
  }

  //using view-prescription-rev1 if we are coming from push notification else we are using view-prescription-rev2
  componentWillMount() {
    if (localStorage.getItem("id_token")) {
    } else {
      this.props.history.push("/patientslogin");
    }
    this.setState({
      loaderFlag: false,
    });
    this.getSharedPrescriptionsList();
  }

  async getSharedPrescriptionsList() {
    this.setState({
      loaderFlag: true,
    });
    await afterLoginApiAxios
      .post(
        "public/get-prescription-by-appointment-id-rev1",
        {
          appointment_id: this.state.appID,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then((response) => {
        this.setState({
          loaderFlag: false,
        });
        if (response?.data?.statuscode == 403) {
          this.props.history.push("/patient/login");
        }
        this.setState({
          upload_prescriptions: response?.data?.manual_rx_url,
          sharedPrescriptions: response?.data?.shared_rx_urls,
        });
      })
      .catch((err) =>
        swal({
          title: "Error Fetching Prescriptions",
          icon: "warning",
        })
      );
  }
  async downloadURI(url, name, fileType) {
    const blob = await axios.get(url, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
      responseType: "blob",
    });
    const a = document.createElement("a");
    const href = window.URL.createObjectURL(blob.data);
    a.href = href;
    a.download = name;
    a.click();
  }
  closeNotification() {
    this.setState({
      documentShow: false,
      fileUrl: "",
      fileName: "",
      fileType: "",
      fullView: false,
    });
  }

  render() {
    var styles = {
      cardOdd: {
        backgroundColor: "#eeae01",
        color: "#454545",
      },
      cardEven: {
        backgroundColor: "#454545",
        color: "#eeae01",
      },
    };

    return (
      <div>
        {this.state.loaderFlag ? <Loader /> : null}
        <div className="row pt-4" style={{ backgroundColor: "#fff" }}>
          <div className="col-sm-12 col-md-4 text-center">
            <img
              src={process.env.PUBLIC_URL + "/images/HN_logo.png"}
              style={{ width: "70%" }}
            />
          </div>
          <div className="col-sm-12 col-md-8 text-center pt-4"></div>
        </div>

        <div className="container">
          <div className="row mb-5">
            <div className="col-sm-12 col-lg-12 pt-5">
              <h2>
                <strong>
                  <b>
                    <Link to="/patient/account/dashboard">
                      <i
                        className="fa fa-chevron-circle-left mr-3"
                        style={{ color: "#17BAE0" }}
                      ></i>
                    </Link>
                    PATIENT PRESCRIPTION
                  </b>
                </strong>
              </h2>
            </div>
          </div>
          {this.state.upload_prescriptions &&
          this.state.upload_prescriptions?.length > 0 ? (
            <div className="row mb-5">
              <div
                className="col-sm-12 col-lg-12 p-3"
                style={{ backgroundColor: "#fff" }}
              >
                <h5 className="mb-2">Manual Prescription :</h5>
                {this.state.upload_prescriptions.map((upload, index) => {
                  let file = upload.manual_rx_url;
                  return (
                    <span
                      className="mr-2 mb-2 btn btn-info btn-sm downloadButton"
                      onClick={() =>
                        this.downloadURI(file, upload.document_name, "")
                      }
                    >
                      Download Prescription {index + 1}
                    </span>
                    // </a>
                  );
                })}
              </div>
            </div>
          ) : null}
          <div
            style={{
              padding: "30px",
              background:
                "transparent linear-gradient(123deg,#fdfdfd,#e0e0e0) 0 0 no-repeat padding-box",
            }}
          >
            {this.state.sharedPrescriptions?.length > 0 ? (
              <>
                <div>
                  <h6>
                    Shared E-Prescriptions (
                    {this.state.sharedPrescriptions?.length})
                  </h6>
                </div>
                <ErxCell
                  e_rx_list={this.state.sharedPrescriptions}
                  setFileName={(name) => {
                    this.setState({
                      fileName: name,
                    });
                  }}
                  setFileType={(type) => {
                    this.setState({
                      fileType: type,
                    });
                  }}
                  setFileUrl={(url) => {
                    this.setState({
                      fileUrl: url,
                    });
                  }}
                  setDocumentShow={(show) => {
                    this.setState({
                      documentShow: show,
                    });
                  }}
                  downloadURI={(url, name, type) =>
                    this.downloadURI(url, name, type)
                  }
                  saved={false}
                  icons={{
                    edit: false,
                    view: true,
                    download: true,
                    share: false,
                  }}
                  additionalStyles={{
                    border: "1px solid #d6dae5",
                    marginBottom: "5px",
                  }}
                />
              </>
            ) : (
              <h6>No Prescriptions Shared</h6>
            )}
          </div>

          <div>
            {this.state.documentShow && (
              <DocumentContainer
                cancelHandler={() => this.closeNotification()}
                fullViewHandler={() => {
                  this.setState({
                    fullView: !this.state.fullView,
                  });
                }}
                url={this.state.fileUrl}
                fileName={this.state.fileName}
                fileType={this.state.fileType}
              />
            )}
          </div>
        </div>

        <div
          className="modal fade"
          id="registerModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Register
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-12 text-left">
                    <label>Phone No.</label>
                    <input type="text" name="phone" className="w-100" />
                  </div>

                  <div className="col-sm-12">
                    <label>OTP</label>
                    <input type="text" name="otp" className="w-100" />
                  </div>

                  <div className="col-sm-4">
                    <Link
                      className="btn btn-sm mt-2"
                      style={{ color: "#454545", backgroundColor: "#eeae01" }}
                      data-toggle="modal"
                      data-target="#registerModal"
                    >
                      <b>Register</b>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrescriptionsPatient;
