import React from "react";
import PatientFeedbackHeader from "./PatientFeedbackHeader";
import "./PatientFeedback.scss";
import PatientDefaultQus from "./PatientDefaultQus";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";

const PatientFeedback = () => {
  return (
    <>
      <div style={{ height: "80vh" }}>
        {localStorage.getItem(LOCALSTORAGEKEY.ID_TOKEN) && (
          <PatientFeedbackHeader />
        )}

        <div className="patientFeedback-body">
          <PatientDefaultQus />
        </div>
      </div>
    </>
  );
};

export default PatientFeedback;
