import React from "react";
import { DatePicker } from "rsuite";
import { isAfter } from "date-fns";
import moment from "moment";
import Select from "react-select";
import MultipleFileSelector from "../UI Component/MultipleImageSelector/MultipleFileSelector";

function DetailsForm({
  patientState,
  setPatientState,
  terms,
  setTerms,
  isNamePresent,
  isDobPresent,
  formErrors,
}) {
  let statesList = [
    { label: "Andhra Pradesh", value: 37 },
    { label: "Arunachal Pradesh", value: 12 },
    { label: "Assam", value: 18 },
    { label: "Bihar", value: 10 },
    { label: "Chattisgarh", value: 22 },
    { label: "Delhi", value: 7 },
    { label: "Goa", value: 30 },
    { label: "Gujarat", value: 24 },
    { label: "Haryana", value: 6 },
    { label: "Himachal Pradesh", value: 2 },
    { label: "Jammu and Kashmir", value: 1 },
    { label: "Jharkhand", value: 20 },
    { label: "Karnataka", value: 29 },
    { label: "Kerala", value: 32 },
    { label: "Lakshadweep Islands", value: 31 },
    { label: "Madhya Pradesh", value: 23 },
    { label: "Maharashtra", value: 27 },
    { label: "Manipur", value: 14 },
    { label: "Meghalaya", value: 17 },
    { label: "Mizoram", value: 15 },
    { label: "Nagaland", value: 13 },
    { label: "Odisha", value: 21 },
    { label: "Pondicherry", value: 34 },
    { label: "Punjab", value: 3 },
    { label: "Rajasthan", value: 8 },
    { label: "Sikkim", value: 11 },
    { label: "Tamil Nadu", value: 33 },
    { label: "Telangana", value: 36 },
    { label: "Tripura", value: 16 },
    { label: "Uttar Pradesh", value: 9 },
    { label: "Uttarakhand", value: 5 },
    { label: "West Bengal", value: 19 },
    { label: "Andaman And Nicobar Islands", value: 35 },
    { label: "Chandigarh", value: 4 },
    { label: "Dadra and Nagar Haveli", value: 26 },
    { label: "Daman and Diu", value: 25 },
    { label: "Ladakh", value: 38 },
  ];

  let stateIndex = statesList.findIndex(
    (state) => state.value === patientState.selectedState
  );

  const handleOnChangeInput = (e, title) => {
    const emailRegex = RegExp(/[\w-]+@([\w-]+\.)+[\w-]+/);
    const pincodeRegex = RegExp(/^[1-9][0-9]{5}$/);
    if (title === "address" || title === "name" || title === "purpose") {
      if (e.target.value === "") {
        formErrors[title] = `${
          title.charAt(0).toUpperCase() + title.slice(1)
        } is Mandatory`;
      } else {
        formErrors[title] = "";
      }
    } else if (title === "email") {
      if (e.target.value !== "" && !emailRegex.test(e.target.value)) {
        formErrors[title] = "Invalid Email";
      } else {
        formErrors[title] = "";
      }
    } else if (title === "pincode") {
      if (e.target.value.length > 6) {
        e.target.value = e.target.value.slice(0, 6);
      }
      if (e.target.value === "") {
        formErrors[title] = "Pincode is Mandatory";
      } else if (
        !pincodeRegex.test(e.target.value) ||
        e.target.value.length !== 6
      ) {
        console.log(e.target.value);
        formErrors[title] = "Please Enter Valid Pincode";
      } else {
        formErrors[title] = "";
      }
    }
    setPatientState({ ...patientState, [title]: e.target.value });
  };

  const handleOnDateClear = () => {
    setPatientState({ ...patientState, dob: "" });
  };

  return (
    <div className="details-form">
      <div className="patient-details">Patient Details</div>
      <hr className="mt-3 mb-4" />
      <div className="details-field">
        <div className="row">
          <div className="col-sm-12 col-lg-6 online-patient-details">
            <label>
              Full Name<span className="color-pink">*</span>
            </label>

            {isNamePresent ? (
              <div>
                <p>{patientState.name}</p>
              </div>
            ) : (
              <div className="patientInput">
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="w-100 patient-inputs-form"
                  placeholder="Patient Name"
                  onChange={(e) => handleOnChangeInput(e, "name")}
                />
                <span style={{ color: "#F969A4" }}>{formErrors["name"]}</span>
              </div>
            )}
          </div>
          <div className="col-sm-12 col-lg-6 online-patient-details gap-for-mob">
            <label>Email</label>
            <div className="patientInput">
              <input
                type="text"
                name="email"
                id="email"
                value={patientState.email}
                placeholder="Patient Email ID"
                className="w-100 patient-inputs-form"
                onChange={(e) => handleOnChangeInput(e, "email")}
              />
              <span style={{ color: "#F969A4" }}>{formErrors["email"]}</span>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-12 col-lg-6 online-patient-details">
            <label>
              Date of Birth<span className="color-pink">*</span>
            </label>
            {isDobPresent ? (
              <div>
                <p>{moment(patientState.dob).format("DD MMM YYYY")}</p>
              </div>
            ) : (
              <div className="patient-detail-date-picker">
                {
                  <DatePicker
                    oneTap
                    onChangeCalendarDate={(date) =>
                      setPatientState({ ...patientState, dob: date })
                    }
                    onClean={handleOnDateClear}
                    disabledDate={(date) => isAfter(date, new Date())}
                    format={"DD/MM/YYYY"}
                    ranges={[]}
                  />
                }
                <br />
                <span style={{ color: "#F969A4" }}>{formErrors["dob"]}</span>
              </div>
            )}
          </div>
          <div className="col-sm-12 col-lg-6 online-patient-details gap-for-mob">
            <label>
              Gender<span className="color-pink">*</span>
            </label>

            <div className="online-patient-gender-options">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="sex"
                  id="gender_male"
                  checked={patientState.sex === "Male" ? true : false}
                  value="Male"
                  onChange={() =>
                    setPatientState({ ...patientState, sex: "Male" })
                  }
                />
                <label className="form-check-label ml-1" htmlFor="gender_male">
                  Male
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="sex"
                  id="gender_female"
                  checked={patientState.sex === "Female" ? true : false}
                  value="Female"
                  onChange={() =>
                    setPatientState({ ...patientState, sex: "Female" })
                  }
                />
                <label
                  className="form-check-label ml-1"
                  htmlFor="gender_female"
                >
                  Female
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="sex"
                  id="gender_intersex"
                  checked={patientState.sex === "Others" ? true : false}
                  value="Others"
                  onChange={(e) =>
                    setPatientState({ ...patientState, sex: "Others" })
                  }
                />
                <label className="form-check-label ml-1" for="gender_intersex">
                  Others
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-12 col-lg-12 online-patient-details">
            <label>
              ADDRESS<span className="color-pink">*</span>
            </label>
            <div>
              <input
                type="text"
                className="patient-inputs-form w-100"
                placeholder="Enter your Address"
                name="address"
                onChange={(e) => handleOnChangeInput(e, "address")}
                value={patientState.address}
              />
              <span style={{ color: "#F969A4" }}>{formErrors["address"]}</span>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-12 col-lg-6 online-patient-details">
            <div>
              <label>
                Pincode
                <span className="color-pink">*</span>
              </label>
              <input
                type="number"
                className="w-100 patient-inputs-form"
                placeholder="Enter your Pincode"
                name="pincode"
                id="pincode"
                maxLength="6"
                value={patientState.pincode}
                onKeyDown={(e) =>
                  (e.keyCode === 69 ||
                    e.keyCode === 187 ||
                    e.keyCode === 61 ||
                    e.keyCode === 190 ||
                    e.keyCode === 189) &&
                  e.preventDefault()
                }
                onChange={(e) => handleOnChangeInput(e, "pincode")}
              />
              <span style={{ color: "#F969A4" }}>{formErrors["pincode"]}</span>
            </div>
          </div>
          <div className="col-sm-12 col-lg-6 online-patient-details gap-for-mob">
            <div>
              <label>
                State
                <span className="color-pink">*</span>
              </label>
              <Select
                name="select_state"
                value={statesList[stateIndex]}
                onChange={(e) => {
                  setPatientState({ ...patientState, selectedState: e.value });
                }}
                options={statesList}
                styles={customStyles}
                placeholder="State"
              />

              <span style={{ color: "#F969A4" }}>
                {formErrors["selectedState"]}
              </span>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-12 col-lg-12 online-patient-details">
            <label>
              Purpose of Consultation
              <span className="color-pink">*</span>
            </label>
            <div>
              <input
                type="text"
                name="purpose"
                id="purpose"
                className="w-100 patient-inputs-form"
                value={patientState.purpose}
                onChange={(e) => handleOnChangeInput(e, "purpose")}
                placeholder="Describe your symptoms, concerns, and help required."
              />
              <span style={{ color: "#F969A4" }}>{formErrors["purpose"]}</span>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-12 col-lg-12 online-patient-details">
            <label>
              Upload any previous medical transcripts, test results, diagnostic
              reports and more.
            </label>

            <MultipleFileSelector
              showWarning
              maxFileSize={20}
              selectedImages={(imageArray) => {
                setPatientState({
                  ...patientState,
                  prev_prescription: imageArray,
                });
              }}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12 online-patient-details">
            <label>Is this for you or someone else?</label>
          </div>
          <div className="col-md-12">For self</div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12 font-weight-bold">
            NOTE : The doctor can ask patient for an in-person visit if required
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-12 col-lg-12 agreement-outer">
            <input
              className="form-check-input agreement-checkbox"
              type="checkbox"
              name="terms"
              id="terms"
              checked={terms}
              onChange={() => setTerms(!terms)}
            />
            <label className="form-check-label agreement-text" htmlFor="terms">
              I agree to Connect2Clinic's{" "}
              <a
                href="../terms.html"
                target="_blank"
                style={{ color: "#17BAE0" }}
              >
                Terms and Conditions.
              </a>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

const customStyles = {
  control: (base) => ({
    ...base,
    width: "100%",
    background: " #F5F6F7 0% 0% no-repeat padding-box",
    border: "1px solid #DBDBDB",
    borderRadius: "5px",
    height: "45px",
  }),
  container: (base) => ({
    ...base,
    width: "100%",
  }),
};

export default DetailsForm;
