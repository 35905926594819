import React, { Component, PureComponent } from "react";
import axios from "axios";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import ShowMoreText from "react-show-more-text";
import afterLoginApiAxiosPEM from "./PEMAfterLogin";
import closeIcon from "../../assets/close_icon.svg";
import { C2CConsole } from "../../Util/Helper/C2CConsole";

class DocumentViewer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      document: "",
      fileType: "",
      url: "",
      content: {},
    };
  }

  componentDidMount() {
    if (this.props.id) {
      this.fetchViewContentDetails(this.props.id);
    }
  }

  fetchViewContentDetails = async (id) => {
    const res = await afterLoginApiAxiosPEM({
      method: "get",
      url: "doctor/view-pem-content-detail",
      params: {
        article_id: id,
      },
    });

    if (res && res.data && res.data.response) {
      this.setState({
        content: res.data.response,
        url: res.data.response.media_presigned_view,
      });
      this.fetchDoc();
    }
  };

  fetchDoc = () => {
    axios({
      method: "get",
      url: this.state.url,
      responseType: "blob",
    }).then((response) => {
      var reader = new FileReader();
      reader.readAsDataURL(response.data);
      reader.onloadend = () => {
        var base64data = reader.result;
        this.setState({ document: base64data, url: response.config.url });
      };
    });
  };

  executeOnClick = (isExpanded) => {
    C2CConsole.log(isExpanded);
  };

  closeImgClick = () => {
    this.props.removeDocView(this.props.id);
  };
  // create UI ==>
  headerUI = () => {
    return (
      <div className="share-document-content">
        <div
          className="share-document-content-right"
          // onClick={() => setViewModal(true)}
        >
          <div className="share-document-content-title">
            {this.state.content.article_title}
            <img
              src={closeIcon}
              alt="cancel"
              className="share-content-upload-media-cancel"
              onClick={this.closeImgClick}
              style={{ position: "initial" }}
            />
          </div>
          <div>
            <ShowMoreText
              /* Default options */
              lines={2}
              more="Read more"
              less="Read less"
              className="share-document-content-description"
              anchorClass="my-anchor-css-class"
              onClick={this.executeOnClick}
              expanded={false}
              truncatedEndingComponent={"... "}
            >
              {this.state.content.article_description}
            </ShowMoreText>
          </div>
        </div>
      </div>
    );
  };

  docViewerUI = (docs) => {
    return (
      this.state.document && (
        <DocViewer
          documents={docs}
          pluginRenderers={DocViewerRenderers}
          style={{
            width: "100%",
          }}
          config={{
            header: {
              disableHeader: false,
              disableFileName: false,
              retainURLParams: false,
            },
          }}
        />
      )
    );
  };

  videoUI = () => {
    return (
      <video width="100%" height="100%" controls>
        <source src={this.state.url} type="video/mp4" />
      </video>
    );
  };

  audioUI = () => {
    return (
      <audio width="100%" height="100%" controls autoplay>
        <source src={this.state.url} type="audio/mpeg" />
      </audio>
    );
  };

  renderDocView = (docs) => {
    switch (this.state.content.media_file_type) {
      case "video/mp4":
        return this.videoUI();
      case "video/wav":
        return this.videoUI();
      case "audio/wav":
        return this.audioUI();
      case "audio/mpeg":
        return this.audioUI();
      default:
        return this.docViewerUI(docs);
    }
  };

  // <==

  render() {
    const docs = [
      {
        uri: `${this.state.document}`,
      },
    ];

    return (
      <div>
        {this.state.content ? (
          <>
            {this.headerUI()} {this.renderDocView(docs)}{" "}
          </>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default DocumentViewer;
