import React from "react";
import { Modal } from "@mui/material";
import TickIcon from "../../assets/check.png";
import "./rx.scss";

const ShareManualRxModal = (props) => {
  const { show, handleClose } = props;

  return (
    <div>
      {show && (
        <Modal open={show} onClose={handleClose}>
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="share-manual-rx-modal-wrapper">
              <div className="p-lg-5 p-2 text-center">
                <img src={TickIcon} alt="" />
                <h3 className="mt-2">Prescription Shared!</h3>
                <p className="rx_modal_content">
                  Your prescription has been shared with the patient.
                </p>
                <button
                  className="btn btn-sm primary-cta-button rounded-pill py-2 px-3 mt-2"
                  onClick={handleClose}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ShareManualRxModal;
