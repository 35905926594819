// dependencies imports ⇒
import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
// assets and scss imports ⇒
import C2cimage from "../../../assets/forhomepage/c2c_logo_new.png";
import appleIcon from "../../../assets/forhomepage/apple_logo_new.png";
import playstoreIcon from "../../../assets/forhomepage/playstore_logo_new.png";
import Call from "../../../assets/forhomepage/phone_logo_new.png";
import Mail from "../../../assets/forhomepage/email_logo_new.png";
import MenuNewHomepage from "../../../assets/forhomepage/menu-new-homepage.svg";
import "./homePageHeaderContainer.scss";
import DoctorInputSearch from "../../common/DoctorInputSearch/DoctorInputSearch";
import MyAccountAndLoginButton from "../../../components/HomepageNew/MyAccountAndLoginButton"; // project file imports(UI components) ⇒
import Sidebar from "./sideBar";
import { HOME_PAGE_CONTENT, COMPANIES_LINK } from "../constant";
import { DEV, QA } from "../../common/config";

const HomePageHeaderContainer = (props) => {
  const { handleScrollToContent, showDetails } = props;
  const location = useLocation();
  const isAbhaPath =
    process.env.REACT_APP_ENV === DEV ||
      process.env.REACT_APP_ENV === QA ||
      window.location.hostname === "localhost"
      ? location.pathname === "/abha"
      : false;
  //const ==>
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [serchContainer, setSerchContainer] = useState(false);
  const [isABHAMenuOpen, setIsABHAMenuOpen] = useState(false);
  const history = useHistory();

  //<==

  //method ==>
  const handleMenuToggle = () => {
    setIsABHAMenuOpen(!isABHAMenuOpen);
  };

  const showSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const closeSidebar = () => {
    setSidebarVisible(false);
  };

  const removeData = () => {
    setSerchContainer(false);
  };

  const handleLinks = (link) => {
    if (link?.isLink) {
      window.open(link?.navigateTo, "_self", "noopener,noreferrer");
    } else {
      if (window.location.pathname === "/") {
        handleScrollToContent(link?.navigateTo);
      } else {
        history.replace({
          pathname: "/",
          state: { scrollTo: link?.navigateTo },
        });
      }
    }
  };

  //<==
  //create UI ==>
  const createAppleLogoUI = () => {
    return (
      <div className="home_page_header_logoSpace">
        {" "}
        <a
          href={"https://apps.apple.com/in/app/connect2clinic/id1530572274"}
          target="_blank"
        >
          <img
            className="web-home-page-apple-play-logo"
            src={appleIcon}
            alt="#"
          />
        </a>
      </div>
    );
  };

  const createPlayStoreLogoUI = () => {
    return (
      <div className="home_page_header_logoSpace">
        {" "}
        <a
          href={
            "https://play.google.com/store/apps/details?id=com.connect2clinic.app"
          }
          target="_blank"
        >
          <img
            className="web-home-page-apple-play-logo"
            src={playstoreIcon}
            alt="#"
          />
        </a>
      </div>
    );
  };

  const createCallLogoUI = () => {
    return (
      <div className="home_page_header_logoSpace_call_email">
        {" "}
        <img src={Call} alt="#" />
      </div>
    );
  };

  const createMailLogoUI = () => {
    return (
      <div className="home_page_header_logoSpace_call_email">
        {" "}
        <img src={Mail} alt="#" />
      </div>
    );
  };

  const createDownlodUI = () => {
    return <div className="home_page_header_download_size">Download App</div>;
  };

  const createPhoneUI = () => {
    return (
      <div>
        <a href="tel:1800 267 9899" className="home_page_header_logo_size">
          {HOME_PAGE_CONTENT.C2C_PHONE_NO}
        </a>
      </div>
    );
  };

  const createMailUI = () => {
    return (
      <div>
        <a
          href="mailto:hello@connect2clinic.com"
          className="home_page_header_phone_size"
        >
          {HOME_PAGE_CONTENT.C2C_EMAIL}
        </a>
      </div>
    );
  };
  const handleButtonClick = () => {
    // Redirect to the "abha" link
    window.location.href = "/abha";
  };

  const handleABHAHoverEnter = (index) => {
    setIsABHAMenuOpen(true);
  };

  const handleABHAHoverLeave = () => {
    setIsABHAMenuOpen(false);
  };

  const createDetailsView = () => {
    return (
      <div className="home_page_header_details_view">
        <div
          className={
            COMPANIES_LINK[0].title === "About Us"
              ? "home_page_header_about_view"
              : "home_page_header_feature_view"
          }
        >
          {COMPANIES_LINK.map((link, index) => (
            <div
              key={index}
              onClick={() => handleLinks(link)}
              className="home_page_header_company_text"
            >
              {link.title === "ABDM" &&
                (process.env.REACT_APP_ENV === DEV ||
                  process.env.REACT_APP_ENV === QA ||
                  window.location.hostname === "localhost") && (
                  <div
                    onClick={handleMenuToggle}
                    onMouseEnter={() => handleABHAHoverEnter()}
                    onMouseLeave={() => handleABHAHoverLeave()}
                  >
                    <div style={{ width: "110px" }}>
                      <span>{link.title}</span>
                      <img
                        src={require("../../../assets/arrow-down.png")}
                        alt="Arrow"
                        className={
                          `arrow-img ${isABHAMenuOpen ? "open" : ""}` +
                          " arrow_icon_color"
                        }
                      />
                    </div>
                    {isABHAMenuOpen && (
                      <button
                        className="hoverable-button"
                        onClick={handleButtonClick}
                      >
                        Create ABHA
                      </button>
                    )}
                  </div>
                )}
              {link.title !== "ABDM" && (
                <div className="home_page_title_display">{link.title}</div>
              )}
            </div>
          ))}
        </div>

        <div
          className={`home_page_header_containt_doctor_search ${serchContainer ? "openSerchBox" : ""
            } `}
        >
          <DoctorInputSearch closeData={removeData} />
        </div>
        <div className="home_page_header_containt_login_view">
          <MyAccountAndLoginButton />
        </div>
      </div>
    );
  };

  const showMenuBar = () => {
    return (
      <div className="home_page_menu_bar_view">
        <img
          className="home_page_header_Menu_view"
          src={MenuNewHomepage}
          alt="#"
          onClick={showSidebar}
        />
        <Sidebar
          visible={sidebarVisible}
          onClose={closeSidebar}
          handleScrollToContent={handleScrollToContent}
        />
      </div>
    );
  };

  //<==

  return (
    <div className="home_page_header_content_main">
      <div className="home_page_header_View">
        {showDetails && showMenuBar()}
        {isAbhaPath ? (
          <a href="/">
            <img
              className="home_page_header_c2clog_view"
              src={C2cimage}
              alt=""
            />
          </a>
        ) : (
          <img className="home_page_header_c2clog_view" src={C2cimage} alt="" />
        )}
        {window.innerWidth < 819 && <MyAccountAndLoginButton />}
        <div className="home_page_Logo_view">
          {createAppleLogoUI()}
          {createPlayStoreLogoUI()}
          {createDownlodUI()}
          {createCallLogoUI()}
          {createPhoneUI()}
          {createMailLogoUI()}
          {createMailUI()}
        </div>
      </div>
      {showDetails && createDetailsView()}
    </div>
  );
};

export default HomePageHeaderContainer;
