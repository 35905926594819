import moment from "moment";
import React from "react";

export const groupSlotsByTime = (fetchedSlots, selectedDate, counter = 0) => {
  const morningLimit = moment("12:00 PM", "h:mm a");
  const afternoonLimit = moment("6:00 PM", "h:mm a");
  const eveningLimit = moment("11:59 PM", "h:mm a");
  const midnightLimit = moment("12:00 AM", "h:mm a");

  const tempMorningSlots = [];
  const tempAfternoonSlots = [];
  const tempEveningSlots = [];
  const tempMidnightSlots = [];

  fetchedSlots.forEach((slot) => {
    const slotStartTime = moment(slot.start_time, "h:mm a");
    const slotEndTime = moment(slot.end_time, "h:mma a");
    const difference = slotStartTime.diff(moment(), "m");

    if (
      selectedDate &&
      moment(selectedDate).format("DD-MM-YYYY") ===
        moment().format("DD-MM-YYYY")
    ) {
      // If less then 5 minutes left for slot end, it will be unavailable to book
      // It only applies to current Date and not on 12:00 AM
      if (difference < counter && slot.end_time !== "12:00 AM") return;
    }

    // Slot is not available if already booked
    if (slot.patient_status && selectedDate) {
      return;
    }

    if (
      slotStartTime.isBefore(morningLimit) &&
      slotStartTime.isAfter(moment("6:00 AM", "hh:mm A"))
    ) {
      tempMorningSlots.push(slot);
    } else if (
      slotStartTime.isBefore(afternoonLimit) &&
      slotStartTime.isAfter(morningLimit)
    ) {
      tempAfternoonSlots.push(slot);
    } else if (
      slotStartTime.isBefore(eveningLimit) &&
      (slotEndTime.isBefore(eveningLimit) ||
        slotEndTime.isSame(eveningLimit)) &&
      slotStartTime.isAfter(afternoonLimit)
    ) {
      tempEveningSlots.push(slot);
    } else if (
      slotStartTime.isBefore(moment("6:00 AM", "hh:mm A")) &&
      slotStartTime.isAfter(midnightLimit)
    ) {
      tempMidnightSlots.push(slot);
    } else {
      if (slotStartTime.isSame(morningLimit)) {
        tempAfternoonSlots.push(slot);
      } else if (slotStartTime.isSame(afternoonLimit)) {
        tempEveningSlots.push(slot);
      } else if (slotStartTime.isSame(moment("6:00 AM", "hh:mm A"))) {
        tempMorningSlots.push(slot);
      } else if (slotStartTime.isSame(midnightLimit)) {
        tempMidnightSlots.push(slot);
      }
    }
  });
  return {
    tempMorningSlots,
    tempAfternoonSlots,
    tempEveningSlots,
    tempMidnightSlots,
  };
};
export const getWalkInId = (arr) => {
  arr.forEach((slot, ind) => {
    if (slot.is_walkin) {
      slot.walkin_id = slot.id + "-" + ind;
    }
  });
  return arr;
};

export const checkOfffline = (
  selectedProcedure,
  procedureDetails,
  doctor,
  doctorDetails,
  setDisplayMessage,
  setSlotsNotAvailable,
  procedure_id,
  followUpInfo
) => {
  if (selectedProcedure && selectedProcedure.value) {
    if (
      localStorage.getItem("user_type") != "doctor" &&
      localStorage.getItem("user_type") != "staff"
    ) {
      if (procedureDetails && procedureDetails.length) {
        let selectedOne = procedureDetails.find(
          (data) => data.id == selectedProcedure.value
        );

        if (
          selectedOne &&
          selectedOne.consultation_method === "False" &&
          selectedOne.online_payment_enabled === "False"
        ) { 
          setDisplayMessage("offline_payment");
          setSlotsNotAvailable(true);
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  } else {
    if (
      localStorage.getItem("user_type") != "doctor" &&
      localStorage.getItem("user_type") != "staff"
    ) {
      if (procedureDetails && procedureDetails.length) {
        let selectedOne = procedureDetails.find(
          (data) => data.id == procedure_id
        );

        if (followUpInfo?.rescheduling_available) {
          if (
            selectedOne &&
            selectedOne.consultation_method === "False" &&
            selectedOne.online_payment_enabled === "False"
          ) {
           
            setDisplayMessage("offline_payment");
            setSlotsNotAvailable(true);
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
};

export const getMessage = (alt, clinicId) => {
  if (alt && !clinicId) {
    return (
      <span>
        For any assistance related to appointment booking please contact clinic
        on{" "}
        <strong style={{ color: "#1B2027" }}>
          {alt} .
          {/* {doctor ? doctor.phone_no : doctorDetails.phone_no}{" "} */}
        </strong>{" "}
      </span>
    );
  } else if (alt && clinicId) {
    return (
      <span>
        For any assistance related to appointment booking please contact clinic
        on{" "}
        <strong style={{ color: "#1B2027" }}>
          {alt} .
          {/* {doctor ? doctor.phone_no : doctorDetails.phone_no}{" "} */}
        </strong>{" "}
      </span>
    );
  } else {
    return (
      <span>
        For any assistance related to appointment booking please contact clinic.
      </span>
    );
  }
};
