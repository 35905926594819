import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import { createMuiTheme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";
import "../styles/SearchedQuery.scss";
import { StyledInputBase } from "./StyledInputBase";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Query from "./Query";

const theme = createMuiTheme({
  paper: {
    border: "100px solid red",
  },
  spacing: 4,
});

const styles = {
  paper: {
    border: "100px solid red",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },

  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  input: {
    color: "red",
  },
  popper: {
    zIndex: 1200,
    marginTop: "13px",
    position: "absolute",
  },
};

class SearchedQuery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      open: false,
      text: "",
      data: [],
      editSearch: "",
      cursor: 0,
    };
    this.drawerRef = React.createRef();
  }

  url = new URL("https://javascript.info/profile/admin");

  searchFilterFunction = () => (event) => {
    var main = event.currentTarget;
    var textValue = event.target.value;
    var newData = this.queryHolder.filter((item) => {
      var itemData = `${item.question.toUpperCase()}  
           `;
      var textData = event.target.value.toUpperCase();
      return itemData.indexOf(textData) > -1;
    });
    this.setState({ data: newData }, () => {
      if (this.state.data.length > 0) {
        this.setState({ anchorEl: main });
        this.setState({ open: true });
      }
      if (this.state.data.length === 0) {
        this.setState({
          data: [
            {
              question: `No match found. Please try to rephrase your query or reach out to our `,
            },
          ],
        });
      }

      if (textValue.length === 0) {
        this.setState({ open: false });
        this.setState({ anchorEl: null });
        this.closeSearchDialogue();
      }
    });
  };

  handleKeyDown = (e, dataBy) => {
    if (e.keyCode === 38 && this.state.cursor > 0) {
      this.setState({
        cursor: this.state.cursor - 1,
      });
    } else if (e.keyCode === 40 && this.state.cursor < dataBy.length - 1) {
      this.setState({
        cursor: this.state.cursor + 1,
      });
    }
  };

  makeCollapse = (name, ans) => {
    this.closeSearchDialogue();
    this.props.run(name, ans);
  };

  closeSearchDialogue = () => {
    this.setState({ data: [] });
  };

  render() {
    const { classes } = this.props;

    this.queryHolder = this.props.temporaryData;

    return (
      <div style={{ width: "100%" }}>
        <StyledInputBase
          onChange={this.searchFilterFunction("bottom-start").bind(this)}
          placeholder="Search Your Query"
          onKeyDown={
            this.state.data !== undefined
              ? (e) => this.handleKeyDown(e, this.state.data, this.state.cursor)
              : ""
          }
        />

        <ClickAwayListener
          onClickAway={() => {
            this.closeSearchDialogue();
          }}
        >
          <div className={classes.popper}>
            <Paper className="root" style={{ height: "auto", width: "100%" }}>
              <div>
                {this.state.data.map((query, index) => (
                  <Query
                    makeCollapse={this.makeCollapse}
                    query={query}
                    key={index}
                    run={this.props.run}
                    className={
                      this.state.cursor == index ? "keypress-active" : null
                    }
                  />
                ))}
              </div>
            </Paper>
          </div>
        </ClickAwayListener>
      </div>
    );
  }
}

export default withStyles(styles)(SearchedQuery);
