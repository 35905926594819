import React from "react";
import { View, Font, Text, Image, StyleSheet } from "@react-pdf/renderer";
import leftArrow from "../../../../assets/right_arrow.png";
import {
  PDF_DIAGNOSIS,
  PDF_REPORT_AND_EXAM,
  PDF_VACCINE_HEADERS,
} from "../ERXPdfConstants/constants";
import {
  breakLongWords,
  capitalizeEachWord,
  capitalizeFirstLetter,
} from "../ERXPdfHelper/helper";
import ErxPdfMainContentTable from "./ERXPdfMainContentTable";
import ERXPdfMainContentList from "./ERXPdfMainContentList";
import moment from "moment";
import {
  PDF_BLACK,
  PDF_GREY_BG_COLOR,
  PDF_GREY_ROW_COLOR,
  PDF_WHITE_ROW_COLOR,
} from "../ERXPdfConstants/colorConstant";

// 595/1052 = 0.565 for 72 PPI so 16 = 16*0.565 = 9.04 = 9

const ERXPdfMainContentView = (props) => {
  const { erxPdfData, pdfFontFamily, pdfMainContent } = props;
  const styles = StyleSheet.create({
    pdLabel: {
      textAlign: "left",
      fontSize: "8px",
      color: "#1B2027",
      marginBottom: "3px",
      fontFamily: pdfFontFamily,
      lineHeight: "1px",
    },
    pdValue: {
      textAlign: "left",
      fontSize: "9px",
      color: "#000000",
      fontFamily: pdfFontFamily,
      fontWeight: "700",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      marginBottom: "2px",
    },
    pdSubTitle: {
      textAlign: "left",
      fontSize: "7px",
      color: "#1B2027",
      fontFamily: pdfFontFamily,
      marginBottom: "5px",
    },
    topHeaderHr: {
      borderTop: "0.7px solid #dae0e2",
      borderBottom: "none",
      marginBottom: 0,
      marginTop: 0,
    },
    content_heading: {
      textDecoration: "underline",
      fontFamily: pdfFontFamily,
      textTransform: "uppercase",
      marginTop: "5px",
      marginBottom: "5px",
      color: "#000",
      fontSize: "9px", // 16 *.565 = 9.04 ~ 9
      fontWeight: "600",
    },
    category_heading_right: {
      fontFamily: pdfFontFamily,
      textTransform: "capitalize",
      color: "#000",
      fontSize: "8px", // 16 *.565 = 9.04 ~ 9
      fontWeight: "600",
      width: "21%",
    },
    category_heading_left: {
      fontFamily: pdfFontFamily,
      textTransform: "capitalize",
      marginLeft: "2px",
      color: "#000",
      fontSize: "8px", // 16 *.565 = 9.04 ~ 9
      fontWeight: "600",
      width: "100%",
    },
    category_arrow: {
      height: "5px",
      marginTop: "3px",
      marginRight: "2px",
    },
    category_text: {
      wrap: false,
    },
    category_text_arrow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "start",
    },

    content_text: {
      fontFamily: pdfFontFamily,
      fontWeight: "normal",
      fontSize: "8px", // 14 *.565 = 7.91 ~ 8
      color: "#000",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      textTransform: "capitalize",
      marginBottom: "5px",
    },

    // diagnosis and reports
    darName: {
      fontSize: "9px",
      textDecoration: "underline",
      fontWeight: "600",
      fontFamily: pdfFontFamily,
      paddingBottom: "5px", //10 * 0.565 = 5.65 ~ 5
    },
    darWrapper: {
      width: "auto",
      display: "flex",
      paddingTop: "5px", //10 * 0.565 = 5.65 ~ 5
    },
    darContentWrapper: {
      display: "flex",
      flexDirection: "row",
    },
    darContent: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: "5px", //10 * 0.565 = 5.65 ~ 5
      paddingBottom: "8px", //15 * 0.565 = 8.45 ~ 8
    },
    darId: {
      fontFamily: pdfFontFamily,
      fontSize: "8px", //14 * 0.565 = 7.91 ~ 8
      color: "#000",
      fontWeight: "600",
    },
    darTitle: {
      fontFamily: pdfFontFamily,
      fontSize: "8px", //14 * 0.565 = 7.91 ~ 8
      color: "#000",
      fontWeight: "600",
      textTransform: "capitalize",
    },
    darSubtitle: {
      paddingTop: "3px", // 5 * .565 = 2.82 ~ 3
      fontFamily: pdfFontFamily,
      fontSize: "8px", //14 * 0.565 = 7.91 ~ 8
      color: "#000",
      fontWeight: "normal",
      wordWrap: "break-word",
      maxWidth: "374px",
      textTransform: "capitalize",
    },
    custom_subheading: {
      fontSize: "8px", // 14 *.565 = 7.91 ~ 8
      color: "#000",
      fontWeight: "600",
    },
    graph_container: {
      display: "flex",
      flexDirection: "row",
    },
    pdfDoctorDetails: {
      display: "flex",
      flexDirection: "row",
      padding: "6px 11px 11px 11px",
    },
    pdfDoctorDetailsContent: {
      width: "20%",
    },
    pdfPatientDetails: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      padding: "11px",
    },
    bullet: {
      marginTop: "5px",
      marginRight: "4px",
      width: "3px",
      height: "3px",
      borderRadius: "50%",
      backgroundColor: "black",
    },
    drug_headerRow: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      backgroundColor: PDF_GREY_BG_COLOR,
    },
    drug_tableHeader: {
      fontFamily: pdfFontFamily,
      fontWeight: "600",
      padding: "5px", // 14 *.565 = 7.91 ~ 8
      color: PDF_BLACK,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      fontSize: "8px", // 14 *.565 = 7.91 ~ 8
      // minHeight: "21px", // 37 *.565 = 20.09 ~ 21
    },
    drug_tableCol: (width) => ({
      width: `${width}%`,
      borderStyle: "solid",
      borderWidth: 0,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      // padding: "5px",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      flexWrap: "wrap",
      // wordWrap: "break-word",
    }),
    drug_content: {
      fontFamily: pdfFontFamily,
      fontWeight: "normal",
      fontSize: "8px", // 14 *.565 = 7.91 ~ 8
      color: PDF_BLACK,
    },
    drug_tableCell: {
      padding: "5px", // 14 *.565 = 7.91 ~ 8
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      // wordWrap: "break-word",
      // minHeight: "21px", // 37 *.565 = 20.09 ~ 21
    },
    drug_tableRow: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    drug_evenRow: {
      backgroundColor: PDF_GREY_ROW_COLOR,
    },
    drug_oddRow: {
      backgroundColor: PDF_WHITE_ROW_COLOR,
    },
  });

  const getPdfContentMarginLeft = () => {
    let marginLeft = "0";
    let customMargins =
      erxPdfData?.doctor_profile_details?.custom_margin_dimensions;
    if (erxPdfData?.doctor_profile_details?.custom_margins_enabled) {
      marginLeft = (+customMargins?.rxLeft).toString() + customMargins?.unit;
    }

    return marginLeft;
  };

  const getPdfContentMarginRight = () => {
    let marginRight = "0";
    let customMargins =
      erxPdfData?.doctor_profile_details?.custom_margin_dimensions;
    if (erxPdfData?.doctor_profile_details?.custom_margins_enabled) {
      marginRight = (+customMargins?.rxRight).toString() + customMargins?.unit;
    }

    return marginRight;
  };

  // <===

  const hyphenationCallback = (word) => {
    return [word];
  };

  Font.registerHyphenationCallback(hyphenationCallback);

  const renderBulletIcon = () => {
    return (
      <View>
        <Text
          style={{
            marginRight: 4,
            fontSize: "8px",
            lineHeight: "0px",
            fontWeight: "700",
          }}
        >
          •
        </Text>
      </View>
    );
  };

  const renderDiagnosisAndReports = (name, data) => {
    return (
      <View style={styles.darWrapper}>
        <Text style={styles.darName}>{name}</Text>
        {data.map((obj, ind) => (
          <View key={ind} style={styles.darContentWrapper}>
            <View style={styles.darId}>
              <Text>{ind + 1}.</Text>
            </View>
            <View style={styles.darContent}>
              <Text style={styles.darTitle}>
                {obj.title?.replace(/[\r\n]/g, " ")}
              </Text>
              <Text style={styles.darSubtitle}>{obj.description}</Text>
            </View>
          </View>
        ))}
      </View>
    );
  };

  const vitalsUI = (vitalsData, alignment) => {
    return (
      <View>
        <Text style={styles.content_heading}>Vitals</Text>
        <View
          style={{
            display: "flex",
            flexDirection: alignment === "right" ? "row" : "column",
            flexWrap: alignment === "right" ? "wrap" : "nowrap",
          }}
        >
          {vitalsData?.map((data, ind) => (
            <View
              key={ind}
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: "5px",
              }}
            >
              {renderBulletIcon()}
              <Text style={[styles.content_text, { textTransform: "none" }]}>
                {`${data.key}: ${data.value} ${data.unit}`}
              </Text>
            </View>
          ))}
        </View>
      </View>
    );
  };

  const followUpUI = (followUpDate) => {
    return (
      <View>
        <Text style={styles.content_heading}>Follow Up</Text>
        <View>
          <Text style={styles.content_text}>
            {moment(followUpDate).format("DD MMM YYYY")}
          </Text>
        </View>
      </View>
    );
  };

  const obstetricHistoryUI = (obstetricHistory, alignment) => {
    return (
      <View>
        <Text style={styles.content_heading}>Obstetric History</Text>
        <View
          style={{
            display: "flex",
            flexDirection: alignment === "right" ? "row" : "column",
            flexWrap: "wrap",
          }}
        >
          {obstetricHistory?.map((data, ind) => (
            <View
              key={ind}
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: "5px",
              }}
            >
              {renderBulletIcon()}
              <Text style={styles.content_text}>
                {data?.title}:{" "}
                <Text style={[styles.content_text]}>
                  {breakLongWords(data?.value, 20)}
                </Text>{" "}
              </Text>
            </View>
          ))}
        </View>
      </View>
    );
  };

  const obstetricCalculatorUI = (obstetricDetails) => {
    return (
      <View>
        <Text style={styles.content_heading}>Obstetric Calculator</Text>
        {obstetricDetails?.map((data, ind) => (
          <View
            key={ind}
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              maxWidth: "90%",
            }}
          >
            {renderBulletIcon()}
            <Text style={styles.content_text}>{data?.title}: </Text>
            <Text
              style={[
                styles.content_text,
                {
                  flex: 1,
                  flexShrink: 1,
                },
              ]}
            >
              {breakLongWords(data?.value, 20)}
            </Text>
          </View>
        ))}
      </View>
    );
  };

  const customSectionUI = (customValue) => {
    return (
      <View>
        {customValue?.map((c_value, ind) => (
          <View key={ind}>
            <Text style={styles.content_heading}>{c_value?.custom_title}</Text>
            <View>
              {c_value?.fields?.length > 0 &&
                c_value?.fields?.map((fieldsVal, index1) => (
                  <View key={index1}>
                    {fieldsVal?.field_title !== "" && (
                      <Text style={styles.custom_subheading}>
                        {fieldsVal?.field_title}
                      </Text>
                    )}
                    {fieldsVal?.value?.length > 0 &&
                      fieldsVal?.value?.map((val, index) => (
                        <View
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          {renderBulletIcon()}
                          <Text style={styles.content_text}>
                            {val?.title?.replace(/[\r\n]/g, " ")}
                          </Text>
                        </View>
                      ))}
                  </View>
                ))}
            </View>
          </View>
        ))}
      </View>
    );
  };
  const patientHistoryUI = (patientHistoryList, alignment) => {
    return (
      <View>
        <Text style={styles.content_heading}>Patient history</Text>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: alignment === "right" ? "wrap" : "nowrap",
          }}
        >
          {patientHistoryList?.length > 0 &&
            patientHistoryList?.map((category, index) => (
              <View
                key={index}
                style={{
                  display: "flex",
                  flexDirection: alignment === "right" ? "row" : "column",
                }}
              >
                <View
                  style={
                    alignment === "right"
                      ? styles.category_heading_right
                      : styles.category_heading_left
                  }
                >
                  <View style={styles.category_text_arrow}>
                    <Image
                      style={styles.category_arrow}
                      src={leftArrow}
                    ></Image>
                    <Text style={styles.category_text}>{category.key} </Text>
                  </View>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: alignment === "right" ? "row" : "column",
                    flexWrap: alignment === "right" ? "wrap" : "nowrap",
                    width: alignment === "right" ? "79%" : "100%",
                  }}
                >
                  {category.value?.length > 0
                    ? category.value?.map((item, idx) => (
                        <View
                          key={idx}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            marginLeft: alignment === "right" ? "5px" : "12px",
                          }}
                        >
                          {renderBulletIcon()}
                          <Text style={styles.content_text}>
                            {alignment === "right"
                              ? item.title?.replace(/[\r\n]/g, " ")
                              : breakLongWords(
                                  item.title?.replace(/[\r\n]/g, " "),
                                  26
                                )}
                          </Text>
                        </View>
                      ))
                    : ""}
                </View>
              </View>
            ))}
        </View>
      </View>
    );
  };

  const growthChartUI = (growthChartData) => {
    return (
      <View>
        <View style={styles?.graph_container}>
          {growthChartData?.graph1?.length > 0 &&
            growthChartData?.graph1?.map((data, ind) => (
              <View key={ind}>
                <Image
                  src={data?.key}
                  style={{
                    width: "100px",
                    height: "100px",
                  }}
                />
              </View>
            ))}
        </View>
        <View style={styles?.graph_container}>
          {growthChartData?.graph2?.length > 0 &&
            growthChartData?.graph2?.map((data, ind) => (
              <View key={ind}>
                <Image
                  src={data?.key}
                  style={{ width: "100px", height: "100px" }}
                />
              </View>
            ))}
        </View>
        <View style={styles?.graph_container}>
          {growthChartData?.graph3?.length > 0 &&
            growthChartData?.graph3?.map((data, ind) => (
              <View key={ind}>
                <Image
                  src={data?.key}
                  style={{ width: "100px", height: "100px" }}
                />
              </View>
            ))}
        </View>
      </View>
    );
  };

  const displayDoctorDetails = () => {
    return (
      <View>
        <View style={styles.pdfDoctorDetails}>
          <View style={styles.pdfDoctorDetailsContent}>
            <Text style={styles.pdLabel}>Doctor Name</Text>
            <Text style={[styles.pdValue, { paddingRight: "11px" }]}>
              {pdfMainContent?.doctorFullName}
            </Text>
          </View>
          <View style={styles.pdfDoctorDetailsContent}>
            <Text style={styles.pdLabel}>Reg No</Text>
            <Text style={styles.pdValue}>
              {pdfMainContent?.doctor_profile_details?.medical_reg_no}
            </Text>
          </View>
          <View style={styles.pdfDoctorDetailsContent}>
            <Text style={styles.pdLabel}>Degree</Text>
            <Text style={styles.pdValue}>
              {pdfMainContent?.doctor_profile_details?.degree}
            </Text>
          </View>
          <View style={styles.pdfDoctorDetailsContent}>
            <Text style={styles.pdLabel}>Clinic Contact No.</Text>
            <Text style={styles.pdValue}>
              {
                pdfMainContent?.doctor_profile_details
                  ?.doctor_registered_mobile_number
              }
            </Text>
          </View>
        </View>
        <View style={styles.topHeaderHr}></View>
      </View>
    );
  };

  const displayPatientDetails = () => {
    let patientName = capitalizeEachWord(pdfMainContent.patient_name);
    return (
      <View>
        <View
          style={[
            styles.pdfPatientDetails,
            {
              paddingTop: pdfMainContent?.showDoctorDetails ? "11px" : "6px",
            },
          ]}
        >
          <View style={styles.pdfDoctorDetailsContent}>
            <Text style={styles.pdLabel}>Name of Patient</Text>
            <Text style={[styles.pdValue, { paddingRight: "11px" }]}>
              {patientName}
            </Text>
            <Text style={styles.pdSubTitle}>
              {pdfMainContent?.patient_phone_no}
            </Text>
          </View>
          <View style={styles.pdfDoctorDetailsContent}>
            <Text style={styles.pdLabel}>Age & Gender</Text>
            <Text style={styles.pdValue}>
              {pdfMainContent?.display_age} / {pdfMainContent?.patient_gender}
            </Text>
          </View>
          {pdfMainContent?.formatted_uhid &&
            pdfMainContent?.formatted_uhid !== "" && (
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "20%",
                }}
              >
                <Text style={styles.pdLabel}>Patient UHID</Text>
                <View style={{ maxWidth: "90%" }}>
                  <Text
                    style={[
                      styles.pdValue,
                      {
                        flex: 1,
                        flexShrink: 1,
                      },
                    ]}
                  >
                    {breakLongWords(pdfMainContent?.formatted_uhid, 17)}
                  </Text>
                </View>
              </View>
            )}
          <View style={styles.pdfDoctorDetailsContent}>
            <Text style={styles.pdLabel}>Date of Consultation</Text>
            <Text style={styles.pdValue}>
              {pdfMainContent?.appointment_date}
            </Text>
          </View>
          {pdfMainContent?.parent_guardian_name &&
            pdfMainContent?.parent_guardian_name !== "" && (
              <View style={styles.pdfDoctorDetailsContent}>
                <Text style={styles.pdLabel}>Parent/Guardian Details</Text>
                <Text style={styles.pdValue}>
                  {pdfMainContent?.relation}
                  {pdfMainContent?.parent_guardian_name}
                </Text>
              </View>
            )}
          {pdfMainContent?.abha_address !== "undefined" &&
            pdfMainContent?.abha_address !== "" && (
              <View style={styles.pdfDoctorDetailsContent}>
                <Text style={styles.pdLabel}>ABHA Address</Text>
                <Text style={styles.pdValue}>
                  {pdfMainContent?.abha_address}
                </Text>
              </View>
            )}
          {pdfMainContent?.abha_number !== "undefined" &&
            pdfMainContent?.abha_number !== "" && (
              <View style={styles.pdfDoctorDetailsContent}>
                <Text style={styles.pdLabel}>ABHA Number</Text>
                <Text style={styles.pdValue}>
                  {pdfMainContent?.abha_number}
                </Text>
              </View>
            )}
        </View>
        <View style={styles.topHeaderHr}></View>
      </View>
    );
  };

  const vaccineChartUI = (vaccineData, vaccineNotes) => {
    return (
      <View>
        {vaccineData?.length > 0 && (
          <ErxPdfMainContentTable
            id={"vaccine_id"}
            title={"Vaccine"}
            data={vaccineData}
            tableHeaders={PDF_VACCINE_HEADERS}
            pdfFontFamily={pdfFontFamily}
          />
        )}
        {vaccineNotes?.length > 0 && (
          <Text style={styles.content_text}>Note: {vaccineNotes}</Text>
        )}
      </View>
    );
  };

  const drugUI = (header, value) => {
    return (
      <View>
        <Text style={styles.content_heading}>{"Drugs"}</Text>
        <View style={styles.drug_headerRow}>
          <View style={styles.drug_tableCol(5)}>
            <Text style={styles.drug_tableHeader}>#</Text>
          </View>
          {header?.map((headerData, index) => (
            <View
              key={`${headerData.title}${headerData.id}`}
              style={styles.drug_tableCol(headerData.width)}
            >
              <Text style={styles.drug_tableHeader}>{headerData.title}</Text>
            </View>
          ))}
        </View>
        {value?.length > 0 &&
          value?.map((data, index) => (
            <View
              key={index}
              style={[
                styles.drug_tableRow,
                index % 2 === 0 ? styles.drug_evenRow : styles.drug_oddRow,
              ]}
              wrap={false}
            >
              <View style={[styles.drug_tableCol(5), styles.drug_tableCell]}>
                <Text style={styles.drug_content}>{index + 1}</Text>
              </View>
              <View style={[styles.drug_tableCol(25), styles.drug_tableCell]}>
                <Text style={[styles.drug_content, { fontWeight: "700" }]}>
                  {capitalizeFirstLetter(
                    breakLongWords(data?.drug_name ?? "", 14)
                  )}
                </Text>
                <Text style={[styles.drug_content, { fontSize: "6px" }]}>
                  {breakLongWords(data?.salt_composition ?? "", 20)}
                </Text>
              </View>
              <View
                style={[
                  styles.drug_tableCol(30),
                  styles.drug_tableCell,
                  {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  },
                ]}
              >
                <View
                  style={{
                    width: "40%",
                    maxWidth: "40%",
                    wordWrap: "break-word",
                  }}
                >
                  {data?.quantity || data?.unit ? (
                    <View>
                      <Text
                        style={[
                          styles.drug_content,
                          {
                            fontFamily: "notosans",
                            display: "flex",
                            flexDirection: "row",
                            wordWrap: "break-word",
                          },
                        ]}
                      >
                        {data?.quantity ?? ""} {data?.unit ?? ""}
                      </Text>
                    </View>
                  ) : (
                    <Text>{""}</Text>
                  )}
                  <View>
                    <Text style={styles.drug_content}>
                      {data?.frequency_extended?.title
                        ? `(${data?.frequency_extended?.title})`
                        : ""}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    paddingLeft: "5px",
                    wordWrap: "break-word",
                    width: "50%",
                  }}
                >
                  <Text style={styles.drug_content}>
                    {data?.frequency_extended?.details ?? ""}
                  </Text>
                </View>
              </View>
              <View style={[styles.drug_tableCol(15), styles.drug_tableCell]}>
                <Text style={styles.drug_content}>{data?.duration ?? ""}</Text>
              </View>
              <View style={[styles.drug_tableCol(25), styles.drug_tableCell]}>
                <Text style={styles.drug_content}>
                  {breakLongWords(data?.instruction ?? "", 18)}
                </Text>
              </View>
            </View>
          ))}
      </View>
    );
  };

  const displayMainContentData = (data, ind) => {
    return (
      <View key={ind}>
        {data?.name === "followUp" &&
          data?.value?.length > 0 &&
          followUpUI(data?.value)}

        {data?.name === "vitals" &&
          data?.value?.length > 0 &&
          vitalsUI(data?.value, data?.alignment)}

        {data?.name === "chiefComplaints" && data?.value?.length > 0 && (
          <ERXPdfMainContentList
            title={"Chief Complaints"}
            value={data?.value}
            pdfFontFamily={pdfFontFamily}
            alignment={data?.alignment}
          />
        )}

        {data?.name === "medicalHistory" &&
          data?.value?.length > 0 &&
          patientHistoryUI(data?.value, data?.alignment)}

        {data?.name === "obstetricHistory" &&
          data?.value?.length > 0 &&
          obstetricHistoryUI(data?.value, data?.alignment)}

        {data?.name === "obstetricCalculator" &&
          data?.value?.length > 0 &&
          obstetricCalculatorUI(data?.value)}

        {data?.name.includes("customSection") &&
          data?.value?.length > 0 &&
          customSectionUI(data?.value)}

        {data?.name === "suggestedInvestigations" &&
          data?.value?.length > 0 && (
            <ERXPdfMainContentList
              title={"Suggested Investigations"}
              value={data?.value}
              pdfFontFamily={pdfFontFamily}
              alignment={data?.alignment}
            />
          )}

        {data?.name === "instructionsAndNotes" && data?.value?.length > 0 && (
          <ERXPdfMainContentList
            title={"Instructions and Notes"}
            value={data?.value}
            pdfFontFamily={pdfFontFamily}
            alignment={data?.alignment}
          />
        )}

        {data?.name === "drugs" &&
          data?.value?.length > 0 &&
          drugUI(pdfMainContent?.drugHeader, data?.value)}

        {data?.name === "vaccine" &&
          (data?.value?.length > 0 || data?.vaccineNotes?.length > 0) &&
          vaccineChartUI(data?.value, data?.vaccineNotes)}

        {data?.name === "diagnosis" &&
          data?.value?.length > 0 &&
          renderDiagnosisAndReports(PDF_DIAGNOSIS, data?.value)}

        {data?.name === "reportsExamination" &&
          data?.value?.length > 0 &&
          renderDiagnosisAndReports(PDF_REPORT_AND_EXAM, data?.value)}

        {data?.name === "growthChart" && growthChartUI(data)}
      </View>
    );
  };

  const displayPdfMainContent = () => {
    return (
      <View>
        {pdfMainContent?.showDoctorDetails && displayDoctorDetails()}
        {displayPatientDetails()}

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: "3px",
          }}
        >
          <View
            style={{
              width: "30%",
              display: pdfMainContent?.displayLeftContent,
              padding: "0px 11px",
              borderRight: "0.7px solid #dae0e2",
            }}
          >
            {pdfMainContent?.sortedData?.length > 0 &&
              pdfMainContent?.sortedData?.map(
                (data, ind) =>
                  data?.alignment === "left" &&
                  data?.includeInErx &&
                  displayMainContentData(data, ind)
              )}
          </View>
          <View
            style={{
              width:
                pdfMainContent?.displayLeftContent === "none" ? "100%" : "70%",
              padding: "0px 11px",
            }}
          >
            {pdfMainContent?.sortedData?.length > 0 &&
              pdfMainContent?.sortedData?.map(
                (data, ind) =>
                  data?.alignment === "right" &&
                  data?.includeInErx &&
                  displayMainContentData(data, ind)
              )}
          </View>
        </View>
      </View>
    );
  };

  return (
    <View
      style={[
        styles.container,
        {
          marginLeft: getPdfContentMarginLeft(),
          marginRight: getPdfContentMarginRight(),
        },
      ]}
    >
      {displayPdfMainContent()}
    </View>
  );
};

export default ERXPdfMainContentView;
