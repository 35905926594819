import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import FormControl from "@material-ui/core/FormControl";
import FilledInput from "@material-ui/core/FilledInput";
import { useHistory, Redirect } from "react-router-dom";
import { useTimer } from "../../Hooks/useTimer";
import swal from "sweetalert";
import beforeLoginAxios from "../../Util/API Calls/beforeLogin";
import ErrorIcon from "@material-ui/icons/Error";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";
import {
  setCookieForUser,
  setDynamicCookieForUser,
} from "../../Util/API Calls/cookieHelper";
import { useCookies } from "react-cookie";
import { C2CConsole } from "../../Util/Helper/C2CConsole";

function OtploginPartialDoctor(props) {
  let { phoneNumber = "" } = props;

  let history = useHistory();
  const [setCookie] = useCookies(["cookie-name"]);
  const [otpInput, setOtpInput] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [resendAllowed, setResendAllowed] = useState(false);
  const [sendOTP, setSendOTP] = useState(0);

  useEffect(() => {
    swal({
      title:
        "An OTP has been sent to your registered Phone number and/or Email Address ",
      type: "warning",
    });
    beforeLoginAxios.post("public/send-onboard-doctor-OTP", {
      channel_id: "doctor-login",
      phone_no: `+91${phoneNumber}`,
    });
  }, [sendOTP]);

  const { time, start, reset, status } = useTimer({
    initialTime: 60,
    endTime: 0,
    timerType: "DECREMENTAL",
    autostart: true,
    onTimeOver: () => {
      setResendAllowed(true);
    },
  });

  if (phoneNumber == "") {
    return <Redirect to="/doctor/login/" />;
  }

  function handleForgotPassword() {
    if (!resendAllowed) return;
    reset();
    start();
    setResendAllowed(false);

    setSendOTP(sendOTP + 1);
  }

  const handleContinueClick = (e) => {
    e.preventDefault();
    if (otpInput.length !== 6) {
      setOtpError("OTP must be exactly 6 characters");
      return;
    }
    beforeLoginAxios
      .post("doctor/webinar-login", {
        phone_no: `+91${phoneNumber}`,
        user_otp: otpInput,
      })
      .then((response) => {
        if (response) {
          if (response.data.hasOwnProperty("statusCode")) {
            if (response.data.statusCode == 200) {
              localStorage.setItem("id_token", response.data.user.id_token);
              localStorage.setItem(
                LOCALSTORAGEKEY.USER_ID,
                response.data.user.user_id
              );
              localStorage.setItem("user_type", response.data.user.user_type);
              setCookieForUser(response.data.user.id_token, setCookie);
              setDynamicCookieForUser(
                LOCALSTORAGEKEY.USER_ID,
                response.data.user.user_id,
                setCookie
              );
              setDynamicCookieForUser(
                "user_type",
                response.data.user.user_type,
                setCookie
              );
              localStorage.setItem("doctorPhone", `+91${phoneNumber}`);
              localStorage.setItem("onBoardedDoctor", true);
              localStorage.setItem(
                "specialization",
                response?.data?.user?.specialization_id
              );
              setTimeout(() => {
                if (localStorage.getItem("id_token")) {
                  history.replace(
                    "/doctor/account/dashboard?utm_source=webapp&utm_medium=onboarding"
                  );
                }
              }, 100);

              swal({
                title: "Login Successful!",
                type: "success",
              }).then(function () {});
            } else if (response.data.statusCode == 513) {
              setOtpError("OTP you entered was incorrect");
            } else {
              setOtpError("Something went wrong");
            }
          }
        }
      })
      .catch(function (error) {
        C2CConsole.log(error);
      });
  };

  return (
    <div
      className="container h-100 d-flex align-items-center justify-content-center flex-column"
      style={{ position: "relative" }}
    >
      <div>
        <ArrowBackIcon
          onClick={() => {
            history.replace("/doctor/login/sign-up");
          }}
          style={{ position: "absolute", left: "0%", top: "2%" }}
          className="color-blue cursor-pointer"
        />
        <h3 className="text-center align-top">Enter OTP</h3>
      </div>
      <h6 className="text-center">Please Enter the OTP to login</h6>
      <div className="w-100 d-flex flex-row justify-content-between mt-3">
        <div className="d-inline-block text-muted">+91-{phoneNumber}</div>
        <Edit className="color-blue cursor-pointer" />
      </div>
      <div className="w-100 mt-2">
        <form
          className="d-flex flex-column align-items-center justify-content-center"
          onSubmit={(e) => {
            handleContinueClick(e);
          }}
        >
          <FormControl
            hiddenLabel={true}
            size="small"
            margin="dense"
            fullWidth={true}
          >
            <FilledInput
              id="OTP-input"
              aria-describedby="my-helper-text"
              aria-label="otp-input"
              placeholder="Enter OTP"
              autoFocus={true}
              disableUnderline={true}
              value={otpInput}
              inputProps={{
                autoComplete: "off",
              }}
              endAdornment={
                otpError ? <ErrorIcon style={{ color: "#e84444" }} /> : null
              }
              onChange={(e) => {
                setOtpInput(e.target.value);
              }}
            />
          </FormControl>
          <div className="row justify-content-between w-100">
            <div className="col-7 p-0">
              {resendAllowed ? (
                <div className="align-self-start text-muted">OTP expired</div>
              ) : (
                <div className="align-self-start text-muted">
                  {time} seconds left to expire
                </div>
              )}
            </div>
            <div className="col-5 p-0 d-flex justify-content-end align-items-start">
              <div
                className={`align-self-start cursor-pointer ${
                  !resendAllowed ? "text-muted pointer-disabled" : "textLink"
                }`}
                style={{ textAlign: "right" }}
                onClick={(e) => {
                  e.preventDefault();
                  handleForgotPassword();
                }}
              >
                Resend OTP
              </div>
            </div>
          </div>
          <div className="input-error text-center mt-1">
            {otpError ? otpError : ""}
          </div>
          <button
            className="btn primary-cta-button rounded-pill mt-3 align-self-center py-2 px-4"
            disabled={resendAllowed}
          >
            Login
          </button>
        </form>
      </div>
      <div className="separator w-50 mt-3 align-self-center">Or</div>
      <div
        className="mt-3 textLink font-weight-bold align-self-center"
        style={{ minWidth: "fit-content" }}
      >
        <div
          onClick={() => {
            history.replace("/doctor/login/partial-doctor");
          }}
        >
          Login with Password
        </div>
      </div>
    </div>
  );
}

export default OtploginPartialDoctor;

const Edit = () => {
  let history = useHistory();
  return (
    <span
      className="color-blue cursor-pointer"
      onClick={() => {
        history.replace("/doctor/login/sign-up");
      }}
    >
      Edit
      <EditIcon className="color-blue cursor-pointer" fontSize="inherit" />
    </span>
  );
};
