import React, { useEffect, useRef, useState } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CreateIcon from "@mui/icons-material/Create";
import GetAppIcon from "@material-ui/icons/GetApp";
import ReplyIcon from "@material-ui/icons/Reply";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment";

const ErxCell = ({
  e_rx_list,
  setFileName,
  setFileType,
  setFileUrl,
  setDocumentShow,
  downloadURI,
  ShareERx,
  ShareSavedErx,
  icons,
  editCallBack,
  saved,
  sethrsMinSec,
  expiryTime,
  additionalStyles,
}) => {
  const [showMoreHoriz, setShowMoreHoriz] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuRef = useRef(null);
  useEffect(() => {
    handleWindowResize();
    function handleWindowResize() {
      window.innerWidth < 900
        ? setShowMoreHoriz(true)
        : setShowMoreHoriz(false);
    }
    saved && getTimeout();
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const getTimeout = () => {
    setInterval(() => {
      let expiryDate = moment(expiryTime?.split(" ")[0])
        .add(2, "days")
        .format("DD/MM/YYYY");
      let expirytime = moment(expiryTime?.split("+")[0]).format("HH:mm");
      let expiryOn = moment(expiryDate + " " + expirytime, "DD/MM/YYYY HH:mm");
      let now = moment();
      let diffh = expiryOn.diff(now, "hours");
      let diffm = expiryOn.diff(now, "minutes");
      let diffs = expiryOn.diff(now, "seconds");
      let sc = diffm * 60;
      let hrs = diffh;
      let min = diffm - diffh * 60;
      let sec = diffs - sc;
      hrs = String(hrs).length === 1 ? "0" + hrs : hrs;
      min = String(min).length === 1 ? "0" + min : min;
      sec = String(sec).length === 1 ? "0" + sec : sec;
      sethrsMinSec && sethrsMinSec([hrs, min, sec]);
    }, [1000]);
  };

  const handleAction = (key, index, e_rx_list, result) => {
    switch (key) {
      case "edit":
        let payload = {
          prescription_id: result?.prescription_id,
        };
        editCallBack(payload);
        break;
      case "view":
        viewPrescription(result, e_rx_list, index);
        break;
      case "download":
        downloadCall(result, e_rx_list, index);
        break;
      case "share":
        saved ? ShareSavedErx(result) : ShareERx(result);
        break;
      default:
        break;
    }
  };
  const downloadCall = (result, e_rx_list, index) => {
    if (result?.presigned_url || result?.erx_urls) {
      downloadURI(
        result?.presigned_url || result?.erx_urls,
        `E-Prescription ${
          e_rx_list.length - index <= 9 ? `0${e_rx_list.length - index}` : e_rx_list.length - index
        }`,
        ".pdf"
      );
    }
  };
  const viewPrescription = (result, e_rx_list, index) => {
    if (result?.presigned_url_view || result?.erx_urls_view) {
      setFileName(
        `E-Prescription ${
          e_rx_list.length - index <= 9 ? `0${e_rx_list.length - index}` : e_rx_list.length - index
        }`
      );
      setFileType(".pdf");
      result?.erx_urls_view
        ? setFileUrl(result?.erx_urls_view)
        : setFileUrl(result?.presigned_url_view);
      setDocumentShow(true);
    }
  };
  const getActionIcon = (key) => {
    let icon =
      key === "view" ? (
        <span className="mr-2">
          <VisibilityIcon fontSize="small" />
        </span>
      ) : key === "edit" ? (
        <span className="mr-2">
          <CreateIcon fontSize="small" />
        </span>
      ) : key === "download" ? (
        <span className="mr-2">
          <GetAppIcon fontSize="small" />
        </span>
      ) : (
        key === "share" && (
          <span className="mr-2">
            <ReplyIcon fontSize="small" style={{ transform: "scaleX(-1)" }} />
          </span>
        )
      );
    return icon;
  };
  return (
    <div
      className="w-100"
      style={{
        backgroundColor: "#ffff",
      }}
    >
      {e_rx_list?.length > 0
        ? e_rx_list?.map((result, index) => {
            return (
              <div
                className={`d-flex p-3 ${"m_rx_border"} justify-content-between`}
                key={index}
                style={{ ...(additionalStyles ? additionalStyles : null) }}
              >
                <div style={{ maxWidth: "40%" }}>
                  <p className="m_rx_title">{`E-Prescription ${
                    index < 9
                      ? `0${e_rx_list.length - index}`
                      : e_rx_list.length - index
                  }`}</p>
                  {saved ? (
                    <p className="m_rx_details" style={{ fontSize: "13px" }}>
                      {moment(result?.saved_at?.split(" ")[0]).format(
                        "DD MMM, YYYY "
                      )}
                      |{" "}
                      {moment(result?.saved_at?.split("+")[0]).format(
                        " hh:mm A"
                      )}
                    </p>
                  ) : result?.shared_at ? (
                    <p className="m_rx_details" style={{ fontSize: "13px" }}>
                      {moment(result?.shared_at?.split(" ")[0]).format(
                        "DD MMM, YYYY "
                      )}
                      |{" "}
                      {moment(result?.shared_at?.split("+")[0]).format(
                        " hh:mm A"
                      )}
                    </p>
                  ) : (
                    result?.created_at_date && (
                      <p className="m_rx_details" style={{ fontSize: "13px" }}>
                        {moment(result?.created_at_date).format(
                          "DD MMM, YYYY "
                        )}
                        |{" "}
                        {moment(result?.created_at_time, "HH:mm:ss").format(
                          "hh:mm A"
                        )}
                      </p>
                    )
                  )}
                </div>
                {showMoreHoriz ? (
                  <div className="d-flex justify-content-end">
                    <div class="dropdown">
                      <span
                        style={{ paddingTop: "8px" }}
                        data-tooltip="Edit"
                        data-tooltip-conf="bottom"
                        onClick={(e) => {
                          handleClick(e);
                        }}
                        data-toggle="dropdown"
                      >
                        <i className="medical-record-icon m-1">
                          <MoreHorizIcon fontSize="small" />
                        </i>
                      </span>
                      <div className="dropdown-menu dropleft menu-pos">
                        {Object.keys(icons).map((key, index) => {
                          return (
                            icons[key] === true && (
                              <button
                                style={{
                                  color: "#26bee2",
                                  background: "transparent",
                                }}
                                className="dropdown-item"
                                onClick={(e) => {
                                  handleAction(key, index, e_rx_list, result);
                                }}
                              >
                                {getActionIcon(key)}
                                {key?.charAt(0)?.toUpperCase() + key?.slice(1)}
                              </button>
                            )
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{ maxWidth: "60%", flexWrap: "wrap", gap: "10px" }}
                    className="d-flex justify-content-end"
                  >
                    {icons.edit && (
                      <span
                        style={{ paddingTop: "8px" }}
                        data-tooltip="Edit"
                        data-tooltip-conf="bottom"
                      >
                        <i
                          className="medical-record-icon m-1"
                          onClick={(e) => {
                            let payload = {
                              prescription_id: result?.prescription_id,
                            };
                            editCallBack(payload);
                          }}
                        >
                          <CreateIcon fontSize="small" />
                        </i>
                      </span>
                    )}
                    <span
                      style={{ paddingTop: "8px" }}
                      data-tooltip="View"
                      data-tooltip-conf="bottom"
                    >
                      <i
                        className={`medical-record-icon  m-1 ${
                          !result?.presigned_url_view && !result?.erx_urls_view
                            ? "disable-current-icon disable-pointer-event-span"
                            : ""
                        }`}
                        onClick={() => {
                          viewPrescription(result, e_rx_list, index);
                        }}
                      >
                        <VisibilityIcon fontSize="small" />
                      </i>
                    </span>
                    <span
                      style={{ paddingTop: "8px" }}
                      data-tooltip="Download"
                      data-tooltip-conf="bottom"
                    >
                      <i
                        className={`medical-record-icon  m-1 ${
                          !result?.presigned_url_view && !result?.erx_urls_view
                            ? "disable-current-icon disable-pointer-event-span "
                            : ""
                        }`}
                        onClick={() => downloadCall(result, e_rx_list, index)}
                      >
                        <GetAppIcon fontSize="small" />
                      </i>
                    </span>
                    {icons.share && (
                      <span
                        style={{ paddingTop: "8px" }}
                        data-tooltip="Share"
                        data-tooltip-conf="bottom"
                      >
                        <i
                          className={`medical-record-icon  m-1 ${
                            !result?.presigned_url_view &&
                            !result?.erx_urls_view
                              ? "disable-current-icon disable-pointer-event-span"
                              : ""
                          }`}
                          onClick={() => {
                            saved ? ShareSavedErx(result) : ShareERx(result);
                          }}
                        >
                          <ReplyIcon
                            fontSize="small"
                            style={{ transform: "scaleX(-1)" }}
                          />
                        </i>
                      </span>
                    )}
                  </div>
                )}
              </div>
            );
          })
        : null}
    </div>
  );
};
export default ErxCell;
