import React, { useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import FilledInput from "@material-ui/core/FilledInput";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import beforeLoginAxios from "../../Util/API Calls/beforeLogin";
import swal from "sweetalert";
import ErrorIcon from "@material-ui/icons/Error";

import { useHistory, Redirect } from "react-router-dom";
import {
  setCookieForUser,
  setDynamicCookieForUser,
} from "../../Util/API Calls/cookieHelper";
import { useCookies } from "react-cookie";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";

function StaffLoginEnterPassword(props) {
  let { phoneNumber = "" } = props;
  let history = useHistory();

  const [cookies, setCookie] = useCookies(["cookie-name"]);

  function handleForgotPassword() {
    beforeLoginAxios
      .post("public/staff-send-otp", {
        // user_type: "doctor",
        channel_id: "staff-login",
        phone_no: `+91${phoneNumber}`,
      })
      .then((response) => {
        if (response) {
          if (response.data && response.data.statusCode === 200) {
            history.replace("/doctor/login/staff/set-password", {
              title: "Reset Password",
              resetTitle:
                "Set password for easier access (minimum 6 characters required)",
            });
          }
        }
      });
  }

  function handlePassword(val) {
    setPassword(val);
  }

  const handleContinueClick = (e) => {
    e.preventDefault();
    //validations
    // history.replace("/doctor/login/enter-password");
    //api call
    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
      return;
    } else {
      setPasswordError(false);
    }

    beforeLoginAxios
      .post("auth/staff-login-rev1", {
        // channel_id: "doctor-login",
        phone_no: `+91${phoneNumber}`,
        user_type: "staff",
        password: password,
        stay_login: stayLogin,
      })
      .then((response) => {
        if (response) {
          if (response.data.hasOwnProperty("statusCode")) {
            if (response.data.statusCode == 200) {
              setCookieForUser(response.data.user.id_token, setCookie);
              setDynamicCookieForUser(
                LOCALSTORAGEKEY.USER_ID,
                response.data.user.staff_id,
                setCookie
              );
              setDynamicCookieForUser(
                "user_type",
                response.data.user.user_type,
                setCookie
              );
              localStorage.setItem("id_token", response.data.user.id_token);
              localStorage.setItem(
                LOCALSTORAGEKEY.USER_ID,
                response.data.user.doctor_id
              );
              localStorage.setItem("user_type", response.data.user.user_type);
              localStorage.setItem(
                "user_name",
                response.data.user.staff_full_name
              );
              localStorage.setItem(
                LOCALSTORAGEKEY.STAFF_ID,
                response.data.user.staff_id
              );
              localStorage.setItem(
                "full_name",
                response.data.user.staff_full_name
              );
              localStorage.setItem("doctorPhone", `+91${phoneNumber}`);
              localStorage.setItem("id_token", response.data.user.id_token);
              localStorage.setItem(
                "share_instant_consultation_link_allow",
                response.data.user.permission
                  .share_instant_consultation_link_allow
              );
              localStorage.setItem(
                "add_edit_new_staff_name_and_number_allow",
                response.data.user.permission
                  .add_edit_new_staff_name_and_number_allow
              );
              localStorage.setItem(
                "start_instant_consultation_allow",
                response.data.user.permission.start_instant_consultation_allow
              );
              localStorage.setItem(
                "upload_manual_prescriptions_allow",
                response.data.user.permission.upload_manual_prescriptions_allow
              );
              localStorage.setItem(
                "access_knowledge_center_allow",
                response.data.user.permission.access_knowledge_center_allow
              );
              localStorage.setItem(
                "download_offline_patients_allow",
                response.data.user.permission.download_offline_patients_allow
              );
              localStorage.setItem(
                "edit_prescriptions_allow",
                response.data.user.permission.edit_prescriptions_allow
              );
              localStorage.setItem(
                "join_webinar_allow",
                response.data.user.permission.join_webinar_allow
              );
              localStorage.setItem(
                "update_account_details_allow",
                response.data.user.permission.update_account_details_allow
              );
              localStorage.setItem(
                "update_profile_details_allow",
                response.data.user.permission.update_profile_details_allow
              );

              localStorage.setItem(
                "last_login_date",
                response.data.user.last_login_date
              );

              localStorage.setItem(
                "start_upcoming_consultation_allow",
                response.data.user.permission.start_upcoming_consultation_allow
              );

              localStorage.setItem(
                "register_for_upcoming_webinar_allow",
                response?.data?.user?.permission
                  ?.register_for_upcoming_webinar_allow
              );
              localStorage.setItem("stay-login", stayLogin);
              // swal({
              //   title: "Login Successful!",
              //   type: "success",
              // }).then(function () {});

              localStorage.setItem(
                LOCALSTORAGEKEY.STAFF_CLINIC_ASSOCIATION,
                response?.data?.staff_clinic_association
              );
              localStorage.setItem(
                "staff_doctor_association",
                response?.data?.staff_doctor_association
              );

              setTimeout(() => {
                if (localStorage.getItem("id_token")) {
                  if (response?.data?.staff_clinic_association === true) {
                    history.replace("/clinic/account/dashboard");
                  } else {
                    history.replace("/doctor/account/dashboard");
                  }
                }
              }, 100);
            } else if (response.data.statusCode == 510) {
              setPasswordError("Incorrect password entered. Please try again");
            }
          }
        }
      });
  };

  const [focusOnOTP, setFocusOnOTP] = useState(true);
  const [showSetPassword, setShowSetPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [stayLogin, setStayLogin] = useState(false);

  if (phoneNumber == "") {
    // phoneNumber = "1234123412";
    return <Redirect to="/doctor/login/" />;
  }

  return (
    <div
      className="container h-100 d-flex align-items-center justify-content-center flex-column px-sm-2 px-md-0"
      style={{ position: "relative" }}
    >
      <div className="w-100">
        <ArrowBackIcon
          onClick={() => {
            history.replace("/doctor/login/");
          }}
          style={{ position: "absolute", left: "0%", top: "2%" }}
          className="color-blue cursor-pointer"
        />
        <h3 className="text-center align-top">Login</h3>
      </div>
      <h6 className="text-center">Enter your password to login</h6>
      <div className="w-100 d-flex flex-row justify-content-between mt-4">
        <div className="d-inline-block text-muted">+91-{phoneNumber}</div>
        <Edit className="color-blue cursor-pointer" />
      </div>
      <div className="w-100 mt-3">
        <form
          className="d-flex flex-column align-items-center justify-content-center"
          onSubmit={(e) => {
            handleContinueClick(e);
          }}
        >
          <FormControl
            hiddenLabel="true"
            size="small"
            margin="dense"
            fullWidth="true"
          >
            <FilledInput
              id="password-input"
              placeholder="Enter Password"
              aria-describedby="my-helper-text"
              aria-label="Set-password"
              disableUnderline="true"
              autoFocus={!focusOnOTP}
              type={showPassword ? "text" : "password"}
              endAdornment={
                passwordError ? (
                  <ErrorIcon style={{ color: "#e84444" }} />
                ) : (
                  <ShowPassword
                    setShowPassword={setShowPassword}
                    showPassword={showPassword}
                  />
                )
              }
              onChange={(e) => {
                handlePassword(e.target.value);
              }}
              value={password}
            />
          </FormControl>

          <div className="d-flex stay-login pt-2">
            <label class="container custom-checkbox mb-0 d-flex">
              <input
                type="checkbox"
                name="EnableOnlinePayment"
                onClick={() => setStayLogin(true)}
              />
              <span class="checkmark"></span>
              <span className="staysignin">Stay Signed In</span>
            </label>
            <span
              className="align-self-end cursor-pointer textLink border-bottom-0"
              onClick={() => {
                handleForgotPassword();
              }}
            >
              Forgot Password?
            </span>
          </div>
          <div className="input-error align-self-center text-center mt-3">
            {passwordError ? passwordError : ""}
          </div>
          <button className="btn primary-cta-button rounded-pill mt-3 align-self-center py-2 px-4">
            Login
          </button>
        </form>
      </div>
      <div
        className="separator w-50 mt-3"
        style={{ margin: "auto", left: "50px" }}
      >
        Or
      </div>
      <div
        className="mt-3 textLink font-weight-bold"
        style={{ minWidth: "fit-content" }}
      >
        <div
          onClick={() => {
            history.replace("/doctor/login/staff/otp-login");
          }}
        >
          Login with OTP
        </div>
      </div>
    </div>
  );
}

export default StaffLoginEnterPassword;

const Edit = () => {
  let history = useHistory();
  return (
    <span
      className="color-blue cursor-pointer"
      onClick={() => {
        history.replace("/doctor/login");
      }}
    >
      Edit
      <EditIcon className="color-blue cursor-pointer" fontSize="inherit" />
    </span>
  );
};

export function ShowPassword(props) {
  const { setShowPassword, showPassword = false } = props;

  if (showPassword === false) {
    return (
      <span
        className="color-blue cursor-pointer"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        <VisibilityIcon />
      </span>
    );
  } else {
    return (
      <span
        className="color-blue cursor-pointer"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        <VisibilityOffIcon />
      </span>
    );
  }
}
