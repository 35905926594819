export const stepper = {
    "1": [
        {
            index: 1,
            name: 'Select Date & Time',
            opacity: true
        },
        {
            index: 2,
            name: 'Select Patient',
        },
        {
            index: 3,
            name: 'Enter Details & Confirm',
        }
    ],
    "2": [
        {
            index: 1,
            name: 'Select Date & Time',
            opacity: true,
            tick: true
        },
        {
            index: 2,
            name: 'Select Patient',
        },
        {
            index: 3,
            name: 'Enter Details & Confirm',
        }
    ],
    "3": [
        {
            index: 1,
            name: 'Select Date & Time',
            tick: true,
            opacity: true,
        },
        {
            index: 2,
            name: 'Select Patient',
            tick: true,
            opacity: true,
        },
        {
            index: 3,
            name: 'Enter Details & Confirm',
            opacity: true,
        }
    ]
};

export const stepper_mob = {
    "1": [
        {
            index: 1,
            name: 'Select Date & Time',
            opacity: true
        },
        {
            index: 2,
            name: '',
        },
        {
            index: 3,
            name: '',
        }
    ],
    "2": [
        {
            index: 1,
            name: '',  
            tick: true,
            // opacity: true,          
        },
        {
            index: 2,
            name: 'Select Patient',
            opacity: true,
            tick: true
        },
        {
            index: 3,
            name: '',
        }
    ],
    "3": [
        {
            index: 1,
            name: '',
            tick: true,
            // opacity: true,
        },
        {
            index: 2,
            name: '',
            tick: true,
            // opacity: true,
        },
        {
            index: 3,
            name: 'Enter Details & Confirm',
            tick: true,
            opacity: true,
        }
    ]
};


export const stepper_is_follow_up = {
    "1": [
        {
            index: 1,
            name: 'Select Patient',
            opacity: true
        },
        {
            index: 2,
            name: 'Select Date & Time',
        },
        {
            index: 3,
            name: 'Enter Details & Confirm',
        }
    ],
    "2": [
        {
            index: 1,
            name: 'Select Patient',
            opacity: true,
            tick: true
        },
        {
            index: 2,
            name: 'Select Date & Time',
        },
        {
            index: 3,
            name: 'Enter Details & Confirm',
        }
    ],
    "3": [
        {
            index: 1,
            name: 'Select Patient',
            tick: true,
            opacity: true,
        },
        {
            index: 2,
            name: 'Select Date & Time',
            tick: true,
            opacity: true,
        },
        {
            index: 3,
            name: 'Enter Details & Confirm',
            opacity: true,
        }
    ]
};

export const stepper_is_follow_up_mob = {
    "1": [
        {
            index: 1,
            name: 'Select Patient',
            opacity: true
        },
        {
            index: 2,
            name: '',
        },
        {
            index: 3,
            name: '',
        }
    ],
    "2": [
        {
            index: 1,
            name: '',
            // opacity: true,
            tick: true
        },
        {
            index: 2,
            name: 'Select Date & Time',
        },
        {
            index: 3,
            name: '',
        }
    ],
    "3": [
        {
            index: 1,
            name: '',
            tick: true,
            // opacity: true,
        },
        {
            index: 2,
            name: '',
            tick: true,
            // opacity: true,
        },
        {
            index: 3,
            name: 'Enter Details & Confirm',
            opacity: true,
        }
    ]
};
