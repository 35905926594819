import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import afterLogin from "../Util/API Calls/afterLogin";
import Loader from "./UI Component/Loader/Loader";
import { patientFiles } from "../Util/API Calls/APIList";
import FliterIcon from "../../src/assets/FliterIcon.svg";
import moment from "moment";
import "./Admin/styles.css";
import afterLoginApiAxios from "../Util/API Calls/afterLogin";
import _ from "lodash";
import Checkbox from "../assets/check-transaction-icon.png";
import swal from "sweetalert";
import PreviousPatientRow from "./PreviousPatients/PreviousPatientRow";
import PrescriptionComponent from "./DoctorVideo/PrescriptionComponent";
import Drawer from "@material-ui/core/Drawer";
import DocumentDoctor from "./DocumentDoctor";
import DocumentCotainer from "./UI Component/DocumentViewer/DocumentCotainer";
import { LOCALSTORAGEKEY } from "../Util/Helper/localStorageUtils";
import { C2CConsole } from "../Util/Helper/C2CConsole";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const monthShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

var FontAwesome = require("react-fontawesome");

class PreviousPatients extends Component {
  constructor(props, context) {
    super(props, context);

    this.viewAppointment = this.viewAppointment.bind(this);
    this.closeAppointment = this.closeAppointment.bind(this);
    this.state = {
      view: "all",
      sortFilter: "most",
      prescription_notification_sent: "all",
      ascendingsorted: true,
      URL: patientFiles,
      docType: "",
      appointments: [],
      openView: false,
      patient: null,
      months: [
        "-",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      searchTerm: "",
      activeSearchTerm: "",
      filterDate: null,
      filteringByDate: false,
      dateSorted: false,
      showLoader: false,
      all_appointments: [],
      isCalendarOpen: false,
      number_of_pages: 0,
      activePage: 1,
      calendarChanged: false,
      activeStartDate: new Date(),
      drawerOpen: false,
      ageForDrawer: 0,
      appointmentForDrawer: null,
      drawerType: "",
      documentShow: false,
      fullView: false,
      thisWeek: "",
    };
    this.logOut.bind(this);
    this.searchBarRef = React.createRef();

    this.getDebouncedResults = _.debounce(this.handleSearch, 1000);
    this.userId = localStorage.getItem(LOCALSTORAGEKEY.USER_ID);
  }

  toggleDrawer() {
    this.setState({
      drawerOpen: !this.state.drawerOpen,
      drawerType: "",
    });
    this.fetchAppointments();
  }

  editPrescriptionAction(appointment) {
    var tempDate = new Date();
    var yr = tempDate.getFullYear();
    var age = yr - Number(appointment.patient_dob.substring(0, 4));

    this.setState(
      { appointmentForDrawer: { app_id: appointment }, ageForDrawer: age },
      () => {
        this.setState({
          drawerOpen: true,
          drawerType: "Edit Prescription",
        });
      }
    );
  }

  viewDocumentAction(appointment) {
    this.setState({ appointmentForDrawer: { app_id: appointment } }, () => {
      this.setState({
        drawerOpen: true,
        drawerType: "View Documents",
      });
    });
  }

  closeDrawerAction = () => {
    this.setState({
      drawerOpen: false,
    });
  };

  logOut() {
    afterLoginApiAxios
      .post(
        "auth/user-logout",
        {
          user_token: `${localStorage.getItem("id_token")}`,
        },
        {
          headers: {
            Authorization: `${window.localStorage.getItem("id_token")}`,
          },
        }
      )
      .then((response) => {
        C2CConsole.log(response);
        localStorage.removeItem("id_token");
        localStorage.removeItem("user_type");
        localStorage.removeItem(LOCALSTORAGEKEY.USER_ID);
        localStorage.removeItem("user_name");
        localStorage.removeItem("full_name");
        this.props.history.push("/doctor/login");
      })
      .catch((err) => {
        C2CConsole.log("error found");
        C2CConsole.log(err);
        swal("", "An error occured, logging out now!", "info").then(() => {
          localStorage.removeItem("id_token");
          localStorage.removeItem("user_type");
          localStorage.removeItem(LOCALSTORAGEKEY.USER_ID);
          localStorage.removeItem("user_name");
          localStorage.removeItem("full_name");
          this.props.history.push("/doctor/login");
        });
      });
  }
  componentWillMount() {
    if (localStorage.getItem("id_token")) {
    } else {
      this.props.history.push("/doctor/login");
    }

    var token = localStorage.getItem("id_token");
    var user_type = localStorage.getItem("user_type");
    var full_name = localStorage.getItem("full_name");

    this.setState({
      token: token,
      user_type: user_type,
      user_name: full_name,
      showLoader: true,
    });

    this.fetchAppointments();
  }

  componentWillReceiveProps(props) {
    if (props.refreshState == true) {
      this.setState(
        {
          searchTerm: "",
          activeSearchTerm: "",
          filterDate: null,
          filteringByDate: false,
          dateSorted: false,
          all_appointments: [],
          isCalendarOpen: false,
          number_of_pages: 0,
          activePage: 1,
          calendarChanged: false,
          showLoader: true,
        },
        () => this.fetchAppointments()
      );
    }
  }

  fetchAppointments() {
    afterLogin
      .post(
        "user/list-appointments",
        {
          upcoming: "false",
          // rows_per_page: 10,
          page_no: 1,
          "authorizer-principal-id": {
            user_type: 1,
            user_id: this.userId,
          },
        },
        {
          headers: {
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then((response) => {
        if (response) {
          var responseData = response.data;
          if (response.status == 403) {
            this.props.history.push("/doctor/login");
          }
          if (responseData) {
            if (response.data.hasOwnProperty("statusCode")) {
              if (response.data.statusCode == 200) {
                this.setState({
                  appointments: responseData.categories,
                  all_appointments: responseData.categories,
                  number_of_pages: responseData.total_pages_count,
                  activePage: 1,
                  showLoader: false,
                });
              } else if (response.data.statusCode == 503) {
                this.setState({
                  appointments: [],
                  number_of_pages: 0,
                  // activePage:0,
                  showLoader: false,
                });
              } else if (response.data.statusCode == 403) {
                this.props.history.push("/doctor/login");
              }
            } else {
              this.setState(
                {
                  response_message: "Something Went Wrong!Please Try Again!!",
                  dataload: false,
                },
                () => {}
              );
            }
          }
        }
      })
      .catch(function (error) {
        C2CConsole.log(error);
      });
  }

  viewAppointment(appoint) {
    this.setState({
      openView: true,
      patient: appoint,
    });
  }

  closeAppointment() {
    this.setState({
      openView: false,
    });
  }

  handleDateOrder = () => {
    if (this.state.ascendingsorted == false) {
      const appointments = [...this.state.appointments].reverse();
      const dateSorted = !this.state.dateSorted;
      const ascendingsorted = true;
      this.setState({
        appointments,
        dateSorted,
        ascendingsorted,
        sortFilter: "most",
      });
    }
  };

  handleOrder = () => {
    if (this.state.ascendingsorted) {
      const appointments = [...this.state.appointments].reverse();
      const dateSorted = !this.state.dateSorted;
      const ascendingsorted = false;

      this.setState({
        appointments,
        dateSorted,
        ascendingsorted,
        sortFilter: "least",
      });
    }
  };

  handleSearch(e, forceRefresh, bringToFocus) {
    if (e) {
      e.persist();
    }
    if (
      !this.state.filterDate &&
      this.state.searchTerm.trim().length === 0 &&
      this.state.searchTerm.trim() === this.state.activeSearchTerm &&
      forceRefresh === false
    ) {
      return;
    }
    this.setState({
      activeSearchTerm: this.state.searchTerm,
      showLoader: true,
    });

    let params = {};

    // let shared = false

    const today = new Date();
    const temp_date = new Date();

    if (this.state.prescription_notification_sent == "shared") {
      params["prescription_notification_sent"] = true;
      // shared =true
    }
    if (this.state.prescription_notification_sent == "notshared") {
      params["prescription_notification_sent"] = false;
      // shared=false
    }

    if (this.state.activeSearchTerm.length != 0) {
      params["search_string"] = this.state.activeSearchTerm.trim();
    }

    let day = temp_date.getDay(),
      diff = temp_date.getDate() - day + (day === 0 ? -6 : 1);
    let mondayy = new Date(temp_date.setDate(diff));
    if (this.state.thisWeek == "week") {
      this.setState({
        filterDate: mondayy.toString().padStart(2, "0"),
      });
      const apt_date_query = moment(mondayy).format("YYYY-MM-DD");
      // const lastWeekday = (today.getDate() - 4).toString().padStart(2, "0");
      // const lastWeekmonth = (today.getMonth() + 1).toString().padStart(2, "0");
      // const lastWeekyear = today.getFullYear().toString();

      params["appointment_date_query"] = apt_date_query;
    } else if (this.state.thisWeek == "month") {
      // const lastWeekday = today.getDate().toString().padStart(2, "0");
      // const lastWeekmonth = today.getMonth().toString().padStart(2, "0");
      // const lastWeekyear = today.getFullYear().toString();

      let firstdayy = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      );

      this.setState({
        filterDate: firstdayy,
      });
      const apt_date_query = moment(firstdayy).format("YYYY-MM-DD");
      params["appointment_date_query"] = apt_date_query;
    } else if (this.state.thisWeek == "year") {
      let firstdayoftheyear = new Date(new Date().getFullYear(), 0);

      this.setState({
        filterDate: firstdayoftheyear,
      });
      const apt_date_query = moment(firstdayoftheyear).format("YYYY-MM-DD");

      params["appointment_date_query"] = apt_date_query;
    } else if (this.state.prescription_notification_sent == "shared") {
      params["prescription_notification_sent"] = true;
      // shared =true
    } else if (this.state.prescription_notification_sent == "notshared") {
      params["prescription_notification_sent"] = false;
      // shared=false
    } else if (this.state.filterDate) {
      const date = new Date(this.state.filterDate);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = this.state.filterDate.getFullYear().toString();
      const appointment_date_query = [year, month, day].join("-");
      params["appointment_date_query"] = appointment_date_query;
    }
    afterLogin
      .post(
        "user/list-appointments",
        {
          "authorizer-principal-id": {
            user_type: 1,
            user_id: this.userId,
          },
          ...params,
          end_date: moment(today).format("YYYY-MM-DD"),
          upcoming: false,
          // prescription_notification_sent:shared,
          // rows_per_page: 10,
          page_no: 1,
          // appointment_date_query:this.state.filterDate
        },
        {
          headers: {
            Authorization: `${window.localStorage.getItem("id_token")}`,
          },
        }
      )
      .then((response) => {
        if (response) {
          var responseData = response.data;
          if (responseData) {
            if (response.data.hasOwnProperty("statusCode")) {
              if (response.data.statusCode == 200) {
                this.setState({
                  appointments: responseData.categories,
                  number_of_pages: responseData.total_pages_count,
                  appointment_date_query: this.state.filterDate,
                  activePage: 1,
                  showLoader: false,
                });
              } else if (response.data.statusCode == 503) {
                this.setState({
                  appointments: [],
                  number_of_pages: 0,
                  // activePage:0,
                  showLoader: false,
                });
              } else if (response.data.statusCode == 403) {
                this.props.history.push("/doctor/login");
              }
            } else {
              this.setState({
                response_message: "Something Went Wrong!Please Try Again!!",
                dataload: false,
              });
            }
          }
          if (bringToFocus) {
            this.searchBarRef.current.focus();
          }
        }
      })
      .catch(function (error) {
        C2CConsole.log(error);
      });
  }

  fetchPage() {
    let params = {};
    if (this.state.filterDate) {
      const date = new Date(this.state.filterDate);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = this.state.filterDate.getFullYear().toString();
      const appointment_date_query = [year, month, day].join("-");
      params["appointment_date_query"] = appointment_date_query;
    }
    if (this.state.activeSearchTerm.length != 0) {
      params["search_string"] = this.state.searchTerm;
    }
    //WHEN NOT IN SEARCH MODE

    afterLogin
      .post(
        "user/list-appointments",
        {
          upcoming: "false",
          // rows_per_page: 10,
          page_no: this.state.activePage,
          "authorizer-principal-id": {
            user_type: 1,
            user_id: this.userId,
          },
          ...params,
        },
        {
          headers: {
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then((response) => {
        if (response) {
          var responseData = response.data;

          if (response.status == 403) {
            this.props.history.push("/doctor/login");
          }
          if (responseData) {
            if (response.data.hasOwnProperty("statusCode")) {
              if (response.data.statusCode == 200) {
                let sortedAppointments = !this.state.dateSorted
                  ? responseData.categories
                  : responseData.categories.reverse();
                this.setState({
                  appointments: sortedAppointments,
                  all_appointments: responseData.categories,
                  showLoader: false,
                });
              } else if (response.data.statusCode == 503) {
                this.setState({
                  appointments: [],
                  number_of_pages: 0,
                  // activePage:0,
                  showLoader: false,
                });
              } else if (response.data.statusCode == 403) {
                this.props.history.push("/doctor/login");
              }
            } else {
              this.setState({
                response_message: "Something Went Wrong!Please Try Again!!",
                dataload: false,
              });
            }
          }
        }
      })
      .catch(function (error) {
        C2CConsole.log(error);
      });
  }

  renderPagination() {
    if (this.state.number_of_pages != 0) {
      return (
        <div className="d-flex justify-content-center page-number-row mt-3">
          {/* {this.renderPageNumbers()} */}
          <span
            onClick={(e) =>
              this.setState(
                (prevState) => ({ showLoader: true, activePage: 1 }),
                () => this.fetchPage()
              )
            }
            className={this.state.activePage == 1 ? "d-none" : "inactivePage"}
          >
            <i className="fa fa-angle-double-left" aria-hidden="true"></i>
          </span>
          <span
            onClick={(e) =>
              this.setState(
                (prevState) => ({
                  showLoader: true,
                  activePage: prevState.activePage - 1,
                }),
                () => this.fetchPage()
              )
            }
            className={this.state.activePage == 1 ? "d-none" : "inactivePage"}
          >
            <i className="fa fa-angle-left" aria-hidden="true"></i>
          </span>
          <span
            onClick={(e) =>
              this.setState(
                (prevState) => ({
                  showLoader: true,
                  activePage: prevState.activePage - 1,
                }),
                () => this.fetchPage()
              )
            }
            className={this.state.activePage == 1 ? "d-none" : "inactivePage"}
          >
            {this.state.activePage - 1}
          </span>
          <span className="activePage">{this.state.activePage}</span>
          <span
            onClick={(e) =>
              this.setState(
                (prevState) => ({
                  showLoader: true,
                  activePage: prevState.activePage + 1,
                }),
                () => this.fetchPage()
              )
            }
            className={
              this.state.activePage == this.state.number_of_pages
                ? "d-none"
                : "inactivePage"
            }
          >
            {this.state.activePage + 1}
          </span>
          <span
            onClick={(e) =>
              this.setState(
                (prevState) => ({
                  showLoader: true,
                  activePage: prevState.activePage + 1,
                }),
                () => this.fetchPage()
              )
            }
            className={
              this.state.activePage == this.state.number_of_pages
                ? "d-none"
                : "inactivePage"
            }
          >
            <i className="fa fa-angle-right" aria-hidden="true"></i>
          </span>
          <span
            onClick={(e) =>
              this.setState(
                (prevState) => ({
                  showLoader: true,
                  activePage: this.state.number_of_pages,
                }),
                () => this.fetchPage()
              )
            }
            className={
              this.state.activePage == this.state.number_of_pages
                ? "d-none"
                : "inactivePage"
            }
          >
            <i className="fa fa-angle-double-right" aria-hidden="true"></i>
          </span>
        </div>
      );
    }
  }

  renderDate() {
    if (this.state.filterDate) {
      const date = new Date(this.state.filterDate);
      const day = date.getDate().toString().padStart(2, "0");
      const month = date.getMonth();
      const monthName = monthNames[month];
      const year = this.state.filterDate.getFullYear().toString();
      // const appointment_date_query = [year, month,day].join('-').toString()
      return `${
        this.state.thisWeek != ""
          ? `Showing results for this ${this.state.thisWeek}, `
          : ""
      }
      Total results are ${this.state.appointments.length}`;
    }
    return "";
  }

  closeNotification = () => {
    this.setState({
      documentShow: false,
      fullView: false,
      fileName: "",
      fileURL: "",
      fileType: "",
    });
  };

  viewDocument = ({ fileURL, fileName, fileType }) => {
    if (fileType && fileType.match(/pdf/gim)) {
      this.downloadURI(fileURL, fileName);
    } else {
      this.setState({
        documentShow: true,
        fileURL: fileURL,
        fileName: fileName,
        fileType: fileType,
      });
    }
  };

  downloadURI = (uri, name) => {
    let link = document.createElement("a");
    link.href = uri;
    link.target = "_blank";
    link.click();
  };

  renderDocument(width) {
    if (this.state.documentShow) {
      return (
        <DocumentCotainer
          cancelHandler={() => this.closeNotification()}
          fullViewHandler={() =>
            this.setState({ fullView: !this.state.fullView })
          }
          url={this.state.fileURL}
          fileName={this.state.fileName}
          fileType={this.state.fileType}
          width={width || ""}
        />
      );
    }
  }

  render() {
    var styles = {
      cardOdd: {
        backgroundColor: "#eeae01",
        color: "#454545",
      },
      cardEven: {
        backgroundColor: "#454545",
        color: "#eeae01",
      },
    };

    return (
      <React.Fragment>
        <div className="col-sm-12 col-lg-12 ml-2 mb-4 appoint-history-heading-outer transaction-conatiner-div">
          {this.props.children}
          <h5 className="appoint-history-heading">
            <strong>
              <b>APPOINTMENT HISTORY</b>
            </strong>
          </h5>
        </div>
        <div className="container appoint-history-search-bar">
          <div className="row  align-items-center justify-content-between searchPatientContainer">
            <div className="position-relative searchBarPatient">
              <input
                type="text"
                placeholder="Search by patient name..."
                value={this.state.searchTerm}
                onChange={(e) =>
                  this.setState({ searchTerm: e.target.value }, () =>
                    this.getDebouncedResults(e, false, true)
                  )
                }
                ref={this.searchBarRef}
                disabled={this.state.showLoader}
                className="form-control"
              />
              {this.state.searchTerm == 0 ? (
                <i className="fa fa-search position-absolute cursor-pointer clearSearch"></i>
              ) : (
                <i
                  className={`fa fa-times position-absolute cursor-pointer clearSearch ${
                    this.state.searchTerm == 0 ? "d-none" : ""
                  }`}
                  onClick={() =>
                    this.setState(
                      {
                        searchTerm: "",
                        activeSearchTerm: "",
                      },
                      () => this.handleSearch(null, true, false)
                    )
                  }
                ></i>
              )}
            </div>

            {/* <button
              onClick={() => this.setState({ isCalendarOpen: true })}
              className="btn btn-sm bg-white ml-2 searchBarBtn"
              title={"Find appointments by date"}
            >
              <span className={this.state.filterDate ? "text-red" : ""}>
                <i className="fa fa-filter"></i>
              </span>
              {this.state.filterDate ? <sup>&nbsp;!</sup> : ""}
            </button> */}

            {/* <div className="filters  mt-3 mt-md-0"> */}
            <span className="appoint-history-filters">
              <button
                className="btn btn-sm  searchBarBtn pr-3"
                style={{ borderRight: "2px solid #ccc" }}
                onClick={() =>
                  this.setState(
                    {
                      filteringByDate: false,
                      ascendingsorted: true,
                      thisWeek: false,
                      prescription_notification_sent: "all",
                      filterDate: null,
                      search_string: "",
                    },
                    () => this.handleSearch(null, true, false)
                  )
                }
                title="Remove date filter"
              >
                <i className="fa fa-refresh"></i>
              </button>

              <button
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                className=" btn btn-sm px-3 filter-serach-bar"
                style={{ cursor: "pointer", borderRight: "2px solid #ccc" }}
              >
                <img src={FliterIcon}></img>
              </button>
              <span
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
                // style={{ margin: "-3% -19%" }}
              >
                <div
                  className="dropdown-item w-100"
                  onClick={() => this.handleDateOrder()}
                  style={{ cursor: "pointer" }}
                >
                  {this.state.sortFilter == "most" && (
                    <img
                      src={Checkbox}
                      className="transaction-checkbox-icon"
                      alt="Checkbox"
                      style={{
                        width: "18px",
                        height: "18px",
                        marginRight: "5px",
                        marginLeft: "-18px",
                      }}
                    />
                  )}
                  Most Recent First
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  className="dropdown-item w-100"
                  onClick={() => this.handleOrder()}
                >
                  {this.state.sortFilter == "least" && (
                    <img
                      src={Checkbox}
                      className="transaction-checkbox-icon"
                      alt="Checkbox"
                      style={{
                        width: "18px",
                        height: "18px",
                        marginRight: "5px",
                        marginLeft: "-18px",
                      }}
                    />
                  )}
                  Least Recent First
                </div>
              </span>

              <span className="dropdown date-filter-dropdown">
                <button
                  className="btn btn-secondary sort-serach-bar"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-filter"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                  // style={{ margin: "-91% -695%" }}
                  // style={{ transform: "translate3d(-80px, 30px, 0px)" }}
                >
                  <div
                    className="dropdown-item"
                    onClick={() =>
                      this.setState(
                        {
                          thisWeek: "week",
                          filteringByDate: true,
                          ascendingsorted: true,
                          filterDate: new Date(),
                          search_string: "",
                        },
                        (e) => this.handleSearch(e, false, true)
                      )
                    }
                  >
                    {this.state.thisWeek == "week" && (
                      <img
                        src={Checkbox}
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "5px",
                          marginLeft: "-18px",
                        }}
                        alt="Checkbox"
                      />
                    )}
                    This Week
                  </div>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() =>
                      this.setState(
                        {
                          thisWeek: "month",
                          filteringByDate: true,
                          ascendingsorted: true,
                          filterDate: new Date(),
                          search_string: "",
                        },
                        (e) => this.handleSearch(e, false, true)
                      )
                    }
                  >
                    {this.state.thisWeek == "month" && (
                      <img
                        src={Checkbox}
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "5px",
                          marginLeft: "-18px",
                        }}
                        alt="Checkbox"
                      />
                    )}
                    This Month
                  </a>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() =>
                      this.setState(
                        {
                          thisWeek: "year",
                          filteringByDate: true,
                          ascendingsorted: true,
                          filterDate: new Date(),
                          search_string: "",
                        },
                        (e) => this.handleSearch(e, false, true)
                      )
                    }
                  >
                    {this.state.thisWeek == "year" && (
                      <img
                        src={Checkbox}
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "5px",
                          marginLeft: "-18px",
                        }}
                        alt="Checkbox"
                      />
                    )}
                    This Year
                  </a>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() =>
                      this.setState(
                        {
                          prescription_notification_sent: "shared",
                          filteringByDate: true,
                          ascendingsorted: true,
                          filterDate: new Date(),
                          search_string: "",
                        },
                        (e) => this.handleSearch(e, false, true)
                      )
                    }
                  >
                    {this.state.prescription_notification_sent == "shared" && (
                      <img
                        src={Checkbox}
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "5px",
                          marginLeft: "-18px",
                        }}
                        alt="Checkbox"
                      />
                    )}
                    Prescription Shared
                  </a>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() =>
                      this.setState(
                        {
                          prescription_notification_sent: "notshared",
                          filteringByDate: true,
                          ascendingsorted: true,
                          filterDate: new Date(),
                          search_string: "",
                        },
                        (e) => this.handleSearch(e, false, true)
                      )
                    }
                  >
                    {this.state.prescription_notification_sent ==
                      "notshared" && (
                      <img
                        src={Checkbox}
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "5px",
                          marginLeft: "-18px",
                        }}
                        alt="Checkbox"
                      />
                    )}
                    Prescription Not Shared
                  </a>
                  <a
                    className="dropdown-item"
                    href="#"
                    style={{ cursor: "pointer", color: "#17BAE0" }}
                    onClick={() =>
                      this.setState(
                        {
                          filteringByDate: false,
                          thisWeek: false,
                          prescription_notification_sent: "all",
                          ascendingsorted: true,

                          filterDate: null,
                          search_string: "",
                        },
                        () => this.handleSearch(null, true, false)
                      )
                    }
                  >
                    Clear All Filters
                  </a>
                </div>
              </span>
            </span>
            {/* <span className="border-1">
                <button
                  onClick={() => this.handleDateOrder()}
                  className="btn btn-sm bg-white searchBarBtn"
                  title={
                    this.state.dateSorted
                      ? "Sort Descending date"
                      : "Sort Ascending date"
                  }
                >
                  <i className="fa fa-sort"></i>
                  <i
                    style={{ marginLeft: "10px" }}
                    className={
                      !this.state.dateSorted
                        ? "fa fa-arrow-down"
                        : "fa fa-arrow-up"
                    }
                    aria-hidden="true"
                  ></i>
                </button>
              </span> */}
            {/* </div> */}
          </div>
        </div>
        <hr className="mt-4" />
        {/* <div className="d-flex justify-content-start mx-4">
          View
          <div className="form-check form-check-inline mx-4">
            <input
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio1"
              value="all"
              checked={(this.state.view = "all")}
            />
            <label className="form-check-label ml-2" htmlFor="inlineRadio1">
              All
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio2"
              value="initial"
              checked={(this.state.view = "initial")}
            />
            <label className="form-check-label ml-2" htmlFor="inlineRadio2">
              Initial Consultation
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio3"
              value="follow"
              checked={(this.state.view = "follow")}
            />
            <label className="form-check-label ml-2" htmlFor="inlineRadio3">
              Follow Up Consultation
            </label>
          </div>
        </div> */}
        {/* <div className="row justify-content-center justify-content-sm-end">
          <div className="mr-2 searchBar">
            {!this.state.showLoader ? (
              <DatePicker
                clearIcon={null}
                value={this.state.filterDate}
                onCalendarOpen={() => this.setState({ calendarChanged: false })}
                onChange={(e) =>
                  this.setState({ filterDate: e, calendarChanged: true })
                }
                onCalendarClose={
                  this.state.calendarChanged
                    ? () =>
                        this.setState({ isCalendarOpen: false }, () =>
                          this.handleSearch(null, false, false)
                        )
                    : () => this.setState({ isCalendarOpen: false })
                }
                isOpen={this.state.isCalendarOpen}
              />
            ) : (
              " "
            )}
          </div>
        </div> */}
        {/* </div> */}
        <p className="pl-0 pl-sm-5">
          {" "}
          <span>
            <i>{this.renderDate()}</i>
          </span>
        </p>
        {/* {this.renderPagination()} */}

        <div className="container-fluid bg-light py-2">
          {this.state.showLoader ? <Loader /> : null}

          <div className="row patient-row-header align-items-center my-2 mx-0 text-uppercase d-none d-md-flex">
            <div className="col ">Patient name</div>
            <div className="col">Consultation type</div>
            <div className="col">mobile number</div>
            <div className="col">date and time</div>
            <div className="col">actions</div>
            <div className="col">prescription</div>
          </div>

          {this.state.appointments ? (
            <React.Fragment>
              {this.state.appointments.map((appointment, index) => {
                return (
                  <PreviousPatientRow
                    appointment={appointment}
                    key={index}
                    index={index}
                    viewAppointment={this.viewAppointment.bind(this)}
                    actions={{
                      editPrescriptionAction:
                        this.editPrescriptionAction.bind(this),
                      viewDocumentAction: this.viewDocumentAction.bind(this),
                      closeDrawer: this.closeDrawerAction.bind(this),
                    }}
                  />
                );

                //   <div className="row previous-patients-row  align-items-center ">
                //     <div className="col-sm-12 col-lg-2 pt-2">
                //       <h6>{appointment.patient_name}</h6>
                //       <small
                //         style={{
                //           backgroundColor: "#404760",
                //           color: "#fff",
                //           paddingLeft: "2px",
                //           paddingRight: "2px",
                //         }}
                //       >
                //         ID:{" "}
                //         {appointment.appointment_number
                //           ? appointment.appointment_number
                //           : "NA"}
                //       </small>
                //     </div>
                //     <div className="col-sm-12 col-lg-1 text-center">
                //       {appointment.is_follow_up == "True" ? (
                //         <button
                //           className="customBadge"
                //           style={{
                //             width: "120px",
                //             marginTop: "10px",
                //             marginLeft: "-15px",
                //           }}
                //         >
                //           <small style={{ fontSize: "65%" }}>FollowUp</small>
                //         </button>
                //       ) : (
                //         <button
                //           className="customBadge"
                //           style={{
                //             width: "130px",
                //             marginTop: "10px",
                //             marginLeft: "-20px",
                //           }}
                //         >
                //           <small style={{ fontSize: "65%" }}>
                //             Initial Consultation
                //           </small>
                //         </button>
                //       )}
                //     </div>

                //     <div className="col-lg-3 pt-2 text-center">
                //       <small>
                //         <FontAwesome className="mr-1 iconSmall" name="phone" />
                //         {/* {appointment.phone_no} */}
                //         +({appointment.phone_no.substring(1, 3)}){" "}
                //         {appointment.phone_no.substring(3, 6)}-
                //         {appointment.phone_no.substring(6, 9)}-
                //         {appointment.phone_no.substring(9, 13)}
                //       </small>
                //     </div>

                //     <div className="col-sm-12 col-lg-2 pt-2 text-center">
                //       <small>
                //         <FontAwesome
                //           className="mr-1 iconSmall"
                //           name="calendar"
                //         />{" "}
                //         {moment(
                //           appointment.appointment_date,
                //           "YYYY-MM-DD"
                //         ).format("DD MMM")}{" "}
                //         {moment(
                //           appointment.appointment_date,
                //           "YYYY-MM-DD"
                //         ).format("YYYY")}
                //         <br />
                //         {moment(appointment.start_time, "hh:mmZ").format(
                //           "hh:mm A"
                //         )}{" "}
                //       </small>
                //     </div>

                //     <div className="col-sm-12 col-lg-2 pt-2 text-center">
                //       {appointment.status == "2" ? (
                //         <button
                //           style={{
                //             backgroundColor: "#f969a4",
                //             color: "#fff",
                //             border: "none",
                //           }}
                //         >
                //           Cancelled
                //         </button>
                //       ) : (
                //         <span>
                //           <Link to={"/prescription/" + appointment.id}>
                //             <button className="customButton pl-3 pr-3">
                //               <small>
                //                 <FontAwesome className="mr-1" name="eye" />
                //                 Prescription
                //               </small>
                //             </button>
                //           </Link>
                //           <Link to={"/document/" + appointment.id}>
                //             <button className="customButton mt-1 ml-1 ">
                //               <small>
                //                 <i className="fa fa-paperclip"></i>
                //               </small>
                //             </button>
                //           </Link>
                //         </span>
                //       )}
                //     </div>

                //     <div className="col-sm-12 col-lg-2 pt-2 text-center">
                //       {appointment.status == "2" ? null : (
                //         <button
                //           className="customButton pl-3 pr-3"
                //           onClick={this.viewAppointment.bind(this, appointment)}
                //         >
                //           <small>
                //             <FontAwesome className="mr-1" name="eye" />
                //             Details
                //           </small>
                //         </button>
                //       )}
                //     </div>
                //   </div>
                // );
              })}
            </React.Fragment>
          ) : (
            <span>
              {this.state.showLoader == false ? (
                <div className="row upcomingList p-2 mt-2 text-center">
                  <div
                    className="col-sm-12 col-lg-12 pt-1"
                    style={{ fontSize: "12px" }}
                  >
                    No Records Found
                  </div>
                </div>
              ) : null}
            </span>
          )}

          <Modal show={this.state.openView} onHide={this.closeAppointment}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4>Appointment Details</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <small>
                {this.state.patient ? (
                  <div className="row">
                    <div className="col-sm-12 col-lg-12 text-center">
                      <h3>
                        <FontAwesome
                          className="mr-1 iconSmall"
                          name="calendar"
                        />
                        <br />
                        <b>
                          {" "}
                          {moment(
                            this.state.patient.appointment_date,
                            "YYYY-MM-DD"
                          ).format("DD MMM")}{" "}
                          {moment(
                            this.state.patient.appointment_date,
                            "YYYY-MM-DD"
                          ).format("YYYY")}
                        </b>
                      </h3>
                      <h5>
                        {Number(
                          this.state.patient.start_time.substring(0, 2)
                        ) <= 12
                          ? Number(
                              this.state.patient.start_time.substring(0, 2)
                            ) +
                            "" +
                            this.state.patient.start_time.substring(2, 5)
                          : Number(
                              this.state.patient.start_time.substring(0, 2)
                            ) -
                            12 +
                            "" +
                            this.state.patient.start_time.substring(2, 5)}
                        {Number(this.state.patient.start_time.substring(0, 2)) <
                        12
                          ? " AM"
                          : " PM"}
                      </h5>
                    </div>
                    <div className="col-sm-12 col-lg-10 offset-lg-1 mt-2">
                      <div className="row" style={{ fontSize: "15px" }}>
                        <div className="col-6 col-sm-6 col-lg-6 labelPatient pt-2">
                          Patient Name:
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6 pt-2">
                          {this.state.patient.patient_name}
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6 labelPatient pt-2">
                          DOB:
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6 pt-2">
                          {moment(new Date()).format("YYYY") -
                            moment(this.state.patient.patient_dob).format(
                              "YYYY"
                            ) ===
                          0
                            ? "Less than 1 year"
                            : moment(this.state.patient.patient_dob).format(
                                "DD MMM YYYY"
                              )}
                          {/* {moment(this.state.patient.patient_dob).format(
                              "DD-MM-YYYY"
                            )} */}
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6 labelPatient pt-2">
                          Gender:
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6 pt-2">
                          {this.state.patient.patient_gender}
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6 labelPatient pt-2">
                          Mobile Number:
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6 pt-2">
                          +({this.state.patient.phone_no.substring(1, 3)}){" "}
                          {this.state.patient.phone_no.substring(3, 6)}-
                          {this.state.patient.phone_no.substring(6, 9)}-
                          {this.state.patient.phone_no.substring(9, 13)}
                        </div>

                        <br />

                        <div className="col-6 col-sm-6 col-lg-6 labelPatient pt-3">
                          Purpose of consultation:
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6 pt-3">
                          {this.state.patient.purpose_of_appointment}
                        </div>

                        <div className="col-6 col-sm-6 col-lg-6 labelPatient pt-3">
                          Attachments:
                        </div>
                        {this.state.patient.patient_prescription_url &&
                        this.state.patient.patient_prescription_url.lenght >
                          0 ? (
                          <div className="col-6 col-sm-6 col-lg-12 pt-1 pb-3">
                            <span>
                              {this.state.patient.patient_prescription_url.map(
                                (each_pres, index) => {
                                  return (
                                    <span>
                                      {each_pres ? (
                                        <div className="mb-2">
                                          Medical Record {index + 1}{" "}
                                          <a
                                            target="_blank"
                                            href={each_pres}
                                            className="mr-2 btn btn-info btn-sm downloadButton"
                                          >
                                            Download
                                          </a>
                                        </div>
                                      ) : (
                                        <span>--</span>
                                      )}
                                    </span>
                                  );
                                }
                              )}
                            </span>
                          </div>
                        ) : (
                          <div className="col-6 col-sm-6 col-lg-6 pt-3 pb-3">
                            NA
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <p className="m-5 m p-5">Loading..</p>
                )}
              </small>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="dark" onClick={this.closeAppointment}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* {this.renderPagination()} */}
        </div>
        <Drawer
          anchor={"right"}
          open={this.state.drawerOpen}
          onClose={this.toggleDrawer.bind(this)}
        >
          <div className="py-5 previous-patients-prescription-edit-drawer px-0 px-md-2">
            <h2 className="px-2">
              {this.state.drawerType}
              <span
                className="float-right cursor-pointer"
                onClick={this.toggleDrawer.bind(this)}
                style={{ fontSize: "1rem" }}
              >
                Close <i className="fa fa-times" aria-hidden="true"></i>
              </span>
            </h2>
            {this.state.drawerType === "View Documents" ? (
              <DocumentDoctor
                appointmentData={this.state.appointmentForDrawer}
                documentShow={this.state.documentShow}
                actions={{
                  viewDocumentAction: this.viewDocument.bind(this),
                }}
              >
                {this.renderDocument("98%")}
              </DocumentDoctor>
            ) : this.state.drawerType === "Edit Prescription" ? (
              <PrescriptionComponent
                appointmentData={this.state.appointmentForDrawer}
                age={this.state.ageForDrawer}
                fetchappointment={() => this.fetchAppointments()}
                closeDrawer={() => {
                  this.closeDrawerAction();
                }}
                setDrowerPostionInitianlView={() => {}}
              />
            ) : (
              ""
            )}
          </div>
        </Drawer>
        {/* {this.state.fullView && this.renderDocument()} */}
      </React.Fragment>
    );
  }
}

export default withRouter(PreviousPatients);
