import React from "react";
import classes from "./MultipleImageSelector.module.css";
import { Image } from "react-bootstrap";

const PreviewImages = ({ image, id, removeClickHandler }) => {
  return (
    <div className={classes.previewedImageRoot}>
      <Image src={image.value} className={classes.previewedImage} />
      <i
        className={"fa fa-times " + classes.crossedIcon}
        onClick={() => removeClickHandler(id)}
      />
    </div>
  );
};

export default PreviewImages;
