import React from "react";
import { useMutation } from "react-query";
import beforeLogin from "../Util/API Calls/beforeLogin";

const handleReveverLogin = (payload) => {
  return beforeLogin
    .post(`public/receiver-OTP-generation`, {
      ...payload,
      receiver_phone_no: `+91${payload.receiver_phone_no}`,
    })
    .then((res) => res.data);
};

const handleReceiverOtpValidation = (payload) => {
  return beforeLogin
    .post(`auth/receiver-OTP-validation`, payload)
    .then((res) => res.data);
};

const generateReceiverToken = (payload) => {
  return beforeLogin
    .post(`receiver/get-receiver-consultation-token`, payload, {
      headers: {
        Authorization: localStorage.getItem("authorization_token"),
      },
    })
    .then((res) => res.data);
};

const UseReceiver = () => {};

const useReceiverLogin = () => {
  return useMutation([], handleReveverLogin);
};

const useVerifyReceiverOtp = () => {
  return useMutation([], handleReceiverOtpValidation);
};

const useGenerateReceiverToken = () => {
  return useMutation([], generateReceiverToken);
};

export {
  UseReceiver,
  useReceiverLogin,
  useVerifyReceiverOtp,
  useGenerateReceiverToken,
};
