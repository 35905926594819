import React, { useEffect, useState } from "react";
import "../globalStyles/prescriptionpdf.css";
import "../globalStyles/login.css";
import afterLoginApiAxios from "../Util/API Calls/afterLogin";
import { doctorFiles } from "../Util/API Calls/APIList";
import swal from "sweetalert";
import Loader from "./UI Component/Loader/Loader";
import moment from "moment";
import PrintIcon from "../assets/print_icon.svg";
import PdfViewer from "./UI Component/DocumentViewer/PdfViewer";
import axios from "axios";

const Prescription = (props) => {
  const appointment_id = props.appointment_id;
  const from_dr_video = props.from_dr_video;
  const [loader, setLoader] = useState(false);
  const [downloadingState, setDownloadingState] = useState(false);
  const from_patient = props.from_patient;

  const [fileDetails, setFileDetails] = useState({
    fileURL: "",
    fileName: "Prescription",
    fileType: ".pdf",
  });

  const prescription_id = props.prescription_id;

  const getPrescriptionPreview = () => {
    setLoader(true);
    let params = {
      appointment_id: appointment_id,
      prescription_id: props?.prescription_id,
      "authorizer-principal-id": {
        user_id: localStorage.getItem("id_token"),
        user_type: "2",
        agent: "web",
      },
      is_shared: props?.isShared,
    };

    afterLoginApiAxios
      .post("/api/v1/user/prescription/pdf", params, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("id_token"),
        },
      })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          openPdfViewer(response?.data?.url);
        } else {
          setLoader(false);
          swal({
            title: "Sorry",
            text: "Prescription could not be downloaded",
            icon: "warning",
          });
        }
      });
  };

  const openPdfViewer = (url) => {
    const presDate = moment().format("DD MMM YYYY");
    fileDetails.fileURL = url;
    fileDetails.fileName = `Prescription_${presDate}.pdf`;
    setFileDetails(fileDetails);
    setLoader(false);
  };

  useEffect(() => {
    if (prescription_id) {
      getPrescriptionPreview();
    }
  }, [prescription_id]);

  const downloadURI = async (url, name, fileType, type) => {
    const blob = await axios.get(url, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
      responseType: "blob",
    });
    const a = document.createElement("a");
    const href = window.URL.createObjectURL(blob.data);
    a.href = href;
    a.download = name + "." + fileType.trim();

    a.click();
  };

  const ImagetoPrint = (source) => {
    return (
      "<html><head><scri" +
      "pt>function step1(){\n" +
      "setTimeout('step2()', 10);}\n" +
      "function step2(){window.print();window.close()}\n" +
      "</scri" +
      "pt></head><body onload='step1()'>\n" +
      "<img src='" +
      source +
      "' /></body></html>"
    );
  };

  const printFile = (url, fileType) => {
    if (fileType.toLowerCase().includes("pdf")) {
      window.open(url, "PRINT", "height=400,width=600");
    }
    if (
      fileType.toLowerCase().includes("jpg") ||
      fileType.toLowerCase().includes("png") ||
      fileType.toLowerCase().includes("jpeg")
    ) {
      var Pagelink = "about:blank";
      var pwa = window.open(Pagelink, "_new");
      pwa.document.open();
      pwa.document.write(ImagetoPrint(url));
      pwa.document.close();
    }
  };

  return (
    <div className="">
      {loader ? <Loader /> : null}
      <div
        className=" container-fluid bodyStyle watermark-section container_scroll"
        // style={{overflowY: "scroll", height: "calc(100vh - 170px)"}}
      >
        <div className="container">
          {!from_dr_video ? (
            <div className="download_pdf_btn row justify-content-end">
              <div className="d-flex">
                <button
                  className="otp-btn mb-2"
                  style={{ width: "150px" }}
                  onClick={() =>
                    downloadURI(
                      fileDetails?.fileURL,
                      fileDetails?.fileName,
                      fileDetails?.fileType
                    )
                  }
                  disabled={downloadingState}
                >
                  GENERATE PDF
                </button>
                {from_patient ? (
                  <button
                    className="otp-btn mb-2 ml-3"
                    style={{
                      width: "45px",
                      height: "45px",
                      borderRadius: "50%",
                    }}
                    onClick={() => printFile(fileDetails?.fileURL, "pdf")}
                  >
                    <img src={PrintIcon} alt="" />
                  </button>
                ) : null}
              </div>
            </div>
          ) : null}
          {fileDetails?.fileURL !== "" && (
            <PdfViewer
              url={fileDetails?.fileURL}
              fileType={fileDetails?.fileType}
              preview_from={props?.preview_from}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Prescription;
