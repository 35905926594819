import React, { useState } from "react";
import "./style.scss";
import TickMark from "../../assets/check-pink.svg";

const DelayTimePicker = (props) => {
  const {
    inputLabel,
    timeError,
    hour,
    min,
    meridiem,
    disabled,
    handleHr,
    handleMin,
    handleMeridiem,
    doctor_schedule_id,
    name,
    delay_schedule_id,
    status,
    error_message,
  } = props;
  // const [meridiem, setMeridiem] = useState("AM");

  // const handleMeridiem = (e) => {
  //   setMeridiem(e.target.value);
  // };

  return (
    <div>
      <div className="d-flex justify-content-between mb-1">
        <div className="delay-input-label">{inputLabel}</div>
        {name === "start_time" && delay_schedule_id && status == "1" && (
          <div className="d-sm-flex d-lg-none d-flex align-items-center">
            <img
              src={TickMark}
              alt=""
              style={{ width: "15px", height: "15px" }}
            />
          </div>
        )}
      </div>
      <div
        className={`delay-input-container ${
          timeError ? "input-container-error" : ""
        }`}
      >
        <input
          className={`delay-input-field ${
            timeError ? "input-field-error" : ""
          }`}
          type={"number"}
          // onError={true}
          value={hour}
          onChange={(e) => handleHr(e, doctor_schedule_id)}
          name={name}
          autoComplete={"off"}
          onKeyDown={(e) =>
            (e.key?.toLowerCase() === "e" ||
              e.key === "+" ||
              e.key === "-" ||
              e.key === ".") &&
            e.preventDefault()
          }
          disabled={disabled}
        />
        <input
          className={`delay-input-field ${
            timeError ? "input-field-error" : ""
          }`}
          type={"number"}
          onError={true}
          value={min}
          name={name}
          onChange={(e) => handleMin(e, doctor_schedule_id)}
          autoComplete={"off"}
          onKeyDown={(e) =>
            (e.key?.toLowerCase() === "e" ||
              e.key === "+" ||
              e.key === "-" ||
              e.key === ".") &&
            e.preventDefault()
          }
          disabled={disabled}
        />
        <select
          value={meridiem}
          onChange={(e) => handleMeridiem(e, doctor_schedule_id)}
          className="delay-meridiem"
          name={name}
          disabled={disabled}
        >
          <option value="AM">AM</option>
          <option value="PM">PM</option>
        </select>
      </div>
      {timeError && (
        <div className="delay-error-message">
          {error_message ? error_message : "Please enter a valid time"}
        </div>
      )}
    </div>
  );
};

export default DelayTimePicker;
