import {
  ADD_CHIEF_COMPLAINT,
  UPDATE_CHIEF_COMPLAINT,
  TEST,
  TEST_FAILED,
  TEST_SUCCESS,
  ADD_DIAGNOSIS_ITEM,
  UPDATE_DIAGNOSIS_ITEM,
  UPDATE_PATIENT_DATA,
  UPDATE_DRUG_ITEM_DATA,
  CLEAR_DRUG_ITEM_DATA,
  ADD_NEW_DRUG_ITEM,
  UPDATE_EXAM_ITEM_DATA,
  CLEAR_EXAM_ITEM_DATA,
  ADD_NEW_EXAM_ITEM,
  PESCRIPTION_FORM_PROPERTY_UPDATE,
  DOCTOR_NOTES_UPDATE,
  SET_USER_TOKEN,
  SET_USER_TYPE,
  SAVE_FORM,
  PREVIEW_FORM,
  SET_APPOINTMENT_DATA,
  SHARE_FORM,
  INITALIZE_AUTOSAVE,
  LOAD_LAST_AUTOSAVED,
  RESET_STATE,
  HIDE_PREVIEW_FORM,
  SET_NEW_DATE,
  DELETE_FOLLOW_UP_DATE,
  SHOW_PRESCRIPTION_MODAL,
  HIDE_PRESCRIPTION_MODAL,
  FETCH_PRESCRIPTION_DETAILS,
  REMOVE_DROP_DOWN_OPTION,
  DELETE_COMFORMATION_MODAL_SHOW,
  DELETE_COMFORMATION_MODAL_CANCEL,
  DELETE_COMFORMATION_MODAL_SUCCESS,
  REMOVE_DRUGITEM,
  REMOVE_EXAMINITEM,
  GET_PREVIOUS_PRESCRIPTION,
  PRESCRIPTION_TEMPLATE,
  GET_SELECTED_TEMPLATE,
  FETCH_DRUG_ON_SEARCH,
  CHECK_MANUAL_RX_SHARED,
  UPDATE_PATIENT_VITALS,
  GET_VITALS,
} from "./constants";

/**
 * Changes the input field of the form
 *
 * @param  {string} username The new text of the input field
 *
 * @return {object} An action object with a type of CHANGE_USERNAME
 */
export function test() {
  return {
    type: TEST,
  };
}
export function testFailed(payload) {
  return {
    type: TEST_FAILED,
    payload,
  };
}

export function testSuccess(payload) {
  return {
    type: TEST_SUCCESS,
    payload,
  };
}

export function addChiefComplaint() {
  return {
    type: ADD_CHIEF_COMPLAINT,
  };
}

export function updateChiefComplaint(value, index) {
  return {
    type: UPDATE_CHIEF_COMPLAINT,
    payload: { value, index },
  };
}

export function addDiagnosisItem() {
  return {
    type: ADD_DIAGNOSIS_ITEM,
  };
}

export function updateDiagnosisItem(value, index) {
  return {
    type: UPDATE_DIAGNOSIS_ITEM,
    payload: { value, index },
  };
}

export function updatePatientData(event) {
  return {
    type: UPDATE_PATIENT_DATA,
    payload: { value: event.target.value, name: event.target.name },
  };
}
export function updatePatientVitals(value, name, unit) {
  return {
    type: UPDATE_PATIENT_VITALS,
    payload: {
      value: value,
      name: name,
      unit: unit,
    },
  };
}
export function updateDrugItemData(event, index, drug_id) {
  return {
    type: UPDATE_DRUG_ITEM_DATA,
    payload: {
      value: event.target.value,
      name: event.target.name,
      index,
      drug_id,
    },
  };
}

export function clearDrugItemData(index) {
  return {
    type: CLEAR_DRUG_ITEM_DATA,
    payload: { index },
  };
}

export function addNewDrugItem() {
  return {
    type: ADD_NEW_DRUG_ITEM,
  };
}

export function updateExamItemData(event, index) {
  return {
    type: UPDATE_EXAM_ITEM_DATA,
    payload: { value: event.target.value, name: event.target.name, index },
  };
}

export function clearExamItemData(index) {
  return {
    type: CLEAR_EXAM_ITEM_DATA,
    payload: { index },
  };
}

export function addNewExamItem() {
  return {
    type: ADD_NEW_EXAM_ITEM,
  };
}
export function hidePreviewform() {
  return {
    type: HIDE_PREVIEW_FORM,
  };
}

export function prescriptionFormPropertyUpdate(event) {
  return {
    type: PESCRIPTION_FORM_PROPERTY_UPDATE,
    payload: { value: event.target.value, name: event.target.name },
  };
}

export function doctorNotesUpdate(event) {
  return {
    type: DOCTOR_NOTES_UPDATE,
    payload: { value: event.target.value, name: event.target.name },
  };
}

export function shareButtonClicked() {
  return {
    type: SHARE_FORM,
  };
}

export function setUserToken(payload) {
  return {
    type: SET_USER_TOKEN,
    payload,
  };
}
export function setUserType(payload) {
  return {
    type: SET_USER_TYPE,
    payload,
  };
}

export function setAppointmentData(payload) {
  return {
    type: SET_APPOINTMENT_DATA,
    payload,
  };
}

export function saveForm() {
  return {
    type: SAVE_FORM,
    payload: "regularSave",
  };
}

export function previewForm() {
  return {
    type: PREVIEW_FORM,
  };
}
export function getPatientVitals(id) {
  return {
    type: GET_VITALS,
    payload: id,
  };
}
export function initializeAutosave() {
  return {
    type: INITALIZE_AUTOSAVE,
  };
}

export function loadLastAutoSavedState(payload) {
  return {
    type: LOAD_LAST_AUTOSAVED,
    payload,
  };
}

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export function setNewDate(payload) {
  return {
    type: SET_NEW_DATE,
    payload,
  };
}

export function deleteFollowUpDate() {
  return {
    type: DELETE_FOLLOW_UP_DATE,
  };
}

export function showModal() {
  return {
    type: SHOW_PRESCRIPTION_MODAL,
  };
}

export function hideModal() {
  return {
    type: HIDE_PRESCRIPTION_MODAL,
  };
}

export function fetchDropDownMenu(value, isShowDropDown) {
  return {
    type: FETCH_PRESCRIPTION_DETAILS,
    payload: { value, isShowDropDown },
  };
}

export function removeDropDownMenu() {
  return {
    type: REMOVE_DROP_DOWN_OPTION,
  };
}

export function deletedComformation(payload) {
  return {
    type: DELETE_COMFORMATION_MODAL_SHOW,
    payload,
  };
}

export function deletedComformationCancel() {
  return {
    type: DELETE_COMFORMATION_MODAL_CANCEL,
  };
}

export function deletedComformationSuccess(payload) {
  return {
    type: DELETE_COMFORMATION_MODAL_SUCCESS,
    payload,
  };
}
//guys, I've implemented the feature, already, but feel free to look
// you couldn't see the changes, so I thought I'd directly implement it
// lol / sorry

export function removeDrugItem(index) {
  return {
    type: REMOVE_DRUGITEM,
    payload: { index },
  };
}

export function removeExaminItem(index) {
  return {
    type: REMOVE_EXAMINITEM,
    payload: { index },
  };
}

export function previousPrescription(payload) {
  return {
    type: GET_PREVIOUS_PRESCRIPTION,
    payload,
  };
}

export function prescriptionTemplate(payload) {
  //event
  return {
    type: PRESCRIPTION_TEMPLATE,
    payload: { value: payload.value, name: payload.name }, //{ value: event.target.value, name: event.target.name },
  };
}

export function getSelectedTemplate(payload) {
  return {
    type: GET_SELECTED_TEMPLATE,
    payload,
  };
}

export function fetchDrugDropdown(value, isShowDropDown) {
  return {
    type: FETCH_DRUG_ON_SEARCH,
    payload: { value, isShowDropDown },
  };
}

export function checkManualRxShared(payload) {
  return {
    type: CHECK_MANUAL_RX_SHARED,
    payload,
  };
}
