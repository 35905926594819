import React from "react";
import "./LoginWithPasswordOtp.scss";
import { useLocation } from "react-router-dom";

const LoginWithPasswordOtp = ({
  loginpasswordCallBack,
  loginOtpCallBack,
  passwordPath,
  otpPath,
}) => {
  const location = useLocation();
  return (
    <div className="login-password-and-otp">
      <div
        className={`login-password-btn ${
          location.pathname === passwordPath ? "login-header-selected" : ""
        }`}
        onClick={loginpasswordCallBack}
      >
        Login with Password
      </div>
      <div
        className={`login-with-otp-btn  ${
          location.pathname === otpPath ? "login-header-selected" : ""
        }`}
        onClick={loginOtpCallBack}
      >
        Login with OTP
      </div>
    </div>
  );
};

export default LoginWithPasswordOtp;
