import React, { useState, useEffect } from "react";
import logo from "../../assets/susess-animation.gif";
export const ThankYouPage = ({ text, flag }) => {
  const [timer, setTimer] = useState(true);

  useEffect(() => {
    const interval = setTimeout(() => {
      flag && setTimer(false);
    }, 2800);
    return () => clearTimeout(interval);
  }, []);

  return (
    <div className="thank-you-page">
      {timer && flag ? (
        <img width="136px" height="136px" src={logo} alt="" />
      ) : (
        <div className="feedback-circle">
          <div></div>
        </div>
      )}
      <p style={{ marginTop: timer ? "60px" : "64px" }}>{text}</p>
    </div>
  );
};
