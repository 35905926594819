// Dependies import
import React, { useState, useRef, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import swal from "sweetalert";

//assest and scss import
import "./doctorSignInPasswordContainer.scss";

//project files import
import PhoneNumberInputWithAction from "../../common/PhoneNumberInputWithAction";
import ActionButton from "../../common/Buttons/ActionButton";
import PasswordInputWithHeader from "../../common/PasswordInputWithHeader";
import SignInFormLayout from "../../common/SignInFormLayout";
import ErrorContainer from "../../common/ErrorContainer";
import { LOCALSTORAGEKEY } from "../../../Util/Helper/localStorageUtils";
import {
  DOCTOR_LOGIN_WITH_PASSWORD_OR_OTP,
  SEND_OTP,
} from "../DoctorSignInAPIHandler/apiConstant";
import beforeLoginAxios from "../../../Util/API Calls/beforeLogin";
import { moveToNextPage } from "../../common/DeepLink";
import {
  setCookieForUser,
  setDynamicCookieForUser,
} from "../../../Util/API Calls/cookieHelper";
import { C2CConsole } from "../../../Util/Helper/C2CConsole";
import afterLoginApiAxios from "../../../Util/API Calls/afterLogin";
import StaffLoginErrorModal from "../../Staff/StaffLoginErrorModal";
import Loader from "../../UI Component/Loader/Loader";
import { deleteDB } from "../../common/dexieDB";
import TermsSignedInForgotPassword from "../../TermsSignedInForgotPassword";
import DownloadAppAndCallContactUI from "../../DownloadAppAndCallContact";
import LoginWithPasswordOtp from "../../LoginWithPasswordOtp";
import { c2cBeforeLoginPostApiHandler } from "../../../Util/c2cBeforeLoginApiHandler";

// const import

function DoctorSignInPasswordContainer(props) {
  const { phoneNumber, setPhoneNumber, loader } = props;
  const [password, setPassword] = useState("");
  const [staySignIn, setStaySignIn] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [hasPasswordError, setHasPasswordError] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [disableActionButton, setDisableActionButton] = useState(false);
  let history = useHistory();
  let location = useLocation();
  const stayLogin = useState(false)[0];
  const [cookies, setCookie] = useCookies(["cookie-name"]);
  const setIsStaff = useState(false)[1];
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showErrorCard, setShowErrorCard] = useState(false);
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const inputRef = useRef();
  // life cycle methods ==>

  useEffect(() => {
    if (mobileNumber?.length < 10 || !password) {
      setDisableActionButton(true);
    } else {
      setDisableActionButton(false);
    }
  }, [password, mobileNumber]);

  useEffect(() => {
    if (phoneNumber) {
      setMobileNumber(phoneNumber);
    }
  }, [phoneNumber]);

  useEffect(() => {
    // Update the document title using the browser API
    let userType = window.localStorage.getItem("user_type");
    let tokenId = localStorage.getItem(LOCALSTORAGEKEY.ID_TOKEN);
    if (
      userType !== undefined &&
      userType === "doctor" &&
      tokenId &&
      !location?.state?.redirectTo?.includes("clinic_feedback_questions")
    ) {
      let doctor_clinic_association = localStorage.getItem(
        "doctor_clinic_association"
      );
      if (doctor_clinic_association && doctor_clinic_association === "false") {
        window.open(
          `${window.location.origin}/${userType}/account/dashboard`,
          "_self"
        );
      } else {
        window.open(
          `${window.location.origin}/clinic/account/dashboard`,
          "_self"
        );
      }
    }
  });

  const getBrowserName = () => {
    // Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== "undefined";

    // Safari 3.0+ "[object HTMLElementConstructor]"
    var isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && window["safari"].pushNotification)
      );

    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;

    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1 - 79
    var isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    // Edge (based on chromium) detection
    var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1;

    // Blink engine detection
    var isBlink = isChrome && !!window.CSS;

    if (isFirefox) return "FireFox";
    if (isSafari) return "Safari";
    if (isIE && !isEdge) return "IE";
    if (isEdge) return "Edge";
    if (isChrome) return "Chrome";
    if (isEdgeChromium) return "EdgeChromium";
    if (isBlink) return "Blink-based";
  };

  const registerAnalytics = () => {
    afterLoginApiAxios
      .post(
        "user/engagement-analytics-data",
        {
          usecase: "login",
          user_type: "1",
          device_details: {
            device_type: "desktop",
            device_manufacturer: "",
            os_version: window.navigator.userAgent.substring(
              window.navigator.userAgent.indexOf("(") + 1,
              window.navigator.userAgent.indexOf(")")
            ),
            os_name: window.navigator.userAgent.substring(
              window.navigator.userAgent.indexOf("(") + 1,
              window.navigator.userAgent.indexOf(")")
            ),
            application_type: "web",
            application_version: "1.0",
            browser_name: getBrowserName(),
            browser_version: window.navigator.userAgent.substring(
              window.navigator.userAgent.lastIndexOf(")") + 1
            ),
          },
          doctor_login_data: {
            doctor_id: localStorage.getItem(LOCALSTORAGEKEY.USER_ID),
            doctor_status: "registered",
          },
        },
        {
          headers: {
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .catch((err) => C2CConsole.error(err));
  };
  //methods ==>

  const handleTermsAndCondition = () => {
    setStaySignIn(!staySignIn);
  };

  const handlePhoneInputChange = (value) => {
    if (value.length === 10) {
      inputRef.current.focus();
    }
    setMobileNumber(value);
    setPhoneNumber(value);
  };

  const handlePasswordInputChange = (value) => {
    setPassword(value);
  };
  const handleKeydown = (e) => {
    if (e.key !== "Backspace" && e.key !== "Delete") {
      if (e.key === "Enter" && !disableActionButton) {
        handleContinue();
      }
      if (mobileNumber?.length >= 10) {
        inputRef.current.focus();
      }
    }
  };

  const handleContinue = () => {
    deleteDB();
    setHasPasswordError(false);
    checkIsStaffAPICall();
  };

  const handleSubmitAPICall = () => {
    let payload = {
      channel_id: "doctor-login",
      login_type: "password",
      phone_no: "+91" + phoneNumber,
      user_type: "doctor",
    };
    const sendOTPResponse = c2cBeforeLoginPostApiHandler(SEND_OTP, payload);
    sendOTPResponse.then(([response, error]) => {
      if (!error) {
        if (response.data && response.data.statusCode === 200) {
          localStorage.setItem("is_partial_doctor", "False");
          setShowErrorCard(false);
          if (response.data.password_set === 1) {
            handleLoginAPICall();
          } else {
            history.replace("/doctor/login/set-password");
          }
        } else if (response.data && response.data.statusCode === 500) {
          partialDoctorLogin();
        } else if (response.data && response.data.statusCode === 501) {
          setShowButtonLoader(false);
          swal({
            title: "Invalid Phone Number Entered",
            type: "info",
          });
        } else {
          setShowButtonLoader(false);
          swal({
            title: "Something Went Wrong!",
            type: "info",
          });
        }
      } else {
        setShowButtonLoader(false);
      }
    });
  };

  const partialDoctorLogin = () => {
    localStorage.setItem("is_partial_doctor", "True");
    beforeLoginAxios
      .post("public/send-onboard-doctor-OTP", {
        phone_no: `+91${phoneNumber}`,
        channel_id: "doctor-login",
        login_type: "password",
      })
      .then((response) => {
        if (response) {
          if (response.data && response.data.statusCode === 200) {
            if (response.data.onboard_application_id === "NULL") {
              //swal doctor ot found
              setShowErrorCard(true);
              setShowButtonLoader(false);
            } else if (response.data.password_set === 0) {
              setShowButtonLoader(false);
            } else if (response.data.password_set === 1) {
              handleLoginPartialDoctor();
            } else {
              setShowButtonLoader(false);
              swal({
                title: "Something Went Wrong",
                text: "You are not registered on Connect2Clinic. But Something went wrong while attempting to register you. Please reach out to the customer support team, to register yourself",
                type: "info",
              });
            }
          } else {
            setShowButtonLoader(false);
          }
        } else {
          setShowButtonLoader(false);
        }
      })
      .catch(function (error) {
        C2CConsole.log("err", error);
      });
  };

  const handleLoginAPICall = () => {
    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
      setHasPasswordError(true);
      setShowButtonLoader(false);
      return;
    }
    setHasPasswordError(false);
    let payload = {
      channel_id: "doctor-login",
      phone_no: "+91" + mobileNumber,
      user_type: "doctor",
      password: password,
      stay_login: staySignIn,
      source: "web",
    };

    const doctorLoginResponse = c2cBeforeLoginPostApiHandler(
      DOCTOR_LOGIN_WITH_PASSWORD_OR_OTP,
      payload
    );
    doctorLoginResponse.then(([response, error]) => {
      if (!error) {
        if (response?.data?.statusCode == 200) {
          setCookieForUser(response.data.user.id_token, setCookie);
          setDynamicCookieForUser(
            LOCALSTORAGEKEY.USER_ID,
            response.data.user.user_id,
            setCookie
          );
          setDynamicCookieForUser(
            "user_type",
            response.data.user.user_type,
            setCookie
          );
          setDynamicCookieForUser(
            "full_name",
            response.data.user.full_name,
            setCookie
          );
          setDynamicCookieForUser(
            "specialization",
            response?.data?.specialization_id,
            setCookie
          );
          setDynamicCookieForUser(
            "last_login_date",
            response.data.user.last_login_date,
            setCookie
          );
          setDynamicCookieForUser(
            "user_name",
            response.data.user.user_name,
            setCookie
          );
          setDynamicCookieForUser(
            "doctor_role_name",
            response.data.user.doctor_role_name,
            setCookie
          );
          setDynamicCookieForUser(
            "doctor_clinic_association",
            response.data.doctor_clinic_association,
            setCookie
          );
          setDynamicCookieForUser(
            "doctorPhone",
            `+91${phoneNumber}`,
            setCookie
          );
          setDynamicCookieForUser("stay_login", stayLogin, setCookie);
          localStorage.setItem("id_token", response.data.user.id_token);
          localStorage.setItem(
            LOCALSTORAGEKEY.USER_ID,
            response.data.user.user_id
          );
          localStorage.setItem("user_type", response.data.user.user_type);
          localStorage.setItem("user_name", response.data.user.user_name);
          localStorage.setItem("full_name", response.data.user.full_name);
          localStorage.setItem(
            "doctor_role_name",
            response.data.user.doctor_role_name
          );
          localStorage.setItem("doctorPhone", `+91${phoneNumber}`);
          localStorage.setItem("stay-login", stayLogin);
          localStorage.setItem(
            LOCALSTORAGEKEY.DOCTOR_CLINIC_ASSOCIATION,
            response?.data?.doctor_clinic_association
          );
          localStorage.setItem(
            "last_login_date",
            response.data.user.last_login_date
          );
          localStorage.setItem(
            "specialization",
            response?.data?.specialization_id
          );
          registerAnalytics();

          setTimeout(() => {
            if (localStorage.getItem("id_token")) {
              if (localStorage.getItem("cid")) {
                history.push(
                  `/doctor/instant_consultation?consultation_id=${localStorage.getItem(
                    "cid"
                  )}`
                );
              } else {
                if (response?.data?.doctor_clinic_association === true) {
                  if (
                    cookies?.lastPathBeforeLogin &&
                    cookies?.lastPathBeforeLogin == "/doctor/account/webinars"
                  ) {
                    history.push("/clinic/account/webinars");
                  } else {
                    moveToNextPage("clinic", history, cookies);
                  }
                } else {
                  moveToNextPage("doctor", history, cookies);
                }
              }
            }
          }, 10);
        } else if (response?.data?.statusCode == 510) {
          setPasswordError("Incorrect password entered");
          setHasPasswordError(true);
          setShowButtonLoader(false);
        } else {
          setShowButtonLoader(false);
        }
      } else {
        setShowButtonLoader(false);
      }
    });
  };

  const handleLoginPartialDoctor = () => {
    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
      setHasPasswordError(true);
      setShowButtonLoader(false);
      return;
    } else {
      setHasPasswordError(false);
    }

    beforeLoginAxios
      .post("doctor/webinar-login", {
        phone_no: `+91${phoneNumber}`,
        password: password,
      })
      .then((response) => {
        if (response) {
          if (response?.data?.statusCode == 200) {
            localStorage.setItem("id_token", response.data.user.id_token);
            localStorage.setItem(
              LOCALSTORAGEKEY.USER_ID,
              response.data.user.user_id
            );
            localStorage.setItem("user_type", response.data.user.user_type);
            setCookieForUser(response.data.user.id_token, setCookie);
            setDynamicCookieForUser(
              LOCALSTORAGEKEY.USER_ID,
              response.data.user.user_id,
              setCookie
            );
            setDynamicCookieForUser(
              "user_type",
              response.data.user.user_type,
              setCookie
            );
            localStorage.setItem("doctorPhone", `+91${phoneNumber}`);
            localStorage.setItem("onBoardedDoctor", true);
            localStorage.setItem(
              "specialization",
              response?.data?.specialization_id
            );
            setTimeout(() => {
              if (localStorage.getItem("id_token")) {
                history.replace(
                  "/doctor/account/dashboard?utm_source=webapp&utm_medium=onboarding"
                );
              } else {
                setShowButtonLoader(false);
              }
            }, 100);
          } else if (response.data.statusCode == 510) {
            setPasswordError("Incorrect password entered");
            setShowButtonLoader(false);
          } else {
            setShowButtonLoader(false);
          }
        } else {
          setShowButtonLoader(false);
        }
      });
  };

  const checkIsStaffAPICall = () => {
    setShowButtonLoader(true);
    beforeLoginAxios
      .post("public/staff-send-otp", {
        channel_id: "staff-login",
        phone_no: `+91${phoneNumber}`,
        login_type: "password",
      })
      .then((response) => {
        if (response) {
          if (response.data && response.data.statusCode === 200) {
            if (response.data.password_set === 1) {
              setIsStaff(true);
              handleStaffSubmitAPICall();
            } else {
              history.replace("/doctor/login/staff/set-password");
            }
          } else if (response.data && response.data.statusCode == 505) {
            setShowErrorPopup(true);
            setShowButtonLoader(false);
          } else if (response.data && response.data.statusCode === 202) {
            setIsStaff(false);
            handleSubmitAPICall();
          } else if (response.data && response.data.statusCode === 510) {
            setShowButtonLoader(false);
            swal({
              title: "Invalid Phone Number Entered",
              type: "info",
            });
          } else {
            setShowButtonLoader(false);
            swal({
              title: "Something Went Wrong!",
              type: "info",
            });
          }
        } else {
          setShowButtonLoader(false);
        }
      })
      .catch(function (error) {
        C2CConsole.log(error);
      });
  };

  const handleStaffSubmitAPICall = () => {
    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
      setHasPasswordError(true);
      setShowButtonLoader(false);
      return;
    } else {
      setHasPasswordError(false);
    }

    beforeLoginAxios
      .post("auth/staff-login-rev1", {
        // channel_id: "doctor-login",
        phone_no: `+91${phoneNumber}`,
        user_type: "staff",
        password: password,
        stay_login: stayLogin,
      })
      .then((response) => {
        if (response) {
          if (response?.data?.statusCode == 200) {
            setCookieForUser(response.data.user.id_token, setCookie);
            setDynamicCookieForUser(
              LOCALSTORAGEKEY.USER_ID,
              response.data.user.staff_id,
              setCookie
            );
            setDynamicCookieForUser(
              "user_type",
              response.data.user.user_type,
              setCookie
            );
            localStorage.setItem("id_token", response.data.user.id_token);
            localStorage.setItem(
              LOCALSTORAGEKEY.USER_ID,
              response.data.user.doctor_id
            );
            localStorage.setItem("user_type", response.data.user.user_type);
            localStorage.setItem(
              "user_name",
              response.data.user.staff_full_name
            );
            localStorage.setItem(
              LOCALSTORAGEKEY.STAFF_ID,
              response.data.user.staff_id
            );
            localStorage.setItem(
              "full_name",
              response.data.user.staff_full_name
            );
            localStorage.setItem("doctorPhone", `+91${mobileNumber}`);
            localStorage.setItem("id_token", response.data.user.id_token);
            localStorage.setItem(
              "share_instant_consultation_link_allow",
              response.data.user.permission
                .share_instant_consultation_link_allow
            );
            localStorage.setItem(
              "add_edit_new_staff_name_and_number_allow",
              response.data.user.permission
                .add_edit_new_staff_name_and_number_allow
            );
            localStorage.setItem(
              "start_instant_consultation_allow",
              response.data.user.permission.start_instant_consultation_allow
            );
            localStorage.setItem(
              "upload_manual_prescriptions_allow",
              response.data.user.permission.upload_manual_prescriptions_allow
            );
            localStorage.setItem(
              "access_knowledge_center_allow",
              response.data.user.permission.access_knowledge_center_allow
            );
            localStorage.setItem(
              "download_offline_patients_allow",
              response.data.user.permission.download_offline_patients_allow
            );
            localStorage.setItem(
              "edit_prescriptions_allow",
              response.data.user.permission.edit_prescriptions_allow
            );
            localStorage.setItem(
              "join_webinar_allow",
              response.data.user.permission.join_webinar_allow
            );
            localStorage.setItem(
              "update_account_details_allow",
              response.data.user.permission.update_account_details_allow
            );
            localStorage.setItem(
              "update_profile_details_allow",
              response.data.user.permission.update_profile_details_allow
            );

            localStorage.setItem(
              "last_login_date",
              response.data.user.last_login_date
            );

            localStorage.setItem(
              "start_upcoming_consultation_allow",
              response.data.user.permission.start_upcoming_consultation_allow
            );

            localStorage.setItem(
              "register_for_upcoming_webinar_allow",
              response?.data?.user?.permission
                ?.register_for_upcoming_webinar_allow
            );
            localStorage.setItem("stay-login", stayLogin);
            localStorage.setItem(
              LOCALSTORAGEKEY.STAFF_CLINIC_ASSOCIATION,
              response?.data?.staff_clinic_association
            );
            localStorage.setItem(
              "staff_doctor_association",
              response?.data?.staff_doctor_association
            );

            setTimeout(() => {
              if (localStorage.getItem("id_token")) {
                if (response?.data?.staff_clinic_association === true) {
                  history.replace("/clinic/account/dashboard");
                } else {
                  history.replace("/doctor/account/dashboard");
                }
              } else {
                setShowButtonLoader(false);
              }
            }, 100);
          } else if (response.data.statusCode == 510) {
            setPasswordError("Incorrect password entered");
            setHasPasswordError(true);
            setShowButtonLoader(false);
          } else {
            setShowButtonLoader(false);
          }
        } else {
          setShowButtonLoader(false);
        }
      });
  };

  //<==

  //create UI ==>

  const errorContrainerUi = () => {
    return (
      <div className="error-msg-container">
        <ErrorContainer
          title={"No Doctor Registered!"}
          errorMessage={
            "You are not registered on Connect2Clinic. Kindly create a new Doctor’s account"
          }
        />
      </div>
    );
  };
  const forgotPassCallBack = () => {
    history.push("/doctor/login/set-password");
  };

  const loginpasswordCallBack = () => {
    history.push("/doctor/login");
  };
  const loginOtpCallBack = () => {
    history.push("/doctor/login/otp-login");
  };

  const LoginWithPasswordAndOtp = () => {
    return (
      <div className="login-with-password-otp-container">
        <LoginWithPasswordOtp
          loginpasswordCallBack={loginpasswordCallBack}
          loginOtpCallBack={loginOtpCallBack}
          otpPath={"/doctor/login/otp-login"}
          passwordPath={"/doctor/login"}
        />
        <div className="phone-number-otp-input-feild">
          <div className="doctor-login-phone-input">
            <PhoneNumberInputWithAction
              placeholder={"Enter Phone Number"}
              inputValue={mobileNumber}
              disabled={false}
              handleInputChange={handlePhoneInputChange}
              handleKeyDown={handleKeydown}
              autoFocus
            />
          </div>
          <div className="doctor-login-password-input">
            <PasswordInputWithHeader
              passwordInput={password}
              disabled={false}
              hasError={hasPasswordError}
              errorMessage={passwordError}
              inputRef={inputRef}
              handlePasswordInputChange={handlePasswordInputChange}
              onKeyDown={handleKeydown}
            />
          </div>

          {showErrorCard && errorContrainerUi()}
          <TermsSignedInForgotPassword
            staySignIn={staySignIn}
            handleTermsAndCondition={handleTermsAndCondition}
            forgotPassCallBack={forgotPassCallBack}
          />
        </div>
        <div className="create-account-continue-btn-wrapper">
          <div className="create-account-btn-wrapper">
            <div className="dont-have-account-text">Don’t have Account?</div>
            <div
              className="create-account-btn"
              onClick={() => {
                history.push("/doctor/login/sign-up");
              }}
            >
              Create account
            </div>
          </div>
          <ActionButton
            title={"Continue"}
            disabled={disableActionButton}
            showButtonLoader={showButtonLoader}
            handleClick={handleContinue}
          />
        </div>
      </div>
    );
  };

  //<==

  return (
    <div>
      {loader ? (
        <div className="loader-container">
          <Loader />
        </div>
      ) : (
        <div className="doctor-signin-login-wrapper">
          <div className="doctor-signin-login-box">
            <SignInFormLayout
              heading={"Hello, Doctor"}
              subheading={"Login here"}
            >
              {LoginWithPasswordAndOtp()}
              <DownloadAppAndCallContactUI />
            </SignInFormLayout>
          </div>
        </div>
      )}
      <StaffLoginErrorModal
        show={showErrorPopup}
        onClose={() => {
          setShowErrorPopup(false);
        }}
      />
    </div>
  );
}

export default DoctorSignInPasswordContainer;
