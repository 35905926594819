import React from "react";
import Stepper from "../DoctorProfile/stepper";
import * as Steps from "../DoctorProfile/constants";
import DetailsForm from "./DetailsForm";

function Details({
  patientState,
  setPatientState,
  terms,
  setTerms,
  isNamePresent,
  isDobPresent,
  setIsPresent,
  formErrors,
}) {
  return (
    <>
      <div className="online-book-appointment">Book Appointment</div>
      <Stepper
        active={3}
        stepper={Steps.stepper[3]}
        stepper_mob={Steps.stepper_mob[3]}
      />
      <DetailsForm
        patientState={patientState}
        setPatientState={setPatientState}
        terms={terms}
        setTerms={setTerms}
        isNamePresent={isNamePresent}
        isDobPresent={isDobPresent}
        setIsPresent={setIsPresent}
        formErrors={formErrors}
      />
    </>
  );
}

export default Details;
