import React, { useEffect, useState } from "react";

import { Switch, Route } from "react-router-dom";

import LoginEnterPassword from "./LoginEnterPassword";
import SignInEnterPhoneNumber from "./SignInEnterPhoneNumber";
import OtpLoginFullDoctor from "./otploginFullDoctor";
import PatientOTPEnterScreen from "./patientOTPEnterScreen";
import SetPasswordFirstTime from "./SetPassword";
import ResetPassword from "./ResetPassword";

import "./styles.scss";
import {
  disableHelpButton,
  enableHelpButton,
} from "../common/DisableHelpButton/DisableHelpButton";

function PatientLoginContainer(props) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const { redirectUrl, baseUrl = "/doctor/:slug" } = props;

  useEffect(() => {
    if (props.showPatientLoginModal) {
      enableHelpButton();
    } else {
      disableHelpButton();
    }
    return () => {
      // if (props.showPatientLoginModal) {
      disableHelpButton();
      // }
    };
  }, []);
  return (
    <div className="max-width-restrict">
      <div>
        <Switch>
          <Route path={`${baseUrl}/login/forgot-password`}>
            <ResetPassword phoneNumber={phoneNumber} baseUrl={baseUrl} />
          </Route>
          <Route path={`${baseUrl}/login/set-password`}>
            <SetPasswordFirstTime
              phoneNumber={phoneNumber}
              redirectUrl={redirectUrl}
              baseUrl={baseUrl}
            />
          </Route>
          <Route path={`${baseUrl}/login/enter-otp`}>
            <PatientOTPEnterScreen
              phoneNumber={phoneNumber}
              redirectUrl={redirectUrl}
              baseUrl={baseUrl}
            />
          </Route>
          <Route path={`${baseUrl}/login/otp-login`}>
            <OtpLoginFullDoctor phoneNumber={phoneNumber} baseUrl={baseUrl} />
          </Route>
          <Route path={`${baseUrl}/login/enter-password`}>
            <LoginEnterPassword
              phoneNumber={phoneNumber}
              redirectUrl={redirectUrl}
              baseUrl={baseUrl}
            />
          </Route>
          <Route path={`${baseUrl}/login/`}>
            <SignInEnterPhoneNumber
              baseUrl={baseUrl}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              redirectUrl={redirectUrl}
            />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default PatientLoginContainer;
