import emailIcon from "../../../../assets/mail-icon.png";
import callIcon from "../../../../assets/call-24px.png";

export const getContactNumber = async (doctor_profile_details) => {
  let contactNos = "";

  if (
    doctor_profile_details?.is_doctor_registered_mob_no_enabled &&
    doctor_profile_details?.is_doctor_alternate_no_enabled &&
    doctor_profile_details?.doctor_registered_mobile_number &&
    doctor_profile_details?.doctor_alternate_number
  ) {
    if (
      !doctor_profile_details.doctor_alternate_number.startsWith("+91") &&
      doctor_profile_details.doctor_alternate_number.length === 10
    ) {
      doctor_profile_details.doctor_alternate_number =
        "+91" + doctor_profile_details.doctor_alternate_number;
    }
    contactNos = ` For Consultations: ${doctor_profile_details.doctor_registered_mobile_number} | Alternate No: ${doctor_profile_details.doctor_alternate_number}`;
  } else if (
    doctor_profile_details?.is_doctor_registered_mob_no_enabled &&
    doctor_profile_details?.doctor_registered_mobile_number
  ) {
    contactNos = ` For Consultations: ${doctor_profile_details.doctor_registered_mobile_number}`;
  } else if (
    doctor_profile_details?.is_doctor_alternate_no_enabled &&
    doctor_profile_details?.doctor_alternate_number
  ) {
    if (
      !doctor_profile_details.doctor_alternate_number.startsWith("+91") &&
      doctor_profile_details.doctor_alternate_number.length === 10
    ) {
      doctor_profile_details.doctor_alternate_number =
        "+91" + doctor_profile_details.doctor_alternate_number;
    }
    contactNos = ` For Consultations: Alternate No: ${doctor_profile_details.doctor_alternate_number}`;
  }

  return contactNos;
};

export const getHeaderData = async (
  doctor_profile_details,
  qrCodeUrl,
  headerImg,
  showHeaderTemplate
) => {
  let contactNos = await getContactNumber(doctor_profile_details);
  let hospitalLogo = "";
  if (doctor_profile_details?.hospital_logo?.presigned_url_view) {
    hospitalLogo = doctor_profile_details?.hospital_logo?.presigned_url_view;
  }

  let doctorName = `${doctor_profile_details.first_name}${
    doctor_profile_details.middle_name
      ? " " + doctor_profile_details.middle_name
      : ""
  } ${doctor_profile_details.last_name}`;

  return {
    hospitalLogo: hospitalLogo,
    hospitalName: doctor_profile_details.hospital_name,
    is_header_hospital_logo_enabled:
      doctor_profile_details.is_header_hospital_logo_enabled,
    contactNos: contactNos,
    firstName: doctor_profile_details.first_name,
    middleName: doctor_profile_details.middle_name,
    lastName: doctor_profile_details.last_name,
    doctorName: doctorName,
    degree: doctor_profile_details.degree,
    medicalRegNo: doctor_profile_details.medical_reg_no,
    doctorAddress: doctor_profile_details.doctor_address?.replace(/\n/g, " "),
    doctorEmail: doctor_profile_details.doctor_registered_email,
    doctorPhoneNo: doctor_profile_details.doctor_registered_mobile_number,
    doctorAlternateNumber: doctor_profile_details.doctor_alternate_number,
    doctorSlugUrl: doctor_profile_details.doctor_appointment_booking_link,
    qrCodeUrl: qrCodeUrl,
    emailIcon: emailIcon,
    callIcon: callIcon,
    doctorConsultationTimings:
      doctor_profile_details.doctor_consultation_timings,
    isDoctorNameEnabled: doctor_profile_details.is_doctor_name_enabled,
    isDoctorDegreeEnabled: doctor_profile_details.is_doctor_degree_enabled,
    isDoctorRegistrationNumberEnabled:
      doctor_profile_details.is_doctor_registration_number_enabled,
    isDoctorAddressEnabled: doctor_profile_details.is_doctor_address_enabled,
    isDoctoRegisteredMobNoEnabled:
      doctor_profile_details.is_doctor_registered_mob_no_enabled,
    isDoctorAlternateNoEnabled:
      doctor_profile_details.is_doctor_alternate_no_enabled,
    isDoctorRegisteredEmailEnabled:
      doctor_profile_details.is_doctor_registered_email_enabled,
    isDoctorConsultationTimingsEnabled:
      doctor_profile_details.is_doctor_consultation_timings_enabled,
    isDrAppointmentBookingQrEnabled:
      doctor_profile_details.is_dr_appointment_booking_qr_enabled,
    isUploadedHeaderTemplateToBeUsed: showHeaderTemplate,
    headerImg: headerImg,
  };
};
