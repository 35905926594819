import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import PatientLoginContainer from "../PatientLoginContainerBooking";
import { useParams } from "react-router";
import { disableHelpButton } from "../common/DisableHelpButton/DisableHelpButton";
import "../../globalStyles/main.css";

function PatientLoginModal({
  baseUrl,
  showPatientLoginModal,
  setShowPatientLoginModal,
}) {
  const { slug } = useParams();
  useEffect(() => {
    if (showPatientLoginModal) {
      disableHelpButton();
    }
    if (!showPatientLoginModal) {
      disableHelpButton();
    }
  }, []);
  return (
    <Modal
      show={showPatientLoginModal}
      onHide={() => {
        setShowPatientLoginModal(false);
        disableHelpButton();
      }}
    >
      <Modal.Body>
        <div className="d-flex flex-row align-items-center justify-content-center w-100">
          <PatientLoginContainer
            baseUrl={baseUrl}
            // loginSuccessHandler={loginSuccessHandler}
            closeModal={() => {
              setShowPatientLoginModal(false);
            }}
            redirectUrl={`/in-clinic-payment/${slug}`}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PatientLoginModal;
