import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectHome = (state) => state.prescriptionComponent || initialState;

const makeSelectPrescription = () =>
  createSelector(selectHome, (prescriptionComponent) => prescriptionComponent);

const makeselectPatient = () =>
  createSelector(
    selectHome,
    (prescriptionComponent) => prescriptionComponent.patient
  );
const makeSelectDrugs = () =>
  createSelector(
    selectHome,
    (prescriptionComponent) => prescriptionComponent.drugs
  );

const makeSelectLabFindings = () =>
  createSelector(
    selectHome,
    (prescriptionComponent) => prescriptionComponent.labFindings
  );

const makeSelectDropDownAuto = () =>
  createSelector(
    selectHome,
    (prescriptionComponent) => prescriptionComponent.dropDown
  );

export {
  selectHome,
  makeSelectPrescription,
  makeselectPatient,
  makeSelectDrugs,
  makeSelectLabFindings,
  makeSelectDropDownAuto,
};
