import React, { createRef, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";

const FileSelector = ({ onSelect, accept, maxFileSize, isTrue, ...props }) => {
  let inputRef = createRef();
  //const [fileState, setFile] = useState({});
  const [fileState, setFile] = useState(null);

  useEffect(() => {
    onSelect(fileState);
  }, [fileState]);

  const inputChangeHandler = (event) => {
    const slectedFileName = props.name;
    const file = event.target.files[0];
    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        const lastDot = file.name.lastIndexOf(".");
        const fileObject = {
          value: reader.result,
          name: file.name,
          size: (file.size / 1024 / 1024).toFixed(2),
          type: file.name.substring(lastDot + 1),
          fileName: file.name.substring(0, lastDot),
          fileType: file.type,
          fileDate: file,
        };
        var filePath = fileObject.name;
        var allowedExtensions =
          /(\.jpg|\.jpeg|\.png|\.gif|\.bmp|\.doc|\.docx|\.pdf|\.txt)$/i;
        if (accept && !allowedExtensions.exec(filePath)) {
          alert(
            "The allowed extension is .jpeg/.jpg/.png/.gif/.pdf/.doc only."
          );
        } else {
          if (maxFileSize) {
            if (file.size / 1024 / 1024 > maxFileSize) {
              if ("Signature Photo" === slectedFileName) {
                alert(
                  "Please upload a file less than " + maxFileSize * 1000 + "KB"
                );
              } else {
                alert("Please upload a file less than " + maxFileSize + "MB");
              }
            } else {
              setFile(fileObject);
            }
          } else {
            setFile(fileObject);
          }
        }
      };
    }
  };

  return (
    <>
      <Button
        variant="secondary"
        onClick={() => inputRef.click()}
        disabled={isTrue ? "disabled" : ""}
        {...props}
      >
        Choose File
      </Button>
      <input
        type="file"
        onChange={inputChangeHandler}
        style={{ display: "none" }}
        accept={accept || "image/*,.doc, .docx,.pdf,.txt"}
        ref={(ref) => (inputRef = ref)}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
    </>
  );
};

export default FileSelector;
