import axios from "axios";

export const getBase64Image = async (img) => {
  // temp veriable which store image from url

  // image to base64 covert
  let image = await axios
    .get(img, { responseType: "arraybuffer" })
    .catch(function (error) {
      console.log("error while fetching image ", img, error);
    });
  if (image && image?.data) {
    let raw = Buffer.from(image.data).toString("base64");
    return "data:" + image.headers["content-type"] + ";base64," + raw;
  } else {
    console.log("catch image error");
    return "";
  }
};

export const getBase64Text = async (url) => {
  return axios
    .get(url, {
      responseType: "text" /* or responseType: 'arraybuffer'  */,
    })
    .then((r) => "data:image/png;base64," + r.data.toString());
};
